import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Popover from '@material-ui/core/Popover';
import LighthouseDialog from '../../../../../components/Shared/organisms/LighthouseDialog';
import { AlertIcon, RefreshIcon, DeleteIcon, OptionsIcon } from '../../../../../Icons';
import { setSelectedAlertIdInManager } from '../../../../../redux/slices/alertSelectionSlice';
import AuthorizedComponent from '../../../../../components/Shared/molecules/AuthorizedComponents/AuthorizedComponent';
import DismissAlertWizardContainer from '../DismissAlertWizardContainer/DismissAlertWizardContainer';
import ConfirmUnDismissAlertWizardContainer from '../ConfirmUnDismissAlertWizard/ConfirmUnDismissAlertWizard';
import './AlertManagerSelectorItem.scss';

const AlertManagerSelectorItem = (props) => {
  const { alert, selectedProject } = props;
  const { selectedAlertIdInManager } = useSelector(state => state.alertSelection);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'interface-point-popover' : undefined;
  const [dismissAlertDialogBoxVisibility, setDismissAlertDialogBoxVisibility] = useState(false);
  const [unDismissAlertDialogBoxVisibility, setUnDismissAlertDialogBoxVisibility] = useState(false);
  const dispatch = useDispatch();
  const alertRef = useRef(null);
  const scrollToAlert = () => alertRef.current.scrollIntoView();

  useEffect(()=>{
    if(alert.alertId === selectedAlertIdInManager){
      scrollToAlert()
    }
  },[])

  let alertStatus = alert.alertStatus;
  let alertStatusDate = '';

  if (alertStatus == 'Active' || alertStatus == 'Expired'){
    alertStatusDate = alert.raisedOn;
  }
  else if (alertStatus == 'Dismissed'){
    alertStatusDate = alert.resolvedOn;
  }
  else {
    alertStatusDate = alert.raisedOn;
  }

    /**
  * Handles alert selection change event
  * @param {*} alertId 
  */
     const handleAlertIdSelectorChange = (alertId) => {
      // gets and sets the interface point Id
      dispatch(setSelectedAlertIdInManager(alertId));
    };

  /**
   * Options icon clicked
   * shows the Option pop-up
   */
  const handleOptionsClick = (event) => {
    if((alertStatus=="Active" || alertStatus=="Dismissed") && alert.isAlertValid > 0)
    {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };
  
  /**
   * To close the pop-up
   */ 
  const handleOptionsClose= (event) =>{
    event.stopPropagation();
    setAnchorEl(null);
  }

  /**
   * Dismiss Alert clicked
   * shows the Disimss Alert wizard
   */
   const dismissAlertWizard = (event) => {
    event.stopPropagation();
    setDismissAlertDialogBoxVisibility(true);
    setAnchorEl(null);
  }

    /**
   * Undismiss Alert clicked
   * shows the Undismiss Alert wizard
   */
     const unDismissAlertWizard = (event) => {
      event.stopPropagation();
      setUnDismissAlertDialogBoxVisibility(true);
      setAnchorEl(null);
    }

  /**
   * Cancel clicked
   * Closes the Disimss Alert wizard
   */
  const cancelDismissAlert = () => {
    setDismissAlertDialogBoxVisibility(false);
  }

    /**
   * Cancel clicked
   * Closes the Un-Disimss Alert wizard
   */
     const cancelUnDismissAlert = () => {
      setUnDismissAlertDialogBoxVisibility(false);
    }

    /**
   * Un-Dismiss Alert clicked
   * Passes the trigger to Close the Disimss Alert wizard
   */
      const unDismissAlertClick = () => {
      setUnDismissAlertDialogBoxVisibility(false);
    }

  return (
    <div>
    {alert && selectedProject &&
    <div ref={alertRef}>
      <div
        onClick={() => handleAlertIdSelectorChange(`${alert.alertId}`)}
        className={`${alert.alertId === selectedAlertIdInManager ? "active alert-item-container" : "alert-item-container"}`} >   
      <div className="alert-item-header" > 
         {(alert.alertType === "Secondary" || alert.alertType === "Primary") && alert.alertStatus === 'Expired' &&
         <span className="alert-icon-expired" >
            <AlertIcon className="alert-icon" />
        </span>}
        {alert.alertType === "Primary" && alert.alertStatus !== 'Expired' &&
         <span className="alert-icon-critical">
            <AlertIcon className="alert-icon" />
         </span> }
         {alert.alertType === "Secondary" && alert.alertStatus !== 'Expired' &&
         <span className="alert-icon-others" >
            <AlertIcon className="alert-icon" />
        </span>}
        <span className='alert-name'>{alert.alertName?.length > 37 && alert.alertName?.substring(0, 36) + "..."}
        {alert.alertName?.length <= 37 && alert.alertName}</span>
      </div>
      {(alertStatus !=="Expired" ) && alert.isAlertValid > 0 &&
        <OptionsIcon onClick={handleOptionsClick} className="options-icon" />
      }
      </div>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleOptionsClose} anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        transformOrigin={{vertical: 'top',horizontal: 'right'}} BackdropProps={{ invisible: false }} style={{borderRadius:12}}>
          <div className='alert-full-content'>
          <div className="alert-option-content">
            <AuthorizedComponent value="dismiss-alert" projectId = {selectedProject.projectId} disciplineId = {alert.disciplineId}>
            <div className='dismissal-active-container'>
               {alertStatus.toLowerCase()==="dismissed" ? <span className="dismissed-alert"><DeleteIcon className='dismissed-alert-icon'/> Dismiss Alert</span> : <span className="dismiss-alert" onClick={dismissAlertWizard}><DeleteIcon className='dismiss-alert-icon'/>Dismiss Alert</span>   }
             </div>
            </AuthorizedComponent>
          </div>
          <hr/>
          <div className="alert-option-content">
            <AuthorizedComponent value="dismiss-alert" projectId = {selectedProject.projectId} disciplineId = {alert.disciplineId}>
            <div className='dismissal-active-container'>
              {alertStatus.toLowerCase()==="active" ? <span className="dismissed-alert"><RefreshIcon className='dismissed-alert-icon'/> Re-Activate</span> : <span className="dismiss-alert" onClick={unDismissAlertWizard}><DeleteIcon className='dismiss-alert-icon'/>Re-Activate</span>   }
              </div>
            </AuthorizedComponent>
          </div>
          </div>
      </Popover>
      <LighthouseDialog isVisible={dismissAlertDialogBoxVisibility} title={'Dismiss Alert'} width="770px" height="475px" z-index="98" noPadding={true}>
        <DismissAlertWizardContainer cancelDismissAlert={cancelDismissAlert} selectedAlertId = {alert.alertId} selectedAlertName = {alert.alertName} selectedAlertStatus={alert.alertStatus} selectedPhaseId={alert.phaseId} selectedPackageId={alert.packageId} selectedAlertType={alert.alertType} selectedAlertDriver={alert.driver}/>
      </LighthouseDialog>
      <LighthouseDialog isVisible={unDismissAlertDialogBoxVisibility} title={'Re-Activate'} width="640px" height="340px" z-index="98" noPadding={true}>
        <ConfirmUnDismissAlertWizardContainer unDismissAlertClick={unDismissAlertClick}  cancelUnDismissAlert={cancelUnDismissAlert} selectedAlertId = {alert.alertId} selectedAlertName = {alert.alertName} selectedDisciplineId ={alert.disciplineId} selectedAlertStatus={alert.alertStatus} selectedPhaseId={alert.phaseId} selectedPackageId={alert.packageId} selectedAlertType={alert.alertType} selectedAlertDriver={alert.alertDriver}/>
      </LighthouseDialog>
      </div>
      }
    </div>
  );
}

export default AlertManagerSelectorItem;