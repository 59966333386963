import { 
  createSlice,
} from '@reduxjs/toolkit';

/**
 * Create dpv slice / reducers
 */
const dpvSlice = createSlice({
  name: 'dpv',
  initialState: { progressBarHeight: "16px" },
  reducers: {
    setProgressBarHeight: (state, action) => {
      state.progressBarHeight = action.payload;
    }
  }
});

/**
 * dpv actions
 */
 export const {  
  setProgressBarHeight, 
} = dpvSlice.actions;

/**
 * Export reducer
 */
export default dpvSlice.reducer;