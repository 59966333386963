import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { PackagePctCompleteCalcTypeEndpoint, UpdatePackagePctCompleteCalcTypeEndpoint } from '../../api/apiRoutes';

/**
 * 
 * @returns Fetches PackagePctCompleteCalcTypes
 */
export const fetchPackagePctCompleteCalcTypes = createAsyncThunk(
  'package/fetchPackagePctCompleteCalcTypes', async (params, thunkAPI) => {
     try {
        const response = await api.get(PackagePctCompleteCalcTypeEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * 
 * @returns update PackagePctCompleteCalcType
 */
 export const updatePackagePctCompleteCalcType = createAsyncThunk(
  'package/updatePackagePctCompleteCalcType', async (params, thunkAPI) => {
     try {
        const response = await api.put(UpdatePackagePctCompleteCalcTypeEndpoint(params.projectId, params.packageId), params.packagePctCalcType,  { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Create PackagePctCompleteCalcType adapter
 */
const packagePctCompleteCalcTypesAdapter = createEntityAdapter({
  selectId: (pkg) => pkg.packageId,
})

/**
 * Create schedule slice / reducers
 */
const packagePctCompleteCalcTypesSlice = createSlice({
  name: 'packagePctCompleteCalcTypes',
  initialState: packagePctCompleteCalcTypesAdapter.getInitialState( {  
                                                    selectedPackageId: undefined,
                                                    packagePctCalcType: [], 
                                                    isLoading: false,
                                                    error: undefined } ),
  reducers: {
    setPackagePctCompleteCalcTypeId: (state, action) => {
      state.selectedPackageId = action.payload;
    },
    setPackagePctCalcType: (state, action) => {
      state.packagePctCalcType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackagePctCompleteCalcTypes.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchPackagePctCompleteCalcTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        packagePctCompleteCalcTypesAdapter.setAll(state, action.payload);
      })
      .addCase(fetchPackagePctCompleteCalcTypes.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(updatePackagePctCompleteCalcType.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(updatePackagePctCompleteCalcType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(updatePackagePctCompleteCalcType.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * PackagePctCompleteCalcType selectors
 */
export const {
  selectAll: selectAllPackagePctCompleteCalcTypes,
  selectById: selectPackagePctCompleteCalcTypeById,
} = packagePctCompleteCalcTypesAdapter.getSelectors((state) => state.packagePctCompleteCalcTypes);

/**
 * Export actions
 */

export const { setPackagePctCompleteCalcTypeId, setPackagePctCalcType } = packagePctCompleteCalcTypesSlice.actions;

/**
 * Export reducer
 */
export default packagePctCompleteCalcTypesSlice.reducer;