import React, { useState, useRef, useImperativeHandle, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import  Radio  from '@material-ui/core/Radio';
import  RadioGroup  from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/styles';
import { FormControlLabel } from '@material-ui/core';
import AuthorizedComponent from '../../../../../components/Shared/molecules/AuthorizedComponents/AuthorizedComponent';
import { setPackagePctCalcType } from '../../../../../redux/slices/packagePctCompleteCalcTypesSlice';
import './ActivityPackageHeader.scss';

const ActivityPackageHeader = (props) => {
  const dispatch = useDispatch();
  const { selectedProjectId, packageActivityCheck, workPackage, packagePctCompleteCalcTypes } = props;
  const { packagePctCalcType } = useSelector(state => state.packagePctCompleteCalcTypes);

  const checkValue = (packId) =>{
   if(packageActivityCheck.slice().find(x=>x.packageId === packId) && !packagePctCalcType.slice().find(x=>x.packageId === packId)){
     return packageActivityCheck.slice().find(x=>x.packageId == packId)?.activityChecked;
   }else if(packagePctCalcType.slice().find(x=>x.packageId == packId)){
     if (packagePctCalcType.slice().find(x=>x.packageId == packId)?.packagePctCalcType === (1 || 0)){
       return false;
     }else if(packagePctCalcType.slice().find(x=>x.packageId == packId)?.packagePctCalcType === 2 ) {
       return true;
     }
     else{
      return false;
     }
   }else{
    return false;
   }
  }

   /**
   * Handles activity type change
   */

const ActivityCalcTypeChanged = (args) => {
         let packageId = workPackage?.packageId;
         let value = args?.target?.value;
        if(value === 'true'){
             dispatch(setPackagePctCalcType([{"packageId": packageId, "packagePctCalcType": 2 }, ...packagePctCalcType.filter(obj => { return obj?.packageId !== packageId; })]));
        }
        else if(value === 'false'){
           if(packagePctCompleteCalcTypes.slice().find(x=>x.packageId === packageId).packagePctCalcType === 2){
            dispatch(setPackagePctCalcType([{"packageId": packageId, "packagePctCalcType": 1 }, ...packagePctCalcType.filter(obj => { return obj?.packageId !== packageId; })]));
           }
           else{
            dispatch(setPackagePctCalcType([{"packageId": packageId, "packagePctCalcType": 0 }, ...packagePctCalcType.filter(obj => { return obj?.packageId !== packageId; })]));
           }
    }
}

  const BlueRadio = withStyles({
    root: {
      color: 'var(--color-unchecked)',
      "&$checked": {
        color: 'var(--color-accent)'
      }
    },
    checked: {}
  })((props) => <Radio color="default" {...props} />);

  return (
    <>
        { workPackage  &&
            <>
                <div className="package">
                    <div className="package-header">
                        <div className="package-title">
                            {workPackage.description?.length > 65 && workPackage.description?.substring(0,62) + "..."}
                            {workPackage.description?.length <= 65 && workPackage.description}
                        </div>
                        <div className="activity-toggle">
                            <RadioGroup row aria-label="custom-switch" size="small" value ={checkValue(workPackage?.packageId)} onChange={ActivityCalcTypeChanged}>
                            <AuthorizedComponent value="activity-switch" projectId = {selectedProjectId} disciplineId = 'All'> 
                                 <FormControlLabel  value={false} control={<BlueRadio size="small"/>} />  
                                 <FormControlLabel  value={true} control={<BlueRadio size="small"/>} /> 
                           </AuthorizedComponent>      
                            </RadioGroup>
                        </div>
                    </div>
                </div>
            </>
        }
    </>
);
}

export default ActivityPackageHeader;