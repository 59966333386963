import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { removeActivityChanges, selectAllActivityChanges } from '../../../../../redux/slices/activityChangesSlice';
import Popover from '@material-ui/core/Popover';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import ActivityStateChangesPopover from '../ActivityStateChangesPopover/ActivityStateChangesPopover';
import AllActivityStateDropDownListContainer from '../../molecules/AllActivityStateDropDownListContainer';
import { setIsChecked } from '../../../../../redux/slices/activityChangesSlice';
import './ActivityStateConfirmChangesContainer.scss';

const ActivityStateConfirmChangesContainer = (props) => {
    const dispatch = useDispatch();
    const { isCheckedValue } = useSelector(state => state.activityChanges)
    const activityChanges  = useSelector((state) => selectAllActivityChanges(state));
    const taskChanges  = activityChanges.filter(a=>a.activityType == 'Task');
    const milestoneChanges  = activityChanges.filter(a=>a.activityType == 'Milestone' || a.activityType =='Finish Milestone');
    const [anchorEl, setAnchorEl] = useState(null);
    const [addOpen, setAddOpen] = useState(false);

    const confirmSubmitChanges = (args) =>{
         props.confirmSubmitChanges();
         setAnchorEl(null);
         setAddOpen(false);
    }

    const cancelSubmitChanges = (event) =>{
        setAnchorEl(null);
        setAddOpen(false);
    }

    const submitChanges = (event) =>{
        setAnchorEl(event);
        setAddOpen(true);
    }

    const resetChanges = (event) =>{
        props.cleanFilterState();
        dispatch(setIsChecked(0))
        dispatch(removeActivityChanges());
    }

    return(
        <>
            { activityChanges && activityChanges.length > 0 && isCheckedValue !== 0 &&
                <div className='all-activity-change-status-container'>
                    <div className='all-activity-change-status'>
                         <div className='all-activity-dropdown-status-value-align'>

                              {isCheckedValue === 2 && taskChanges?.length > 0 && <p><strong>{taskChanges.length}</strong> {taskChanges?.length>1 ? 'Tasks':'Task'} Selected</p>}
                              {isCheckedValue === 2 && taskChanges?.length > 0 && <AllActivityStateDropDownListContainer type='Task'/> } 

                              {isCheckedValue === 2 && milestoneChanges?.length > 0 && <p><strong>{milestoneChanges.length}</strong> {milestoneChanges?.length>1 ? 'Milestones':'Milestone'} Selected</p>}
                              {isCheckedValue === 2 && milestoneChanges?.length > 0 && <AllActivityStateDropDownListContainer type='Milestone'/> } 
                              
                              {isCheckedValue !== 2 && <>
                                                        <p>
                                                            {taskChanges?.length > 0 && <><strong> {taskChanges.length}</strong>&nbsp;{taskChanges?.length > 1 ? 'Tasks':'Task'} </>}
                                                            {taskChanges?.length > 0 && milestoneChanges?.length > 0 && <>,</>}
                                                            {milestoneChanges?.length > 0 && <><strong> {milestoneChanges.length}</strong>&nbsp;{milestoneChanges?.length > 1 ? 'Milestones':'Milestone'} </>}
                                                            'Live Status' {activityChanges?.length>1?'changes':'change'} made
                                                        </p>
                                                       </>
                                                       }
                        </div>
                        <div className='all-activity-buttons-align'>
                             <SecondaryLighthouseButton className='reset' onClick={resetChanges}>Reset</SecondaryLighthouseButton>
                             <PrimaryLighthouseButton className='submit' onClick={submitChanges}>Submit</PrimaryLighthouseButton>
                        </div>
                 </div>
                    <Popover
                    id='submit-changes'
                    open={addOpen}
                    anchorEl={anchorEl}   
                    anchorReference='none'
                    BackdropProps={{ invisible: false }}
                    onClose={cancelSubmitChanges}
                    >
                        <div className="submit-changes-popover">
                            <ActivityStateChangesPopover confirmSubmitChanges={confirmSubmitChanges} cancelSubmitChanges={cancelSubmitChanges} />
                        </div>
                    </Popover>
                </div>
            }
        </>
    );
}

export default ActivityStateConfirmChangesContainer;