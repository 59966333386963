import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { fetchAppRoles, fetchSearchedAdUsers, addUserRoles, setSearchUsersList} from '../../../../../redux/slices/userAdSlice';
import { selectAllDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import AuthorizationService from "../../../../../components/Shared/molecules/Authorization/AuthorizationService";
import { fetchDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import AddUserWidget from '../../molecules/AddUserWidget';


const AddUserWidgetContainer = (props) => {
    const dispatch = useDispatch();
    const { selectedProjectId } = useSelector(state => state.projects);
    const { appRoles } = useSelector(state => state.usersAd);
    const { LHRoles } = useSelector(state => state.authorization);
    const { isLoading, error } = useSelector(state => state.disciplines);
    const disciplines = useSelector(selectAllDisciplines).filter((d) => d.disciplineName !== "External Milestones");
    const { instance, accounts } = useMsal();
    const [ accessToken, setAccessToken ] = useState(null);
    const [ isReady, setIsReady ] = useState(false);

    useEffect(() => {
        /**
         * Acquire access token
         */
          if (accounts.length > 0) {
              const request = {
                  scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
                  account: accounts[0]
              };
              instance.acquireTokenSilent(request).then(response => {
                  setAccessToken(response.accessToken);
              }).catch(error => {
                  // acquireTokenSilent can fail for a number of reasons, fallback to interaction
                  if (error instanceof InteractionRequiredAuthError) {
                      instance.acquireTokenPopup(request).then(response => {
                          setAccessToken(response.accessToken);
                      });
                  }
              });
          }
      }, [accounts]);

      useEffect(() => {
        if (accessToken){
          dispatch(fetchAppRoles({projectId: selectedProjectId, accessToken: accessToken}))
           .then(() => dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken })).then(()=>{
                AuthorizationService({dispatch : dispatch, instance: instance, accounts: accounts, accessTokenAvailable: accessToken});
                setIsReady(true);
            }) 
          )
        }
    
      },[dispatch, selectedProjectId, accessToken]);

      const searchUsers = (currentText) => {
        dispatch(fetchSearchedAdUsers({ projectId: selectedProjectId, user: currentText, accessToken: accessToken}))
      }

      const resetSearchUserList = () =>{
        dispatch(setSearchUsersList([]))
      }

      const closeWidget = () =>{
          props.closeWidget()
      }

      const addUserRole = (value, selectedRoleIds) =>{
        if(value.length >0 && selectedRoleIds.length > 0){
          setIsReady(false)
         let userRoles = [];
         value?.map((val)=>{
             userRoles.push({
             UserEmailAddress: val.mail,
             RoleIds: selectedRoleIds
           })
          })
          dispatch(addUserRoles({ projectId: selectedProjectId, userRoles: userRoles, accessToken: accessToken}))
          .then(()=>{props.handleAddUsersClose(value.length === 1?'1 User has been added':`${value.length} Users have been added.`)})
        }else{
           props.toastNotificationHandle(2,'Please select all the required fields');
           setIsReady(true);
        }
      }

  return (
    <>
    {!isReady &&
        <CircularProgressBar />
    }
    {error && !isLoading && isReady &&
        <ErrorMessage message="Unable to load roles and disciplines." />
     }
    {!isLoading && !error && isReady &&
        <AddUserWidget 
                     disciplines={disciplines} 
                     selectedProjectId={selectedProjectId}
                     appRoles={appRoles} 
                     LHRoles={LHRoles} 
                     addUserRole={addUserRole} 
                     searchUsers={searchUsers} 
                     resetSearchUserList={resetSearchUserList} 
                     closeWidget={closeWidget}/>
    }
</>
  )
}

export default AddUserWidgetContainer;