import React from 'react';
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';
import { RelationTreeIcon, ProductionIcon, GlobIcon } from '../../../../../Icons';
import './InterfaceActivitySelectorElement.scss';

const InterfaceActivitySelectorElement = (props) =>{
    const { activitySchedule } = props;


    return (
        <div className='dis-name-container'>
        {activitySchedule.type === 'Discipline' && 
            <div className='icon-style'>
                <span><DisciplineIcon disciplineId={activitySchedule.disciplineId} /></span>
            </div>
        }
        {activitySchedule.type === 'Package' && <span><RelationTreeIcon className='icon-style' /></span>}
        {activitySchedule.type === 'Phase' && <span><GlobIcon className='icon-style' /></span>}
        {(activitySchedule.type === 'Task' || activitySchedule.type === 'Milestone' || activitySchedule.type === 'Finish Milestone') && <span><ProductionIcon className='icon-style' /></span>}
        {(activitySchedule.type === 'Task' || activitySchedule.type === 'Milestone' || activitySchedule.type === 'Finish Milestone') &&
            <>
                {activitySchedule.name}
            </>
        }
        {activitySchedule.type != 'Task' && activitySchedule.type != 'Milestone' && activitySchedule.type != 'Finish Milestone' &&
            <>
            {activitySchedule.name?.length > 50 && activitySchedule.name?.substring(0, 49) + "..."}
            {activitySchedule.name?.length <= 50 && activitySchedule.name}
            </>
        }
        </div>
    );
}

export default InterfaceActivitySelectorElement;