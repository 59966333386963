import React, { useEffect, useState } from 'react';
import { setScenarioStatusForAlert } from '../../../../../../redux/slices/createScenarioSlice';
import { fetchScenarios } from '../../../../../../redux/slices/scenariosSlice';
import { fetchAlerts } from '../../../../../../redux/slices/alertsSlice';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../../components/Shared/molecules/ErrorMessage';
import CircularProgressBar from '../../../../../../components/Shared/molecules/CircularProgressBar';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import CreateScenarioFromAlert from '../CreateScenarioFromAlert';
import { useLocation, useParams } from 'react-router-dom';

const CreateScenarioFromAlertContainer = (props) => {
  const { alertId } = props;
  const { routeAlertId } = useParams();
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const { error, isLoading} = useSelector(state => state.createScenario);
  const location = useLocation();
  const isDialog = !location.pathname.includes("createalertscenario");
 
  const closeCreateScenario = () => {
    props.closeCreateScenario();
  }

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  /**
   * Sets the scenario status from alert
   */
  useEffect(() => {
    let scenarioAlertId;

    // either create the scenario from the route alert id or prop alert id
    if (routeAlertId){
      scenarioAlertId = routeAlertId;
    }
    else{
      scenarioAlertId = alertId;
    }

    if (accessToken && scenarioAlertId){
      // set the scenario status to active then update scenarios and alerts
      dispatch(setScenarioStatusForAlert({ projectId: selectedProjectId, alertId: scenarioAlertId, status: 'Active', accessToken: accessToken})).then(
        dispatch(fetchScenarios({ projectId: selectedProjectId, accessToken: accessToken})).then(
          dispatch(fetchAlerts({ projectId: selectedProjectId, accessToken: accessToken}))
        )
      );
    }
  }, [dispatch, accessToken]);

  return (
    <>
    { isLoading && 
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to create scenario" />
      }
      { !isLoading && !error && accessToken && routeAlertId &&
        <CreateScenarioFromAlert closeCreateScenario={closeCreateScenario} isDialog={isDialog} alertId={routeAlertId} />
      }
      { !isLoading && !error && accessToken && !routeAlertId &&
        <CreateScenarioFromAlert closeCreateScenario={closeCreateScenario} isDialog={isDialog} alertId={alertId} />
      }
    </>
  );
}

export default CreateScenarioFromAlertContainer;