import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import FilterToolbarFocusArea from '../../../../../components/Shared/molecules/FilterToolbarFocusArea';
import { selectAllSortedInterfacePoints } from '../../../../../redux/slices/interfacePointsSlice';
import OptimisationFocusAreasInterfacePointSelector from '../OptimisationFocusAreasInterfacePointSelector/OptimisationFocusAreasInterfacePointSelector';

import { fetchFocusAreasInterfacePoints } from '../../../../../redux/slices/optimisationFocusAreasSlice';
import { fetchInterfacePointTypes, fetchInterfaceWarnings } from "../../../../../redux/slices/interfacePointsSlice";
import './OptimisationFocusAreasSelectionCriteriaContainer.scss';

const OptimisationFocusAreasSelectionCriteriaContainer = (props) => {
  const dispatch = useDispatch();
  const { focusAreasInterfacePointsError, isFocusAreasInterfacePointsLoading, focusAreasInterfacePoints } = useSelector(state => state.optimisationFocusAreas);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [filterSelectionOptions, setFilterSelectionOptions] = useState([]);
  const dateFilterFields = { text: "text", value: "value" };

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  /**
  * Fetches the interface points for the selected project
  */
  useEffect(() => {
    if (accessToken !== null) {
      dispatch(fetchFocusAreasInterfacePoints({ projectId: selectedProjectId, accessToken: accessToken }));
      dispatch(fetchInterfacePointTypes({ projectId: selectedProjectId, accessToken: accessToken }));
      dispatch(fetchInterfaceWarnings({ projectId: selectedProjectId, accessToken: accessToken }));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  /**
    * Sets up date filter list
    */
  useEffect(() => {
    let dateFilterOptions = [];

    let interfaceDateOptions = [
      {
        text: 'All Interfaces',
        value: 0,
        filterProperty: 'interfacePointDate',
        default: true
      },
      {
        text: 'Next 30 Days',
        value: 30,
        filterProperty: 'interfacePointDate',
        default: false
      }
    ];

    dateFilterOptions = dateFilterOptions.concat(interfaceDateOptions);

    if (dateFilterOptions?.length > 0) {
      setFilterSelectionOptions(dateFilterOptions);
    }
  }, []);

  /**
  * Processes and filters the raw interfaces data
  * and if no raw interface data searched blank array is passed
  * @param {object} rawData
  * @returns 
  */
  const processInterfacePoints = (rawData) => {
    let searchedData = [];
    if (rawData.length > 0) {
      if (searchText && searchText.length > 0) {
        searchedData = rawData.slice().filter((d) => ((d.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) || (d.interfacePointNumber.toLowerCase().indexOf(searchText.toLowerCase()) > -1)))
      } else {
        searchedData = rawData.slice();
      }

      /*
        Filtering the Data
      */
      let filtered = searchedData;

      // date filtering
      if (selectedDateFilter > 0){
        let filterByDate = new Date();
        // set the filter by date to the number of days in the filter 
        // beoynd today
        filterByDate.setDate(filterByDate.getDate() + selectedDateFilter);

        // return the filtered dataset on the interface point date
        const filteredDateResult = filtered.filter(item => {
          return new Date(item.interfacePointDate) <  filterByDate;
        });

        filtered = filteredDateResult;
      }
      else{
        // skip filter and set to the search data
        filtered = searchedData;
      }


      // return filtered data
      return filtered;
    } else {
      return searchedData;
    }
  }

  /**
   * Handles the date filter update
   * @param {object} e 
   */
  const handleUpdateDateFilter = (e) => {
    setSelectedDateFilter(e);
  }

  /**
   * Handles text search change
   * @param {string} searchText 
   */
  const handleSearch = (searchText) => {
    setSearchText(searchText);
  }

  return (
    <>
      {isFocusAreasInterfacePointsLoading &&
        <CircularProgressBar />
      }
      {focusAreasInterfacePointsError && !isFocusAreasInterfacePointsLoading &&
        <ErrorMessage message="Unable to display optimisation focus area selection criteria" />
      }
      {!isFocusAreasInterfacePointsLoading && !focusAreasInterfacePointsError &&
        <div className="sfacc-container">
          <div className="sfacc-filter-section">
            <div className="sfacc-header-section">Interface List</div>
            <FilterToolbarFocusArea
              dateFilterOptions={filterSelectionOptions}
              dateFilterFields={dateFilterFields}
              processUpdateDateFilter={handleUpdateDateFilter}
              processSearch={handleSearch}
              labelName={'Search for Interface:'} />
          </div>
          <div className='sfacc-content-section'>
            <OptimisationFocusAreasInterfacePointSelector interfacePoints={processInterfacePoints(focusAreasInterfacePoints)} />
          </div>
        </div>
      }
    </>
  );
}

export default OptimisationFocusAreasSelectionCriteriaContainer;