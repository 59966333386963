import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import BuildFailedAnimation from '../../../../../components/Shared/atoms/BuildFailedAnimation'
import { WarningCircleIcon, CloseIcon } from '../../../../../Icons';

import './InterfacePointDrivingPathCountFlag.scss';

const InterfacePointDrivingPathCountFlag = (props) => {

  const { selectedInterfacePointNumber } = props;
  const [ divTimer, setDivTimer ] = useState();

  const closeNotification = () => {
    setDivTimer(false);
}

  /**
   * Selected interface changed
   */
   useEffect(() => {
    setDivTimer(true);
    },
     [selectedInterfacePointNumber]);

  return (
        <div className="flag-container">
          {divTimer === true &&
            <div className="pp-widgets-flag">
              <div className="flag-text">
                <div className="flag-container-driving-path">
                  <div className="flag-header-driving-path">
                    <div className="flag-icon-label-container-driving-path">
                      <span className="flag-span-warning-driving-path"></span>
                      <span className="flag-warning-icon-driving-path">
                        <WarningCircleIcon />
                      </span>
                      <p>
                      The Activities and Interfaces leading up to the selected Interface are exceeding a volume of 100. The Activities and Interfaces listed in the table view and the driving path below are the 100 nearest predecessors.                       </p>
                    </div>
                    <div className='flag-notification-close'>
                      <CloseIcon width="7.5px" height="7.5px" color="#005A84" onClick={closeNotification} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
        </div>
  );
}

export default InterfacePointDrivingPathCountFlag;