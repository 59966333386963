import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import InterfacePointEditor from '../InterfacePointEditor/InterfacePointEditor';
import { createInterfacePoint, updateInterfacePoint, removeInterfaceActivityLinks, fetchInterfaceActivityLinks } from '../../../../../redux/slices/interfacePointsSlice';

const InterfacePointEditorContainer = (props) => {
  const dispatch = useDispatch();
  const { isEditable,selectedActivityFromFullProgramme, daysPendingForCompletion, addForecastDate } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const { selectedInterfacePointId } = useSelector(state => state.interfacePoints);
  const [accessToken, setAccessToken] = useState(null);
  const { instance, accounts } = useMsal();
  const [isEditableType, setIsEditableType] = useState(false);
  const { interfacePointTypesError, isInterfacePointTypesLoading, isInterfaceActivityLinksLoading } = useSelector(state => state.interfacePoints);
  const [isReady, setIsReady] = useState(false);
 
  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  // Fetches the interface activity links
  useEffect(() => {
    if (accessToken){
      if(selectedInterfacePointId){
        dispatch(fetchInterfaceActivityLinks({projectId: selectedProjectId, interfacePointId: selectedInterfacePointId, accessToken: accessToken}));  
      }
    }
  }, [dispatch, selectedProjectId, accessToken]);

  /**
   * Sets the flag if its editable
   */
   useEffect(() => {
    if (!isInterfacePointTypesLoading && !isInterfaceActivityLinksLoading) {
      if (isEditable)
        setIsEditableType(true);
      else
        setIsEditableType(false);
    }
  }, [isInterfacePointTypesLoading, isInterfaceActivityLinksLoading]);

  /**
   * Waits for data to be ready and sets flag
   */
  useEffect(() => {
    if (!isInterfacePointTypesLoading && !isInterfaceActivityLinksLoading) {
      setIsReady(true);
      dispatch(removeInterfaceActivityLinks());
    }
  }, [isEditableType]);


  /**
   * Cancel adding interface point
   */
  const cancelAddInterfacePoint = () => {
    props.closeAddInterfacePoint();
  }

   /**
   * Cancel editing interface point
   */
    const cancelEditInterfacePoint = () => {
      props.closeEditInterfacePoint();
    }

  /**
   * Interface point creation callback
   */
  const createInterfacePointCallback = () => {
    props.closeAddInterfacePoint();
  }

  /**
   * Interface point updation callback
   */
   const updateInterfacePointCallback = () => {
    props.closeEditInterfacePoint();
  }

  /**
   * Creates a new interface point
   * @param {object} data 
   */
  const createNewInterfacePoint = (data) => {
    if (data) {
      data.projectId = selectedProjectId;
      data.status = "Active";
      dispatch(createInterfacePoint({ projectId: selectedProjectId, interfacePointObject: data, accessToken: accessToken }))
        .then(createInterfacePointCallback)
    }
  }

  /**
   * Edits an existing interface point
   * @param {object} data 
   */
   const editNewInterfacePoint = (data, selectedInterfacePointId,selectedInterfacePointStatus) => {
    if (data) {
      data.projectId = selectedProjectId;
      data.status = selectedInterfacePointStatus;
      dispatch(updateInterfacePoint({ projectId: selectedProjectId, interfacePointId: selectedInterfacePointId, interfacePointObject: data, accessToken: accessToken }))
        .then(updateInterfacePointCallback)
    }
  }

  return (
    <>
      {!isReady &&
        <CircularProgressBar />
      }
      {(interfacePointTypesError) && !isReady &&
        <ErrorMessage message="Unable to display interface editor" />
      }
      {isReady && <>
        <InterfacePointEditor selectedActivityFromFullProgramme={selectedActivityFromFullProgramme} cancelAddInterfacePoint={cancelAddInterfacePoint} createNewInterfacePoint={createNewInterfacePoint} editNewInterfacePoint={editNewInterfacePoint} cancelEditInterfacePoint={cancelEditInterfacePoint} isEditable={isEditableType} daysPendingForCompletion={daysPendingForCompletion} addForecastDate={addForecastDate}/>
      </>
      }
    </>
  );
}

export default InterfacePointEditorContainer;