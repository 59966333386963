import React, { useEffect, useState } from 'react';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import SearchToolbarScenario from '../../../../../components/Shared/molecules/SearchToolbarScenario';
import { fetchScenarios, selectAllScenarios } from '../../../../../redux/slices/scenariosSlice';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import UserGenScenarioSelector from '../../molecules/UserGenScenarioSelector/UserGenScenarioSelector';
import SystemGenScenarioSelector from '../../molecules/SystemGenScenarioSelector/SystemGenScenarioSelector';
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';
import CreateScenarioNavigation from '../../atoms/CreateScenarioNavigation';

import './ScenarioManagerContainer.scss';

const ScenarioManagerContainer = (props) => {
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(state => state.scenarios);
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { selectedScenarioId } = useSelector(state => state.scenarios);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const scenarios = useSelector(selectAllScenarios);

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
  }

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  /**
  * Fetches the scenario for the selected project
  */
  useEffect(() => {
    if (accessToken !== null) {
      dispatch(fetchScenarios({ projectId: selectedProjectId, accessToken: accessToken }));
    }
  }, [dispatch, selectedProjectId, accessToken]);

     /**
   * Processes and filters the raw scenario data
   * and if no raw scenario data searched blank array is passed
   * @param {object} rawData
   * @returns 
   */
  const processScenarios = (rawData) => {
    let searchedData = [];
    if (rawData.length > 0) {
      if (searchValue && searchValue.length > 0) {
        searchedData = rawData.slice().filter((d) => d.scenarioName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
      } else {
        searchedData = rawData.slice();
      }
      return searchedData;
    } else {
      return searchedData;
    }
  }
 
  return (
    <>
      {isLoading &&
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to display design scenario manager" />
      }
      {!isLoading &&
        <>
          <div className="scenario-manager-panel-top">
            <div className="scenario-header-section">Scenario Manager</div>
            <SearchToolbarScenario
              processSearch={handleSearch}
              labelName={'Search Scenario For:'}
            />
          </div>
          <div className="scenario-manager-container">
            <div className='scenario-content-section'>
              <SystemGenScenarioSelector scenarios={scenarios} selectedProject={selectedProject} selectedScenarioId={selectedScenarioId} />
              <UserGenScenarioSelector scenarios={processScenarios(scenarios)} selectedProject={selectedProject} selectedScenarioId={selectedScenarioId} />
              <CreateScenarioNavigation />
            </div>
          </div>
        </>
      }
    </>
  );
}

export default ScenarioManagerContainer;