import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Popover } from '@material-ui/core';
import {
  AccordionComponent, AccordionItemDirective, AccordionItemsDirective
} from '@syncfusion/ej2-react-navigations';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { appInsights } from '../../../../../AppInsights';
import { AlertIcon, ChevronDownIcon, ChevronUpIcon } from '../../../../../Icons';
import { setSelectedAlertIdInManager } from '../../../../../redux/slices/alertSelectionSlice';
import { setSelectedAlertId } from '../../../../../redux/slices/alertsSlice';
import { fetchPhaseProgress } from '../../../../../redux/slices/phaseProgressSlice';
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';
import { LinkLighthouseButton2, PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import PhaseProgessChartContainer from '../../../../ProjectAlertsPage/components/molecules/PhaseProgessChartContainer/PhaseProgessChartContainer';
import Alert from '../Alert';
import './AlertListAccordian.scss';

let trackData = require('../../../../../appTrackerInfo.json');

const AlertListAccordian = React.memo(props => {
  const { alertData = [], selectedAlert, isCriticalAlerts = false } = props;
  const alertsAccordianComponentRef = React.createRef();
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const [alertsToDisplay, setAlertsToDisplay] = useState(5);

  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  const [phaseProgressChartVisibility, setPhaseProgressChartVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    /**
 * Acquire access token
 */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);



  /** close phase progess chart graph pop up */
  const closePhaseProgressChart = () => {
    setAnchorEl(null);
    setPhaseProgressChartVisibility(false);
  }

  /** sets the selected alerts in redux store */
  const handleAlertsClick = (args) => {
    if (args.item) {
      let clickedAlertItem = args.item.alertData;

      /*Tracking click on alert list's alerts*/
      appInsights.trackEvent({
        name: `DPM - Alert List - ${clickedAlertItem.alertType.charAt(0).toUpperCase() + clickedAlertItem.alertType.slice(1)} Alert Click`,
        properties: {
          Page: `${trackData.pages[1].name}`,
          Alert: `${clickedAlertItem.alertId}`,
          Type: `${clickedAlertItem.alertType.charAt(0).toUpperCase() + clickedAlertItem.alertType.slice(1)}`,
          Project: `${selectedProjectId}`,
          ProjectName: `${selectedProject.projectName}`,
          User: `${accounts[0].username}`,
          Username: `${accounts[0].name}`,
          Role: 'Owner'
        }
      });

        /* set selected alert id */
      dispatch(setSelectedAlertId(clickedAlertItem.alertId));
    }
  };

  /** The alert header template */
  const alertHeader = (alert) => {
    return <>
      <div className="al-alert-header">
        {isCriticalAlerts
          ? <span className="alert-icon-critical">
            <AlertIcon className="alert-icon" />
          </span>
          : <span className="alert-icon-others">
            <AlertIcon className="alert-icon" />
          </span>
        }
        <div className="alert-title">
          {alert.alertName}
        </div>
      </div>
    </>;
  }

  /** The alert content template */
  const alertContent = (alert) => {
    /**
   * Opens resource allocation chart
   * @param {object} event 
   */
    const handleOpenPhaseProgressChart = (event) => {
      dispatch(fetchPhaseProgress({ projectId: selectedProjectId, alertId: alert.alertId, accessToken: accessToken }))
      setAnchorEl(event.currentTarget);
      setPhaseProgressChartVisibility(true);
    }

    const ViewMoreClick = () => {
      dispatch(setSelectedAlertIdInManager(alert.alertId));
    }

    return (
      <>
        <div>
          <Alert alert={alert} currencyCode={selectedProject.currencyCode} />
        </div>
        <div className="alert-actions">
          {alert.alertType.toLowerCase() === "primary" &&
            <SecondaryLighthouseButton style={{ width: "158px", fontWeight: 400, marginRight: "10px" }} onClick={handleOpenPhaseProgressChart}>View Graph</SecondaryLighthouseButton>
          }

          <PrimaryLighthouseButton style={{ width: "158px", fontWeight: 400 }} onClick={ViewMoreClick} component={Link} to={`/${selectedProjectId}/alerts`}>View More</PrimaryLighthouseButton>
        </div>

        {/* <div className="alert-actions">
          <AuthorizedComponent value="dismiss-alert" projectId = {selectedProjectId} disciplineId = {alert.disciplineId}>
            <SecondaryLighthouseButton style={{ width: "158px", marginRight: "10px", fontWeight: 400 }} onClick={dismissAlertWizard}>Dismiss Alert</SecondaryLighthouseButton>
          </AuthorizedComponent>

          {alert.alertType.toLowerCase() === "primary" && alert.scenarioId &&
            <PrimaryLighthouseButton style={{ width: "158px", fontWeight: 400 }} component={Link} onClick={CompareOptionClick} to={`/${selectedProjectId}/scenarios/${alert.scenarioId}/compareoptions`}>Compare Options</PrimaryLighthouseButton>
          }
          {alert.alertType.toLowerCase() === "primary" && !alert.scenarioId &&
            <PrimaryLighthouseButton style={{ width: "158px", fontWeight: 400 }} component={Link} onClick={CompareOptionClick} to={`/${selectedProjectId}/scenarios/createalertscenario/${alert.alertId}`}>Compare Options</PrimaryLighthouseButton>
          }
        </div> */}
      </>
    )
  }

  useEffect(() => {
    // when the selected alert is changed
    // check if the alert is displayed currently
    // and if not then expand the list
    if (selectedAlert) {
      alertData.forEach((alert, index) => {
        if (alert.alertId === selectedAlert.alertId) {
          if (index > alertsToDisplay) {
            setAlertsToDisplay(alertData.length);
          }
        }
      })
    }
  }, [selectedAlert])

  /** Accordian animation settings */
  const animationSettings =
  {
    expand: { effect: 'None', duration: 0, easing: 'linear' },
    collapse: { effect: 'None', duration: 0, easing: 'linear' }
  };

  /** Handles accordian item expanded
   * scrolls to the location of the expanded item
   */
  const alertAccordianExpaned = (args) => {
    let alertRef = args.content;

    // need set timeout 
    // for this to work in React
    setTimeout(function () {
      alertRef.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'center',
        }
      );
    }, 100);
  }

  /**
   * More button clicked
   * expand list
   */
  const moreButtonClick = () => {
    setAlertsToDisplay(alertData.length);
  }

  /**
   * Less button clicked
   * collapse list
   */
  const lessButtonClick = () => {
    setAlertsToDisplay(5);
  }

  return (
    <div className="alert-accordian-container">
      <AccordionComponent expandMode='Single'
        clicked={handleAlertsClick}
        ref={alertsAccordianComponentRef}
        animation={animationSettings}
        expanded={alertAccordianExpaned}>
        <AccordionItemsDirective>
          {
            alertData.slice(0, alertsToDisplay).map((alert, i) => {
              let isExpanded = false;
              if (selectedAlert) {
                isExpanded = selectedAlert.alertId === alert.alertId ? true : false;
              }
              return (
                <AccordionItemDirective header={() => alertHeader(alert)}
                  content={() => alertContent(alert)}
                  iconCss='e-athletics e-acrdn-icons'
                  cssClass="acc-item component-shadow"
                  key={alert.alertId}
                  id={alert.alertId}
                  alertData={alert}
                  expanded={isExpanded}
                />
              )
            })
          }
        </AccordionItemsDirective>
      </AccordionComponent>

      <div className="more-button">
        {alertData.length > 5 && alertsToDisplay !== alertData.length &&
          <LinkLighthouseButton2 onClick={moreButtonClick}>
            Show More
            <ChevronDownIcon style={{ marginLeft: "10px" }} />
          </LinkLighthouseButton2>
        }
        {alertData.length > 5 && alertsToDisplay == alertData.length &&
          <LinkLighthouseButton2 onClick={lessButtonClick}>
            Show Less
            <ChevronUpIcon style={{ marginLeft: "10px" }} />
          </LinkLighthouseButton2>
        }
      </div>
      <Popover
        id='phase-progress-popover'
        open={phaseProgressChartVisibility}
        anchorEl={anchorEl}
        anchorReference='none'
        BackdropProps={{ invisible: false }}
        onClose={closePhaseProgressChart}
      >
        <div className='phase-progress-chart-popover'>
          <PhaseProgessChartContainer alert={selectedAlert} selectedProjectId={selectedProjectId} closePhaseProgressChart={closePhaseProgressChart} />
        </div>
      </Popover>
    </div>
  );
})

export default AlertListAccordian;