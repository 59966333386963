import React, { useEffect, useState } from 'react';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { fetchScenario, selectScenarioById } from '../../../../../redux/slices/scenariosSlice';
import { fetchScenarioOptions, selectAllScenarioOptions } from '../../../../../redux/slices/scenarioOptionsSlice';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import ScenarioOptionsComparison from '../ScenarioOptionsComparison';

const ScenarioOptionsComparisonContainer = (props) => {
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(state => state.scenarioOptions);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const { selectedScenarioId } = useSelector(state => state.scenarios);
  const scenarioOptions = useSelector((state) => selectAllScenarioOptions(state, selectedScenarioId));
  const scenario = useSelector((state) => selectScenarioById(state, selectedScenarioId));

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

   /**
   * Fetches the scenario and options for the selected scenario
   */
  useEffect(() => {
    if (accessToken && selectedScenarioId){
      dispatch(fetchScenario({ projectId: selectedProjectId, scenarioId: selectedScenarioId, accessToken: accessToken}));
      dispatch(fetchScenarioOptions({ projectId: selectedProjectId, scenarioId: selectedScenarioId, accessToken: accessToken}));
      //dispatch(setSelectedScenarioOptionId(null))
    }
  }, [dispatch, selectedProjectId, selectedScenarioId, accessToken]);

  return (
    <>
     { isLoading && 
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to display scenario option comparison" />
      }
      { !isLoading && !error && scenarioOptions.length > 0 &&
        <>
          <ScenarioOptionsComparison scenario={scenario}  options={scenarioOptions} />
        </>
      }
    </>
  );
}

export default ScenarioOptionsComparisonContainer;