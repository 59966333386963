import React, { useEffect, useState } from 'react';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { WarningCircleIcon, CalenderIcon, TimeIcon, RefreshIcon, VersionIcon } from '../../../../../Icons';
import { fetchProjectSourceDetails} from '../../../../../redux/slices/projectSettingsSlice';
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';
import { getFormattedDate } from '../../../../../utils';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import DataSourceStatus from '../../atoms/DataSourceStatus';
import './DataConnectionSettings.scss';

const DataConnectionSettings = ()=> {
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { isLoading, error, projectSources } = useSelector(state => state.projectSettings);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);


  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  useEffect(() =>{
    if (accessToken){
      dispatch(fetchProjectSourceDetails({ projectId: selectedProjectId, accessToken: accessToken}));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  return (
        <>
       <div className='data-connection-container'>
             <div className='data-connection-content'>
              {isLoading &&
              <CircularProgressBar />
              }
              {error && !isLoading &&
              <ErrorMessage message="No Data found for data connections." />
              }
               {!isLoading && !error && projectSources &&
                     <>
                        <div className='source-refresh'>
                        <div className='source-title'>
                               {selectedProject?.projectName}
                            </div>
                            <div className='source-details'>
                                <span ><RefreshIcon className='icon'/>  Last Refresh: <b> 
                                  {getFormattedDate(projectSources?.appLoad_IngestedDate)}</b></span>
                            </div>
                        </div>
                        <hr />
                        <div className='source-refresh'>
                            <div className='source-title'>
                               % Complete
                               &nbsp;&nbsp;
                               <InfoTooltip message={'Expected to be updated weekly. '}  iconSize={14} width={500}/>
                            </div>
                            <div className='source-details'>
                                <div>
                                    {projectSources?.percentCompleteUpdatedDate && 
                                      <span ><RefreshIcon className='icon'/>Last Update:<b> {getFormattedDate(projectSources?.percentCompleteUpdatedDate)}</b></span>
                                    }
                                {!projectSources?.percentCompleteUpdatedDate &&
                                <>
                                  <span className='status-icon-text' ><WarningCircleIcon className='icon-warning' /><b>Data unavailable</b></span> 
                                </>
                                }
                                </div>
                                <div >
                                   <DataSourceStatus updateDate={projectSources?.percentCompleteUpdatedDate} Status={projectSources?.percentCompleteDataRefresh} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='source-refresh'>
                            <div className='source-title'>
                               Alerts
                               &nbsp;&nbsp;
                               <InfoTooltip message={'Expected to be updated weekly. '}  iconSize={14} width={500}/>
                            </div>
                            <div className='source-details'>
                                <div>
                                    {projectSources?.alertsUpdatedDate && 
                                      <span ><RefreshIcon className='icon'/>Last Update:<b> {getFormattedDate(projectSources?.alertsUpdatedDate)}</b></span>
                                    }
                                 {!projectSources?.alertsUpdatedDate &&
                                   <>
                                     <span className='status-icon-text' ><WarningCircleIcon className='icon-warning' /><b>Data unavailable</b></span> 
                                   </>
                                }
                                </div>
                                <div >
                                  <DataSourceStatus updateDate={projectSources?.alertsUpdatedDate} Status={projectSources?.alertsDataRefresh} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='source-refresh'>
                            <div className='source-title'>
                               Optimisation
                               &nbsp;&nbsp;
                               <InfoTooltip message={'Expected to be updated weekly. '}  iconSize={14} width={500}/>
                            </div>
                            <div className='source-details'>
                                <div>
                                    {projectSources?.optimisationUpdatedDate && 
                                      <span ><RefreshIcon className='icon'/>Last Update:<b> {getFormattedDate(projectSources?.optimisationUpdatedDate)}</b></span>
                                    }
                                   {!projectSources?.optimisationUpdatedDate &&
                                   <>
                                     <span className='status-icon-text' ><WarningCircleIcon className='icon-warning' /><b>Data unavailable</b></span>  
                                   </>
                                }
                                </div>
                                <div >
                                  <DataSourceStatus updateDate={projectSources?.optimisationUpdatedDate} Status={projectSources?.optimisationDataRefresh} />
                                </div>
                            </div>
                        </div>
                         <hr />
                        <div className='source-refresh'>
                            <div className='source-title'>
                               Timesheet Data - JDE &nbsp;&nbsp;
                               <InfoTooltip message={'Expected to be reingested into Lighthouse weekly. '}  iconSize={14} width={500}/>
                            </div>
                            <div className='source-details'>
                                <div>
                                {projectSources?.jdE_IngestedDate && 
                                  <span ><CalenderIcon className='icon'/> Ingested Date : <b>
                                    {getFormattedDate(projectSources?.jdE_IngestedDate)}</b></span> 
                                 }
                                {projectSources?.jdE_MaxGLDate && 
                                  <span ><CalenderIcon className='icon'/> General Ledger Date : <b>
                                    {getFormattedDate(projectSources?.jdE_MaxGLDate)}</b></span>  
                                } 
                              {!projectSources?.jdE_IngestedDate && !projectSources?.jdE_MaxGLDate &&
                                <>
                                  <span className='status-icon-text' ><WarningCircleIcon className='icon-warning' /><b>Data unavailable</b></span> 
                                </>
                                }   
                                </div>
                                <div>
                                    <DataSourceStatus updateDate={projectSources?.jdE_IngestedDate} Status={projectSources?.jdE_Refresh_Status} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='source-refresh'>
                            <div className='source-title'>
                               Schedule Data - P6
                               &nbsp;&nbsp;
                               <InfoTooltip message={'Expected to be reingested into Lighthouse twice monthly. Planner to provide an updated XER when updates have been made. '}  iconSize={14} width={500}/>
                            </div>
                            <div className='source-details'>
                                <div >
                                {projectSources?.p6_IngestedDate && 
                                   <span ><CalenderIcon className='icon'/>  Ingested Date : <b> 
                                     {getFormattedDate(projectSources?.p6_IngestedDate)}
                                     </b></span>  
                                }
                                {projectSources?.p6_VersionId && 
                                <>
                                   <p></p>
                                  <span ><VersionIcon className='icon'/>  Version : <b> {projectSources?.p6_VersionId.toString()}</b></span> 
                                </>
                                }    
                                {!projectSources?.p6_VersionId && !projectSources?.p6_IngestedDate &&
                                <>
                                 <span className='status-icon-text' ><WarningCircleIcon className='icon-warning' /><b>Data unavailable</b></span> 
                                </>
                                }    
                                </div>
                                <div >
                                    <DataSourceStatus updateDate={projectSources?.p6_IngestedDate} Status={projectSources?.p6_Refresh_Status} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='source-refresh'>
                            <div className='source-title'>
                               Deliverable Data - MIDP Online
                               &nbsp;&nbsp;
                               <InfoTooltip message={'Expected to be reingested into Lighthouse weekly. Information Manager to ensure MIDP Online is synced with ProjectWise prior. '}  iconSize={14} width={500}/>
                            </div>
                            <div className='source-details'>
                                <div>
                                {projectSources?.midP_IngestedDate && 
                                   <span ><CalenderIcon className='icon'/>  Ingested Date : <b> {getFormattedDate(projectSources?.midP_IngestedDate)}</b></span>
                                 }
                                 {projectSources?.midP_Snapshot && 
                                  <span ><TimeIcon className='icon'/>  Snapshot Date : <b> {getFormattedDate(projectSources?.midP_Snapshot)}</b></span> 
                                 }
                                {/* {projectSources?.midP_SyncDate && 
                                  <>
                                   <p></p>
                                      <span ><TimeIcon className='icon'/>  Project Wise Last Sync : <b> {getFormattedDate(projectSources?.midP_SyncDate)}</b></span>
                                  </>
                                 } */}
                                 {!projectSources?.midP_IngestedDate && !projectSources?.midP_Snapshot && 
                                <>
                                  <span className='status-icon-text' ><WarningCircleIcon className='icon-warning' /><b>Data unavailable</b></span> 
                                </>
                                }
                                </div>
                                <div >
                                   <DataSourceStatus updateDate={projectSources?.midP_IngestedDate} Status={projectSources?.midP_Refresh_Status} />
                                </div>
                            </div>
                        </div>
                      </>
                     }
             </div>
        </div>
       <div className='data-connection-footer'>
       </div>
       </>
  )
}

export default DataConnectionSettings;