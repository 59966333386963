import { 
    createEntityAdapter, 
    createSlice,
    createAsyncThunk
  } from '@reduxjs/toolkit';
  import { api } from '../../api';
  import { activityOptimisationChangeEndpoint, disciplineImpactEndpoint } from '../../api/apiRoutes';
  
  export const fetchActivityOptimisationChanges = createAsyncThunk(
    'scenarioOption/fetchactivityOptimisationChanges', async (params, thunkAPI) => {
      try{
        const response = await api.get(activityOptimisationChangeEndpoint(params.projectId, params.scenarioOptionId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      }
      catch (error){
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const fetchDisciplineImpact = createAsyncThunk(
    'scenarioOption/fetchDisciplineImpact', async (params, thunkAPI) => {
      try{
        const response = await api.get(disciplineImpactEndpoint(params.projectId, params.scenarioOptionId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      }
      catch (error){
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  
  /**
   * Create activity optimisation change adapter
   */
   const activityOptimisationChangeAdapter = createEntityAdapter({
    selectId: (activityOptimisationChange) => activityOptimisationChange.disciplineId,
  })

    
  /**
   * Create activity optimisation change slice / reducers
   */
  const activityOptimisationChangeSlice = createSlice({
    name: 'activities',
    initialState: activityOptimisationChangeAdapter.getInitialState( {  isLoading: false,
                                                        error: undefined
                                                     } ),
    reducers: {
  
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchActivityOptimisationChanges.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(fetchActivityOptimisationChanges.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          activityOptimisationChangeAdapter.setAll(state, action.payload.disciplines);
        })
        .addCase(fetchActivityOptimisationChanges.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })

        .addCase(fetchDisciplineImpact.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
          state.disciplineImpact = undefined;

        })
        .addCase(fetchDisciplineImpact.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          state.disciplineImpact = action.payload;
        })
        .addCase(fetchDisciplineImpact.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
    }
  });
  
  /**
   *  Activity optimisation changes selectors
  */
   export const {
    selectById: selectActivityOptimisationChangeById,
  } = activityOptimisationChangeAdapter.getSelectors((state) => state.activityOptimisationChange);
  
  export const selectAllActivityOptimisationChanges = (state) => {
    const activities = state.activityOptimisationChange;
    const allActivityOptimisationChanges = activityOptimisationChangeAdapter
          .getSelectors((state) => activities)
          .selectAll();
    return allActivityOptimisationChanges;
  }
  
  /**
   * Activity optimisation changes actions
   */
  export const {  
                } = activityOptimisationChangeSlice.actions;
  
  /**
   * Export reducer
   */
  export default activityOptimisationChangeSlice.reducer;