import React, { useState, useEffect } from 'react';
import dagre from 'dagre';
import ReactFlow, {
  Background, Controls, isEdge, isNode, ReactFlowProvider
} from 'react-flow-renderer';
import { useSelector } from "react-redux";
import ActivityNode from '../../molecules/ActivityNode';
import InterfaceNode from '../../molecules/InterfaceNode';
import LagFloatEdge from '../../molecules/LagFloatEdge';

import './InterfacePointDrivingPathDiagram.scss';

/**
 * Node types
 */
 const nodeTypes = {
  activity: ActivityNode,
  interface: InterfaceNode,
};

/**
 * Edge types
 */
 const edgeTypes = {
  edge: LagFloatEdge
};

/** Set width and height of nodes */
var nodeWidth = 300;
const nodeHeight = 100;

/**
 * Gets layouted items from raw elements
 * @param {json} rawElements
 * @param {string} direction
 * @returns 
 */
const getLayoutedElements = (rawElements, focusAreaNodeFilterIds, direction = 'LR') => {
  /** Initialise auto layout libray */
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === 'LR';

  dagreGraph.setGraph({
    rankdir: direction,
    align: 'DR',
    ranker: 'longest-path',
    nodesep: 80,
    edgesep: 10,
    ranksep: 100,
  });

  let filteredRawElements = rawElements;
  
  if (focusAreaNodeFilterIds && focusAreaNodeFilterIds.length){
    filteredRawElements = rawElements.filter(e => focusAreaNodeFilterIds.includes(e.id) 
                                                  || focusAreaNodeFilterIds.includes(e.source) 
                                                  || focusAreaNodeFilterIds.includes(e.target));
  }

  filteredRawElements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id,
        {
          width: nodeWidth,
          height: nodeHeight
        }
      );
    } else {
      dagreGraph.setEdge(el.source, el.target,
        {
          minlen: 4,
        }
      );
    }
  });

  dagre.layout(dagreGraph);

  return filteredRawElements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // unfortunately we need this little hack to pass a slighltiy different position
      // to notify react flow about the change. More over we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWithPosition.width / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeWithPosition.height / 2,
      };
    }

    if (isEdge(el)) {
      if (!el.type) {
        el.type = 'edge';
      }
      el.arrowHeadType = 'arrow';
    }

    return el;
  });
};

const InterfacePointDrivingPathDiagram = (props) => {
  const { drivingPathData = [] } = props;
  const { selectedFocusAreaNodeId, focusAreaNodeFilterIds } = useSelector(state => state.optimisationFocusAreas);
  const initialLayoutedElements = getLayoutedElements(drivingPathData, focusAreaNodeFilterIds, 'LR');
  const [elements, setElements] = useState(initialLayoutedElements);
  const [reactFlowInstance, setReactFlowInstance] = useState();

  useEffect(() =>{
    if (focusAreaNodeFilterIds && focusAreaNodeFilterIds.length){
      setElements(getLayoutedElements(drivingPathData, focusAreaNodeFilterIds, 'LR'));
    }
    else{
      setElements(getLayoutedElements(drivingPathData, [], 'LR'));
    }
  }, [focusAreaNodeFilterIds]);

  useEffect(() =>{
    reactFlowInstance?.fitView({ padding: 0.1 });
  }, [elements]);

  const onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView({ padding: 0.1 });
    setReactFlowInstance(reactFlowInstance);
  };

  return (
    <div className="driving-path-diagram">
      <ReactFlowProvider>
        <ReactFlow
          elements={elements}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          defaultPosition={[0, 0]}
          onlyRenderVisibleElements={true}
          minZoom={0}
          draggable={false}
          panOnScroll={false}
          zoomOnScroll={true}
          panOnScrollMode='free'
          elementsSelectable={true}
          nodesDraggable={false}
          onLoad={onLoad}
        />
        <Background color="#FAFCFF" />
        <Controls showInteractive={false} className="dpd-controls">
        </Controls>
      </ReactFlowProvider>
    </div>
  );
}

export default InterfacePointDrivingPathDiagram;