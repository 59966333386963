import React from 'react';
import InfoTooltip from '../../molecules/InfoTooltip';
import './PageHeader.scss';

const PageHeader = (props) => {
  const { projectName, pageTitle, pageDescription, tooltip, Icon, margin = "60" } = props;

  return (
    <>
    <div className="page-header" style={{ margin: `0px ${margin}px`  }}>
      <div className="page-title">
        {Icon && <span className="icon"><Icon /></span>}
           <span className="title">{pageTitle}</span>
        {tooltip && <><span className="tooltip"><InfoTooltip message={tooltip}  iconSize={20} width="none"/></span></>}
      </div>
      <div className="page-actions">
        {props.children}
      </div>
    </div>
    {pageDescription &&
      <div className="page-description" style={{ margin: `0px ${margin}px`  }}>
        {pageDescription}
      </div>
    }
    </>
  )
};

export default PageHeader;