import React from 'react';
import { getSmoothStepPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer';

const Edge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
}) => {
  const edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, borderRadius: 0 });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({ sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition });
  const markerEndId = "alert-arrowhead-" + id;
  const markerEndUrl = "url(#" + markerEndId + ")";
  let markerColour = style.stroke;
  let edgeStyle = style;

  if (data.isHidden){
    markerColour = "#FFFFFF";
    edgeStyle = { stroke: '#FFFFFF' };
  }
  else if (data.isFiltered){
    markerColour = "#CCCCCC";
    edgeStyle = { stroke: '#CCCCCC' };
  }
  else if (data.hasAlert){
    markerColour = "#E24255";
    edgeStyle = { stroke: '#E24255', strokeWidth: '2' };
  }

  return (
    <>
      <marker id={markerEndId} markerWidth="20" markerHeight="20" viewBox="-10 -10 20 20" orient="auto" refX="0" refY="0">
        <polyline stroke={markerColour} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" fill="none" points="-5,-4 0,0 -5,4"></polyline>
      </marker>
      <path
        id={id}
        style={edgeStyle}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEndUrl}
      />  
      
    </>
  )
};

export default React.memo(Edge);