import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { allUsersEndPoint, appRolesEndPoint, addUserAppRoleEndPoint, editUserEndPoint } from '../../api/apiRoutes';

export const fetchSearchedAdUsers = createAsyncThunk(
  'user/fetchallusers', async (params, thunkAPI) => {
    try{
      const response = await api.get(allUsersEndPoint(params.projectId, params.user), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchAppRoles = createAsyncThunk(
  'user/fetchAppRoles', async (params, thunkAPI) => {
    try{
      const response = await api.get(appRolesEndPoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addUserRoles = createAsyncThunk(
  'user/adduserroles', async (params, thunkAPI) => {
    try{
      const response = await api.post(addUserAppRoleEndPoint(params.projectId),params.userRoles, { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const editUserRoles = createAsyncThunk(
  'user/edituserroles', async (params, thunkAPI) => {
    try{
      const response = await api.post(editUserEndPoint(params.projectId),params.userRoles, { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


/**
 * Create deliverables slice / reducers
 */
const usersAdSlice = createSlice({
  name: 'usersad',
  initialState:{      UsersAd: [], 
                      appRoles: [],
                      selectedUser: undefined,
                      status: undefined,
                      error: undefined,
                      isLoading: false,
                      searchLoading: false,
                      searchError: undefined
                } ,
  reducers: {
    setSearchUsersList: (state, action) => {
      state.UsersAd = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchedAdUsers.pending, (state, action) => {
        state.searchLoading = true;
        state.searchError = undefined;
      })
      .addCase(fetchSearchedAdUsers.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.searchError = undefined;
        state.UsersAd = action.payload;
      })
      .addCase(fetchSearchedAdUsers.rejected, (state, action) => {
        state.searchLoading = false;
        if (action.payload){
          state.searchError = action.payload.error;
        }
        else{
          state.searchError = action.error.message;
        }
      })
      .addCase(fetchAppRoles.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchAppRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.appRoles = action.payload;
      })
      .addCase(fetchAppRoles.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(addUserRoles.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(addUserRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(addUserRoles.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(editUserRoles.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(editUserRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(editUserRoles.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});


/**
 * UserAD actions
 */
 export const {  setSearchUsersList , setStatus, setSelectedUser } = usersAdSlice.actions;

/**
 * Export reducer
 */
export default usersAdSlice.reducer;