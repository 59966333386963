import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import LighthouseDialog from '../../../../../components/Shared/organisms/LighthouseDialog';
import { PrimaryLighthouseButton, LinkLighthouseButton2 } from '../../../../../theme/Buttons';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { WarningCircleIcon } from '../../../../../Icons';
import { fetchInterfacePoints, fetchInterfacePointTypes, selectAllInterfacePoints } from '../../../../../redux/slices/interfacePointsSlice';
import './AddInterfacePoint.scss';
import CreateInterfacePoint from '../CreateInterfacePoint/CreateInterfacePoint';

const AddInterfacePoint = (props) => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  const { selectedProjectId } = useSelector(state => state.projects);
  const interfacePoints = useSelector((state) => selectAllInterfacePoints(state, selectedProjectId));
  const { isInterfacePointsLoading, interfacePointTypesError, isInterfacePointTypesLoading } = useSelector(state => state.interfacePoints);
  const { interfacePointTypes } = useSelector(state => state.interfacePoints);

  const [createInterfacePointVisibility, setCreateInterfacePointVisibility] = useState(false);
  const [ mandatoryInterfacePointTypes, setMandatoryInterfacePointTypes] = useState([]);
  const [ disableAddInterfacePoint, setDisableAddInterfacePoint] = useState(false);
  const [ processInterfaceTypes, setProcessInterfaceTypes] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  /**
  * Fetches the interface point for the current project
  */
  useEffect(() => {
    if (accessToken && selectedProjectId) {
      dispatch(fetchInterfacePointTypes({ projectId: selectedProjectId, accessToken: accessToken }));
      setIsFetched(true);
    }
  }, [dispatch, selectedProjectId, accessToken]);

  /**
   * Waits for data to be ready and sets flag
   */
   useEffect(() => {
    if (!isInterfacePointsLoading && !isInterfacePointTypesLoading && isFetched) {
      setIsReady(true);
    }
  }, [isFetched, interfacePoints, isInterfacePointsLoading, isInterfacePointTypesLoading]);

  /**
   * Sets Mandatory interfaces
   */
  useEffect(() => {
    if (isReady && interfacePointTypes && interfacePointTypes.length > 0){
      setMandatoryInterfacePointTypes(interfacePointTypes.filter(t=>t.isMandatory == true));
    }
  }, [isReady, interfacePoints]);

  /**
   * Processing interface types & setting the notification if required
   */
  useEffect(() => {
    if (isReady && !isInterfacePointsLoading && interfacePointTypes && interfacePointTypes.length > 0 && interfacePointTypes && interfacePointTypes.length > 0){
      if(mandatoryInterfacePointTypes.length > 0){
        interfacePointTypes.forEach((t)=>{
          if(mandatoryInterfacePointTypes.some(m=>m.id == t.id)){
            if(!interfacePoints.some(i=>i.projectInterfacePointTypeId == t.id)){
              //Setting the add interface point button disabled
              setDisableAddInterfacePoint(true);
            }
            else{
              setDisableAddInterfacePoint(false);
            }
          }
        })
      } 
      setProcessInterfaceTypes(true);
    }
  }, [mandatoryInterfacePointTypes]);


  /**
  * Handles the add new interface point click
  */
  const addNewInterfacePointClick = () => {
    setCreateInterfacePointVisibility(true);
  }

  /**
  * Handles the close button click
  */
  const closeCreateInterface = () =>{
    setCreateInterfacePointVisibility(false);
  };

  return (
    <>
    {!isReady &&
      <CircularProgressBar />
    }
    
    {(interfacePointTypesError) && !isReady &&
      <ErrorMessage message="Unable to display interface editor" />
    }
      
    {isReady && processInterfaceTypes &&
      <>
        {!disableAddInterfacePoint &&
          <div className='add-new-interface'>
            <PrimaryLighthouseButton disabled={disableAddInterfacePoint} onClick={addNewInterfacePointClick}>+ Add new interface</PrimaryLighthouseButton>
          </div>
        }
        {disableAddInterfacePoint &&
        <>
          <div className="header-section">Interface List</div>
          <div className='add-new-mandatory-interface'>
            <div className="toast-container-mandatory-type">
              <div className="toast-icon-label-container-mandatory-type">
                <span className="span-warning-mandatory-type"></span>
                <span className="toast-warning-icon-mandatory-type">
                  <WarningCircleIcon />
                </span>
                <p>You need to create the<LinkLighthouseButton2 className='link-mandatory' onClick={addNewInterfacePointClick}>+ Mandatory interface</LinkLighthouseButton2>before you can add any other types of interfaces.</p>
              </div>
            </div>
          </div>
        </>
        }
        {createInterfacePointVisibility &&
          <LighthouseDialog isVisible={createInterfacePointVisibility} closeButton={true} closeButtonAction={closeCreateInterface} title="Create a New Interface" width="63%" height="800px">
            <CreateInterfacePoint closeCreateInterface={closeCreateInterface} />
          </LighthouseDialog>
        }
      </>
    }
    </>
  );
}

export default AddInterfacePoint;