import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ProjectSelectorContainer from '../../molecules/ProjectSelectorContainer';
import sidebarBg from './sidebar-bg.svg';
import { useSelector } from 'react-redux';
import { ReactComponent as ProjectOverViewIcon } from '../../../../Icons/project-overview-icon.svg';
import { ReactComponent as DPMIcon } from '../../../../Icons/production-icon.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../../Icons/external-link-icon.svg';
import UserDetails from '../../molecules/UserDetails';
import { IconButton } from '@material-ui/core';
import { ReactComponent as ScenariosIcon } from '../../../../Icons/scenarios-icon.svg';
import { ReactComponent as AlertsIcon } from '../../../../Icons/alerts-icon.svg';
import { InterfacePointIcon } from '../../../../Icons';
import AuthorizedComponent from '../../molecules/AuthorizedComponents'
import SncLavalinLogo from '../../molecules/SncLavalinLogo/SncLavalinLogo';
import Support from '../../molecules/Support';
import { LighthouseTooltip } from '../../../../utils';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../../Icons';
import { setSideBarOpen } from '../../../../redux/slices/projectsSlice'
import './Sidebar.scss';

const Sidebar = (props) => {
  const location = useLocation();
  const { selectedProjectId } = useSelector(state => state.projects);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const dispatch = useDispatch();

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    dispatch(setSideBarOpen(!sidebarOpen));
  }

  return (
    <div className="sidebar-wrapper" style={{ backgroundImage: `url(${sidebarBg})` }}>
      <ProSidebar collapsed={!sidebarOpen}>
        <SidebarHeader>
          <div className={sidebarOpen ? "sidebar-header" : "sidebar-header-collapse"}>
            <SncLavalinLogo collapsed={sidebarOpen} />
          </div>
        </SidebarHeader>
        <SidebarContent>
          <div className={sidebarOpen ? "sb-component-container" : "sb-component-container-collapse"}>
            <ProjectSelectorContainer collapsed={!sidebarOpen} />
          </div>
          {selectedProjectId && <Menu className="main-menu">
            <LighthouseTooltip content="Project Home" isActive={!sidebarOpen}>
              <MenuItem icon={<ProjectOverViewIcon />} active={location.pathname === `/${selectedProjectId}/home` ? true : false} className="mm-item">
                Project Home
                <Link to={`/${selectedProjectId}/home`} />
              </MenuItem>
            </LighthouseTooltip>
            <LighthouseTooltip content="Production" isActive={!sidebarOpen}>
              <MenuItem active={location.pathname === `/${selectedProjectId}/designstatus` ? true : false} icon={<DPMIcon />} className="mm-item">
                Production
                <Link to={`/${selectedProjectId}/designstatus`} />
              </MenuItem>
            </LighthouseTooltip>
            <LighthouseTooltip content="Alerts" isActive={!sidebarOpen}>
              <MenuItem active={location.pathname === `/${selectedProjectId}/alerts` ? true : false} icon={<AlertsIcon />} className="mm-item">
                Alerts
                <Link to={`/${selectedProjectId}/alerts`} />
              </MenuItem>
            </LighthouseTooltip>
            <LighthouseTooltip content="Scenarios" isActive={!sidebarOpen}>
              <MenuItem active={location.pathname.includes('scenarios') || location.pathname.includes('optimisation') ? true : false} icon={<ScenariosIcon />} className="mm-item">
                Optimisation
                <Link to={`/${selectedProjectId}/scenarios`} />
              </MenuItem>
            </LighthouseTooltip>
            {process.env.REACT_APP_ENABLE_INTERFACE_POINTS === 'true' &&
            <LighthouseTooltip content="InterfacePoints" isActive={!sidebarOpen}>
              <MenuItem active={location.pathname.includes('interfacepoints') ? true : false} icon={<InterfacePointIcon />} className="mm-item">
                Interfaces
                <Link to={`/${selectedProjectId}/interfacepoints`} />
              </MenuItem>
            </LighthouseTooltip>
            }           
          </Menu>
          }
          <div className="sidebar-toggle-container">
            {sidebarOpen &&
              <div style={{ paddingLeft: "40px", paddingRight: "20px" }}>
                <hr />
              </div>}
            {!sidebarOpen &&
              <div style={{ paddingLeft: "20px", paddingRight: "8px" }}>
                <hr />
              </div>}
            <IconButton aria-label="sidebar-toggle" onClick={handleSidebarToggle}>
              {sidebarOpen && <ChevronLeftIcon style={{ width: '20px', height: '20px' }} />}
              {!sidebarOpen && <ChevronRightIcon style={{ width: '20px', height: '20px' }} />}
            </IconButton>
          </div>
          <Menu className="main-menu">
            <LighthouseTooltip content="MIDP" isActive={!sidebarOpen}>
              <MenuItem icon={<ExternalLinkIcon />} className="mm-item">
                 MIDP Online <a href="https://sncl-midp.azurewebsites.net" target="_blank" />
              </MenuItem>
            </LighthouseTooltip>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <div className={sidebarOpen ? "sb-component-container" : "sb-component-container-collapse"}>
            <LighthouseTooltip content="Support" isActive={!sidebarOpen}>
              <Support sidebarOpen={sidebarOpen} />
            </LighthouseTooltip>
          </div>
          <div className={sidebarOpen ? "sb-component-container" : "sb-component-container-collapse"}>
            <LighthouseTooltip content="Account Details" isActive={!sidebarOpen}>
              <UserDetails sidebarOpen={sidebarOpen} />
            </LighthouseTooltip>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  )
};

export default Sidebar;