import React, { useEffect, useState } from 'react';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FilterToolbarInterface.scss';

const FilterToolbarInterface = (props) => {
  const multiSelectFilter = React.useRef();
  const { filterOptions, filterFields, labelName } = props;
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);

  /**
   * Selected filter types changed
   */
  useEffect(() => {
    props.processUpdateFilter(selectedFilterTypes);
  }, [selectedFilterTypes]);

  /**
   * Handles adding filter type
   * @param {object} e 
   */
  const handleFilterAdd = (e) => {
    setSelectedFilterTypes([...selectedFilterTypes, {
      value: e.itemData
    }]);
  }

  /**
   * Handles removing filter type
   * @param {object} e 
   */
  const handleFilterRemove = (e) => {
    let filterValueToRemove = e.itemData;

    const updatedFilterTypes = selectedFilterTypes.filter((filterType) => {
      return !(filterType.value === filterValueToRemove);
    });

    setSelectedFilterTypes(updatedFilterTypes);
  }

  /**
   * Handles search change
   * @param {object} e 
   */
  const handleSearch = (e) => {
    props.processSearch(e.target.value);
  }


  return (
    <div className="interface-filter-container">
      <div className='search-container'>
        <TextField className="search-user-grid-text"
          label={labelName}
          onChange={handleSearch}
          InputLabelProps={{ shrink: true }}
          placeholder='Enter Search...'
          InputProps={{ disableUnderline: true, style: { fontSize: 14 }, endAdornment: <FontAwesomeIcon icon="search" className='icon' /> }} />
      </div>
      <div className='show-container'>
        <MultiSelectComponent id='filterSelectionList'
          ref={multiSelectFilter}
          placeholder='Filter By:'
          floatLabelType='Always'
          tagging={handleFilterAdd}
          removed={handleFilterRemove}
          dataSource={filterOptions}
          fields={filterFields}
          popupWidth='320px'>
        </MultiSelectComponent>
      </div>
    </div>
  );
}


export default FilterToolbarInterface;