import React from 'react';
import BuildFailedAnimation from '../../../../../components/Shared/atoms/BuildFailedAnimation'
import {SecondaryLighthouseButton} from "../../../../../theme/Buttons/index";
import './ScenarioErrorInfo.scss';

const ScenarioErrorInfo = () => {

  return (
        <div className="scenario-error-container">
              <div className="scenario-error-body">
                     <div className="scenario-error-img">
                       <BuildFailedAnimation width="450px"/>
                      </div>
                    <h5>Oops!</h5>
                    <p>Failed to create scenario options</p>
             </div>
        </div>
  );
}

export default ScenarioErrorInfo;