import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { alertDeliverableDriversEndPoint } from '../../api/apiRoutes';

export const fetchAlertDeliverableDrivers = createAsyncThunk(
  'alert/fetchalertdeliverabledrivers', async (params, thunkAPI) => {
    try{
      const response = await api.get(alertDeliverableDriversEndPoint(params.projectId, params.alertId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Create Alert Deliverable adapter
 */
 const alertDeliverableDriversAdapter = createEntityAdapter({
  selectId: (deliverableDriver) => deliverableDriver.id,
})

/**
 * Create Alert Deliverable Drivers slice / reducers
 */
const alertDeliverableDriversSlice = createSlice({
  name: 'deliverableDrivers',
  initialState: alertDeliverableDriversAdapter.getInitialState( { id: undefined,
                                                      deliverableLoading: false,
                                                      deliverableError: undefined,
                                                   } ),
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlertDeliverableDrivers.pending, (state, action) => {
        state.deliverableLoading = true;
        state.deliverableError = undefined;
      })
      .addCase(fetchAlertDeliverableDrivers.fulfilled, (state, action) => {
        state.deliverableLoading = false;
        state.deliverableError = undefined;
        alertDeliverableDriversAdapter.setMany(state, action.payload);
      })
      .addCase(fetchAlertDeliverableDrivers.rejected, (state, action) => {
        state.deliverableLoading = false;
        if (action.payload){
          state.deliverableError = action.payload.error;
        }
        else{
          state.deliverableError = action.error.message;
        }
      })
  }
});

/**
 * Alert Deliverable selectors
*/

export const selectAllDeliverableDrivers = (state, alertId) => {
  const alertDeliverableDrivers = state.alertDeliverableDrivers;
  const filteredDeliverableDrivers = alertDeliverableDriversAdapter.getSelectors((state) => alertDeliverableDrivers).selectAll().filter(deliverableDriver => deliverableDriver.alertId === alertId);
  return filteredDeliverableDrivers;
}

/**
 * Export Alert Deliverable reducer
 */
export default alertDeliverableDriversSlice.reducer;