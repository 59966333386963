import React, { useState } from 'react';
import DisciplineProgressItem from '../../molecules/DisciplineProgressItem';
import { Popover } from '@material-ui/core';
import ResourceAllocationChartContainer from '../../molecules/ResourceAllocationChartContainer';
import ResourceCapacityChartContainer from '../../molecules/ResourceCapacityChartContainer';
import './DesignProductionViewerProgress.scss'

const DesignProductionViewerProgress = (props) => {
  const { filteredDisciplineIds } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [allocationVisibility, setAllocationVisibility] = useState(false);
  const [capacityVisibility, setCapacityVisibility] = useState(false);
  const [ChartDisciplineId, setChartDisciplineId] = useState(null);

  const openResourceAllocation = (event, disciplineId) => {
    setAnchorEl(event.currentTarget);
    setAllocationVisibility(true);
    setChartDisciplineId(disciplineId);
  }

  const openResourceCapacity = (event, disciplineId) => {
    setAnchorEl(event.currentTarget);
    setCapacityVisibility(true);
    setChartDisciplineId(disciplineId);
  }


  const handleAllocationClose = () => {
    setAnchorEl(null);
    setAllocationVisibility(false);
  }

  const handleCapacityClose = () => {
    setAnchorEl(null);
    setCapacityVisibility(false);
  }

  return (
    <>
    <div className='progress-tab-container'>
    <div className='progress-tab-desc'>Track the % Progress on (1) Activities - Planned Progress, (2) Schedule Activities - Estimated progress Completion and (3) Deliverables - Estimated Progess Completion on discipline level.</div>
      <div className="progress-discipline-list">
        {filteredDisciplineIds?.map((disciplineId, i) =>
          <div className="progress-discipline-item">
            <DisciplineProgressItem key={i} disciplineId={disciplineId} openResourceCapacity={openResourceCapacity} isPropertyViewer={true} openResourceAllocation={openResourceAllocation} />
          </div>)}
       </div>

      </div>

      <Popover
        id='allocation-chart-popover'
        open={allocationVisibility}
        anchorEl={anchorEl}
        anchorReference='none'
        BackdropProps={{ invisible: false }}
        onClose={handleAllocationClose}
        >
          <div className='resource-allocation-chart-popover'>
             <ResourceAllocationChartContainer closePopover={handleAllocationClose} disciplineId={ChartDisciplineId} /> 
          </div>
      </Popover>
      <Popover
        id='capacity-chart-popover'
        open={capacityVisibility}
        anchorEl={anchorEl}
        anchorReference='none'
        onClose={handleCapacityClose}
        BackdropProps={{ invisible: false }}
        >
          <div className='resource-capacity-chart-popover'>
             <ResourceCapacityChartContainer closePopover={handleCapacityClose} disciplineId={ChartDisciplineId} /> 
          </div>
      </Popover>
    </>
  )
}

export default DesignProductionViewerProgress;