import React from 'react';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import './ActivityStateChangesPopover.scss';

const ActivityStateChangesPopover = (props) =>{

    const confirmSubmitChanges = (args) =>{
        props.confirmSubmitChanges()
    }

    const cancelSubmitChanges = (event) =>{
        props.cancelSubmitChanges()
    }
    return(
        <>
            <div className='popover-header'>
                <h6>Change Activity Live Status</h6>
            </div>
            <div className='popover-content'>
                Changes have been made to the Live Activity Status. Are you sure you would like to proceed with these changes?
                <br/>If confirmed, your changes will be processed. Please check back in an hour to see updated design progress metrics. Re-calculating alerts and optimisation can take a little longer, please check back tomorrow for updates.
            </div>
            <div className="popover-footer">
                <SecondaryLighthouseButton onClick={cancelSubmitChanges}>No Thanks</SecondaryLighthouseButton>
                <PrimaryLighthouseButton onClick={confirmSubmitChanges}>Yes Please</PrimaryLighthouseButton> 
            </div>
        </>
    );

}

export default ActivityStateChangesPopover;