import { 
  createSlice,
} from '@reduxjs/toolkit';

/**
 * Create initial state
 */
 const initialState = { selectedAlertIdInManager: undefined }

/**
 * Create alertSelection slice / reducers
 */
const alertSelectionSlice = createSlice({
  name: 'alertSelection',
  initialState,
  reducers: {
    setSelectedAlertIdInManager: (state, action) => {
      state.selectedAlertIdInManager = action.payload;
    },
  },
});

/**
 * alertSelection actions
 */
 export const {  
  setSelectedAlertIdInManager,
} = alertSelectionSlice.actions;

/**
 * Export reducer
 */
export default alertSelectionSlice.reducer;