import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import { selectAlertById, fetchAlerts, setSelectedAlertId, dismissOrUndismissAlert } from '../../../../../redux/slices/alertsSlice';
import { fetchPhases } from '../../../../../redux/slices/phasesSlice';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { setSelectedAlertIdInManager } from '../../../../../redux/slices/alertSelectionSlice';
import './ConfirmDismissAlertWizard.scss';

const ConfirmDismissAlertWizard = React.memo(props => {
  const { selectedAlertId, selectedAlertName, selectedAlertStatus, selectedPhaseId, selectedPackageId, selectedAlertType, selectedAlertDriver } = props;
  const { alertReason } = useSelector(state => state.alertReason);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  /**
   * Closes the dismiss alerts window
   */
  const closeDismissAlert = () => {
    props.closeDismissAlert();
  }


    /**
   * Dismiss Alert clicked
   * Closes the confirm Disimss Alert wizard
   * Passes the trigger to confirm the Dismiss Alert, Calls the API and hides the wizard
   */
     const dismissAlertAPI = () => {
      if (accessToken) {
        let alertData = {
          alertReason : alertReason,
          phaseId : selectedPhaseId,
          packageId : selectedPackageId,
          alertType : selectedAlertType,
          driver : selectedAlertDriver
          }
        dispatch(dismissOrUndismissAlert({ projectId: selectedProjectId, alertId: selectedAlertId, alertStatus: selectedAlertStatus, alertData : alertData,  accessToken: accessToken }))
        .then((response) => dispatch(setSelectedAlertIdInManager(selectedAlertId)))
        .then((responsess) => dispatch(setSelectedAlertId(undefined)))
        .then((response) => dispatch(fetchAlerts({ projectId: selectedProjectId, accessToken: accessToken })))
        .then((response) => dispatch(fetchPhases({ projectId: selectedProjectId, accessToken: accessToken })));
      }
      props.dismissAlertClick();
    }

  return (
    <>
      <div className="confirm-dismiss-alert-wizard">
      <div className="confirm-dismiss-alert-title">{selectedAlertName}</div>
        <div className="confirm-dismiss-alert-content">Are you sure that you would like to dismiss this alert?</div>
        <div className="confirm-dismiss-alert-footer">
          <SecondaryLighthouseButton className="mr-15 confirm-dismiss-alert-footer-button1" onClick={closeDismissAlert}>No thanks</SecondaryLighthouseButton>
          <PrimaryLighthouseButton className="confirm-dismiss-alert-footer-button2" onClick={dismissAlertAPI}>Dismiss Alert</PrimaryLighthouseButton>
        </div>
      </div>
    </>
  );
})

const areEqual = (prevProps, nextProps) => {
  if (nextProps.selectedAlertId == prevProps.selectedAlertId && nextProps.alertReason == prevProps.alertReason) {
    return true;
  }
  else {
    return false;
  }
}

export default React.memo(ConfirmDismissAlertWizard, areEqual);