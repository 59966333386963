import React, { useEffect, useState } from 'react';
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { selectProjectById, setSelectedProjectId } from '../../redux/slices/projectsSlice';
import { appInsights } from '../../AppInsights';
import AlertManagerSelectorContainer from './components/organisms/AlertManagerSelectorContainer';
import AlertProperties from './components/molecules/AlertProperties';
import AlertActivityDrivers from './components/molecules/AlertActivityDrivers';
import AlertDeliverableDrivers from './components/molecules/AlertDeliverableDrivers';
import RelatedAlertContainer from './components/molecules/RelatedAlertContainer';
import PageHeader from '../../components/Shared/organisms/PageHeader';
import { selectAllAlerts } from '../../redux/slices/alertsSlice';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import './ProjectAlertsPage.scss';

let trackData = require('../../appTrackerInfo.json');
var classNames = require('classnames');

function AlertTabPanel(props) {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

AlertTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AlertTabs = withStyles({
  root: {

  },
  indicator: {
      backgroundColor: 'var(--color-additional)',
      marginBottom: '7px',
      height: '3px'
    }
})(Tabs);

const AlertTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 'fit-content',
    maxWidth: null,
    fontWeight: '450',
    padding: '20px',
    color: 'var(--color-tab-text)',
    '&:hover': {
      color: 'var(--color-tab-text)'
    },
    '&$selected': {
      color: 'var(--color-tab-text)'
    },
    '&:focus': {
      color: 'var(--color-tab-text)',
    },
  },
  selected: {},
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))((props) => <Tab disableRipple {...props} />);

export const ProjectAlertsPage = (props) => {
  const { match: { params } } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { selectedAlertIdInManager } = useSelector(state => state.alertSelection);
  const [value, setValue] = React.useState("PropertiesAndImpact");
  const alerts = useSelector(selectAllAlerts);
  const [ alert, setAlert ] = useState(null);
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { LHRoles, projects } = useSelector(state => state.authorization);


  useEffect(()=>{
    if(selectedAlertIdInManager && alerts){
      setAlert(alerts.filter(x=>x.alertId === selectedAlertIdInManager)[0])
    }
  },[selectedProjectId, selectedAlertIdInManager, alerts])

  useEffect(() => {
    if (params.projectId) {
      dispatch(setSelectedProjectId(params.projectId));
    }
  }, []);


useEffect(() => {
  if (account && !selectedProject) {
    appInsights.trackPageView({
      name:`${trackData.pages[6].name}`,   
      properties: { 
                 Project: 'Selection',
                 ProjectName: 'Selection',
                 User:`${account.username}`,
                 Username: `${account.name}`,
                 Role: 'Selection'  
      }});
  }
  if (account && selectedProject) {
    appInsights.trackPageView({
      name:`${trackData.pages[6].name}`,   
      properties: { 
                 Project: `${selectedProjectId}`,
                 ProjectName: `${selectedProject?.projectName}`,
                 User:`${account.username}`,
                 Username: `${account.name}`,
                 Role: `${LHRoles.find( x => x === 'GlobalAdmin')?'GlobalAdmin' : LHRoles[projects?.indexOf(selectedProjectId.toUpperCase())]?.toString()}` 
      }});
  }
}, [account, selectedProject]);

    /**
* Tab change event
* @param {*} event 
* @param {*} newValue 
*/
const handleTabChange = (event, newValue) => {
  setValue(newValue);
};

  if (process.env.REACT_APP_ENABLE_INTERFACE_POINTS === 'true'){
    return (
      <>
        {selectedProject &&
          <div className="alert-page">
            <div className="alert-page-header">
              <PageHeader
                pageTitle="Alert Dashboard"
                margin="40"
              >
              </PageHeader>
            </div>
            <div className="alert-manager-panel">
              <AlertManagerSelectorContainer projectId={selectedProjectId} />
            </div>
            <div className="alert-main">
              <div className={classNames('alert-container')}>
                {selectedAlertIdInManager &&
                  <>
                    <div className="alert-header">
                        <AlertTabs
                          value={value}
                          indicatorColor="primary"
                          textColor="primary"
                          onChange={handleTabChange}>
                          <AlertTab label={<span className="tab-label">Properties and Impact</span>} value="PropertiesAndImpact" />
                          {alert?.alertStatus !== 'Expired' && <AlertTab label={<span className="tab-label">Activity Drivers</span>} value="ActivityDrivers" />}
                          {alert?.alertStatus !== 'Expired' &&<AlertTab label={<span className="tab-label">Deliverable Drivers</span>} value="DeliverableDrivers" />}
                          {alert?.alertStatus !== 'Expired' &&<AlertTab label={<span className="tab-label">Related Alerts</span>} value="RelatedAlerts" />}
                        </AlertTabs>
                    </div>
                    <AlertTabPanel value={value} index="PropertiesAndImpact" className="alert-content">
                       <AlertProperties currentAlertId={selectedAlertIdInManager} alert={alert} currencyCode={selectedProject.currencyCode} /> 
                    </AlertTabPanel>
                    {alert?.alertStatus !== 'Expired' && 
                      <AlertTabPanel value={value} index="ActivityDrivers" className="alert-content">
                            <AlertActivityDrivers />
                      </AlertTabPanel> }
                    {alert?.alertStatus !== 'Expired' &&
                     <AlertTabPanel value={value} index="DeliverableDrivers" className="alert-content">
                           <AlertDeliverableDrivers /> 
                       </AlertTabPanel> }
                    {alert?.alertStatus !== 'Expired' && 
                       <AlertTabPanel value={value} index="RelatedAlerts" className="alert-content">
                             <RelatedAlertContainer currentAlertId={selectedAlertIdInManager} alert={alert} />
                        </AlertTabPanel>}
                  </>
                }
                {!selectedAlertIdInManager &&
                  <div className="alert-no-selection">
                    Select an Alert to view its properties
                  </div>
                }
              </div>
            </div>
  
          </div>
        }
      </>
    );
    }
};

export default ProjectAlertsPage;