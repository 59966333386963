import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { setActivityChanges, selectAllActivityChanges, setIsChecked, removeActivityChange } from '../../../../../redux/slices/activityChangesSlice';
import { selectAllActivityCompletionStatus, selectAllActivityCompletionStatusMilestone } from '../../../../../redux/slices/activityCompletionStatusSlice';
import './ActivityStateDropDownListContainer.scss';

const ActivityStateDropDownListContainer = (props) =>{
    const dispatch = useDispatch();
    const { type, activityId, liveCompletionStatusId } = props;
    const activityStateSelector = React.useRef();
    const fields = { text: 'completionStatus', value: 'id' };
    const { isCheckedValue } = useSelector(state => state.activityChanges);
    const activityCompletionStatus = useSelector(selectAllActivityCompletionStatus);
    const activityCompletionStatusMilestone = useSelector(selectAllActivityCompletionStatusMilestone);
    const activityChanges  = useSelector((state) => selectAllActivityChanges(state));

    const disabledDropdown = () => {
      if(isCheckedValue === 2)
      {
        return true;
      }
      else{
        return false;
      }
    }

    const onStateChangeActivity = (event) =>{
        var completionStatusId = null;
        completionStatusId = event.value;
        var obj = activityStateSelector.current;
        if(obj){
          obj.dataSource = null;
          obj.dataSource = activityCompletionStatus;
          if(isCheckedValue !== 1){
            dispatch(setIsChecked(1))
          }
          if(activityChanges.find(x=>x.activityId === activityId && x.liveStatusId === completionStatusId)){
            dispatch(removeActivityChange(activityId));
          }else{
            dispatch(setActivityChanges({activityId: activityId, activityType: type, completionStatusId: completionStatusId, liveStatusId: liveCompletionStatusId}));
          }
        }
      }
      
      const onStateChangeMilestone = (event) =>{
        var completionStatusId = null;
        completionStatusId = event.value;
        var obj = activityStateSelector.current;
        if(obj){
          obj.dataSource = null;
          obj.dataSource = activityCompletionStatusMilestone;
          if(isCheckedValue !== 1){
            dispatch(setIsChecked(1))
          }
          if(activityChanges.find(x=>x.activityId === activityId && x.liveStatusId === completionStatusId)){
            dispatch(removeActivityChange(activityId));
          }else{
            dispatch(setActivityChanges({activityId: activityId, activityType: type, milestoneCompletionStatusId: completionStatusId, liveStatusId: liveCompletionStatusId}));
          }
        }
      }

    const valueCheck = (type) =>{
        if(activityChanges.find(x=>x.activityId === activityId)){
          if(type == 'Task'){
            return activityChanges.find(x=>x.activityId === activityId).completionStatusId;
          }
          else if(type == 'Milestone'){
            return activityChanges.find(x=>x.activityId === activityId).milestoneCompletionStatusId;
          }
        }
        return liveCompletionStatusId;
    }
    
      const stateTemplate = (data) => {
        return (
        <div className='state-item-dropdown state-item-dropdown-not-selected'>
            {data.completionStatus}
        </div>
        )
    };
    
    const selectedStateTemplate = (data) => {
        return (
        <div >
            {data.completionStatus}
            <div><FontAwesomeIcon icon="edit"/></div>
        </div>
        )
    };

    return(
    <>
      { type !== 'Task' && type !== 'Milestone' && type !== 'Finish Milestone' && liveCompletionStatusId }
      { type === 'Task' && 
      <DropDownListComponent id="activity-state-selector"
          ref={activityStateSelector}
          cssClass="activity-state-selector"
          readonly={disabledDropdown()}
          onChange={onStateChangeActivity}
          fields={fields}
          dataSource={activityCompletionStatus}
          value={valueCheck('Task')}
          valueTemplate={selectedStateTemplate}
          itemTemplate={stateTemplate}
          popupWidth="95px"
          delayUpdate={false}
          width="110px"
      />}
      {(type === 'Milestone' || type === 'Finish Milestone') && 
      <DropDownListComponent id="activity-state-selector"
          ref={activityStateSelector}
          cssClass="activity-state-selector"
          readonly={disabledDropdown()}
          onChange={onStateChangeMilestone}
          fields={fields}
          dataSource={activityCompletionStatusMilestone}
          value={valueCheck('Milestone')}
          valueTemplate={selectedStateTemplate}
          itemTemplate={stateTemplate}
          popupWidth="95px"
          delayUpdate={false}
          width="110px"
      />}
    </>
    );
}

export default ActivityStateDropDownListContainer;