import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ActivityList from '../ActivityList';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { fetchActivities, selectAllActivities, setTasksLength, setMilestonesLength } from '../../../../../redux/slices/activitiesSlice';
import { fetchActivityCompletionStatus, selectAllActivityCompletionStatus } from '../../../../../redux/slices/activityCompletionStatusSlice';
import { fetchActivityCompletionPipeline, selectAllActivityCompletionPipeline } from '../../../../../redux/slices/activityCompletionPipelineSlice';

import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const ActivityListContainer = (props) => {
  const dispatch = useDispatch();
  const { selectedPhaseId } = useSelector(state => state.info);
  const { selectedProjectId } = useSelector(state => state.projects);

  const activities = useSelector((state) => selectAllActivities(state, selectedPhaseId));
  const activityCompletionStatus = useSelector(selectAllActivityCompletionStatus);
  const activityCompletionPipeline = useSelector(selectAllActivityCompletionPipeline);

  const { error, isLoading } = useSelector(state => state.activityCompletionPipeline);

  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  
  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };

          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  useEffect(() => {
    // console.log("activity list container useeffect", selectedProjectId, selectedPhaseId)
    if (accessToken && selectedPhaseId){
      dispatch(fetchActivities({ projectId: selectedProjectId, phaseId: selectedPhaseId, accessToken: accessToken}))
      .then((response) => dispatch(fetchActivityCompletionStatus({ projectId: selectedProjectId, phaseId: selectedPhaseId, accessToken: accessToken}))
      .then((response) =>dispatch(setTasksLength({tasksLength: 5, lengthAction: 'Default'})))
      .then((response) => dispatch(setMilestonesLength({milestonesLength: 5, lengthAction: 'Default'})))
      .then((response) =>dispatch(fetchActivityCompletionPipeline({ projectId: selectedProjectId, phaseId: selectedPhaseId, accessToken: accessToken}))));
    }
  }, [dispatch, selectedProjectId, selectedPhaseId, accessToken]);

  return (
    <>
      { isLoading &&
          <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to get activities list" />
      }
      { !isLoading && !error && activities.length > 0 && activityCompletionStatus.length > 0 &&
        <>
          <ActivityList activities={activities} activityCompletionStatus={activityCompletionStatus} activityCompletionPipeline={activityCompletionPipeline}/>
        </>
      }
    </>
  );
}

export default ActivityListContainer;