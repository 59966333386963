import React, { useEffect, useState } from 'react';
import OptimisationFocusAreasInterfacePointSelectorItem from '../../molecules/OptimisationFocusAreasInterfacePointSelectorItem/OptimisationFocusAreasInterfacePointSelectorItem';

import './OptimisationFocusAreasInterfacePointSelector.scss';

const OptimisationFocusAreasInterfacePointSelector = (props) => {
  const { selectedProject, interfacePoints } = props;

  return (
    <div className="sfais-container">
      {interfacePoints.length > 0 &&
        interfacePoints.map((interfacePoint, i) =>
        (
          <OptimisationFocusAreasInterfacePointSelectorItem interfacePoint={interfacePoint} key={interfacePoint.interfacePointNumber} />
        ))
      }
    </div>
  )
}

export default OptimisationFocusAreasInterfacePointSelector;