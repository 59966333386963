import Popover from '@material-ui/core/Popover';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import LighthouseDialog from '../../../../../components/Shared/organisms/LighthouseDialog';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import InterfacePointEditorContainer from '../../../../ProjectInterfacePointsPage/components/organisms/InterfacePointEditorContainer';
import { DeleteIcon, OptionsIcon, EditIcon, TickCirecleIcon, InterfaceIncompleteStateIcon, InterfaceNotificationIcon, CheckCircle } from '../../../../../Icons';
import { setSelectedInterfacePointId, updateInterfacePointStatus, fetchInterfacePoints, removeModifiedInterfaceId } from '../../../../../redux/slices/interfacePointsSlice';
import './InterfacePointSelectorItem.scss';

const InterfacePointSelectorItem = (props) => {
  const { interfacePoint } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const { selectedInterfacePointId, modifiedInterfaceId } = useSelector(state => state.interfacePoints);
  const { interfacePointTypes } = useSelector(state => state.interfacePoints);
  const { interfaceWarnings } = useSelector(state => state.interfacePoints);
  const { isInterfacePointsLoading, interfacePointsError } = useSelector(state => state.interfacePoints);

  const [anchorEl, setAnchorEl] = useState(null);
  const [deletableInterfacePoint, setDeletableInterfacePoint] = useState(false);
  const [daysPendingForCompletion, setDaysPendingForCompletion] = useState(false);
  const [interfaceWarningType, setInterfaceWarningType] = useState(null);
  const [interfaceWarningMessage, setInterfaceWarningMessage] = useState(null);
  const [editInterfacePointVisibility, setEditInterfacePointVisibility] = useState(false);
  const [deleteInterfacePointDialogConfirmVisibility, setDeleteInterfacePointDialogConfirmVisibility] = useState(false);
  const [isReady, setIsReady] = useState(false);
  
  const open = Boolean(anchorEl);
  const id = open ? 'interface-point-popover' : undefined;
  const interfacePointTitle = interfacePoint.interfacePointNumber + ' - ' + interfacePoint.name;

  const [accessToken, setAccessToken] = useState(null);
  const { instance, accounts } = useMsal();

  const dispatch = useDispatch();

  const interfaceItem = React.useRef();

  /**
   * Get the number of days remaining for an incomplete interface point(due in the next week)
   */
   const getPendingDaysForCompletion = (selectedInterfacePoint) => {
    const millisecondsInDay = 86400000;
    let currentDate = new Date();
    if(selectedInterfacePoint.forecastDate == null || selectedInterfacePoint.forecastDate == undefined){
      let differenceInDays = new Date(selectedInterfacePoint.interfacePointDate).getTime() - new Date(currentDate).setHours(0,0,0,0);
      const daysDiff = Math.round(differenceInDays / millisecondsInDay);
      setDaysPendingForCompletion(daysDiff);
    }
    else{
      let differenceInDays = new Date(selectedInterfacePoint.forecastDate).getTime() - new Date(currentDate).setHours(0,0,0,0);
      const daysDiff = Math.round(differenceInDays / millisecondsInDay);
      setDaysPendingForCompletion(daysDiff);
    }
    setIsReady(true);
  }

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);
  

  useEffect(() => {
    // To define if the interface is deletable or not
    if (interfacePoint && interfacePointTypes && interfacePointTypes.length > 0){
      let deletableInterfaceTypes = interfacePointTypes.filter(t=>t.isDeletable == true);
      if(deletableInterfaceTypes.some(d=>d.id == interfacePoint.projectInterfacePointTypeId)){
        setDeletableInterfacePoint(true);
      }
    }
  }, [interfacePoint, interfacePointTypes]);

  /**
   * Fetched activities linked to the selected interface point
   */
   useEffect(() => {
      if(interfacePoint){
        let selectedInterfaceWarnings = interfaceWarnings.filter(a=>a.interfaceId == interfacePoint.id).slice();
        let linkType = "";
        if(selectedInterfaceWarnings.length > 0)
        {
          linkType = [...new Set(selectedInterfaceWarnings.map(item => item.linkType))][0];
          setInterfaceWarningType(linkType);
        }
        else{
          setInterfaceWarningType(null);
        }

        if(linkType == 'UpstreamPhase'){
          setInterfaceWarningMessage("This interface is at risk of delay due to observed low productivity on the driving pathway.");
        }
        else if(linkType == 'UpstreamResource'){
          setInterfaceWarningMessage("This interface is at risk of delay due to observed low productivity and resulting resource bottleneck(s).");
        }
        else if(linkType == 'UpstreamInterface'){
          setInterfaceWarningMessage("This interface is at risk of delay due to delay to another interface on the driving pathway.");
        }

        getPendingDaysForCompletion(interfacePoint);
      }
    }, [interfacePoint, interfacePointTypes]);

  /**
  * Handles interface point selection change event
  * @param {*} InterfacePointId 
  */
  const handleInterfacePointSelectorChange = (interfacePointId) => {
    // gets and sets the interface point Id
    dispatch(setSelectedInterfacePointId(interfacePointId));
  };

  /**
 * Options icon clicked
 * shows the Option pop-up menu
 */
  const handleOptionsClick = (event, interfacePointId) => {
    dispatch(setSelectedInterfacePointId(interfacePointId));
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the options pop-up menu
   */
  const handleOptionsClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  }

  /**
 * Confirm delete of this interface point
 */
  const confirmDeleteInterfacePoint = (event) => {
    event.stopPropagation();
    setDeleteInterfacePointDialogConfirmVisibility(true);
    setAnchorEl(null);
  }

  /**
   * Sets this interface point status as deleted
   */
  const deleteInterfacePoint = (event) =>{
    props.deleteInterfacePoint(interfacePoint.id);
  }

  /**
   * Cancels deleting this interface point
   */
  const cancelDeleteInterfacePoint = () =>{
    setDeleteInterfacePointDialogConfirmVisibility(false);
    setAnchorEl(null);
  }

  /**
   * cancels editing this interface point
   */
  const closeEditInterfacePoint = () =>{
    setEditInterfacePointVisibility(false);
  }

  /**
   * Sets the selected interface point id and turns on the visibility of the dialog box
   */
  const editInterfacePointClick = (event, interfacePointId) => {
    event.stopPropagation();
    dispatch(setSelectedInterfacePointId(interfacePointId));
    setEditInterfacePointVisibility(true);
    setAnchorEl(null);
  }

  /**
  * Marks an interface point as complete/active by setting the status to completed/active
  * @param {string} id 
  */
     const markInterfacePointAsCompletedOrActive = (interfacePointId) => {
      if(interfacePoint.status.toLowerCase() === 'active')
        dispatch(updateInterfacePointStatus({ projectId: selectedProjectId, interfacePointId: interfacePoint.id, status: "Completed",  accessToken: accessToken }))
      else
        dispatch(updateInterfacePointStatus({ projectId: selectedProjectId, interfacePointId: interfacePoint.id, status: "Active",  accessToken: accessToken }))
    }

    useEffect(() => {
      if (isReady && !isInterfacePointsLoading && !interfacePointsError && selectedInterfacePointId && modifiedInterfaceId){
        if(interfaceItem.current && interfacePoint.id == modifiedInterfaceId){
          //scroll into view
          interfaceItem.current.scrollIntoView();
          dispatch(removeModifiedInterfaceId());
        }
      }
    }, [isReady, isInterfacePointsLoading, interfacePointsError, selectedInterfacePointId, modifiedInterfaceId]);

  return (
    <>
    {isReady && !isInterfacePointsLoading && !interfacePointsError &&
      <>
        <div ref={interfaceItem}
          onClick={() => handleInterfacePointSelectorChange(`${interfacePoint.id}`)}
          className={`${interfacePoint.id === selectedInterfacePointId ? "active ip-item-container" : "ip-item-container"}`}
        >
          {interfacePoint.status.toLowerCase() ==='completed' && <><span><CheckCircle className="ip-completed" /></span><span className='ip-completed-name'>{interfacePointTitle}</span></>}
          {interfacePoint.status.toLowerCase() ==='active' && daysPendingForCompletion >= 5 && interfaceWarningType == null && <> <span><InterfaceIncompleteStateIcon className="ip-active" /></span><span className='ip-name'>{interfacePointTitle}</span></>}
          {interfacePoint.status.toLowerCase() ==='active' && daysPendingForCompletion < 5 && daysPendingForCompletion > 0 && interfaceWarningType == null && <> <span><InterfaceNotificationIcon className="ip-secondary-notification" /></span><span className='ip-name'>{interfacePointTitle}</span></>}
          {interfacePoint.status.toLowerCase() ==='active' && daysPendingForCompletion <= 0 && interfaceWarningType == null && <> <span><InterfaceNotificationIcon className="ip-primary-notification" /></span><span className='ip-name'>{interfacePointTitle}</span></>}
          {interfacePoint.status.toLowerCase() ==='active' && interfaceWarningType && <> <span><InterfaceNotificationIcon className="ip-primary-notification" /></span><span className='ip-name'>{interfacePointTitle}</span></>}
          <OptionsIcon onClick={(event) => {handleOptionsClick(event, interfacePoint.id)}} className="options-icon" />
        </div>
        <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleOptionsClose} anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }} BackdropProps={{ invisible: false }} style={{ borderRadius: 12 }}>
          <div className="ip-options-menu">
            <>
              <span className="ip-options-menu-item" onClick={(event) => editInterfacePointClick(event,`${interfacePoint.id}`)}><EditIcon className='edit-icon'/>Edit</span>
              {deletableInterfacePoint == true &&
                <span className="ip-options-menu-item" onClick={confirmDeleteInterfacePoint}><DeleteIcon />Delete</span>
              }
              {deletableInterfacePoint == false &&
                <span className="ip-options-menu-item-disabled"><DeleteIcon />Delete</span>
              }
              {interfacePoint.status.toLowerCase() ==='completed' && <> <span className="ip-options-menu-item" onClick={(event) => markInterfacePointAsCompletedOrActive(event,`${interfacePoint.id}`)}><TickCirecleIcon className='mark-complete-state-icon'/>Mark as incomplete</span></>}
              {interfacePoint.status.toLowerCase() !='completed' && <> <span className="ip-options-menu-item" onClick={(event) => markInterfacePointAsCompletedOrActive(event,`${interfacePoint.id}`)}><TickCirecleIcon className='mark-incomplete-state-icon'/>Mark as complete</span></>}
            </>
          </div>
        </Popover>
        {deleteInterfacePointDialogConfirmVisibility &&
          <LighthouseDialog isVisible={deleteInterfacePointDialogConfirmVisibility} title={'Delete Interface'} width="500px" height="250px" z-index="98">
            <div className="confirm-delete-ip-dialog">
              <div className="confirm-delete-ip-dialog-title">{interfacePointTitle}</div>
              <div className="confirm-delete-ip-dialog-content">Are you sure that you would like to delete this interface?</div>
              <div className="confirm-delete-ip-dialog-actions">
                <SecondaryLighthouseButton onClick={cancelDeleteInterfacePoint}>No thanks</SecondaryLighthouseButton>
                <PrimaryLighthouseButton onClick={deleteInterfacePoint}>Delete</PrimaryLighthouseButton>
              </div>
            </div>
          </LighthouseDialog>
        }
        {editInterfacePointVisibility &&
          <LighthouseDialog isVisible={editInterfacePointVisibility} closeButton={true} closeButtonAction={closeEditInterfacePoint} title="Edit Interface" width="63%" height="800px">
              <InterfacePointEditorContainer isEditable={true} closeEditInterfacePoint={closeEditInterfacePoint} daysPendingForCompletion={daysPendingForCompletion} />
          </LighthouseDialog>
        }
        <div title={'Mark as complete'} width="500px" height="250px" z-index="98" onClick={markInterfacePointAsCompletedOrActive}></div>
      </>
    }
    </>
  );
}

export default InterfacePointSelectorItem;