import React , { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Inject } from '@syncfusion/ej2-react-grids';
import { getFormattedDate } from '../../../../../utils';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { selectAllActivityDrivers, fetchAlertActivityDrivers } from '../../../../../redux/slices/alertActivityDriversSlice';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import './AlertActivityDrivers.scss';

const grdAlertActivityDrivers = React.createRef();

const AlertActivityDrivers = () => {
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const { activityError, activityLoading } = useSelector(state => state.alertActivityDrivers);
  const { selectedAlertIdInManager } = useSelector(state => state.alertSelection);
  const activityDrivers = useSelector((state) => selectAllActivityDrivers(state, selectedAlertIdInManager));
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    /**
 * Acquire access token
 */
  if (accounts.length > 0) {
    const request = {
      scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
      account: accounts[0]
    };
    instance.acquireTokenSilent(request).then(response => {
      setAccessToken(response.accessToken);
    }).catch(error => {
      // acquireTokenSilent can fail for a number of reasons, fallback to interaction
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenPopup(request).then(response => {
          setAccessToken(response.accessToken);
        });
      }
    });
  }
}, [accounts]);

  const gridRowTemplate = (props) => {

    return (
      <tr className="alert-driver-row">
        <td>
          {props.activityName}
        </td>
        <td className="text-left">
          {props.activityId}
        </td>
        <td className="text-left">
          {props.activityType}
        </td>
        <td className="text-left">
          {props.driver}
        </td>
        <td className="text-left">
          {getFormattedDate(props.plannedStartDate)}
        </td>
        <td className="text-left">
          {getFormattedDate(props.actualStartDate)}
        </td>
        <td className="text-left">
          {getFormattedDate(props.plannedEndDate)}
        </td>
        <td className="text-left">
          {getFormattedDate(props.actualEndDate)}
        </td>
      </tr>
    )
  }

     /**
   * Fetches the alert deliverable drivers for the alert
   */
      useEffect(() => {
        if (accessToken){
          dispatch(fetchAlertActivityDrivers({ projectId: selectedProjectId, alertId: selectedAlertIdInManager, accessToken: accessToken}))   
        }
      }, [dispatch, selectedAlertIdInManager, accessToken]);

  return (
    <>
      { activityLoading && 
        <CircularProgressBar />
      }
      { activityError && !activityLoading && 
        <ErrorMessage message="Unable to display alert Activity drivers." />
      }
      { !activityError && !activityLoading && activityDrivers.length === 0 && 
        <ErrorMessage message="No Activity drivers are available." />
      }
      { !activityLoading && !activityError && activityDrivers.length > 0 && 
        <div className="alert-drivers-container">
          <GridComponent
               id='grdAlertActivityDrivers' 
               ref={grdAlertActivityDrivers}
               dataSource={activityDrivers} 
               allowSorting={true} 
               rowTemplate={gridRowTemplate}
               height='700'
               >
            <ColumnsDirective>
              <ColumnDirective field='activityName' headerText='Activity Name' width='350' headerTextAlign='left' />
              <ColumnDirective field='activityId' headerText='Activity ID' width='200'  headerTextAlign='left' />
              <ColumnDirective field='activityType' headerText='Activity Type' headerTextAlign='left' />
              <ColumnDirective field='driver' headerText='Driver' headerTextAlign='left' />
              <ColumnDirective field='plannedStartDate' headerText='Planned Start'  headerTextAlign='left' />
              <ColumnDirective field='actualStartDate' headerText='Actual Start' headerTextAlign='left' />
              <ColumnDirective field='plannedEndDate' headerText='Planned End'  headerTextAlign='left' />
              <ColumnDirective field='actualEndDate' headerText='Actual End'  headerTextAlign='left' />
            </ColumnsDirective>
            <Inject services={[Page, Sort]} />
          </GridComponent>
        </div>
      }
    </>
  );
}

export default AlertActivityDrivers;