import React from 'react';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import CircularProgressIndicator from '../../../../../components/Shared/molecules/CircularProgressIndicator';
import { getProgressBarColour } from '../../../../../utils';
import { renderToString } from 'react-dom/server';
import '../../../ProjectProgressWidgets.scss';

const ProjectDesignProgressWidget = (props) => {
  const { project } = props;

  const infoDetails = [
    {
      header: 'Design Progress',
      first: 'Planned & actuals calculated from the value of work scheduled in the programme and production status of design deliverables.'
    },
    {
      header: 'Schedule Activities - Planned Progress',
      first: 'The planned % completion at the project level is the % of the planned hours that should be completed.'
    },
    { 
      header: 'Schedule Activities - Estimated Progress Completion',
      first: 'The % completion at the project level is calculated as the aggregation of the individual discipline % completions weighted by the planned hours for each discipline.',
      second: 'An Activity can be defined as a process, done for a particular purpose. Each Work package is divided into activities. Activities have to lead to the development of one or more project outputs.'
    },
    {
      header: 'Deliverables - Estimated Progress Completion',
      first: 'The % completion at the project level is calculated as the aggregation of the individual discipline % completions weighted by planned hours for each discipline.',
      second: 'A deliverable can be defined as the physical evidence of what has been produced through an activity or as the physical evidence/support of the output that was produced through an activity.'
    }
  ]

   /**
 * Tooltip template
 * @param {object} props 
 * @returns 
 */
 const TooltipTemplate = (props) => {
  return (
      <>
      <div className='progress-info-container'>
        <div className='progress-info-header'>
          <p>{props?.header}</p>
        </div>
        <div className='progress-info-desc'>
          {props?.first !== null && <p>{props?.first}</p>}
          {props?.second !== null && <p>{props?.second}</p>}
        </div>
      </div>
      </>
  );
}


  if (project) {
      const deliverableBasedActualPercComplete =  project?.completionStatus?.deliverableBasedActualPercComplete??null;
      const plannedPercentageComplete = project?.completionStatus?.plannedPercentageComplete??null;
      const activityBasedActualPercComplete = project?.completionStatus?.activityBasedActualPercComplete??null;
      const progressDesignProps = { size: 60, labelSize: '14px', lineWidth: 5, labelColor: '#05273D'};

    return (
      <div className="widget-container">
        <div className="widget-title">
          <h3>Design Progress</h3>
          <span className="tooltip ml-10">
            <InfoTooltip message={renderToString(TooltipTemplate(infoDetails[0]))}  width={510}  iconSize={20} />
          </span>
        </div>
        <div className="widget-content">
          <div className="widget-progress-bars mt-15">
            <div className="widget-progress-container">
            <CircularProgressIndicator progress={plannedPercentageComplete} {...getProgressBarColour(plannedPercentageComplete, null, true)}  {...progressDesignProps} /> 
              <div className="widget-progress-header">
                <div className='progress-bar-tag'>
                  <span>
                  Planned Progress Completion
                  </span>
                    <InfoTooltip message={renderToString(TooltipTemplate(infoDetails[1]))}  width={510}  iconSize={14} />
                </div>
                <span className='progress-bar-desc'>Schedule Activities {plannedPercentageComplete === null && ': Zero registered'}</span>
              </div>
            </div>
            <div className="widget-progress-container">
              <CircularProgressIndicator progress={activityBasedActualPercComplete} {...getProgressBarColour(plannedPercentageComplete, activityBasedActualPercComplete)} {...progressDesignProps} /> 
              <div className="widget-progress-header">
                <div className='progress-bar-tag' >
                  <span>
                      Estimated Progress Completion
                   </span>
                    <InfoTooltip message={renderToString(TooltipTemplate(infoDetails[2]))}  width={510} iconSize={14} />
                </div>
                <span className='progress-bar-desc' >Schedule Activities {activityBasedActualPercComplete === null && ': Zero registered'} </span>
              </div>
            </div>
            <div className="widget-progress-container">
            <CircularProgressIndicator progress={deliverableBasedActualPercComplete} {...getProgressBarColour(plannedPercentageComplete, deliverableBasedActualPercComplete)} {...progressDesignProps} /> 
              <div className="widget-progress-header">
                <div className='progress-bar-tag'>
                  <span>
                    Estimated Progress Completion
                  </span>
                   <InfoTooltip message={renderToString(TooltipTemplate(infoDetails[3]))} width={510}  iconSize={14} />
                </div>
                <span className='progress-bar-desc'>Deliverables{deliverableBasedActualPercComplete === null && ': Zero registered'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return null;
  }
}

export default ProjectDesignProgressWidget;