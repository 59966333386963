import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import './Alert.scss';
import CostImpactNew from '../../../../../components/Shared/atoms/CostImpactNew';
import ImpactRating from '../../../../../components/Shared/atoms/ImpactRating';
import getSymbolFromCurrency from 'currency-symbol-map';
import { formatCost } from '../../../../../utils';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import { DENOMINATION } from '../../../../../../src/constants';
import { WarningTriangleIcon } from '../../../../../Icons/index';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { selectPhaseById } from '../../../../../redux/slices/phasesSlice';
import { WarningCircleIcon, CloseIcon } from '../../../../../Icons';

const Alert = React.memo(props => {
  const { alert, currencyCode } = props;
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  const selectedPhase = useSelector((state) => selectPhaseById(state, alert.phaseId));
  const orchestratorRunRequired = selectedPhase?.orchestratorRunRequired;

  const [notification, setNotification] = useState(true);

  /**
  * Acquires the access token
  */
  useEffect(() => {
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  function createDescriptionMarkup() {
    return { __html: alert.description };
  }

  const closeNotification = () =>{
    setNotification(false);
  }

  const ConstructionTooltipMessage = () => {
    return(
      <div>
          Construction cost impact shown is an estimated proportion of the total forecast construction cost linked to this alert. Lighthouse allocates an estimated <b> 
          { getSymbolFromCurrency(currencyCode) +  formatCost(Math.round(Math.abs(alert.costImpactConstruction)/10)*10, 0, DENOMINATION.auto) } </b> impact against total 
          construction cost impact of <b>{ getSymbolFromCurrency(currencyCode) + formatCost(Math.round(Math.abs(alert.fullCostImpactConstruction)/10)*10, 0, DENOMINATION.auto) } 
          </b> for this alert.'
      </div>
    )
  }
  const OtherTooltipMessage = () => {
    return(
      <div>
          Other cost impacts include 3rd Party Approval and Operational Revenue loss. Lighthouse estimates a potential total 3rd Party Approval impact = <b> 
          { getSymbolFromCurrency(currencyCode) + formatCost(Math.round(Math.abs(alert.fullCostImpactClient)/10)*10, 0, DENOMINATION.auto) } </b> and 
          Operational Revenue Loss = <b> { getSymbolFromCurrency(currencyCode) + formatCost(Math.round(Math.abs(alert.fullRevenueLoss)/10)*10, 0, DENOMINATION.auto) }</b>. 
          The impact shown is a proportion of this total allocated to the alert as a magnitude of risk.
      </div>
    )
  }
  const TimeTooltipMessage = () => {
    return(
      <div>
          The time impact shown is the estimated proportion of the total time impact linked to this alert. Lighthouse allocates an estimated <b> 
          { Math.ceil(alert.ifcCompletionDelayDays) } day(s)</b> delay against the total time impact of <b> {Math.ceil(alert.fullIfcCompletionDelayDays) } day(s)</b> for this alert
      </div>
    )
  }

  const ToolTipClicked = (event) => {
    event.stopPropagation();
  }

  return (
    <>
      <div className="alert-content-container">
        <div className="alert-description">
          {orchestratorRunRequired == true && notification == true && alert.alertStatus == 'Active' &&
          <>
            <div className="alert-toast-container">
              <div className="alert-toast-header">
                <div className="alert-toast-icon-label-container">
                  <span className="alert-span-warning-act"></span>
                  <span className="alert-toast-warning-icon">
                    <WarningCircleIcon />
                  </span>
                  <p>
                  A new % complete has been calculated for this work package. 
                  Updates to this alert are now being processed, please check back tomorrow to see the changes.
                  </p>
                  <div className='alert-toast-cancel-icon'>
                    <CloseIcon width="14px" height="14px"onClick={closeNotification}/>
                  </div>
                </div>
              </div>
            </div>
          </>
          }
          <div className="al-desc-title">Event & Consequence</div>
          <div dangerouslySetInnerHTML={createDescriptionMarkup()}></div>
        </div>
        {alert.alertType.toLowerCase() === 'primary' &&
          <>
            <div className="alert-design-cost">
              <div className="al-des-section">
                <div className="al-des-title"><div className='cost-code-icon'>{getSymbolFromCurrency(currencyCode)}</ div>
                  &emsp;Estimated Design Cost
                </div>
                <div className="al-des-container">
                  <table className="al-des-table">
                    <tbody>
                      
                          <tr className='total-cost'>
                            <th>Total Design Cost</th>
                            <td>
                              <span className='total-cost-value'>
                                <CostImpactNew costImpact={Math.round(alert.costImpactDesign/100)*100} scale={1} denomination={DENOMINATION.auto} width='110px' />
                              </span>
                            </td>
                          </tr>
                          <tr className='design-sub-cost'>
                            <th>Direct work to complete</th>
                            <td>
                                <CostImpactNew costImpact={Math.round(alert.costImpactDesignDirect/100)*100} scale={1} denomination={DENOMINATION.auto} width='110px' />
                            </td>
                          </tr>
                          <tr  className='design-sub-cost'>
                            <th>Direct downstream re-work</th>
                            <td>
                                <CostImpactNew costImpact={Math.round(alert.costImpactDesignDirectInter/100)*100} scale={1} denomination={DENOMINATION.auto} width='110px' />
                            </td>
                          </tr>
                        
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="alert-other-impact">
              <div className="al-other-impact-section">
                <div className="al-other-impact-title"><WarningTriangleIcon className='other-impact-icon'/>&emsp;Other Forecast Impacts</div>
                <div className="al-other-impact-container">
                  <table className="al-other-impact-table" onClick={ToolTipClicked}>
                    <tbody>
                      <tr className='other-impact-name'>
                        <th>Construction
                            <InfoTooltip message={ConstructionTooltipMessage} onClick={ToolTipClicked}  iconSize={12} width={500}/>
                        </th>
                        <td>
                            <ImpactRating value={alert.costImpactConstruction} total={alert.fullCostImpactConstruction} scale={2} outOf={10} width='80px' />
                        </td>
                      </tr>
                      <tr className='other-impact-name'>
                        <th>Other
                            <InfoTooltip message={OtherTooltipMessage} onClick={ToolTipClicked}  iconSize={12} width={500}/>
                        </th>
                        <td>
                            <ImpactRating value={alert.revenueLoss+alert.costImpactClient} total={alert.fullRevenueLoss+alert.fullCostImpactClient} scale={2} outOf={10} width='80px'/>
                        </td>
                      </tr>
                      <tr className='other-impact-name'>
                        <th>Time Impact
                            <InfoTooltip message={TimeTooltipMessage} onClick={ToolTipClicked}  iconSize={12} width={500}/>
                        </th>
                        <td>
                            <ImpactRating value={Math.ceil(alert.ifcCompletionDelayDays)} total={Math.ceil(alert.fullIfcCompletionDelayDays)} scale={2} outOf={10} width='80px' />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
});

const areEqual = (prevProps, nextProps) => {
  if (nextProps.alert == prevProps.alert) {
    return false;
  }
  else {
    return true;
  }
}

export default React.memo(Alert, areEqual);