import React from 'react';
import DeliverableListAccordion from '../../molecules/DeliverableListAccordion';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import './DeliverableList.scss';

const DeliverableList = (props) => {
  const { deliverables, selectedPhaseId } = props;
  const deliverablesCount = deliverables.length;

  return (
    <div className="delv-container">
      <div className="pv-title">
        <h4>Deliverables:</h4>
      </div>
      <div className="delv-list">
        {
          !deliverables  &&
          <ErrorMessage message="Unable to get deliverables list" />
        }
        {
          deliverablesCount > 0 &&
          <DeliverableListAccordion deliverables={deliverables} selectedPhaseId={selectedPhaseId}
          />
        }
      </div>
    </div>
  );
};

export default DeliverableList;