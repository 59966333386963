import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { fetchPackages ,selectAllNonKEMPackages } from '../../../../../redux/slices/packagesSlice';
import { fetchPackagePctCompleteCalcTypes, updatePackagePctCompleteCalcType, selectAllPackagePctCompleteCalcTypes, setPackagePctCalcType } from '../../../../../redux/slices/packagePctCompleteCalcTypesSlice';
import { fetchDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import { fetchPhases } from '../../../../../redux/slices/phasesSlice';
import AuthorizationService from "../../../../../components/Shared/molecules/Authorization/AuthorizationService";
import ActivityCalculationFooter from './../../atoms/ActivityCalculationFooter';
import ActivityCalculationBody from './../../atoms/ActivityCalculationBody';
import ActivityCalculationHeader from './../../atoms/ActivityCalculationHeader';
import { useMsal } from "@azure/msal-react";
import './ActivityCalculationSettings.scss';

const ActivityCalculationSettings = (props, ref) => {
  const dispatch = useDispatch();
  const { error, isLoading, packagePctCalcType } = useSelector(state => state.packagePctCompleteCalcTypes);
  const { selectedProjectId } = useSelector(state => state.projects);
  const packages = useSelector(selectAllNonKEMPackages);
  const packagePctCompleteCalcTypes = useSelector(selectAllPackagePctCompleteCalcTypes);
  const { instance, accounts } = useMsal();
  const [packageActivityCheck, setPackageActivityCheck] = useState([]);
  const filterFields = {id: "id", value: "value"};
  const [filterSelectionOptions, setFilterSelectionOptions] = useState([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const { accessToken, authorized } = useSelector(state => state.authorization);

  useImperativeHandle(ref,() => ({
    handleUpdateCloseCall: () => { ActivityChangeSaved('Indirect') }
  }));

  useEffect(() => {
     //Acquire access token
     AuthorizationService({dispatch : dispatch, instance: instance, accounts: accounts, accessTokenAvailable:accessToken});
  }, [accounts]);


  useEffect(() => {
    // fetch PackagePctCompleteCalcTypes
    if (accessToken && authorized == "Yes"){
      dispatch(fetchDisciplines({projectId : selectedProjectId, accessToken : accessToken}))  
      .then((disResponse) => { dispatch(fetchPackages({projectId : selectedProjectId, accessToken : accessToken}))
        .then((packResponse) => { dispatch(fetchPhases({projectId : selectedProjectId, accessToken : accessToken}))
          .then((phaseResponse) => { dispatch(fetchPackagePctCompleteCalcTypes({projectId : selectedProjectId, accessToken : accessToken}))
            .then((pctResponse) => {
              let packageActivityCheckArray = [];
              for(var i = 0; i < packResponse.payload.length; i++){
                  let activityChecked = false;
                  let packageId = packResponse.payload[i].packageId;
                  if(pctResponse.payload.slice().filter(x=>x.packageId == packageId).map(x=>x.packagePctCalcType)[0] == 2){
                      activityChecked = true;
                  }
                  packageActivityCheckArray.push({packageId: packageId, activityChecked: activityChecked});
              }
              let packageDisciplineIds = [...new Set(packResponse.payload.map(x=>x.disciplineId))];
              let allPackageDisciplines = disResponse.payload.slice().filter(x=>packageDisciplineIds.includes(x.disciplineId))
              setPackageActivityCheck(packageActivityCheckArray);
              setFilterSelectionOptions(allPackageDisciplines.map(x=> ({id: x.disciplineId, value: x.disciplineName})));
              dispatch(setPackagePctCalcType([]));
              setIsReady(true);
            })
          })
        })
      })
    }
  }, [dispatch, accessToken, authorized, selectedProjectId]);


  /**
   * Handles search
   * @param {object} e 
   */
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  }

  /**
   * Handles adding filter type
   * @param {object} e 
   */
   const handleFilterAdd = (e) => {
    setSelectedFilterOptions([...selectedFilterOptions, e.itemData]);
  }

  /**
   * Handles removing filter type
   * @param {object} e 
   */
  const handleFilterRemove = (e) => {
    let filterOptionToRemove = e.itemData;
    const updatedFilterOptions = selectedFilterOptions.filter((filterOption) => {
      return !(filterOption === filterOptionToRemove);
    });
    setSelectedFilterOptions(updatedFilterOptions);
  }

  const ActivityChangeSaved = (args) => {
    let packagePctCalcTypeToBeSaved = packagePctCalcType.slice();
    if(packagePctCalcTypeToBeSaved.length > 0){
      setIsReady(false)
      packagePctCalcTypeToBeSaved.forEach((element, i) => {
          if (accessToken && authorized == "Yes" && element.packageId != null ){
              dispatch(updatePackagePctCompleteCalcType({projectId: selectedProjectId,packageId: element.packageId, packagePctCalcType:element.packagePctCalcType, accessToken : accessToken}))
              .then((response) => {
                  if(i === (packagePctCalcType.length - 1)){
                    setSearchValue(null);
                    setSelectedFilterOptions([]);
                    dispatch(fetchPackages({projectId : selectedProjectId, accessToken : accessToken}))
                    .then((response) => dispatch(fetchDisciplines({projectId : selectedProjectId, accessToken : accessToken}))
                      .then((response) => dispatch(fetchPhases({projectId : selectedProjectId, accessToken : accessToken}))
                        .then((response) => dispatch(fetchPackagePctCompleteCalcTypes({projectId : selectedProjectId, accessToken : accessToken}))
                          .then((pctPackResponse)=>{
                            let packageActivityCheckArray = [];
                            for(var i = 0; i < packages.length; i++){
                              let activityChecked = false;
                              let packageId = packages[i].packageId;
                              if(pctPackResponse.payload.slice().filter(x=>x.packageId == packageId).map(x=>x.packagePctCalcType)[0] == 2){
                                activityChecked = true;
                              }
                              packageActivityCheckArray.push({packageId: packageId, activityChecked: activityChecked});
                            }
                            setPackageActivityCheck(packageActivityCheckArray);
                            dispatch(setPackagePctCalcType([]));
                            if(args === 'Indirect'){
                              props.closeActivityChanges();
                            }
                            setIsReady(true);
                          })
                        )
                      )
                    )
                  }
              })
            }
      })
    }}
  

  return (
    <>
        <div className='activity-calculation-container'>
            { !isReady &&
                <CircularProgressBar />
            }
            { isReady && isLoading &&
                <CircularProgressBar />
            }
            { error && !isLoading &&
                <ErrorMessage message="unable to display design package settings" />
            }
            { !isLoading && !error && isReady &&
              <>
                 <ActivityCalculationHeader 
                     handleSearch={handleSearch} 
                     handleFilterAdd={handleFilterAdd} 
                     handleFilterRemove={handleFilterRemove} 
                     filterSelectionOptions={filterSelectionOptions} 
                     filterFields={filterFields}/>
                  <ActivityCalculationBody 
                     selectedProjectId={selectedProjectId}
                     packages={packages} 
                     selectedFilterOptions={selectedFilterOptions} 
                     searchValue={searchValue} 
                     packageActivityCheck={packageActivityCheck} 
                     packagePctCompleteCalcTypes={packagePctCompleteCalcTypes} />
                  <ActivityCalculationFooter 
                     selectedProjectId={selectedProjectId} 
                     ActivityChangeSaved={()=> ActivityChangeSaved('direct')}/>
              </>
            }
        </div>
    </>
      
  );
};

export default forwardRef(ActivityCalculationSettings);