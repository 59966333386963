import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Edit, Sort, Page } from '@syncfusion/ej2-react-grids';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { TextField } from '@material-ui/core';
import { PrimaryLighthouseButton } from '../../../../../theme/Buttons';
import { fetchAppRoles, setSelectedUser } from '../../../../../redux/slices/userAdSlice';
import { selectAllUsers } from '../../../../../redux/slices/usersSlice';
import { fetchDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import AuthorizedComponent from '../../../../../components/Shared/molecules/AuthorizedComponents';
import Popover from '@material-ui/core/Popover';
import RemoveUserWidget from '../RemoveUserWidget/RemoveUserWidget';
import './ManageTeam.scss';

const ManageTeam = (props) => {
  const grdUsers = React.useRef();
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const users = useSelector((state) => selectAllUsers(state, selectedProjectId));
  const { userProjectRole, userProjectDisciplines } = useSelector(state => state.authorization);
  const { error, isLoading } = useSelector(state => state.users);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [sortIcon, setSortIcon] = useState("sort");
  const [anchorEl, setAnchorEl] = useState(null);
  const [removeOpen, setRemoveOpen] = useState(false);
  const account = useAccount(accounts[0] || {});

  const filterUsers = (users) => {
    if(users){
      if(userProjectRole === 'GlobalAdmin'){
        return users;
      }
      else if(userProjectRole !== 'GlobalAdmin'){
        return users.filter((obj)=> { return !obj?.userAccessGroup.includes('Global Admin')});
      }
    }
  }

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: account
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  const header = (props) =>{
    return (
      <>
        <div className="header">
            {props.headerText}
            {
              (props.headerText == "User") &&
              <div className="sort-icon">
                <FontAwesomeIcon icon={sortIcon}/>
              </div>
            }
        </div>
      </>
    );
  }

  const user = (props) =>{

    let emailUser = 'mailto:' + props.userEmail;

      return(
          <div>
            <div className='user-image'>
                <FontAwesomeIcon icon="user-circle"/>
            </div>
            <div className="user-name">
                {props.userName}
            </div>
            <div className='user-email'>
              <a className='user-email-link' href={emailUser}>{props.userEmail}</a>
              <span className='.user-email-span'>
                <FontAwesomeIcon icon='copy' className='user-email-icon' onClick={userCopyClick(props.userEmail)}/>
              </span>
            </div>
        </div>
      );
  }

  const userCopyClick = (param) => (e) =>{
    navigator.clipboard.writeText(param);
    const cell = e.currentTarget;
    cell.classList.add('user-email-span2')
    setTimeout(() => {
      cell.classList.remove('user-email-span2')
    }, 1500);
  }

  const userAccessGroup = (props) => {
    
    let className = ""
    let userAccessGroupContent = []

    props.userAccessGroup.forEach(element => {
      
      className = ""

      if(element === "Global Admin"){
        className = "global-admin ";
      }
      else if(element === "Owner"){
        className = "project-owner";
      }
      else if (element === "Contributor"){
        className = "contributor";
      }
      else if (element === "Member"){
        className = "member";
      }
      else if (element === "Guest"){
        className = "guest";
      }

      if(element === "Global Admin"){
        userAccessGroupContent.push( 
          <>
           <div className='span-separator'>
            <span className={`span-design ${className}`}>
             {element}
             <FontAwesomeIcon icon="cog" className='global-admin-icon'/>
            </span>
            </div>
          </>
        )
      }
      else if(element === "Owner"){
        userAccessGroupContent.push( 
          <>
           <div className='span-separator'>
            <span className={`span-design ${className}`}>
                Project Owner
                <FontAwesomeIcon icon="star" className='project-owner-icon'/>
            </span>
            </div>
          </>
        )
      }
      else {
        userAccessGroupContent.push( 
          <>
          <div className='span-separator'>
          <span className={`span-design ${className}`} >
              {element} 
            </span> 
          </div>
          </>
        )
      }
    });

    return userAccessGroupContent;

  }

  const discipline = (props) =>{

    let disciplineContent = [];

    if (props != ""){

      props.discipline.forEach(element => {
        if(element != ""){
          let discipline = element;
          disciplineContent.push(
            <>
            <div className='span-separator'>
              <span className="discipline span-design">
                {discipline}
              </span>
            </div>
            </>
          )
        }
        else{
          disciplineContent.push(
            <>
              <div className="discipline-no-content span-design"></div>
            </>
          )
        }
      });
    }

    return disciplineContent;
  }

  const handleClose = () => {
    setAnchorEl(null);
    setRemoveOpen(false);
  };

  const handleEditUser = (userData) => {
    if(userData){
      dispatch(setSelectedUser(users?.filter( x => x?.userId === userData?.userId)[0]));
      props.editUserWidget();
    }
  }

  const handleRemoveUser = (event) => {
    setAnchorEl(event.currentTarget);
    setRemoveOpen(true);
  }

  const editUserField = (props) =>{
    var permissionFlag = permissionCheck(props)
    return (
      <>
      {permissionFlag && 
         <div className='icon-active'>
              <FontAwesomeIcon icon="edit" onClick={() => handleEditUser(props)} />
          </div>
      }
      {!permissionFlag && 
           <div className='icon-disabled'>
               <FontAwesomeIcon icon="edit" />
           </div>
      }
      </>

    );
  }

  const permissionCheck = (props) => {
     if(account?.username === props.userEmail){
        return false;
     }
     else if(userProjectRole === 'GlobalAdmin' && !props.userAccessGroup.includes("Global Admin")){
        return true;
     }
     else if(userProjectRole === 'Owner' && !props.userAccessGroup.includes("Global Admin") && !props.userAccessGroup.includes("Owner")){
       return true;
     }
     else if(userProjectRole === 'Contributor' && !props.userAccessGroup.includes("Global Admin") && !props.userAccessGroup.includes("Owner")){
      if(props.userAccessGroup.includes("Contributor") && userProjectDisciplines.filter((obj)=>props.discipline.includes(obj)).length > 0){
        return true;
      }else if(props.userAccessGroup.includes("Member")){
          return true;
      }else{
        return false;
      }
    }
    else{
      return false;
    } 
  }

  const editSettings = {
    allowDeleting: true,
    mode: 'Normal'
  };

  const deleteUserField = (props) =>{

    const deleteUserClick = (e) =>{
      // var data = grdUsers.current.currentViewData[props.index];
      // grdUsers.current.deleteRecord('userId', data);
      
      handleRemoveUser(e);

      // if (accessToken && selectedProjectId){
      //   dispatch(deleteUser({ projectId: selectedProjectId, userId: props.userId, accessToken: accessToken}));
      // }
    }

    return (
      <div className="icon-disabled">
          <FontAwesomeIcon 
            icon="trash-alt" 
            //onClick={deleteUserClick}
          />
      </div>
    );
  }

  const actionBegin = (params)=>{
    if(params.direction == "Ascending"){
      setSortIcon("caret-up");
    }
    else if(params.direction == "Descending"){
      setSortIcon("caret-down");
    }
    else{
      setSortIcon("sort");
    }
    
  }

  const searchUserGrid = (params) => {
    grdUsers.current.search(params.target.value);
  }

  const addTeamMemberClick = (event) =>{
    props.addUserWidget(event.currentTarget)
    dispatch(fetchAppRoles({projectId: selectedProjectId, accessToken: accessToken}))
    .then(() => dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken })) 
      )
  }

  return (
    <>
    { isLoading && 
      <CircularProgressBar />
    }
    { error && !isLoading &&
      <ErrorMessage message="Unable to get users list" />
    }
    { !isLoading && !error && users &&
      <>
        <div className="manage-team">
          <div className='manage-team-header'>
            <div className='manage-team-header-text'>
                <span>Here is an overview of your Team Members.</span>
            </div>
            <div className='manage-team-header-actions'>
            <TextField className ="search-user-grid-text" label='Search for Team Member:' InputLabelProps={{ shrink: true }} placeholder='Type Here...' onChange={searchUserGrid} InputProps={{ disableUnderline: true, style:{fontSize:14, color:"#005A84"}, endAdornment: <FontAwesomeIcon icon="search" className='search-icon'/> }}/>
            <AuthorizedComponent value='add-member' projectId={selectedProjectId} disciplineId = 'All'>
                 <PrimaryLighthouseButton className='add-team-member' onClick={addTeamMemberClick}><FontAwesomeIcon icon="user-plus" className='add-team-member-icon'/>Add Team Member</PrimaryLighthouseButton>
            </AuthorizedComponent>
            </div>
          </div>
          <GridComponent id='grdUsers' ref={grdUsers} dataSource={filterUsers(users)} allowSorting={true} allowSelection={false} actionBegin={actionBegin}
          allowEditing={true} editSettings={editSettings} height='650'>
            <ColumnsDirective>
                <ColumnDirective field='userName' headerText='User' width='30%' headerTemplate={header} template={user}/>
                <ColumnDirective field='userAccessGroup' headerText='User Access Group' width='15%' textAlign="left" template={userAccessGroup} headerTemplate={header} allowSorting={false}/>
                <ColumnDirective field='discipline' headerText='Discipline' width='15%' textAlign="left" template={discipline} headerTemplate={header} allowSorting={false}/>
                <ColumnDirective field='deleteUser' headerText='' width='20%' textAlign="Right" template={deleteUserField} allowSorting={false}/>
                <ColumnDirective field='userId' headerText='' width='5%' textAlign="Center" template={editUserField} allowSorting={false}/>
                <ColumnDirective field='userId' isPrimaryKey={true} headerText='' visible={false}/>
            </ColumnsDirective>
            <Inject services={[Sort,Edit, Page]} />
          </GridComponent>
            <Popover
                id='remove-user'
                open={removeOpen}
                anchorEl={anchorEl}
                anchorReference='none'
                BackdropProps={{ invisible: false }}
              >
                <div className="remove-user-popover">
                  <RemoveUserWidget closeWidget={handleClose} />
                </div>
              </Popover>
        </div>
      </>
    }
  </>
      
  );
};

export default ManageTeam;