import React from 'react';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons/index';
import { CloseIcon } from '../../../../../Icons';

import './ActivityCalculationCloseConfirm.scss'

const ActivityCalculationCloseConfirm = (props) => {
    const { closeActivityChanges, activitySaveChanges, closeCloseByActivity } = props;

  return (
    <div className="activity-Change-Popup-container">
    <div className='popover-header'>
       <div>
       <h6>Alert Calculations</h6>
       </div>
           <div className='popover-close' onClick={closeCloseByActivity}>
                 <CloseIcon />
           </div>
     </div>
     <div className='popover-content'>
          <p>Are you sure you want to close this window without saving your changes?</p>
     </div>
     <div className="popover-footer">
          <SecondaryLighthouseButton onClick={closeActivityChanges} >Yes</SecondaryLighthouseButton>
           <PrimaryLighthouseButton onClick={activitySaveChanges} >Save Changes</PrimaryLighthouseButton>
     </div>
   </div>
  )
}

export default ActivityCalculationCloseConfirm;