import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ResourceChange from '../ResourceChange';
import ResourceChart from '../ResourceChart';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { setSelectedWeekStartDate, fetchDisciplineResourceProfile, setEditedResources, setSelectedDisciplineId } from '../../../../../redux/slices/createScenarioSlice';
import { fetchDisciplines, selectAllDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import { selectDisciplineById } from '../../../../../redux/slices/disciplinesSlice';
import { getFormattedDate } from '../../../../../utils';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import './DisciplineWeeklyResourcing.scss';

const weekSelectorItemTemplate = (data) => {
  return (
    <div className="ws-item">
      {getFormattedDate(data.weekStartDate)}
    </div>
  );
};

const weekSelectorValueTemplate = (data) => {
  return (
    <div className="ws-item">
      {getFormattedDate(data.weekStartDate)}
    </div>
  );
};

const disciplineSelectorItemTemplate = (data) => {
  return (
    <div className="ws-item">
      {data.disciplineName}
    </div>
  );
};
const disciplineSelectorValueTemplate = (data) => {
  return (
    <div className="ws-item">
      {data.disciplineName}
    </div>
  );
};


const DisciplineWeeklyResourcing = (props) => {
  const { resourceProfile, selectedWeekStartDate } = useSelector(state => state.createScenario);
  const { editedResources } = useSelector(state => state.createScenario);
  const existingWeeklyResources = editedResources ? editedResources : [];
  const disciplines = useSelector(selectAllDisciplines).filter((d) => d.disciplineName !== "External Milestones");
  const fields = { text: 'weekStartDate', value: 'weekStartDate' };
  const disciplineFields = { text: 'disciplineName', value: 'disciplineId' };
  const { selectedDisciplineId } = useSelector(state => state.createScenario);
  const selectedDiscipline = useSelector((state) => selectDisciplineById(state, selectedDisciplineId));
  const [accessToken, setAccessToken] = useState(null);
  const { instance, accounts } = useMsal();
  const { selectedProjectId } = useSelector(state => state.projects);
  const ddlWeekSelector = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    /**
    * Acquire access token
    */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  useEffect(() => {
    if (ddlWeekSelector && selectedWeekStartDate) {
      ddlWeekSelector.current.value = selectedWeekStartDate;
    }
  }, [selectedWeekStartDate]);


  useEffect(() => {
    // fetch disciplines
    if (accessToken) {
      dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken }));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  const handleDisciplineSelectorChange = (args) => {
    let disciplineId = args.value;
    dispatch(setSelectedDisciplineId(disciplineId));
    //dispatch(setSelectedWeekStartDate(undefined));
  };

  useEffect(() => {
    if (resourceProfile && resourceProfile.length > 0) {
      let initialWeekStartDate = resourceProfile[0].weekStartDate
      if (initialWeekStartDate) {
        dispatch(setSelectedWeekStartDate(initialWeekStartDate));
      }
    }
  }, [resourceProfile]);

  /**
   * Set the initial week start date
   */
  useEffect(() => {
    if (resourceProfile && resourceProfile.length > 0) {
      let initialWeekStartDate = resourceProfile[0].weekStartDate
      if (initialWeekStartDate) {
        dispatch(setSelectedWeekStartDate(initialWeekStartDate));
      }
    }
  }, [resourceProfile]);

  /**
* Gets the resource profile for the selected discipline
*/
  useEffect(() => {
    if (accessToken && selectedDisciplineId) {
      dispatch(fetchDisciplineResourceProfile({ projectId: selectedProjectId, disciplineId: selectedDisciplineId, accessToken: accessToken }))
    }
  }, [dispatch, selectedDisciplineId, accessToken]);

  /**
 * Set the selected week start date
 * @param {object} args 
 */
  const handleWeekSelectorChange = (args) => {
    let weekStartDate = args.value;
    dispatch(setSelectedWeekStartDate(weekStartDate));
  };

  return (
    <>
      <div className="dwr-resource-change">
        <div className="dwr-resource-chart">
          <ResourceChart discipline={selectedDiscipline} />
        </div>
        <div className="dwr-resource-adjustments">
          <div className="dwr-heading">Adjustments</div>
          <div className="dwr-sub-heading">Discipline:</div>
          <DropDownListComponent id="ddlDisciplinesSelector"
            change={handleDisciplineSelectorChange}
            dataSource={disciplines}
            fields={disciplineFields}
            value={selectedDisciplineId}
            itemTemplate={disciplineSelectorItemTemplate}
            valueTemplate={disciplineSelectorValueTemplate}
            width="240px"
            popupWidth="280px"
            delayUpdate={true}
          />
          <div className="dwr-sub-heading">Week Commencing:</div>
          <DropDownListComponent id="ddlWeekSelector"
            ref={ddlWeekSelector}
            width="240px"
            change={handleWeekSelectorChange}
            dataSource={resourceProfile}
            fields={fields}
            itemTemplate={weekSelectorItemTemplate}
            valueTemplate={weekSelectorValueTemplate}
            popupWidth="280px"
            delayUpdate={true}
          />
          <div className="dwr-sub-heading">Resource Value:</div>
          {resourceProfile && selectedDiscipline && selectedWeekStartDate &&
            <ResourceChange selectedWeekStartDate={selectedWeekStartDate}
              weeklyResources={resourceProfile.filter(resourceProfile => resourceProfile.weekStartDate === selectedWeekStartDate)}
              existingWeeklyResources={existingWeeklyResources}
              discipline={selectedDiscipline} />
          } 
        </div>
      </div>
    </>
  );
}

export default DisciplineWeeklyResourcing;