import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { formatCost } from '../../../../utils';
import { DENOMINATION } from '../../../../constants';
import { selectProjectById } from '../../../../redux/slices/projectsSlice';
import { useSelector } from "react-redux";

var classNames = require('classnames');

const CostImpact = (props) => {
  const { costImpact, scale = 2, denomination = DENOMINATION.auto } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId)); 

  var costImpactClass = classNames({
    'red': costImpact > 5000,
    'amber': costImpact > 0 && costImpact <= 5000,
    'green': costImpact < 0 || isNaN(costImpact),
    'grey': costImpact == 0,
  });
  
  return (
    <>
    <span className={costImpactClass}>
      {isNaN(costImpact) && <>Not Available</>}
      {!isNaN(costImpact) && <>
      {costImpact < 0 && <>-</>}
      {costImpact > 0 && <>+</>}
      {costImpact == 0 && <>&ensp;</>} 
      {getSymbolFromCurrency(selectedProject?.currencyCode)}
      {formatCost(Math.abs(costImpact), scale, denomination.toLowerCase())}
      </>}
    </span>
    </>
  )
};

export default CostImpact;