import React from 'react';
import buildFailedAnimation from './BuildFailedAnimation.gif';


const BuildFailedAnimation = (props) => {
    const { width = '100%', height = '100%' } = props;

    return (
      <img width={width} height={height} src={buildFailedAnimation} alt="Build Failed Animation" />
    )
};

export default BuildFailedAnimation