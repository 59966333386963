import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Inject, Freeze} from '@syncfusion/ej2-react-grids';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimeImpact from '../../../../../components/Shared/atoms/TimeImpact';
import CostImpact from '../../../../../components/Shared/atoms/CostImpact';
import RiskCategory from '../../../../../components/Shared/atoms/RiskCategory';
import { DENOMINATION } from '../../../../../../src/constants';
import { v4 as uuidv4 } from 'uuid';
import './ScenarioOptions.scss';

const ScenarioOptions = (props) => {
  const { options } = props;
  const gridId = "gridOpt" + uuidv4();
  const firstOption = options.filter(opt => opt.optionName == 'Base Option')[0];
  const allOptions = options.filter(opt => opt.optionName != 'Base Option');
  var first;
  const sortingOptions = { columns: [{ field: 'optionName', direction: 'Ascending' }] };

  const gridRowTemplate = (props) => { 
    if(props.index===0){
      first = options.filter(opt => opt.optionName == props.optionName)[0];
    }
    return (
      <>
      {props.index===0 &&  
      <>
       <tr className="scenario-option-row ">
        <td>
          {firstOption.optionName.toLowerCase() === 'base option'&&
            <FontAwesomeIcon icon="times-circle" className="base-option-icon" />
          }
          {firstOption.rank === 1 &&
            <FontAwesomeIcon icon="check-circle" className="best-option-icon" />
          }
          {firstOption.optionName}
        </td>
        <td className="text-left">
          <CostImpact costImpact={firstOption.costImpactDesign} scale={2} denomination={DENOMINATION.auto} />
        </td>
        <td className="text-left">
          <CostImpact costImpact={firstOption.costImpactConstruction} scale={2} denomination={DENOMINATION.auto}  />
        </td>
        <td className="text-left">
          <TimeImpact timeImpactDelayDays={firstOption.ifcCompletionDelayDays} />
        </td>
        <td className="center-text">
          <RiskCategory riskCategory={firstOption.riskCategory} />
        </td>
      </tr>
      <tr><td>&nbsp;</td></tr></>}
      {
        props.index===1 &&   <>  
          <tr className="scenario-option-row ">
        <td>
          {first.optionName.toLowerCase() === 'base option'&&
            <FontAwesomeIcon icon="times-circle" className="base-option-icon" />
          }
          {first.rank === 1 &&
            <FontAwesomeIcon icon="check-circle" className="best-option-icon" />
          }
          {first.optionName}
        </td>
        <td className="text-left">
          <CostImpact costImpact={first.costImpactDesign} scale={2} denomination={DENOMINATION.auto} />
        </td>
        <td className="text-left">
          <CostImpact costImpact={first.costImpactConstruction} scale={2} denomination={DENOMINATION.auto}  />
        </td>
        <td className="text-left">
          <TimeImpact timeImpactDelayDays={first.ifcCompletionDelayDays} />
        </td>
        <td className="center-text">
          <RiskCategory riskCategory={first.riskCategory} />
        </td>
      </tr>
      </>
      }
      {props.index!==0   &&     
          <tr className="scenario-option-row ">
        <td>
          {props.optionName.toLowerCase() === 'base option'&&
            <FontAwesomeIcon icon="times-circle" className="base-option-icon" />
          }
          {props.rank === 1 &&
            <FontAwesomeIcon icon="check-circle" className="best-option-icon" />
          }
          {props.optionName}
        </td>
        <td className="text-left">
          <CostImpact costImpact={props.costImpactDesign} scale={2} denomination={DENOMINATION.auto} />
        </td>
        <td className="text-left">
          <CostImpact costImpact={props.costImpactConstruction} scale={2} denomination={DENOMINATION.auto}  />
        </td>
        <td className="text-left">
          <TimeImpact timeImpactDelayDays={props.ifcCompletionDelayDays} />
        </td>
        <td className="center-text">
          <RiskCategory riskCategory={props.riskCategory} />
        </td>
      </tr>
      }
      </>
    )
  }

  return (
    <>
      {options &&
        <div className="scenario-options-container">
          <GridComponent  id={gridId} 
                          dataSource={allOptions}
                          allowSorting={true}
                          frozenRows={1}
                          rowTemplate={gridRowTemplate}
                          height='600'
                          delayUpdate={true}>
            <ColumnsDirective>
              <ColumnDirective field='optionName' headerText='Option Name' width='200' color='#005A84'/>
              <ColumnDirective field='costImpactDesign' headerText='Forecast Design Cost Impact' width='200' />
              <ColumnDirective field='costImpactConstruction' headerText='Forecast Construction Cost Impact' width='200' />
              <ColumnDirective field='ifcCompletionDelayDays' headerText='Forecast Time Impact' width='200' />
              <ColumnDirective field='riskCategory' headerText='Forecast Risk Category' width='200' />
              <ColumnDirective  width='50' />
            </ColumnsDirective>
            <Inject services={[Page, Sort, Freeze]} />
          </GridComponent>
        </div>
      }
    </>
  );
}

export default ScenarioOptions;