import React from 'react';
import { useSelector } from "react-redux";
import { selectAllActivityScheduleChanges } from '../../../../../redux/slices/createScenarioSlice';
import { TimeIcon } from '../../../../../Icons';
import ScheduleChangeDisplay from '../ScheduleChangeDisplay';

import './ChangeListHistory.scss';
import CapacityChangeDisplay from '../CapacityChangeDisplay';
import { selectAllActivitySchedule } from '../../../../../redux/slices/activityScheduleSlice';

const ChangeListHistory = (props) => {
  const { tabName } = props;
  const { editedResources } = useSelector(state => state.createScenario);
  const activityScheduleChanges = useSelector(selectAllActivityScheduleChanges);
  const activitySchedule = useSelector(selectAllActivitySchedule);
  const { error, isLoading } = useSelector(state => state.activitySchedule);

  /**
  * Returns the top level object in nested array by key value
  * @param {object} array
  * @param {*} fn
  * @returns
  */
   function filter(array, fn) {
    return array.reduce((r, o) => {
        var subtasks = filter(o.subtasks || [], fn);
        if (fn(o) || subtasks.length) r.push(Object.assign({}, o, subtasks.length && { subtasks }));
        return r;
    }, []);
  }

  /**
   * Iterates through nested object to find by key value
   * @param {object} object 
   * @param {string} key 
   * @param {object} predicate 
   * @returns 
   */
  function deepSearch (object, key, predicate) {
    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) return object

    for (let i = 0; i < Object.keys(object).length; i++) {
      let value = object[Object.keys(object)[i]];
      if (typeof value === "object" && value != null) {
        let o = deepSearch(object[Object.keys(object)[i]], key, predicate)
        if (o != null) return o
      }
    }
    return null
  }

  /**
   * Gets the planned duration for the activity from the activity schedule 
   * @param {*} inputActivityId 
   * @returns 
   */
  const getPlannedDuration = (inputActivityId) => {
    if (activitySchedule.length > 0){
      let foundActivity =  deepSearch(activitySchedule, 'activityId', (k, v) => v === inputActivityId);
      return foundActivity.activityPlannedDuration;
    }
    else{
      return null;
    }
  }

  /**
   * Gets the discipline name for this activity from the schedule data
   * @param {Guid} inputActivityId 
   * @returns 
   */
  const getActivityDiscipline = (inputActivityId) => {
    if (activitySchedule.length > 0){
      let filteredActivity = filter(activitySchedule, ({ activityId }) => activityId === inputActivityId);
      return filteredActivity[0].name;
    }
    else{
      return null;
    }
  }
  
  return (
    <>
      <div>
        {tabName === 'schedule' &&
          <>
            <div className='show-title-content-container'>
              <div className='show-header-container'>
                <TimeIcon className='icon' width={28} height={28} /> <span className='list-instruction'>Schedule Change List</span>
              </div>
              <div>
                {activityScheduleChanges.length > 0 && !isLoading &&
                 activityScheduleChanges.map((scheduleChange, i) => {
                    let activityPlannedDuration = getPlannedDuration(scheduleChange.activityId)
                    let disciplineName = getActivityDiscipline(scheduleChange.activityId)
                    return <ScheduleChangeDisplay scheduleChange={scheduleChange} 
                                                  plannedDuration={activityPlannedDuration}
                                                  disciplineName={disciplineName}
                                                  key={i} />
                 })
                }
              </div>
            </div>
          </>
        }
        {tabName === 'Capacity' &&
          <>
            <div className='show-title-content-container'>
              <div className='show-header-container'>
                <TimeIcon className='icon' width={28} height={28} /> <span className='list-instruction'>Capacity Change List</span>
              </div>
              <div>
                {editedResources && editedResources.map((capacityChange, i) => {
                    return <CapacityChangeDisplay capacityChange={capacityChange} key={i} />
                  })
                }
              </div>
            </div>
          </>
        }
      </div>
    </>
  );
}

export default ChangeListHistory;