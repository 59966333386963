import React, { useEffect } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProjectId } from '../../../../redux/slices/projectsSlice';
import ProjectAvatar from '../../atoms/ProjectAvatar';
import { useHistory, useLocation } from 'react-router-dom';
import { removeAlertStatus, setHighlightAlertSourceId, setHighlightAlertTargetId, setSelectedAlertId, setSelectedAlertSourceId, setSelectedAlertTargetId } from '../../../../redux/slices/alertsSlice';
import { setFilteredDisciplineIds, setSelectedDisciplineId, removeAlldisciplines } from '../../../../redux/slices/disciplinesSlice';
import { removeAllPhases, setSelectedPhaseId } from '../../../../redux/slices/phasesSlice';
import { setInfoSelectedPhaseId } from '../../../../redux/slices/infoSlice';
import { removeAllPackages } from '../../../../redux/slices/packagesSlice';
import { setSelectedAlertIdInManager } from '../../../../redux/slices/alertSelectionSlice';
import { setIsChecked } from '../../../../redux/slices/activityChangesSlice';
import { setSelectedScenarioId } from '../../../../redux/slices/scenariosSlice';
import { removeActivityScheduleScenario } from '../../../../redux/slices/createScenarioSlice';
import { removeActivitySchedule } from '../../../../redux/slices/activityScheduleSlice';
import { setProjectRoleDisciplines } from '../../../../redux/slices/authorizationSlice'
import './ProjectSelector.scss';
import { setSelectedInterfacePointNumber } from '../../../../redux/slices/optimisationFocusAreasSlice';

const projectSelectorItemTemplate = (data) => {
  return (
    <div className="ps-item">
      {/* <div className="psi-avatar">
        <ProjectAvatar projectName={data.projectName} />
      </div> */}
      <div className="psi-content">
        {data.projectName}
      </div>
    </div>
  );
};

const projectSelectorValueTemplate = (data) => {
  return (
    <div className="ps-value">
      <div className="psi-value-content">
        {data.projectName}
      </div>
    </div>
  );
};

const projectSelectorCollapsedValueTemplate = (data) => {
  return (
    <div className="ps-item">
      <div className="psi-avatar">
        <ProjectAvatar projectName={data.projectName} />
      </div>
    </div>
  );
};

const ProjectSelector = (props) => {
  const { projects, collapsed = true } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const { roles } = useSelector(state => state.authorization);
  const dispatch = useDispatch();
  const fields = { text: 'projectName', value: 'projectId' };
  const valueTemplate = collapsed ? projectSelectorCollapsedValueTemplate : projectSelectorValueTemplate;
  const history = useHistory();
  const location = useLocation();

  /**
   * Handles project selection change event
   * @param {*} args 
   */
  const handleProjectSelectorChange = (args) => {
    // gets and sets the project Id
    // Redirects to the overview page
    let projectId = args.value;
    history.push(`/${projectId}/home`);
    dispatch(setSelectedAlertIdInManager(undefined));
    dispatch(setSelectedAlertId(undefined));
    dispatch(setSelectedProjectId(projectId));
    dispatch(setSelectedAlertSourceId(undefined));
    dispatch(setSelectedAlertTargetId([]));
    dispatch(setHighlightAlertSourceId(undefined));
    dispatch(setHighlightAlertTargetId([]));
    dispatch(setFilteredDisciplineIds([]));
    dispatch(setSelectedDisciplineId(undefined))
    dispatch(removeAlldisciplines());
    dispatch(removeAllPackages());
    dispatch(setSelectedPhaseId(undefined));
    dispatch(setInfoSelectedPhaseId(undefined));
    dispatch(removeAllPhases());
    dispatch(removeAlertStatus());
    dispatch(setIsChecked(0));
    dispatch(setSelectedScenarioId(undefined))
    dispatch(removeActivityScheduleScenario());
    dispatch(removeActivitySchedule());
    dispatch(setSelectedInterfacePointNumber(undefined));
  };

  useEffect(()=>{
    if(roles && selectedProjectId){
      let userRoles = [];
      let userDisciplines = [];
      for (var i = 0; i < roles.length; i++){
        let currentRole = roles[i].split("_")[0];
        if(currentRole === "GlobalAdmin"){
          userRoles.push(currentRole);
          userDisciplines = [];
        }else{
          let currentProject = roles[i].split("_")[1];
          if(currentProject === selectedProjectId?.toUpperCase()){
            userRoles.push(currentRole);
            if(currentRole === "Contributor"){
              userDisciplines.push(roles[i].split("_")[2])
            }
          }
        }
      }
      if(userRoles.includes('GlobalAdmin')){
        dispatch(setProjectRoleDisciplines({userProjectRole : 'GlobalAdmin', userProjectDisciplines : userDisciplines}))
      }
      else if(userRoles.includes('Owner')){
        dispatch(setProjectRoleDisciplines({userProjectRole : 'Owner', userProjectDisciplines : userDisciplines}))
      }
      else if(userRoles.includes('Contributor')){
        dispatch(setProjectRoleDisciplines({userProjectRole : 'Contributor', userProjectDisciplines : userDisciplines}))
      }
      else if( userRoles.includes('Member')){
        dispatch(setProjectRoleDisciplines({userProjectRole : 'Member', userProjectDisciplines : [...userDisciplines]}))
      }
    }

  },[selectedProjectId, roles])

  return (
    <div className={collapsed? "ps-container collapsed":"ps-container open"} >
      <DropDownListComponent id="ddlProjectSelector"
        change={handleProjectSelectorChange}
        dataSource={projects}
        fields={fields}
        value={selectedProjectId}
        popupWidth="300px"
        itemTemplate={projectSelectorItemTemplate}
        valueTemplate={valueTemplate}
        delayUpdate={true}
      />
    </div>
  );
};


export default ProjectSelector;