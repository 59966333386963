import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import ScenarioOption from '../../molecules/ScenarioOption';
import BaseScenarioOption from '../../molecules/BaseScenarioOption';
import { selectScenarioOptionById } from '../../../../../redux/slices/scenarioOptionsSlice';
import ScenarioOptionsChartContainer from '../ScenarioOptionsChartContainer';
import {fetchPrimaryAlertsByAlert, selectAllAlerts } from '../../../../../redux/slices/alertsSlice';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import './ScenarioOptionsComparison.scss';

const ScenarioOptionsComparison = (props) => {  
  const dispatch = useDispatch();
  const { scenario, options } = props;
  const { selectedScenarioOptionId } = useSelector(state => state.scenarioOptionsComparison);
  const selectedScenarioOption = useSelector((state) => selectScenarioOptionById(state, selectedScenarioOptionId));
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const { selectedProjectId } = useSelector(state => state.projects);
  const alerts = useSelector(selectAllAlerts);
  const scenarioOption = "scenariooption";

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

   /**
   * Fetches the alerts
   */
  useEffect(() => {
    if (accessToken && scenario){
      dispatch(fetchPrimaryAlertsByAlert({ projectId: selectedProjectId, alertId: scenario.alertId, accessToken: accessToken}));
    }
  }, [dispatch, selectedProjectId, accessToken]);
 
  return (
    <div className="scenario-options-heatmap-comparison-container">
       <div className="scenario-options-comparison-properties-container">
          {selectedScenarioOption && selectedScenarioOption.optionName.toLowerCase() !== 'base option' && <ScenarioOption option={selectedScenarioOption} scenarioOption={scenarioOption} scenarioOptionDescription={''}/>}
          {selectedScenarioOption && selectedScenarioOption.optionName.toLowerCase() === 'base option' && <BaseScenarioOption alerts={alerts} scenario={scenario} option={selectedScenarioOption} scenarioOption={scenarioOption} scenarioOptionDescription={''}/>}
      </div>
      <div className="scenario-options-comparison-chart-container">
        <div className="chart-header">
          <div className="chart-title">
              <div>
                 Change Impact Per Discipline
              </div>
            <div>
            <InfoTooltip message={'The relative change in the number of activities as well as hours for a particular option from the planned in a week.'}  iconSize={15} width="none" />
            </div>
          </div>
        </div>
        <div className="chart-content">
          <ScenarioOptionsChartContainer options={options} />
        </div>
      </div>
    </div>
  );
}

export default ScenarioOptionsComparison;