/* Completion status threshold values */
export const COMPLETION_STATUS_THRESHOLD = {
  low: 25.0,
  medium: 50.0,
  high: 75.0,
};

/* Design progress threshold values */
export const DESIGN_PROGRESS_THRESHOLD = {
  low: 5.0,
  high: 15.0,
};

/* Design spend threshold values */
export const DESIGN_SPEND_THRESHOLD = {
  low: 1000,
  high: 10000,
};

/* Progress track colours */
export const PROGRESS_BAR_TRACK_COLOUR = {
  low: '#F8CCD7', //Red
  medium: '#FAEBD3', //Amber
  high: '#C8E5D5', //Green
  default: '#90AFCC', //Grey
  constant: '#D2DBFB' //Blue
};

/* Progress Indicator colours */
export const PROGRESS_BAR_INDICATOR_COLOUR = {
  low: '#ED6D8B', //Red
  medium: '#F0C582', //Amber
  high: '#63B488', //Green
  default: '#90AFCC', //Grey
  constant: '#2853EB' //Blue
};

/* Completion status colours */
export const COMPLETION_STATUS_COLOUR = {
  low: '#EDDCDE', //Red
  medium: '#FFF6ED', //Amber
  high: '#C0E4D1', //Green
  completed: '#C0E4D1', //Green
  default: '#F5F5F5', //White
  milestone:'#4E91CF'
};

export const COMPLETION_STATUS_SELECTED_COLOUR = {
  low: '#FFFFFF', //White
  medium: '#FFFFFF',
  high: '#FFFFFF',
  completed: '#FFFFFF',
  default: '#FFFFFF',
  milestone:'#DBE0E7'
};

export const COMPLETION_STATUS_SELECTED_BORDER_COLOUR = {
  low: '#E41748', //Red
  medium: '#FFAD61', //Amber
  high: '#55AC7D', //Green
  completed: '#55AC7D', //Green
  disabled: '#A7A9AC',
  default: '#8D9DB4',
};

export const COMPLETION_STATUS_PROGRESS_COLOUR = {
  low: '#E41748', //Red
  medium: '#FFAD61', //Amber
  high: '#55AC7D', //Green
  completed: '#55AC7D', //Green
  disabled: '#A7A9AC',
  default: '#8D9DB4',
};

export const COMPLETION_STATUS_PROGRESS_BG_COLOUR = {
  low: '#EEBCC8', //Red
  medium: '#F1DEC2', //Amber
  high: '#87D1C3', //Green
  completed: '#87D1C3', //Green
  disabled: '#F6FAFC',
  default: '#EBEDEF',
};

/* Node state colours */
export const NODE_STATE_COLOUR = {
  default: '#FFFFFF',
  filtered: '#DADEE5',
  filteredBorder: '#B2BAC7',
  filteredProgress: '#B2BAC7',
  filteredProgressBg: '#CCD2DC',
  shadowColour: 'rgba(0, 90, 132, 0.05)'
}

/* Node default colours */
export const NODE_DEFAULT_COLOUR = {
  default: '#FFFFFF',
  filtered: '#C7D0D8',
  hidden: '#FFFFFFF',
}

/* Discipline icons */
export const DISCIPLINE_ICON = {
  highways: 'road',
  drainage: 'tint',
  lighting: 'lightbulb',
  technology: 'microchip',
  geotechnical: 'hard-hat',
  pavement: 'road',
  'design structures' : 'archway',
  structures: 'archway',
  traffic: 'car',
  'traffic signals': 'traffic-light',
  environment: 'leaf',
  'external milestones': 'calendar',
  'default': 'question-circle',
};

export const ALERT_COLOUR = '#F55568';

export const ALERT_STATUS_COLOUR = {
  open: '#E4F1F8',
  active: '#ADE7DC',
  inactive: '#E5E7EB',
  dismissed: '#E9EEF5',
  expired: '#F1DEC2',
  resolved: '#7B61FF'
}

export const DENOMINATION = {
  auto: 'auto',
  thousands: 'thousands',
  millions: 'millions'
}

export const BG_COLOUR = {
  low: '#C0E4D1', // Green
  medium: '#F1DEC2', // Orange
  high: '#FFDDE5', // Red
  equal: "#E9EEF5"  // GREY
}