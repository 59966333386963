// API routes
export const projectsEndpoint = () => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects`;

export const disciplinesEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/disciplines`;

export const packagesEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/packages`;

export const criticalPackagesEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/cirticalpackages`;

export const phasesEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/phases`;

export const alertsEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/alerts`;

export const alertsFilteredEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/alertsFiltered`;

  export const primaryAlertsByAlertEndpoint = (projectId, alertId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/primaryAlerts/${alertId}`;

export const dismissOrUndismissAlertEndpoint = (projectId, alertId, alertStatus, phaseId, packageId,alertType,driver) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/alerts/${alertId}/dismissOrUndismissAlert/${alertStatus}`;

  export const unDismissAlertEndpoint = (projectId, alertId, alertStatus) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/alerts/${alertId}/unDismissAlert/${alertStatus}`;

export const scenariosEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios`;

export const scenarioStatusesEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/statuses`;

export const scenarioEndpoint = (projectId, scenarioId) => 
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/${scenarioId}`;

export const scenarioOptionsEndpoint = (projectId, scenarioId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/${scenarioId}/options`;

export const setScenarioStatusForAlertEndpoint = (projectId, alertId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/alert/${alertId}/scenariostatus`;

export const updateScenarioCommentEndpoint = (projectId, scenarioId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/${scenarioId}/commentupdate`;

export const getScenarioCommentEndpoint = (projectId, scenarioId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/${scenarioId}/getcomment`;

export const defaultConstraintsEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/defaultconstraints`;
  
export const setScenarioConstraintsEndpoint = (projectId, scenarioId) => 
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/${scenarioId}/constraints`;

export const resourceTypesEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/resourcetypes`;
  
export const constructionRisksEndpoint = (projectId) => 
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/constructionrisks`;

export const filteredPhasesEndpoint = (projectId) => 
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/user/phases`;

export const disciplineResourceProfileEndpoint = (projectId, disciplineId) => 
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/disciplines/${disciplineId}/resourceprofile`;

export const disciplineResourceUsageEndpoint = (projectId, disciplineId) => 
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/disciplines/${disciplineId}/resourceusage`;

export const disciplineResourceCapacityEndpoint = (projectId, disciplineId) => 
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/disciplines/${disciplineId}/resourcecapacity`;

export const disciplinesResourceCapacityEndpoint = (projectId, scenarioId, scenarioOptionId) => 
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/resourcecapacity/${scenarioId}/${scenarioOptionId}`;

export const createUserGeneratedScenarioEndPoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/user/create`;

export const updateUserGeneratedScenarioEndPoint = (projectId, scenarioId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/user/${scenarioId}/update`;

export const analyseUserGeneratedScenarioEndPoint = (projectId, scenarioId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/user/${scenarioId}/analyse`;

export const activitiesEndPoint = (projectId, phaseId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/activities/${phaseId}`;

export const activityCompletionStatusUpdateEndPoint = (projectId, activityChanges) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/activityCompletionStatus/${activityChanges}/edit`;

export const activityCompletionPipelineEndPoint = (projectId, phaseId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/activityCompletionPipeline/${phaseId}`;

export const activityCompletionStatusEndPoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/activityCompletionStatus`;

export const deliverablesEndPoint = (projectId, phaseId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/deliverables/${phaseId}`;

export const addUserAppRoleEndPoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/admin/${projectId}/users/assignrole`;

export const appRolesEndPoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/admin/roles/${projectId}`;

export const allUsersEndPoint = (projectId, user) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/admin/${projectId}/search/aduser/${user}`;

export const usersEndPoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/admin/${projectId}/users`;

export const addUserEndPoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/admin/${projectId}/users`;

export const deleteUserEndPoint = (projectId, userId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/admin/${projectId}/users/${userId}/delete`;

export const editUserEndPoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/admin/${projectId}/users/edit`;

export const userGeneratedScenarioSlotAvailableEndPoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/slotdetails`;

export const alertActivityDriversEndPoint = (projectId, alertId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/alerts/${alertId}/alertactivitydrivers`;

export const alertDeliverableDriversEndPoint = (projectId, alertId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/alerts/${alertId}/alertdeliverabledrivers`;

export const phaseProgressEndpoint = (projectId, alertId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/alerts/${alertId}/phaseprogress`;

export const alertStatusesEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/alerts/statuses`;

export const alertMitigationZeroEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/alerts/mitigationzero`;

export const fastestOptionEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/scenarios/options/fastestoption`;

export const protectedResourcesEndpoint = () => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/admin/protectedResources`;

export const roleResourceMappingEndpoint = (roles, projects, disciplines) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/admin/roleResourceMapping/${roles}/${projects}/${disciplines}`;

export const PackagePctCompleteCalcTypeEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/packagePctCompleteCalcTypes`;

export const UpdatePackagePctCompleteCalcTypeEndpoint = (projectId, packageId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/packagePctCompleteCalcTypes/${packageId}`;

  export const activityOptimisationChangeEndpoint = (projectId, scenarioOptionId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/ActivityOptimisationChange?scenarioOptionId=${scenarioOptionId}`;

  export const disciplineImpactEndpoint = (projectId, scenarioOptionId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/disciplineImpact?scenarioOptionId=${scenarioOptionId}`;

export const projectSourceDetailsEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/projectsourcedetails`;

export const activityFocusAreasEndpoint = (projectId) => 
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/activityfocus`;

export const phaseCostCodeEndpoint = (projectId, phaseId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/costcodes/${phaseId}`;

export const dateFormatsEndpoint = () => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/dateformats`;

export const projectRegionalPreferenceUpdateEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/updatepreference`;

export const interfacePointsEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/interfacepoints`;

export const interfacePointTypesEndpoint = (projectId) => 
  `${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/interfacepointtypes`;

export const createInterfacePointEndPoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/interfacepoint`;

export const updateInterfacePointEndPoint = (projectId, interfacePointId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/interfacepoint/${interfacePointId}`;

export const updateInterfacePointStatusEndPoint = (projectId, interfacePointId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/interfacepoint/${interfacePointId}/status`;

export const activityScheduleEndpoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/activitySchedule`;

export const activityScheduleMilestoneEndpoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/activityScheduleMilestone`;

export const relatedAlertsEndPoint = (projectId, alertId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/relatedalerts/${alertId}`;

export const interfaceActivityLinksEndPoint = (projectId, interfacePointId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/interfacepoint/${interfacePointId}/activityLinks`;

export const fullActivityScheduleEndpoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/fullactivityschedule`;

export const focusAreasInterfacePointsEndpoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/focusareas/interfacepoints`;

export const focusAreasInterfacePointDrivingPathEndpoint = (projectId, interfacePointNumber) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/focusareas/interface/${interfacePointNumber}/drivingpath`;
export const interfaceWarningsEndpoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/interfacewarnings`;

export const lastDesignActivityEndpoint = (projectId) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/lastdesignactivity`;

export const totalCountDrivingPathEndpoint = (projectId, interfacePointNumber) =>
`${process.env.REACT_APP_LIGHTHOUSE_API_URL}/projects/${projectId}/focusareas/interface/${interfacePointNumber}/totalcount`;