import './App.css';
import DefaultLayout from './layouts/DefaultLayout';
import ProjectDesignStatusPage from './pages/ProjectDesignStatusPage/ProjectDesignStatusPage';
import ProjectOverviewPage from './pages/ProjectOverviewPage/ProjectOverviewPage';
import ProjectScenariosPage from './pages/ProjectScenariosPage/ProjectScenariosPage';
import ProjectScenarioOptionsComparisonPage from './pages/ProjectScenarioOptionsComparisonPage/ProjectScenarioOptionsComparisonPage';
import CreateScenarioFromAlertContainer from './pages/ProjectScenariosPage/components/molecules/CreateScenarioWizardSteps/CreateScenarioFromAlertContainer';
import ProjectAlertsPage from './pages/ProjectAlertsPage/ProjectAlertsPage';
import ProjectInterfacePointsPage from './pages/ProjectInterfacePointsPage/ProjectInterfacePointsPage';
import ProjectCreateScenarioPage from './pages/ProjectCreateScenarioPage/ProjectCreateScenarioPage';
import ProjectOptimisationFocusAreasPage from './pages/ProjectOptimisationFocusAreasPage/ProjectOptimisationFocusAreasPage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import history from './utils/history';
import { library } from '@fortawesome/fontawesome-svg-core';
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import {
  faExclamationCircle,
  faExclamationTriangle,
  faFileContract,
  faHardHat,
  faLightbulb,
  faListAlt,
  faPencilRuler,
  faProjectDiagram,
  faTimesCircle,
  faTint,
  faWaveSquare,
  faCogs,
  faCog,
  faWrench,
  faRoad,
  faGlobeEurope,
  faCalendar,
  faMicrochip,
  faInfoCircle,
  faCheckCircle,
  faHammer,
  faClock,
  faArchway,
  faQuestionCircle,
  faPlusCircle,
  faUserCircle,
  faLink,
  faEdit,
  faSave,
  faCommentAlt,
  faUsers,
  faUser,
  faPlus,
  faMinusSquare,
  faPlusSquare,
  faTrafficLight,
  faLeaf,
  faCar,
  faCubes,
  faBusinessTime,
  faCheck,
  faCaretDown,
  faCaretUp,
  faBox,
  faPaperPlane,
  faChartBar,
  faLevelDownAlt,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faAngleDoubleDown,
  faSignInAlt,
  faTrashAlt,
  faStar,
  faCopy,
  faSearch,
  faUserPlus,
  faSync,
  faExpand,
  faExpandArrowsAlt,
  faMinus,
  faSortAmountUp,
  faSortAmountDown,
  faFilter,
  faTimes,
  faSort
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faPencilRuler,
  faExclamationCircle,
  faExclamationTriangle,
  faFileContract,
  faHardHat,
  faTint,
  faLightbulb,
  faTimesCircle,
  faWaveSquare,
  faProjectDiagram,
  faListAlt,
  faCogs,
  faCog,
  faWrench,
  faRoad,
  faGlobeEurope,
  faCalendar,
  faMicrochip,
  faInfoCircle,
  faCheckCircle,
  faHammer,
  faClock,
  faArchway,
  faQuestionCircle,
  faInfoCircle,
  faPlusCircle,
  faUserCircle,
  faLink,
  faEdit,
  faSave,
  faCommentAlt,
  faUsers,
  faUser,
  faMinusSquare,
  faPlusSquare,
  faTrafficLight,
  faLeaf,
  faCar,
  faCubes,
  faBusinessTime,
  faCheck,
  faCaretDown,
  faCaretUp,
  faBox,
  faPaperPlane,
  faChartBar,
  faLevelDownAlt,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faAngleDoubleDown,
  faSignInAlt,
  faTrashAlt,
  faStar,
  faCopy,
  faSearch,
  faUserPlus,
  faSync,
  faPlus,
  faExpand,
  faExpandArrowsAlt,
  faMinus,
  faSortAmountUp,
  faSortAmountDown,
  faFilter,
  faTimes,
  faSort
);

function ErrorComponent({ error }) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <p className="welcome">Authentication in progress...</p>;
}

function App({ pca }) {
  const authRequest = {
    scopes: ["openid"]
  };

  return (
    <MsalProvider instance={pca}>
      <Router history={history} getUserConfirmation={() =>{}}>
        <DefaultLayout>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
          >
            <Route exact path='/' component={ProjectOverviewPage} />
            <Route exact path='/:projectId/home' component={ProjectOverviewPage} />
            <Route exact path='/:projectId/designstatus' component={ProjectDesignStatusPage} />
            <Route exact path='/:projectId/scenarios' component={ProjectScenariosPage} />
            <Route exact path='/:projectId/createscenario' component={ProjectCreateScenarioPage} />
            <Route exact path='/:projectId/alerts' component={ProjectAlertsPage} />
            <Route exact path='/:projectId/interfacepoints' component={ProjectInterfacePointsPage} />
            <Route exact path='/:projectId/manager/scenarios/createalertscenario/:routeAlertId' component={CreateScenarioFromAlertContainer}  />
            <Route exact path='/:projectId/scenarios/:scenarioId/compareoptions' component={ProjectScenarioOptionsComparisonPage} />
            <Route exact path='/:projectId/scenarios/:scenarioId/editscenario' component={ProjectCreateScenarioPage} />
            <Route exact path='/:projectId/scenarios/createscenario' component={ProjectCreateScenarioPage} />
            <Route exact path='/:projectId/optimisation/focusareas' component={ProjectOptimisationFocusAreasPage} />
          </MsalAuthenticationTemplate>
        </DefaultLayout>
      </Router>
    </MsalProvider>
  );
}

export default App;