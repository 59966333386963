import React from 'react';
import { ReactComponent as SncLavalinLogoFull } from './snc-lavalin-logo-full.svg';
import { ReactComponent as SncLavalinLogoGraphic } from './snc-lavalin-logo-graphic.svg';
import './SncLavalinLogo.scss';

var classNames = require('classnames');

const SncLavalinLogo = (props) => {
  return (
    <div className="logo-container">
      <div className="logo-full">
        <div className="logo-graphic">
          <SncLavalinLogoGraphic className={classNames({ 'fade-out': props.collapsed, 'fade-in': !props.collapsed })} />
        </div>
        <SncLavalinLogoFull className={classNames({ 'fade-out': !props.collapsed, 'fade-in': props.collapsed })} />
      </div>
    </div>
  )
};

export default SncLavalinLogo;