import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import { getFormattedDate } from '../../../../../utils';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Toolbar, Filter } from '@syncfusion/ej2-react-treegrid';
import InterfaceActivitySelectorElement from '../../molecules//InterfaceActivitySelectorElement/InterfaceActivitySelectorElement';
import '../../../../../theme/Forms/formstyles.scss';
import './InterfaceActivitySelector.scss';

const InterfaceActivitySelector = (props) => {
  const {activitySchedule, selectedActivities, editActivity} = props;
  const { isLoading, error } = useSelector(state => state.activityScheduleMilestone);
  const grdActivityTree = React.createRef();
  
  let selectedActivitiesArray = selectedActivities.slice();

  const dataBound=(event)=>{
    if(editActivity){
      let rows = grdActivityTree.current.getContentTable().rows;
      let disciplineId = grdActivityTree.current.flatData.find(a=>a.activityId == editActivity).parentItem.parentItem.parentItem.disciplineId;
      let packageId = grdActivityTree.current.flatData.find(a=>a.activityId == editActivity).parentItem.parentItem.packageId;
      let phaseId = grdActivityTree.current.flatData.find(a=>a.activityId == editActivity).parentItem.phaseId;
      for (let i=0; i<rows.length; i++)
      {
        if(rows[i].id == disciplineId || rows[i].id == packageId )
        {
          grdActivityTree.current.expandRow(rows[i]);
        }
        else if(rows[i].id == phaseId)
        {
          grdActivityTree.current.expandRow(rows[i]);
        }
        else if(rows[i].id == editActivity)
        {
          rows[i].scrollIntoView(false);
          break;
        }
      };
    }
  }


  const treeRowTemplate = (activitySchedule) => {
    let checked = false;
    if(selectedActivities && selectedActivities.some(p=>p.activityId == activitySchedule.activityId)){
      checked = true;
    }
    return (
        <tr className='activity-driver-row' id={activitySchedule.disciplineId ? activitySchedule.disciplineId : activitySchedule.packageId ? activitySchedule.packageId : activitySchedule.phaseId ? activitySchedule.phaseId : activitySchedule.activityId}>
          <td className='text-left-align'> 
              <div>{activitySchedule.type}</div>
          </td>
          <td>
            <InterfaceActivitySelectorElement activitySchedule={activitySchedule}/>
          </td>
          <td className='text-left-align'> 
            <div>
            {(activitySchedule.type === 'Task' || activitySchedule.type === 'Milestone' || activitySchedule.type === 'Finish Milestone') && activitySchedule.activityCode}
            </div>
          </td>
          <td className='text-left-align'> 
            <div>
            {(activitySchedule.type === 'Task' || activitySchedule.type === 'Milestone' || activitySchedule.type === 'Finish Milestone') && getFormattedDate(activitySchedule.plannedStartDate)}
            </div>
          </td>
          <td className='text-left-align'> 
            <div>
            {(activitySchedule.type === 'Task' || activitySchedule.type === 'Milestone' || activitySchedule.type === 'Finish Milestone') &&  getFormattedDate(activitySchedule.plannedEndDate)}
            </div>
          </td>
          <td className='text-center-align'>
            {(activitySchedule.type === 'Task' || activitySchedule.type === 'Milestone' || activitySchedule.type === 'Finish Milestone') && 
              <CheckBoxComponent label="" name="activitySelected" value={
                {
                  activityId: activitySchedule.activityId, 
                  activityName: activitySchedule.name,
                  activityCode: activitySchedule.activityCode,
                  actualStartDate: activitySchedule.actualStartDate,
                  actualEndDate: activitySchedule.actualEndDate,
                  plannedStartDate: activitySchedule.plannedStartDate,
                  plannedEndDate: activitySchedule.plannedEndDate
                }
              } checked={checked} labelPosition="After" onChange={rowSelected}/>
            }
          </td>
        </tr>
    );
  }
  
  const toolbarOptions = ['Search'];
  
  /**
  * Adds/removes the activity to the list when checkbox is checked/unchecked
  */
  const rowSelected =(event) => {
    event.syntheticEvent.stopPropagation();
    if(event.target.properties.checked == true){
      if(!selectedActivitiesArray.some(p=>p.activityId==event.target.properties.value.activityId)){
        selectedActivitiesArray.push(
          {
            activityId: event.target.properties.value.activityId, 
            activityName: event.target.properties.value.activityName,
            activityCode: event.target.properties.value.activityCode,
            actualStartDate: event.target.properties.value.actualStartDate,
            actualEndDate: event.target.properties.value.actualEndDate,
            plannedStartDate: event.target.properties.value.plannedStartDate,
            plannedEndDate: event.target.properties.value.plannedEndDate,
          }
        );
      }
    }
    else if(event.target.properties.checked == false){
      if(selectedActivitiesArray.some(p=>p.activityId==event.target.properties.value.activityId)){
        const index = selectedActivitiesArray.findIndex(p=>p.activityId==event.target.properties.value.activityId);
        if (index > -1) { // only splice array when item is found
          selectedActivitiesArray.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
  }

  const nameHeaderTemp = () => {
    return (<div className='text-name-align'>Name:</div>);
  };

  /**
  * Handles close of Add New Activity Link
  */
  const closeNewActivityLinkClick = () => {
    props.closeNewActivityLinkClick();
  }

  /**
  * Handles adding of Add New Activity Link
  */
  const addNewActivityLinkClick = () => {
    props.addNewActivity(selectedActivitiesArray);
  }

  return (
    <>
    { isLoading === true && 
      <CircularProgressBar />
    }
    { error && isLoading !== true &&
      <ErrorMessage message="Unable to display activities" />
    }
    { isLoading === false && !error &&
    <div className="activity-link-container">
      <div className="ias-container">
        <label className="ias-label">Add activities you want to link with the interface.</label> 
        <div className="activity-change-container">
          <div className='treeGrid-container'>
            <TreeGridComponent
              id='grdActivityTree'
              ref={grdActivityTree}
              className='treeGrid-class'
              rowTemplate={treeRowTemplate}
              enableCollapseAll={true}
              dataSource={activitySchedule}
              childMapping="subtasks"
              treeColumnIndex={1}
              toolbar={toolbarOptions}
              allowTextWrap={true}
              allowSelection={true}
              dataBound={dataBound}
              height="500" 
              width="100%"
            >
              <Inject services={[Filter, Toolbar]}/>
              <ColumnsDirective>
                <ColumnDirective headerText='Type:' field='type' width='80' textAlign='left' ></ColumnDirective>
                <ColumnDirective field='name' headerTemplate={nameHeaderTemp} width='500' textAlign='left'></ColumnDirective>
                <ColumnDirective field='code' width='200' headerText='Code:' textAlign='left'></ColumnDirective>
                <ColumnDirective field='startDate' width='100' headerText='Start Date:' textAlign='left'></ColumnDirective>
                <ColumnDirective field='endDate' width='100' headerText='End Date:' textAlign='left'></ColumnDirective>
                <ColumnDirective field='select' headerText='Select:' textAlign='right'></ColumnDirective>
              </ColumnsDirective>
            </TreeGridComponent>
          </div>
        </div>
      </div>
      <div className="add-link-button">
        <PrimaryLighthouseButton className="mr-15" onClick={addNewActivityLinkClick}>Add Link</PrimaryLighthouseButton>
        <SecondaryLighthouseButton onClick={closeNewActivityLinkClick}>Cancel</SecondaryLighthouseButton>
      </div>
    </div>
    }
    </>
  );
}

export default InterfaceActivitySelector;