import React from 'react';
import './CircularProgressIndicator.scss';

const CircularProgressIndicator = (props) => {

let { size = 150, 
      progress = 0, 
      lineWidth = 10, 
      trackColor = `#90AFCC`, 
      indicatorColor = `#90AFCC`, 
      indicatorCap = `round`, 
      labelColor = `#333`, 
      labelSize = '18px',} = props;

  const center = size / 2, 
        radius = center - lineWidth, 
        dashArray = 2 * Math.PI * radius, 
        dashOffset = dashArray * ((100 - Math.round(progress)) / 100);

  return (
    <>
      <div className="progress-bar-comp" style={{ width: size, height: size }} >
        <svg className="progress-bar-comp-pi" style={{ width: size, height: size }} >
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={lineWidth}
          />
          <circle
            style={{ animationDuration: 1000 }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={lineWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>
          <div className={`progress-bar-comp-pi-label ${progress === null?'comp-pi-label-highlight':''}`} style={{ color: labelColor, fontSize: labelSize }} >
             <span > {`${ progress !== null? progress > 100 ? '100%' : Math.round(progress) +'%' : '-' }`} </span>
          </div>
      </div>
    </>
  )
  
}

export default CircularProgressIndicator;