import React, { useEffect, useState } from 'react';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useDispatch, useSelector } from "react-redux";
import AlertList from '../AlertList';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';

import { fetchAlerts, selectAllAlerts, selectAlertById, setSelectedAlertId } from '../../../../../redux/slices/alertsSlice';

import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { fetchScenarios } from '../../../../../redux/slices/scenariosSlice';
import { fetchPhases } from '../../../../../redux/slices/phasesSlice';

const AlertListContainer = (props) => {
  const dispatch = useDispatch();
  const { selectedAlertId, error, isLoading, visibleAlertIds, alertsDdvToggle } = useSelector(state => state.alerts);
  const selectedAlert = useSelector((state) => selectAlertById(state, selectedAlertId));
  const alerts = useSelector(selectAllAlerts);
  // const [filteredAlerts, setFilteredAlerts] = useState([]);
  const { selectedProjectId } = useSelector(state => state.projects);

  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [primaryAlerts, setPrimaryAlerts] = useState([]);
  const [secondaryAlerts, setSecondaryAlerts] = useState([]);

  let activeAlerts = [];

  const getAlerts = () => {
    activeAlerts = alerts.filter((alert) => alert.alertStatus.toLowerCase() === "active" || alert.alertStatus.toLowerCase() === "open");

    if (activeAlerts.length > 0 && !alertsDdvToggle) {
      setPrimaryAlerts(activeAlerts.filter(alert => alert.alertType.toLowerCase() === "primary").sort((a, b) => a.priority > b.priority ? 1 : -1));
      setSecondaryAlerts(activeAlerts.filter(alert => alert.alertType.toLowerCase() === "secondary").sort((a, b) => a.priority > b.priority ? 1 : -1));
    }
    //console.log('Visible alert Ids', visibleAlertIds);
    // split the alerts into two lists, primary and secondary
    // primary alerts have the top 5 alerts that are type primary
    else if (activeAlerts.length > 0 && visibleAlertIds ) {
      //alerts.sort((a, b) => a.priority > b.priority || !b.priority ? 1 : -1);
      //console.log("sorted alert data:", alerts);
      setPrimaryAlerts(activeAlerts.filter((a) => visibleAlertIds.includes(a.alertId)).filter(alert => alert.alertType.toLowerCase() === "primary").sort((a, b) => a.priority > b.priority ? 1 : -1));
      setSecondaryAlerts(activeAlerts.filter((a) => visibleAlertIds.includes(a.alertId)).filter(alert => alert.alertType.toLowerCase() === "secondary").sort((a, b) => a.priority > b.priority ? 1 : -1));
    // } else if (alerts.length > 0) {
    //   //alerts.sort((a, b) => a.priority > b.priority || !b.priority ? 1 : -1);
    //   //console.log("sorted alert data:", alerts);
    //   setPrimaryAlerts(alerts.filter(alert => alert.alertType.toLowerCase() === "primary").sort((a, b) => a.priority > b.priority ? 1 : -1));
    //   setSecondaryAlerts(alerts.filter(alert => alert.alertType.toLowerCase() === "secondary").sort((a, b) => a.priority > b.priority ? 1 : -1));
    } else {
      setPrimaryAlerts([]);
      setSecondaryAlerts([]);
    }
  }

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  /**
   * Fetches the alert list
   */
  useEffect(() => {
    if (accessToken){
      dispatch(fetchAlerts({ projectId: selectedProjectId, accessToken: accessToken}));
      dispatch(fetchScenarios({ projectId: selectedProjectId, accessToken: accessToken }));
      getAlerts();
    }
  }, [dispatch, selectedProjectId, accessToken]);

  useEffect(() => {
    getAlerts();
  }, [alertsDdvToggle]);

  useEffect(() => {
    getAlerts();
  }, [visibleAlertIds]);  

  useEffect(() => {
    getAlerts();
  }, [alerts]);
  
  return (
    <>
    { isLoading && 
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to get alert list" />
      }
      { !isLoading && !error && primaryAlerts && secondaryAlerts &&
        <AlertList primaryAlerts={primaryAlerts} secondaryAlerts={secondaryAlerts} selectedAlert={selectedAlert}/>
      }
    </>
  );
}

export default AlertListContainer;