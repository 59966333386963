import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dagre from 'dagre';
import { useEffect, useState } from 'react';
import ReactFlow, {
  Background, ControlButton, Controls, getConnectedEdges, getIncomers,
  getOutgoers, isEdge, isNode, ReactFlowProvider, useStoreState
} from 'react-flow-renderer';

import { useAccount, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlertsDdvToggle, setSelectedAlertId, setSelectedAlertSourceId,
  setSelectedAlertTargetId,
  setVisibleAlertIds,
} from '../../../../../redux/slices/alertsSlice';

import Edge from '../../molecules/Edge';
import KemNode from '../../molecules/KemNode';
import PhaseNode from '../../molecules/PhaseNode';
import AlertFinder from '../AlertFinder';

import { setProgressBarHeight } from '../../../../../redux/slices/dpvSlice';
import { setInfoSelectedPhaseId, setSelectedTab } from '../../../../../redux/slices/infoSlice';
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';
import './DesignProductionViewer.scss';

import { appInsights } from '../../../../../AppInsights';
import { LighthouseTooltip } from '../../../../../utils';
import { COMPLETION_STATUS_PROGRESS_COLOUR } from '../../../../../constants';
let trackData = require('../../../../../appTrackerInfo.json');


/**
 * Node types
 */
const nodeTypes = {
  phase: PhaseNode,
  kem: KemNode,
};

/**
 * Edge types
 */
const edgeTypes = {
  edge: Edge
};

/** Set width and height of nodes */
var nodeWidth = 300;
const nodeHeight = 36;

/**
 * Gets layouted items from raw elements
 * @param {json} rawElements
 * @param {string} direction
 * @returns 
 */
const getLayoutedElements = (rawElements, direction = 'LR') => {
  /** Initialise auto layout libray */
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === 'LR';

  dagreGraph.setGraph({
    rankdir: direction,
    align: 'DR',
    ranker: 'tight-tree',
    nodesep: 36,
    edgesep: 10,
    ranksep: 50,
  });

  rawElements.forEach((el) => {
    if (isNode(el)) {
      if (el.type === "alert") {
        nodeWidth = 36;
        //console.log("alert:", el);
      }

      //console.log("node:", el);
      dagreGraph.setNode(el.id,
        {
          width: nodeWidth,
          height: nodeHeight
        }
      );
    } else {
      dagreGraph.setEdge(el.source, el.target,
        {
          minlen: 4,
        }
      );
    }
  });

  dagre.layout(dagreGraph);

  return rawElements.map((el) => {
    if (isNode(el)) {
      //console.log("el", el);
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // unfortunately we need this little hack to pass a slighltiy different position
      // to notify react flow about the change. More over we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWithPosition.width / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeWithPosition.height / 2,
      };
    }

    if (isEdge(el)) {
      if (!el.type) {
        el.type = 'edge';
      }
      el.arrowHeadType = 'arrow';
    }

    return el;
  });
};

/** Node debugger */
const NodesDebugger = () => {
  const nodes = useStoreState((state) => state.nodes);
  //console.log(nodes);
  return null;
};

/**
 * The design production viewer main component
 * @param {*} props 
 * @returns 
 */
const DesignProductionViewer = (props) => {
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { accounts, instance } = useMsal();
  const { dpvData, filteredDisciplineIds } = props;

  const [isSelectable, setIsSelectable] = useState(true);
  const [isDraggable, setIsDraggable] = useState(false);
  const [isConnectable, setIsConnectable] = useState(false);
  const [zoomOnScroll, setZoomOnScroll] = useState(true);
  const [panOnScroll, setPanOnScroll] = useState(false);
  const [panOnScrollMode, setPanOnScrollMode] = useState('free');
  const [zoomOnDoubleClick, setZoomOnDoubleClick] = useState(true);
  const [captureElementClick, setCaptureElementClick] = useState(true);
  const { selectedAlertId } = useSelector(state => state.alerts);
  const initialLayoutedElements = getLayoutedElements(dpvData, 'LR');
  const [filteredElements, setFilteredElements] = useState(initialLayoutedElements);
  const [elements, setElements] = useState(initialLayoutedElements);
  const [reactFlowInstance, setReactFlowInstance] = useState();
  const account = useAccount(accounts[0] || {});
  const [ddvToggle, setDdvToggle] = useState(false)
  const dispatch = useDispatch();

  const onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView({ padding: 0.1 });
    setReactFlowInstance(reactFlowInstance);
  };

  /**
   * Pane clicked in viewer
   * @param {*} evt 
   */
  const onPaneClick = (evt) => {
    // clear selections
    selectElement(null);
    dispatch(setInfoSelectedPhaseId(undefined));
    dispatch(setSelectedAlertId(undefined));
    dispatch(setSelectedAlertSourceId(undefined));
    dispatch(setSelectedAlertTargetId([]));
    setElements(initialLayoutedElements);
    setFilteredElements(initialLayoutedElements);
  };

  /**
   * Element clicked in viewer
   * @param {*} evt 
   * @param {*} element 
   */
  const onElementClick = (evt, element) => {
    let selectedPhaseId = undefined;
    if (isEdge(element)) {
      // do nothing
    }
    else if (isNode(element)) {
      // WBS / Phase
      let eleName = '';
      if (element.type === "kem" || element.type === "Kem") {
        eleName = element.type.toUpperCase();
      } else {
        eleName = element.type.charAt(0).toUpperCase() + element.type.slice(1);
      }
      selectedPhaseId = element.id;
      dispatch(setInfoSelectedPhaseId(selectedPhaseId));
      dispatch(setSelectedAlertId(undefined));
      dispatch(setSelectedTab("PropertyViewer"));
      appInsights.trackEvent({
        name: `DPM - DPV - ${eleName} Click`,
        properties: {
          Page: `${trackData.pages[1].name}`,
          Type: `${eleName}`,
          Project: `${selectedProjectId}`,
          ProjectName: `${selectedProject.projectName}`,
          User: `${account.username}`,
          Username: `${account.name}`,
          Role: 'Owner'
        }
      });
    }
    else {
      //console.log("clicked element not recognised");
    }

    selectElement(element);
  }

  /**
   * Filters the DPV elements based on the passed element
   * @param {object*} elementId 
   */
  const filterElements = (elementId) => {
    if (elementId) {
      let selectedPhase = undefined;
      if (elementId.type == "alert") {
        selectedPhase = elements.find((p) => p.id == elementId.data.alerts[0].phaseId);
      } else if (elementId.alertId) {
        selectedPhase = elements.find((p) => p.id == elementId.phaseId);
      } else {
        selectedPhase = elementId;
      }
      var packageElementList = elements.filter((e) => e.data.packageId == selectedPhase.data.packageId);
      packageElementList = packageElementList.filter((v, i, a) => a.indexOf(v) === i);
      var packageListPlusOneDegree = packageElementList;
      packageElementList.forEach((x) => {
        getOutgoers(x, elements).forEach((e) => packageListPlusOneDegree.push(e));
        getIncomers(x, elements).forEach((e) => packageListPlusOneDegree.push(e));
      });
      packageListPlusOneDegree = packageListPlusOneDegree.filter((v, i, a) => a.indexOf(v) === i);
      getConnectedEdges(packageElementList, elements.filter((j) => j.type == "alert" || j.type == "edge")).forEach((e) => packageListPlusOneDegree.push(e));
      packageListPlusOneDegree = packageListPlusOneDegree.filter((v, i, a) => a.indexOf(v) === i);

      var packageElementIdList = [];
      packageElementList.forEach((l) => packageElementIdList.push(l.id));
      packageElementIdList = packageElementIdList.filter((v, i, a) => a.indexOf(v) === i);

      packageListPlusOneDegree = packageListPlusOneDegree.filter((k) => (packageElementIdList.indexOf(k.source) > -1 && packageElementIdList.indexOf(k.target) > -1) || k.type === "phase" || k.type === "kem");

      let alertIds = [];
      (packageListPlusOneDegree.filter((a) => a.type === "alert")).filter((v, i, a) => a.indexOf(v) === i).forEach(b => b.data.alerts.forEach((c) => alertIds.push(c.alertId)));

      dispatch(setVisibleAlertIds(alertIds.filter((v, i, a) => a.indexOf(v) === i)));

      setFilteredElements(getLayoutedElements(packageListPlusOneDegree, 'LR'));
    }
  };

  const toggleDdv = () => {
    dispatch(setAlertsDdvToggle(!ddvToggle));
    setDdvToggle(!ddvToggle);
  };


  /**
   * Selects a DPV element
   * @param {*} element 
   */
  const selectElement = (element) => {
    if (ddvToggle) {
      // if the drill down mode is on filter the elements
      // based on the element being selected
      filterElements(element);
    }
  }

  /**
   * On Pan/Zoom in DPV
   */
  const onMove = (event) => {
    let zoomLevel = event.zoom;
    let height = '16px'
    if (zoomLevel >= '0.25' && zoomLevel < '0.3') {
      height = '15px';
    }
    else if (zoomLevel >= '0.3' && zoomLevel < '0.4') {
      height = '14px';
    }
    else if (zoomLevel >= '0.4' && zoomLevel < '0.55') {
      height = '13px';
    }
    else if (zoomLevel >= '0.55' && zoomLevel < '0.7') {
      height = '12px';
    }
    else if (zoomLevel >= '0.7' && zoomLevel < '0.9') {
      height = '10px';
    }
    else if (zoomLevel >= '0.9' && zoomLevel < '1.2') {
      height = '8px';
    }
    else if (zoomLevel >= '1.2' && zoomLevel < '1.6') {
      height = '6px';
    }
    else if (zoomLevel >= '1.6' && zoomLevel < '2') {
      height = '4px';
    }
    else if (zoomLevel >= '2') {
      height = '4px';
    }

    dispatch(setProgressBarHeight(height));
  }

  /* filter elements based on discipline selection */
  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.data) {
          if (!filteredDisciplineIds.length) {
            el.data = {
              ...el.data,
              isFiltered: false,
              isHidden: false
            }
          }
          else {
            if (!filteredDisciplineIds.includes(el.data.disciplineId) && ddvToggle) {
              el.data = {
                ...el.data,
                isFiltered: true,
                isHidden: true
              }
            }
            else if (!filteredDisciplineIds.includes(el.data.disciplineId)) {
              el.data = {
                ...el.data,
                isFiltered: true,
                isHidden: false
              }
            }
            else {
              el.data = {
                ...el.data,
                isFiltered: false,
                isHidden: false
              }
            }
          }
        }
        return el;
      })
    );
  }, [filteredDisciplineIds, setElements]);

  /* selected alert changed */
  useEffect(() => {
    if (selectedAlertId){
      if (reactFlowInstance){
        // find the phase for this alert id and use this to select the element
        let dpvElementsWithAlerts = reactFlowInstance.getElements().filter((a) => a.type === "phase" && a.data.alerts !== undefined);
        let selectedPhasewithAlert = dpvElementsWithAlerts.find(p => p.data.alerts.find(a => a.alertId == selectedAlertId));
        let selectedAlert = selectedPhasewithAlert.data.alerts?.find(a => a.alertId === selectedAlertId);
        selectElement(selectedAlert);
      }
    }
    
  }, [selectedAlertId]);

  /* React flow update */
  useEffect(() => {
    if (reactFlowInstance) {
      reactFlowInstance.fitView({ padding: 0.1 });
    }
    let alertIds = [];
    (filteredElements.filter((a) => a.type === "phase" && a.data.alerts !== undefined)).filter((v, i, a) => a.indexOf(v) === i).forEach(b => b.data.alerts.forEach((c) => alertIds.push(c.alertId)));

    //console.log("setting visible alert ids - in hook", filteredElements, alertIds, alertIds.filter((v, i, a) => a.indexOf(v) === i))
    dispatch(setVisibleAlertIds(alertIds.filter((v, i, a) => a.indexOf(v) === i)));

  }, [filteredElements])

  useEffect(() => {
    if (reactFlowInstance) {
      reactFlowInstance.fitView({ padding: 0.1 });
    }
    //console.log('Toggled', ddvToggle);
  }, [ddvToggle])

  return (
    <>
      <div className="design-production-viewer">
        <ReactFlowProvider>
          <ReactFlow
            elements={ddvToggle ? filteredElements : elements}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            elementsSelectable={isSelectable}
            nodesConnectable={isConnectable}
            nodesDraggable={isDraggable}
            zoomOnScroll={zoomOnScroll}
            panOnScroll={panOnScroll}
            panOnScrollMode={panOnScrollMode}
            zoomOnDoubleClick={zoomOnDoubleClick}
            onPaneClick={onPaneClick}
            onElementClick={captureElementClick ? onElementClick : undefined}
            defaultPosition={[0, 0]}
            onLoad={onLoad}
            onlyRenderVisibleElements={true}
            minZoom={0}
            onMoveEnd={onMove}
          />
          <Background color="#FAFCFF" />
          <Controls showInteractive={false} className="dpv-controls">
            <LighthouseTooltip content="Drilldown Mode">
              <ControlButton onClick={toggleDdv} className={ddvToggle ? "ddv-toggle ddv-active" : "ddv-toggle ddv-inactive"}>
                <FontAwesomeIcon icon="angle-double-down" />
              </ControlButton>
            </LighthouseTooltip>
          </Controls>
          <AlertFinder />
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default DesignProductionViewer;