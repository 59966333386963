import React, {useEffect} from 'react';
import { renderToString } from 'react-dom/server';
import { Handle } from 'react-flow-renderer';
import { useSelector } from "react-redux";
import { LighthouseTooltip, getFormattedDate } from '../../../../../utils';
import { setSelectedFocusAreaNodeId, setHoverFocusAreaNodeId } from '../../../../../redux/slices/optimisationFocusAreasSlice';
import { useDispatch } from "react-redux";
import classNames from "classnames";

import './ActivityNode.scss';

const ActivityNode = (props) => {
  const { id, 
          label,
          isDrivingPath,
          earliestStartDate,
          earliestFinishDate,
          latestStartDate,
          latestFinishDate
         } = props.data;
  const dispatch = useDispatch();
  const { selectedFocusAreaNodeId, selectedHoverFocusAreaNodeId } = useSelector(state => state.optimisationFocusAreas);
  const isSelected = selectedFocusAreaNodeId === id;
  const isHover = selectedHoverFocusAreaNodeId === id;

  const toolTipRef =  React.createRef();

  /**
   * Set the hover focus area node id in state
   */
  const onMouseEnter = () => { 
    if (selectedFocusAreaNodeId){
      dispatch(setHoverFocusAreaNodeId(id));
    }
  };

  /**
   * Clear the hover focus area node id in state
   */
  const onMouseLeave = () => { 
    if (selectedFocusAreaNodeId){
      dispatch(setHoverFocusAreaNodeId(undefined));
    }
  };

  /**
 * Tooltip template
 * @param {object} props 
 * @returns 
 */
  const TooltipTemplate = (props) => {
    return (
      <>
        <div className='templete-tooltip-container'>
          <div className='templete-tooltip-field'>
            <span className='text-title' >Activity:</span>
            <span className='text-desc' style={{ width: '200px' }}>{props.label}</span>
          </div>
          <div className='templete-tooltip-field'>
            <span className='text-title'>ES (Earliest Start Date):</span>
            <span className='text-desc'>{getFormattedDate(props?.earliestStartDate)}</span>
          </div>
          <div className='templete-tooltip-field'>
            <span className='text-title'>EF (Earliest Finish Date):</span>
            <span className='text-desc'>{getFormattedDate(props?.earliestFinishDate)}</span>
          </div>
          <div className='templete-tooltip-field'>
            <span className='text-title'>LS (Latest Start Date):</span>
            <span className='text-desc'>{getFormattedDate(props.latestStartDate)}</span>
          </div>
          <div className='templete-tooltip-field'>
            <span className='text-title'>LF (Latest Finish Date):</span>
            <span className='text-desc'>{getFormattedDate(props.latestFinishDate)}</span>
          </div>
        </div>
      </>
    );
  }

  /**
   * Sets the selected focus area Id
   */
  const onClick = () =>{
    if (selectedFocusAreaNodeId !== id){
      dispatch(setSelectedFocusAreaNodeId(id));
    }
    else{
      dispatch(setSelectedFocusAreaNodeId(undefined));
    }
  }

  useEffect(() => {
    if(selectedHoverFocusAreaNodeId && selectedHoverFocusAreaNodeId === id) {
      toolTipRef.current?.open();
    }
    else{
      toolTipRef.current?.close();
    }
  },[selectedHoverFocusAreaNodeId,toolTipRef])

  return (
    <div className="activity-node-container" onClick={onClick}>
      <LighthouseTooltip tipRef={toolTipRef}  content={renderToString(TooltipTemplate(props.data))} openDelay="250">
        <div className={classNames({ "activity-node": true, "driving-path": isDrivingPath, "selected-node": isSelected, "hover-node": isHover })} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <div className="an-dates">
            <div className={classNames({ "an-date-field": true, "driving-path": isDrivingPath })}>ES {getFormattedDate(earliestStartDate)}</div>
            <div className={classNames({ "an-date-field": true, "driving-path": isDrivingPath })}>EF {getFormattedDate(earliestFinishDate)}</div>
          </div>
          <div className="an-content">
            <Handle
              type="target"
              position="left"
              style={{ background: 'none', border: 'none', left: -5, top: 75 }}
            />
            <Handle
              type="source"
              position="right"
              style={{ background: 'none', border: 'none', right: -5, top: 75 }}
            />
            <div className="an-label">
              {label}
            </div>
          </div>
          <div className="an-dates">
            <div className={classNames({ "an-date-field": true, "driving-path": isDrivingPath })}>LS {getFormattedDate(latestStartDate)}</div>
            <div className={classNames({ "an-date-field": true, "driving-path": isDrivingPath })}>LF {getFormattedDate(latestFinishDate)}</div>
          </div>
        </div>
      </LighthouseTooltip>
    </div>
  );
};

export default React.memo(ActivityNode);