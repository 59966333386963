import React from 'react';
import { PrimaryLighthouseButton } from '../../../../../theme/Buttons/index';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthorizedComponent from '../../../../../components/Shared/molecules/AuthorizedComponents/AuthorizedComponent';

import './CreateScenarioNavigation.scss';

const CreateScenarioNavigation = (props) => {
  const { selectedProjectId } = useSelector(state => state.projects);

  return (
    <div className='create-scenario-container'>
      <div className='create-scenario-status'>
        <div className='create-scenario-buttons-align'>
        <AuthorizedComponent value='create-new-scenario' projectId={selectedProjectId} disciplineId = 'All'>
          <PrimaryLighthouseButton className='submit' component={Link} to={`/${selectedProjectId}/createscenario`}>Create Scenario</PrimaryLighthouseButton>
          </AuthorizedComponent>
        </div>
      </div>
    </div>

  );
}

export default CreateScenarioNavigation;