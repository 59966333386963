import { ChartComponent, SeriesCollectionDirective, StripLine, SeriesDirective, Inject, Tooltip, DataLabel, LineSeries, DateTime, Zoom, Crosshair, ScrollBar  } from '@syncfusion/ej2-react-charts';
import React from 'react';
import {  useSelector } from "react-redux";
import CircularProgressBar from '../../../../../../src/components/Shared/molecules/CircularProgressBar';
import { getGlobalFormat } from '../../../../../utils';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { chartTooltipThemeSettings } from '../../../../../theme/Charts';

const PlannedResourceCapacityRequirementChart = (props) => {
    const data = props.resourceCapacityData;
    const { isLoading, error } = useSelector(state => state.disciplines);
    const { sidebarOpen } = useSelector(state => state.projects);
    const majorGridLines= { color: '#F6F6F6',width: 1};
    const primaryXAxis = { title: 'Time',  titleStyle: {size: '12px', color: '#005A84',  fontWeight: 'bold'},
    valueType: 'DateTime', skeleton: 'Ed', intervalType: 'Days', interval : 1, labelFormat: getGlobalFormat() , labelRotation: -45, majorGridLines: majorGridLines, minimum: data.minDate, maximum: data.maxDate };
    const primaryYAxis = { title: 'Hours (Equiv. to FTE)', titleStyle: {size: '12px', color: '#005A84',  fontWeight: 'bold'}, majorGridLines: majorGridLines, rangePadding: 'Additional' };
    const tooltipSettings = { enable: true, shared: true, format: '${series.name} :  ${point.y} hrs', ...chartTooltipThemeSettings };
    const zoomsettings = { enableMouseWheelZooming: true, enablePinchZooming: true,
                           enableSelectionZooming: true, mode: 'X', enableScrollbar: true };
    const crosshair = { enable: true, lineType: 'Vertical', dashArray: '10,5', line: { color: '#ff66ff' } };

    return (
        <>
            <div className='chart-div'>
                { isLoading && 
                    <CircularProgressBar />
                }
                 { !isLoading &&  error &&
                    <ErrorMessage  message="Unable to display resource capacity graph" />
                }
                { !isLoading && !error &&
                <>
                    <ChartComponent primaryXAxis={primaryXAxis} primaryYAxis={primaryYAxis} crosshair={crosshair} zoomSettings={zoomsettings} tooltip={tooltipSettings} width={sidebarOpen?'1220px':'1350px'} height='680px'>
                        <Inject services={[LineSeries, Tooltip, DataLabel, DateTime, Zoom, Crosshair, StripLine, ScrollBar]}/>
                        <SeriesCollectionDirective>
                        <SeriesDirective dataSource={data.balanced} xName='date' yName='hours' width={2} name='Balanced Requirement'  type='Line' fill='#005A84'>
                            </SeriesDirective>  
                        <SeriesDirective dataSource={data.original} xName='date' yName='hours' width={2} dashArray='5,5'  name='Original Capacity' type='Line' fill='#8ED8F8'>
                            </SeriesDirective>           
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </>
                }
            </div>           
    
        </>
      );
};

export default PlannedResourceCapacityRequirementChart;