import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate } from '../../../../../utils';
import { LinkLighthouseButton, LinkLighthouseButton2 } from '../../../../../theme/Buttons';
import LighthouseDialog from '../../../../../components/Shared/organisms/LighthouseDialog';
import InterfacePointEditorContainer from '../../../../ProjectInterfacePointsPage/components/organisms/InterfacePointEditorContainer';
import { selectInterfacePointById,updateInterfacePointStatus,fetchInterfacePoints,fetchInterfaceActivityLinks } from '../../../../../redux/slices/interfacePointsSlice';

import { useMsal } from "@azure/msal-react";
import AuthorizationService from '../../../../../components/Shared/molecules/Authorization/AuthorizationService';
import { InterfaceNotificationIcon, InterfaceNotificationStripIcon, IPForecastDateUpdatedIcon, IPForecastDateUpdatedStripIcon, UndoIcon } from '../../../../../Icons';

import './InterfacePointProperties.scss';

const InterfacePointProperties = (props) => {
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const { selectedInterfacePointId } = useSelector(state => state.interfacePoints);
  const selectedInterfacePoint = useSelector((state) => selectInterfacePointById(state, selectedInterfacePointId));
  const { interfacePointTypes } = useSelector(state => state.interfacePoints);
  const { interfaceWarnings } = useSelector(state => state.interfacePoints);
  const { markedAsCompleteInterfaceId, markedAsInCompleteInterfaceId } = useSelector(state => state.interfacePoints);
  const { interfaceActivityLinks } = useSelector(state => state.interfacePoints);
  const { isInterfacePointsLoading, interfacePointsError } = useSelector(state => state.interfacePoints);
  const [editInterfacePointVisibility, setEditInterfacePointVisibility] = useState(false);
  const [interfaceWarningType, setInterfaceWarningType] = useState([]);
  const [interfaceWarningMessage, setInterfaceWarningMessage] = useState([]);

  const { accessToken, authorized } = useSelector(state => state.authorization);
  const { instance, accounts } = useMsal();
  const [daysPendingForCompletion, setDaysPendingForCompletion] = useState(false);
  const { interfacePointForecastDateUpdate } = useSelector(state => state.interfacePoints);
  const forecastDateUpdated = selectedInterfacePoint?.isForecastDateUpdated;

  /**
   * Get the number of days remaining for an incomplete interface point(due in the next week)
   */
  const getPendingDaysForCompletion = (selectedInterfacePoint) => {
    const millisecondsInDay = 86400000;
    let currentDate = new Date();
    if(selectedInterfacePoint.forecastDate == null || selectedInterfacePoint.forecastDate == undefined){
      let differenceInDays = new Date(selectedInterfacePoint.interfacePointDate).getTime() - new Date(currentDate).setHours(0,0,0,0);
      const daysDiff = Math.round(differenceInDays / millisecondsInDay);
      setDaysPendingForCompletion(daysDiff);
    }
    else{
      let differenceInDays = new Date(selectedInterfacePoint.forecastDate).getTime() - new Date(currentDate).setHours(0,0,0,0);
      const daysDiff = Math.round(differenceInDays / millisecondsInDay);
      setDaysPendingForCompletion(daysDiff);
    }
  }
  
  useEffect(() => {
    //Acquire access token
    if(accessToken == null || authorized == 'No'){
      AuthorizationService({dispatch: dispatch, instance: instance, accounts: accounts});
    }
  }, [accounts]);

  /**
   * Fetched activities linked to the selected interface point
   */
  useEffect(() => {
    if (accessToken){
      if(selectedInterfacePointId){
        dispatch(fetchInterfaceActivityLinks({projectId: selectedProjectId, interfacePointId: selectedInterfacePointId, accessToken: accessToken}));  
      }
    }
  }, [dispatch, selectedInterfacePointId, selectedProjectId, accessToken]);

  /**
   * Fetched activities linked to the selected interface point
   */
   useEffect(() => {
    if (accessToken){
      if(selectedInterfacePointId){
        var selectedInterfaceWarnings = interfaceWarnings.filter(a=>a.interfaceId == selectedInterfacePointId).slice();
        var linkType = [];
        let warningMessage = [];

        if(selectedInterfaceWarnings.length > 0)
        {
          linkType = [...new Set(selectedInterfaceWarnings.map(item => item.linkType))];
          linkType.forEach(element => {
            if(element == 'UpstreamPhase'){
              warningMessage.push("This interface is at risk of delay due to observed low productivity on the driving pathway.");
            }
            else if(element == 'UpstreamResource'){
              warningMessage.push("This interface is at risk of delay due to observed low productivity and resulting resource bottleneck(s).");
            }
            else if(element == 'UpstreamInterface'){
              warningMessage.push("This interface is at risk of delay due to delay to another interface on the driving pathway.");
            }
          });
          
          setInterfaceWarningType(linkType);
          setInterfaceWarningMessage(warningMessage);
          
        }
        else{
          setInterfaceWarningType([]);
          setInterfaceWarningMessage([]);
        }

        getPendingDaysForCompletion(selectedInterfacePoint);
      }
    }
  }, [dispatch, selectedInterfacePointId, selectedProjectId, accessToken]);
  
  /**
   * Turns on the dialog box for editing interface point
   */
  const editInterfacePointClick = () => {
    setEditInterfacePointVisibility(true);
  }

  /**
   * Cancels editing this interface point
   */
  const closeEditInterfacePoint = () =>{
    setEditInterfacePointVisibility(false);
  }
 
   /**
  * Marks an interface point as complete/active by setting the status to completed/active
  * @param {string} id 
  */
    const markInterfacePointAsCompletedOrActive = () => {
      if(selectedInterfacePoint.status.toLowerCase() === 'active')
        dispatch(updateInterfacePointStatus({ projectId: selectedProjectId, interfacePointId: selectedInterfacePointId, status: "Completed",  accessToken: accessToken }))
      
      else
        dispatch(updateInterfacePointStatus({ projectId: selectedProjectId, interfacePointId: selectedInterfacePointId, status: "Active",  accessToken: accessToken }))      
    }
   
   
  return (
    <>
    {selectedInterfacePoint && !isInterfacePointsLoading && !interfacePointsError &&
      <div className="ip-prop-container">
       {daysPendingForCompletion < 5 && daysPendingForCompletion > 0 && selectedInterfacePoint.status.toLowerCase() === 'active' &&
        <div className="ip-prop-cols">
          <div className="ip-prop-col">
            <div className="ip-secondary-flag-section">
              <InterfaceNotificationStripIcon className="ip-secondary-notification-strip-icon"/>
                <InterfaceNotificationIcon className="ip-secondary-notification-icon"/>
                  <label className="ip-notification-title">This interface is to be received soon ({ (selectedInterfacePoint && !selectedInterfacePoint.forecastDate) ?  getFormattedDate(selectedInterfacePoint.interfacePointDate) : getFormattedDate(selectedInterfacePoint.forecastDate)}) 
                    <LinkLighthouseButton2 className="ip-forecast-date-link" onClick={editInterfacePointClick}>Please provide a forecast date</LinkLighthouseButton2>
                      {editInterfacePointVisibility &&
                        <LighthouseDialog isVisible={editInterfacePointVisibility} closeButton={true} closeButtonAction={closeEditInterfacePoint} title="Edit Interface" width="63%" height="800px">
                        <InterfacePointEditorContainer isEditable={true} addForecastDate={true} closeEditInterfacePoint={closeEditInterfacePoint} daysPendingForCompletion={daysPendingForCompletion} />
                        </LighthouseDialog>
                      }
                  </label> 
              </div>
          </div>
        </div>
        }

        {daysPendingForCompletion <= 0 && selectedInterfacePoint.status.toLowerCase() === 'active' &&
        <div className="ip-prop-cols">
          <div className="ip-prop-col">
            <div className="ip-primary-flag-section">
              <InterfaceNotificationStripIcon className="ip-primary-notification-strip-icon"/>
                <InterfaceNotificationIcon className="ip-primary-notification-icon"/>
                  <label className="ip-notification-title">This interface point is late. You can either mark as complete or enter a new
                    <LinkLighthouseButton2 className="ip-forecast-date-link" onClick={editInterfacePointClick}> Forecast Date</LinkLighthouseButton2>
                      {editInterfacePointVisibility &&
                        <LighthouseDialog isVisible={editInterfacePointVisibility} closeButton={true} closeButtonAction={closeEditInterfacePoint} title="Edit Interface" width="63%" height="800px">
                        <InterfacePointEditorContainer isEditable={true} addForecastDate={true} closeEditInterfacePoint={closeEditInterfacePoint} daysPendingForCompletion={daysPendingForCompletion} />
                        </LighthouseDialog>
                      }
                  </label> 
              </div>
          </div>
        </div>
        }

        {interfaceWarningType.length > 0 &&
          interfaceWarningMessage.map((message, i) => 
          (
            <div className="ip-prop-cols" key={i}>
              <div className="ip-prop-col">
                <div className="ip-primary-flag-section">
                  <InterfaceNotificationStripIcon className="ip-primary-notification-strip-icon"/>
                    <InterfaceNotificationIcon className="ip-primary-notification-icon"/>
                      <label className="ip-notification-title">{message}</label> 
                  </div>
              </div>  
            </div>
          ))
        }

        {forecastDateUpdated && interfacePointForecastDateUpdate.selectedInterfacePointId === selectedInterfacePointId && daysPendingForCompletion >= 5 &&
        <div className="ip-prop-cols">
          <div className="ip-prop-col">
            <div className="ip-forecast-date-updated-section ip-forecast-date-updated-height">
              <IPForecastDateUpdatedStripIcon className="ip-forecast-date-updated-strip-icon"/>
              <IPForecastDateUpdatedIcon className="ip-forecast-date-updated-icon"/>
              <label className="ip-forecast-date-updated-title">Interface has been updated with forecast date ({getFormattedDate(selectedInterfacePoint.forecastDate)}) 
              </label>
            </div>
          </div>
        </div>
        }

        {markedAsCompleteInterfaceId && selectedInterfacePointId == markedAsCompleteInterfaceId && selectedInterfacePoint.status == 'Completed' &&
        <div className="ip-prop-cols">
          <div className="ip-prop-col">
            <div className="ip-completed-section ip-completed-height">
              <IPForecastDateUpdatedStripIcon className="ip-completed-strip-icon"/>
              <IPForecastDateUpdatedIcon className="ip-completed-icon"/>
              <label className="ip-completed-title"> 
                <span className='ip-completed-text'>This interface is now marked as complete.</span>
                <LinkLighthouseButton2 onClick={markInterfacePointAsCompletedOrActive} className='ip-undo-link'><span className='ip-undo-icon'><UndoIcon /></span>Undo</LinkLighthouseButton2>
              </label>
            </div>
          </div>
        </div>
        }

      {markedAsInCompleteInterfaceId && selectedInterfacePointId == markedAsInCompleteInterfaceId && selectedInterfacePoint.status == 'Active' && interfaceWarningType?.length == 0 && daysPendingForCompletion >= 5 &&
        <div className="ip-prop-cols">
          <div className="ip-prop-col">
            <div className="ip-incomplete-section ip-incomplete-height">
              <IPForecastDateUpdatedStripIcon className="ip-incomplete-strip-icon"/>
              <InterfaceNotificationIcon className="ip-incomplete-icon"/>
              <label className="ip-incomplete-title"> 
                This interface is now marked as incomplete.
              </label>
            </div>
          </div>
        </div>
        }

        <div className="ip-prop-cols">
          <div className="ip-prop-col">
            <div className="ip-prop-title">Properties:</div>
            <div className="ip-prop-section min-section-height">
              <table className="ip-prop-table">
                <tbody>
                  <tr>
                    <th>Interface Number:</th>
                    <td>{selectedInterfacePoint.interfacePointNumber}</td>
                  </tr>
                  <tr>
                    <th>Name:</th>
                    <td>{selectedInterfacePoint.name}</td>
                  </tr>
                  <tr>
                    <th>Responsible Party:</th>
                    <td>{selectedInterfacePoint.responsibleParty}</td>
                  </tr>
                  <tr>
                    <th>Type:</th>
                    <td>{interfacePointTypes.find(ipt => ipt.id === selectedInterfacePoint.projectInterfacePointTypeId)?.description}</td>
                  </tr>
                  <tr>
                    <th>Planned Date:</th>
                    <td>{getFormattedDate(selectedInterfacePoint.interfacePointDate)}</td>
                  </tr>
                  {selectedInterfacePoint.forecastDate && 
                  <tr>
                    <th>Forecast Date:</th>
                    <td>{getFormattedDate(selectedInterfacePoint.forecastDate)}</td>
                  </tr>}
                  <tr>
                    <th>Direction:</th>
                    <td>{selectedInterfacePoint.interfaceDirection == 'I' ? 'Input' : selectedInterfacePoint.interfaceDirection == 'O' ? 'Output' : null}</td>
                  </tr>
                  <tr>
                    <th>Description:</th>
                    <td>{selectedInterfacePoint.description}</td>
                  </tr>
                  <tr>
                    <th>Created By:</th>
                    <td>{selectedInterfacePoint.createdBy}</td>
                  </tr>
                  <tr>
                    <th>Created Date:</th>
                    <td>{getFormattedDate(selectedInterfacePoint.createdDate)}</td>
                  </tr>
                  <tr>
                    <th>Linked Activity:</th>
                    <td>
                      <ul>
                        {interfaceActivityLinks && interfaceActivityLinks.map((activity) =>
                          (
                            <li key={activity.activityId}>{activity.activityCode} - {activity.activityName}</li>
                          ))
                        }
                    </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    }
    </>
  );
}

export default InterfacePointProperties;