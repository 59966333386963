import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import  Radio  from '@material-ui/core/Radio';
import  RadioGroup  from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import './DismissAlertWizardContainer.scss'
import { FormControlLabel } from '@material-ui/core';
import { setAlertReason } from '../../../../../redux/slices/alertReasonSlice';
import ConfirmDismissAlertWizard from '../ConfirmDismissAlertWizard/ConfirmDismissAlertWizard';
import LighthouseDialog from '../../../../../components/Shared/organisms/LighthouseDialog';


const DismissAlertWizardContainer = React.memo(props => {
  const { selectedAlertId, selectedAlertName, selectedAlertStatus, selectedPhaseId, selectedPackageId, selectedAlertType, selectedAlertDriver } = props;
  const { alertReason } = useSelector(state => state.alertReason);
  const [confirmDismissAlertDialogBoxVisibility, setConfirmDismissAlertDialogBoxVisibility] = useState(false);
  const dispatch = useDispatch();

  const BlueRadio = withStyles({
    root: {
      color: '#B6BECA',
      "&$checked": {
        color: '#005A84'
      }
    },
    checked: {}
  })((props) => <Radio color="default" {...props} />);

  const WhiteTextTypography = withStyles({
    root: {
      fontWeight:"bold",
      fontSize: "0.9rem",
      lineHeight: "3.5rem"
    }
  })(Typography);

  
  /**
   * Dismiss clicked
   * shows the Confirm Disimss Alert wizard
   */
  const confirmDismissAlert = () => {
    if(alertReason != ''){
      setConfirmDismissAlertDialogBoxVisibility(true);
    }
  }

  /**
   * Cancel clicked
   * Passes the trigger to Close the Disimss Alert wizard
   */
  const cancelDismissAlert = () => {
    dispatch(setAlertReason(''));
    props.cancelDismissAlert();
  }


    /**
   * Dismiss Alert clicked
   * Passes the trigger to Close the Disimss Alert wizard
   */
     const dismissAlertClick = () => {
      dispatch(setAlertReason(''));
      setConfirmDismissAlertDialogBoxVisibility(false);
      props.cancelDismissAlert();
    }

  /**
   * No Thanks clicked
   * Closes the confirm Disimss Alert wizard
   */
   const closeDismissAlert = () => {
    setConfirmDismissAlertDialogBoxVisibility(false);
  }



   /**
   * On Radio button value change
   */
  const radioButtonValue = (e) => {
    dispatch(setAlertReason(e.target.value));
    e.stopPropagation();
  } 

  return (
    <>
      <div>
        <div className="dismiss-alert-wizard">
          <div className="dismiss-alert-content" >
            <div className="dismiss-alert-title">Alert : {selectedAlertName}</div>
            <div className="dismiss-alert-heading">Select one of the following options</div>
            <div className="dismiss-alert-subHeading">Select an option to explain the reason behind dismissing an Alert:</div>
            <div className="dismiss-alert-radio-group">
              <RadioGroup aria-label="alertReason"  name="radio-buttons-group" size="small" value = {alertReason} onChange={radioButtonValue}>
                <FormControlLabel value="I believe the alert is wrong" control={<BlueRadio size="small"/>} label={<WhiteTextTypography>I believe the alert is wrong</WhiteTextTypography>}/>
                <FormControlLabel value="I accept the time & cost impact if I do nothing" control={<BlueRadio size="small"/>} label={<WhiteTextTypography>I accept the time & cost impact if I do nothing</WhiteTextTypography>}/>
                <FormControlLabel value="I want to mute this alert because I cannot decide on an action today" control={<BlueRadio size="small"/>} label={<WhiteTextTypography>I want to mute this alert because I cannot decide on an action today</WhiteTextTypography>}/>
              </RadioGroup>
            </div>
          </div>
        <div className="dismiss-alert-footer">
          <div className="dismiss-alert-footer-button">
                <PrimaryLighthouseButton className="mr-15" onClick={confirmDismissAlert}>Dismiss</PrimaryLighthouseButton>
                <SecondaryLighthouseButton onClick={cancelDismissAlert}>Cancel</SecondaryLighthouseButton>
            </div>
          </div>
        </div>
        
        <LighthouseDialog isVisible={confirmDismissAlertDialogBoxVisibility} title={'Dismiss Alert'} width="600px" height="285px" z-index="99" noPadding={true}>
          <ConfirmDismissAlertWizard closeDismissAlert={closeDismissAlert} dismissAlertClick={dismissAlertClick} selectedAlertId={selectedAlertId} selectedAlertName={selectedAlertName} selectedAlertStatus={selectedAlertStatus} selectedPhaseId={selectedPhaseId} selectedPackageId={selectedPackageId} selectedAlertType={selectedAlertType} selectedAlertDriver={selectedAlertDriver} />
        </LighthouseDialog>
      </div>
    </>
  );
})

const areEqual = (prevProps, nextProps) => {
  if (nextProps.selectedAlertId == prevProps.selectedAlertId) {
    return true;
  }
  else {
    return false;
  }
}

export default React.memo(DismissAlertWizardContainer, areEqual);