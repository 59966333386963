import React from 'react';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Toolbar, Filter, Edit } from '@syncfusion/ej2-react-treegrid';
import { getFormattedDate } from '../../../../../utils';
import { RelationTreeIcon, ProductionIcon, GlobIcon } from '../../../../../Icons';
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';
import { L10n } from '@syncfusion/ej2-base';
import ScheduledActivityDateChange from '../ScheduledActivityDateChange';
import ScheduledActivityDurationChange from '../ScheduledActivityDurationChange';

import './CreateScenarioScheduleChart.scss';

L10n.load({
  'ac-opt': {
    'treegrid': {
      'EmptyRecord': '',
    }
  }
});

const CreateScenarioScheduleChart = React.memo(props => {
  const { activityScheduleDateChange } = props;
  const grdActivityTree = React.createRef();

  const nameHeaderTemp = () => {
    return (<div className='text-name-align' >Name</div>);
  };

  const treeRowTemplate = (props) => {
    return (
      <tr className={`activity-driver-row ${props.actualStartDate && props.type.toLowerCase() === "activity" ? "non-editable" : ""}`}>
        <td className='text-left-align'>
          <div>{props.type}</div>
        </td>
        <td>
          <div className='cssc-dis-name-container'>
            {props.type === 'Discipline' && <div className='icon-style'><span><DisciplineIcon disciplineId={props.disciplineId} /></span></div>}
            {props.type === 'Package' && <span><RelationTreeIcon className='icon-style' /></span>}
            {props.type === 'Phase' && <span><GlobIcon className='icon-style' /></span>}
            {props.type === 'Activity' && <span><ProductionIcon className='icon-style' /></span>}
            {props.name}
          </div>
        </td>
        <td className='text-left-align'>
          {props.activityCode}
        </td>
        <td className='text-left-align'>
          {getFormattedDate(props.plannedStartDate)}
        </td>
        <td className='text-left-align'>
          {getFormattedDate(props.plannedEndDate)}
        </td>
        <td className='text-left-align-duration'>
          {(props.activityPlannedDuration || props.activityPlannedDuration > 0) && `${props.activityPlannedDuration > 1 ? props.activityPlannedDuration + " days" : props.activityPlannedDuration + " day"}`}
          {props.activityPlannedDuration === 0 && `${props.activityPlannedDuration} days`}
        </td>
        <td className='text-left-align'>
          {props.type === 'Activity' && !props.actualStartDate &&
            <ScheduledActivityDateChange activity={props} />
          }
        </td>
        <td className='text-left-align'>
          {props.type === 'Activity' && !props.actualStartDate &&
            <ScheduledActivityDurationChange activity={props} durationType="shortest" />
          }
        </td>
        <td className='text-left-align'>
          {props.type === 'Activity' && !props.actualStartDate &&
            <ScheduledActivityDurationChange activity={props} durationType="longest" />
          }
        </td>
      </tr>
    );
  }

  // toolbar options
  const toolbarOptions = ['Search'];

  const dataLoadFinished = (args) => {
    document.getElementsByClassName("e-search")[0].getElementsByClassName("e-input")[0].setAttribute("placeholder", "Enter Search...")
  }

  return (
    <div className="cssc-schedule-container">
      <TreeGridComponent
        id='grdActivityTree'
        ref={grdActivityTree}
        className='treeGrid-class'
        rowTemplate={treeRowTemplate}
        dataSource={activityScheduleDateChange}
        childMapping='subtasks'
        treeColumnIndex={1}
        toolbar={toolbarOptions}
        allowTextWrap={true}
        height='100%'
        width='auto'
        locale='ac-opt'
        dataBound={dataLoadFinished}
        enableCollapseAll={true}
        textWrapSettings={{ wrapMode: "Both" }}
      >
        <ColumnsDirective>
          <ColumnDirective headerText='Type' field='type' textAlign='left' isPrimaryKey={true} width='75'></ColumnDirective>
          <ColumnDirective field='name' headerTemplate={nameHeaderTemp} textAlign='left' width='300'></ColumnDirective>
          <ColumnDirective headerText='Activity ID' field='activityCode' textAlign='left' width='170'></ColumnDirective>
          <ColumnDirective headerText='Planned Start' field='plannedStartDate' textAlign='left' width='100'></ColumnDirective>
          <ColumnDirective headerText='Planned End' field='plannedEndDate' textAlign='left' width='100'></ColumnDirective>
          <ColumnDirective headerText='Planned Duration' field='activityPlannedDuration' textAlign='left' width='100'></ColumnDirective>
          <ColumnDirective headerText='Earliest Start' field='earliestStartDate' textAlign='left' width='120'></ColumnDirective>
          <ColumnDirective headerText='Shortest Duration' field='' textAlign='left' width='120'></ColumnDirective>
          <ColumnDirective headerText='Longest Duration' field='' textAlign='left' width='120'></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Filter, Toolbar, Edit]} />
      </TreeGridComponent>
    </div>
  );
})

const areEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) == JSON.stringify(nextProps)) {
    return true;
  }
  else {
    return false;
  }
}

export default React.memo(CreateScenarioScheduleChart, areEqual);