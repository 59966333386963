import React from 'react';
import { getDesignDeliveryDifference, getRiskColourInverse, getFormattedDate } from '../../../../../utils';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import TimeImpact from '../../../../../components/Shared/atoms/TimeImpact';
import '../../../ProjectProgressWidgets.scss';

const ProjectDesignDeliveryWidget = (props) => {
  const { project } = props;

  if (project) {
    const estimatedDelivery = project?.completionStatus?.actualEndDate;
    const plannedDelivery = project?.completionStatus?.plannedEndDate;

    // set the delivery difference in days as undefined
    // so if not available will display Not Available
    let deliveryDifferenceDays = undefined;

    // calculate the difference in delivery dates
    if (estimatedDelivery && plannedDelivery){
      deliveryDifferenceDays = getDesignDeliveryDifference(estimatedDelivery, plannedDelivery);
    } 

    return (
      <div className="widget-container">
        <div className="widget-title">
          <h3>Design Delivery</h3>
          <span className="tooltip ml-10">
            <InfoTooltip message={'Programme, cost and design deliverable data have been used to forecast team productivity and necessary time to complete design.'}  iconSize={20} width="none" />
          </span>
        </div>
        <div className="widget-content">
          <table>
            <thead>
            </thead>
            <tbody>
              <tr className="header-row">
                <td colSpan={2} className="row-header">Predicted Completion Date:</td>
              </tr>
              <tr>
                {estimatedDelivery && <td>{getFormattedDate(estimatedDelivery)} </td> }
                {!estimatedDelivery && <td>Not Available</td> }
                <td width={160}>
                  <article style={{ backgroundColor: getRiskColourInverse(deliveryDifferenceDays) }}>
                    <TimeImpact timeImpactDelayDays={deliveryDifferenceDays} />
                  </article>
                </td>
              </tr>
              <tr className="header-row">
                <td colSpan={2}>Planned Completion Date:</td>
              </tr>
              <tr>
                {plannedDelivery && <td>{getFormattedDate(plannedDelivery)} </td> }
                {!plannedDelivery && <td>Not Available</td> }
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  else {
    return null;
  }
}

export default ProjectDesignDeliveryWidget;