import React, { useEffect, useState } from 'react';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons/index';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './ScenarioActions.scss';

const ScenarioActions = (props) => {
  const { scenario } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const [disableCompareOption, setDisableCompareOption] = useState(false);
  const [disableFocusAreas, setDisableFocusAreas] = useState(true);
  
  /**
  * diabling the compare option button if scenario are failed or pending
  * disabling the view focus areas button if the scenario type is not system
  */
  useEffect(() => {
    if (scenario.scenarioStatus.toLowerCase() === 'active'
      || (scenario.scenarioStatus.toLowerCase() === 'draft' && scenario.optionCount > 1)) {
      setDisableCompareOption(false);
    }
    else {
      setDisableCompareOption(true)
    }

    if (scenario.scenarioType.toLowerCase() === 'system') {
      setDisableFocusAreas(false);
    }
    else {
      setDisableFocusAreas(true);
    }
  }, [scenario]);

  return (
    <div className='scenario-actions-container'>
      <div className='scenario-actions-status'>
        <div className='scenario-actions-buttons-align'>
          <SecondaryLighthouseButton className={disableFocusAreas === true ? "disable-submit" : "submit"}
            disabled={disableFocusAreas}
            component={Link} to={`/${selectedProjectId}/optimisation/focusareas`}>
            View Focus Areas
          </SecondaryLighthouseButton>
          <PrimaryLighthouseButton className={disableCompareOption === true ? "disable-submit" : "submit"}
            disabled={disableCompareOption}
            component={Link} to={`/${selectedProjectId}/scenarios/${scenario.scenarioId}/compareoptions`}>
            Compare Options
          </PrimaryLighthouseButton>
        </div>
      </div>
    </div>

  );
}

export default ScenarioActions;