import { useAccount, useMsal } from "@azure/msal-react";
import classNames from "classnames";
import { useEffect, useState } from 'react';
import { useStore } from 'react-flow-renderer';
import { useDispatch, useSelector } from "react-redux";
import { appInsights } from '../../../../../AppInsights';
import { AlertIcon } from '../../../../../Icons';
import { LighthouseTooltip } from '../../../../../utils';
import {
  setHighlightAlertSourceId,
  setHighlightAlertTargetId, setSelectedAlertId, setSelectedAlertSourceId,
  setSelectedAlertTargetId
} from '../../../../../redux/slices/alertsSlice';
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';

import './PhaseNodeAlert.scss';
import { setSelectedTab } from "../../../../../redux/slices/infoSlice";

const PhaseNodeAlert = (props) => {
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { isFiltered, alerts, phaseId } = props;
  const { selectedAlertId } = useSelector(state => state.alerts);
  const [isSelected, setIsSelected] = useState(false);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const store = useStore();

  const dispatch = useDispatch();

  let trackData = require('../../../../../appTrackerInfo.json');

  useEffect(() => {
    // set the alert source and targets and select this
    if (alerts && alerts.some(alert => alert.alertId === selectedAlertId)) {
      setAlertSourceAndTargets("select");
      setIsSelected(true);
    }
    else {
      setIsSelected(false);
    }
  }, [selectedAlertId]);

  // find if there are any primary alerts
  let alertType;
  if (alerts) {
    let primaryAlerts = alerts.find(alert => alert.alertType.toLowerCase() === "primary");
    if (primaryAlerts) {
      alertType = "primary";
    }
    else {
      alertType = "secondary";
    }
  }

  /**
   * Alert mouse click
   * sets the selected alert and source and targets
   * @param {object} evt 
   */
  const alertOnClick = (evt) => {
    // prevent the default event
    evt.stopPropagation();

    // use the first alert availble in the list
    // which should be the highest priority
    let selectedAlert = alerts[0];
    dispatch(setSelectedAlertId(selectedAlert.alertId));
    dispatch(setSelectedTab("AlertList"));
    setAlertSourceAndTargets("select");

    // tracking event
    appInsights.trackEvent({
      name: `DPM - DPV - ${selectedAlert.alertType.charAt(0).toUpperCase() + selectedAlert.alertType.slice(1)} Alert Click`,
      properties: {
        Page: `${trackData.pages[1].name}`,
        Type: `${selectedAlert.alertType.charAt(0).toUpperCase() + selectedAlert.alertType.slice(1)} Alert`,
        Project: `${selectedProjectId}`,
        ProjectName: `${selectedProject.projectName}`,
        User: `${account.username}`,
        Username: `${account.name}`,
        Role: 'Owner'
      }
    });
  }

  /**
   * Alert mouse enter
   * sets the alert source and targets
   * @param {object} evt 
   */
  const alertOnMouseEnter = (evt) => {
    // set the highlight source and targets
    setAlertSourceAndTargets("highlight");
  }

  /**
   * Alert mouse leave
   * clears alert source and targets
   * @param {object} evt 
   */
  const alertOnMouseLeave = (evt) => {
    // clear source and targets
    dispatch(setHighlightAlertSourceId(undefined));
    dispatch(setHighlightAlertTargetId([]));
  }

  /**
   * Sets alert source and target ids
   * for selection or highlight
   * @param {guid} elementId 
   * @param {string} mode 
   */
  const setAlertSourceAndTargets = (mode) => {
    const { nodes } = store.getState();
    let sourceId = phaseId;
    let targetIds = [];

    // loop dvp elements
    nodes.forEach((el) => {
      if (el.data.predecessors) {
        el.data.predecessors.forEach((predecessor) => {
          if (predecessor.phaseId === sourceId) {
            // add element id to target id list
            targetIds = [...targetIds, el.data.id]
          }
        })
      }
    })

    if (mode === "select") {
      dispatch(setSelectedAlertSourceId(sourceId));
      dispatch(setSelectedAlertTargetId(targetIds));
    }
    else if (mode === "highlight") {
      dispatch(setHighlightAlertSourceId(sourceId));
      dispatch(setHighlightAlertTargetId(targetIds));
    }
  }

  /**
   * Alert tooltip template
   * @returns html
   */
   const alertTooltipTemplate = () => {
    let alertTooltipContent = "";
    alerts.forEach(alert => alertTooltipContent += `<li>${alert.alertName}</li>`)
    return (
      <>
        <ul className="alert-tooltip-list" dangerouslySetInnerHTML={{ __html: alertTooltipContent }}></ul>
      </>
    );
  }

  return (
    <>
      {alerts &&
        <div className="alert-wrapper">
          {alerts.length > 1 && <span className={classNames('alert-counter', { grey: isFiltered })}>{alerts.length}</span>}
          <LighthouseTooltip content={alertTooltipTemplate} offsetX={5} offsetY={0} width={420}>
            <button className={classNames('alert', {
              primary: !isFiltered && alertType === "primary",
              secondary: !isFiltered && alertType === "secondary",
              highlight: isSelected
            })
            }
              onClick={alertOnClick}
              onMouseEnter={alertOnMouseEnter}
              onMouseLeave={alertOnMouseLeave}>
              <AlertIcon width={18} height={18} />
            </button>
          </LighthouseTooltip>
        </div>
      }
    </>
  );
};

export default PhaseNodeAlert;