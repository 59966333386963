import React, { useEffect, useState } from 'react';
import DesignProductionViewer from '../DesignProductionViewer';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { fetchPackagePctCompleteCalcTypes, selectAllPackagePctCompleteCalcTypes } from '../../../../../redux/slices/packagePctCompleteCalcTypesSlice';
import { fetchPhases, selectAllPhases } from '../../../../../redux/slices/phasesSlice';
import { fetchPackages } from '../../../../../redux/slices/packagesSlice';
import { selectDisciplineById, fetchDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import { getCompletionStatusColour } from '../../../../../utils';

import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { ALERT_COLOUR } from '../../../../../constants';

//let dpvData = require('./designProductionViewer.mock.json');

const DesignProductionViewerContainer = (props) => {
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(state => state.phases);
  const schedule = useSelector(selectAllPhases);
  const filteredDisciplineIds = useSelector(state => state.disciplines.filteredDisciplineIds);
  const { selectedProjectId } = useSelector(state => state.projects);
  // const ddvToggle = props.ddvToggle;
  const packagePctCompleteCalcTypes = useSelector(selectAllPackagePctCompleteCalcTypes);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  /**
  * Fetches the packages and phases
  */
  useEffect(() => {
    if (accessToken) {
      dispatch(fetchPackagePctCompleteCalcTypes({projectId : selectedProjectId, accessToken : accessToken}))
      dispatch(fetchPackages({ projectId: selectedProjectId, accessToken: accessToken }));
      dispatch(fetchPhases({ projectId: selectedProjectId, accessToken: accessToken }));
      dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken }));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  /**
   * Adds connectors to the elements array
   * @param {*} elems 
   * @param {*} fromNodes 
   * @param {*} toNodeId 
   */
  function addConnector(wbss, elems, fromNodes, toNodeId) {
    fromNodes.forEach((fromNode) => {
      let edgeColour;
      let connector = {};
      connector.id = fromNode.phaseId + '-' + toNodeId;
      connector.source = fromNode.phaseId;
      connector.target = toNodeId;

      // define extra data such as has alert
      // so we can use this when rendering the edge
      let data = {};

      // determine if this connector has an alert on the previous node
      let hasAlert = wbss.find(el => el.phaseId === fromNode.phaseId)?.alerts?.length > 0;
      data.hasAlert = hasAlert;

      let fromNodeObject = wbss.find(obj => {
        return obj.phaseId === fromNode.phaseId;
      });

      if (fromNodeObject) {
        data.disciplineId = fromNodeObject.disciplineId;
        let actualPercentageComplete = fromNodeObject.completionStatus.actualPercentageComplete;
        let plannedPercentageComplete = fromNodeObject.completionStatus.plannedPercentageComplete;

        if (hasAlert) {
          edgeColour = ALERT_COLOUR;
        }
        else {
          edgeColour = getCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete);
        }

        connector.style = { stroke: edgeColour };
      }

      connector.data = data;

      //console.log(connector);
      elems.push(connector);
    }
    )
  };

  /**
   * Processes the schedule into elements for the 
   * consumption by design production viewer
   * @param {*} wbss
   * @returns 
   */
  const processSchedule = (wbss) => {
    var elems = [];
    for (let t of wbss) {
      if(t.wbsType.toLowerCase() !== 'kem'){
        var e = {};
        e.id = t.phaseId;
        e.type = t.wbsType.toLowerCase();
  
        let data = {};
        data.disciplineId = t.disciplineId;
        data.label = t.phaseName;
        data.id = t.phaseId;
        //data.taskOwner = t.taskOwner;
        data.plannedPercentageComplete = t.completionStatus.plannedPercentageComplete;
        data.activityBasedActualPercComplete = t.completionStatus.activityBasedActualPercComplete;
        data.deliverableBasedActualPercComplete = t.completionStatus.deliverableBasedActualPercComplete;
        data.predecessors = t.predecessors;
        data.packageId = t.packageId;
        var selectedPhasePackagePctCalcType = packagePctCompleteCalcTypes.find(x=>x.packageId == t.packageId)?.packagePctCalcType;
        if(selectedPhasePackagePctCalcType == 0 || selectedPhasePackagePctCalcType == 1){
            data.pctType = 0;
            data.actualPercentageComplete = t.completionStatus.deliverableBasedActualPercComplete;
        }
        if(selectedPhasePackagePctCalcType == 2){
            data.pctType = 1;
            data.actualPercentageComplete = t.completionStatus.activityBasedActualPercComplete;
        }
        if (t.completionStatus.plannedStartDate) {
          data.plannedStartDate = t.completionStatus.plannedStartDate;
        }
        if (t.completionStatus.actualStartDate) {
          data.actualStartDate = t.completionStatus.actualStartDate;
        }
        if (t.completionStatus.plannedEndDate) {
          data.plannedEndDate = t.completionStatus.plannedEndDate;
        }
        if (t.completionStatus.actualEndDate) {
          data.actualEndDate = t.completionStatus.actualEndDate;
        }
  
        // add any alerts and set the flag
        if (t.hasOwnProperty('alerts') && t.alerts.length > 0) {
          data.alerts = t.alerts;
        }
  
        e.data = data;
        elems.push(e);
  
      // check for predecessors and 
        // add connectors for them
        if (t.hasOwnProperty('predecessors') && t.predecessors !== null) {
          addConnector(wbss, elems, t.predecessors, e.id);
        }
      }
    }
    return elems;
  }

  return (
    <>
      {isLoading &&
        <CircularProgressBar />
      }
      {error && !isLoading &&
        <ErrorMessage message="Unable to display design production viewer" />
      }
      {!isLoading && !error &&
        // <DesignProductionViewer dpvData={processSchedule(schedule)} selectedDiscipline={selectedDiscipline} ddvToggle={ddvToggle} />
        <DesignProductionViewer dpvData={processSchedule(schedule)} filteredDisciplineIds={filteredDisciplineIds} />
      }
    </>
  );
}

export default DesignProductionViewerContainer;