import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import BuildFailedAnimation from '../../../../../components/Shared/atoms/BuildFailedAnimation'
import { WarningCircleIcon, CloseIcon } from '../../../../../Icons';

import './ScenarioDraftInfo.scss';

const ScenarioDraftInfo = (props) => {

  const { scenario, currentScenarioId } = props;
  const [ divTimer, setDivTimer ] = useState();
    const { selectedProjectId } = useSelector(state => state.projects);

  const closeNotification = () => {
    setDivTimer(false);
}

  /**
   * Selected filter types changed
   */
   useEffect(() => {
    setDivTimer(true);
    },
     [currentScenarioId]);
  return (
        <div className="scenario-draft-container">
          {divTimer === true &&
            <div className="pp-widgets-scenario-draft">
              <div className="scenario-toast-text-draft">
                <div className="scenario-container-scheduled-analysis-draft">
                  <div className="scenario-header-scheduled-analysis-draft">
                    <div className="scenario-icon-label-container-scheduled-analysis-draft">
                      <span className="scenario-span-warning-scheduled-analysis-draft"></span>
                      <span className="scenario-warning-icon-scheduled-analysis-draft">
                        <WarningCircleIcon />
                      </span>
                      <p>
                        The scenario selected is still a draft. In order to Compare Options for this scenario, you need to analyse it. <a className="help-center-btn" style={{paddingLeft: '3px'}} href={`/${selectedProjectId}/scenarios/${scenario.scenarioId}/editscenario`} >Edit Scenario</a>
                      </p>
                    </div>
                    <div className='scenario-notification-close-draft'>
                      <CloseIcon width="7.5px" height="7.5px" color="#005A84" onClick={closeNotification} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
              <div className="scenario-draft-body">
                     <div className="scenario-draft-img">
                       <BuildFailedAnimation width="450px"/>
                      </div>
                    <h5></h5>
                    <p>The Scenario is saved as draft. Analyse for the options</p>
             </div>
        </div>
  );
}

export default ScenarioDraftInfo;