import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import InterfacePointsSelector from '../InterfacePointsSelector/InterfacePointsSelector';
import { fetchInterfacePoints, fetchInterfacePointTypes, updateInterfacePointStatus, setSelectedInterfacePointId, fetchInterfaceWarnings } from '../../../../../redux/slices/interfacePointsSlice';

const InterfacePointsSelectorContainer = (props) => {
  const dispatch = useDispatch();
  const { interfacePointsError, isInterfacePointsLoading } = useSelector(state => state.interfacePoints);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);


  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

   /**
   * Fetches the interface points for the current project
   */
  useEffect(() => {
    if (accessToken && selectedProjectId){
      dispatch(fetchInterfacePoints({ projectId: selectedProjectId, accessToken: accessToken}));
      dispatch(fetchInterfacePointTypes({ projectId: selectedProjectId, accessToken: accessToken }));
      dispatch(fetchInterfaceWarnings({ projectId: selectedProjectId, accessToken: accessToken }));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  /**
  * Deletes an interface point by setting status to deleted
  * @param {string} id 
  */
  const deleteInterfacePoint = (interfacePointId) => {
    dispatch(updateInterfacePointStatus({ projectId: selectedProjectId, interfacePointId: interfacePointId, status: "Deleted",  accessToken: accessToken }))
      .then((response) => dispatch(fetchInterfacePoints({ projectId: selectedProjectId, accessToken: accessToken}))
    )
  }

  return (
    <>
      { isInterfacePointsLoading  && 
        <CircularProgressBar />
      }
      { interfacePointsError && !isInterfacePointsLoading &&
        <ErrorMessage message="Unable to display interface selector" />
      }
      { !isInterfacePointsLoading && !interfacePointsError &&
        <>
          <InterfacePointsSelector deleteInterfacePoint={deleteInterfacePoint} isEditable={true} />
        </>
      } 
    </>
  );
}

export default InterfacePointsSelectorContainer;