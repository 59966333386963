import classNames from "classnames";
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Handle } from 'react-flow-renderer';
import { useSelector } from "react-redux";
import {
  getCompletionStatusColour, getPhaseNodeColour, getPhaseNodeDisabledColour, getPhaseNodeProgressBgColour, getPhaseNodeProgressColour, getPhaseNodeShadowColour, getSelectedCompletionStatusBorderColour, getSelectedCompletionStatusColour, LighthouseTooltip
} from '../../../../../utils';
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';
import LinearProgressBarContainer from '../LinearProgressBarContainer/LinearProgressBarContainer';
import PhaseNodeAlert from '../PhaseNodeAlert';
import './PhaseNode.scss';
import { getFormattedDate } from '../../../../../utils';

const PhaseNode = (props) => {
  const { id, label, disciplineId, actualPercentageComplete, plannedPercentageComplete, isFiltered, actualStartDate, alerts } = props.data;
  const [bgColour, setBgColour] = useState(getPhaseNodeColour(actualPercentageComplete, plannedPercentageComplete));
  const [completedBgColour, setCompletedBgColour] = useState(getCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete));
  const [borderColour, setBorderColour] = useState(getPhaseNodeColour(actualPercentageComplete, plannedPercentageComplete));
  const [phaseNodeProgressColour, setPhaseNodeProgressColour] = useState(getPhaseNodeProgressColour(actualPercentageComplete, plannedPercentageComplete));
  const [phaseNodeProgressBgColour, setPhaseNodeProgressBgColour] = useState(getPhaseNodeProgressBgColour(actualPercentageComplete, plannedPercentageComplete));
  const [phaseNodeShadowColor, setPhaseNodeShadowColor] = useState(getPhaseNodeShadowColour());
  const [disabledBorderColour, setDisabledBorderColour] = useState(getPhaseNodeDisabledColour('node'));
  const [disabledOpacity, setDisabledOpacity] = useState('0.7');
  const [borderStyle, setBorderStyle] = useState('solid');
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  // sort alerts by priority
  let sortedAlerts = [];
  
  if (alerts){
    sortedAlerts = alerts.reduce((newArray, element) => {
      newArray.push(element);
      return newArray;
    }, []);

    sortedAlerts.sort((a, b) => a.priority > b.priority ? 1 : -1);
  }
  

  const {
    highlightAlertSourceId,
    highlightAlertTargetId,
    selectedAlertSourceId,
    selectedAlertTargetId } = useSelector(state => state.alerts);

  const { selectedPhaseId } = useSelector(state => state.info);

  useEffect(() => {
    // highlight alert source changed
    if (highlightAlertSourceId === props.id || highlightAlertTargetId.includes(props.id)
      || selectedAlertSourceId === props.id || selectedAlertTargetId.includes(props.id)) {
      setIsHighlighted(true);
      //console.log("found alert source to highlight:", props.id)
    }
    else {
      setIsHighlighted(false);
    }
  }, [highlightAlertSourceId, highlightAlertTargetId, selectedAlertSourceId, selectedAlertTargetId]);

  useEffect(() => {
    if (selectedPhaseId === props.id) {
      setIsSelected(true);
    }
    else {
      setIsSelected(false);
    }
  }, [selectedPhaseId]);

  // set node colour and background colour based on percentage complete
  // and filter status
  let nodeBorderColour;
  let backgroundColour
  let nodeStyle;
  let progressBarColour;
  let progressBarBgColour;
  let shadowColour;
  let iconStyle;

  if (isSelected) {
    nodeBorderColour = `${getSelectedCompletionStatusBorderColour(actualPercentageComplete, plannedPercentageComplete)}`;
    backgroundColour = `${getSelectedCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete)}`;
    shadowColour = `0px 0px 1px 1px ${getPhaseNodeShadowColour('shadow')}`;
    nodeStyle = {
      ...nodeStyle,
      borderColor: nodeBorderColour, borderStyle: 'dashed', background: backgroundColour, boxShadow: shadowColour
    };
  }
  else {
    if (actualPercentageComplete === 100) {
      nodeStyle = {
        ...nodeStyle,
        borderColor: `${borderColour}`, borderStyle: `${borderStyle}`, background: `${completedBgColour}`, boxShadow: `0px 0px 0px 0px ${phaseNodeProgressColour}`
      };
    }
    else {
      nodeStyle = {
        ...nodeStyle,
        borderColor: `${borderColour}`, borderStyle: `${borderStyle}`, background: `${bgColour}`, boxShadow: `0px 0px 0px 0px ${phaseNodeProgressColour}`
      };
    }
  }

  if (isFiltered) {
    backgroundColour = getPhaseNodeDisabledColour('node');
    nodeBorderColour = getPhaseNodeDisabledColour('node');
    progressBarColour = getPhaseNodeDisabledColour('progress');
    progressBarBgColour = getPhaseNodeDisabledColour('progressBg');
    nodeStyle = {
      ...nodeStyle,
      borderColor: `${disabledBorderColour}`, background: backgroundColour, opacity: `${disabledOpacity}`
    };

    if (isSelected) {
      nodeBorderColour = getPhaseNodeDisabledColour('border');
      nodeStyle = {
        ...nodeStyle,
        borderColor: nodeBorderColour, borderStyle: 'dashed', opacity: '100%'
      };
    }

  }
  else {
    progressBarColour = phaseNodeProgressColour;
    progressBarBgColour = phaseNodeProgressBgColour;
  }

  iconStyle = {
    background: 'linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3))',
    padding: '3px',
    borderRadius: '2px'
  }

  const onMouseEnter = () => {
    setBgColour(getSelectedCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete));
    setCompletedBgColour(getSelectedCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete));
    setBorderColour(getSelectedCompletionStatusBorderColour(actualPercentageComplete, plannedPercentageComplete));
    setBorderStyle('dashed');
    setPhaseNodeShadowColor(getPhaseNodeShadowColour('shadow'));

    //filtered
    if (isFiltered) {
      setDisabledBorderColour(getPhaseNodeDisabledColour('border'));
      setDisabledOpacity(1);
    }
  };
  const onMouseLeave = () => {
    setBgColour(getPhaseNodeColour(actualPercentageComplete, plannedPercentageComplete));
    setCompletedBgColour(getCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete));
    setBorderColour(getPhaseNodeColour(actualPercentageComplete, plannedPercentageComplete));
    setBorderStyle('solid')
    setPhaseNodeShadowColor(getPhaseNodeShadowColour());

    //filtered
    if (isFiltered) {
      setDisabledBorderColour(getPhaseNodeDisabledColour('node'));
      setDisabledOpacity(0.7);
    }
  };

  /**
 * Tooltip template
 * @param {object} props 
 * @returns 
 */
  const TooltipTemplate = (props) => {
    return (
      <>
      <div className='templete-tooltip-container'>
      <div className='templete-tooltip-field'>
          <span className='text-desc'>{props.label}</span>
        </div>
        {props.actualStartDate != undefined &&
        <div className='templete-tooltip-field'>
          <span className='text-title'>Started On:</span>
          <span className='text-desc'>{getFormattedDate(props.actualStartDate)}</span>
        </div>
        }
        {props.actualStartDate === undefined &&
        <div className='templete-tooltip-field'>
          <span className='text-title'>Starts On:</span>
          <span className='text-desc'>{getFormattedDate(props.plannedStartDate)}</span>
        </div>
        }
       {props.actualEndDate != undefined &&
        <div className='templete-tooltip-field'>
          <span className='text-title'>Ended On:</span>
          <span className='text-desc'>{getFormattedDate(props.actualEndDate)}</span>
        </div>
       } 
       {props.actualEndDate === undefined &&
        <div className='templete-tooltip-field'>
          <span className='text-title'>Ends On:</span>
          <span className='text-desc'>{getFormattedDate(props.plannedEndDate)}</span>
        </div>
        }
        <div className='templete-tooltip-field'>
          <span className='text-title'>Planned Schedule Activities:</span>
          <span className='text-desc'>{Math.round(props.plannedPercentageComplete??0)}%</span>
        </div>
        {props.pctType === 1 && 
       <div className='templete-tooltip-field'>
         <span className='text-title'>Estimated Schedule Activities:</span>
         <span className='text-desc'>{Math.round(props.activityBasedActualPercComplete??0)}%</span>
       </div>
      }
      {props.pctType === 0 && 
       <div className='templete-tooltip-field'>
          <span className='text-title'>Estimated Deliverable:</span>
          <span className='text-desc'>{Math.round(props.deliverableBasedActualPercComplete??0)}%</span>
       </div>
      }
      </div>
   </>
    );
  }

  return (
    <div className="phase-node">
      <LighthouseTooltip content={renderToString(TooltipTemplate(props.data))}>
        <div className="phase-container">
          <div>
            <div className={classNames({ phase: true, filtered: isFiltered, phaseHighlighted: isHighlighted })}
              style={nodeStyle} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
            >
              <Handle
                type="target"
                position="left"
                style={{ background: 'none', border: 'none', left: -5, top: 19 }}
              />
              <Handle
                type="source"
                position="right"
                style={{ background: 'none', border: 'none', right: -5, top: 19 }}
              />
              <span className="icon" style={iconStyle}>
                <DisciplineIcon disciplineId={disciplineId} />
              </span>
              <span className="label">
                {label}
              </span>
            </div>
            <div className="phase-progress">
              <LinearProgressBarContainer actualPercentageComplete={actualPercentageComplete} progressBarBgColour={progressBarBgColour} progressBarColour={progressBarColour} />
            </div>
          </div>
        </div>
      </LighthouseTooltip>
      {alerts &&
        <div className="alert-container">
          <PhaseNodeAlert alerts={sortedAlerts} isFiltered={isFiltered} phaseId={id} />
        </div>
      }
    </div>
  );
};

export default React.memo(PhaseNode);