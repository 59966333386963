import { useAccount, useMsal } from "@azure/msal-react";
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { appInsights } from '../../AppInsights';
import PageHeader from '../../components/Shared/organisms/PageHeader';
import { selectProjectById, setSelectedProjectId } from '../../redux/slices/projectsSlice';
import { setSelectedScenarioId } from '../../redux/slices/scenariosSlice';
import { selectAllInterfacePoints, setSelectedInterfacePointId } from '../../redux/slices/interfacePointsSlice';
import InterfacePointProperties from "./components/molecules/InterfacePointProperties/InterfacePointProperties";
import AddInterfacePoint from './components/organisms/AddInterfacePoint';
import InterfacePointsSelectorContainer from './components/organisms/InterfacePointsSelectorContainer';
import './ProjectInterfacePointsPage.scss';

let trackData = require('../../appTrackerInfo.json');
var classNames = require('classnames');

function IpTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dpv-tabpanel-${index}`}
      aria-labelledby={`dpv-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

IpTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const IpTabs = withStyles({
  root: {

  },
  indicator: {
    backgroundColor: 'var(--color-additional)',
    marginBottom: '7px',
    height: '3px'
  }
})(Tabs);

const IpTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 'fit-content',
    maxWidth: null,
    fontWeight: '450',
    padding: '20px',
    color: 'var(--color-tab-text)',
    '&:hover': {
      color: 'var(--color-tab-text)'
    },
    '&$selected': {
      color: 'var(--color-tab-text)'
    },
    '&:focus': {
      color: 'var(--color-tab-text)',
    },
  },
  selected: {},
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))((props) => <Tab disableRipple {...props} />);

export const ProjectInterfacePointsPage = (props) => {
  const {
    match: { params },
  } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const interfacePoints = useSelector((state) => selectAllInterfacePoints(state, selectedProjectId));
  const { selectedInterfacePointId, isInterfacePointsLoading } = useSelector(state => state.interfacePoints);
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [value, setValue] = React.useState("Properties");
  const { LHRoles, projects } = useSelector(state => state.authorization);

  useEffect(() => {
    if (params.projectId) {
      dispatch(setSelectedProjectId(params.projectId));
      dispatch(setSelectedInterfacePointId(undefined));
    }

    if (params.scenarioId) {
      dispatch(setSelectedScenarioId(params.scenarioId));
    }
  }, []);

  useEffect(() => {
    if (account && !selectedProject) {
      dispatch(setSelectedProjectId(params.projectId));
      appInsights.trackPageView({
        name:`${trackData.pages[5].name}`,   
        properties: { 
                   Project: 'Selection',
                   ProjectName: 'Selection',
                   User:`${account.username}`,
                   Username: `${account.name}`,
                   Role: 'Selection'  
        }});
    }
    if (account && selectedProject) {
      appInsights.trackPageView({
        name:`${trackData.pages[5].name}`,   
        properties: { 
                   Project: `${selectedProjectId}`,
                   ProjectName: `${selectedProject?.projectName}`,
                   User:`${account.username}`,
                   Username: `${account.name}`,
                   Role: `${LHRoles.find( x => x === 'GlobalAdmin')?'GlobalAdmin' : LHRoles[projects?.indexOf(selectedProjectId.toUpperCase())]?.toString()}` 
        }});
    }
  }, [account, selectedProject]);

  /**
* Tab change event
* @param {*} event 
* @param {*} newValue 
*/
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  if (process.env.REACT_APP_ENABLE_INTERFACE_POINTS === 'true'){
  return (
    <>
      {selectedProject &&
        <div className="ip-page">
          <div className="ip-page-header">
            <PageHeader
              pageTitle="Interfaces"
              margin="40"
            >
            </PageHeader>
          </div>
          <div className="ip-manager-panel">
            <InterfacePointsSelectorContainer />
            <AddInterfacePoint />
          </div>
          <div className="ip-main">
            <div className={classNames('ip-container')}>
              {selectedInterfacePointId &&
                <>
                  <div className="ip-header">
                    <div className="dpv-tabs">
                      <IpTabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                      >
                        <IpTab label={<span className="tab-label">Properties</span>} value="Properties" />
                      </IpTabs>
                    </div>
                  </div>
                  <IpTabPanel value={value} index="Properties" className="ip-content">
                    <InterfacePointProperties />
                  </IpTabPanel>
                </>
              }
              {!selectedInterfacePointId && interfacePoints.length > 0 &&
                <div className="ip-no-selection">
                  Select an interface to view its properties
                </div>
              }
              {!isInterfacePointsLoading && interfacePoints.length == 0 &&
                <div className="ip-no-interfaces">
                  Add an interface to view its properties
              </div>
              }
            </div>
          </div>

        </div>
      }
    </>
  );
  }
};

export default ProjectInterfacePointsPage;