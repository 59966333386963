import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useStore, useZoomPanHelper } from 'react-flow-renderer';
import { useSelector } from "react-redux";

/**
 * Finds an alert in the DPV
 * @param {*} props 
 * @returns 
 */
const AlertFinder = (props) => {
  const store = useStore();
  const { setCenter } = useZoomPanHelper();
  const { selectedAlertId } = useSelector(state => state.alerts);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Selected alert changed
   */
  useEffect(() => {
    if (selectedAlertId) {
      // find the alert in the phase elements
      const { nodes } = store.getState();
      let sourceNode;

      if (nodes.length) {
        nodes.forEach((node) => {
          if (node.data.alerts) {
            node.data.alerts.forEach((alert) => {
              if (alert.alertId === selectedAlertId) {
                sourceNode = node;
              }
            });
          }
        });
      }

      if (sourceNode) {
        const x = (sourceNode.__rf.position.x + sourceNode.__rf.width / 2) + 200;
        const y = sourceNode.__rf.position.y + sourceNode.__rf.height / 2;
        const zoom = 0.9;
        setCenter(x, y, zoom);
      }
      else {
        enqueueSnackbar('The alert could not be found in the design production viewer', { variant: 'error' });
      }
    }
  }, [selectedAlertId]);

  return null;
};

export default AlertFinder;