import { useAccount, useMsal } from "@azure/msal-react";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { appInsights } from '../../AppInsights';
import PageHeader from '../../components/Shared/organisms/PageHeader';
import { ArrowLeftIcon } from '../../Icons';
import { selectProjectById, setSelectedProjectId } from '../../redux/slices/projectsSlice';
import { fetchScenario, selectScenarioById } from '../../redux/slices/scenariosSlice';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../theme/Buttons/index';
import { setManyActivityChanges, analyseUserGeneratedScenario, setUnsavedChanges } from '../../redux/slices/createScenarioSlice';
import {
  setEditedResources,
  setSelectedWeekStartDate,
  setSelectedDisciplineId,
  removeActivityScheduleScenario,
  setName,
  setDescription,
  setResourceProfile,
  setReqMessage,
  setError
} from '../../redux/slices/createScenarioSlice';
import CreateScenarioScheduleContainer from "./components/organisms/CreateScenarioScheduleContainer/CreateScenarioScheduleContainer";
import CreateScenarioCapacityContainer from "./components/organisms/CreateScenarioCapacityContainer/CreateScenarioCapacityContainer";
import CreateScenarioDetailsContainer from "./components/organisms/CreateScenarioDetailsContainer";
import { selectAllActivityScheduleChanges } from '../../redux/slices/createScenarioSlice';
import { createUserGeneratedScenario, updateUserGeneratedScenario } from '../../redux/slices/scenariosSlice';
import { useSnackbar } from 'notistack';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { format } from 'date-fns';
import LighthouseDialog from "../../components/Shared/organisms/LighthouseDialog";
import ChangeListHistory from "./components/molecules/ChangeListHistory";
import AuthorizedComponent from '../../components/Shared/molecules/AuthorizedComponents/AuthorizedComponent';
import './ProjectCreateScenarioPage.scss';
import CircularProgressBar from "../../components/Shared/molecules/CircularProgressBar";
import RouterPrompt from "../../components/Shared/organisms/RouterPrompt";

let trackData = require('../../appTrackerInfo.json');
var classNames = require('classnames');

function CreateScenarioTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

CreateScenarioTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CreateScenarioTabs = withStyles({
  root: {
  },
  indicator: {
    backgroundColor: 'var(--color-additional)',
    height: '3px'
  }
})(Tabs);

const CreateScenarioTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 'fit-content',
    maxWidth: null,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingRight: '2px',
    color: '#8D9DB4',
    '&:hover': {
      color: '#302D2D',
      opacity: 1,
    },
    '&$selected': {
      color: '#302D2D',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#302D2D',
    },
  },
  selected: {},
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))((props) => <Tab disableRipple {...props} />);

export const ProjectCreateScenarioPage = (props) => {

  const {
    match: { params },
  } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [accessToken, setAccessToken] = useState(null);
  const [value, setValue] = React.useState("details");
  const { name, description, editedResources, error, reqMessage, unsavedChanges } = useSelector(state => state.createScenario);
  const { error: saveScenarioError } = useSelector(state => state.scenarios);
  const createScheduleScenario = useSelector(selectAllActivityScheduleChanges);
  const [confirmScenarioAnalysingDialogBoxVisibility, setScenarioAnalysingDialogBoxVisibility] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [editScenarioId, setEditScenarioId] = useState(undefined);
  const editScenario = useSelector((state) => selectScenarioById(state, editScenarioId));
  const { LHRoles, projects } = useSelector(state => state.authorization);


  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);


  useEffect(() => {
    if (account && !selectedProject) {
      dispatch(setSelectedProjectId(params.projectId));
      appInsights.trackPageView({
        name:`${trackData.pages[4].name}`,   
        properties: { 
                   Project: 'Selection',
                   ProjectName: 'Selection',
                   User:`${account.username}`,
                   Username: `${account.name}`,
                   Role: 'Selection'  
        }});
    }
    if (account && selectedProject) {
      appInsights.trackPageView({
        name:`${trackData.pages[4].name}`,   
        properties: { 
                   Project: `${selectedProjectId}`,
                   ProjectName: `${selectedProject?.projectName}`,
                   User:`${account.username}`,
                   Username: `${account.name}`,
                   Role: `${LHRoles.find( x => x === 'GlobalAdmin')?'GlobalAdmin' : LHRoles[projects?.indexOf(selectedProjectId.toUpperCase())]?.toString()}` 
        }});
    }
  }, [account, selectedProject]);

  const createScenarioLog = () =>{
        // tracking event
        appInsights.trackEvent({
          name: 'Create Scenario',
          properties: {
            Page: `${trackData.pages[4].name}`,
            Project: `${selectedProjectId}`,
            ProjectName: `${selectedProject?.projectName}`,
            User: `${account.username}`,
            Username: `${account.name}`,
            Role: `${LHRoles.find( x => x === 'GlobalAdmin')?'GlobalAdmin' : LHRoles[projects?.indexOf(selectedProjectId?.toUpperCase())]?.toString()}`  
          }
        });
      }

  /**
   * Finishes the create scenario
   * and re-directs to the scenario manager
   */
  const finishCreateScenario = () => {
    // clean state
    cleanCreateScenarioState();
    dispatch(setReqMessage(undefined));
    dispatch(setError(undefined));

    // re-direct to the scenario manager
    history.push(`/${selectedProjectId}/scenarios`);
  }

  /**
  * cleans the create scenario state on page re-load
  */
  useEffect(() => {
    // check if there is an existing project and scenario id
    if (params.projectId) {
      dispatch(setSelectedProjectId(params.projectId));
    }

    cleanCreateScenarioState();

    if (params.scenarioId) {
      // editing existing scenario
      setEditScenarioId(params.scenarioId);
    }
  }, []);

  /**
 * Fetches the scenario if there is an existing one
 */
  useEffect(() => {
    if (accessToken && editScenarioId) {
      // fetch the scenario for editing
      dispatch(fetchScenario({ projectId: selectedProjectId, scenarioId: editScenarioId, accessToken: accessToken }));
    }
  }, [dispatch, selectedProjectId, editScenarioId, accessToken]);

  useEffect(() => {
    if (editScenario) {
      // when we have a scenario to edit
      setEditScenarioState(editScenario);

      // check the status of this scenario for editing, if it is pending then editing is not allowed
      // so show the analysing dialog instead
      if (editScenario.scenarioStatus.toLowerCase() === "pending") {
        setScenarioAnalysingDialogBoxVisibility(true);
      }
    }
  }, [editScenario]);

  /**
   * Sets the state for editing a scenario
   * @param {object} scenario 
   */
  const setEditScenarioState = (scenario) => {
    // set the name and description from the scenario
    dispatch(setName(scenario.scenarioName));
    dispatch(setDescription(scenario.scenarioDescription));

    // build the scenario schedule and capacity state from the changelist
    if (scenario.changeList) {
      let changeList = JSON.parse(scenario.changeList);

      // edited resources / capacity changes
      dispatch(setEditedResources(changeList.resourceChanges));

      // add existing activity schedule changes to state
      let existingActivityChanges = [];
      changeList.activityConditions.forEach(existingActivityChange => {
        let existingChange = {
          "activityId": existingActivityChange.activityId,
          "activityCode": existingActivityChange.activityCode,
          "shortestDuration": existingActivityChange.shortestDuration,
          "longestDuration": existingActivityChange.longestDuration,
          "earliestStartDate": existingActivityChange.earliestStartDate !== "None" ? existingActivityChange.earliestStartDate : null
        };
        existingActivityChanges.push(existingChange);
      });

      // dispatch to state
      dispatch(setManyActivityChanges(existingActivityChanges));
    }
  }

  /**
  * Cleans the create scenario state once scenario is analysed.
  */
  const cleanCreateScenarioState = () => {
    dispatch(setName(undefined));
    dispatch(setDescription(undefined));
    dispatch(setResourceProfile(undefined));
    dispatch(setEditedResources(undefined));
    dispatch(setSelectedDisciplineId(undefined));
    dispatch(setSelectedWeekStartDate(undefined));
    dispatch(removeActivityScheduleScenario());
    dispatch(setUnsavedChanges(false));
  }

  /**
   * Validates scenario inputs
   * @returns string
   */
  const validateScenarioInputs = () => {
    let validationMessage = "";
    if (!name || name === "") {
      validationMessage += "Name must be supplied\n";
    }

    if (!description || description === "") {
      validationMessage += "Description must be supplied\n";
    }

    if ((editedResources === undefined || editedResources.length === 0) && createScheduleScenario.length === 0) {
      validationMessage += "At least one schedule or capacity change must be supplied\n";
    }
    return validationMessage;
  }

  /**
* Tab change event
* @param {*} event 
* @param {*} newValue 
*/
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Builds the scenario object
   * @returns object
   */
  const buildScenarioObject = () => {
    /* 
         creating obj for schedule changes
      */
    let createScenarioConditions = createScheduleScenario.map(createScheduleScenario => ({
      activityId: createScheduleScenario.activityId,
      activityCode: createScheduleScenario.activityCode,
      shortestDuration: createScheduleScenario.shortestDuration ? createScheduleScenario.shortestDuration : 'None',
      longestDuration: createScheduleScenario.longestDuration ? createScheduleScenario.longestDuration : 'None',
      earliestStartDate: createScheduleScenario.earliestStartDate === undefined
        || createScheduleScenario.earliestStartDate === null ? 'None' : format(new Date(createScheduleScenario.earliestStartDate), 'yyyy-MM-dd'),
      latestFinishDate: 'None'
    }));

    /* 
      dispact scenario obj to api end point
     */
    let scenarioObject = {
      projectId: selectedProjectId,
      activityConditions: createScenarioConditions ? createScenarioConditions : [],
      resourceChanges: editedResources ? editedResources : [],
      userEmail: accounts[0].username,
      name: name,
      description: description
    }
    return scenarioObject;
  }

  /**
   * Saves the scenario
   */
  const saveUserGenScenarioAPI = (analyse) => {
    if (accessToken) {
      let validationMesssage = validateScenarioInputs();
      if (validationMesssage === "") {
        //disable analyse button
        setIsSaving(true);

        // build the scenario object
        let scenarioObject = buildScenarioObject();

        if (analyse) {
          // dispactch scenario object to api end point for updating or creating and then analysing
          if (editScenarioId) {
            // call api to update the existing scenario passing the scenario JSON object
            dispatch(updateUserGeneratedScenario({ projectId: selectedProjectId, scenarioId: editScenarioId, scenarioObject: scenarioObject, accessToken: accessToken })).then(
              dispatch(analyseUserGeneratedScenario({ projectId: selectedProjectId, scenarioId: editScenarioId, scenarioObject: scenarioObject, accessToken: accessToken })).then(
                (response) => { createScenarioLog() }
              )
            );
          }
          else {
            // call api to create the scenario passing the scenario JSON object
            dispatch(createUserGeneratedScenario({ projectId: selectedProjectId, scenarioObject: scenarioObject, accessToken: accessToken })).then(
              (response) => {
                dispatch(analyseUserGeneratedScenario({ projectId: selectedProjectId, scenarioId: response.payload.scenarioId, scenarioObject: scenarioObject, accessToken: accessToken })).then(
                  (response)=>{ createScenarioLog() }
                )
              }
            );
          }
        }
        else {
          // dispactch scenario object to api end point for updating or creating
          if (editScenarioId) {
            // call api to update the existing scenario passing the scenario JSON object
            dispatch(updateUserGeneratedScenario({ projectId: selectedProjectId, scenarioId: editScenarioId, scenarioObject: scenarioObject, accessToken: accessToken })).then(
              scenarioSaved()
            );
          }
          else {
            // call api to create the scenario passing the scenario JSON object
            dispatch(createUserGeneratedScenario({ projectId: selectedProjectId, scenarioObject: scenarioObject, accessToken: accessToken })).then(
              response =>{
                if (response.payload){
                  scenarioCreated(response.payload.scenarioId);
                }
              }
            );
          }
        }
      }
      else {
        // display the validation message and enable analyse button
        enqueueSnackbar('Please correct the following issues before your scenario can be analysed: \n\n' + validationMesssage, { variant: 'warning', style: { whiteSpace: 'pre-line' }, preventDuplicate: true });
        setIsSaving(false);
      }
    }
  }

  /**
   * Post scenario saved
   */
  const scenarioSaved = () => {
    setIsSaving(false);
    if (!saveScenarioError) {
      dispatch(setUnsavedChanges(false));
      enqueueSnackbar('Scenario saved as Draft', { variant: 'info', style: { whiteSpace: 'pre-line' }, preventDuplicate: true });
    }
    else {
      enqueueSnackbar('There was an error saving the scenario', { variant: 'error', style: { whiteSpace: 'pre-line' }, preventDuplicate: true });
    }
  }

  /**
   * Post scenario created, set to edit mode
   * @param {Guid} scenarioId 
   */
  const scenarioCreated = (scenarioId) =>{
    setIsSaving(false);
    if (!saveScenarioError) {
      enqueueSnackbar('Scenario created as Draft', { variant: 'info', style: { whiteSpace: 'pre-line' }, preventDuplicate: true });
      dispatch(setUnsavedChanges(false)).then(
        setEditScenarioId(scenarioId)
      );
    }
    else {
      enqueueSnackbar('There was an error creating the scenario', { variant: 'error', style: { whiteSpace: 'pre-line' }, preventDuplicate: true });
    }
  }
  

  /**
   * Monitor response from create scenario
   * API call to check for errors
   */
  useEffect(() => {
    if (error) {
      // display an error message and enable analyse button
      enqueueSnackbar('There was an error analysing the scenario: \n\n' + error, { variant: 'error', style: { whiteSpace: 'pre-line' }, preventDuplicate: true });
      setIsSaving(false);

      // clear error message
      dispatch(setError(undefined));
    }
  }, [error]);

  /**
   * Monitor response from create scenario
   * API call to check if successful
   */
  useEffect(() => {
    if (reqMessage) {
      if (reqMessage.status !== 500) {
        // check req message to see if the scenario creation
        // succeedded
        if (reqMessage === "Succeeded") {
          // show the analysing dialog confirmation
          setScenarioAnalysingDialogBoxVisibility(true);
          dispatch(setUnsavedChanges(false));
        } else {
          // display the error message and enable analyse button
          enqueueSnackbar('There was an error analysing the scenario: ' + reqMessage.replace(/[A-Z]/g, ' $&').trim(), { variant: 'error', style: { whiteSpace: 'pre-line' }, preventDuplicate: true  });
          setIsSaving(false);
        }
      }
      else {
        // 500 error, display an error messagea and enable analyse button
        enqueueSnackbar('There was an error analysing the scenario', { variant: 'error', style: { whiteSpace: 'pre-line' }, preventDuplicate: true  });
        setIsSaving(false);
      }

      // clear request message
      dispatch(setReqMessage(undefined));

    }
  }, [reqMessage]);

  useEffect(() => {
    // when edits occur check for changes and
    // set flag in state
    if(!isSaving) {
      let unsavedChanges = checkForUnsavedChanges();
      dispatch(setUnsavedChanges(unsavedChanges));
    }
  }, [editedResources, createScheduleScenario, name, description]);

  /**
   * Checks for any unsaved changes in the details
   * schedule and capacity and returns true or false
   * @returns boolean
   */
  const checkForUnsavedChanges = () => {
    let unsavedChanges = false;

    // if this is editing an existing scenario
    // then compare change lists
    if (editScenario) {
      let originalChangeList = JSON.parse(editScenario.changeList);
      let newScenarioObject = buildScenarioObject();
      let isActivityChanges = false,
        isResourceChanges = false,
        isDetailChanges = false;

      // compare details
      if (name && name.trim() !== editScenario.scenarioName.trim() ||
        description && description.trim() !== editScenario.scenarioDescription.trim()) {
        isDetailChanges = true;
      }

      // compare activities
      if (originalChangeList.activityConditions) {
        isActivityChanges = JSON.stringify(originalChangeList.activityConditions) !== JSON.stringify(newScenarioObject.activityConditions);
      }

      // compare resource changes
      if (originalChangeList.resourceChanges) {
        isResourceChanges = JSON.stringify(originalChangeList.resourceChanges) !== JSON.stringify(newScenarioObject.resourceChanges);
      }

      // return if any have changed
      unsavedChanges = isResourceChanges || isActivityChanges || isDetailChanges;
    }
    else {
      // check if any resource or activity changes have been made
      if ((editedResources && editedResources.length > 0) ||
        (createScheduleScenario && createScheduleScenario.length > 0) ||
        (name && name !== null && name !== "") ||
        (description && description !== null && description !== "")) {
        unsavedChanges = true;
      }
      else {
        unsavedChanges = false;
      }
    }

    // return result
    return unsavedChanges;
  }

  /**
  * Clears state and returns true
  * @returns true
  */
  const routerPromptOk =() =>{
    cleanCreateScenarioState();
    return true;
  }

  return (
    <>
      <RouterPrompt
        when={unsavedChanges}
        title="Leave Page"
        messageText="You have unsaved changes which will be lost. Are you sure you want to leave this page?"
        cancelText="Cancel"
        okText="Confirm"
        onOK={routerPromptOk}
        onCancel={() => false}
      />
     
      {selectedProject &&
        <div className="csp-page">
          <div className="csp-page-header">
            <div className="csp-page-breadcrumbs">
              <Link to={`/${selectedProjectId}/scenarios`} >
                <ArrowLeftIcon width={20} />
              </Link >
              <PageHeader
                pageTitle={`Optimisation / ${editScenarioId ? "Edit" : "New"} What If Scenario`}
                margin="40"
              >
              </PageHeader>
            </div>
          </div>
          <div className="csp-options-selector-panel panel-shadow">
            <ChangeListHistory tabName={value} />
          </div>
          <div className="csp-main">
            <div className={classNames('csp-container')}>
              <div className="csp-header">
                <div className="csp-tabs">
                  <CreateScenarioTabs
                    className="tabs-container"
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    value={value}
                    onChange={handleTabChange}
                  >
                    <CreateScenarioTab label={<span className="tab-label">Details</span>} value="details" />
                    <CreateScenarioTab label={<span className="tab-label">Schedule</span>} value="schedule" />
                    <CreateScenarioTab label={<span className="tab-label">Capacity</span>} value="Capacity" />
                  </CreateScenarioTabs>
                </div>
              </div>
              <div className="csp-tabs-content">
                <CreateScenarioTabPanel value={value} index="details" className="csp-content">
                  <CreateScenarioDetailsContainer />
                </CreateScenarioTabPanel>
                <CreateScenarioTabPanel value={value} index="schedule" className="csp-content">
                  <CreateScenarioScheduleContainer />
                </CreateScenarioTabPanel>
                <CreateScenarioTabPanel value={value} index="Capacity" className="csp-content">
                  <CreateScenarioCapacityContainer />
                </CreateScenarioTabPanel>
              </div>
              <div className="csp-page-actions">
                {!isSaving && <AuthorizedComponent value='create-new-scenario' projectId={selectedProjectId} disciplineId='All'>
                  <SecondaryLighthouseButton onClick={() => saveUserGenScenarioAPI(false)}>Save Draft</SecondaryLighthouseButton>
                  <PrimaryLighthouseButton onClick={() => saveUserGenScenarioAPI(true)}>Analyse</PrimaryLighthouseButton>
                </AuthorizedComponent>
                }
                {isSaving &&
                  <CircularProgressBar />
                }
              </div>
            </div>
          </div>
          <LighthouseDialog isVisible={confirmScenarioAnalysingDialogBoxVisibility} title="Scenario Analysing" width="600px" height="285px" z-index="99" noPadding={false}>
            <>
              <p>Your new scenario has started analysing! You will be navigated back to the optimisation list while your scenario is analysing.
              </p>
              <p>(this may take a few minutes)</p>
              <PrimaryLighthouseButton onClick={finishCreateScenario}>Back to Optimisation</PrimaryLighthouseButton>
            </>
          </LighthouseDialog>
        </div>
      }
    </>
  );
};

export default ProjectCreateScenarioPage;