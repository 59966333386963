import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk ,
  createDraftSafeSelector
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { scenarioOptionsEndpoint } from '../../api/apiRoutes';

export const fetchScenarioOptions = createAsyncThunk(
  'scenario/fetchoptions', async (params, thunkAPI) => {
    try{
      const response = await api.get(scenarioOptionsEndpoint(params.projectId, params.scenarioId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Create scenario options adapter
 */
 const scenarioOptionsAdapter = createEntityAdapter({
  selectId: (scenarioOption) => scenarioOption.optionId,
})

/**
 * Create scenarios options slice / reducers
 */
const scenarioOptionsSlice = createSlice({
  name: 'scenarioOptions',
  initialState: scenarioOptionsAdapter.getInitialState( { selectedScenarioOptionId: undefined,
                                                      isLoading: false,
                                                      error: undefined
                                                   } ),
  reducers: {
    setSelectedScenarioOptionId: (state, action) => {
      state.selectedScenarioOptionId = action.payload;
    },
    removeScenarioOptions: (state, action) => {
      scenarioOptionsAdapter.removeMany(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScenarioOptions.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchScenarioOptions.fulfilled, (state, action) => {
        scenarioOptionsAdapter.setMany(state, action.payload);
        if (state.payload){
          state.selectedScenarioOptionId = action.payload.filter(scenarioOption => scenarioOption.optionName.toLowerCase() !== 'base option')[0].optionId;
        }
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(fetchScenarioOptions.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Scenario options selectors
 */
 export const {
  selectById: selectScenarioOptionById,
} = scenarioOptionsAdapter.getSelectors((state) => state.scenarioOptions);

export const selectAllScenarioOptions = (state, scenarioId) => {
  const scenarioOptions = state.scenarioOptions;
  const filteredScenarioOptions = scenarioOptionsAdapter.getSelectors((state) => scenarioOptions)
                                                        .selectAll()
                                                        .filter(scenarioOption => scenarioOption.scenarioId === scenarioId);

  return filteredScenarioOptions;
}

/**
 * Scenarios options actions
 */
export const {  scenarioOptionsSuccess, 
                startLoading, 
                hasError, 
                setSelectedScenarioOptionId, 
                setAllScenarioOptions,
                removeScenarioOptions,
              } = scenarioOptionsSlice.actions;

/**
 * Export reducer
 */
export default scenarioOptionsSlice.reducer;