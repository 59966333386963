import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { formatCost } from '../../../../utils';
import { DENOMINATION } from '../../../../constants';
import { selectProjectById } from '../../../../redux/slices/projectsSlice';
import { useSelector } from "react-redux";
import './CostImpactNew.scss';

var classNames = require('classnames');

const CostImpactNew = (props) => {
  const { costImpact, scale = 2, denomination = DENOMINATION.auto, width } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId)); 
  var costImpactClass = '';

  if(costImpact > 5000) {
    costImpactClass = '#FADAE2';
  }
  else if(costImpact > 0 && costImpact <= 5000) {
    costImpactClass = '#FFE1B4';
  }
  else if(costImpact <= 0) {
    costImpactClass = '#C6E3D3';
  }
  else if(isNaN(costImpact)) {
    costImpactClass = '#C6E3D3';
  }
  else {
    costImpactClass = '#FADAE2';
  }
  
  return (
    <>
    <span className='cost-impact-class' style={{backgroundColor: costImpactClass, width: width}}>
      {isNaN(costImpact) && <>No Impact</>}
      {!isNaN(costImpact) && <>
      {costImpact < 0 && <>-</>}
      {costImpact > 0 && <>+</>}
      {costImpact == 0 && <>&ensp;</>} 
      {getSymbolFromCurrency(selectedProject?.currencyCode)}
      {formatCost(Math.abs(costImpact), scale, denomination.toLowerCase())}
      </>}
    </span>
    </>
  )
};

export default CostImpactNew;