import React from 'react';
import { getFormattedDate } from '../../../../../utils';
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';

const CapacityChangeDisplay = (props) => {
  const { capacityChange } = props;

  return (
    <>
      <div className='change-list-container'>
        <div className='show-title-container'>
          <div className='icon-div'>
            <DisciplineIcon className='icon' disciplineId={capacityChange.disciplineId} />
          </div>
          <div className='title-div'>
            {capacityChange.disciplineName}
          </div>
        </div>
        <hr></hr>
        <div className='show-changes-list-div-container'>
          <table className='show-changes-list'>
            <tbody>
              <tr>
                <th>Change: </th>
                <td>Discipline Capacity</td>
              </tr>
              <tr>
                <th>Week Commencing: </th>
                <td>{getFormattedDate(capacityChange.weekStartDate)}</td>
              </tr>
              <tr>
                <th>Original FTE Resource: </th>
                <td>{capacityChange.originalWeekFTECapcity.toFixed(2)}</td>
              </tr>
              <tr>
                <th> New FTE Resource: </th>
                <td>{capacityChange.weekFTECapacity.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
};

export default CapacityChangeDisplay;