import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { appInsights } from '../../AppInsights';
import PageHeader from '../../components/Shared/organisms/PageHeader';
import { selectProjectById, setSelectedProjectId } from '../../redux/slices/projectsSlice';
import OptimisationFocusAreasSelectionCriteriaContainer from './components/organisms/OptimisationFocusAreasSelectionCriteriaContainer';
import InlineInfoMessage from '../../components/Shared/molecules/InlineInfoMessage';
import InterfacePointDrivingPathContainer from './components/organisms/InterfacePointDrivingPathContainer';
import { ArrowLeftIcon } from '../../Icons';
import { Link } from 'react-router-dom';

import './ProjectOptimisationFocusAreasPage.scss';

let trackData = require('../../appTrackerInfo.json');
var classNames = require('classnames');

function FocusAreaTabPanel(props) {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

FocusAreaTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const FocusAreaTabs = withStyles({
  root: {

  },
  indicator: {
    backgroundColor: 'var(--color-additional)',
    marginBottom: '7px',
    height: '3px'
  }
})(Tabs);

const FocusAreaTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 'fit-content',
    maxWidth: null,
    fontWeight: '450',
    padding: '20px',
    color: 'var(--color-tab-text)',
    '&:hover': {
      color: 'var(--color-tab-text)'
    },
    '&$selected': {
      color: 'var(--color-tab-text)'
    },
    '&:focus': {
      color: 'var(--color-tab-text)',
    },
  },
  selected: {},
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))((props) => <Tab disableRipple {...props} />);

export const ProjectOptimisationFocusAreasPage = (props) => {
  const { match: { params } } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { selectedInterfacePointNumber } = useSelector(state => state.optimisationFocusAreas);
  const [value, setValue] = React.useState("DrivingPath");
  const dispatch = useDispatch();

  useEffect(() => {

  }, [selectedProjectId])

  useEffect(() => {
    if (params.projectId) {
      dispatch(setSelectedProjectId(params.projectId));
    }
  }, []);

  //send page view to App Insights
  appInsights.trackPageView({ name: `${trackData.pages[2].name}` });

  /**
* Tab change event
* @param {*} event 
* @param {*} newValue 
*/
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {selectedProject &&
        <div className="focus-areas-page">
          <div className="focus-areas-page-header">
              <Link className='arrow-left-icon' to={`/${selectedProjectId}/scenarios`} >
                <ArrowLeftIcon width={20} />
              </Link >
              <PageHeader
                pageTitle="Optimisation / Focus Area"
               margin="0"
              >
              </PageHeader>
          </div>
          <div className="focus-areas-manager-panel">
            <OptimisationFocusAreasSelectionCriteriaContainer />
          </div>
          <div className="focus-areas-main">
            <div className={classNames('focus-areas-container')}>
              <div className="focus-areas-header">
                <FocusAreaTabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleTabChange}>
                  <FocusAreaTab label={<span className="tab-label">Driving Path</span>} value="DrivingPath" />
                </FocusAreaTabs>
              </div>
              <FocusAreaTabPanel value={value} index="DrivingPath" className="focus-areas-content">
                {selectedInterfacePointNumber && <InterfacePointDrivingPathContainer />}
              </FocusAreaTabPanel>
              {!selectedInterfacePointNumber &&
                <div className="focus-areas-no-selection">
                  <InlineInfoMessage message="To avoid a delay of this Interface, we need to investigate the driving path, identify the activities in order to see how we can potentially save time and avoid delay. Please select an Interface from the Interface List to View its Driving Path."/>
                </div>
              }
            </div>
          </div>

        </div>
      }
    </>
  );
};

export default ProjectOptimisationFocusAreasPage;