import React, { useEffect, useState } from 'react';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import  Radio  from '@material-ui/core/Radio';
import  RadioGroup  from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import './EditUserWidget.scss';

const BlueRadio = withStyles({
  root: {
    color: 'var(--color-unchecked) !important',
    "&$checked": {
      color: 'var(--color-vibrant) !important'
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);


const WhiteTextTypography = withStyles({
  root: {
    fontWeight:"500 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: 'var(--color-vibrant) !important'
  }
})(Typography);

const EditUserWidgetContainer = (props) => {
  const { disciplines, loggedUserRoles, appRoles, selectedProject, cancelUserEdit, selectedUser, editUserRole , userProjectRole, userProjectDisciplines } = props;
  const [ disSelect, setdisSelect ] = useState(true);
  const [ disciplineList, setDisciplineList] = useState([]);
  const [ changedRole, setChangedRole ] = useState('');
  const [ changedRoleIds, setChangedRoleIds] = useState([]);
  const [ currentRoleIds, setCurrentRoleIds] = useState([]);
  const [ allRoles, setAllRoles ] = useState([]);
  const [ allowedDisciplines, setAllowedDiscipline ] = useState([]);
   
  useEffect(() => {
      if(loggedUserRoles){
        if(loggedUserRoles.includes('GlobalAdmin')){
          setAllRoles([{ label: 'Project Owner', value: 'owner'},{ label: 'Contributor', value: 'contributor'}, { label: 'Member', value: 'member'}])
        }
        else if(loggedUserRoles.includes('Owner')){
          setAllRoles([{ label: 'Project Owner', value: 'owner'},{ label: 'Contributor', value: 'contributor'}, { label: 'Member', value: 'member'}])
        }
        else if(loggedUserRoles.includes('Contributor')){
          setAllRoles([{ label: 'Contributor', value: 'contributor'}, { label: 'Member', value: 'member'}])
        }
      }
  },[loggedUserRoles])

  useEffect(() => {
    if(disciplines && userProjectRole && userProjectDisciplines){
      if(userProjectRole === 'GlobalAdmin'){
         setAllowedDiscipline([...disciplines]);
      }
      else if(userProjectRole === 'Owner'){
         setAllowedDiscipline([...disciplines]);
      }
      else if(userProjectRole === 'Contributor'){
         var selectedDisc = [];
          selectedDisc = disciplines.filter(function(obj) { 
             return userProjectDisciplines.indexOf(obj?.disciplineName) !== -1; 
         });
         setAllowedDiscipline([...selectedDisc]);
      }
    }
},[disciplines])

  useEffect(()=>{
    if(selectedUser){
      let projectId = selectedProject.projectId.toUpperCase();
      let RoleIds = [];
      let currentRole ;
      if(selectedUser?.userAccessGroup?.includes('Owner')){
        let roleValue = `Owner_${projectId}`;
        RoleIds.push(appRoles?.filter((role) => role?.value === roleValue)[0]?.id);
        currentRole = 'owner';
      }
      else if(selectedUser?.userAccessGroup?.includes('Contributor')){
          let Dis = [];
          setdisSelect(false)
          selectedUser?.discipline?.map((val, i)=>{
          let roleValue = `Contributor_${projectId}_${val.replace(' ','')}`;
          Dis.push(val);
          RoleIds.push(appRoles?.filter((role) => role?.value === roleValue)[0]?.id);
        })
        currentRole = 'contributor';
        setDisciplineList(Dis);
      }
      else if(selectedUser?.userAccessGroup?.includes('Member')){
        let roleValue = `Member_${projectId}`;
        RoleIds.push(appRoles?.filter((role) => role?.value === roleValue)[0]?.id);
        currentRole = 'member';
      }
      setCurrentRoleIds(RoleIds);
      setChangedRole(currentRole);
    }
  },[])


  /**
   * Cancels the user edit and closes dialog
   */
  const cancelEdit = () => {
    cancelUserEdit();
  }

  /**
   * Edit the user closes dialog
   */
  const editRole = () =>{

    var addRoleIds = changedRoleIds.filter(function(obj) { return currentRoleIds.indexOf(obj) == -1; });
    var removeRoleIds = currentRoleIds.filter(function(obj) { return changedRoleIds.indexOf(obj) == -1; });
     editUserRole(removeRoleIds, addRoleIds);
   }

  const roleChange = (event, roleVal) => {
    if(roleVal === 'member' || roleVal === 'owner'){
      setChangedRole(roleVal)
      setdisSelect(true)
    }else{
      setChangedRole(roleVal)
      setdisSelect(false)
    }
  }

  const disciplineChange = (event) => {
    const propValue = event.target.properties;
    if(propValue.checked){
      setDisciplineList([...disciplineList, propValue.label]);
    }else{
      const updatedSelectedDis = disciplineList.filter((dis) => {
        return !(dis === propValue.label );
    });
    setDisciplineList(updatedSelectedDis);
    }
  }


useEffect(() => {
    if(changedRole){
      let projectId = selectedProject.projectId.toUpperCase();
      let RoleIds = [];
      if(changedRole === 'contributor' && disciplineList){
        disciplineList?.map((val, i)=>{
          let roleValue = `contributor_${projectId}_${val.replaceAll(' ','')}`;
          RoleIds.push(appRoles?.filter((role) => role?.value?.toLowerCase() === roleValue?.toLowerCase())[0]?.id);
        })
      }else if(changedRole === 'owner'){
        let roleValue = `owner_${projectId}`;
        RoleIds.push(appRoles?.filter((role) =>role?.value?.toLowerCase() === roleValue?.toLowerCase())[0]?.id);
      }
      else if(changedRole === 'member'){
        let roleValue = `member_${projectId}`;
        RoleIds.push(appRoles?.filter((role) => role?.value?.toLowerCase() === roleValue?.toLowerCase())[0]?.id);
      }
      setChangedRoleIds(RoleIds)
    }
   
  }, [ changedRole, disciplineList ]);

 /**
  * set the current selected User Role as default
  */
  const getCurrentRole = () =>{
    if(selectedUser){
      if(selectedUser?.userAccessGroup?.includes('Owner')){
        return 'owner';
      }
      else if(selectedUser?.userAccessGroup?.includes('Contributor')){
        return 'contributor';
      }
      else if(selectedUser?.userAccessGroup?.includes('Member')){
        return'member';
      }
    }
 }

  /**
  * set the current selected User Disciplines as default if role is contributor
  */
   const getCurrentUserDisciplines = (disciplineName) =>{
    if(selectedUser && disciplineName){
      if(selectedUser?.discipline.length > 0 && selectedUser?.userAccessGroup?.includes('Contributor'))
          if(selectedUser?.discipline?.includes(disciplineName)){
                return true;
          }else{
                return false;
          }
     }
    return false;
 }
  
  return (
    <>
         <div className="user-edit-container">
          <div className='user-edit-content'>
          <div className="edit-popover-section">
            <h4>1. Choose Member Role</h4>
            <p>Select a role for the member:</p>
            <div className='input-role-box' >
               <RadioGroup  row aria-label="role-preference" defaultValue={getCurrentRole}  name="radio-buttons-group" size="small" onChange={roleChange}>
                 {allRoles?.map((currRole,i)=>{
                  return(
                     <FormControlLabel key={i} value={currRole?.value} control={<BlueRadio size="small"/>} label={<WhiteTextTypography>{currRole?.label}</WhiteTextTypography>}/>
                   ) 
                 })}    
               </RadioGroup>
            </div>
          </div>
          <hr color='#E4F1F8' ></hr>
          <div className="edit-popover-section">
            <h4>2. Select Disciplines</h4>
            <p>Select all Disciplines this user can access:</p>
            <div className='input-dis-box' >
              {allowedDisciplines?.map((dis, i)=> {
               return (
                    <CheckBoxComponent checked={getCurrentUserDisciplines(dis?.disciplineName)}  onChange={disciplineChange} disabled={disSelect} key={i} label={dis?.disciplineName} name="disciplines" labelPosition="After"/>
               )
            }
            )}
            </div>
          </div>
          </div>
          </div>
          <div className="edit-popover-footer">
          <SecondaryLighthouseButton className="mr-15" onClick={cancelEdit}>Cancel</SecondaryLighthouseButton>
           <PrimaryLighthouseButton onClick={editRole}>Apply Changes</PrimaryLighthouseButton> 
        </div>
        </>

  );
}

export default EditUserWidgetContainer;