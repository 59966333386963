import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk ,
  createDraftSafeSelector
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { activityCompletionStatusUpdateEndPoint } from '../../api/apiRoutes';

export const updateActivityCompletionStatus = createAsyncThunk(
  'scenario/updateActivityCompletionStatus', async (params, thunkAPI) => {
    try{
      const response = await api.put(activityCompletionStatusUpdateEndPoint(params.projectId), params.activityChanges, { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Create activities adapter
 */
 const updateActivityStatusAdapter = createEntityAdapter({
  selectId: (activity) => activity.activitiesCount,
})

/**
 * Create activities slice / reducers
 */
const updateActivityCompletionStatusSlice = createSlice({
  name: 'updateActivityCompletionStatus',
  initialState: updateActivityStatusAdapter.getInitialState( { activitiesCount: undefined,
                                                      isLoading: false,
                                                      error: undefined
                                                   } ),
  reducers: {
    setUpdatedActivityId: (state, action) => {
      state.activitiesCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateActivityCompletionStatus.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(updateActivityCompletionStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        updateActivityStatusAdapter.setOne(state, action.payload);
      })
      .addCase(updateActivityCompletionStatus.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Export reducer
 */
export default updateActivityCompletionStatusSlice.reducer;