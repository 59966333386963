import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { fetchConstructionRisks } from '../../../../../redux/slices/alertsSlice';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import '../../../ProjectProgressWidgets.scss';
import IFCDelayIcon from '../../atoms/IFCDelayIcon';

const ProjectConstructionRisksWidget = (props) => {
  const { project } = props;
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { error, constructionRisksLoading } = useSelector(state => state.alerts);
  const { constructionRisks } = useSelector(state => state.alerts);
  const [alertCountForConstructionRisks, setAlertCountForConstructionRisks] = useState([]);

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  useEffect(() => {
    // fetch construction risks
    if (accessToken) {
      dispatch(fetchConstructionRisks({ projectId: selectedProjectId, accessToken: accessToken }));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  useEffect(() => {
    if (constructionRisks) {
      let sortedAlertsCountForConstructionRisks = constructionRisks.slice().sort((a, b) => a.totalDelay < b.totalDelay ? 1 : -1);
      let maxDelay = getMaxDelayValue(sortedAlertsCountForConstructionRisks);
      let interval = Math.round(maxDelay / 5);
      let scaledConstructionRisks = sortedAlertsCountForConstructionRisks.map((item) => ({...item, scale: getScale(item.totalDelay, interval)}));
      setAlertCountForConstructionRisks(scaledConstructionRisks);
    }
    else {
      setAlertCountForConstructionRisks([]);
    }
  }, [constructionRisks]);

  const getMaxDelayValue = (risks) => {
      return Math.max(...risks.map(item => item.totalDelay), 0);
  };

  const getScale = (value, interval) => {
    if(value == 0)
      return 0;
    
    const diff = value - interval;

    switch(true) {
        case diff <= 0:
            return 1;
        case (diff > 1 && diff <= (2 * interval)):
            return 2;
        case (diff > interval && diff <= (3 * interval)):
            return 3;
        case (diff > (2 * interval) && diff <= (4 * interval)):
            return 4;
        case (diff > (3 * interval) && diff <= (5 * interval)):
            return 5;
    }
  };

  if (project) {

    return (
      <>
        {constructionRisksLoading &&
          <CircularProgressBar />
        }
        {error && !constructionRisksLoading &&
          <ErrorMessage message="Unable to get critical packages" />
        }
        {!constructionRisksLoading && !error &&
          <>
            <div className="widget-container">
              <div className="widget-title">
                <h3>Forecast Downstream Impact</h3>
                <span className="tooltip ml-10">
                  <InfoTooltip message={'A prediction of your disciplines that will not complete design as scheduled and cause a downstream delay to the client or construction partners.'}  iconSize={20} width="none" />
                </span>
              </div>
              <div className="widget-summary">
                <div className="widget-content">
                  {alertCountForConstructionRisks.length === 0 &&
                    <p>There are currently no Downstream Impact with alerts.</p>
                  }
                  {alertCountForConstructionRisks.length > 0 &&
                    <table>
                      <thead>
                        <tr>
                          <th>Discipline</th>
                          <th style={{ textAlign: 'center' }}>Completion Delay</th>
                        </tr>
                      </thead>
                      <tbody>
                        {alertCountForConstructionRisks.map((constructionRisk) => {
                          return (
                            <tr key={constructionRisk.disciplineId}>
                              <td>{constructionRisk.disciplineName}</td>
                              <td style={{ textAlign: 'center' }} title={constructionRisk.totalDelay > 0 ? "Total IFC Completion Delay : " + constructionRisk.totalDelay + " days" : "No delay"}>
                                <IFCDelayIcon scale={constructionRisk.scale}></IFCDelayIcon>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>
          </>
        }
      </>
    );
  }
  else {
    return null;
  }
}

export default ProjectConstructionRisksWidget;