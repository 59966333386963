import { api } from '../../api';
import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk ,
} from '@reduxjs/toolkit';
import {  scenariosEndpoint, 
          scenarioEndpoint,
          createUserGeneratedScenarioEndPoint,
          updateUserGeneratedScenarioEndPoint 
} from '../../api/apiRoutes';

/**
 * Fetches scenarios
 * @returns Scenarios
 */
export const fetchScenarios = createAsyncThunk(
  'project/fetchscenarios', async (params, thunkAPI) => {
     try {
        const response = await api.get(scenariosEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Fetches a scenario by Id
 * @returns Scenario
 */
 export const fetchScenario = createAsyncThunk(
  'project/fetchscenario', async (params, thunkAPI) => {
     try {
        const response = await api.get(scenarioEndpoint(params.projectId, params.scenarioId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Creates a user gen scenario
 * @returns Scenario
 */
export const createUserGeneratedScenario = createAsyncThunk(
  'project/createusergeneratedscenario', async (params, thunkAPI) => {
    try {
      const response = await api.post(createUserGeneratedScenarioEndPoint(params.projectId), params.scenarioObject, { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

/**
 * Updates a user gen scenario
 * @retturns Scenario
 */
export const updateUserGeneratedScenario = createAsyncThunk(
  'project/updateusergeneratedscenario', async (params, thunkAPI) => {
    try {
      const response = await api.post(updateUserGeneratedScenarioEndPoint(params.projectId, params.scenarioId), params.scenarioObject, { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

/**
 * Create scenarios adapter
 */
const scenariosAdapter = createEntityAdapter({
  selectId: (scenario) => scenario.scenarioId,
})

/**
 * Create scenarios slice / reducers
 */
const scenariosSlice = createSlice({
  name: 'scenarios',
  initialState: scenariosAdapter.getInitialState( {   selectedScenarioId: undefined, 
                                                      selectedScenarioOptionId: undefined,
                                                      isLoading: false,
                                                      error: undefined
                                                   } ),
  reducers: {
    setSelectedScenarioId: (state, action) => {
      state.selectedScenarioId = action.payload;
    },
    setSelectedScenarioOptionId: (state, action) => {
      state.selectedScenarioOptionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScenarios.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchScenarios.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        scenariosAdapter.setAll(state, action.payload);
      })
      .addCase(fetchScenarios.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(fetchScenario.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchScenario.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        scenariosAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchScenario.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(createUserGeneratedScenario.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(createUserGeneratedScenario.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        scenariosAdapter.upsertOne(state, action.payload);
      })
      .addCase(createUserGeneratedScenario.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(updateUserGeneratedScenario.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(updateUserGeneratedScenario.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        scenariosAdapter.upsertOne(state, action.payload);
      })
      .addCase(updateUserGeneratedScenario.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Scenario selectors
 */
export const {
  selectAll: selectAllScenarios,
  selectById: selectScenarioById,
} = scenariosAdapter.getSelectors((state) => state.scenarios);

/**
 * Scenarios actions
 */
export const {  scenariosSuccess, 
                startLoading, 
                hasError, 
                setSelectedScenarioId, 
                setAllScenarios
              } = scenariosSlice.actions;

/**
 * Export reducer
 */
export default scenariosSlice.reducer;