import React from 'react';
import './ScenarioProcessingInfo.scss';
import BuildingAnimation from '../../../../../components/Shared/atoms/BuildingAnimation';

const ScenarioProcessingInfo = () => {

  return (
        <div className="scenario-process-container">
              <div className="scenario-process-body">
                      <div className="scenario-process-img">
                         <BuildingAnimation width='300px' />
                      </div>
                    <h5>Almost There!</h5>
                    <p>We are currently processing your scenario.</p>
             </div>
        </div>
  );
}

export default ScenarioProcessingInfo;