import React from 'react';
import './ActivityOptimisationOption.scss';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import { getFormattedDate } from '../../../../../utils';
import TimeImpact from '../../../../../components/Shared/atoms/TimeImpact';


const ActivityOptimisationOption = (props) => {
  const { selectedScenarioOption } = props;

  return (
    <>
      <div className="activity-properties">
        <div className="al-prop-section">
         <div className="al-prop-title">Time Impact Breakdown
         <span className="tooltip">
            <InfoTooltip message={'A quick summary of the impact that optimisation could have on the schedule.'}  iconSize={15} width="none" />
          </span>
         </div>
           <div className="al-prop-container">
             <div className="activity-description-event">
               <ul>
                 <li>The final design activity in the programme has moved by <b><TimeImpact timeImpactDelayDays={selectedScenarioOption.ifcCompletionDelayDays} /></b>  to <b>{getFormattedDate(selectedScenarioOption.optionEndDate)}</b>.</li>
                 <li>The final design activity is <u><b>{selectedScenarioOption.activityNameFinalPackage && selectedScenarioOption.activityNameFinalPackage.trim()}</b></u>. The final planned design activity is <u><b>{selectedScenarioOption.plannedActivityName && selectedScenarioOption.plannedActivityName.trim()}</b></u>.</li>
                 <li>The net change across all work packages is <b><TimeImpact timeImpactDelayDays={selectedScenarioOption.totalDelayPredicted}/></b>.</li>
                 <li>On average, work package end dates have changed by <b><TimeImpact timeImpactDelayDays={selectedScenarioOption.averageDelayPredicted} /></b>.</li>
                 <li>The end date of work package <u><b>{selectedScenarioOption.maximumDelayPackageName}</b></u> has changed the most, at <b><TimeImpact timeImpactDelayDays={selectedScenarioOption.maximumDelayPredicted} /></b>.</li>
               </ul>
             </div>
         </div>
    </div>
    </div>
    </>
  );
}

export default ActivityOptimisationOption;