import React, { useEffect, useState } from 'react';
import { getFormattedDate } from '../../../../../utils';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip/InfoTooltip';
import InfoMessage from '../../../../../components/Shared/molecules/InfoMessage';
import { RelationTreeIcon, ProductionIcon, GlobIcon } from '../../../../../Icons';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Filter } from '@syncfusion/ej2-react-treegrid';
import ActivityStateDropDownListContainer from '../../molecules/ActivityStateDropDownListContainer/ActivityStateDropDownListContainer';
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';
import { L10n } from '@syncfusion/ej2-base';
import MultiSelectCheckBox from '../MultiselectCheckBox';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import './DesignProductionViewerTableTree.scss';

L10n.load({
    'ac-opt': {
        'treegrid': {
            'EmptyRecord': '',
        }
    }
  });
  const treeRowTemplate = (props) => {
    return (
        <tr className='activity-driver-row'>
            <td className='text-left-align'>
                <div> {props.type} </div>
            </td>
            <td>
                <div className='dis-name-container'>
                    <TooltipComponent title={props.name} className="tooltip-container">
                    {props.type === 'Discipline' && <div className='icon-style'><DisciplineIcon disciplineId={props.disciplineId} /></div>}
                    {props.type === 'Package' && <RelationTreeIcon className='icon-style' />}
                    {props.type === 'Phase' && <GlobIcon className='icon-style' />}
                    {(props.type === 'Task' || props.type === 'Milestone' || props.type === 'Finish Milestone') && <ProductionIcon className='icon-style' />}
                    {props.name?.length > 50 && props.name?.substring(0, 49) + "..."}
                    {props.name?.length <= 50 && props.name}
                    </TooltipComponent>
                </div>
            </td>
            <td className='text-left-align'>
                 <div>{getFormattedDate(props.plannedStartDate)}</div> 
            </td>
            <td className='text-left-align'>
                 <div>{getFormattedDate(props.plannedEndDate)}</div> 
            </td>
            <td className='text-left-align'>
                 <div>{getFormattedDate(props.actualStartDate)}</div> 
            </td>
            <td className='text-left-align'>
                 <div>{getFormattedDate(props.actualEndDate)}</div> 
            </td>
            <td className='text-status-value-align'>
                <div>{props.activityReportedStatus}</div>
            </td>
            <td className='dropdown-status-value-align'>
                <ActivityStateDropDownListContainer type={props.type} activityId={props.activityId} activityPackageId={props.activityPackageId} liveCompletionStatusId={props.activityCompletionStatusId} />
            </td>
            <td className='text-center'>
                  <MultiSelectCheckBox type={props.type} activityId={props.activityId} activityPackageId={props.activityPackageId} liveCompletionStatusId={props.activityCompletionStatusId} labelPosition="After"/>
            </td>
        </tr>
    );
  }

  const nameHeaderTemp = () => {
    return (<div className='text-name-align'>Name</div>);
  };

  const reportedStatusHeaderTemp = () => {
    return (<div className='text-status-align'><span className="info-icon"><InfoTooltip message="Reported Status is aligned with outputs from the project schedule" alignment="left-start" iconSize={14} width="none"/></span>Reported Status</div>);
  };

  const liveStatusHeaderTemp = () => {
    return (<div className='text-status-align'><span className="info-icon"><InfoTooltip message="Live Status is aligned with Lighthouse reporting and is used in activity based % complete calculations" alignment="left-start" iconSize={14} width="none"/></span>Live Status</div>);
  };



const DesignProductionViewerTableTree = (props) => {
    const { searchValue, selectedDiscipline, selectedPackage, selectedPhase, taskData } = props;
    const grdActivityTree = React.createRef();
    const [sortedtaskData, setSortedTaskData] = useState([]);

    const processTaskData = () => {
      let filteredData = [...taskData];
      if(selectedPhase !== undefined && selectedPhase !== null){
        filteredData = filter(filteredData, ({ phaseId }) => phaseId === selectedPhase)??filteredData;
      }
      else if(selectedPackage !== undefined && selectedPackage !== null){
        filteredData = filter(filteredData, ({ packageId }) => packageId === selectedPackage)??filteredData;
      }
      else if(selectedDiscipline !== undefined && selectedDiscipline !== null){
       filteredData = filter(filteredData, ({ disciplineId }) => disciplineId === selectedDiscipline)??filteredData;
      }
      if(searchValue?.length > 0){
        filteredData = filter(filteredData, ({ name }) => name.includes(searchValue))??null;
      }
      setSortedTaskData([...filteredData]);
    }


  /**
   * Does the filter
   * @param {object} array
   * @param {*} fn
   * @returns
   */

  function filter(array, fn) {
    return array.reduce((r, o) => {
        var subtasks = filter(o.subtasks || [], fn);
        if (fn(o) || subtasks.length)
          r.push(Object.assign({}, o, subtasks.length && { subtasks }));
          return r;
    }, []);

  }
  
    useEffect(() => {
      processTaskData();
    }, [selectedDiscipline, selectedPackage, selectedPhase, taskData])

    useEffect(() => {
        const timer = setTimeout(() => { processTaskData() }, 500)
        return () => clearTimeout(timer)
    }, [ searchValue ])


  return (
    <div className='treeGrid-container'>
        {sortedtaskData.length > 0 &&
              <TreeGridComponent
              id='grdActivityTree'
              ref={grdActivityTree}
              className='treeGrid-class'
              rowTemplate={treeRowTemplate}
              enableCollapseAll={true}
              dataSource={sortedtaskData}
              childMapping="subtasks"
              treeColumnIndex={1}
              allowTextWrap={true}
              height="100%"
              locale='ac-opt'
          >
              <Inject services={[Filter]}/>
              <ColumnsDirective>
                  <ColumnDirective headerText='Type' field='type' width='80' textAlign='left' ></ColumnDirective>
                  <ColumnDirective field='name' headerTemplate={nameHeaderTemp} width='400' textAlign='left'></ColumnDirective>
                  <ColumnDirective headerText='Planned Start' field='plannedStartDate' width='95' textAlign='left' ></ColumnDirective>
                  <ColumnDirective headerText='Planned End' field='plannedEndDate' width='95' textAlign='left'></ColumnDirective>
                  <ColumnDirective headerText='Actual Start' field='actualStartDate' width='90' textAlign='left' ></ColumnDirective>
                  <ColumnDirective headerText='Actual End' field='actualEndDate' width='90' textAlign='left'></ColumnDirective>
                  <ColumnDirective headerTemplate={reportedStatusHeaderTemp} field='activityReportedStatus' textAlign='left' ></ColumnDirective>
                  <ColumnDirective headerTemplate={liveStatusHeaderTemp} field='activityCompletionStatusId' textAlign='left' ></ColumnDirective>
                  <ColumnDirective headerText='Select' width='80' textAlign='center'></ColumnDirective>
              </ColumnsDirective>
             </TreeGridComponent>
       }
       {sortedtaskData.length === 0 &&
        <InfoMessage message="No activity found." />
      }
    </div>
  )
}

export default DesignProductionViewerTableTree;