import React, { useState, useEffect } from 'react';
import CircularProgressBar from '../CircularProgressBar';
import { NODE_STATE_COLOUR as nodeStateColour } from '../../../../../constants';
import classNames from "classnames";
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';
import { getCompletionStatusColour, getSelectedCompletionStatusColour, getSelectedCompletionStatusBorderColour, getPhaseNodeShadowColour, 
  getPhaseNodeColour, getPhaseNodeProgressColour, getPhaseNodeProgressBgColour, getPhaseNodeDisabledColour, LighthouseTooltip } from '../../../../../utils';
import { useSelector, useDispatch } from "react-redux";
import LinearProgressBar from '../../../../../components/Shared/molecules/LinearProgressBar';
import './ChartTask.scss';

/**
 * Chart Task Component
 * @param {object} props 
 * @returns 
 */
const ChartTask = (props) => {
  const { phaseId, label, disciplineId, actualPercentageComplete, plannedPercentageComplete, isFiltered, } = props;
  const [bgColour, setBgColour] = useState(getPhaseNodeColour(actualPercentageComplete, plannedPercentageComplete));
  const [completedBgColour, setCompletedBgColour] = useState(getCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete));
  const [borderColour, setBorderColour] = useState(getPhaseNodeColour(actualPercentageComplete, plannedPercentageComplete));
  const [phaseNodeShadowColor, setPhaseNodeShadowColor] = useState(getPhaseNodeShadowColour());
  const [disabledBorderColour, setDisabledBorderColour] = useState(getPhaseNodeDisabledColour('node'));
  const [phaseNodeProgressColour, setPhaseNodeProgressColour] = useState(getPhaseNodeProgressColour(actualPercentageComplete, plannedPercentageComplete));
  const [phaseNodeProgressBgColour, setPhaseNodeProgressBgColour] = useState(getPhaseNodeProgressBgColour(actualPercentageComplete, plannedPercentageComplete));
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [borderStyle, setBorderStyle] = useState('solid');

  const { selectedPhaseId } = useSelector(state => state.info);
  const { progressBarHeight } = "4px";

  useEffect(() => {
    if (selectedPhaseId === phaseId) {
      setIsSelected(true);
    }
    else {
      setIsSelected(false);
    }
  }, [selectedPhaseId]);

  let taskStyle;
  let iconStyle;
  let progressBarColour;
  let progressBarBgColour;
  let shadowColour;

  // set node colour and background colour based on percentage complete
  // and filter status
  let taskBorderColour;
  let backgroundColour;
  

  if (isFiltered) {
    taskBorderColour = nodeStateColour['filtered'];
  }
  else {
    taskBorderColour = getCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete);
    progressBarColour = phaseNodeProgressColour;
    progressBarBgColour = phaseNodeProgressBgColour;
  }

  if (isSelected) {
    taskBorderColour = `${getSelectedCompletionStatusBorderColour(actualPercentageComplete, plannedPercentageComplete)}`;
    backgroundColour = `${getSelectedCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete)}`;
    shadowColour = `0px 0px 1px 1px ${getPhaseNodeShadowColour('shadow')}`;
    taskStyle = {
      ...taskStyle,
      borderColor: taskBorderColour, borderStyle: 'dashed', background: backgroundColour, boxShadow: shadowColour};
  }
  else {
    if (actualPercentageComplete === 100) {
      taskStyle = {
        ...taskStyle,
        borderColor: `${borderColour}`, borderStyle: `${borderStyle}`, background: `${completedBgColour}`, boxShadow: `0px 0px 1px 1px ${phaseNodeShadowColor}`
      };
    }
    else {
      taskStyle = { 
        ...taskStyle,
        borderColor: `${borderColour}`, borderStyle: `${borderStyle}`, background: `${bgColour}`, boxShadow: `0px 0px 1px 1px ${phaseNodeShadowColor}`
      };
    }
  }

  // add the box shadow colour
  taskStyle = {
    ...taskStyle,
    boxShadow: `0 0 0 ${taskBorderColour}`
  }

  iconStyle = {
    background: 'linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3))'
  }

  const onMouseEnter = () => {
    setBgColour(getSelectedCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete));
    setCompletedBgColour(getSelectedCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete));
  };
  const onMouseLeave = () => {
    setBgColour(getPhaseNodeColour(actualPercentageComplete, plannedPercentageComplete));
    setCompletedBgColour(getCompletionStatusColour(actualPercentageComplete, plannedPercentageComplete));
  };

  return (
    <div className={classNames({ task: true, filtered: isFiltered, highlighted: isHighlighted })}
          style={taskStyle} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className="discipline-icon-wrapper" style={iconStyle}>
        <DisciplineIcon disciplineId={disciplineId} />
      </div>
      <div className="progress">
        <LinearProgressBar percentage={actualPercentageComplete}
                           backgroundBarColour={progressBarBgColour}
                           actualBarColour={progressBarColour} 
                           height={progressBarHeight}
                           />
      </div>
    </div>
  );
};

export default ChartTask;