import React , { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Inject } from '@syncfusion/ej2-react-grids';
import { selectAllDeliverableDrivers, fetchAlertDeliverableDrivers } from '../../../../../redux/slices/alertDeliverableDriversSlice';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import './AlertDeliverableDrivers.scss';

const grdAlertDeliverableDrivers = React.createRef();

const AlertDeliverableDrivers  = (props) => {
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const { deliverableError, deliverableLoading } = useSelector(state => state.alertDeliverableDrivers);
  const { selectedAlertIdInManager } = useSelector(state => state.alertSelection);
  const deliverableDrivers = useSelector((state) => selectAllDeliverableDrivers(state, selectedAlertIdInManager));
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);


  useEffect(() => {
    /**
 * Acquire access token
 */
  if (accounts.length > 0) {
    const request = {
      scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
      account: accounts[0]
    };
    instance.acquireTokenSilent(request).then(response => {
      setAccessToken(response.accessToken);
    }).catch(error => {
      // acquireTokenSilent can fail for a number of reasons, fallback to interaction
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenPopup(request).then(response => {
          setAccessToken(response.accessToken);
        });
      }
    });
  }
}, [accounts]);

     /**
   * Fetches the alert deliverable drivers for the alert
   */
      useEffect(() => {
        if (accessToken){
          dispatch(fetchAlertDeliverableDrivers({ projectId: selectedProjectId, alertId: selectedAlertIdInManager, accessToken: accessToken}))   
        }
      }, [dispatch, selectedAlertIdInManager, accessToken]);

  const gridRowTemplate = (props) => {

    return (
      <tr className="alert-driver-row">
        <td>
          {props.deliverableName}
        </td>
        <td className="text-left">
          {props.deliverableId}
        </td>
        <td className="text-left">
          {props.expectedCode}
        </td>
        <td className="text-left">
          {props.driver}
        </td>
        <td className="center-text">
        {Math.round(props.actualPercentageComplete * 100)}%
        </td>
        <td className="center-text">
        {Math.round(props.plannedPercentageComplete * 100)}%
        </td>
      </tr>
    )
  }

  return (
    <>
     { deliverableLoading && 
        <CircularProgressBar />
      }
      { deliverableError && !deliverableLoading &&
        <ErrorMessage message="Unable to display alert deliverable drivers." />
      }
      { !deliverableError && !deliverableLoading && deliverableDrivers.length === 0 &&
        <ErrorMessage message="No deliverable drivers are available." />
      }
      { !deliverableLoading && !deliverableError && deliverableDrivers.length > 0 && 
        <div className="alert-drivers-container">
          <GridComponent
               id='grdAlertDeliverableDrivers'
               ref={grdAlertDeliverableDrivers}
               dataSource={deliverableDrivers} 
               allowSorting={true} 
               rowTemplate={gridRowTemplate}
               height='700'
               >
            <ColumnsDirective>
              <ColumnDirective field='deliverableName' headerText='Deliverable Name' width='350' headerTextAlign='left' />
              <ColumnDirective field='deliverableId' headerText='Deliverable Id' width='200' headerTextAlign='left' />
              <ColumnDirective field='expectedCode' headerText='Expected Code' headerTextAlign='left' />
              <ColumnDirective field='driver' headerText='Driver' headerTextAlign='left' />
              <ColumnDirective field='actualPercentageComplete' headerText='Actual % Complete'  headerTextAlign='center' />
              <ColumnDirective field='plannedPercentageComplete' headerText='Planned % Complete' headerTextAlign='center' />
            </ColumnsDirective>
            <Inject services={[Page, Sort]} />
          </GridComponent>
        </div>
      }
    </>
  );
}

export default AlertDeliverableDrivers;