import React from 'react';
import { selectDisciplineById } from '../../../../redux/slices/disciplinesSlice';
import { useSelector } from "react-redux";
import { ReactComponent as DefaultDisciplineIcon } from '../../../../Icons/default-discipline-icon.svg';

import './DisciplineIcon.scss';

const DisciplineIcon = (props) => {
  const { disciplineId } = props;
  const discipline = useSelector((state) => selectDisciplineById(state, disciplineId));
  
  if (discipline?.disciplineIcon){
    return (  <img className="discipline-icon" src={`data:image/svg+xml;utf8,${encodeURIComponent(discipline?.disciplineIcon)}`} /> );
  }
  else{
    return ( <DefaultDisciplineIcon className="discipline-icon" /> );
  }
}

export default React.memo(DisciplineIcon);

