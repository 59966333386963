import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import CostImpact from '../../../../../components/Shared/atoms/CostImpact';
import TimeImpact from '../../../../../components/Shared/atoms/TimeImpact'
import { fetchAlertMitigationZero } from '../../../../../redux/slices/alertsSlice';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import RiskCategory from '../../../../../components/Shared/atoms/RiskCategory/RiskCategory';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import { DENOMINATION as denomination } from '../../../../../constants';
import { getRiskCategoryColour, getTimeColourInverse, getCostColourInverse, getCostScale } from '../../../../../utils';
import '../../../ProjectProgressWidgets.scss';



const ProjectMitigationZeroWidget = (props) => {
    const { project } = props;
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const { selectedProjectId } = useSelector(state => state.projects);
    const { error, isLoading } = useSelector(state => state.alerts);
    const { alertMitigationZero } = useSelector(state => state.alerts);

    useEffect(() => {
      /**
       * Acquire access token
       */
      if (accounts.length > 0) {
        const request = {
          scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
          account: accounts[0]
        };
        instance.acquireTokenSilent(request).then(response => {
          setAccessToken(response.accessToken);
        }).catch(error => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(request).then(response => {
              setAccessToken(response.accessToken);
            });
          }
        });
      }
    }, [accounts]);

    useEffect(() => {
      // fetch alert zero mitigation 
      if (accessToken) {
        dispatch(fetchAlertMitigationZero({ projectId: selectedProjectId, accessToken: accessToken }));
      }
    }, [dispatch, selectedProjectId, accessToken]);

if (project) {
    return (
      <>
        {isLoading &&
          <CircularProgressBar />
        }
        {error && !isLoading &&
          <ErrorMessage message="Can not load project forecast worst case impact" />
        }
        {!isLoading && !error &&
          <>
            <div className="widget-container">
              <div className="widget-title">
                <h3>Forecast Worst Case Impact &nbsp;</h3>
                <span className="tooltip">
                  <InfoTooltip message={'A prediction of the worst-case scenario if you take no action today to mitigate bottlenecks or productivity issues.'} iconSize={20} width="none"/>
                </span>
              </div>
              <div className="widget-content">
                <table>
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Design Cost</td>
                      <td>
                        <article
                          style={{ backgroundColor: getCostColourInverse(alertMitigationZero.costImpactDesignTotal) }}>
                          <CostImpact costImpact={alertMitigationZero.costImpactDesignTotal} denomination={denomination.auto} />
                        </article>
                      </td>
                    </tr>
                    <tr>
                      <td>Construction Cost</td>
                      <td width={160}>
                        <article
                          style={{ backgroundColor: getCostColourInverse(alertMitigationZero.costImpactConstructionTotal) }}>
                          <CostImpact costImpact={alertMitigationZero.costImpactConstructionTotal} denomination={denomination.auto} />
                        </article>
                      </td>
                    </tr>
                    <tr>
                      <td>3rd Party Cost</td>
                      <td>
                        <article
                          style={{ backgroundColor: getCostColourInverse(alertMitigationZero.costImpactClientTotal) }}>
                          <CostImpact costImpact={alertMitigationZero.costImpactClientTotal}  denomination={denomination.auto} />
                        </article>
                      </td>
                    </tr>
                    <tr>
                      <td>Time Delay</td>
                      <td>
                        <article
                          style={{ backgroundColor: getTimeColourInverse(alertMitigationZero.ifcCompletionDelayDaysTotal) }}>
                            <TimeImpact timeImpactDelayDays={alertMitigationZero.ifcCompletionDelayDaysTotal} />
                        </article>
                      </td>
                    </tr>
                    <tr>
                      <td>Risk Category</td>
                      <td>
                          <article
                            style={{ backgroundColor: getRiskCategoryColour(alertMitigationZero.riskCategory) }}>
                             <RiskCategory riskCategory={alertMitigationZero.riskCategory} /> 
                          </article>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        }
      </>
    );
  }
  else {
    return null;
  }
}

export default ProjectMitigationZeroWidget;