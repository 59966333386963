import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { phasesEndpoint, phaseCostCodeEndpoint } from '../../api/apiRoutes';

/**
 * 
 * @returns Fetches phases
 */
export const fetchPhases = createAsyncThunk(
  'phase/fetchphases', async (params, thunkAPI) => {
     try {
        const response = await api.get(phasesEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});
/**
 * 
 * @returns Fetches phase cost codes
 */
 export const fetchPhaseCostCodes = createAsyncThunk(
  'package/fetchphasecostcodes', async (params, thunkAPI) => {
     try {
        const response = await api.get(phaseCostCodeEndpoint(params.projectId, params.phaseId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Create phases adapter
 */
const phasesAdapter = createEntityAdapter({
  selectId: (phase) => phase.phaseId,
})

/**
 * Create phases slice / reducers
 */
const phasesSlice = createSlice({
  name: 'phases',
  initialState: phasesAdapter.getInitialState( {    selectedPhaseId: undefined, 
                                                    isLoading: false,
                                                    error: undefined ,
                                                    costCodesLoading: false,
                                                    costCodes: []} ),
  reducers: {
    setSelectedPhaseId: (state, action) => {
      state.selectedPhaseId = action.payload;
    },
    removeAllPhases: (state) => {
      phasesAdapter.removeAll(state);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPhases.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchPhases.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        phasesAdapter.setAll(state, action.payload);
      })
      .addCase(fetchPhases.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(fetchPhaseCostCodes.pending, (state, action) => {
        state.costCodesLoading = true;
        state.error = undefined;
      })
      .addCase(fetchPhaseCostCodes.fulfilled, (state, action) => {
        state.costCodesLoading = false;
        state.error = undefined;
        state.costCodes = action.payload;
      })
      .addCase(fetchPhaseCostCodes.rejected, (state, action) => {
        state.costCodesLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Phase selectors
 */
export const {
  selectAll: selectAllPhases,
  selectById: selectPhaseById,
} = phasesAdapter.getSelectors((state) => state.phases);

/**
 * Phase actions
 */
export const {  phasesSuccess, 
                startLoading, 
                hasError, 
                setSelectedPhaseId, 
                setAllPhases,
                removeAllPhases
              } = phasesSlice.actions;

/**
 * Export reducer
 */
export default phasesSlice.reducer;