import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Inject, rowDataBound, row } from '@syncfusion/ej2-react-grids';
import { getFormattedDate, LighthouseTooltip } from '../../../../../utils';
import { RedDotIcon, GreenDotIcon } from '../../../../../Icons';
import { setSelectedFocusAreaNodeId, setHoverFocusAreaNodeId } from '../../../../../redux/slices/optimisationFocusAreasSlice';
import { useDispatch, useSelector } from "react-redux";

import './InterfacePointDrivingPathTable.scss';

const grdDrivingPathTable = React.createRef();

const InterfacePointDrivingPathTable = (props) => {
  const { drivingPathData } = props;
  const { selectedFocusAreaNodeId, focusAreaNodeFilterIds, selectedHoverFocusAreaNodeId  } = useSelector(state => state.optimisationFocusAreas);

  const textWrapSettings = { wrapMode: 'Both' }

  const dispatch = useDispatch();

  /**
   * refreshes the grid when filter ids change
   */
  useEffect(() =>{
    if (focusAreaNodeFilterIds){
      grdDrivingPathTable.current.dataSource = drivingPathData.filter(d => focusAreaNodeFilterIds.includes(d.focusAreaNodeId));
      grdDrivingPathTable.current.refresh();
    }
    else{
      grdDrivingPathTable.current.dataSource = drivingPathData;
      grdDrivingPathTable.current.refresh();  
    }
  }, [focusAreaNodeFilterIds]);

  /**
   * Sets a hovered state on the table row for the corresponding hovered node
   * when a hover focus area node id is set in state, only when there
   * is a selected focus area node (drill down mode)
   */
  useEffect(() => {
    if (selectedFocusAreaNodeId && selectedHoverFocusAreaNodeId && grdDrivingPathTable){
      // add a hover class to the associated row
      let rowToHighlight = grdDrivingPathTable.current.getRows().find(r => r.firstChild.innerText === selectedHoverFocusAreaNodeId);
      rowToHighlight?.classList.add("e-row-hovered");
    }
    else{
      // clears all hovered classes
      let allRows = grdDrivingPathTable.current.getRows();
      allRows?.forEach((row, index) => { 
        row.classList.remove("e-row-hovered");
      });
    }
  },[selectedHoverFocusAreaNodeId])

  /*
  * selecting and de-selecting
    the selected row data obj
  */
  const rowSelected = (e) => {
    if (e.data.focusAreaNodeId !== selectedFocusAreaNodeId) {
      dispatch(setSelectedFocusAreaNodeId(e.data.focusAreaNodeId));
    } else {
      dispatch(setSelectedFocusAreaNodeId(undefined));
    }
  }

  /**
   * Start date template
   * @param {obj} columnData 
   * @returns 
   */
  const predictedStartDateColumnTemplate = (columnData) =>{
    return(
      <>
        {getFormattedDate(columnData.predictedStartDate)}
      </>
    )
  }

  /**
   * End date template
   * @param {obj} columnData 
   * @returns 
   */
  const predictedEndDateColumnTemplate = (columnData) =>{
    return(
      <>
        {getFormattedDate(columnData.predictedEndDate)}
      </>
    )
  }

  /**
   * Driving path template
   * @param {obj} columnData 
   * @returns 
   */
  const drivingPathColumnTemplate = (columnData) =>{
    return(
      <>
        {columnData.isDrivingPath.toString() === 'false' ? <span><GreenDotIcon /> No</span> : <span><RedDotIcon /> Yes</span>}
      </>
    )
  }

  /**
   * Name template
   * @param {obj} columnData 
   * @returns 
   */
  const nameColumnTemplate = (columnData) =>{
    return(
      <span className="text-truncate" title={columnData.name}>
        {columnData.name}
      </span>
    )
  }

  /**
   * Row data bound event
   * @param {obj} e 
   */
  const rowDataBound = (e) =>{
    let gridElement = document.getElementById('grdDrivingPathTable').ej2_instances[0];

    if (selectedFocusAreaNodeId !== undefined){
      // sets focus area node id when hover over the table row
      e.row.addEventListener('mouseover', function (e) {
          dispatch(setHoverFocusAreaNodeId(gridElement.getRowInfo(e.target).rowData.focusAreaNodeId));
      });

      // un-set focus area node id 
      e.row.addEventListener('mouseout', function (e) {
          dispatch(setHoverFocusAreaNodeId(undefined));
      });
    }

    // if the row matches the selected node id then add a selected css class
    if (selectedFocusAreaNodeId === e.data.focusAreaNodeId){
      e.row.className = "e-row e-row-selected";
    }
  }

  return (
    <>
      <div className="driving-path-table">
        <div className='header-title'>
          <span>Activities & Interfaces:</span>
          <span className='total-count'>Total count: {focusAreaNodeFilterIds && focusAreaNodeFilterIds.length || !focusAreaNodeFilterIds && drivingPathData.length}</span>
        </div>

        <GridComponent
          id='grdDrivingPathTable'
          ref={grdDrivingPathTable}
          dataSource={drivingPathData}
          allowSorting={true}
          rowSelected={rowSelected}
          enableHover={true}
          enableStickyHeader={true}
          height='350px'
          allowTextWrap={true}
          textWrapSettings={textWrapSettings}
          delayUpdate={true}
          rowDataBound={rowDataBound}
        >
          <ColumnsDirective>
            <ColumnDirective field='focusAreaNodeId' visible={false} />
            <ColumnDirective field='type' headerText='Type' headerTextAlign='left' width='100' />
            <ColumnDirective field='name' headerText='Name' headerTextAlign='left' template={nameColumnTemplate} width='200' />
            <ColumnDirective field='nodeCode' headerText='Activity ID' headerTextAlign='left' width='200' />
            <ColumnDirective field='duration' headerText='Duration' width='110' headerTextAlign='left' />
            <ColumnDirective field='predictedStartDate' headerText='Predicted Start Date' headerTextAlign='left' width='120' template={predictedStartDateColumnTemplate} />
            <ColumnDirective field='predictedEndDate' headerText='Predicted End Date' headerTextAlign='left' width='120' template={predictedEndDateColumnTemplate} />
            <ColumnDirective field='discipline' headerText='Discipline' width='120' headerTextAlign='left' />
            <ColumnDirective field='package' headerText='Package' headerTextAlign='left' width='200' />
            <ColumnDirective field='phase' headerText='Phase' headerTextAlign='left' width='100' />
            <ColumnDirective field='isDrivingPath' headerText='Driving Path' headerTextAlign='left' template={drivingPathColumnTemplate} width='120' />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </div>
    </>
  );
}

export default InterfacePointDrivingPathTable;