import React, { useState} from 'react';
import ActivityStateConfirmChangesContainer from '../../molecules/ActivityStateConfirmChangesContainer/ActivityStateConfirmChangesContainer';
import DesignProductionViewerTableTree from '../../molecules/DesignProductionViewerTableTree/DesignProductionViewerTableTree'
import FilterActivityToolbar from '../../../../../components/Shared/molecules/FilterActivityToolbar';
import './DesignProductionViewerTable.scss';

  const DesignProductionViewerTable = (props) => {
  const {confirmSubmitChanges, disciplineOptions, packageOptions, phaseOptions, taskData, activityCompletionStatus, packagePctCompleteCalcTypes } = props;
  const SelectionByFields = {text: "text", value: "value"};
  const [selectedDiscipline, setselectedDiscipline] = useState(null);
  const [selectedPackage, setselectedPackage] = useState(null);
  const [selectedPhase, setselectedPhase] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  

  const handleDisciplineSelection = (e) => {
      setselectedDiscipline(e);
      setselectedPackage(null);
      setselectedPhase(null);
  }

  const handlePackageSelection = (e) => {
      setselectedPackage(e);
      setselectedPhase(null);
  }

  const handlePhaseSelection = (e) => {
      setselectedPhase(e);
  }

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
  }

  const cleanFilterState = () =>{
    setselectedDiscipline(null);
    setselectedPackage(null);
    setselectedPhase(null);
    setSearchValue("");
  }


  return (  <>
        <div className="activity-change-container">
            <div className='activity-filter-section'>
                 <FilterActivityToolbar
                      DisciplineOptions={disciplineOptions}
                      PackageOptions={packageOptions}
                      PhaseOptions={phaseOptions}
                      defaultDiscipline={selectedDiscipline}
                      defaultPackage={selectedPackage}
                      defaultPhase={selectedPhase}
                      searchValue={searchValue}
                      SelectionByFields={SelectionByFields}
                      handleDisciplineSelection={handleDisciplineSelection}
                      handlePackageSelection={handlePackageSelection}
                      handlePhaseSelection={handlePhaseSelection}
                      processSearch={handleSearch} />
            </div>
            <div className='activity-content-section'>
                  <DesignProductionViewerTableTree 
                      searchValue={searchValue} 
                      selectedDiscipline={selectedDiscipline} 
                      selectedPackage={selectedPackage}
                      selectedPhase={selectedPhase}
                      taskData={taskData} 
                      activityCompletionStatus={activityCompletionStatus} 
                      packagePctCompleteCalcTypes={packagePctCompleteCalcTypes}/>
            </div>
             <div className='activity-action-section'>
                   <ActivityStateConfirmChangesContainer confirmSubmitChanges={confirmSubmitChanges} cleanFilterState={cleanFilterState}/>   
              </div>
        </div>
    </>
  );
}

export default DesignProductionViewerTable;