import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

import ResourceAllocationChart from '../../molecules/ResourceAllocationChart';
import { selectDisciplineById, fetchDisciplineResourceUsage } from '../../../../../redux/slices/disciplinesSlice';
import { getGlobalFormat } from '../../../../../utils';
import './ResourceAllocationChartContainer.scss';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

const filterSelectorItemTemplate = (data) => {
  return (
    <div className="fs-item">
        {data.text}
    </div>
  );
};

const filterSelectorValueTemplate = (data) => {
  return (
    <div className="fs-item">
        {data.text}
    </div>
  );
};

const ResourceAllocationChartContainer = (props) => {
    const { disciplineId } = props;
    const dispatch = useDispatch();
    const { isAllocationLoading, allocationError, resourceUsage } = useSelector(state => state.disciplines);
    const disciplineItem = useSelector((state) => selectDisciplineById(state, disciplineId));
    const { selectedProjectId } = useSelector(state => state.projects);
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    const [resourceFilter, setResourceFilter] = useState('All');

    const [selectedFilterType, setSelectedFilterType] = useState('full');
    const fields = {text: 'text', value: 'value'}
    const filterOptions = [{text: 'Full', value: 'full'},
                            {text: '7 Days', value: 'week'},
                            {text: '14 Days', value: 'fortnight'},
                            {text: '30 Days', value: 'month'},
                            {text: '45 Days', value: 'monthhalf'},
                            {text: '3 Months', value: '3months'},
                            {text: '6 Months', value: '6months'},
                            {text: '9 Months', value: '9months'},
                            {text: '12 Months', value: 'year'},
                            {text: 'Custom', value: 'custom'}]
    
    const startDatePicker = React.useRef();
    const [startDate, setStartDate] = useState(startDatePicker.current?.value);
    const [displayDatePickers, setDisplayDatePickers] = useState(false);
    const endDatePicker = React.useRef();
    const [endDate, setEndDate] = useState(endDatePicker.current?.value);
    const [endDateMinimum, setEndDateMinimum] = useState(undefined);
    const [allowEndDateChange, setAllowEndDateChange] = useState(false);
    
    const millisecondsInDay = 86400000;
    const millisecondsInWeek = 7 * millisecondsInDay;
    const millisecondsInMonth = 2629800000;
    const millisecondsInYear = 31557600000;

    const handleClose = () => {
        props.closePopover();
    }

    const addTimePeriodToDate = (date) => {
      let dateMilliseconds = date.getTime();
      switch (selectedFilterType.value) {
        case 'week':
          return new Date(dateMilliseconds +  millisecondsInWeek);
          break;
        case 'fortnight':
          return new Date(dateMilliseconds +  (millisecondsInWeek * 2));
          break;
        case 'month':
          return new Date(dateMilliseconds +  millisecondsInMonth);
          break;
        case 'monthhalf':
          return new Date(dateMilliseconds +  (millisecondsInMonth * 1.5));
          break;
        case '3months':
          return new Date(dateMilliseconds +  (millisecondsInMonth * 3));
          break;
        case '6months':
          return new Date(dateMilliseconds +  (millisecondsInMonth * 6));
          break;
        case '9months':
          return new Date(dateMilliseconds +  (millisecondsInMonth * 9));
          break;
        case 'year':
          return new Date(dateMilliseconds +  millisecondsInYear);
          break;      
        default:
          return endDate;
          break;
      }
    }

    const startDateChange = (args) => {
      let changedStartDate = new Date(args.value);
 
      setStartDate(changedStartDate);
      setEndDateMinimum(new Date(changedStartDate.getTime() + (millisecondsInDay * 1.1)));

      if (selectedFilterType !== ('custom' || 'full')) {
        setEndDate(addTimePeriodToDate(changedStartDate));
      } else if (endDate?.getTime() <=  changedStartDate.getTime()) {
        setEndDate(endDateMinimum);
      }
    }

    const endDateChange = (args) => {
      let changedEndDate = new Date(args.value);  

      if (changedEndDate.getTime() < startDate.getTime()) {
        changedEndDate = new Date(startDate.getTime() + (millisecondsInDay * 1.1));
      }
      setEndDate(changedEndDate);
    }

    const clearStartDate = () => {
      setStartDate(undefined);
    }
    
    const clearEndDate = () => {
      setEndDate(undefined);
    }

    const handleFilterSelector = (event) => {
      if (event.value !== 'full') {
        setDisplayDatePickers(true);
      } else {
        setDisplayDatePickers(false);
        clearStartDate();
        clearEndDate();
      }

      if (event.value === 'custom') {
        setAllowEndDateChange(true);
      } else {
        setAllowEndDateChange(false);
      }

      setSelectedFilterType(event.itemData);
    }

    const handleSeriesFiltering = (event) => {
      setResourceFilter(event.target.innerText);
    }

    const convertToWeekly = (dayArray) => {
      let weeklyArray = [];
      let tempWeekly = {};
      
      let weekCounter = 1;

      dayArray.forEach((day) => {
        let matchingWeekIndex = weeklyArray.findIndex(spw => spw.weekId === weekCounter);

        if (matchingWeekIndex > -1 && new Date(day.workDate).getDay() !== 1) {
          weeklyArray[matchingWeekIndex].hours = day?.hours + weeklyArray[matchingWeekIndex]?.hours;
          weeklyArray[matchingWeekIndex].daysCounted++;
        } else {
        let weekCommenceDate = undefined;
        switch (new Date(day.workDate).getDay()) {
          case 0: // Sunday
            weekCommenceDate = new Date(day.workDate.getTime() - (millisecondsInDay * 1));
            break;
          case 1: // Monday
            weekCommenceDate = new Date(day.workDate.getTime() - (millisecondsInDay * 2));;
            weekCounter++;
            break;
          case 2: // Tuesday
            weekCommenceDate = new Date(day.workDate.getTime() - (millisecondsInDay * 3));
            break;
          case 3: // Wednesday
            weekCommenceDate = new Date(day.workDate.getTime() - (millisecondsInDay * 4));
            break;
          case 4: // Thursday
            weekCommenceDate = new Date(day.workDate.getTime() - (millisecondsInDay * 5));
            break;
          case 5: // Friday
            weekCommenceDate = new Date(day.workDate.getTime() - (millisecondsInDay * 6));
            break;
          case 6: // Saturday
            weekCommenceDate = day.workDate;
            break;
          default:
            break;
        }

          tempWeekly = { weekId: weekCounter, 
            daysCounted: 1,
            weekCommencing: weekCommenceDate, 
            firstDateCounted: day.workDate,
            hours: day.hours, 
            disciplineId: day.disciplineId, 
            projectId: day.projectId,
          }
          weeklyArray.push(tempWeekly);
        }
      });
      return weeklyArray;
    }
    
    const processData = (rawData) => {
      const seniorActual = [];
      let seniorPlanned = [];

      const juniorActual = [];
      let juniorPlanned = [];

      const intermediateActual = [];
      let intermediatePlanned = [];

      const leadActual = [];
      let leadPlanned = [];

      let filtered = rawData;

      // Date filtering
      if (startDate && endDate) {
        filtered = rawData?.filter(d => startDate?.getTime() <= new Date(d.workDate).getTime() && new Date(d.workDate).getTime() <= endDate?.getTime());
      } else if (endDate) {
        filtered = rawData?.filter(d => new Date(d.workDate).getTime() <= endDate?.getTime());
      } else if (startDate) {
        filtered = rawData?.filter(d => startDate?.getTime() <= new Date(d.workDate).getTime());
      } else {
        filtered = rawData;
      }


      switch (resourceFilter) {
        case "Senior":
          filtered = filtered.filter(e => e.resourceType === "Senior");
          break;
        case "Junior":
          filtered = filtered.filter(e => e.resourceType === "Junior");
          break;
        case "Lead":
          filtered = filtered.filter(e => e.resourceType === "Lead");
          break;
        case "Intermediate":
          filtered = filtered.filter(e => e.resourceType === "Intermediate");
          break;
      
        default:
          break;
      }
      let sorted = filtered?.slice().sort((a, b) => new Date(b.workDate).getTime() - new Date(a.workDate).getTime()).reverse();
      let seniorCount = 0;
      let juniorCount = 0;
      let intermediateCount = 0;
      let leadCount = 0;

      

      // sorted = sorted?.filter((s) => !(new Date(s.workDate).getDay() === 0 || new Date(s.workDate).getDay() === 6));

      if (sorted) {
        for (let dataPoint of sorted) {
          let tempActual = {};
          let tempPlanned = {};
          switch (dataPoint.resourceType) {
            case "Senior":
              tempActual = { hours: dataPoint.actualHours, disciplineId: dataPoint.disciplineId, projectId : dataPoint.projectId, workDate: new Date(dataPoint.workDate), identifier: seniorCount}
              tempPlanned = { hours: dataPoint.plannedHours, disciplineId: dataPoint.disciplineId, projectId : dataPoint.projectId, workDate: new Date(dataPoint.workDate), identifier: seniorCount}
              seniorActual.push(tempActual);
              seniorPlanned.push(tempPlanned);
              seniorCount++;
              break;
            case "Junior":
              tempActual = { hours: dataPoint.actualHours, disciplineId: dataPoint.disciplineId, projectId : dataPoint.projectId, workDate: new Date(dataPoint.workDate), identifier: juniorCount}
              tempPlanned = { hours: dataPoint.plannedHours, disciplineId: dataPoint.disciplineId, projectId : dataPoint.projectId, workDate: new Date(dataPoint.workDate), identifier: juniorCount}
              juniorActual.push(tempActual);
              juniorPlanned.push(tempPlanned);
              juniorCount++;
              break;
            case "Intermediate":
              tempActual = { hours: dataPoint.actualHours, disciplineId: dataPoint.disciplineId, projectId : dataPoint.projectId, workDate: new Date(dataPoint.workDate), identifier: intermediateCount}
              tempPlanned = { hours: dataPoint.plannedHours, disciplineId: dataPoint.disciplineId, projectId : dataPoint.projectId, workDate: new Date(dataPoint.workDate), identifier: intermediateCount}
              intermediateActual.push(tempActual);
              intermediatePlanned.push(tempPlanned);
              intermediateCount++;
              break;
            case "Lead":
              tempActual = { hours: dataPoint.actualHours, disciplineId: dataPoint.disciplineId, projectId : dataPoint.projectId, workDate: new Date(dataPoint.workDate), identifier: leadCount}
              tempPlanned = { hours: dataPoint.plannedHours, disciplineId: dataPoint.disciplineId, projectId : dataPoint.projectId, workDate: new Date(dataPoint.workDate), identifier: leadCount}
              leadActual.push(tempActual);
              leadPlanned.push(tempPlanned);
              leadCount++;
              break;
            
            default:
              break;
          }
        }
      }

      
      const processed = {
        seniorActual: convertToWeekly(seniorActual), 
        seniorPlanned: convertToWeekly(seniorPlanned), 
        juniorActual: convertToWeekly(juniorActual), 
        juniorPlanned: convertToWeekly(juniorPlanned), 
        intermediateActual: convertToWeekly(intermediateActual), 
        intermediatePlanned: convertToWeekly(intermediatePlanned), 
        leadActual: convertToWeekly(leadActual), 
        leadPlanned: convertToWeekly(leadPlanned)
      };
    
      //console.log("start date:", startDate, "\n", "end date:", endDate, "\n", "filtered:", filtered, "\n", "sorted:", sorted, "\n", "processed:", processed)
      return processed;
    }

    const disabledWeekendDates = (args) =>  {
      if (args.date.getDay() === 0 || args.date.getDay() === 6) {
          /*set 'true' to disable the weekends*/
          args.isDisabled = true;
      }
    }

    useEffect(() => {
      if (selectedFilterType !== ('custom' || 'full') && startDate) {
        setEndDate(addTimePeriodToDate(startDate));
      }
    }, [selectedFilterType]);

    useEffect(() => {
      /**
       * Acquire access token
       */
      if (accounts.length > 0) {
        const request = {
          scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
          account: accounts[0]
        };
        instance.acquireTokenSilent(request).then(response => {
          setAccessToken(response.accessToken);
        }).catch(error => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(request).then(response => {
              setAccessToken(response.accessToken);
            });
          }
        });
      }
    }, [accounts]);
  
    /**
     * Gets the resource usage for the discipline
     */
    useEffect(() => {
      if (accessToken) {
        dispatch(fetchDisciplineResourceUsage({ projectId: selectedProjectId, disciplineId: disciplineId, accessToken: accessToken }))
      }
    }, [dispatch, selectedProjectId, disciplineId, accessToken]);

    return (
      <>
      <div className="chart-container">
        <div className='popover-header'>
            <h4>{disciplineItem?.disciplineName} - Resource Overview</h4>
        </div>
        <div className='popover-content'>
          {isAllocationLoading &&
            <CircularProgressBar />
          }
          {!isAllocationLoading &&
          <div className='content-wrapper'>
            <ResourceAllocationChart closePopover={handleClose} resourceData={processData(resourceUsage)} disciplineItem={disciplineItem} resourceFilter={resourceFilter} />
            <div className='right-column'>
              <div className='column-content'>
                <div className='key-container'>
                  <h5>Key:</h5>
                  <div className='key-item'>
                    <div className='key solid'></div>
                    <h6>Actual Data</h6>
                  </div>
                  <div className='key-item'>
                    <div className='key dashed'></div>
                    <h6>Planned Data</h6>
                  </div>
                </div>
                <div className='date-filter hidden'>
                  <h5>Filter by Date range:</h5>
                  <DropDownListComponent id='dateFilterSelector' 
                    placeholder='Date range'
                    floatLabelType='Always'
                    change={handleFilterSelector}
                    dataSource={filterOptions}
                    fields={fields}
                    value={selectedFilterType}
                    popupWidth='200px'
                    itemTemplate={filterSelectorItemTemplate}
                    valueTemplate={filterSelectorValueTemplate}
                    delayUpdate={true}/>
                  
                  {displayDatePickers &&
                  <DatePickerComponent  id='startDate' 
                                        ref={startDatePicker}
                                        value={startDate}
                                        format={getGlobalFormat}
                                        change={startDateChange}
                                        cleared={clearStartDate}
                                        min={new Date(2010,1,1)}
                                        openOnFocus={true}
                                        placeholder='Start Date'
                                        floatLabelType='Auto'
                                        renderDayCell={disabledWeekendDates}></DatePickerComponent>
                  }
                  {displayDatePickers &&
                  <DatePickerComponent id='endDate' ref={endDatePicker} strictMode={true} value={endDate} format={getGlobalFormat()} change={endDateChange} cleared={clearEndDate} min={endDateMinimum} openOnFocus={true} placeholder='End Date' floatLabelType='Auto' readonly={!allowEndDateChange} enabled={allowEndDateChange} ></DatePickerComponent>
                  }
                </div>
                <div className='series-filter'>
                  {resourceFilter === 'All' &&
                    <PrimaryLighthouseButton onClick={handleSeriesFiltering} value='All'>All</PrimaryLighthouseButton>
                  }
                  {resourceFilter !== 'All' &&
                    <SecondaryLighthouseButton onClick={handleSeriesFiltering} value='All'>All</SecondaryLighthouseButton>
                  }
                  {resourceFilter === 'Junior' &&
                    <PrimaryLighthouseButton onClick={handleSeriesFiltering} value='Junior'><div className='key junior'></div>Junior</PrimaryLighthouseButton>
                  }
                  {resourceFilter !== 'Junior' &&
                    <SecondaryLighthouseButton onClick={handleSeriesFiltering} value='Junior'><div className='key junior'></div>Junior</SecondaryLighthouseButton>
                  }
                  {resourceFilter === 'Intermediate' &&
                    <PrimaryLighthouseButton onClick={handleSeriesFiltering} value='Intermediate'><div className='key intermediate'></div>Intermediate</PrimaryLighthouseButton>
                  }
                  {resourceFilter !== 'Intermediate' &&
                    <SecondaryLighthouseButton onClick={handleSeriesFiltering} value='Intermediate'><div className='key intermediate'></div>Intermediate</SecondaryLighthouseButton>
                  }
                  {resourceFilter === 'Senior' &&
                    <PrimaryLighthouseButton onClick={handleSeriesFiltering} value='Senior'><div className='key senior'></div>Senior</PrimaryLighthouseButton>
                  }
                  {resourceFilter !== 'Senior' &&
                    <SecondaryLighthouseButton onClick={handleSeriesFiltering} value='Senior'><div className='key senior'></div>Senior</SecondaryLighthouseButton>
                  }
                  {resourceFilter === 'Lead' &&
                    <PrimaryLighthouseButton onClick={handleSeriesFiltering} value='Lead'><div className='key lead'></div>Lead</PrimaryLighthouseButton>
                  }
                  {resourceFilter !== 'Lead' &&
                    <SecondaryLighthouseButton onClick={handleSeriesFiltering} value='Lead'><div className='key lead'></div>Lead</SecondaryLighthouseButton>
                  }
                </div>
              </div>
              <PrimaryLighthouseButton onClick={handleClose}>Close Window</PrimaryLighthouseButton>
            </div>
          </div>
          }
        </div>
    </div>

    </> 
    );
};

export default ResourceAllocationChartContainer;