import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { interfacePointsEndpoint, interfacePointTypesEndpoint, createInterfacePointEndPoint, updateInterfacePointEndPoint, updateInterfacePointStatusEndPoint, interfaceActivityLinksEndPoint, interfaceWarningsEndpoint } from '../../api/apiRoutes';

/**
* Fetches interface point types for a project
*/
export const fetchInterfacePointTypes = createAsyncThunk(
  'project/interfacepointtypes', async (params, thunkAPI) => {
    try {
      const response = await api.get(interfacePointTypesEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Fetches interface points for a project
 */
export const fetchInterfacePoints = createAsyncThunk(
  'project/interfacepoints', async (params, thunkAPI) => {
    try {
      const response = await api.get(interfacePointsEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    }
    catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Updates interface point status
 */
export const updateInterfacePointStatus = createAsyncThunk(
  'project/updateInterfacePointStatus', async (params, thunkAPI) => {
    try{
      const response = await api.post(updateInterfacePointStatusEndPoint(params.projectId, params.interfacePointId), params.status, { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Create an interface point
 */
export const createInterfacePoint = createAsyncThunk(
  'project/createinterfacepoint', async (params, thunkAPI) => {
    try {
      const response = await api.post(createInterfacePointEndPoint(params.projectId), params.interfacePointObject, { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

/**
 * updates an interface point
 */
 export const updateInterfacePoint = createAsyncThunk(
  'project/updateinterfacepoint', async (params, thunkAPI) => {
    try {
      const response = await api.put(updateInterfacePointEndPoint(params.projectId, params.interfacePointId), params.interfacePointObject, { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

/**
 * fetches interface activity links
 */
 export const fetchInterfaceActivityLinks = createAsyncThunk(
  'project/fetchinterfaceactivitylinks', async (params, thunkAPI) => {
    try {
      const response = await api.get(interfaceActivityLinksEndPoint(params.projectId, params.interfacePointId), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

/**
 * fetches interface warnings
 */
 export const fetchInterfaceWarnings = createAsyncThunk(
  'project/fetchinterfacewarnings', async (params, thunkAPI) => {
    try {
      const response = await api.get(interfaceWarningsEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);



/**
 * Interface points adapter
 */
const interfacePointsAdapter = createEntityAdapter({
  selectId: (interfacePoint) => interfacePoint.id,
})

/**
 * Create interface points slice / reducers
 */
const interfacePointsSlice = createSlice({
  name: 'interfacePoints',
  initialState: interfacePointsAdapter.getInitialState({
    selectedInterfacePointId: undefined,
    isInterfacePointsLoading: false,
    interfacePointsError: undefined,
    interfacePointTypes: [],
    isInterfacePointTypesLoading: false,
    interfacePointTypesError: undefined,
    interfaceActivityLinks: [],
    isInterfaceActivityLinksLoading: false,
    interfaceActivityLinksError: undefined,
    interfacePointForecastDateUpdate:{selectedInterfacePointId : undefined},
    interfaceWarnings: [],
    isInterfaceWarningsLoading: false,
    interfaceWarningsError: undefined,
    modifiedInterfaceId: undefined,
    markedAsCompleteInterfaceId: undefined,
    markedAsInCompleteInterfaceId: undefined,
  }),
  reducers: {
    setSelectedInterfacePointId: (state, action) => {
      state.selectedInterfacePointId = action.payload;
    },
    removeModifiedInterfaceId: (state, action) => {
      state.modifiedInterfaceId = undefined;
    },
    removeInterfaceActivityLinks: (state, action) => {
      state.interfaceActivityLinks = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInterfacePoints.pending, (state, action) => {
        interfacePointsAdapter.removeAll(state);
        state.isInterfacePointsLoading = true;
        state.interfacePointsError = undefined;
      })
      .addCase(fetchInterfacePoints.fulfilled, (state, action) => {
        interfacePointsAdapter.setAll(state, action.payload);
        state.selectedInterfacePointId = undefined;
        state.isInterfacePointsLoading = false;
        state.interfacePointsError = undefined;
      })
      .addCase(fetchInterfacePoints.rejected, (state, action) => {
        state.isInterfacePointsLoading = false;
        if (action.payload) {
          state.interfacePointsError = action.payload.error;
        }
        else {
          state.interfacePointsError = action.error.message;
        }
      })

      .addCase(createInterfacePoint.pending, (state, action) => {
        state.selectedInterfacePointId = undefined;
        state.isInterfacePointsLoading = true;
        state.interfacePointsError = undefined;
      })
      .addCase(createInterfacePoint.fulfilled, (state, action) => {
        state.selectedInterfacePointId = action.payload.id;
        state.modifiedInterfaceId = action.payload.id;
        state.isInterfacePointsLoading = false;
        state.interfacePointsError = undefined;
        interfacePointsAdapter.addOne(state, action.payload);
      })
      .addCase(createInterfacePoint.rejected, (state, action) => {
        state.isInterfacePointsLoading = false;
        state.interfacePointsError = action.error.message;
      })

      .addCase(updateInterfacePoint.pending, (state, action) => {
        state.selectedInterfacePointId = undefined;
        state.isInterfacePointsLoading = true;
        state.interfacePointsError = undefined;
      })
      .addCase(updateInterfacePoint.fulfilled, (state, action) => {
        state.selectedInterfacePointId = action.payload.id;
        state.modifiedInterfaceId = action.payload.id;
        state.isInterfacePointsLoading = false;
        state.interfacePointsError = undefined;
        state.interfacePointForecastDateUpdate.selectedInterfacePointId = state.selectedInterfacePointId;
        interfacePointsAdapter.upsertOne(state, action.payload);
      })
      .addCase(updateInterfacePoint.rejected, (state, action) => {
        state.isInterfacePointsLoading = false;
        state.interfacePointsError = action.error.message;
      })

      .addCase(updateInterfacePointStatus.pending, (state, action) => {
        state.selectedInterfacePointId = undefined;
        state.isInterfacePointsLoading = true;
        state.interfacePointsError = undefined;
      })
      .addCase(updateInterfacePointStatus.fulfilled, (state, action) => {
        state.selectedInterfacePointId = action.payload.id;
        state.modifiedInterfaceId = action.payload.id;
        
        if(action.payload.status == 'Completed'){
          state.markedAsCompleteInterfaceId = action.payload.id;
          state.markedAsInCompleteInterfaceId = undefined;
        }
        else if(action.payload.status == 'Active'){
          state.markedAsCompleteInterfaceId = undefined;
          state.markedAsInCompleteInterfaceId = action.payload.id;
        }
        else{
          state.markedAsCompleteInterfaceId = undefined;
          state.markedAsInCompleteInterfaceId = undefined;
        }
        
        state.isInterfacePointsLoading = false;
        state.interfacePointsError = undefined;
        interfacePointsAdapter.upsertOne(state, action.payload);
      })
      .addCase(updateInterfacePointStatus.rejected, (state, action) => {
        state.isInterfacePointsLoading = false;
        state.interfacePointsError = action.error.message;
      })

      .addCase(fetchInterfacePointTypes.pending, (state, action) => {
        state.interfacePointTypes = [];
        state.isInterfacePointTypesLoading = true;
        state.interfacePointTypesError = undefined;
      })
      .addCase(fetchInterfacePointTypes.fulfilled, (state, action) => {
        state.interfacePointTypes = action.payload;
        state.isInterfacePointTypesLoading = false;
        state.interfacePointTypesError = undefined;
      })
      .addCase(fetchInterfacePointTypes.rejected, (state, action) => {
        state.isInterfacePointTypesLoading = false;
        if (action.payload) {
          state.interfacePointTypesError = action.payload.error;
        }
        else {
          state.interfacePointTypesError = action.error.message;
        }
      })

      .addCase(fetchInterfaceActivityLinks.pending, (state, action) => {
        state.interfaceActivityLinks = [];
        state.isInterfaceActivityLinksLoading = true;
        state.interfaceActivityLinksError = undefined;
      })
      .addCase(fetchInterfaceActivityLinks.fulfilled, (state, action) => {
        state.interfaceActivityLinks = action.payload;
        state.isInterfaceActivityLinksLoading = false;
        state.interfaceActivityLinksError = undefined;
      })
      .addCase(fetchInterfaceActivityLinks.rejected, (state, action) => {
        state.isInterfaceActivityLinksLoading = false;
        if (action.payload) {
          state.interfaceActivityLinksError = action.payload.error;
        }
        else {
          state.interfaceActivityLinksError = action.error.message;
        }
      })
      .addCase(fetchInterfaceWarnings.pending, (state, action) => {
        state.interfaceWarnings = [];
        state.isInterfaceWarningsLoading = true;
        state.interfaceWarningsError = undefined;
      })
      .addCase(fetchInterfaceWarnings.fulfilled, (state, action) => {
        state.interfaceWarnings = action.payload;
        state.isInterfaceWarningsLoading = false;
        state.interfaceWarningsError = undefined;
      })
      .addCase(fetchInterfaceWarnings.rejected, (state, action) => {
        state.isInterfaceWarningsLoading = false;
        if (action.payload) {
          state.interfaceWarningsError = action.payload.error;
        }
        else {
          state.interfaceWarningsError = action.error.message;
        }
      })
  }
});

/**
* Interface points selectors
*/
export const {
  selectAll: selectAllInterfacePoints,
  selectById: selectInterfacePointById
} = interfacePointsAdapter.getSelectors((state) => state.interfacePoints);

export const selectAllSortedInterfacePoints = (state) =>{
  const interfacePoints = state.interfacePoints;
  const sortedInterfacePoints = interfacePointsAdapter.getSelectors((state) => interfacePoints).selectAll()
                                .sort((a,b)=>
                                  (a.status.localeCompare(b.status)) || 
                                  ((new Date() - new Date(b.forecastDate ? b.forecastDate : b.interfacePointDate)) - (new Date() - new Date(a.forecastDate ? a.forecastDate : a.interfacePointDate))) || 
                                  (a.interfacePointNumber - b.interfacePointNumber)
                                )
  return sortedInterfacePoints;
}

/**
 * Interface points actions
 */
export const { setSelectedInterfacePointId,removeInterfaceActivityLinks, removeModifiedInterfaceId
} = interfacePointsSlice.actions;

/**
 * Export reducer
 */
export default interfacePointsSlice.reducer;