import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DisciplineProgress from '../../molecules/DisciplineProgress';
import { ListBoxComponent, Inject, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';
import { setFilteredDisciplineIds } from '../../../../../redux/slices/disciplinesSlice';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './DisciplineSelector.scss';

const DisciplineSelector = (props) => {
  const { disciplineData } = props;
  const { filteredDisciplineIds } = useSelector(state => state.disciplines);
  const [allChecked, setAllChecked] = useState(false);
  const listboxComponentRef = useRef();
  const allCheckboxComponentRef = useRef();
  const dispatch = useDispatch();

  const fields = { text: 'disciplineId', value: 'disciplineId' };
  const selectionSettings = { showCheckbox: true, mode: 'Multiple' };

  const [filteredDisciplinesArray, setFilteredDisciplinesArray] = useState([]);

  /**
   * Item template
   * @param {object} args 
   * @returns 
   */
  const itemTemplate = (args) => {
    return (
      <DisciplineProgress discipline={args} setSelectedDiscipline={false} />
    );
  }

  /**
   * Select disciplines already in state
   * @param {object} args 
   */
  const handleCreated = (args) => {
    // set the initial selection
    listboxComponentRef.current?.selectItems(filteredDisciplineIds);
    setAllChecked(filteredDisciplineIds?.length === disciplineData.length);
  }

  /**
   * Update the selected disciplines
   * @param {object} args 
   */
  const handleChange = (args) => {
    // build an array of selected discipline ids
    let disciplineIds = [];
    for (let discipline of args.items) {
      disciplineIds.push(discipline.disciplineId);
    }

    // update the all checkbox state
    setAllChecked(disciplineIds.length === disciplineData.length);

    // set the discipline ids
    setFilteredDisciplinesArray(disciplineIds);
  }

  /**
   * Clear selected disciplines
   */
  const handleClearFilterClick = () => {
    // clear the checkbox selection
    listboxComponentRef.current?.selectItems(listboxComponentRef.current?.value, false);

    setAllChecked(false);

    // clear the state for selected discipline ids
    dispatch(setFilteredDisciplineIds([]));

    // close the filter
    props.closeFilter();
  }

  /**
   * Applies the filter by updating the discipline ids in state
   */
  const handleApplyFilterClick = () => {
    // set the state for selected discipline ids
    dispatch(setFilteredDisciplineIds(filteredDisciplinesArray));

    // close the filter
    props.closeFilter();
  }

  /**
   * Handles the select all checkbox
   * @param {object} args 
   */
  const handleSelectAllChange = (event) => {
    setAllChecked(event.target.checked);
    listboxComponentRef.current?.selectAll(event.target.checked);
  }

  return (
    <div className="ds-container">
      <div className="ds-header">
        <b>Select Disciplines:</b>
        <FormControlLabel
          value="All"
          control={<Checkbox checked={allChecked} onChange={handleSelectAllChange} inputProps={{ 'aria-label': 'primary checkbox' }} />}
          label="All"
          labelPlacement="start"
        />
      </div>
      <div className="ds-list">
        <ListBoxComponent ref={listboxComponentRef}
          dataSource={disciplineData}
          selectionSettings={selectionSettings}
          fields={fields}
          itemTemplate={itemTemplate}
          change={handleChange}
          created={handleCreated}
        >
          <Inject services={[CheckBoxSelection]} />
        </ListBoxComponent>
      </div>
      <div className="ds-actions">
        <a onClick={handleApplyFilterClick} style={{ cursor: 'pointer' }}>Apply</a>
        <a onClick={handleClearFilterClick} style={{ cursor: 'pointer' }}>Clear</a>
      </div>
    </div>
  );
}

export default DisciplineSelector;