import React from 'react';
import { getSmoothStepPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer';
import './LagFloatEdge.scss';

const LagFloatEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
}) => {
  const edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, borderRadius: 0 });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({ sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition });
  const markerEndId = "lagfloat-arrowhead-" + id;
  const markerEndUrl = "url(#" + markerEndId + ")";
  const foreignObjectWidth = 142;
  const foreignObjectheight = 82;
  let markerColour = style.stroke;
  let edgeStyle = style;

  edgeStyle = { stroke: '#183F64', strokeWidth: '2' };
  markerColour = "#183F64";

  return (
    <>
      <marker id={markerEndId} markerWidth="7" markerHeight="7" viewBox="-10 -10 20 20" orient="auto" refX="0" refY="0">
        <polyline className="lf-ah" stroke={markerColour} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" fill="none" points="-5,-4 0,0 -5,4"></polyline>
      </marker>
      <path
        id={id}
        style={edgeStyle}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEndUrl}
      />
      <foreignObject
        width={foreignObjectWidth}
        height={foreignObjectheight}
        x={edgeCenterX - foreignObjectWidth / 2}
        y={edgeCenterY - foreignObjectheight / 2}>
        <div className="lag-float-container">
          <span>Lag days:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {data.lag}</span>
          <span>Float days:&nbsp;&nbsp;&nbsp; {data.float}</span>
          <span>relationship:&nbsp; {data.relationship}</span>
        </div>
      </foreignObject>
    </>
  )
};

export default React.memo(LagFloatEdge);