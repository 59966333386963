import React, { useEffect, useState } from 'react';
import DisciplineSelector from '../DisciplineSelector';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { fetchDisciplines, selectAllDisciplines, selectDisciplineById } from '../../../../../redux/slices/disciplinesSlice';

const DisciplineSelectorContainer = (props) => {
  const dispatch = useDispatch();
  const { selectedDisciplineId, isLoading, error } = useSelector(state => state.disciplines);
  const selectedDiscipline = useSelector((state) => selectDisciplineById(state, selectedDisciplineId));
  const disciplines = useSelector(selectAllDisciplines).filter((d) => d.disciplineName !== "External Milestones");
  const { selectedProjectId } = useSelector(state => state.projects);
  
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  useEffect(() => {
    // fetch disciplines
    if (accessToken){
      dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken}));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  return (
    <>
      { isLoading && 
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to display discipline selector" />
      }
      { !isLoading && !error &&
        <DisciplineSelector disciplineData={disciplines} selectedDiscipline={selectedDiscipline} closeFilter={props.closeFilter} />
      }
    </>
  );
}

export default DisciplineSelectorContainer;