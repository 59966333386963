import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Edit, NumericEditCell } from '@syncfusion/ej2-react-grids';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { setEditedResources } from '../../../../../redux/slices/createScenarioSlice';
import './ResourceChange.scss';

const ResourceChange = (props) => {
  const { weeklyResources, existingWeeklyResources, selectedWeekStartDate, discipline } = props;
  const [resourceLines, setResourceLines] = useState([]);
  const [editedWeeklyResources, setEditedWeeklyResources] = useState(existingWeeklyResources.slice());
  const dispatch = useDispatch();

  const grdResourceEditor = React.createRef();

  useEffect(() => {
    // initialise current resources
    let currentResources = [];

    if (weeklyResources) {
      weeklyResources.map((weeklyResource) => {

        let currentValue = weeklyResource.weekFTECapacity;
        let newValue = weeklyResource.weekFTECapacity;

        // check if any resources have been set
        if (existingWeeklyResources) {
          // check for resource type match
          let existingWeeklyResource = editedWeeklyResources.find(editedWeeklyResource => editedWeeklyResource.resourceType === weeklyResource.resourceType
            && editedWeeklyResource.weekStartDate === weeklyResource.weekStartDate
            && editedWeeklyResource.disciplineId === discipline.disciplineId);

          // if we have a matched value then overwrite with new value
          if (existingWeeklyResource) {
            newValue = existingWeeklyResource.weekFTECapacity;
          }
        }

        // set the resource line
        let resourceLine = {
          resourceType: weeklyResource.resourceType,
          currentValue: currentValue,
          newValue: newValue,
        }

        // and add to the current resources
        currentResources.push(resourceLine);
      });

      // set the existing resource to the current resource array
      setResourceLines(currentResources);
    }

  }, [weeklyResources]);

  /**
   * Resource lines changed
   */
  useEffect(() => {
    if (resourceLines && grdResourceEditor) {
      grdResourceEditor.current.dataSource = resourceLines;
      grdResourceEditor.current.refresh();
    }
  }, [resourceLines]);

  /**
   * Update the edited resources in state
   */
  useEffect(() => {
    dispatch(setEditedResources(editedWeeklyResources));
  }, [editedWeeklyResources]);


  /**
   * Edit action complete
   * @param {object} args 
   */
  const actionComplete = (args) => {
    if (args.requestType === 'save') {
      let newResourceData = args.data;

      let existingWeeklyResource = editedWeeklyResources?.find(weeklyResource => weeklyResource.resourceType === newResourceData.resourceType
        && weeklyResource.weekStartDate === selectedWeekStartDate
        && weeklyResource.disciplineId === discipline.disciplineId);

      //console.log("existing weekly resource found:", existingWeeklyResources, editedWeeklyResources, newResourceData, selectedWeekStartDate);

      if (existingWeeklyResource) {
        // check if the new value is the same as the original value
        const origianlResourceLine = weeklyResources.find(resourceLine => resourceLine.weekStartDate === existingWeeklyResource.weekStartDate
          && resourceLine.resourceType === existingWeeklyResource.resourceType
          && resourceLine.disciplineId === existingWeeklyResource.disciplineId);


        if (origianlResourceLine.weekFTECapacity === newResourceData.newValue) {
          // new value is same as original value so remove entry by filtering out
          const filteredEditedWeeklyResources = editedWeeklyResources.filter(weeklyResource => {
            return weeklyResource.weekStartDate !== existingWeeklyResource.weekStartDate
              || weeklyResource.resourceType !== existingWeeklyResource.resourceType
              || weeklyResource.disciplineId !== existingWeeklyResource.disciplineId;
          });

          // update state
          setEditedWeeklyResources(filteredEditedWeeklyResources);
        }
        else {
          // update existing resource
          let updatedResources = editedWeeklyResources.map(weeklyResource => {
            var returnValue = { ...weeklyResource };

            if (weeklyResource.resourceType === newResourceData.resourceType
              && weeklyResource.weekStartDate === selectedWeekStartDate
              && weeklyResource.disciplineId === discipline.disciplineId) {
              returnValue.weekFTECapacity = newResourceData.newValue;
            }

            return returnValue
          })
          // update state
          setEditedWeeklyResources(updatedResources);
        }
      }
      else {
        // add resource if the value is changed
        if (args.data.currentValue !== args.data.newValue){
          let newWeeklyResource = {
            resourceType: newResourceData.resourceType,
            weekStartDate: selectedWeekStartDate,
            weekFTECapacity: newResourceData.newValue,
            disciplineId: discipline.disciplineId,
            disciplineName: discipline.disciplineName,
            originalWeekFTECapcity: newResourceData.currentValue
          }
          // update state
          setEditedWeeklyResources([...editedWeeklyResources, newWeeklyResource]);
        }
      }
    }
  }

  // edit settings
  const editSettings = { allowEditing: true, allowAdding: false, allowDeleting: false };
  const numericEditParams = { params: { decimals: 2, format: "N" } }

  // validation rules
  const resourceValueValidationRules = { required: true, number: true, min: 0, max: 1000 }

  return (
      <div className="rc-grid">
        <GridComponent id='grdResourceEditor'
          ref={grdResourceEditor}
          dataSource={resourceLines}
          editSettings={editSettings}
          actionComplete={actionComplete}
          allowTextWrap={true}
          height={200}
          gridLines='None'
          style={{ border: 'none' }}
        >
          <ColumnsDirective>
            <ColumnDirective columns={[{
              field: 'resourceType',
              headerText: 'Resource Type',
              textAlign: 'Left',
              allowEditing: false
            },
            {
              field: 'currentValue',
              headerText: 'Original Value (FTE)',
              textAlign: 'Center',
              allowEditing: false,
              format: 'N2',
              type: 'number'
            },
            {
              field: 'newValue',
              headerText: 'New Value (FTE)',
              textAlign: 'Center',
              format: 'N2',
              type: 'number',
              editType: 'numericedit',
              edit: numericEditParams,
              validationRules: resourceValueValidationRules
            }]}
            >
            </ColumnDirective>
          </ColumnsDirective>
          <Inject services={[Edit]} />
        </GridComponent>
      </div>
  );
}

export default ResourceChange;