import React from 'react';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Toolbar, Filter } from '@syncfusion/ej2-react-treegrid';
import { getFormattedDate } from '../../../../../utils';
import TimeImpact from '../../../../../components/Shared/atoms/TimeImpact';
import { RelationTreeIcon, ProductionIcon, GlobIcon } from '../../../../../Icons';
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';
import { L10n } from '@syncfusion/ej2-base';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import './ActivityOptimisationChange.scss';

L10n.load({
    'ac-opt': {
        'treegrid': {
            'EmptyRecord': '',
        }
    }
  });

const ActivityOptimisationChange = React.memo(props => {
    const { activityOptimisationChanges } = props;
    const grdActivityTree = React.createRef();

    const nameHeaderTemp = () => {
        return (<div className='text-name-align' >Name</div>);
    };

    const treeRowTemplate = (props) => {
        return (
            <tr className='activity-driver-row'>
                <td className='text-left-align'>
                    <div>{props.type}</div>
                </td>
                <td>
                    <div className='dis-name-container'>
                        <TooltipComponent title={props.name} className="tooltip-container"></TooltipComponent>
                        {props.type === 'Discipline' && <div className='icon-style'> <DisciplineIcon disciplineId={props.disciplineId} /> </div>}
                        {props.type === 'Package' && <RelationTreeIcon className='icon-style' />}
                        {props.type === 'Phase' && <GlobIcon className='icon-style' />}
                        {props.type === 'Activity' && <ProductionIcon className='icon-style' />}
                        {props.name?.length > 50 && props.name?.substring(0, 49) + "..."}
                        {props.name?.length <= 50 && props.name}
                    </div>
                </td>
                <td className='text-left-align'>
                    <div>{getFormattedDate(props.plannedStartDate)}</div>
                </td>
                <td className='text-left-align'>
                    <div>{getFormattedDate(props.plannedEndDate)}</div>
                </td>
                <td className='text-left-align'>
                    <div>{getFormattedDate(props.optionStart)}</div>
                </td>
                <td className='text-left-align'>
                    <div>{getFormattedDate(props.optionEnd)}</div>
                </td>
                <td className='text-left-align'>
                    <div>{(props.changeToStartDate || props.changeToStartDate == 0) && <TimeImpact timeImpactDelayDays={props.changeToStartDate} />}</div>
                </td>
                <td className='text-left-align'>
                    <div>{(props.changeToDuration || props.changeToDuration == 0) && <TimeImpact timeImpactDelayDays={props.changeToDuration} />}</div>
                </td>
            </tr>
        );
    }

    // toolbar options
    const toolbarOptions = ['Search'];

    return (
        <div className="activity-optimisation-change-container">
            <TreeGridComponent
                id='grdActivityTree'
                ref={grdActivityTree}
                rowTemplate={treeRowTemplate}
                enableCollapseAll={true}
                dataSource={activityOptimisationChanges}
                childMapping="subtasks"
                treeColumnIndex={1}
                toolbar={toolbarOptions}
                allowTextWrap={true}
                height="500"
                locale='ac-opt'
            >
                <Inject services={[Filter, Toolbar]} />
                <ColumnsDirective>
                    <ColumnDirective headerText='Type' field='type' textAlign='left' ></ColumnDirective>
                    <ColumnDirective field='name' headerTemplate={nameHeaderTemp} width='435' textAlign='left'></ColumnDirective>
                    <ColumnDirective headerText='Planned Start' field='plannedStartDate' textAlign='left' ></ColumnDirective>
                    <ColumnDirective headerText='Planned End' field='plannedEndDate' textAlign='left'></ColumnDirective>
                    <ColumnDirective headerText='Optimised Start' field='optionStart' textAlign='left' ></ColumnDirective>
                    <ColumnDirective headerText='Optimised End' field='optionEnd' textAlign='left'></ColumnDirective>
                    <ColumnDirective headerText='Change To Start' field='changeToStartDate' textAlign='left' ></ColumnDirective>
                    <ColumnDirective headerText='Change To Duration' field='changeToDuration' textAlign='left' ></ColumnDirective>
                </ColumnsDirective>
            </TreeGridComponent>
        </div>
    );
})

const areEqual = (prevProps, nextProps) => {
    if (JSON.stringify(prevProps) == JSON.stringify(nextProps)) {
      return true;
    }
    else {
      return false;
    }
  }

export default React.memo(ActivityOptimisationChange, areEqual);
