import React from 'react';
import { useSelector } from 'react-redux';
import TimeImpact from '../../../../../components/Shared/atoms/TimeImpact';
import CostImpact from '../../../../../components/Shared/atoms/CostImpact';
import RiskCategory from '../../../../../components/Shared/atoms/RiskCategory';
import { DENOMINATION } from '../../../../../../src/constants';
import './BaseScenarioOption.scss';

const BaseScenarioOption = (props) => {
  const { alerts, scenario, option, scenarioOption } = props;
  const { sidebarOpen } = useSelector(state => state.projects);
  function createDescriptionMarkup() {
    return { __html: option.description };
  }
  const relatedAlerts = alerts?.slice().filter((alert) => alert.alertType !== 'User-generated');
  const mainAlert = alerts?.slice().filter((alert) => alert.alertId === scenario?.alertId)[0];

  return (
    <div className="base-scenario-option-container">
      <div className="option-sub-container">
        <div className="option-title-heatmap">Overall Impact</div>
        <div className="option-sub-container-card">
          <table className="option-table">
            <tbody>
              <tr>
                <th>{scenarioOption === 'scenariooption' && 'IFC Completion:'}</th>
                <td>{scenarioOption === 'scenariooption' && <TimeImpact timeImpactDelayDays={option.ifcCompletionDelayDays} />}</td>
              </tr>
              <tr>
                <th>{scenarioOption === 'scenariooption' && 'Design Cost Impact:'}</th>
                <td>{scenarioOption === 'scenariooption' && <CostImpact costImpact={option.costImpactDesign} denomination={DENOMINATION.auto} />}</td>
              </tr>
              <tr>
                <th>{scenarioOption === 'scenariooption' && 'Construction Cost Impact:'}</th>
                <td>{scenarioOption === 'scenariooption' && <CostImpact costImpact={option.costImpactConstruction} denomination={DENOMINATION.auto} />}</td>
              </tr>
              <tr>
                <th>{scenarioOption === 'scenariooption' && 'Other Cost Impact:'}</th>
                <td>{scenarioOption === 'scenariooption' && <CostImpact costImpact={option.costImpactClient} denomination={DENOMINATION.auto} />}</td>
              </tr>
              <tr>
                <th>{scenarioOption === 'scenariooption' && 'Risk Level:'}</th>
                <td>{scenarioOption === 'scenariooption' && <RiskCategory riskCategory={option.riskCategory} />}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
  );
}

export default BaseScenarioOption;