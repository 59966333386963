import React, {useState} from 'react';
import { getFormattedDate } from '../../../../../utils';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Toolbar, Filter } from '@syncfusion/ej2-react-treegrid';
import './InterfaceFullProgramme.scss';


const InterfaceFullProgramme = (props) => {
    const { fullActivitySchedule } = props;
    const grdFullActivityTree = React.createRef();

    let selectedActivity = [];

    /**
     * Handle the cancel button click
     */
    const handleCancelClick = () => {
      props.closeFullProgramme();
    }
  
    /**
     * Cancel selecting Full Activity Link
     */
    const cancelFullActivityLink = () => {
      props.closeFullProgramme();
    }
  
    /**
     * selects a full activity to create the Interface
     */
    const selectActivityLink = () => {
      if(selectedActivity.length > 0){
        props.selectActivityLink(selectedActivity);
      }
    }

    const treeRowTemplate = (fullActivitySchedule) => {
      return (
        <>
          {fullActivitySchedule && 
            <tr className='fal-driver-row'>
              <td className='text-left-align'> 
                <div>
                  {fullActivitySchedule.activityType && fullActivitySchedule.activityType.startsWith('TT_') && fullActivitySchedule.activityName}
                  {fullActivitySchedule.wbsName}
                </div>
              </td>
              <td className='text-left-align'> 
                <div>
                  {fullActivitySchedule.activityType && fullActivitySchedule.activityType.startsWith('TT_') && fullActivitySchedule.activityCode}
                </div>
              </td>
              <td className='text-left-align'> 
                <div>
                  {fullActivitySchedule.activityType && fullActivitySchedule.activityType.startsWith('TT_') && getFormattedDate(fullActivitySchedule.plannedStartDate)}
                </div>
              </td>
              <td className='text-left-align'> 
                <div>
                  {fullActivitySchedule.activityType && fullActivitySchedule.activityType.startsWith('TT_') && getFormattedDate(fullActivitySchedule.plannedEndDate)}
                </div>
              </td>
              <td className='text-center-align'>
                {fullActivitySchedule.activityType && fullActivitySchedule.activityType.startsWith('TT_') && 
                  <RadioButtonComponent label="" name="activitySelected" value={
                    {
                      activityId: fullActivitySchedule.activityId, 
                      activityName: fullActivitySchedule.activityName,
                      activityCode: fullActivitySchedule.activityCode,
                      actualStartDate: fullActivitySchedule.actualStartDate,
                      actualEndDate: fullActivitySchedule.actualEndDate,
                      plannedStartDate: fullActivitySchedule.plannedStartDate,
                      plannedEndDate: fullActivitySchedule.plannedEndDate,
                      isLighthouseActivity: fullActivitySchedule.isLighthouseActivity
                    }} 
                   labelPosition="After" onChange={rowSelected}/>
                }
              </td>
            </tr>
          }
        </>
      );
    }

    /**
  * Selects the activity when radio box is selected
  */
  const rowSelected =(event) => {
    event.syntheticEvent.stopPropagation();
    selectedActivity = [];
    selectedActivity.push(event.value);
  }
    //Commenting this out for now as the Search feature is not working at the moment
    //To be reconsidered in the newer version
    //const toolbarOptions = ['Search'];
    
    return (
      <>
        <div className="full-activity-link-container">
          <div className="fal-container">
            <label className="fal-label">Select Activity with which to create the interface.</label> 
            <div className="fal-change-container">
              <div className='treeGrid-container'>
                <TreeGridComponent
                  id='grdActivityTree'
                  ref={grdFullActivityTree}
                  className='treeGrid-class'
                  rowTemplate={treeRowTemplate}
                  enableCollapseAll={true}
                  dataSource={fullActivitySchedule}
                  childMapping="subTasks"
                  treeColumnIndex={0}
                  //toolbar={toolbarOptions}
                  allowTextWrap={true}
                  allowSelection={true}
                  height="500" 
                  width="100%"
                >
                  <Inject services={[Filter, Toolbar]}/>
                  <ColumnsDirective>
                    <ColumnDirective field='name' width='500' headerText='Name:' textAlign='left'></ColumnDirective>
                    <ColumnDirective field='code' width='200' headerText='Code:' textAlign='left'></ColumnDirective>
                    <ColumnDirective field='startDate' width='140' headerText='Start Date:' textAlign='left'></ColumnDirective>
                    <ColumnDirective field='endDate' width='140' headerText='End Date:' textAlign='left'></ColumnDirective>
                    <ColumnDirective field='select' headerText='Select:' textAlign='right'></ColumnDirective>
                  </ColumnsDirective>
                </TreeGridComponent>
              </div>
            </div>
          </div>
          <div className="add-link-button">
            <PrimaryLighthouseButton className="mr-15" onClick={selectActivityLink}>Select Link</PrimaryLighthouseButton>
            <SecondaryLighthouseButton onClick={cancelFullActivityLink}>Cancel</SecondaryLighthouseButton>
          </div>
        </div>
      </>
    );
  }

export default InterfaceFullProgramme;