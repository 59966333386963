import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { api } from '../../api';
import {  setScenarioStatusForAlertEndpoint, 
          disciplineResourceProfileEndpoint,
          analyseUserGeneratedScenarioEndPoint
       } from '../../api/apiRoutes';

/**
 * Sets the scenario status for an alert
 */
export const setScenarioStatusForAlert = createAsyncThunk(
  'project/setscenariostatusforalert', async (params, thunkAPI) => {
    try {
      const response = await api.put(setScenarioStatusForAlertEndpoint(params.projectId, params.alertId), params.status, { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Gets resource profile for a project discipline
 */
export const fetchDisciplineResourceProfile = createAsyncThunk(
  'phase/fetchresourceprofile', async (params, thunkAPI) => {
    try {
      const response = await api.get(disciplineResourceProfileEndpoint(params.projectId, params.disciplineId), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
});

export const analyseUserGeneratedScenario = createAsyncThunk(
  'project/analyseusergeneratedscenario', async (params, thunkAPI) => {
    try {
      const response = await api.post(analyseUserGeneratedScenarioEndPoint(params.projectId, params.scenarioId), params.scenarioObject, { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

/**
 * Create activity schedule adapter
 */
 const createScenarioAdapter = createEntityAdapter({
  selectId: (createScenario) => createScenario.activityId,
})

/**
 * Create activity scheduke slice / reducers
 */
const createScenarioSlice = createSlice({
  name: 'createScenario',
  initialState: createScenarioAdapter.getInitialState( {  isLoading: false,
                                                                  error: undefined,
                                                                  activityId:undefined,
                                                                  lastCreatedScenarioId: undefined,
                                                                  name: undefined,
                                                                  description: undefined,
                                                                  resourceProfile: undefined,
                                                                  selectedDisciplineId: undefined,
                                                                  selectedWeekStartDate: undefined,
                                                                  editedResources: undefined,
                                                                  userEmail: undefined,
                                                                  reqMessage: undefined,
                                                                  slotReqMessage: undefined,
                                                                  unsavedChanges: false                                 
                                                          } ),
  reducers: {
    setManyActivityChanges: (state, action) =>{
      createScenarioAdapter.addMany(state, action.payload);
    },
    setActivityChanges: (state, action) => {
     createScenarioAdapter.upsertOne(state, action.payload);
    },
    removeActivityChanges: (state, action) => {
      createScenarioAdapter.removeOne(state, action.payload);
     },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setUnsavedChanges: (state, action) => {
      state.unsavedChanges = action.payload;
    },
    setLastCreatedScenarioId: (state, action) => {
      state.lastCreatedScenarioId = action.payload;
    },
    setEditedResources: (state, action) => {
      state.editedResources = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setSelectedWeekStartDate: (state, action) => {
      state.selectedWeekStartDate = action.payload;
    },
    setSelectedDisciplineId: (state, action) => {
      state.selectedDisciplineId = action.payload;
    },
    removeActivityScheduleScenario: (state, action) => {
      createScenarioAdapter.removeAll(state);
    },
    setResourceProfile: (state, action) =>{
      state.resourceProfile = action.payload;
    },
    setReqMessage: (state, action) =>{
      state.reqMessage = action.payload;
    },
    setError: (state, action) =>{
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDisciplineResourceProfile.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
        state.resourceProfile = undefined;
      })
      .addCase(fetchDisciplineResourceProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.resourceProfile = action.payload;
      })
      .addCase(fetchDisciplineResourceProfile.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = action.payload.error;
        }
        else {
          state.error = action.error.message;
        }
      })
      .addCase(analyseUserGeneratedScenario.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
        state.reqMessage = undefined;
      })
      .addCase(analyseUserGeneratedScenario.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.reqMessage = action.payload;
      })
      .addCase(analyseUserGeneratedScenario.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.reqMessage = action.payload;
        }
        else {
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Selectors
 */
 export const {
  selectById: selectActivityChangeById
} = createScenarioAdapter.getSelectors((state) => state.createScenario);

export const selectAllActivityScheduleChanges = (state) => {
  const createScenario = state.createScenario;
  const allActivitySchedules = createScenarioAdapter.getSelectors((state) => createScenario).selectAll();
  return allActivitySchedules;
}


/**
 * Create scenario actions
 */
 export const {
  setManyActivityChanges,
  setActivityChanges,
  removeActivityChanges,
  setName,
  setDescription,
  setEditedResources,
  setSelectedDisciplineId,
  setSelectedWeekStartDate,
  removeActivityScheduleScenario,
  setResourceProfile,
  setReqMessage,
  setError,
  setUnsavedChanges
} = createScenarioSlice.actions;

/**
 * Export reducer
 */
export default createScenarioSlice.reducer;