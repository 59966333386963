import React from 'react';
import { getRiskCategoryIcon } from '../../../../utils';
import './RiskCategory.scss';
var classNames = require('classnames');

const RiskCategory = (props) => {
  const { riskCategory } = props;

  var riskCategoryClass = classNames({
    'risk-category': true,
    'red': riskCategory?.toLowerCase() === 'high',
    'amber': riskCategory?.toLowerCase() === 'medium',
    'green': riskCategory?.toLowerCase() === 'low' || !riskCategory
  });
  return (
    <>
      <span className={riskCategoryClass}>
      {!riskCategory && <>Not Available</>}
      {riskCategory&& <>
      {getRiskCategoryIcon(riskCategory)}&nbsp;{riskCategory}
      </>}
    </span>
    </>
  )
};

export default RiskCategory;