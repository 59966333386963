import React from 'react';
import './DisciplineProgress.scss';
import LinearProgressBar from '../LinearProgressBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDisciplineId } from '../../../../../redux/slices/disciplinesSlice';
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';
import { appInsights } from '../../../../../AppInsights';
import { useMsal, useAccount } from "@azure/msal-react";
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';
let trackData = require('../../../../../appTrackerInfo.json');


const DisciplineProgress = (props) => {
  const { setSelectedDiscipline = true } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const {
    discipline = null,
    showPercentageComplete = true,
    deEmphasize = false
  } = props;

  const dispatch = useDispatch();

  /** sets the selected discipline in redux store */
  const handleDisciplineClick = () => {
    appInsights.trackEvent({
      name: 'DPM - Discipline Progress Overview - Discipline Clicked',
      properties: {
        Page: `${trackData.pages[1].name}`,
        Name: `${discipline.disciplineCode}`,
        Project: `${selectedProjectId}`,
        ProjectName: `${selectedProject.projectName}`,
        User: `${account.username}`,
        Username: `${account.name}`,
        Role: 'Owner'
      }
    });
    dispatch(setSelectedDisciplineId(discipline.disciplineId));
  };

  return (
    <ButtonBase style={{
      width: "100%",
      display: "inline"
    }}
      onClick={setSelectedDiscipline ? handleDisciplineClick : undefined}>
      <div className={`discipline-progress-container ${deEmphasize ? "deemphasize" : ""}`}>
        <div className="progress-header">
          <div className="progress-icon">
            <DisciplineIcon disciplineId={discipline.disciplineId} />
          </div>
          <div className="progress-title">{discipline.disciplineName}</div>
        </div>
      </div>
    </ButtonBase>
  );
}

export default DisciplineProgress;