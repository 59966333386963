import { useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import PhaseProgessChart from '../PhaseProgessChart';
import './PhaseProgessChartContainer.scss';
import { selectPhaseById } from '../../../../../redux/slices/phasesSlice';
import { selectPackageById } from '../../../../../redux/slices/packagesSlice';
import { CloseIcon } from '../../../../../Icons';

const PhaseProgessChartContainer = (props) => {
  const { isLoading, error, phaseProgress } = useSelector(state => state.phaseProgress);

    const handleClose = () => {
      props.closePhaseProgressChart();
    }

    const processData = (rawData) => {
      let planned = [];
      let actual = [];
      let forecasted = [];
      //let timeDelay = [];

      let sorted = rawData.slice().sort((a, b) => (a.PercentageProgress - b.PercentageProgress));

      if(sorted)
      {
        sorted.forEach((data) => {
            if (data.plannedManHours !== undefined && data.plannedManHours !== null) {
              planned.push({percentage: data.percentageProgress * 100,manHours: Math.round(data.plannedManHours)});
            };
            
            if (data.actualManHours !== undefined && data.actualManHours !== null) {
              actual.push({ percentage: data.percentageProgress * 100,manHours: Math.round(data.actualManHours) });
            };
            if (data.forecastedManHours) {
              forecasted.push({percentage: data.percentageProgress * 100,manHours: Math.round(data.forecastedManHours)});
            };
        });
       // timeDelay.push({manHours: data.plannedCompletion?.manHours + (data.overEstimate/2), percentage: 100},{manHours: data.lastForecasted.manHours, percentage: 100})
      }

      let processed = {
        planned: planned,
        actual: actual,
        forecasted: forecasted,
        plannedCompletion: planned.find(x => x.percentage === 100),
        lastActual: actual.sort((a, b) => (a.manHours - b.manHours))[actual.length-1],
        lastForecasted: forecasted.sort((a, b) => (a.manHours - b.manHours))[forecasted.length - 1],
        overEstimate: forecasted.sort((a, b) => (a.manHours - b.manHours))[forecasted.length - 1]?.manHours - planned.find(x => x.percentage === 100)?.manHours,
        //timeDelay : timeDelay,
      }
      return processed;
    }

    return (
      <>
      <div className="chart-container">
          <div className='popover-header'>
            <div>
              <h4>Phase Impact</h4>
            </div>
            <div className='popover-close' onClick={handleClose}>
                    <CloseIcon />
            </div>
          </div>
          <div className="alert-title">
            {props.alert.alertName}
          </div>
        <div className='popover-content'>
          {isLoading &&
            <CircularProgressBar />
          }
          {!isLoading && !error && phaseProgress.length > 0  &&
          <div className='content-wrapper'>
            <PhaseProgessChart data={processData(phaseProgress)} />
      </div>
          }
          </div>          
    </div>
    </> 
    );
};

export default PhaseProgessChartContainer;