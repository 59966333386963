import React, {useState, useEffect} from 'react';
import AlertManagerSelectorList from '../AlertManagerSelectorList';
import './AlertManagerSelector.scss';

const AlertManagerSelector = (props) => {
  const { selectedProject, alerts, selectedAlertIdInManager } = props;
  const [primaryAlerts, setPrimaryAlerts] = useState([]);
  const [secondaryAlerts, setSecondaryAlerts] = useState([]);

  const getAlerts = () => {

    if (alerts.length > 0 ) {
      setPrimaryAlerts(alerts.filter(alert => alert.alertType.toLowerCase() === "primary"));
      setSecondaryAlerts(alerts.filter(alert => alert.alertType.toLowerCase() === "secondary"));
    } else {
      setPrimaryAlerts([]);
      setSecondaryAlerts([]);
    }
  }

  useEffect(() => {
    getAlerts();
  }, [alerts]);
  
  return (
 <div className='alertlist-body'>
      <AlertManagerSelectorList selectedAlertIdInManager={selectedAlertIdInManager} type={'Critical Alerts'} alerts={primaryAlerts} selectedProject={selectedProject} />
      <hr color='#D9E9F8'></hr>
      <AlertManagerSelectorList selectedAlertIdInManager={selectedAlertIdInManager} type={'Secondary Alerts'} alerts={secondaryAlerts} selectedProject={selectedProject} />
 </div>
  )
}

export default AlertManagerSelector;