import { 
    createEntityAdapter, 
    createSlice,
    createAsyncThunk
  } from '@reduxjs/toolkit';
  import { api } from '../../api';
  import { fullActivityScheduleEndpoint } from '../../api/apiRoutes';
  
  export const fetchFullActivitySchedule = createAsyncThunk(
    'scenarioOption/fetchfullactivityschedule', async (params, thunkAPI) => {
      try{
        const response = await api.get(fullActivityScheduleEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      }
      catch (error){
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  
  /**
   * Create full activity schedule adapter
   */
   const fullActivityScheduleAdapter = createEntityAdapter({
    selectId: (fullActivitySchedule) => fullActivitySchedule.itemId,
  })
  
  /**
   * Create full activity schedule slice / reducers
   */
  const fullActivityScheduleSlice = createSlice({
    name: 'fullActivitySchedule',
    initialState: fullActivityScheduleAdapter.getInitialState( {  isLoading: false,
                                                              error: undefined
                                                          } ),
    reducers: {
  
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchFullActivitySchedule.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(fetchFullActivitySchedule.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          fullActivityScheduleAdapter.setAll(state, action.payload);
        })
        .addCase(fetchFullActivitySchedule.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
    }
  });
  
  /**
   *  Full Activity schedule selectors
  */
   export const {
    selectById: selectFullActivityScheduleById,
  } = fullActivityScheduleAdapter.getSelectors((state) => state.fullActivitySchedule);
  
  export const selectAllFullActivitySchedule = (state) => {
    const fullActivitySchedule = state.fullActivitySchedule;
    const allFullActivitySchedule = fullActivityScheduleAdapter.getSelectors((state) => fullActivitySchedule).selectAll();
    return allFullActivitySchedule;
  }
  
  /**
   * Full Activity schedule actions
   */
  export const {  
                  setAllFullActivitySchedule
               } = fullActivityScheduleSlice.actions;
  
  /**
   * Export reducer
   */
  export default fullActivityScheduleSlice.reducer;