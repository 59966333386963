import React from 'react';
import AuthorizedComponent from '../../../../../components/Shared/molecules/AuthorizedComponents/AuthorizedComponent';
import { SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import './ActivityCalculationFooter.scss'

const ActivityCalculationFooter = (props) => {
  const { selectedProjectId, ActivityChangeSaved } = props;
  return (
    <div className='activity-calculation-footer'>
       <AuthorizedComponent value="activity-switch" projectId = {selectedProjectId} disciplineId = 'All'>
                 <SecondaryLighthouseButton onClick={ActivityChangeSaved} >Save Changes</SecondaryLighthouseButton>
       </AuthorizedComponent>
   </div>
  )
}

export default ActivityCalculationFooter