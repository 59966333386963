import React from 'react';
import { getFormattedDate } from '../../../../../utils';
import './Milestones.scss';

const Milestones = (props) => {
  const { milestones } = props;

  return (
    <>
    <div className="milestones-content-container">
      <div className="ms-prop-container">
      <div className="ms-description">{milestones.activityName}</div>
        <div className="ms-title">Properties:</div>
        <table className="ms-prop-table">
          <tbody>
            <tr>
              <th>Activity ID:</th>
              <td>{milestones.activityCode}</td>
            </tr>
            <tr>
              <th>Activity Type:</th>
              <td>{milestones.activityType}</td>
            </tr>
          </tbody>
        </table>
        <div className="ms-title">Dates:</div>
        <table className="ms-prop-table">
          <tbody>
          <tr>
              <th>Planned Date:</th>
              <td>{milestones.plannedEndDate && getFormattedDate(milestones.plannedEndDate)}</td>
            </tr>
            <tr>
              <th>Actual Date:</th>
              <td>{milestones.actualEndDate && getFormattedDate(milestones.actualEndDate)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </>
  )
};

export default Milestones;