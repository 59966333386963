import React from 'react';
import { getFormattedDate } from '../../../../../utils';
import { CalenderIcon, TimeIcon } from '../../../../../Icons';

const ScheduleChangeDisplay = (props) => {
  const { scheduleChange, plannedDuration, disciplineName } = props;

  return (
    <>
       {/* {when short duration is changed} */}
       {scheduleChange.shortestDuration && scheduleChange.shortestDuration.toString().toLowerCase() !== 'none' &&
            <div className='change-list-container'>
              <div className='show-title-container'>
                <div className='icon-div'>
                  <CalenderIcon className='icon' width={24} height={24} />
                </div>
                <div className='title-div'>
                  {scheduleChange.activityCode}
                </div>
              </div>
              <hr></hr>
              <div className='show-changes-list-div-container'>
                <table className='show-changes-list'>
                  <tbody>
                    <tr>
                      <th>Change: </th>
                      <td>Shortest Duration</td>
                    </tr>
                    <tr>
                      <th>Discipline: </th>
                      <td>{disciplineName}</td>
                    </tr>
                    <tr>
                      <th>Planned Duration: </th>
                      <td>{plannedDuration} {plannedDuration > 1 || plannedDuration === 0 ? "days" : "day"}</td>
                    </tr>
                    <tr>
                      <th>Shortest Duration: </th>
                      <td>{scheduleChange.shortestDuration} {scheduleChange.shortestDuration > 1 ? "days" : "day"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          }
          {/* {when longest duration is changed} */}
          {scheduleChange.longestDuration && scheduleChange.longestDuration.toString().toLowerCase() !== 'none' &&
            <div className='change-list-container'>
              <div className='show-title-container'>
                <div className='icon-div'>
                  <CalenderIcon className='icon' width={24} height={24} />
                </div>
                <div className='title-div'>
                  {scheduleChange.activityCode}
                </div>
              </div>
              <hr></hr>
              <div className='show-changes-list-div-container'>
                <table className='show-changes-list'>
                  <tbody>
                    <tr>
                      <th>Change: </th>
                      <td>Longest Duration</td>
                    </tr>
                    <tr>
                      <th>Discipline: </th>
                      <td>{disciplineName}</td>
                    </tr>
                    <tr>
                      <th>Planned Duration: </th>
                      <td>{plannedDuration} {plannedDuration > 1 || plannedDuration === 0 ? "days" : "day"}</td>
                    </tr>
                    <tr>
                      <th>Longest Duration: </th>
                      <td>{scheduleChange.longestDuration} {scheduleChange.longestDuration > 1 ? "days" : "day"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          }
          {/* {when earliest start date changed} */}
          {scheduleChange.earliestStartDate &&
            <div className='change-list-container'>
              <div className='show-title-container'>
                <div className='icon-div'>
                  <CalenderIcon className='icon' width={24} height={24} />
                </div>
                <div className='title-div'>
                  {scheduleChange.activityCode}
                </div>
              </div>
              <hr></hr>
              <div className='show-changes-list-div-container'>
                <table className='show-changes-list'>
                  <tbody>
                    <tr>
                      <th>Change: </th>
                      <td>Earliest Start Date</td>
                    </tr>
                    <tr>
                      <th>Discipline: </th>
                      <td>{disciplineName}</td>
                    </tr>
                    <tr>
                      <th>Planned Start: </th>
                      <td>{getFormattedDate(scheduleChange.plannedStartDate)}</td>
                    </tr>
                    <tr>
                      <th>Earliest Start: </th>
                      <td>{getFormattedDate(scheduleChange.earliestStartDate)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          }
    </>
  )
};

export default ScheduleChangeDisplay;