import React from 'react';
import TimeImpact from '../../../../../components/Shared/atoms/TimeImpact'
import './ScenarioOptionDescription.scss';

const ScenarioOptionDescription = (props) => {
  const { displayDescriptions } = props;
  return (
    <div className='scenario-option-description-container'>
      <ul className='show-list'>
        {displayDescriptions.reasoning === 'early' && displayDescriptions.averageDelay < 3 &&
          <li>Avoid delay on {displayDescriptions.noOfActivitiesAffected} {displayDescriptions.noOfActivitiesAffected > 1 ? 'activities' : 'acivity'} in {displayDescriptions.phaseName} phase of {displayDescriptions.packageName} package schedule similar to plan.</li>
        }
        {displayDescriptions.reasoning === 'early' && displayDescriptions.averageDelay >= 3 &&
          <li>Delay work on {displayDescriptions.noOfActivitiesAffected} { displayDescriptions.noOfActivitiesAffected > 1 ? 'activities' : 'acivity'} in {displayDescriptions.phaseName} phase of {displayDescriptions.packageName} package by <TimeImpact timeImpactDelayDays={displayDescriptions.averageDelay} />. </li>
        }
        {displayDescriptions.reasoning === 'late' &&
          <li>Begin early work on {displayDescriptions.noOfActivitiesAffected} {displayDescriptions.noOfActivitiesAffected > 1 ? 'activities' : 'acivity'} in {displayDescriptions.phaseName} phase of {displayDescriptions.packageName} package by <TimeImpact timeImpactDelayDays={displayDescriptions.averageDelay} />. </li>
        }
      </ul>
    </div>
  );
}

export default ScenarioOptionDescription;