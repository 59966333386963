import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { protectedResourcesEndpoint } from '../../api/apiRoutes';

/**
 * Fetches protectedResources
 * @returns protectedResources
 */
 export const fetchProtectedResources = createAsyncThunk(
  'admin/fetchProtectedResources', async (accessToken, thunkAPI) => {
     try {
        const response = await api.get(protectedResourcesEndpoint(), { headers: { Authorization: `Bearer ${accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Create protected resources adapter
 */
 const protectedResourcesAdapter = createEntityAdapter({
  selectId: (admin) => admin.id,
})

/**
 * Create protected resources slice / reducers
 */
 const protectedResourcesSlice = createSlice({
  name: 'protectedResources',
  initialState: protectedResourcesAdapter.getInitialState( {  selectedResourceId: undefined,
                                                    isLoading: false,
                                                    error: undefined }),
  reducers: {
    setSelectedResourceId: (state, action) => {
      state.selectedResourceId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProtectedResources.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchProtectedResources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        protectedResourcesAdapter.setMany(state, action.payload);
      })
      .addCase(fetchProtectedResources.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * protected resources actions
 */
 export const {  
  selectById: selectProtectedResourceById,
} = protectedResourcesSlice.actions;

export const selectAllProtectedResources = (state) => {
  const protectedResources = state.protectedResources;
  const filteredProtectedResources = protectedResourcesAdapter.getSelectors((state) => protectedResources).selectAll();
  return filteredProtectedResources;
}

/**
 * Export reducer
 */
export default protectedResourcesSlice.reducer;