import React, { useEffect, useState } from 'react';
import AlertManagerSelectorItem from '../../molecules/AlertManagerSelectorItem';
import { LinkLighthouseButton2 } from '../../../../../theme/Buttons';
import { ChevronDownIcon, ChevronUpIcon, WarningCircleIcon } from '../../../../../Icons';
import './AlertManagerSelectorList.scss';

const AlertManagerSelectorList = (props) => {
  const { selectedProject, alerts, type, selectedAlertIdInManager } = props;
  const [alertsToDisplay, setAlertsToDisplay] = useState(5);
    /**
   * More button clicked
   * expand list
   */
     const moreButtonClick = () => {
      setAlertsToDisplay(alerts.length);
    }
  
    /**
     * Less button clicked
     * collapse list
     */
    const lessButtonClick = () => {
      setAlertsToDisplay(5);
    }

    useEffect(() => {
      // when the selected alert is changed
      // check if the alert is displayed currently
      // and if not then expand the list
      if (selectedAlertIdInManager) {
        alerts.forEach((alert, index) => {
          if (alert.alertId === selectedAlertIdInManager) {
           // violationRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
            if (index > alertsToDisplay) {
              setAlertsToDisplay(alerts.length);
            }
          }
        })
      }
    }, [selectedAlertIdInManager, alerts])
  
  return (
    <div className="alerts-container">
      <div className="header-section">
          <div className='header-title'>{type}:</div>
          <div className="header-button">
        {alerts.length > 5 && alertsToDisplay !== alerts.length &&
          <LinkLighthouseButton2 onClick={moreButtonClick}>
            Show More
            <ChevronDownIcon style={{ marginLeft: "10px" }} />
          </LinkLighthouseButton2>
        }
        {alerts.length > 5 && alertsToDisplay == alerts.length &&
          <LinkLighthouseButton2 onClick={lessButtonClick}>
            Show Less
            <ChevronUpIcon style={{ marginLeft: "10px" }} />
          </LinkLighthouseButton2>
        }
      </div>
     </div>
     {
      alerts.slice(0, alertsToDisplay).map((alert, i) =>
      (
        <AlertManagerSelectorItem alert={alert} key={alert.alertId} selectedProject={selectedProject} />
      ))
    }
    {!alerts.length &&
      <div className='empty-list'>
        <span className='alert-icon-empty'><WarningCircleIcon /> </span>
         <span>No {type} found!</span>
       </div>}
  </div>
  )
}

export default AlertManagerSelectorList;