import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk ,
  createDraftSafeSelector
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { addUserEndPoint, deleteUserEndPoint, editUserEndPoint, usersEndPoint } from '../../api/apiRoutes';

export const fetchUsers = createAsyncThunk(
  'user/fetchusers', async (params, thunkAPI) => {
    try{
      const response = await api.get(usersEndPoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addUser = createAsyncThunk(
  'user/adduser', async (params, thunkAPI) => {
    try{
      const response = await api.post(addUserEndPoint(params.projectId), params.user, { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteUser = createAsyncThunk(
  'user/deleteuser', async (params, thunkAPI) => {
    try{
      const response = await api.post(deleteUserEndPoint(params.projectId, params.userId), null, { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const editUser = createAsyncThunk(
  'user/edituser', async (params, thunkAPI) => {
    try{
      const response = await api.post(editUserEndPoint(params.projectId, params.userId), null, { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Create phases adapter
 */
 const usersAdapter = createEntityAdapter({
  selectId: (user) => user.userId,
})

/**
 * Create deliverables slice / reducers
 */
const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState( { selectedUserId: undefined,
                                                       error: undefined,
                                                       isLoading: false
                                                   } ),
  reducers: {
    setSelectedUserId: (state, action) => {
      state.selectedUserId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        usersAdapter.setAll(state, action.payload);
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(addUser.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        usersAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(editUser.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Deliverables selectors
*/
export const {
  selectById: selectUserById,
} = usersAdapter.getSelectors((state) => state.users);

export const selectAllUsers = (state, projectId) => {
  const users = state.users;
  const filteredUsers = usersAdapter.getSelectors((state) => users).selectAll();
  return filteredUsers;
}

/**
 * Export reducer
 */
export default usersSlice.reducer;