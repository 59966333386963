import React from 'react';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useDispatch, useSelector } from "react-redux";
import { selectActivityChangeById, setActivityChanges , removeActivityChanges } from '../../../../../redux/slices/createScenarioSlice';

const ScheduledActivityDurationChange = React.memo(props => {
  const { activity, durationType } = props;
  const existingActivityScheduleChange = useSelector((state) => selectActivityChangeById(state, activity.activityId));
  const dispatch = useDispatch();

  // define the current duration
  let currentDuration;

  // if there is an existing match in the schedule changes then use that duration
  if (durationType === "shortest") {
    if (existingActivityScheduleChange && existingActivityScheduleChange.shortestDuration) {
      currentDuration = existingActivityScheduleChange.shortestDuration;
    }
  }
  else if (durationType === "longest") {
    if (existingActivityScheduleChange && existingActivityScheduleChange.longestDuration) {
      currentDuration = existingActivityScheduleChange.longestDuration;
    }
  }

  // setup validation rules for the min and max values allowed
  let minDurationAllowedValue = 1, maxDurationAllowedValue;

  // Validation rules - set the min and max duration values
  //  depending on whether there are shortest or longest values already set
  if (durationType === "shortest") {
    if (existingActivityScheduleChange && existingActivityScheduleChange.longestDuration) {
      // set the max value to the longest minus 1 day
      maxDurationAllowedValue = existingActivityScheduleChange.longestDuration;
    }
  }
  else if (durationType === "longest") {
    if (existingActivityScheduleChange && existingActivityScheduleChange.shortestDuration) {
      // set the minimum duration allowed to the shortest duration plus 1 day
      minDurationAllowedValue = existingActivityScheduleChange.shortestDuration;
    }
  }

  /**
   * Sets the new duration for this activity by creating a new or updating 
   * existing activity schedule change
   * @param {object} args 
   */
  const newDurationChange = (args) => {
    // Get the new duration value
    let durationChange = args.value;
    let activityNewChanges;

    // set the duration type change
    if (durationType === "shortest") {
      activityNewChanges = {
        "activityId": activity.activityId,
        "activityCode": activity.activityCode,
        "shortestDuration": durationChange ? durationChange : undefined,
        "disciplineName" : activity.parentItem.parentItem.parentItem.name,
        "plannedDuration" : activity.activityPlannedDuration
      };
      
      if (existingActivityScheduleChange === undefined) {
        // dispact to set activity state when the shortest duration is unselected
        dispatch(setActivityChanges(activityNewChanges));
      }
      else {
        if (args.value === null && existingActivityScheduleChange.longestDuration === undefined && existingActivityScheduleChange.earliestStartDate === undefined) {
          // dispatch to remove state
          dispatch(removeActivityChanges(activity.activityId));
        }
        else {
          // dispatch to state
          dispatch(setActivityChanges(activityNewChanges));
        }
      }

    }
    else if (durationType === "longest") {
      activityNewChanges = {
        "activityId": activity.activityId,
        "activityCode": activity.activityCode,
        "longestDuration": durationChange ? durationChange : undefined,
        "disciplineName" : activity.parentItem.parentItem.parentItem.name,
        "plannedDuration" : activity.activityPlannedDuration
      };
      if (existingActivityScheduleChange === undefined) {
        // dispact to set activity state when the shortest duration is unselected
        dispatch(setActivityChanges(activityNewChanges));
      }
      else {
        if (args.value === null && existingActivityScheduleChange.shortestDuration === undefined && existingActivityScheduleChange.earliestStartDate === undefined) {
          // dispatch to remove state
          dispatch(removeActivityChanges(activity.activityId));
        }
        else {
          // dispatch to state
          dispatch(setActivityChanges(activityNewChanges));
        }
      }
    }
  }

  // set the custom format
  let formatString = currentDuration > 1 ? "### days" : "### day";

  return (
    <NumericTextBoxComponent decimals={0}
      min={minDurationAllowedValue}
      max={maxDurationAllowedValue}
      value={currentDuration}
      format={formatString}
      change={newDurationChange}
      showClearButton={true} />
  );
});

/**
 * Compares the props to see if they are equal
 * so component only gets re-rendered if changed
 * @param {object} prevProps 
 * @param {object} nextProps 
 * @returns Boolean
 */
const areEqual = (prevProps, nextProps) => {
  if (nextProps.activity == prevProps.activity) {
    return false;
  }
  else {
    return true;
  }
}

export default React.memo(ScheduledActivityDurationChange, areEqual);