import React from 'react';
import { useSelector } from "react-redux";
import { selectAllDisciplines } from '../../../../redux/slices/disciplinesSlice';
import { selectAllProtectedResources } from '../../../../redux/slices/protectedResourcesSlice';
import { selectAllRoleResourceMappings } from '../../../../redux/slices/roleResourceMappingsSlice';

const AuthorizedComponent = (props) =>{
    const { value, projectId, disciplineId } = props;
    const { authorized } = useSelector(state => state.authorization);

    const disciplines = useSelector((state) => selectAllDisciplines(state));
    var disciplineName = "";

    if(disciplineId != undefined){
        if(disciplineId != 'All'){
            disciplineName = disciplines.length > 0 ? disciplines.filter(x=>x.disciplineId == disciplineId)[0].disciplineName : null;
        }
        else{
            disciplineName = disciplineId;
        }
    }

    const protectedResources = useSelector((state) => selectAllProtectedResources(state));
    const selectedProjectResource = protectedResources.length > 0 ? protectedResources.filter(x=>x.resource == value && x.isActive == true)[0].id : null;

    const roleResourceMappings = useSelector((state) => selectAllRoleResourceMappings(state));
    const filteredRoleResourceMappings = roleResourceMappings.length > 0 ? roleResourceMappings.filter(x=>x.protectedResourceId == selectedProjectResource) : null;

    var disabled = true;

    if(authorized && selectedProjectResource != undefined && filteredRoleResourceMappings != undefined){
        for (var i = 0; i < filteredRoleResourceMappings.length; i++){
            if((filteredRoleResourceMappings[i].project.toLowerCase() == projectId || filteredRoleResourceMappings[i].project == "All") && (filteredRoleResourceMappings[i].discipline == disciplineName || filteredRoleResourceMappings[i].discipline == "All" || disciplineName == 'All')){
                disabled = false;
                break;
            }
       }
    }

    return(
        <>
            {React.Children.map(props.children, child => { 
                return React.cloneElement(child, { disabled })
            })}
        </>
    );
}

export default AuthorizedComponent;
