import React, {useEffect} from 'react';
import { renderToString } from 'react-dom/server';
import { Handle } from 'react-flow-renderer';
import { useSelector } from "react-redux";
import { LighthouseTooltip, getFormattedDate } from '../../../../../utils';
import { setSelectedFocusAreaNodeId, setHoverFocusAreaNodeId } from '../../../../../redux/slices/optimisationFocusAreasSlice';
import { useDispatch } from "react-redux";
import classNames from "classnames";

import './InterfaceNode.scss';

const InterfaceNode = (props) => {
  const { id, label, earliestStartDate, isDrivingPath } = props.data;
  const { selectedFocusAreaNodeId, selectedHoverFocusAreaNodeId } = useSelector(state => state.optimisationFocusAreas);
  const isSelected = selectedFocusAreaNodeId === id;
  const isHover = selectedHoverFocusAreaNodeId === id;
  const dispatch = useDispatch();

  const toolTipRef = React.createRef();

  /**
 * Tooltip template
 * @param {object} props 
 * @returns 
 */
  const TooltipTemplate = (props) => {
    return (
      <>
        <div className='templete-tooltip-container'>
          <div className='templete-tooltip-field'>
            <span className='text-title' >Interface:</span>
            <span className='text-desc' style={{ width: '200px' }}>{props.label}</span>
          </div>
          <div className='templete-tooltip-field'>
            <span className='text-title'>Date:</span>
            <span className='text-desc'>{getFormattedDate(props?.earliestStartDate)}</span>
          </div>
        </div>
      </>
    );
  }

  /**
  * Set the hover focus area node id in state
  */
  const onMouseEnter = () => {
    if (selectedFocusAreaNodeId) {
      dispatch(setHoverFocusAreaNodeId(id));
    }
  };

  /**
   * Clear the hover focus area node id in state
   */
  const onMouseLeave = () => {
    if (selectedFocusAreaNodeId) {
      dispatch(setHoverFocusAreaNodeId(undefined));
    }
  };

  /**
   * Sets the selected focus area Id
   */
  const onClick = () => {
    if (selectedFocusAreaNodeId !== id) {
      dispatch(setSelectedFocusAreaNodeId(id));
    }
    else {
      dispatch(setSelectedFocusAreaNodeId(undefined));
    }
  }

  useEffect(() => {
    if(selectedHoverFocusAreaNodeId && selectedHoverFocusAreaNodeId === id) {
      toolTipRef.current?.open();
    }
    else{
      toolTipRef.current?.close();
    }
  },[selectedHoverFocusAreaNodeId,toolTipRef])

  return (
    <div className="interface-node-container" onClick={onClick}>
      <LighthouseTooltip tipRef={toolTipRef} content={renderToString(TooltipTemplate(props.data))} openDelay="250">
        <div className={classNames({ "interface-node": true, "driving-path": isDrivingPath, "selected-node": isSelected, "hover-interface-node": isHover })} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <div>
            <div>
              <Handle
                type="target"
                position="left"
                style={{ background: 'none', border: 'none', left: -5, top: 150 }}
              />
              <Handle
                type="source"
                position="right"
                style={{ background: 'none', border: 'none', right: -5, top: 0 }}
              />
              <div className={classNames({ "in-label": true, "driving-path-label": isDrivingPath })}>
                <span>{label}</span>
                <span>Est. Start Date:</span>
                <span>{getFormattedDate(earliestStartDate)}</span>
              </div>
            </div>
          </div>
        </div>
      </LighthouseTooltip>
    </div>
  );
};

export default React.memo(InterfaceNode);