import { 
    createSlice,
    createAsyncThunk
  } from '@reduxjs/toolkit';
  import { api } from '../../api';
  import { activityFocusAreasEndpoint } from '../../api/apiRoutes';
  
  export const fetchActivityFocusAreas = createAsyncThunk(
    'scenarioOption/fetchactivityFocusAreas', async (params, thunkAPI) => {
      try{
        const response = await api.get(activityFocusAreasEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      }
      catch (error){
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  
  /**
   * Create activity focus area slice / reducers
   */
  const activityFocusAreaSlice = createSlice({
    name: 'activityFocusArea',
    initialState: {
      activityFocusAreas: undefined,
      isLoading: false,
      error: undefined
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchActivityFocusAreas.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(fetchActivityFocusAreas.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          state.activityFocusAreas = action.payload;
        })
        .addCase(fetchActivityFocusAreas.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
    }
  });
  
  /**
   * Export reducer
   */
  export default activityFocusAreaSlice.reducer;