import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleIcon } from '../../../../../Icons';
import { selectDisciplineById, setFilteredDisciplineIds } from '../../../../../redux/slices/disciplinesSlice';
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';
import LinearProgressBar from '../LinearProgressBar';
import './DisciplineProgressDetail.scss';

const DisciplineProgressDetail = (props) => {
  const { disciplineId, isPropertyViewer } = props;
  const discipline = useSelector((state) => selectDisciplineById(state, disciplineId));
  const { filteredDisciplineIds } = useSelector(state => state.disciplines);
  const dispatch = useDispatch();

  /**
   * Closes the discipline progress detail and 
   * removes the discipline id from the filtered disciplines
   */
  const handleCloseClick = () => {
    let filtered = filteredDisciplineIds.filter(function(value){ 
      return value !== disciplineId;
    });
    dispatch(setFilteredDisciplineIds(filtered));
  };

  /**
   * Opens resource allocation chart
   * @param {object} event 
   */
  const handleOpenClick = (event) => {
    props.openResourceAllocation(event, disciplineId);
  }

  const handleOpenCapacity =(event)=>{
    props.openResourceCapacity(event, disciplineId);
  }

  return (
    <>
      {discipline ? (
        <div className="dpd-container component-shadow"  style={{ height: isPropertyViewer? '330px': '135px'}}>
          <div className="dpd-header">
            <div className="dpd-icon-label-container">
              <span className="dpd-icon">
                <DisciplineIcon disciplineId={discipline.disciplineId} />
              </span>
              <span className="dpd-progress-title">{discipline.disciplineName}</span>
            </div>
            <IconButton aria-label="close" onClick={handleCloseClick}>
              <CloseIcon />
            </IconButton>
          </div>
        <div className="dpd-container-content">
          <div className="dpd-progress-container">
            <div className="dpd-progress-header">
              <span className="progress-name">Estimated Progress:</span>
              <span className="progress-val">{Math.round(discipline.completionStatus.actualPercentageComplete)}%</span>
            </div>
            <LinearProgressBar actualPercentageComplete={discipline.completionStatus.actualPercentageComplete} plannedPercentageComplete={discipline.completionStatus.plannedPercentageComplete} designProgress={true} />
          </div>
          <div className="dpd-progress-container">
            <div className="dpd-progress-header">
              <span className="progress-name">Planned Progress:</span>
              <span className="progress-val">{Math.round(discipline.completionStatus.plannedPercentageComplete)}%</span>
            </div>
            <LinearProgressBar plannedPercentageComplete={discipline.completionStatus.plannedPercentageComplete} />
          </div>

          {isPropertyViewer &&
          <>
           <div className='resource-overview'>
              <span>Resource Overview: <a onClick={handleOpenClick} style={{ cursor: 'pointer' }}>View Graph</a></span>
            </div>
            <div className='resource-overview'>
               <span>Resource Capacity: <a onClick={handleOpenCapacity} style={{ cursor: 'pointer' }}>View Graph</a></span>
            </div>
          </>

          }
        </div>
        </div>
      ) : (
        <div className="dpd-container component-shadow">
          <div className="center-content">
          <div className="dpd-progress-header">
            <div className="dpd-icon-label-container">
              <span className="vl"></span>
              <span className="dpd-info-icon">
                <InfoCircleIcon />
              </span>
              <p><b>Drill down into further information</b><br/>Click on a discipline to breakdown into a progress view.</p>
            </div>
          </div>
          
          </div>
        </div>
      )}
    </>
  );
}


export default DisciplineProgressDetail;