import React from 'react';
import { useSelector } from "react-redux";
import { selectDisciplineById } from '../../../../../redux/slices/disciplinesSlice';
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import CircularProgressIndicator from '../../../../../components/Shared/molecules/CircularProgressIndicator';
import { getProgressBarColour } from '../../../../../utils';
import { renderToString } from 'react-dom/server';
import './DisciplineProgressItem.scss';

const DisciplineProgressItem = (props) => {
  const { disciplineId } = props;
  const discipline = useSelector((state) => selectDisciplineById(state, disciplineId));

  const infoDetails = [
    {
      header: 'Schedule Activities - Planned Progress',
      first: 'The planned % completion at the discipline level is the % of the planned hours that should be completed.'
    },
    { 
      header: 'Schedule Activities - Estimated Progress Completion',
      first: 'The % completion at the discipline level is calculated as the aggregation of the individual package % completions weighted by the planned hours for each package.',
      second: 'An Activity can be defined as a process, done for a particular purpose. Each Work package is divided into activities. Activities have to lead to the development of one or more project outputs.'
    },
    {
      header: 'Deliverables - Estimated Progress Completion',
      first: 'The % completion at the discipline level is calculated as the aggregation of the individual package % completions weighted by planned hours for each package.',
      second: 'A deliverable can be defined as the physical evidence of what has been produced through an activity or as the physical evidence/support of the output that was produced through an activity.'
    }
  ]

   /**
 * Tooltip template
 * @param {object} props 
 * @returns 
 */
 const TooltipTemplate = (props) => {
  return (
      <>
      <div className='progress-info-container'>
        <div className='progress-info-header'>
          <p>{props?.header}</p>
        </div>
        <div className='progress-info-desc'>
          {props?.first !== null && <p>{props?.first}</p>}
          {props?.second !== null && <p>{props?.second}</p>}
        </div>
      </div>
      </>
  );
}

  /**
   * Opens resource allocation chart
   * @param {object} event 
   */
  const handleOpenAllocation = (event) => {
    props.openResourceAllocation(event, disciplineId);
  }

  const handleOpenCapacity =(event)=>{
    props.openResourceCapacity(event, disciplineId);
  }
if(discipline){
  const deliverableBasedActualPercComplete =  discipline?.completionStatus?.deliverableBasedActualPercComplete??null;
  const plannedPercentageComplete = discipline?.completionStatus?.plannedPercentageComplete??null;
  const activityBasedActualPercComplete = discipline?.completionStatus?.activityBasedActualPercComplete??null;
  const progressDesignProps = { size: 50, labelSize: '12px', lineWidth: 4, labelColor: '#05273D'};
  return (
        <div className="dp-container component-shadow">
            <div className="dp-header">
              <span className="dp-icon">
                <DisciplineIcon disciplineId={discipline.disciplineId} />
              </span>
              <span className="dp-progress-title">{discipline.disciplineName}</span>
            </div>
            <div className="dp-progress-container">
            <div className="dp-progress-bar">
            <CircularProgressIndicator progress={plannedPercentageComplete} {...getProgressBarColour(plannedPercentageComplete, null, true)}  {...progressDesignProps} /> 
              <div className="dp-progress-header">
                <div className='progress-bar-tag'>
                  <span>
                  Planned Progress Completion
                  </span>
                    <InfoTooltip message={renderToString(TooltipTemplate(infoDetails[0]))}  width={510}  iconSize={14} />
                </div>
                <span className='progress-bar-desc'>Schedule Activities {plannedPercentageComplete === null && ': Zero registered'}</span>
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="dp-progress-bar">
              <CircularProgressIndicator progress={activityBasedActualPercComplete} {...getProgressBarColour(plannedPercentageComplete, activityBasedActualPercComplete)} {...progressDesignProps} /> 
              <div className="dp-progress-header">
                <div className='progress-bar-tag' >
                  <span>
                      Estimated Progress Completion
                   </span>
                    <InfoTooltip message={renderToString(TooltipTemplate(infoDetails[1]))}  width={510} iconSize={14} />
                </div>
                <span className='progress-bar-desc' >Schedule Activities {activityBasedActualPercComplete === null && ': Zero registered'} </span>
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="dp-progress-bar">
            <CircularProgressIndicator progress={deliverableBasedActualPercComplete} {...getProgressBarColour(plannedPercentageComplete, deliverableBasedActualPercComplete)} {...progressDesignProps} /> 
              <div className="dp-progress-header">
                <div className='progress-bar-tag'>
                  <span>
                    Estimated Progress Completion
                  </span>
                   <InfoTooltip message={renderToString(TooltipTemplate(infoDetails[2]))} width={510}  iconSize={14} />
                </div>
                <span className='progress-bar-desc'>Deliverables{deliverableBasedActualPercComplete === null && ': Zero registered'}</span>
              </div>
            </div>
            <div className='resource-overview'>
              <span><a onClick={handleOpenAllocation} style={{ cursor: 'pointer' }}>Resource Overview</a></span>
            </div>
            <div className='resource-overview'>
               <span><a onClick={handleOpenCapacity} style={{ cursor: 'pointer' }}>Resource Capacity</a></span>
            </div>
            </div>
        </div>
  );  
}
  else {
    return null;
  }
}


export default DisciplineProgressItem;