import React from 'react';
import { SecondaryLighthouseButton } from '../../../../theme/Buttons';
import SupportModalItem from '../../atoms/SupportModalItem/SupportModalItem';
import './SupportModal.scss';

const SupportModal = (props) => {
    const {SupportList,closeWidget} = props;
    return (
      <>
       <div className="need-support-container">
        <div className='popover-header'>
          <h6>Need Support?</h6>
        </div>
        <div className='popover-content'>
         <SupportModalItem SupportList={SupportList}/>  
        </div>
        <div className="popover-footer">
          <SecondaryLighthouseButton onClick={closeWidget}>Close Window</SecondaryLighthouseButton>
        </div>
      </div>
      </>
    )
}
export default SupportModal;