import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { alertActivityDriversEndPoint } from '../../api/apiRoutes';

export const fetchAlertActivityDrivers = createAsyncThunk(
  'alert/fetchalertactivitydrivers', async (params, thunkAPI) => {
    try{
      const response = await api.get(alertActivityDriversEndPoint(params.projectId, params.alertId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Create Alert Activity adapter
 */
 const alertActivityDriversAdapter = createEntityAdapter({
  selectId: (activityDriver) => activityDriver.id,
})

/**
 * Create Alert Activity Drivers slice / reducers
 */
const alertActivityDriversSlice = createSlice({
  name: 'activityDrivers',
  initialState: alertActivityDriversAdapter.getInitialState( { id: undefined,
                                                      activityLoading: false,
                                                      activityError: undefined,
                                                   } ),
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlertActivityDrivers.pending, (state, action) => {
        state.activityLoading = true;
        state.activityError = undefined;
      })
      .addCase(fetchAlertActivityDrivers.fulfilled, (state, action) => {
        state.activityLoading = false;
        state.activityError = undefined;
        alertActivityDriversAdapter.setMany(state, action.payload);
      })
      .addCase(fetchAlertActivityDrivers.rejected, (state, action) => {
        state.activityLoading = false;
        if (action.payload){
          state.activityError = action.payload.error;
        }
        else{
          state.activityError = action.error.message;
        }
      })
  }
});

/**
 * Alert Activity selectors
*/

export const selectAllActivityDrivers = (state, alertId) => {
  const alertActivityDrivers = state.alertActivityDrivers;
  const filteredActivityDrivers = alertActivityDriversAdapter.getSelectors((state) => alertActivityDrivers).selectAll().filter(activityDriver => activityDriver.alertId === alertId);
  return filteredActivityDrivers;
}

/**
 * Export Alert Activity reducer
 */
export default alertActivityDriversSlice.reducer;