import React from 'react';
import ScenarioManagerSelectorItem from '../../molecules/ScenarioManagerSelectorItem';
import './ScenarioManagerSelectorList.scss';

const ScenarioManagerSelectorList = (props) => {
  const { selectedProject, scenarios, type } = props;

  return (
    <div className={type === 'Scenario List' ? "user-gen-scenarios-container" : "system-gen-scenarios-container"} >
      <div className="header-section">
        <div className='header-title'>{type}:</div>
      </div>
      {scenarios.length > 0 &&
        scenarios.map((scenario, i) =>
        (
          <ScenarioManagerSelectorItem scenario={scenario} key={scenario.scenarioId} selectedProject={selectedProject} />
        ))
      }
    </div>
  )
}

export default ScenarioManagerSelectorList;