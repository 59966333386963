import React from 'react';
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCompletionStatusColour } from '../../../../../utils';
import './CircularProgressBar.scss';
import { v4 as uuidv4 } from 'uuid';


const CircularProgressBar = (props) => {
  const { actualProgress, plannedProgress, hasStarted } = props;
  //console.log("circular progress:", progress);
  let currentColour = getCompletionStatusColour(actualProgress, plannedProgress);

  if (hasStarted){
    return (
      <>
      {actualProgress < 100 ? (
       <ProgressBarComponent
          id={'cp_' + uuidv4()}
          type='Circular'
          height="40px"
          width="40px"
          progressColor={currentColour} 
          value={actualProgress}
          animation={{
            enable: true,
            duration: 1000,
            delay: 0
          }}
        />
        ):(
          <FontAwesomeIcon icon="check-circle" className="completed-icon" />
        )
      }
      </>
    );
  }
  else{
    return  <FontAwesomeIcon icon="clock" className="icon" />;
  }
};

export default CircularProgressBar;