import React, { useEffect, useState } from 'react';
import {
  AccordionComponent,
  AccordionItemsDirective,
  AccordionItemDirective
} from '@syncfusion/ej2-react-navigations';
import Deliverable from '../Deliverable';
import './DeliverableListAccordion.scss';
import { LinkLighthouseButton2 } from '../../../../../theme/Buttons';
import { ChevronDownIcon, ChevronUpIcon, DeliverableIcon } from '../../../../../Icons';

const DeliverableListAccordion = React.memo(props => {
  const { deliverables = [], selectedPhaseId } = props;
  const deliverableAccordionComponentRef = React.createRef();
  const [deliverablesToDisplay, setDeliverablesToDisplay] = useState(5);

  /** The deliverable header template */
  const deliverableHeader = (deliverable) => {
    return <>
      <div className="deliverable-header">
          <DeliverableIcon className="deliverable-icon" />
          <div className="deliverable-title">
            {deliverable.description?.length > 70 && deliverable.description?.substring(0,67)+"..."}
            {deliverable.description?.length <= 70 && deliverable.description}
        </div>
      </div>
    </>;
  }

  /** The deliverable content template */
  const deliverableContent = (deliverable) => {
      return (
        <>
          <div>
              <Deliverable deliverable={deliverable} />
          </div>
        </>
      )
    }

  useEffect(() => {
    // when the selected phase is changed
    // check if the phase is displayed currently
    // and if not then expand the list
     if (deliverables.length > 0) {
      deliverables.forEach((deliverable, index) => {
         if (deliverable.phaseId === selectedPhaseId) {
          setDeliverablesToDisplay(5);
          }
       })
     }
  }, [deliverables])

  /** Accordion animation settings */
  const animationSettings =
  {
    expand: { effect: 'None', duration: 0, easing: 'linear' },
    collapse: { effect: 'None', duration: 0, easing: 'linear' }
  };

  /** Handles accordion item expanded
   * scrolls to the location of the expanded item
   */
  const deliverableAccordionExpanded = (args) => {
    let deliverableRef = args.content;

    // need set timeout 
    // for this to work in React
    setTimeout(function () {
      deliverableRef.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'center',
        }
      );
    }, 100);
  }

  /**
   * More button clicked
   * expand list
   */
  const moreButtonClick = () => {
    setDeliverablesToDisplay(deliverables.length);
  }

  /**
   * Less button clicked
   * collapse list
   */
  const lessButtonClick = () => {
    setDeliverablesToDisplay(5);
  }

  return (
    <div className="deliverable-accordion-container">
      <AccordionComponent expandMode='Single'
        ref={deliverableAccordionComponentRef}
        animation={animationSettings}
        expanded={deliverableAccordionExpanded}>
        <AccordionItemsDirective>
          {
            deliverables.slice(0, deliverablesToDisplay).map((deliverable, i) => {
              let isExpanded = false;
              return (
                <AccordionItemDirective
                  header={() => deliverableHeader(deliverable)}
                  content={() => deliverableContent(deliverable)}
                  iconCss='e-athletics e-acrdn-icons'
                  cssClass="acc-item component-shadow"
                  key={deliverable.deliverableId}
                  id={deliverable.deliverableId}
                  deliverableData={deliverable}
                  expanded={isExpanded}
                />
              )
            })
          }
        </AccordionItemsDirective>
      </AccordionComponent>
        {deliverables.length > 5 && deliverablesToDisplay !== deliverables.length &&
        <div className="more-button">
          <LinkLighthouseButton2 onClick={moreButtonClick}>
            Show More
            <ChevronDownIcon style={{ marginLeft: "10px" }} />
          </LinkLighthouseButton2>
        </div>
        }
        {deliverables.length > 5 && deliverablesToDisplay == deliverables.length &&
        <div className="more-button">
          <LinkLighthouseButton2 onClick={lessButtonClick}>
            Show Less
            <ChevronUpIcon style={{ marginLeft: "10px" }} />
          </LinkLighthouseButton2>
        </div>
        }
    </div>
  );
})

export default DeliverableListAccordion;