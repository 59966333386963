import { 
    createEntityAdapter, 
    createSlice,
    createAsyncThunk
  } from '@reduxjs/toolkit';
  import { api } from '../../api';
  import { activityScheduleEndpoint } from '../../api/apiRoutes';
  
  export const fetchActivitySchedule = createAsyncThunk(
    'scenarioOption/fetchactivityschedule', async (params, thunkAPI) => {
      try{
        const response = await api.get(activityScheduleEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      }
      catch (error){
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  
  /**
   * Create activity schedule adapter
   */
   const activityScheduleAdapter = createEntityAdapter({
    selectId: (activitySchedule) => activitySchedule.disciplineId,
  })
  
  /**
   * Create activity schedule slice / reducers
   */
  const activityScheduleSlice = createSlice({
    name: 'activitySchedule',
    initialState: activityScheduleAdapter.getInitialState( {  isLoading: false,
                                                              error: undefined
                                                          } ),
    reducers: {
      removeActivitySchedule: (state, action) => {
        activityScheduleAdapter.removeAll(state);
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchActivitySchedule.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(fetchActivitySchedule.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          activityScheduleAdapter.setAll(state, action.payload.disciplines);
        })
        .addCase(fetchActivitySchedule.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
    }
  });
  
  /**
   *  Activity schedule selectors
  */
   export const {
    selectById: selectActivityScheduleById,
  } = activityScheduleAdapter.getSelectors((state) => state.activitySchedule);
  
  export const selectAllActivitySchedule = (state) => {
    const activitySchedule = state.activitySchedule;
    const allActivitySchedule = activityScheduleAdapter.getSelectors((state) => activitySchedule).selectAll();
    return allActivitySchedule;
  }
  
  /**
   * Activity schedule actions
   */
  export const {  
                  setAllActivitySchedule,
                  removeActivitySchedule
               } = activityScheduleSlice.actions;
  
  /**
   * Export reducer
   */
  export default activityScheduleSlice.reducer;