import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import CircularProgressBar from "../../../../../components/Shared/molecules/CircularProgressBar";
import { fetchFocusAreasInterfacePointDrivingPath, setFocusAreaNodeFilterIds, fetchTotalCountDrvingPath, setSelectedFocusAreaNodeId } from "../../../../../redux/slices/optimisationFocusAreasSlice";
import InterfacePointDrivingPathDiagram from "../InterfacePointDrivingPathDiagram";
import InterfacePointDrivingPathTable from '../InterfacePointDrivingPathTable';
import InterfacePointDrivingPathCountFlag from '../../atom/InterfacePointDrivingPathCountFlag';
import { selectAllDrivingPathNodes } from '../../../../../redux/slices/optimisationFocusAreasSlice';
import './InterfacePointDrivingPathContainer.scss';

const InterfacePointDrivingPathContainer = (props) => {
  const { selectedProjectId } = useSelector(state => state.projects);
  const { selectedInterfacePointNumber,
    isInterfacePointDrivingPathLoading,
    interfacePointdrivingPathError,
    selectedFocusAreaNodeId
  } = useSelector(state => state.optimisationFocusAreas);
  const drivingPathNodes = useSelector(selectAllDrivingPathNodes);
  const [filteredDrivingPathNodes, setFilteredDrivingPathNodes] = useState([]);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();
  const { totalCount } = useSelector(state => state.optimisationFocusAreas);

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  useEffect(() => {
    // Get driving path for the selected interface point
    if (accessToken && selectedProjectId){
      dispatch(fetchFocusAreasInterfacePointDrivingPath({ projectId: selectedProjectId, interfacePointNumber: selectedInterfacePointNumber, accessToken: accessToken })).then(() => {
        //fetch total count for interfaces and activities for driving path
        dispatch(fetchTotalCountDrvingPath({projectId: selectedProjectId,interfacePointNumber: selectedInterfacePointNumber, accessToken: accessToken}))
      });

      // clean state
      dispatch(setFocusAreaNodeFilterIds(undefined));
      dispatch(setSelectedFocusAreaNodeId(undefined));
    }
  }, [dispatch, selectedInterfacePointNumber, selectedProjectId, accessToken]);

  useEffect(() => {
    if (selectedFocusAreaNodeId && drivingPathNodes.length) {
      // filter the driving path nodes to include only
      // immediate predecessors and successors
      let selectedNodeSuccessorsIds = drivingPathNodes.find(e => e.focusAreaNodeId === selectedFocusAreaNodeId).focusAreaNodeSuccessor.map(s => s.focusAreaNodeId);
      let reducedDrivingPathNodeIds = drivingPathNodes.filter(e => e.focusAreaNodeId === selectedFocusAreaNodeId
        || e.focusAreaNodeId.includes(selectedNodeSuccessorsIds)
        || e.focusAreaNodeSuccessor.some(g => g.focusAreaNodeId.includes(selectedFocusAreaNodeId))).map(g => g.focusAreaNodeId);

      dispatch(setFocusAreaNodeFilterIds(reducedDrivingPathNodeIds));
    }
    else {
      dispatch(setFocusAreaNodeFilterIds(undefined));
    }

  }, [selectedFocusAreaNodeId, drivingPathNodes]);

  /**
  * Adds connectors to the elements array
  * @param {*} elems 
  * @param {*} toNodes 
  * @param {*} fromNodeId 
  */
  function addConnector(wbss, elems, toNodes, fromNodeId) {
    toNodes.forEach((toNode) => {
      let edgeColour;
      let connector = {};
      connector.id = fromNodeId + '-' + toNode.focusAreaNodeId;
      connector.source = fromNodeId;
      connector.target = toNode.focusAreaNodeId;

      // define extra data such as has alert
      // so we can use this when rendering the edge
      let data = {};
      data.float = toNode.float;
      data.lag = toNode.lag;
      data.relationship = toNode.relationship;
      data.isDrivingPath = toNode.isDrivingPath;
      connector.data = data;

      elems.push(connector);
    }
    )
  };

  /**
  * Processes the driving path data into elements for the 
  * consumption by the driving path diagram
  * @param {*} wbss
  * @returns 
  */
  const processDrivingPath = (wbss) => {
    var elems = [];
    for (let t of wbss) {
      var e = {};
      e.id = t.focusAreaNodeId;
      e.type = t.nodeType.toLowerCase();
      let data = {};
      data.id = t.focusAreaNodeId;
      data.label = t.name;
      data.discipline = t.discipline;
      data.package = t.package;
      data.phase = t.phase;
      data.earliestStartDate = t.earliestStartDate;
      data.latestStartDate = t.latestStartDate;
      data.isDrivingPath = t.isDrivingPath;
      data.earliestFinishDate = t.earliestFinishDate;
      data.latestFinishDate = t.latestFinishDate;
      data.successors = t.focusAreaNodeSuccessor;

      e.data = data;
      elems.push(e);

      // check for successors and 
      // add connectors for them
      if (t.hasOwnProperty('focusAreaNodeSuccessor') && t.focusAreaNodeSuccessor !== null) {
        addConnector(wbss, elems, t.focusAreaNodeSuccessor, e.id);
      }
    }

    return elems;
  }

  /**
   * Processes the driving path data into elements for the 
   * consumption by the driving path table
   * @param {*} rawData
   * @returns 
   */
  const processDrivingPathTable = (rawData) => {
    var drivingPathArr = [];
    for (let t of rawData) {
      var eleObj = {};
      eleObj.focusAreaNodeId = t.focusAreaNodeId;
      eleObj.type = t.nodeType;
      eleObj.predictedStartDate = t.predictedStartDate;
      eleObj.predictedEndDate = t.predictedEndDate;
      eleObj.nodeCode = t.nodeCode;
      eleObj.duration = t.duration.toString();
      eleObj.discipline = t.discipline;
      eleObj.package = t.package;
      eleObj.phase = t.phase;
      eleObj.name = t.name;
      eleObj.isDrivingPath = t.isDrivingPath;

      drivingPathArr.push(eleObj);
    }

    return drivingPathArr;
  }

  return (
    <>
    {isInterfacePointDrivingPathLoading &&
      <CircularProgressBar />
    }
    {interfacePointdrivingPathError && !isInterfacePointDrivingPathLoading &&
      <ErrorMessage message="Unable to display driving path for selected interface" />
    }
    {!isInterfacePointDrivingPathLoading && !interfacePointdrivingPathError &&
      <>
      <div className="driving-path-containar">
        {totalCount && totalCount > 100 && 
          <InterfacePointDrivingPathCountFlag selectedInterfacePointNumber={selectedInterfacePointNumber} />
        }
        <div className="driving-path-diagram-container">
          <InterfacePointDrivingPathDiagram drivingPathData={processDrivingPath(drivingPathNodes)} />
        </div>
        <div className="driving-path-table-container">
          <InterfacePointDrivingPathTable drivingPathData={processDrivingPathTable(drivingPathNodes)} />
        </div>
      </div>      
      </>
    }
  </>
  );
}

export default InterfacePointDrivingPathContainer;