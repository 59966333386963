import React from 'react';
import { getPhaseNodeProgressColour, getDesignProgressColour } from '../../../../../utils';
import './LinearProgressBar.scss';

const LinearProgressBar = (props) => {
  const { actualPercentageComplete = null, plannedPercentageComplete = null, designProgress = false, actualBarColour = undefined } = props;

  const actualPercentageCompleteString = `${Math.min(actualPercentageComplete, 100)}%`;
  const plannedPercentageCompleteString = `${Math.min(plannedPercentageComplete, 100)}%`;

  const projectedColourSingle = "#7262FD";

  let currentColour = "";
  let projectedColour = "#CCD2DA";

  if (actualBarColour){
    currentColour = actualBarColour;
  }
  else{
    
    projectedColour = projectedColourSingle;
  
    if (designProgress){
      currentColour = getDesignProgressColour(actualPercentageComplete, plannedPercentageComplete);
    }
    else{
      currentColour = getPhaseNodeProgressColour(actualPercentageComplete, plannedPercentageComplete);
    }
  }
  

  return (
    <div className="linear-progress-bar-container">
      { actualPercentageComplete > 0 && <div className="linear-progress-bar-actual" style={{ width: actualPercentageCompleteString, backgroundColor: currentColour, zIndex: actualPercentageComplete > plannedPercentageComplete ? 2 : 3}}></div> } 
      { plannedPercentageComplete > 0 && !designProgress && <div className="linear-progress-bar-predicted" style={{ width: plannedPercentageCompleteString, backgroundColor: projectedColour, zIndex: plannedPercentageComplete > actualPercentageComplete ? 2 : 3}}></div> }
    </div>
  );
}

export default LinearProgressBar;