import React from 'react';
import { TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SearchToolbarScenario.scss';

const SearchToolbarScenario = (props) => {
  const { labelName } = props;

  /**
   * Handles search change
   * @param {object} e 
   */
  const handleSearch = (e) => {
    props.processSearch(e.target.value);
  }

  return (
    <div className="scenarios-filter-container">
      <div className='search-container'>
        <TextField className="search-user-grid-text"
          label={labelName}
          onChange={handleSearch}
          InputLabelProps={{ shrink: true }}
          placeholder='Enter Search...'
          InputProps={{ disableUnderline: true, style: { fontSize: 14 }, endAdornment: <FontAwesomeIcon icon="search" className='icon' /> }} />
      </div>
    </div>
  );
}


export default SearchToolbarScenario;