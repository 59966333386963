import { 
    createEntityAdapter, 
    createSlice,
    createAsyncThunk 
  } from '@reduxjs/toolkit';
  import { api } from '../../api';
  import { updateScenarioCommentEndpoint, getScenarioCommentEndpoint } from '../../api/apiRoutes';
  
  export const updateScenarioComment = createAsyncThunk(
    'scenario/updatescenariocomment', async (params, thunkAPI) => {
      try {
        const response = await api.put(updateScenarioCommentEndpoint(params.projectId, params.scenarioId), params.comment, { headers: { Authorization: `Bearer ${params.accessToken}` } });
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const getScenarioComment = createAsyncThunk(
    'scenario/getscenariocomment', async (params, thunkAPI) => {
      try {
        const response = await api.get(getScenarioCommentEndpoint(params.projectId, params.scenarioId), { headers: { Authorization: `Bearer ${params.accessToken}` } });
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  
  
  /**
   * get and Create scenario comment slice / reducers
   */
  const ScenarioCommentSlice = createSlice({
    name: 'ScenarioComment',
    initialState:  { isLoading: false,
                     error: undefined,
                     comment: undefined } ,
    extraReducers: (builder) => {
      builder
        .addCase(updateScenarioComment.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(updateScenarioComment.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          state.comment = action.payload.comments;
        })
        .addCase(updateScenarioComment.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
        .addCase(getScenarioComment.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(getScenarioComment.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          state.comment = action.payload.comments;
        })
        .addCase(getScenarioComment.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
    }
  });
  
  /**
   * Export reducer
   */
  export default ScenarioCommentSlice.reducer;