import { 
    createSlice,
  } from '@reduxjs/toolkit';
  
  /**
   * Create scenarios options comparison slice / reducers
   */
  const scenarioOptionsComparisonSlice = createSlice({
    name: 'scenarioOptionsComparison',
    initialState: { selectedScenarioOptionId: undefined },
    reducers: {
      setSelectedScenarioOptionId: (state, action) => {
        state.selectedScenarioOptionId = action.payload;
      }
    }
  });
  
  /**
   * Scenarios options comparison actions
   */
  export const {  setSelectedScenarioOptionId,
                } = scenarioOptionsComparisonSlice.actions;
  
  /**
   * Export reducer
   */
  export default scenarioOptionsComparisonSlice.reducer;