import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';

import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import InfoMessage from '../../../../../components/Shared/molecules/InfoMessage';
import DisciplineWeeklyResourcing from '../../molecules/DisciplineWeeklyResourcing/DisciplineWeeklyResourcing';

const CreateScenarioCapacityContainer = (props) => {
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);


  return (
    <>
      <DisciplineWeeklyResourcing />
    </>
  );
}

export default CreateScenarioCapacityContainer;