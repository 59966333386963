import React, { useEffect, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FilterToolbarFocusArea.scss';

const FilterToolbarFocusArea = (props) => {
  const dateFilterRef = React.useRef();
  const { dateFilterOptions, dateFilterFields, labelName } = props;
  const [dateFilterValue, setDateFilterValue] = useState(dateFilterOptions.find(d => d.default === true)?.value);

  /**
   * Selected date filter changed
   */
  useEffect(() => {
    props.processUpdateDateFilter(dateFilterValue);
  }, [dateFilterValue]);

  /**
   * Handles the date filter change
   * @param {object} e 
   */
  const handleDateFilterChange = (e) =>{
    setDateFilterValue(e.value);
  }

  /**
   * Handles search change
   * @param {object} e 
   */
  const handleSearch = (e) => {
    props.processSearch(e.target.value);
  }

  return (
    <div className="interfaces-filter-container">
      <label>{labelName}</label>
      <div className='search-container'>
        <TextField className="search-interface-grid-text"
          onChange={handleSearch}
          InputLabelProps={{ shrink: true }}
          placeholder='Enter Search...'
          InputProps={{ disableUnderline: true, style: { fontSize: 14 }, endAdornment: <FontAwesomeIcon icon="search" className='icon' /> }} />
      </div>
      <label>Filter By:</label>
      <div className='date-filter-container'>
        <DropDownListComponent 
          dataSource={dateFilterOptions}
          fields={dateFilterFields}
          ref={dateFilterRef}
          onChange={handleDateFilterChange}
          value={dateFilterValue}
          InputLabelProps={{ shrink: true }}>
        </DropDownListComponent>
      </div>
    </div>
  );
}


export default FilterToolbarFocusArea;