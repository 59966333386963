import React from 'react';
import { TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import './ActivityCalculationHeader.scss';

const ActivityCalculationHeader = (props) => {
  const { handleSearch, handleFilterAdd, handleFilterRemove, filterSelectionOptions, filterFields } = props;
  const multiSelectFilter = React.useRef();


  return (
    <div className='packageSettings-toolbar-container'>
    <div className='toolbar-container'>
        <div className='show-container-settings'>
            <MultiSelectComponent id='filterSelectionList'
                ref={multiSelectFilter}
                filterBarPlaceholder="All"
                placeholder='Filter'
                floatLabelType='Always'
                tagging={handleFilterAdd}
                removed={handleFilterRemove}
                dataSource={filterSelectionOptions}
                fields={filterFields}
                popupWidth='240px'
                popupHeight='500px'>
            </MultiSelectComponent>
        </div>
        <div className='search-container-settings'>
            <TextField className="search-user-grid-text"
                label='Enter Search' onChange={handleSearch} InputLabelProps={{ shrink: true }} placeholder = 'Enter Search...'
                InputProps={{ disableUnderline: true, style: { fontSize: 14, color:"#8D9DB4" }, endAdornment: <FontAwesomeIcon icon="search" className='search-icon-settings' /> }} />
        </div>
    </div>
    <div className='heading-container'>
        <div className='package-heading'>
           Select which methodology to use for Alerts earned value analysis:
        </div>
        <div className='radio-button-heading'>
            <div>Deliverables</div>
            <div>Activities</div>
       </div>
    </div>
    </div>
  )
}

export default ActivityCalculationHeader