import React, { useEffect, useState } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { selectProjectById } from '../../redux/slices/projectsSlice';
import { setSelectedProjectId} from '../../redux/slices/projectsSlice';
import './ProjectOverviewPage.scss';
import {appInsights} from '../../AppInsights';
import './ProjectOverviewPage.scss';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ProjectProgress from './components/organisms/ProjectProgress/ProjectProgress';
import ManageTeam from './components/organisms/ManageTeam/ManageTeam';
import Popover from '@material-ui/core/Popover';
import AddUserWidgetContainer from './components/organisms/AddUserWidgetContainer';
import PageHeader from '../../components/Shared/organisms/PageHeader';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { fetchUsers } from '../../redux/slices/usersSlice';
import BuildingAnimation from '../../components/Shared/atoms/BuildingAnimation';
import ProjectSettingWidget from './components/organisms/ProjectSettingWidget';
import SettingsButton from './components/atoms/SettingsButton';
import EditUserWidgetContainer from './components/organisms/EditUserWidgetContainer';
import LighthouseDialog from '../../components/Shared/organisms/LighthouseDialog';
import { useSnackbar } from 'notistack';
let trackData = require('../../appTrackerInfo.json');
var classNames = require('classnames');

function TabPanel(props) {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


export const ProjectOverviewPage = (props) => {
  const { match: { params }, } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { LHRoles, projects } = useSelector(state => state.authorization);
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [firstname, setFirstname] = useState(null);
  const dispatch = useDispatch();
  const [value, setValue] = useState("Progress");
  const [anchorEl, setAnchorEl] = useState(null);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [settingsModalvisibility, setSettingsModalvisibility] = useState(false);
  const { enqueueSnackbar } = useSnackbar();


  const handleModalClose = () =>{
    setAnchorEl(null);
    setSettingsModalvisibility(false);
  };

  const handleModalOpen = (event) => {
    setAnchorEl(event);
    setSettingsModalvisibility(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAddOpen(false);
    setEditOpen(false);
  };

  const handleAddUser = (event) => {
    setAnchorEl(event);
    setAddOpen(true);
  }

  const handleEditUser = (event) => {
    setEditOpen(true);
  }

  const handleFetchUsersClose = () =>{
    setEditOpen(false);
    toastNotificationHandle(1,'User profile updated successfully.')
    dispatch(fetchUsers({ projectId: selectedProjectId, accessToken: accessToken}));
  }

  const handleAddUsersClose = (addedStatus) =>{
    setAddOpen(false);
    toastNotificationHandle(1, addedStatus )
    dispatch(fetchUsers({ projectId: selectedProjectId, accessToken: accessToken}));
  }

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  useEffect(() =>{
    if (params.projectId){
      dispatch(setSelectedProjectId(params.projectId));
    }
  }, []);

  useEffect(() =>{
    if (accessToken && params.projectId){
      dispatch(fetchUsers({ projectId: selectedProjectId, accessToken: accessToken}));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  useEffect(() => {
    if (account && !selectedProject) {
      appInsights.trackPageView({
        name:`${trackData.pages[0].name}`,   
        properties: { 
                   Project: 'Selection',
                   ProjectName: 'Selection',
                   User:`${account.username}`,
                   Username: `${account.name}`,
                   Role: 'Selection'  
        }});
      setFirstname(account["idTokenClaims"]["given_name"]);
    }
    if (account && selectedProject) {
      appInsights.trackPageView({
        name:`${trackData.pages[0].name}`,   
        properties: { 
                   Project: `${selectedProjectId}`,
                   ProjectName: `${selectedProject?.projectName}`,
                   User:`${account.username}`,
                   Username: `${account.name}`,
                   Role: `${LHRoles.find( x => x === 'GlobalAdmin')?'GlobalAdmin' : LHRoles[projects?.indexOf(selectedProjectId.toUpperCase())]?.toString()}` 
        }});
      setFirstname(account["idTokenClaims"]["given_name"]);
    }
  }, [account, selectedProject]);

  const OverviewTabs = withStyles({
    root: {

    },
    indicator: {
        backgroundColor: 'var(--color-additional)',
        marginBottom: '7px',
        height: '3px'
      }
  })(Tabs);
  
  const OverviewTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 'fit-content',
      maxWidth: null,
      fontWeight: '450',
      padding: '20px',
      color: 'var(--color-tab-text)',
      '&:hover': {
        color: 'var(--color-tab-text)'
      },
      '&$selected': {
        color: 'var(--color-tab-text)'
      },
      '&:focus': {
        color: 'var(--color-tab-text)',
      },
    },
    selected: {},
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
  }))((props) => <Tab disableRipple {...props} />);

    /**
* Show user modification Toast Notification
* @param {int} flag 
* @param {string} toastText 
*/
 const toastNotificationHandle = (flag, toastText) =>{
  if(flag === 1){
   enqueueSnackbar(toastText, { variant: 'success', style: { whiteSpace: 'pre-line' } , preventDuplicate: true }, ) ;
  }
  if(flag === 2){
   enqueueSnackbar(toastText, { variant: 'warning', style: { whiteSpace: 'pre-line' } , preventDuplicate: true }, ) ;
  }
}

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="project-overview-page" data-testid="project-overview-page">
      {selectedProject &&
        <>
           <div className="project-overview-page-header">
            <PageHeader pageTitle="Project Home" projectName='project-home'>
                <SettingsButton modalOpen={handleModalOpen}/>
             </PageHeader>
             <Popover
                  id='project-settings'
                  open={settingsModalvisibility}
                  onClose={handleModalClose}
                  anchorEl={anchorEl}   
                  anchorReference='none'
                  BackdropProps={{ invisible: false }}
                >
                  <div className="project-settings-popover">
                  <ProjectSettingWidget handleModalClose={handleModalClose} />
                  </div>
             </Popover>
           </div>
        <div className="overview-main">
              <div className={classNames('overview-container')}>
          <div className="overview-header">
          <OverviewTabs value={value} variant="fullWidth" indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
            <OverviewTab label={<span className="tab-label">Summary</span>} value="Progress" />
            <OverviewTab label={<span className="tab-label">Team Members</span>} value="Team" />
          </OverviewTabs>
          </div>
            <TabPanel value={value} index="Progress" className="overview-section" >
              <ProjectProgress handleModalOpen={handleModalOpen}/>
            </TabPanel>
            <TabPanel value={value} index="Team" className="overview-section">
               <ManageTeam closeWidget={handleClose} addUserWidget={handleAddUser} editUserWidget={handleEditUser} />
                 <Popover
                  id='add-user'
                  open={addOpen}
                  anchorEl={anchorEl}   
                  anchorReference='none'
                  BackdropProps={{ invisible: false }}
                >
                  <div className="add-user-popover">
                    <AddUserWidgetContainer closeWidget={handleClose} toastNotificationHandle={toastNotificationHandle} handleAddUsersClose={handleAddUsersClose}  />
                  </div>
                </Popover>
                {editOpen && 
                 <LighthouseDialog isVisible={editOpen} closeButton={true} closeButtonAction={handleClose} title="Edit Team Member" width="46%" height="621px">
                   <EditUserWidgetContainer closeWidget={handleClose} toastNotificationHandle={toastNotificationHandle} handleFetchUsersClose={handleFetchUsersClose} />
                 </LighthouseDialog>
                } 
              </TabPanel>
             </div>
        </div>
        </>
        }
        {!selectedProject && 
          <>
            <div className="welcome">
              <div className="lh-logo-container">
                <BuildingAnimation />
              </div>
              <h3>Welcome {firstname}</h3>
              <p>The Lighthouse is a decision support tool for project teams to identify and mitigate issues you may face during design.</p>
              <br/>
              <p>Please select a project from the project selector on the left to continue.</p>
            </div>
          </>
        }
    </div>
  );
};

export default ProjectOverviewPage;