import React from 'react';
import { useSelector } from "react-redux";
import { HeatMapComponent, Inject, Legend, Adaptor, Tooltip } from '@syncfusion/ej2-react-heatmap';

import './ScenarioOptionsChart.scss';

const ScenarioOptionsChart = (props) => {
  const { data } = props;
  const { sidebarOpen } = useSelector(state => state.projects);

  const tooltipRender = (args) =>{
    // get the data item from the original dataset
    let filteredDataItem = data.data.find(dataItem => dataItem.weeklyDate === args.xValue && dataItem.disciplineName === args.yValue);

    if (filteredDataItem){
      args.content = [
        'Schedule Changes: ' + filteredDataItem.scheduleChanges + '<br/>Capacity Changes: ' + filteredDataItem.capacityChanges + '<br/>Week  Commencing: ' + filteredDataItem.weeklyDate
      ]
    }
    else{
      args.content = [
        args.value
      ]
    }
  }

  return (
    <HeatMapComponent id='heatmap' width={sidebarOpen?'1220px':'1400px'} height='485px'
      titleSettings={{
        textStyle: {
          size: '20px',
          fontWeight: '500',
          fontStyle: 'Normal',
          fontFamily: 'Segoe UI'
      }
    }} xAxis={{
      labels: data.weeklyDates,
      //opposedPosition: true,
      labelRotation : '277'
    }} yAxis={{
      labels: data.disiplines,
      color : '#333333',
      border: {
        width: '2',
        color:'#F4F9FD'
        },
    }} dataSource={data.data}
    dataSourceSettings={{
        isJsonData: true,
        adaptorType: 'Cell',
        yDataMapping: 'disciplineName',
        xDataMapping: 'weeklyDate',
        valueMapping: 'colorWaitage',
      }}
      paletteSettings={{
        palette: [
         { value : 0 , color: '#ACE9AE' },
         { value : .25 ,  color: '#E8C95E' },
         { value : .50 ,  color: '#D0A128' },
         { value : .75 ,  color: '#D76E6C' },
         { value : 1 ,  color: '#971210' }
        ],
        type: "Fixed"
      }} 
      legendSettings={{
        position: 'Top',
        alignment: 'Far',
        width: '250px',
        enableSmartLegend: true,
        height: '85px'
    }}
      cellSettings={{
        border: {
          width: '2',
          color:'white'
          },
        showLabel: false,
        enableCellHighlighting: true
      }}
      tooltipRender={tooltipRender}>
      <Inject services={[Legend, Tooltip, Adaptor]} />
    </HeatMapComponent>
  );
}

export default ScenarioOptionsChart;