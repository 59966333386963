import React, { useEffect, useState } from 'react';
import { SecondaryLighthouseButton } from '../../../../../../theme/Buttons';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { selectAllScenarios } from '../../../../../../redux/slices/scenariosSlice';
import { selectAlertById } from '../../../../../../redux/slices/alertsSlice';
import { setLastCreatedScenarioId } from '../../../../../../redux/slices/createScenarioSlice';
import { useDispatch } from "react-redux";
import '../../CreateScenarioWizardSteps/CreateScenarioWizardSteps.scss';
var classNames = require('classnames');

const CreateScenarioFromAlert = (props) => {
  const { isDialog, closeCreateScenario, alertId } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const { lastCreatedScenarioId } = useSelector(state => state.createScenario);
  const scenarios = useSelector(selectAllScenarios);
  const selectedAlert = useSelector((state) => selectAlertById(state, alertId));
  const [redirectToScenarios, setRedirectToScenarios] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * When the last created scenario id gets updated 
   * displays a message and sets to redirect to comparison
   * screen if this is not a dialog, clear the last created scenario id
   */
  useEffect(() =>{
    if (lastCreatedScenarioId){
      let createdScenario = scenarios.filter(scenario => scenario.scenarioId === lastCreatedScenarioId);

      if (!isDialog){
        enqueueSnackbar('You have created a scenario for '+ selectedAlert.alertName + '.\nYou can view your scenario in the Scenario Manager.', { variant: 'info', style: { whiteSpace: 'pre-line' }}, );
        setRedirectToScenarios(true);
      }
    }
  }, [lastCreatedScenarioId])

  /**
   * Handles the close button click
   */
  const handleCloseClick = () => {
    closeCreateScenario();
  }

  if (redirectToScenarios && !isDialog) {
    return (
      // redirect to the option comparison
      <Redirect to={`/${selectedProjectId}/scenarios/${lastCreatedScenarioId}/compareoptions`} /> 
    )
  }
  else {
    return (
      <div className="scenario-wizard-wrapper" className={classNames('scenario-wizard-wrapper', { "no-dialog": !isDialog })}>
        <div className="wizard-content">
          <p>You have created a scenario for {selectedAlert.alertName}.</p>
          <p>You can view your scenario in the Scenario Manager after closing this dialog.</p>
        </div>
        <div className="wizard-actions">
          <div>
          </div>
          <div>
            <SecondaryLighthouseButton onClick={handleCloseClick}>
              Close
            </SecondaryLighthouseButton>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateScenarioFromAlert;