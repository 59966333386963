import React from 'react';
import { useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import InterfaceFullProgramme from '../InterfaceFullProgramme/InterfaceFullProgramme';
import { selectAllFullActivitySchedule } from '../../../../../redux/slices/fullActivityScheduleSlice';


const InterfaceFullProgrammeContainer = (props) => {
    const fullActivitySchedule = useSelector((state) => selectAllFullActivitySchedule(state));
    const { isLoading, error } = useSelector(state => state.fullActivitySchedule);

    /**
     * Cancel selecting Full Activity Link
     */
     const closeFullProgramme = () => {
      props.closeFullProgramme();
    }
  
    /**
     * selects a full activity to create the Interface
     */
    const selectActivityLink = (selectedActivity) => {
      props.selectActivityLink(selectedActivity);
    }
  
    return (
      <>
        {isLoading &&
          <CircularProgressBar />
        }
        {!isLoading && error &&
          <ErrorMessage message="Unable to display full activity schedule" />
        }
        {!isLoading && !error &&
        <>
          <InterfaceFullProgramme fullActivitySchedule={fullActivitySchedule} selectActivityLink={selectActivityLink} closeFullProgramme={closeFullProgramme}/>
        </>
        }
      </>
    );
  }

export default InterfaceFullProgrammeContainer;