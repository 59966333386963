import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { disciplinesEndpoint, disciplineResourceUsageEndpoint, disciplineResourceCapacityEndpoint, disciplinesResourceCapacityEndpoint } from '../../api/apiRoutes';

/**
 * Fetches disciplines
 * @returns disciplines
 */
 export const fetchDisciplines = createAsyncThunk(
  'discipline/fetchdisciplines', async (params, thunkAPI) => {
     try {
        const response = await api.get(disciplinesEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Gets resource usage for a project discipline
 */
 export const fetchDisciplineResourceUsage = createAsyncThunk(
  'discipline/fetchresourceusage', async (params, thunkAPI) => {
    try {
      const response = await api.get(disciplineResourceUsageEndpoint(params.projectId, params.disciplineId), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
});
/**
 * Gets resource capacity for a project discipline
 */
 export const fetchDisciplineResourceCapacity = createAsyncThunk(
  'discipline/fetchresourcecapacity', async (params, thunkAPI) => {
    try {
      const response = await api.get(disciplineResourceCapacityEndpoint(params.projectId, params.disciplineId), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
});

/**
 * Gets resource capacity and balanced requirement for a project
 */
 export const fetchDisciplinesResourceCapacity = createAsyncThunk(
  'discipline/fetchresourcecapacitybyproject', async (params, thunkAPI) => {
    try {
      const response = await api.get(disciplinesResourceCapacityEndpoint(params.projectId, params.scenarioId, params.scenarioOptionId), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
});


/**
 * Create discipline adapter
 */
const disciplineAdapter = createEntityAdapter({
  selectId: (discipline) => discipline.disciplineId,
})

/**
 * Create discipline slice / reducers
 */
const disciplinesSlice = createSlice({
  name: 'disciplines',
  initialState: disciplineAdapter.getInitialState( {  selectedDisciplineId: undefined,
                                                      isAllocationLoading: false,
                                                      allocationError: undefined,
                                                      filteredDisciplineIds: [],
                                                      isLoading: false,
                                                      error: undefined,
                                                      isCapacityLoading: false,
                                                      capacityError: undefined,
                                                      resourceUsage: undefined,
                                                      resourceCapacity: undefined } ),
  reducers: {
    setSelectedDisciplineId: (state, action) => {
      //console.log("selected discipline id:", action);
      state.selectedDisciplineId = action.payload;
    },
    setFilteredDisciplineIds: (state, action) => {
      state.filteredDisciplineIds = action.payload;
    },
    removeAlldisciplines: (state) => {
      disciplineAdapter.removeAll(state);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDisciplines.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchDisciplines.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        disciplineAdapter.setAll(state, action.payload);
      })
      .addCase(fetchDisciplines.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(fetchDisciplineResourceUsage.pending, (state, action) => {
        state.isAllocationLoading = true;
        state.allocationError = undefined;
        state.resourceUsage = undefined;
      })
      .addCase(fetchDisciplineResourceUsage.fulfilled, (state, action) => {
        state.isAllocationLoading = false;
        state.allocationError = undefined;
        state.resourceUsage = action.payload;
      })
      .addCase(fetchDisciplineResourceUsage.rejected, (state, action) => {
        state.isAllocationLoading = false;
        if (action.payload) {
          state.allocationError = action.payload.error;
        }
        else {
          state.allocationError = action.error.message;
        }
      })
      .addCase(fetchDisciplineResourceCapacity.pending, (state, action) => {
        state.isCapacityLoading = true;
        state.capacityError = undefined;
        state.resourceCapacity = undefined;
      })
      .addCase(fetchDisciplineResourceCapacity.fulfilled, (state, action) => {
        state.isCapacityLoading = false;
        state.capacityError = undefined;
        state.resourceCapacity = action.payload;
      })
      .addCase(fetchDisciplineResourceCapacity.rejected, (state, action) => {
        state.isCapacityLoading = false;
        if (action.payload) {
          state.capacityError = action.payload.error;
        }
        else {
          state.capacityError = action.error.message;
        }
      })
      .addCase(fetchDisciplinesResourceCapacity.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
        state.resourceCapacityByProject = undefined;
      })
      .addCase(fetchDisciplinesResourceCapacity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.resourceCapacityByProject = action.payload;
      })
      .addCase(fetchDisciplinesResourceCapacity.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = action.payload.error;
        }
        else {
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Discipline selectors
 */
 export const {
  selectAll: selectAllDisciplines,
  selectById: selectDisciplineById,
} = disciplineAdapter.getSelectors((state) => state.disciplines);

/**
 * Discipline actions
 */
 export const {  
  setSelectedDisciplineId,
  setFilteredDisciplineIds,
  setAllDisciplines,
  removeAlldisciplines
} = disciplinesSlice.actions;

/**
 * Export reducer
 */
export default disciplinesSlice.reducer;