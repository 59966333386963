import { withStyles } from '@material-ui/core/styles';
import { useAccount, useMsal } from "@azure/msal-react";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaChartBar, FaProjectDiagram } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import { appInsights } from '../../AppInsights';
import { setSelectedProjectId, selectProjectById } from '../../redux/slices/projectsSlice';
import DesignProductionViewerChartContainer from './components/organisms/DesignProductionViewerChartContainer';
import DesignProductionViewerTableContainer from './components/organisms/DesignProductionViewerTableContainer';
import DesignProductionViewerContainer from './components/organisms/DesignProductionViewerContainer';
import DesignProductionViewerProgressContainer from './components/organisms/DesignProductionViewerProgressContainer';
import DisciplineFilter from './components/organisms/DisciplineFilter/DisciplineFilter';
import InformationPanel from './components/organisms/InformationPanel';
import { useSnackbar } from 'notistack';
import PageHeader from '../../components/Shared/organisms/PageHeader';
import './ProjectDesignStatusPage.scss';

let trackData = require('../../appTrackerInfo.json');
var classNames = require('classnames');

function DpvTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dpv-tabpanel-${index}`}
      aria-labelledby={`dpv-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

DpvTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const DpvTabs = withStyles({
  root: {

  },
  indicator: {
      backgroundColor: 'var(--color-additional)',
      marginBottom: '7px',
      height: '3px'
    }
})(Tabs);

const DpvTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 'fit-content',
    maxWidth: null,
    fontWeight: '450',
    padding: '20px',
    color: 'var(--color-tab-text)',
    '&:hover': {
      color: 'var(--color-tab-text)'
    },
    '&$selected': {
      color: 'var(--color-tab-text)'
    },
    '&:focus': {
      color: 'var(--color-tab-text)',
    },
  },
  selected: {},
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))((props) => <Tab disableRipple {...props} />);


export const ProjectDesignStatusPage = (props) => {
  const {
    match: { params },
  } = props;
  const [value, setValue] = React.useState("Chart");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [dpoCollapsed, setDpoCollapsed] = useState(true);
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { LHRoles, projects } = useSelector(state => state.authorization);

  useEffect(() => {
    if (params.projectId) {
      dispatch(setSelectedProjectId(params.projectId));
    }
  }, []);

  const handleDisciplineCollapseClick = () => {
    setDpoCollapsed(!dpoCollapsed);
  }


  /**
* Tab change event
* @param {*} event 
* @param {*} newValue 
*/
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

                useEffect(() => {
                  if (account && !selectedProject) {
                    appInsights.trackPageView({
                      name:`${trackData.pages[1].name}`,   
                      properties: { 
                                 Project: 'Selection',
                                 ProjectName: 'Selection',
                                 User:`${account.username}`,
                                 Username: `${account.name}`,
                                 Role: 'Selection'  
                      }});
                  }
                  if (account && selectedProject) {
                    appInsights.trackPageView({
                      name:`${trackData.pages[1].name}`,   
                      properties: { 
                                 Project: `${selectedProjectId}`,
                                 ProjectName: `${selectedProject?.projectName}`,
                                 User:`${account.username}`,
                                 Username: `${account.name}`,
                                 Role: `${LHRoles.find( x => x === 'GlobalAdmin')?'GlobalAdmin' : LHRoles[projects?.indexOf(selectedProjectId.toUpperCase())]?.toString()}` 
                      }});
                  }
                }, [account, selectedProject]);

  /**
* Show Activity change Toast Notification
* @param {int} flag 
* @param {int} activityCount 
*/
 const toastNotificationHandle = (flag, activityCount, taskCount, milestoneCount) =>{
   if(flag === 1){
     let toastText = '';
     if(activityCount === 0){
       toastText = 'No Activity Live Status change(s) made';
     }else if(activityCount > 0){
        if(taskCount > 0 && milestoneCount == 0)
          toastText = taskCount + ' ' + (taskCount > 1 ? 'Tasks':'Task') + " 'Live Status' " + (activityCount > 1 ? 'changes':'change') + ' made';
        else if(taskCount == 0 && milestoneCount > 0)
          toastText = milestoneCount + ' ' + (milestoneCount > 1 ? 'Milestones':'Milestone') + " 'Live Status' " + (activityCount > 1 ? 'changes':'change') + ' made';
        else if(taskCount > 0 && milestoneCount > 0)
          toastText = taskCount + ' ' + (taskCount > 1 ? 'Tasks, ':'Task, ') + milestoneCount + ' ' + (milestoneCount > 1 ? 'Milestones':'Milestone') + " 'Live Status' " + (activityCount > 1 ? 'changes':'change') + ' made';
     }
    enqueueSnackbar(toastText, { variant: 'success', style: { whiteSpace: 'pre-line' } , preventDuplicate: true }, ) ;
   }
   if(flag === 2){
    enqueueSnackbar('Something went wrong', { variant: 'warning', style: { whiteSpace: 'pre-line' } , preventDuplicate: true }, ) ;
   }
 }

  return (
    <>
      <div className="pds-page">
        <div className="pds-info-panel panel-shadow">
          <InformationPanel />
        </div>
        <div className="pds-main" style={{ height: '100vh' }}>
         <PageHeader pageTitle="Production"  margin="0" >
               <div className="dpv-discipline-filter">
                    <DisciplineFilter />
              </div>
         </PageHeader>
          <div className={classNames('dpv-container panel-shadow')}>
            <div className="dpv-header">
              <div className="dpv-tabs">
                <DpvTabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleTabChange}
                >
                  <DpvTab label={<span className="tab-label">&nbsp;&nbsp;Production Map</span>} value="Diagram" />
                  <DpvTab label={<span className="tab-label">&nbsp;&nbsp;Gantt Chart</span>} value="Chart" />
                  <DpvTab label={<span className="tab-label">&nbsp;&nbsp;Table View</span>} value="Table" />
                  <DpvTab label={<span className="tab-label">&nbsp;&nbsp;Progress</span>} value="Progress" />
                </DpvTabs>
              </div>
          
            </div>
            <DpvTabPanel value={value} index="Diagram" className="dpv-content">
              <DesignProductionViewerContainer />
            </DpvTabPanel>
            <DpvTabPanel value={value} index="Chart" className="dpv-content">
              <DesignProductionViewerChartContainer />
            </DpvTabPanel>
            <DpvTabPanel value={value} index="Table" className="dpv-content">
              <DesignProductionViewerTableContainer toastNotificationHandle={toastNotificationHandle}/>
            </DpvTabPanel>
            <DpvTabPanel value={value} index="Progress" className="dpv-content">
              <DesignProductionViewerProgressContainer />
            </DpvTabPanel>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDesignStatusPage;