import React, { useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import ActivityListAccordion from '../../molecules/ActivityListAccordion';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import InfoMessage from '../../../../../components/Shared/molecules/InfoMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ActivityList.scss';

const ActivityList = (props) => {
  const { activities, activityCompletionStatus, activityCompletionPipeline } = props;

  const [tasks, setTasks] = useState([]);
  const [milestones, setMilestones] = useState([]);

  const tasksCount = tasks.length;
  const milestonesCount = milestones.length;

  const {lengthAction} = useSelector(state => state.activities);

  const [notification, setNotification] = useState(true);

  useEffect(() => {
    // split the activities into two lists, tasks and milestones
    if (activities.length > 0){
      setTasks(activities.filter(activity => activity.activityType.toLowerCase() === "task"));
      setMilestones(activities.filter(activity => activity.activityType.toLowerCase() === "finmile" || activity.activityType.toLowerCase() === "milestone"  || activity.activityType.toLowerCase() === "finish milestone" ));
    }
    else{
      setTasks([]);
      setMilestones([]);
    }
    
  }, [activities]);

  const closeNotification = () =>{
    setNotification(false);
  }

  return (
    <div className="tsk-container">
      <div className="pv-title">
        <h4>Activities</h4>
      </div>
      <div className="tsk-list">
        {
          !tasks  &&
          <>
            <div className="pv-title">Tasks:</div>
            <ErrorMessage message="Unable to get tasks list" />
          </>
        }
        {
          tasksCount > 0 &&
          <>
            <div className="pv-title">Tasks:</div>
            { activityCompletionPipeline && activityCompletionPipeline.length > 0 && activityCompletionPipeline[0].activitiesCount > 0 && lengthAction == 'Default' && notification == true &&
              <>
                <div className="toast-container">
                  <div className="toast-header">
                    <div className="toast-icon-label-container">
                      <span className="span-warning-act"></span>
                      <span className="toast-warning-icon">
                        <FontAwesomeIcon icon="info-circle" />
                      </span>
                      <p>
                        The Activity State change is being processed, so please check back in an hour to see updated design progress metrics. 
                        Re-calculating alerts and scenarios can take a little longer, so please check back tomorrow.
                      </p>
                      <div className='toast-cancel-icon'>
                        <FontAwesomeIcon icon="times" onClick={closeNotification}/>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
            
            <ActivityListAccordion activities={tasks} type="Tasks" activityCompletionStatus={activityCompletionStatus}/>
          </>
        }{
          tasksCount < 1 &&
          <>
            <div className="pv-title">Tasks:</div>
            <InfoMessage message="No tasks found on this phase" />
          </>
        }
        
        {
          !milestones  &&
          <>
            <div className="pv-title">Work Package Milestones:</div>
            <ErrorMessage message="Unable to get milestones list" />
          </>
        }
        {
          milestonesCount > 0 &&
          <>
            <div className="pv-title">Work Package Milestones:</div>
            <ActivityListAccordion activities={milestones} type="Milestones" activityCompletionStatus={activityCompletionStatus}/>
          </>
        }
        {
          milestonesCount < 1 &&
          <>
            <div className="pv-title">Work Package Milestones:</div>
            <InfoMessage message="No milestones found on this phase" />
          </>
        }
      </div>
    </div>
  );
};

export default ActivityList;