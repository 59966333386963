import React from 'react';
import AlertListAccordian from '../../molecules/AlertListAccordian';
import Chip from '@material-ui/core/Chip';
import './AlertList.scss';

const AlertList = (props) => {
  const { primaryAlerts, secondaryAlerts, selectedAlert } = props;
  const primaryAlertCount = primaryAlerts.length;
  const secondaryAlertCount = secondaryAlerts.length;

  return (
    <div className="als-container">
      <div className="header-section">
        <h3>Alerts List</h3>
      </div>
      <p>The Lighthouse has identified the most critical alerts for your current view in the Viewer (descending order). Click to investigate further.</p>
      <div className="als-list">
        <div className="header-section">
          <h4>Primary Alerts:</h4>
          <div>
            <Chip className="counter" label={primaryAlertCount} />
          </div>
        </div>
        {!primaryAlerts.length && <p>No Primary alerts found!</p>}
        {primaryAlerts.length &&
          <AlertListAccordian alertData={primaryAlerts}
            isCriticalAlerts={true}
            selectedAlert={selectedAlert}
          />
        }
        <div className="header-section">
          <h4>Secondary Alerts:</h4>
          <div>
            <Chip className="counter" label={secondaryAlertCount} />
          </div>
        </div>
        {!secondaryAlerts.length && <p>No Secondary alerts found!</p>}
        {secondaryAlerts.length &&
          <AlertListAccordian alertData={secondaryAlerts}
            isCriticalAlerts={false}
            selectedAlert={selectedAlert}
          />
        }
      </div>
    </div>
  );
};

export default AlertList;