import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { setActivityChanges, removeActivityChange, selectAllActivityChanges, setIsChecked } from '../../../../../redux/slices/activityChangesSlice';
import './MultiSelectCheckBox.scss';

const MultiSelectCheckBox = (props) => {
    const { type, activityId, liveCompletionStatusId } = props;
    const dispatch = useDispatch();
    const { isCheckedValue } = useSelector(state => state.activityChanges);
    const activityChanges  = useSelector((state) => selectAllActivityChanges(state));

    const activitySelect = (event) => {
        const propValue = event.target.properties;
        if(propValue.checked){
            if(isCheckedValue !== 2){
                dispatch(setIsChecked(2));
            }
            if(!activityChanges.find(x=>x.activityId === activityId)){
              dispatch(setActivityChanges({activityId: propValue.value, activityType: type, completionStatusId: liveCompletionStatusId, liveStatusId: liveCompletionStatusId }));
            }
        }else{
          dispatch(removeActivityChange(propValue.value));
          if(activityChanges.length === 1){
            dispatch(setIsChecked(0));
          }
        }
      }

  const checkboxCheck = (actId) =>{
        if(activityChanges?.filter(act => act.activityId === actId).length > 0 && isCheckedValue === 2){
          return true;
        }
        return false;
    }

  return (
  <>
      {type === 'Task' && 
        <CheckBoxComponent checked={checkboxCheck(activityId)} onChange={activitySelect} value={activityId} labelPosition="After"/>   
      }
      {(type === 'Milestone' || type === 'Finish Milestone') && 
        <CheckBoxComponent checked={checkboxCheck(activityId)} onChange={activitySelect} value={activityId} labelPosition="After"/>   
      }
  </>

  )
}

export default MultiSelectCheckBox;