import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { fetchAlerts, selectAllAlerts, fetchAlertStatuses } from '../../../../../redux/slices/alertsSlice';
import { fetchDisciplines, selectAllDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import { fetchPackages, selectAllPackages } from '../../../../../redux/slices/packagesSlice';
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';
import { fetchPhases  } from '../../../../../redux/slices/phasesSlice';
import AlertManagerSelector from '../AlertManagerSelector/AlertManagerSelector';
import FilterToolbarAlert from '../../../../../components/Shared/molecules/FilterToolbarAlert';

import './AlertManagerSelectorContainer.scss';

const AlertManagerSelectorContainer = (props) => {
  const dispatch = useDispatch();
  const { error, isLoading, statuses } = useSelector(state => state.alerts);
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const alerts = useSelector(selectAllAlerts);
  const disciplines = useSelector(selectAllDisciplines);
  const packages = useSelector(selectAllPackages);
  const { selectedAlertIdInManager } = useSelector(state => state.alertSelection);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const categoryFields = { text: "text", value: "value", groupBy: "category" };
  const [filterSelectionOptions, setFilterSelectionOptions] = useState([]);
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

    /**
  * Fetches the alerts, alert statuses, discipline and packages for the selected project
  */
     useEffect(() => {
      if (accessToken !== null) 
      {
        dispatch(fetchAlerts({ projectId: selectedProjectId, accessToken: accessToken })).then(() => {
          dispatch(fetchPhases({ projectId: selectedProjectId, accessToken: accessToken })).then(() => {
            dispatch(fetchPackages({ projectId: selectedProjectId, accessToken: accessToken })).then(() => {
              dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken })).then(() => {
                if(statuses == undefined || statuses.length == 0)
                {
                  dispatch(fetchAlertStatuses({ projectId: selectedProjectId, accessToken: accessToken }));
                }
              })
            })
          })
        })
      }
    }, [dispatch, selectedProjectId, accessToken]);
  
    /**
   * Updates Discipline/ Statuses/ Packages filter list
   */
    useEffect(() => {
      if (packages.length > 0) {
        let statusOptions = [];
        let disciplineOptions = [];
        let packageOptions = [];
        let filterOptions = [
          { text: 'Primary', value: 'primary', category: 'Alert Type', filterProperty: 'alertType' },
          { text: 'Secondary', value: 'secondary', category: 'Alert Type', filterProperty: 'alertType' },
        ];;
  
        statuses?.forEach((ss) => {
          let scenarioStatus = {
            text: ss.status,
            value: ss.status.toLowerCase(),
            category: 'Status',
            filterProperty: 'alertStatus'
          }
          statusOptions.push(scenarioStatus);
        })
  
        filterOptions = filterOptions.concat(statusOptions);
  
        disciplines.forEach((d) => {
          let item = {
            text: d.disciplineName,
            value: d.disciplineId,
            category: 'Discipline',
            filterProperty: 'disciplineId'
          }
          disciplineOptions.push(item);
        })
  
        filterOptions = filterOptions.concat(disciplineOptions);
  
        packages.forEach((p) => {
          let item = {
            text: p.packageName,
            value: p.packageId,
            category: 'Package',
            filterProperty: 'packageId'
          }
          packageOptions.push(item);
        })
  
        filterOptions = filterOptions.concat(packageOptions);
  
        if (disciplines.length > 0 && statuses?.length > 0 && packages.length > 0) {
          setFilterSelectionOptions(filterOptions);
        }
      }
    }, [statuses])
  
    useEffect(() => {
      if (filterSelectionOptions.length > 0) {
        setIsReady(true);
      }
    }, [filterSelectionOptions])
  
  
    const handleUpdateFilter = (e) => {
      setSelectedFilterTypes(e);
    }
  
    const handleSearch = (searchText) => {
      setSearchText(searchText);
    }

    /**
   * Processes and filters the raw alert data
   * with filter and sort options
   * @param {object} rawData 
   * @returns 
   */
     const processAlerts = (rawData) => {
      let searchedData = [];
      if(rawData.length > 0){
      if (searchText && searchText.length > 0) {
        searchedData = rawData.slice().filter((d) => d.alertName.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
      } else {
        searchedData = rawData.slice();
      }
  
      /*
        Filtering the Data
      **/
      let filtered = searchedData;
  
      // create the filter groups, arrays of filter types
      const groupedFilterTypes = selectedFilterTypes.reduce((group, filterType) => {
        const { filterProperty } = filterType;
        group[filterProperty] = group[filterProperty] ?? [];
        group[filterProperty].push(filterType.value);
        return group;
      }, {});
  
      // loop the filter groups if there are any
      if (Object.keys(groupedFilterTypes).length > 0) {
        for (const [filterProperty, filterValues] of Object.entries(groupedFilterTypes)) {
  
          // filter based on the filter group values
          const filteredGroupResult = filtered.filter(item => {
            return filterValues.includes(item[filterProperty].toLowerCase());
          });
  
          // update the filtered data to the filter group result
          filtered = filteredGroupResult;
        }
      }
      else {
        // skip filter and set to the search data
        filtered = searchedData;
      }

      // return the sorted data
      return filtered;
    }else{
      return searchedData;
    }
    }

  return (
    <>
      {!isReady &&
        <CircularProgressBar />
      }
      { isReady && isLoading &&
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to display design alert manager" />
      }
      { !error && !isLoading && alerts &&
        <>
         <div className="alert-manager-panel-top">
         <div className="header-section">Alerts List</div>
           <FilterToolbarAlert 
            filterOptions={filterSelectionOptions}
            filterFields={categoryFields}
            processUpdateFilter={handleUpdateFilter}
            processSearch={handleSearch}
            labelName={'Search Alerts For:'}
          />
         </div>
         <div className="alert-manager-panel-bottom">
              <AlertManagerSelector alerts={processAlerts(alerts)} selectedAlertIdInManager={selectedAlertIdInManager} selectedProject={selectedProject} />
          </div>
        </>
      }
    </>
  );
}

export default AlertManagerSelectorContainer;