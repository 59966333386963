import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { activitiesEndPoint, lastDesignActivityEndpoint } from '../../api/apiRoutes';

export const fetchActivities = createAsyncThunk(
  'scenario/fetchactivities', async (params, thunkAPI) => {
    try{
      const response = await api.get(activitiesEndPoint(params.projectId, params.phaseId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchLastDesignActivity = createAsyncThunk(
  'scenario/fetchlastdesignactivity', async (params, thunkAPI) => {
    try{
      const response = await api.get(lastDesignActivityEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Create activities adapter
 */
 const activitiesAdapter = createEntityAdapter({
  selectId: (activity) => activity.activityId,
})

/**
 * Create activities slice / reducers
 */
const activitiesSlice = createSlice({
  name: 'activities',
  initialState: activitiesAdapter.getInitialState( { activityId: undefined,
                                                      isLoading: false,
                                                      error: undefined,
                                                      tasksLength: 5,
                                                      milestonesLength: 5,
                                                      lengthAction: 'Default',
                                                      lastDesignActivity: null,
                                                   } ),
  reducers: {
    removeActivities: (state, action) => {
      activitiesAdapter.removeMany(state, action.payload);
    },
    setTasksLength: (state, action) => {
      state.tasksLength = action.payload.tasksLength;
      state.lengthAction = action.payload.lengthAction;
    },
    setMilestonesLength: (state, action) => {
      state.milestonesLength = action.payload.milestonesLength;
      state.lengthAction = action.payload.lengthAction;
    },
    setActivityStatus: (state , action) =>{
      state.entities[action.payload.activityId].completionStatusId = action.payload.completionStatusId;
  }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivities.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        activitiesAdapter.setMany(state, action.payload);
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })

      .addCase(fetchLastDesignActivity.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchLastDesignActivity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.lastDesignActivity = action.payload;
      })
      .addCase(fetchLastDesignActivity.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Activities selectors
*/
 export const {
  selectById: selectActivityById,
} = activitiesAdapter.getSelectors((state) => state.activities);

export const selectAllActivities = (state, phaseId) => {
  const activities = state.activities;
  const filteredActivities = activitiesAdapter.getSelectors((state) => activities).selectAll().filter(activity => activity.phaseId === phaseId);
  return filteredActivities;
}

/**
 * Activities actions
 */
export const {  setAllActivities,
                removeActivities,
                setTasksLength,
                setMilestonesLength,
                setActivityStatus
              } = activitiesSlice.actions;

/**
 * Export reducer
 */
export default activitiesSlice.reducer;