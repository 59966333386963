import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk ,
  createDraftSafeSelector
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { deliverablesEndPoint } from '../../api/apiRoutes';

export const fetchDeliverables = createAsyncThunk(
  'scenario/fetchdeliverables', async (params, thunkAPI) => {
    try{
      const response = await api.get(deliverablesEndPoint(params.projectId, params.phaseId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Create deliverable adapter
 */
 const deliverablesAdapter = createEntityAdapter({
  selectId: (deliverable) => deliverable.deliverableId,
})

/**
 * Create deliverables slice / reducers
 */
const deliverablesSlice = createSlice({
  name: 'deliverables',
  initialState: deliverablesAdapter.getInitialState( { selectedDeliverableId: undefined,
                                                       error: undefined,
                                                       isLoading: false
                                                   } ),
  reducers: {
    setSelectedDeliverableId: (state, action) => {
      state.selectedDeliverableId = action.payload;
    },
    removeDeliverables: (state, action) => {
      deliverablesAdapter.removeMany(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeliverables.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchDeliverables.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        deliverablesAdapter.setMany(state, action.payload);
      })
      .addCase(fetchDeliverables.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Deliverables selectors
*/
export const {
  selectById: selectDeliverableById,
} = deliverablesAdapter.getSelectors((state) => state.deliverables);

export const selectAllDeliverables = (state, phaseId) => {
  const deliverables = state.deliverables;
  const filteredDeliverables = deliverablesAdapter.getSelectors((state) => deliverables).selectAll().filter(deliverable => deliverable.phaseId === phaseId);
  return filteredDeliverables;
}

/**
 * Deliverables actions
 */
export const {  setSelectedPhaseId, 
                setAllDeliverables,
                removeDeliverables,
              } = deliverablesSlice.actions;

/**
 * Export reducer
 */
export default deliverablesSlice.reducer;