import { 
  createSlice,
} from '@reduxjs/toolkit';

/**
 * Create authorization slice / reducers
 */
 const authorizationSlice = createSlice({
  name: 'authorization',
  initialState: { accessToken: undefined,
                  authorized: 'No',
                  roles: undefined,
                  LHRoles: undefined,
                  projects: undefined,
                  disciplines: undefined,
                  userProjectRole: undefined,
                  userProjectDisciplines: []
                },
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
    },
    setRole: (state, action) => {
      state.authorized = action.payload.authorized;
      state.roles = action.payload.roles;
      state.LHRoles = action.payload.LHRoles;
      state.projects = action.payload.projects;
      state.disciplines = action.payload.disciplines;
    },
    setProjectRoleDisciplines: (state, action) => {
      state.userProjectRole = action.payload.userProjectRole;
      state.userProjectDisciplines = action.payload.userProjectDisciplines;
    }
  }
});

/**
 * authorization actions
 */
 export const {  
  setAccessToken,
  setRole,
  setProjectRoleDisciplines
} = authorizationSlice.actions;

/**
 * Export reducer
 */
export default authorizationSlice.reducer;