import React, {useState, useRef} from 'react';
import { useSelector } from "react-redux";
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import { RelationTreeIcon, ActivityCalcIcon, CloseIcon, GlobIcon } from '../../../../../Icons';
import DataConnectionSettings from '../../molecules/DataConnectionSettings';
import ActivityCalculationSettings from '../../molecules/ActivityCalculationSettings';
import RegionalPreferences from '../../molecules/RegionalPreferences';
import ActivityCalculationCloseConfirm from '../../atoms/ActivityCalculationCloseConfirm'
import './ProjectSettingWidget.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const ProjectSettingWidget=(props)=> {
  const [value, setValue] = useState("Data Connections");
  const [modalCloseByActivity, setModalCloseByActivity] = useState(false);
  const { packagePctCalcType } = useSelector(state => state.packagePctCompleteCalcTypes);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const activityRef = useRef();


  /**
   * Handles activity type change confirm modal close
   */
  const closeCloseByActivity = () =>{
    setAnchorEl(null);
    setModalCloseByActivity(false);
  }

  /**
   * Handles activity type change confirm modal open
   */

  const openCloseByActivity = (event) =>{
    setAnchorEl(event);
    setModalCloseByActivity(true);
  }

   /**
   * Handles ProjectSettings close
   */
  const handleModalClose =()=>{
    if(value === 'Alert Calculations' && packagePctCalcType?.length > 0){
      openCloseByActivity();
    }else{
      props.handleModalClose();
    }
  }

   /**
   * Handles all modal close
   */
  const closeActivityChanges = () =>{
    closeCloseByActivity()
    props.handleModalClose();
  }

   /**
   * Handle activty calculation type change save
   */
  const activitySaveChanges = () => {
    activityRef.current.handleUpdateCloseCall();
    setModalCloseByActivity(false);
  }


  const SettingsTabs = withStyles({
    root: {
    },
    indicator: {
      backgroundColor: '#4658F9',
      width: '3px',
      height: '48px !important',
    }
  })(Tabs);
  
  const SettingsTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      minWidth: 'fit-content',
      maxWidth: null,
      fontWeight: theme.typography.fontWeightRegular,
      paddingLeft: theme.spacing(3),
      fontSize: '14px',
      lineHeight: '24px',
      color: '#799BC2',
      '&$selected': {
        color: '#333333',
        fontWeight: theme.typography.fontWeightMedium,
        '& .bar-icon':{
          color: '#4658F9',
        },
      },
      '&:focus': {
        color: '#333333',
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    selected: {
    }
    ,
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
  }))((props) => <Tab disableRipple {...props} />);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
     <div className="nav-section">
       <div className='nav-header'>
         <div className='nav-title'>
             Project Settings
         </div>
        <hr />
       </div>
       <SettingsTabs 
        orientation="vertical"
        value={value}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary" 
        onChange={handleChange}
        >
      <SettingsTab label={<span className="tab-label"><RelationTreeIcon className='bar-icon'/>Data Connections</span>} value="Data Connections" />
      <SettingsTab label={<span className="tab-label"><ActivityCalcIcon className='bar-icon'/>Alert Calculations</span>} value="Alert Calculations" />
      <SettingsTab label={<span className="tab-label"><GlobIcon className='bar-icon'/>Regional Preferences</span>} value="Regional Preferences" />
    </SettingsTabs>
    </div>
    <div className="content-section"> 
      <div className='content-header'>
            <div className='content-title'>
              {value}
            </div>
            <div className='content-action'>
              <div className='settings-close' onClick={handleModalClose}>
              <CloseIcon />
              </div>
            </div>
       </div>
      <TabPanel value={value} index="Data Connections">
         <DataConnectionSettings />
      </TabPanel>
      <TabPanel value={value} index="Alert Calculations">
         <ActivityCalculationSettings ref={activityRef} closeActivityChanges={closeActivityChanges}/>
      </TabPanel>
      <TabPanel value={value} index="Regional Preferences">
         <RegionalPreferences />
      </TabPanel>
    </div>
    <Popover
        id='activity-Change-Popup'
        open={modalCloseByActivity}
        anchorEl={anchorEl}
        anchorReference='none'
        BackdropProps={{ invisible: false }}
      >
       <div className="activity-Change-Popup-popover">
           <ActivityCalculationCloseConfirm closeActivityChanges={closeActivityChanges} activitySaveChanges={activitySaveChanges} closeCloseByActivity={closeCloseByActivity} />
       </div>
      </Popover>
    </>
 
  );
}

export default ProjectSettingWidget;