import React  from 'react';
import TimeImpact from '../../../../../components/Shared/atoms/TimeImpact';
import CostImpact from '../../../../../components/Shared/atoms/CostImpact';
import RiskCategory from '../../../../../components/Shared/atoms/RiskCategory';
import { DENOMINATION } from '../../../../../../src/constants';
import ScenarioOptionDescription from '../ScenarioOptionDescription';
import { isJsonString } from '../../../../../utils';
import './ScenarioOption.scss';

const ScenarioOption = (props) => {
  const { option, scenarioOption } = props;
  let jsonParseObj;
  let createDescriptionMarkup;

  // check for valid json and parse
  if (isJsonString(option.description)) {
    jsonParseObj = JSON.parse(option.description);

    /* 
    * creating object to display the description from parsed json
    */
    createDescriptionMarkup = jsonParseObj.map(jsonObj => ({
      reasoning: jsonObj.reasoning_type,
      noOfActivitiesAffected: jsonObj.activites_affected_tag,
      averageDelay: jsonObj.average_delay_rounded,
      phaseName: jsonObj.descriptive_phase_name,
      packageName: jsonObj.descriptive_package_name,
    }));
  }

  return (
    <div className="scenario-option-container">
      <div className="option-sub-container" >
        <div className="option-title-heatmap">Overall Impact</div>
        <div className="option-sub-container-card">
          <table className="option-table">
            <tbody>
              <tr>
                <th>{scenarioOption === 'scenariooption' && 'IFC Completion:'}</th>
                <td>{scenarioOption === 'scenariooption' && <TimeImpact timeImpactDelayDays={option.ifcCompletionDelayDays} />}</td>
              </tr>
              <tr>
                <th>{scenarioOption === 'scenariooption' && 'Design Cost Impact:'}</th>
                <td>{scenarioOption === 'scenariooption' && <CostImpact costImpact={option.costImpactDesign} denomination={DENOMINATION.auto} />}</td>
              </tr>
              <tr>
                <th>{scenarioOption === 'scenariooption' && 'Construction Cost Impact:'}</th>
                <td>{scenarioOption === 'scenariooption' && <CostImpact costImpact={option.costImpactConstruction} denomination={DENOMINATION.auto} />}</td>
              </tr>
              <tr>
                <th>{scenarioOption === 'scenariooption' && 'Other Cost Impact:'}</th>
                <td>{scenarioOption === 'scenariooption' && <CostImpact costImpact={option.costImpactClient} denomination={DENOMINATION.auto} />}</td>
              </tr>
              <tr>
                <th>{scenarioOption === 'scenariooption' && 'Risk Level:'}</th>
                <td>{scenarioOption === 'scenariooption' && <RiskCategory riskCategory={option.riskCategory} />}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="option-sub-container">
        <div className="option-title-heatmap">What makes this option unique</div>
        <div className="option-sub-container-card">
          {createDescriptionMarkup &&
            <>
              <p>To help you assess what’s required to implement this option, listed below are some of the proposed changes to the project delivery plan that differ most from other high-ranking options. </p>
              {createDescriptionMarkup.map((displayDescription) =>
                <ScenarioOptionDescription displayDescriptions={displayDescription} />
              )
              }
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default ScenarioOption;