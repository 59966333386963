import React, { useState, useRef, useEffect } from 'react';
import { AccordionComponent, AccordionItemsDirective, AccordionItemDirective  } from '@syncfusion/ej2-react-navigations';
import InfoMessage from '../../../../../components/Shared/molecules/InfoMessage';
import ActivityPackageHeader from '../../atoms/ActivityPackageHeader';
import './ActivityCalculationBody.scss'

const ActivityCalculationBody = (props) => {
  const { selectedProjectId, packages, selectedFilterOptions, searchValue, packageActivityCheck, packagePctCompleteCalcTypes } = props;
  const [sortedPackages, setSortedPackages] = useState([]);
  const PackageAccordianComponentRef = useRef();

  const packageHeader = (workPackage) => {
    return <ActivityPackageHeader selectedProjectId={selectedProjectId} packageActivityCheck={packageActivityCheck} workPackage={workPackage} packagePctCompleteCalcTypes={packagePctCompleteCalcTypes} />
  }

  useEffect(() => {
    //To Process packages again once a filter is selected
    processPackages(packages);
  }, [selectedFilterOptions, searchValue, packagePctCompleteCalcTypes])

  const processPackages = () =>{
    let searchedData = [];
    if (searchValue && searchValue.length > 0) {
      searchedData = packages.slice().filter(x=>x.description.toLowerCase().includes(searchValue.toLowerCase()))
    } else {
      searchedData = packages.slice();
    }
    searchedData = searchedData.sort((a,b)=> {
      if(a.description > b.description) return 1;
      else if(a.description < b.description) return -1;
      else return 0;
  });
  
    let filteredData = [];
    if(selectedFilterOptions && selectedFilterOptions.length > 0){
        for (var i = 0; i < selectedFilterOptions.length; i++){
            if(selectedFilterOptions[i].id == 0){
                filteredData = searchedData.slice();
            }
            else{
                filteredData = filteredData.concat(searchedData.slice().filter(x=>x.disciplineId == selectedFilterOptions[i].id));
            }
        }
    }
    else {
        filteredData = searchedData.slice();
      }

    setSortedPackages([...new Set(filteredData)]);
  }

  return (
    <div className="packageSettings-accordian-container">
                    {sortedPackages.length > 0 &&
                        <AccordionComponent expandMode='Single' ref={PackageAccordianComponentRef}>
                            <AccordionItemsDirective>
                                {
                                sortedPackages.map((workPackage, i) => {
                                    return (
                                    <AccordionItemDirective 
                                        header={() => packageHeader(workPackage)}
                                        iconCss='e-athletics e-acrdn-icons'
                                        cssClass="acc-item component-shadow"
                                        key={workPackage.description}
                                        id={workPackage.packageId}
                                    />
                                    )
                                })
                                }
                            </AccordionItemsDirective>
                        </AccordionComponent>
                    }
                    {sortedPackages.length === 0 &&
                        <InfoMessage message="No packages found." />
                    }
                </div>
  )
}

export default ActivityCalculationBody;