import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DeliverableList from '../DeliverableList';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { selectAllDeliverables } from '../../../../../redux/slices/deliverablesSlice';
import { fetchDeliverables } from '../../../../../redux/slices/deliverablesSlice';

import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const DeliverableListContainer = (props) => {
  const dispatch = useDispatch();
  const { selectedPhaseId } = useSelector(state => state.info);
  const { selectedProjectId } = useSelector(state => state.projects);

  const deliverables = useSelector((state) => selectAllDeliverables(state, selectedPhaseId));
  const { error, isLoading } = useSelector(state => state.deliverables);

  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  useEffect(() => {
    if (accessToken && selectedPhaseId){
      dispatch(fetchDeliverables({ projectId: selectedProjectId, phaseId: selectedPhaseId, accessToken: accessToken}));
    }
  }, [dispatch, selectedProjectId, selectedPhaseId, accessToken]);

  return (
    <>
      { isLoading && 
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to get deliverables list" />
      }
      { !isLoading && !error && deliverables.length > 0 &&
        <>
          <DeliverableList deliverables={deliverables} selectedPhaseId={selectedPhaseId}/>
        </>
      }
    </>
  );
}

export default DeliverableListContainer;