import React, { useEffect, useRef } from 'react';
import { ChartComponent, SeriesCollectionDirective, Inject, Highlight, Selection, Legend, Category, StackingColumnSeries, Tooltip, Animation } from '@syncfusion/ej2-react-charts';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedWeekStartDate } from '../../../../../redux/slices/createScenarioSlice';
import { getFormattedDate } from '../../../../../utils';

const ResourceChart = (props) => {
  const { discipline } = props;
  const { selectedWeekStartDate, editedResources, resourceProfile } = useSelector(state => state.createScenario);
  const dispatch = useDispatch();
  const chartComponentRef = useRef();

  const groupBy = (arr, criteria) => {
    return arr.reduce(function (obj, item) {
  
      // Check if the criteria is a function to run on the item or a property of it
      let key = typeof criteria === 'function' ? criteria(item) : item[criteria];
  
      // If the key doesn't exist yet, create it
      if (!Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = [];
      }
  
      // Push the value to the object
      obj[key].push(item);
  
      // Return the object to the next item in the loop
      return obj;
  
    }, {});
  }


  useEffect(() => {
    if(resourceProfile){
    if (chartComponentRef){
      // de-select any selection
      chartComponentRef.current.selectedDataIndexes = [];

      // clear existing series
      chartComponentRef.current.clearSeries();

      // get distinct week start dates
      let weekStartDates = resourceProfile.map(item => item.weekStartDate).filter((value, index, self) => self.indexOf(value) === index);
  
      // group by resource type
      let groupedResources = groupBy(resourceProfile, 'resourceType');
  
      // get the resource types list
      let resourceTypes = Object.keys(groupedResources).map((resourceType, i) => {
        return { resourceType: resourceType };
      });
  
      resourceTypes.forEach((resourceType, i) => {
        // gather the data for the resource types
        let seriesdData = [];
        weekStartDates.forEach((weekStartDate) => {
          let resourceLine = resourceProfile.find(e => e.resourceType === resourceType.resourceType && e.weekStartDate === weekStartDate);
  
          // check if there is an edited resource line
          let editedResource = editedResources ? editedResources.find(e => e.resourceType === resourceType.resourceType
                                                                     && e.weekStartDate === weekStartDate
                                                                     && e.disciplineId === discipline.disciplineId) : undefined;
  
          // and set to either the edited value or from the resource profile
          let yValue = editedResource ? editedResource.weekFTECapacity : resourceLine.weekFTECapacity;
  
          let chartLine = { weekStartDate: weekStartDate, weekStartDateFormatted: getFormattedDate(weekStartDate), weekFTECapacity: yValue }
          seriesdData.push(chartLine);
        });
  
        // add a series to the chart
        chartComponentRef.current.addSeries([{
          type: 'StackingColumn',
          dataSource: seriesdData,
          xName: 'weekStartDateFormatted',
          yName: 'weekFTECapacity',
          name: resourceType.resourceType,
          animation: { enable: false, delay: 0, duration: 300 }
        }])
      });

      selectChartWeek();
    }
  }
  }, [editedResources,resourceProfile,selectedWeekStartDate]);

  useEffect(() =>{
    if (selectedWeekStartDate){
      const timer = setTimeout(() => {
        selectChartWeek();
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [selectedWeekStartDate]);

  /**
   * Selects the chart week
   */
  const selectChartWeek = () => {
    // set the selected index for the chart based on the selected week start date
    if (chartComponentRef){
      if (chartComponentRef.current.series.length > 0) {
        let chartDataSource = chartComponentRef.current.series[0].dataSource;
        let selectedWeekIndex;
  
        for (let i = 0; i < chartDataSource.length; i++) {
          if (chartDataSource[i].weekStartDate === selectedWeekStartDate) {
            selectedWeekIndex = i;
            break;
          }
        }
  
        // set the index if it is not already set
        if (!chartComponentRef.current.selectedDataIndexes.find(selIndex => selIndex.point === selectedWeekIndex)) {
          chartComponentRef.current.selectedDataIndexes = [{ series: 0, point: selectedWeekIndex }]
        }
      }
    }
  }

  /**
   * Point click, set the selected week
   * @param {*} args 
   */
  const pointClick = (args) => {
    let pointIndex = args.pointIndex;
    let selectedStartDate = args.series.dataSource[pointIndex].weekStartDate;

    if (selectedStartDate !== selectedWeekStartDate) {
      // set the selected week start date
      dispatch(setSelectedWeekStartDate(selectedStartDate));
    }
    else {
      dispatch(setSelectedWeekStartDate());
    }
  }

  const palette = ["#005A84"];

  return (
    <div className="rc-wrapper">
      <ChartComponent ref={chartComponentRef}
        id='resourceChart'
        style={{ textAlign: "center" }}
        selectionMode='Point'
        isMultiSelect={false}
        pointClick={pointClick}
        primaryXAxis={{
         title: 'Week',
          majorGridLines: { width: 1 },
          minorGridLines: { width: 0 },
          majorTickLines: { width: 0 },
          minorTickLines: { width: 0 },
          lineStyle: { width: 0 },
          interval: 1,
          labelIntersectAction: 'Rotate90',
          valueType: 'Category',
        }}
        primaryYAxis={{
          title: 'FTE Capacity',
          lineStyle: { width: 0 },
          majorTickLines: { width: 0 },
          majorGridLines: { width: 1 },
          minorGridLines: { width: 1 },
          minorTickLines: { width: 0 },
          labelFormat: '{value}',
          valueType: 'Double', 
          rangePadding: 'Round',
          labelFormat: 'n2'
        }}
        width="100%"
        height="750"
        chartArea={{ border: { width: 0 } }}
        tooltip={{ enable: true }}
        delayUpdate={true}
        palettes={palette}
        >
        <Inject services={[Selection, Highlight, StackingColumnSeries, Category, Legend, Tooltip]} />
        <SeriesCollectionDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );
}

export default ResourceChart;