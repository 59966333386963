import { useMsal } from "@azure/msal-react";
import {
  AccordionComponent, AccordionItemDirective, AccordionItemsDirective
} from '@syncfusion/ej2-react-navigations';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import AuthorizationService from "../../../../../components/Shared/molecules/Authorization/AuthorizationService";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { ActivityleIcon, ChevronDownIcon, ChevronUpIcon, CloseIcon, InfoCircleIcon, KemIcon } from '../../../../../Icons';
import { fetchActivities, setMilestonesLength, setTasksLength } from '../../../../../redux/slices/activitiesSlice';
import { fetchPackagePctCompleteCalcTypes, selectAllPackagePctCompleteCalcTypes } from '../../../../../redux/slices/packagePctCompleteCalcTypesSlice';
import { updateActivityCompletionStatus } from '../../../../../redux/slices/updateActivityCompletionStatusSlice';
import { LinkLighthouseButton2 } from '../../../../../theme/Buttons';
import Milestones from '../Milestones';
import Task from '../Task';
import { setSelectedActivityId } from '../../../../../redux/slices/infoSlice';
import './ActivityListAccordion.scss';



 const ActivityListAccordion = React.memo(props => {
  const dispatch = useDispatch();
  const { activities = [], type, activityCompletionStatus } = props;
  const activityAccordionComponentRef = React.createRef();
  const { selectedPhaseId, selectedActivityId } = useSelector(state => state.info);
  const { selectedProjectId } = useSelector(state => state.projects);
  const packagePctCompleteCalcTypes = useSelector(selectAllPackagePctCompleteCalcTypes);
  const { isLoading, isError } = useSelector(state => state.packagePctCompleteCalcTypes);

  const { accessToken, authorized } = useSelector(state => state.authorization);
  
  const {tasksLength} = useSelector(state => state.activities);
  const {milestonesLength} = useSelector(state => state.activities);
  let activitiesLength = 0;

  if(type == "Tasks"){
    activitiesLength = tasksLength;
  }
  else if (type == "Milestones"){
    activitiesLength = milestonesLength;
  }

  const [toastStatus, setToastStatus] = useState(null);
  const [activityUpdated, setActivityUpdated] = useState(null);

  const { instance, accounts } = useMsal();

  /** The activity header template */
  const activityHeader = (activity) => {
    return <>
      <div className="activity-header">
        { activity.activityType.toLowerCase() === "task" &&
          <ActivityleIcon className="task-icon" />
        }
        { ( activity.activityType.toLowerCase() === "finish milestone" || activity.activityType.toLowerCase() === "milestone" ) &&
          <KemIcon className="milestone-icon" />
        }
        <div className="activity-title">
          {activity.activityName.length > 70 && activity.activityName.substring(0,67)+"..."}
          {activity.activityName.length <= 70 && activity.activityName}
        </div>
      </div>
    </>;
  }

  /** The activity content template */
  const activityContent = (activity) => {
      let activityStateDisabledStatus = true;
      if(packagePctCompleteCalcTypes.find(x=>x.packageId == activity.packageId).packagePctCalcType == 2){
        activityStateDisabledStatus = false;
      }

      return (
        <>
          <div>
            { 
              activity.activityType.toLowerCase() === "task" &&
              <Task task={activity} />
            }
            {
              (activity.activityType.toLowerCase() === "finish milestone" || activity.activityType.toLowerCase() === "milestone" ) &&
              <Milestones milestones={activity} />
            }
          </div>
        </>
      )
    }

  useEffect(() => {
     //Acquire access token
     AuthorizationService({dispatch : dispatch, instance: instance, accounts: accounts, accessTokenAvailable:accessToken});
  }, [accounts]);

  useEffect(() => {
    //Get PackagePctCompleteCalcType
    if (accessToken && authorized == "Yes"){
      dispatch(fetchPackagePctCompleteCalcTypes({projectId : selectedProjectId, accessToken : accessToken}));
    }
 }, [dispatch, accessToken, authorized]);

  /** Accordion animation settings */
  const animationSettings =
  {
    expand: { effect: 'None', duration: 0, easing: 'linear' },
    collapse: { effect: 'None', duration: 0, easing: 'linear' }
  };

  /** Handles accordion item expanded
   * scrolls to the location of the expanded item
   */
  const activityAccordionExpanded = (args) => {
    let alertRef = args.content;

    // need set timeout 
    // for this to work in React
    setTimeout(function () {
      alertRef.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'center',
        }
      );
    }, 100);
  }

  /**
   * More button clicked
   * expand list
   */
  const moreButtonClick = () => {
    activities && activities.length && type == "Tasks" && dispatch(setTasksLength({tasksLength: activities.length, lengthAction: 'More'}));
    activities && activities.length && type == "Milestones" && dispatch(setMilestonesLength({milestonesLength: activities.length, lengthAction: 'More'}));
    dispatch(fetchActivities({ projectId: selectedProjectId, phaseId: selectedPhaseId, accessToken: accessToken}));
  }

  /**
   * Less button clicked
   * collapse list
   */
  const lessButtonClick = () => {
    type == "Tasks" && dispatch(setTasksLength({tasksLength: 5, lengthAction: 'Less'}));
    type == "Milestones" && dispatch(setMilestonesLength({milestonesLength: 5, lengthAction: 'Less'}));
    dispatch(fetchActivities({ projectId: selectedProjectId, phaseId: selectedPhaseId, accessToken: accessToken}));
  }

  const closeToast = () =>{
    setToastStatus(null);
  }

    /** sets the selected activity in redux store */
  const handleTaskClick = (args) => {

    let clickedTaskItemId = args.item.activityData.activityId;

    if (clickedTaskItemId !== selectedActivityId) {
        // set as selected activity Id
        dispatch(setSelectedActivityId(clickedTaskItemId));
    }
  };

  return (
    <>
      { isLoading &&
        <CircularProgressBar />
      }
      { isError &&
        <ErrorMessage message="Unable to display Activity List" />
      }
      { !isLoading && !isError &&
        <>
          {activities && activities.length &&
            <div className="activity-accordion-container">
              <AccordionComponent expandMode='Single'
                ref={activityAccordionComponentRef}
                clicked={handleTaskClick}
                animation={animationSettings}
                expanded={activityAccordionExpanded}>
                <AccordionItemsDirective>
                  {
                    activities.slice(0, activitiesLength).map((activity, i) => {
                      let isExpanded = false;
                      if (selectedActivityId) {
                        isExpanded = selectedActivityId === activity.activityId ? true : false;
                      }
                      return (
                        <AccordionItemDirective
                          header={() => activityHeader(activity)}
                          content={() => activityContent(activity)}
                          iconCss='e-athletics e-acrdn-icons'
                          cssClass="acc-item component-shadow"
                          key={activity.activityId}
                          id={activity.activityId}
                          activityData={activity}
                          expanded={isExpanded}
                        />
                      )
                    })
                  }
                </AccordionItemsDirective>
              </AccordionComponent>
              {activities.length > 5 && activitiesLength !== activities.length &&
              <div className="more-button">
                <LinkLighthouseButton2 onClick={moreButtonClick}>
                  Show More
                  <ChevronDownIcon style={{ marginLeft: "10px" }} />
                </LinkLighthouseButton2>
              </div>
              }
              {activities.length > 5 && activitiesLength == activities.length &&
              <div className="more-button">
                <LinkLighthouseButton2 onClick={lessButtonClick}>
                  Show Less
                  <ChevronUpIcon style={{ marginLeft: "10px" }} />
                </LinkLighthouseButton2>
              </div>
              }
            </div>
          }
        </>
      }
    </>
  );
})

const areEqual = (prevProps, nextProps) => {
  if(nextProps.activities == prevProps.activities){
    return true;
  }
  else{
    return false;
  }
}

export default React.memo(ActivityListAccordion,areEqual);