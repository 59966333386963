import { FaExclamation, FaCheck, FaTimes, FaTimesCircle, FaExclamationCircle, FaCheckCircle } from 'react-icons/fa';
import { COMPLETION_STATUS_THRESHOLD as completionStatusThreshold, DENOMINATION } from '../constants';
import { DESIGN_PROGRESS_THRESHOLD as designProgressThreshold } from '../constants';
import { COMPLETION_STATUS_COLOUR as completionStatusColour } from '../constants';
import { PROGRESS_BAR_TRACK_COLOUR as progressBarTrackColour, PROGRESS_BAR_INDICATOR_COLOUR as  progressBarIndicatorColour } from '../constants';
import { COMPLETION_STATUS_SELECTED_COLOUR as selectedCompletionStatusColour } from '../constants';
import { COMPLETION_STATUS_SELECTED_BORDER_COLOUR as selectedCompletionStatusBorderColour } from '../constants';
import { COMPLETION_STATUS_PROGRESS_COLOUR as completionStatusProgressColour } from '../constants';
import { COMPLETION_STATUS_PROGRESS_BG_COLOUR as completionStatusProgressBgColour } from '../constants';
import { NODE_STATE_COLOUR as nodeStateColour } from '../constants';
import { DISCIPLINE_ICON as disciplineIcon } from '../constants';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BG_COLOUR } from '../constants';
import store from '../redux';
import { format } from 'date-fns';
import './LighthouseTooltip.scss';


/**
 * Gets the design progress colour based on percentage completion
 * @param {decimal} plannedProgressCompletion 
 * @param {decimal} estimatedProgressCompletion 
 * @param {Boolean} isFixedColour 
 */

 export const getProgressBarColour = (plannedProgressCompletion, estimatedProgressCompletion, isPlanned = false ) => {

  // To set to current colour based on estimated & planned
  let diffPercentageComplete = plannedProgressCompletion - estimatedProgressCompletion;
  // initial set to default colour
  let colour = { trackColor : progressBarTrackColour['default'] , indicatorColor : progressBarIndicatorColour['default'] }

  if(isPlanned ){
    if(plannedProgressCompletion !== null ){
      colour = { trackColor : progressBarTrackColour['constant'] , indicatorColor : progressBarIndicatorColour['constant'] }
    }else{
      colour = { trackColor : progressBarTrackColour['default'] , indicatorColor : progressBarIndicatorColour['default'] }
    }
  }
  else {
    if(estimatedProgressCompletion === null){
      colour = { trackColor : progressBarTrackColour['default'] , indicatorColor : progressBarIndicatorColour['default'] }
    }
    else if (diffPercentageComplete < 0) {
      colour = { trackColor : progressBarTrackColour['high'] , indicatorColor : progressBarIndicatorColour['high'] }
    }
    else if (diffPercentageComplete >= 0 && diffPercentageComplete < designProgressThreshold['low']) {
      colour = { trackColor : progressBarTrackColour['high'] , indicatorColor : progressBarIndicatorColour['high'] }
    }
    else if (diffPercentageComplete >= designProgressThreshold['low'] && diffPercentageComplete < designProgressThreshold['high']) {
      colour = { trackColor : progressBarTrackColour['medium'] , indicatorColor : progressBarIndicatorColour['medium'] }
    }
    else if (diffPercentageComplete >= designProgressThreshold['high']) {
      colour = { trackColor : progressBarTrackColour['low'] , indicatorColor : progressBarIndicatorColour['low'] }
    }
  }

  return colour;
}

/**
 * Gets the completion status text
 * @param {*} percentageComplete
 * @param {Boolean} isRounded 
 * @returns 
 */
export const getDesignProgressText = (percentageComplete, isRounded = false) => {
  if(isRounded){
    percentageComplete=Math.round(percentageComplete);
  }
  let text;

  if (percentageComplete !== null) {
    if (percentageComplete < completionStatusThreshold['low']) {
      text = 'Task completion is currently below the projected amount.';
    }
    else if (percentageComplete >= completionStatusThreshold['low'] && percentageComplete < completionStatusThreshold['medium']) {
      text = 'Task completion is currently below the projected amount.';
    }
    else if (percentageComplete >= completionStatusThreshold['medium'] && percentageComplete < 100) {
      text = 'Task completion is currently above the projected amount.';
    }
    else if (percentageComplete === 100) {
      text = 'Task completion is currently above the projected amount.';
    }
  }

  return text;
}

/**
 * Gets the completion status icon
 * @param {*} percentageComplete 
 * @returns 
 */
export const getCompletionStatusIcon = (percentageComplete) => {
  let icon;

  if (percentageComplete !== null) {
    if (percentageComplete < completionStatusThreshold['low']) {
      icon = <FaExclamation />;
    }
    else if (percentageComplete >= completionStatusThreshold['low'] && percentageComplete < completionStatusThreshold['medium']) {
      icon = <FaExclamation />;
    }
    else if (percentageComplete >= completionStatusThreshold['medium'] && percentageComplete < 100) {
      icon = <FaCheck />;
    }
    else if (percentageComplete === 100) {
      icon = <FaCheck />;
    }
  }

  return icon;
}

/**
 * Gets the completion status colour for percentage complete
 * @param {decimal} actualPercentageComplete
 * @param {decimal} plannedPercentageComplete
 */
export const getCompletionStatusColour = (actualPercentageComplete, plannedPercentageComplete) => {
  let diffPercentageComplete = plannedPercentageComplete - actualPercentageComplete;
  //console.log("diff perc complete: ", diffPercentageComplete);

  // initial set to default colour
  let colour = completionStatusColour['default'];

  if (diffPercentageComplete < 0) {
    colour = completionStatusColour['high'];
  }
  else if (diffPercentageComplete >= 0 && diffPercentageComplete < designProgressThreshold['low']) {
    colour = completionStatusColour['high'];
  }
  else if (diffPercentageComplete >= designProgressThreshold['low'] && diffPercentageComplete < designProgressThreshold['high']) {
    colour = completionStatusColour['medium'];
  }
  else if (diffPercentageComplete >= designProgressThreshold['high']) {
    colour = completionStatusColour['low'];
  }

  return colour;
};

/**
 * Gets selected completion status colour for percentage complete
 * @param {*} actualPercentageComplete 
 * @param {*} plannedPercentageComplete 
 * @returns 
 */
export const getSelectedCompletionStatusBorderColour = (actualPercentageComplete, plannedPercentageComplete) => {
  let diffPercentageComplete = plannedPercentageComplete - actualPercentageComplete;

  // initial set to default colour
  let colour = selectedCompletionStatusBorderColour['default'];

  if(actualPercentageComplete === 0 && plannedPercentageComplete === 0 ) {
    colour = selectedCompletionStatusBorderColour['default'];
  }
  else {
    if (diffPercentageComplete < 0) {
      colour = selectedCompletionStatusBorderColour['high'];
    }
    else if (diffPercentageComplete >= 0 && diffPercentageComplete < designProgressThreshold['low']) {
      colour = selectedCompletionStatusBorderColour['high'];
    }
    else if (diffPercentageComplete >= designProgressThreshold['low'] && diffPercentageComplete < designProgressThreshold['high']) {
      colour = selectedCompletionStatusBorderColour['medium'];
    }
    else if (diffPercentageComplete >= designProgressThreshold['high']) {
      colour = selectedCompletionStatusBorderColour['low'];
    }
  }

  return colour;
};

export const getSelectedCompletionStatusColour = (actualPercentageComplete, plannedPercentageComplete) => {
  let diffPercentageComplete = plannedPercentageComplete - actualPercentageComplete;

  // initial set to default colour
  let colour = selectedCompletionStatusColour['default'];

  if(actualPercentageComplete === 0 && plannedPercentageComplete === 0 ) {
    colour = selectedCompletionStatusColour['default'];
  }
  else {
    if (diffPercentageComplete < 0) {
      colour = selectedCompletionStatusColour['high'];
    }
    else if (diffPercentageComplete >= 0 && diffPercentageComplete < designProgressThreshold['low']) {
      colour = selectedCompletionStatusColour['high'];
    }
    else if (diffPercentageComplete >= designProgressThreshold['low'] && diffPercentageComplete < designProgressThreshold['high']) {
      colour = selectedCompletionStatusColour['medium'];
    }
    else if (diffPercentageComplete >= designProgressThreshold['high']) {
      colour = selectedCompletionStatusColour['low'];
    }
  }

  return colour;
};

export const getPhaseNodeColour = (actualPercentageComplete, plannedPercentageComplete) => {

  // To set to current colour based on actual & planned
  let diffPercentageComplete = plannedPercentageComplete - actualPercentageComplete;

  // initial set to default colour
  let colour = completionStatusColour['default'];

  if(actualPercentageComplete === 0 && plannedPercentageComplete === 0 ) {
    colour = completionStatusColour['default'];
  }
  else {
    if (diffPercentageComplete < 0) {
      colour = completionStatusColour['high'];
    }
    else if (diffPercentageComplete >= 0 && diffPercentageComplete < designProgressThreshold['low']) {
      colour = completionStatusColour['high'];
    }
    else if (diffPercentageComplete >= designProgressThreshold['low'] && diffPercentageComplete < designProgressThreshold['high']) {
      colour = completionStatusColour['medium'];
    }
    else if (diffPercentageComplete >= designProgressThreshold['high']) {
      colour = completionStatusColour['low'];
    }
  }

  return colour;
};

export const getPhaseNodeProgressColour = (actualPercentageComplete, plannedPercentageComplete) => {

  // To set to current colour based on actual & planned
  let diffPercentageComplete = plannedPercentageComplete - actualPercentageComplete;

  // initial set to default colour
  let colour = completionStatusProgressColour['default'];

  if(actualPercentageComplete === 0 && plannedPercentageComplete === 0 ) {
    colour = completionStatusProgressColour['default'];
  }
  else {
    if (diffPercentageComplete < 0) {
      colour = completionStatusProgressColour['high'];
    }
    else if (diffPercentageComplete >= 0 && diffPercentageComplete < designProgressThreshold['low']) {
      colour = completionStatusProgressColour['high'];
    }
    else if (diffPercentageComplete >= designProgressThreshold['low'] && diffPercentageComplete < designProgressThreshold['high']) {
      colour = completionStatusProgressColour['medium'];
    }
    else if (diffPercentageComplete >= designProgressThreshold['high']) {
      colour = completionStatusProgressColour['low'];
    }
  }

  return colour;
};

export const getPhaseNodeProgressBgColour = (actualPercentageComplete, plannedPercentageComplete) => {

  // To set to current colour based on actual & planned
  let diffPercentageComplete = plannedPercentageComplete - actualPercentageComplete;

  // initial set to default colour
  let colour = completionStatusProgressBgColour['default'];

  if(actualPercentageComplete === 0 && plannedPercentageComplete === 0 ) {
    colour = completionStatusProgressBgColour['default'];
  }
  else {
    if (diffPercentageComplete < 0) {
      colour = completionStatusProgressBgColour['high'];
    }
    else if (diffPercentageComplete >= 0 && diffPercentageComplete < designProgressThreshold['low']) {
      colour = completionStatusProgressBgColour['high'];
    }
    else if (diffPercentageComplete >= designProgressThreshold['low'] && diffPercentageComplete < designProgressThreshold['high']) {
      colour = completionStatusProgressBgColour['medium'];
    }
    else if (diffPercentageComplete >= designProgressThreshold['high']) {
      colour = completionStatusProgressBgColour['low'];
    }
  }

  return colour;
};

export const getPhaseNodeDisabledColour = (type) => {

  // initial set to default colour
  let colour = nodeStateColour['filtered'];

  if(type === 'node'){
    colour = nodeStateColour['filtered'];
  }
  else if (type === 'border'){
    colour = nodeStateColour['filteredBorder'];
  }
  else if (type === 'progress'){
    colour = nodeStateColour['filteredProgress'];
  }
  else if (type === 'progressBg'){
    colour = nodeStateColour['filteredProgressBg'];
  }

  return colour;
}

export const getPhaseNodeShadowColour = (type) => {
  // initial set to default colour
  let colour = nodeStateColour['default'];

  if(type === 'shadow'){
    colour = nodeStateColour['shadowColour'];
  }
  return colour;
};


/**
 * Gets the milestone completion colour
 * @param {int} percentageComplete 
 * @returns 
 */
export const getMilestoneCompletionStatusColour = (percentageComplete) => {
  // initial set to default colour
  let colour = completionStatusColour['milestone'];

  if (percentageComplete === 100) {
    colour = completionStatusColour['high'];
  }

  return colour;
};

/**
 * Gets the selected milestone completion colour
 * @param {int} actualPercentageComplete 
 * @returns 
 */
export const getSelectedMilestoneCompletionStatusColour = (percentageComplete) => {
  // initial set to default colour
  let colour = selectedCompletionStatusColour['milestone'];

  if (percentageComplete === 100) {
    colour = selectedCompletionStatusColour['high'];
  }

  return colour;
};

/**
 * Gets the design progress colour based on percentag completes
 * @param {decimal} actualPercentageComplete 
 * @param {decimal} plannedPercentageComplete 
 * @param {Boolean} isRounded 
 */
export const getDesignProgressColour = (actualPercentageComplete, plannedPercentageComplete, isRounded = false) => {
  // changing values to Math.round
    if(isRounded){
      actualPercentageComplete=Math.round(actualPercentageComplete);
      plannedPercentageComplete=Math.round(plannedPercentageComplete);
    }
  // calculate the difference
  let diffPercentageComplete = plannedPercentageComplete - actualPercentageComplete;
  //console.log("diff perc complete: ", diffPercentageComplete);

  // initial set to default colour
  let colour = completionStatusProgressColour['default'];

  if (diffPercentageComplete < 0) {
    colour = completionStatusProgressColour['high'];
  }
  else if (diffPercentageComplete >= 0 && diffPercentageComplete < designProgressThreshold['low']) {
    colour = completionStatusProgressColour['high'];
  }
  else if (diffPercentageComplete >= designProgressThreshold['low'] && diffPercentageComplete < designProgressThreshold['high']) {
    colour = completionStatusProgressColour['medium'];
  }
  else if (diffPercentageComplete >= designProgressThreshold['high']) {
    colour = completionStatusProgressColour['low'];
  }

  return colour;
}

/**
 * Gets the design progress icon
 * @param {decimal} actualPercentageComplete 
 * @param {decimal} plannedPercentageComplete 
 * @param {Boolean} isRounded 
 * @returns 
 */
export const getDesignProgressIcon = (actualPercentageComplete, plannedPercentageComplete, isRounded = false) => {
  
  // changing values to Math.round
  if(isRounded){
    actualPercentageComplete=Math.round(actualPercentageComplete);
    plannedPercentageComplete=Math.round(plannedPercentageComplete);
  }

  // calculate the difference
  let diffPercentageComplete = plannedPercentageComplete - actualPercentageComplete;

  let icon;

  if (diffPercentageComplete !== null) {
    if (diffPercentageComplete < 0) {
      icon = <FaCheck />;
    }
    else if (diffPercentageComplete >= 0 && diffPercentageComplete < designProgressThreshold['low']) {
      icon = <FaCheck />;
    }
    else if (diffPercentageComplete >= designProgressThreshold['low'] && diffPercentageComplete < designProgressThreshold['high']) {
      icon = <FaExclamation />;
    }
    else if (diffPercentageComplete >= designProgressThreshold['high']) {
      icon = <FaTimes />;
    }
  }

  return icon;
}


/**
 * Gets design spend text
 * @param {decimal} actualSpend 
 * @param {decimal} currentBudget 
 * @returns {string}
 */
export const getDesignSpendText = (actualSpend, currentBudget) => {
  // calculate the difference
  let percentageDiff = relDiff(actualSpend, currentBudget);
  //console.log(actualSpend, currentBudget, percentageDiff);

  let text;

  if (percentageDiff > 100) {
    text = "Your actual spend is higher than expected based on predicted design progress.";
  }
  else if (percentageDiff > 15 && percentageDiff < 100) {
    text = "Your actual spend is lower than expected based on predicted design progress.";
  }
  else {
    text = "Your actual spend is close to expected based on predicted design progress.";
  }

  return text;
}


/**
 * Gets design spend colour
 * @param {decimal} actualSpend 
 * @param {decimal} currentBudget 
 * @returns {string}
 */
export const getDesignSpendColour = (actualSpend, currentBudget) => {
  // calculate the difference
  let percentageDiff = relDiff(actualSpend, currentBudget);
  //console.log("per diff design spend", percentageDiff);

  // initial set to default colour
  let colour = completionStatusColour['default'];

  if (percentageDiff > 100) {
    colour = completionStatusColour['low'];
  }
  else if (percentageDiff > 15 && percentageDiff < 100) {
    colour = completionStatusColour['high'];
  }
  else {
    colour = completionStatusColour['medium'];
  }

  return colour;
}

/**
 * Gets design spend icon based on actual spend and current budget
 * @param {decimal} actualSpend 
 * @param {decimal} currentBudget 
 * @returns {icon}
 */
export const getDesignSpendIcon = (actualSpend, currentBudget) => {
  let percentageDiff = relDiff(actualSpend, currentBudget);
  let icon;

  if (percentageDiff > 100) {
    icon = <FaTimes />;
  }
  else if (percentageDiff > 15 && percentageDiff < 100) {
    icon = <FaCheck />;
  }
  else {
    icon = <FaExclamation />;
  }

  return icon;
}

/**
 * Gets design hours text
 * @param {decimal} actualHours
 * @param {decimal} plannedHours
 * @returns {string}
 */
 export const getDesignHoursText = (actualHours, plannedHours) => {
  // calculate the difference
  let percentageDiff = relDiff(actualHours, plannedHours);

  let text;

  if (percentageDiff > 100) {
    text = "Actual hours is higher than expected based on predicted design progress.";
  }
  else if (percentageDiff > 15 && percentageDiff < 100) {
    text = "Actual hours is lower than expected based on predicted design progress.";
  }
  else {
    text = "Actual hours is close to expected based on predicted design progress.";
  }

  return text;
}

/**
 * Gets design hours colour
 * @param {decimal} actualHours 
 * @param {decimal} plannedHours
 * @returns {string}
 */
 export const getDesignHoursColour = (actualHours, plannedHours) => {
  // calculate the difference
  let percentageDiff = relDiff(actualHours, plannedHours);
  //console.log("per diff design spend", percentageDiff);

  // initial set to default colour
  let colour = completionStatusColour['default'];

  if (percentageDiff > 100) {
    colour = completionStatusColour['low'];
  }
  else if (percentageDiff > 15 && percentageDiff < 100) {
    colour = completionStatusColour['high'];
  }
  else {
    colour = completionStatusColour['medium'];
  }

  return colour;
}

/**
 * Gets design hours icon based on actual and planned hours
 * @param {decimal} actualHours
 * @param {decimal} plannedHours
 * @returns {icon}
 */
export const getDesignHoursIcon = (actualHours, plannedHours) => {
  let percentageDiff = relDiff(actualHours, plannedHours);
  let icon;

  if (percentageDiff > 100) {
    icon = <FaTimes />;
  }
  else if (percentageDiff > 15 && percentageDiff < 100) {
    icon = <FaCheck />;
  }
  else {
    icon = <FaExclamation />;
  }

  return icon;
}


/**
 * Gets the difference in days between the planned and estimated delivery dates
 * @param {date} estimatedDelivery
 * @param {date} plannedDelivery
 * @returns 
 */
export const getDesignDeliveryDifference = (estimatedDelivery, plannedDelivery) => {
  // calculate the difference in days
  let estimateDate = new Date(estimatedDelivery);
  let plannedDate = new Date(plannedDelivery);
  let diffTime = estimateDate.getTime() - plannedDate.getTime();
  let diffDays = diffTime / (1000 * 3600 * 24);

  return Math.round(diffDays, 0);
}

/**
 * Gets the design delivery colour
 * @param {date} estimatedDelivery 
 * @param {date} plannedDelivery 
 * @returns {string}
 */
export const getDesignDeliveryColour = (estimatedDelivery, plannedDelivery) => {
  // calculate the difference in days
  let diffDays = getDesignDeliveryDifference(estimatedDelivery, plannedDelivery);
  //console.log("per diff design delivery", diffDays);

  // initial set to default colour
  let colour = completionStatusProgressColour['default'];

  if (diffDays > 5) {
    colour = completionStatusProgressColour['low'];
  }
  else if (diffDays >= 0 && diffDays >= 5) {
    colour = completionStatusProgressColour['medium'];
  }
  else {
    colour = completionStatusProgressColour['high'];
  }

  return colour;
}

/**
 * Gets the design delivery icon
 * @param {*} estimatedDelivery 
 * @param {*} plannedDelivery 
 * @returns 
 */
export const getDesignDeliveryIcon = (estimatedDelivery, plannedDelivery) => {
  // calculate the difference in days
  let estimateDate = new Date(estimatedDelivery);
  let plannedDate = new Date(plannedDelivery);
  let diffTime = estimateDate.getTime() - plannedDate.getTime();
  let diffDays = diffTime / (1000 * 3600 * 24);

  let icon;

  if (diffDays > 5) {
    icon = <FaTimes />;
  }
  else if (diffDays >= 0 && diffDays >= 5) {
    icon = <FaExclamation />;
  }
  else {
    icon = <FaCheck />;
  }

  return icon;
}

/**
 * Gets the risk category icon
 * @param {*} riskCategory 
 * @returns 
 */
export const getRiskCategoryIcon = (riskCategory) => {
  let icon;

  if (riskCategory.toLowerCase() === 'high') {
    icon = <FaTimesCircle />;
  }
  else if (riskCategory.toLowerCase() === 'medium') {
    icon = <FaExclamationCircle />;
  }
  else if (riskCategory.toLowerCase() === 'low') {
    icon = <FaCheckCircle />;
  }
  else {
    icon = <FaCheckCircle />;
  }

  return icon;
}

/**
 * Gets the risk category background colour
 * @param {*} riskCategory 
 * @returns 
 */
export const getRiskCategoryColour = (riskCategory) => {
  let colour;

  if (riskCategory?.toLowerCase() === 'high') {
    colour = BG_COLOUR.high;
  }
  else if (riskCategory?.toLowerCase() === 'medium') {
    colour = BG_COLOUR.medium;
  }
  else if (riskCategory?.toLowerCase() === 'low') {
    colour = BG_COLOUR.low;
  }
  else if (!riskCategory) {
    colour = BG_COLOUR.low;
  }
  else {
    colour = BG_COLOUR.low;
  }

  return colour;
}

/**
 * Gets the risk background colour
 * @param {*} value 
 * @returns 
 */
 export const getRiskColourInverse = (value) => {
  let colour;
  if (value > 0) {
    colour = BG_COLOUR.high;
  }
  else if (value < 0) {
    colour = BG_COLOUR.low;
  }
  else if (value == 0) {
    colour = BG_COLOUR.equal;
  }
  else if (isNaN(value)) {
    colour = BG_COLOUR.low;
  }
  else {
    colour = BG_COLOUR.low;
  }
  return colour;
}

/**
 * Gets discipline icon
 * @param {*} discipline 
 * @returns 
 */
export const getDisciplineIcon = (discipline) => {
  if (discipline) {
    return disciplineIcon[discipline.toLowerCase()];
  }
  else {
    return disciplineIcon['default'];
  }
};

/**
 * Lighthouse Tooltip
 * @param {*} props 
 * @returns 
 */
export const LighthouseTooltip = (props) => {
  const { content, isActive = true, width = "auto", offsetX = 0, offsetY = 0, placement = "RightCenter" } = props;
  return <>
    {
      isActive && content 
       ? <TooltipComponent content={content} position={placement} offsetX={offsetX} offsetY={offsetY} width={width}>{props.children}</TooltipComponent>
       : props.children
    }
  </>;
}

function relDiff(a, b) {
  return a - b === 0 ? 0 : 100 * Math.abs((a - b) / b) || 'input error';
}


/**
 * Formats cost values to thousands or millions
 * @param {*} cost 
 * @param {*} scale 
 * @returns 
 */
export const formatCost = (cost, scale, denomination) => {
  if (denomination === DENOMINATION.auto) {
    if (cost < 10000) {
      return (cost / 1000).toFixed(1) + 'k'; // if value < 10000, convert to k
    } else if (cost >= 10000 && cost < 100000) {
      return (cost / 1000).toFixed(0) + 'k'; // convert to k for number from >= 10k < 100k 
    } else if (cost >= 100000 && cost < 1000000) {
      return (cost / 1000000).toFixed(2) + 'm'; // convert to m for number from >= 100k < 1 million 
    }else if (cost >= 1000000 && cost < 10000000) {
      return (cost / 1000000).toFixed(1) + 'm'; // convert to m for number from > 1 million < 10 million 
    } else if (cost >= 10000000) {
      return (cost / 1000000).toFixed(0) + 'm'; // if value >= 10000000, convert to m
    }
  }
  else if (denomination === DENOMINATION.millions){
    return (cost / 1000000).toFixed(scale) + 'm'; // convert to k for number from > 1 million 
  }
  else if (denomination === DENOMINATION.thousands){
    return (cost / 1000).toFixed(scale) + 'k'; // convert to k for number from > 1000 < 1 million 
  }
}

/**
 * Gets the cost background colour
 * @param {*} value 
 * @returns 
 */
 export const getCostColourInverse = (value) => {
  let colour;
  
  if (value > 5000) {
    colour = BG_COLOUR.high;
  }
  else if (value > 0 && value <= 5000) {
    colour = BG_COLOUR.medium;
  }
  else if ( value < 0) {
    colour = BG_COLOUR.low;
  }
  else if ( value == 0) {
    colour = BG_COLOUR.equal;
  }
  else if (isNaN(value)) {
    colour = BG_COLOUR.low;
  }
  else {
    colour = BG_COLOUR.equal;
  }
  return colour;
}

/**
 * Gets the time impact background colour
 * @param {*} value 
 * @returns 
 */
 export const getTimeColourInverse = (value) => {
  let colour;

  if (value > 5) {
    colour = BG_COLOUR.high;
  }
  else if (value > 0 && value <= 5) {
    colour = BG_COLOUR.medium;
  }
  else if ( value < 0) {
    colour = BG_COLOUR.low;
  }
  else if ( value == 0) {
    colour = BG_COLOUR.equal;
  }
  else if (isNaN(value)) {
    colour = BG_COLOUR.low;
  }
  else {
    colour = BG_COLOUR.equal;
  }
  return colour;
}

/**
 * Returns the project specific formatted date
 * @param {*} dateToFormat 
 * @param {bool} addTime 
 * @returns {string}
 */
 export const getFormattedDate = (dateToFormat, addTime = false) => {
   if(dateToFormat){
    return format(Date.parse(dateToFormat),getGlobalFormat().concat(addTime?' HH:mm':''));
   }
}
/**
 * Returns the project specific date format
 * @returns {string}
 */
export const getGlobalFormat = () =>{
  const currentState = store.getState();
  const projectDateFormat = currentState?.projects?.entities[currentState?.projects?.selectedProjectId]?.dateFormat??'dd/MM/yyyy';
  return projectDateFormat;
}
/**
 * Returns the createscenario specific formatted date
 * @param {*} dateToFormat 
  * @returns {string}
 */
 export const getDDMMDate = (dateToFormat) => {
  if(dateToFormat){
    var dtFormat = new Date(dateToFormat.split('T')[0]);
    let dateMD = `${dtFormat.getDate()}/${dtFormat.getMonth() + 1}`;
    return dateMD;
  }
}

/**
 * returns true if valid JSON string
 * @param {string} str 
 * @returns 
 */
export const isJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}