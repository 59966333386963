import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { useSelector } from "react-redux";
import { withStyles } from '@material-ui/core/styles';

import AlertListContainer from '../AlertListContainer';
import PropertyViewer from '../PropertyViewer/PropertyViewer';
import { setSelectedTab } from '../../../../../redux/slices/infoSlice';
import { makeStyles } from '@material-ui/core/styles';
import infoPanelBg from './info-panel-bg.svg';

import { useDispatch } from "react-redux";

import './InformationPanel.scss';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const InfoTabs = withStyles({
  root: {
    
  },
  indicator: {
    backgroundColor: '#7262FD',
  }
})(Tabs);

const InfoTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 200,
    maxWidth: null,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: '#65789B',
    '&:hover': {
      color: '#7262FD',
      opacity: 1,
    },
    '&$selected': {
      color: '#7262FD',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#7262FD',
    },
  },
  selected: {},
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))((props) => <Tab disableRipple {...props} />);

/**
 * Information panel
 * @param {*} props 
 * @returns 
 */
const InformationPanel = (props) => {
  const { title = "Property Viewer",
    showCounter = false,
    count = 0,
  } = props;
  const { selectedTab } = useSelector(state => state.info);
  const [value, setValue] = React.useState("PropertyViewer");
  const ddvToggle = props.ddvToggle;


  const dispatch = useDispatch();

  /**
   * Tab change event
   * @param {*} event 
   * @param {*} newValue 
   */
  const handleTabChange = (event, newValue) => {
    dispatch(setSelectedTab(newValue));
  };

  /**
   * Selected tab changed in state
   */
  useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  return (
    <div className="information-panel" style={{ backgroundImage: `url(${infoPanelBg})`}}>
      <div className="nav-section">
        <InfoTabs
          value={value}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <InfoTab icon={<DescriptionOutlinedIcon />} label={<span className="tab-label">Properties</span>}  value="PropertyViewer" />
          <InfoTab icon={<ReportProblemOutlinedIcon />} label={<span className="tab-label">Alerts List</span>} value="AlertList" />
        </InfoTabs >
      </div>
      <div className="content-section">
        <TabPanel value={value} index="PropertyViewer">
          <PropertyViewer />
        </TabPanel>
        <TabPanel value={value} index="AlertList">
          <AlertListContainer ddvToggle={ddvToggle} />
        </TabPanel>
      </div>
    </div>
  );
};

export default InformationPanel;