import React from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useDispatch, useSelector } from "react-redux";
import { setActivityChanges, selectActivityChangeById , removeActivityChanges } from '../../../../../redux/slices/createScenarioSlice';
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';

const ScheduledActivityDateChange = React.memo(props => {
  const { activity } = props;
  const existingActivityScheduleChange = useSelector((state) => selectActivityChangeById(state, activity.activityId));
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const datePickerRef = React.useRef();
  const dispatch = useDispatch();

  /**
   * Sets the new planned start date for this activity by creating a new or updating 
   * existing activity schedule change
   * @param {object} args 
   */
  const newPlannnedStartDateChange = (args) => {
    // Get the new picked date
    let changedStartDate;

    if(args.value !== null) {
      changedStartDate = args.value.toISOString()
    }

    // Create obj for earliest date change
    let activityNewChanges = {
        "activityId": activity.activityId,
        "activityCode": activity.activityCode,
        "earliestStartDate": changedStartDate ? changedStartDate : undefined,
        "disciplineName" : activity.parentItem.parentItem.parentItem.name,
        "plannedStartDate" : activity.plannedStartDate
    };

    if (existingActivityScheduleChange === undefined) {
      //dispact to set activity state when the earliest start date is unselected
      dispatch(setActivityChanges(activityNewChanges));
    }
    else {
      if (args.value === null && existingActivityScheduleChange.shortestDuration === undefined && existingActivityScheduleChange.longestDuration === undefined) {
        // dispatch to remove state
        dispatch(removeActivityChanges(activity.activityId));
      }
      else {
        // dispatch to state
        dispatch(setActivityChanges(activityNewChanges));
      }
    }
  }

  /**
   * Date picker created
   * @param {object} args 
   */
  const datePickerCreated = (args) => {
    // if there is an existing match in the schedule changes then use that date
    if (existingActivityScheduleChange && existingActivityScheduleChange.earliestStartDate) {
      datePickerRef.current.value = existingActivityScheduleChange.earliestStartDate;
    }
  }

  return (
    <DatePickerComponent ref={datePickerRef}
      created={datePickerCreated}
      format={selectedProject.dateFormat}
      change={newPlannnedStartDateChange}></DatePickerComponent>
  );
});

/**
 * Compares the props to see if they are equal
 * so component only gets re-rendered if changed
 * @param {object} prevProps 
 * @param {object} nextProps 
 * @returns Boolean
 */
const areEqual = (prevProps, nextProps) => {
  if (nextProps.activity == prevProps.activity) {
    return false;
  }
  else {
    return true;
  }
}

export default React.memo(ScheduledActivityDateChange, areEqual);