import React from 'react';
import './InfoMessage.scss';
import { InfoCircleIcon } from '../../../../Icons';

const InfoMessage = (props) => {

  return (
    <div className="info-message-container">
      <InfoCircleIcon className="info-icon" />
      <div className="info-message">{props.message}</div>
    </div>
  );
}

export default InfoMessage;