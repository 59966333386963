import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import AuthorizationService from '../../../../../components/Shared/molecules/Authorization/AuthorizationService';
import DesignProductionViewerTable from '../DesignProductionViewerTable';
import { fetchActivityScheduleMilestone, selectAllActivityScheduleMilestone } from '../../../../../redux/slices/activityScheduleMilestoneSlice';
import { fetchActivityCompletionStatus, selectAllActivityCompletionStatus } from '../../../../../redux/slices/activityCompletionStatusSlice';
import { fetchPackagePctCompleteCalcTypes, selectAllPackagePctCompleteCalcTypes } from '../../../../../redux/slices/packagePctCompleteCalcTypesSlice';
import { fetchDisciplines  } from '../../../../../redux/slices/disciplinesSlice';
import { fetchPackages } from '../../../../../redux/slices/packagesSlice';
import { fetchPhases } from '../../../../../redux/slices/phasesSlice';
import { updateActivityCompletionStatus } from '../../../../../redux/slices/updateActivityCompletionStatusSlice';
import { removeActivityChanges, setIsChecked, setCompletionStatusId, setMilestoneCompletionStatusId, selectAllActivityChanges, updateAllActivity } from '../../../../../redux/slices/activityChangesSlice';
import Popover from '@material-ui/core/Popover';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useMsal } from "@azure/msal-react";
import './DesignProductionViewerTableContainer.scss';

const DesignProductionViewerTableContainer = (props) => {
  const dispatch = useDispatch();
  const { toastNotificationHandle } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const activityScheduleMilestone = useSelector(selectAllActivityScheduleMilestone);
  const activityCompletionStatus = useSelector(selectAllActivityCompletionStatus);
  const packagePctCompleteCalcTypes = useSelector(selectAllPackagePctCompleteCalcTypes);
  const [ disciplineOptions ,setDisciplineOptions ] = useState([]);
  const [ packageOptions ,setPackageOptions ] = useState([]);
  const [ phaseOptions ,setPhaseOptions ] = useState([]);
  const { error, isLoading } = useSelector(state => state.phases);
  const { instance, accounts } = useMsal();
  const { accessToken, authorized } = useSelector(state => state.authorization);
  const activityChanges  = useSelector((state) => selectAllActivityChanges(state));
  const { isCheckedValue, completionStatusId, milestoneCompletionStatusId } = useSelector((state) => state.activityChanges );
  const [anchorEl, setAnchorEl] = useState(null);
  const [addOpen, setAddOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    //Acquire access token
    if(accessToken == null || authorized == 'No'){
      AuthorizationService({dispatch : dispatch, instance: instance, accounts: accounts});
    }
 }, [accounts]);

   /**
   * Fetches the activities, phases, packages and disciplines
   */
  useEffect(() => {
    if (accessToken){
      let disOptions = []; 
      let pkgOptions = []; 
      let phaseOptions = []; 
     dispatch(fetchActivityCompletionStatus({ projectId: selectedProjectId, accessToken: accessToken}))
        .then((response) =>{ dispatch(fetchPackagePctCompleteCalcTypes({ projectId: selectedProjectId, accessToken: accessToken}))
        .then((response) => { dispatch(fetchActivityScheduleMilestone({projectId : selectedProjectId, accessToken : accessToken}))
        .then((response) => { dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken}))
          .then((response) => {response.payload.forEach((d) => {
            if(!d.disciplineName.includes('External Milestones')){
              let item = {
                text: d.disciplineName,
                value: d.disciplineId
              }
              disOptions.push(item)}
            })
              dispatch(fetchPackages({ projectId: selectedProjectId, accessToken: accessToken}))
             .then((response) => {response.payload.forEach((d) => {
              if(!d.packageName.includes('KEM')){
                let item = {
                  text: d.description,
                  value: d.packageId,
                  disciplineId: d.disciplineId
                }
                pkgOptions.push(item)}
              })
              dispatch(fetchPhases({ projectId: selectedProjectId, accessToken: accessToken}))
              .then((response) => {response.payload.forEach((d) => {
               let item = {
                 text: d.phaseName,
                 value: d.phaseId,
                 packageId: d.packageId
               }
               phaseOptions.push(item)})
               setDisciplineOptions([...disOptions]);
               setPackageOptions([...pkgOptions]);
               setPhaseOptions([...phaseOptions]);
               setIsReady(true);
            }); 
        }); 
     });
   })
  })
 });
}
  }, [dispatch, selectedProjectId, accessToken]);

  useEffect(() => {
    if (accessToken){
      dispatch(setIsChecked(0));
      dispatch(setCompletionStatusId(1));
      dispatch(setMilestoneCompletionStatusId(2));
      dispatch(removeActivityChanges());
    }
  }, [dispatch, selectedProjectId, accessToken]);


  const confirmSubmitChanges = (args) =>{
    if (accessToken && authorized){
      setAnchorEl(args);
      setAddOpen(true);
      
      let allActivityChanges = [...activityChanges];
      if (isCheckedValue === 2){
        allActivityChanges = activityChanges?.filter((act)=> (act.activityType == 'Task' && act.liveStatusId !== completionStatusId) || ((act.activityType == 'Milestone' || act.activityType == 'Finish Milestone') && act.liveStatusId !== milestoneCompletionStatusId))
        .map((pct)=>{ return {...pct, completionStatusId: pct.activityType == 'Task' ? completionStatusId : (pct.activityType == 'Milestone' || pct.activityType == 'Finish Milestone') ? milestoneCompletionStatusId : completionStatusId} }) 

        let updatedData = activityChanges?.map((act)=>{ return {...act, completionStatusId: completionStatusId, milestoneCompletionStatusId: milestoneCompletionStatusId}})
        dispatch(updateAllActivity(updatedData));  
      }
      else{
        allActivityChanges = activityChanges?.filter((act)=> (act.activityType == 'Task' && act.liveStatusId !== act.completionStatusId) || ((act.activityType == 'Milestone' || act.activityType == 'Finish Milestone') && act.liveStatusId !== act.milestoneCompletionStatusId))
        .map((pct)=>{ return {...pct, completionStatusId: pct.activityType == 'Task' ? pct.completionStatusId : (pct.activityType == 'Milestone' || pct.activityType == 'Finish Milestone') ? pct.milestoneCompletionStatusId : pct.completionStatusId} }) 
      }
      
      var allTaskChanges = activityChanges?.filter((act)=> act.activityType == 'Task');
      var allMilestoneChanges = activityChanges?.filter((act)=> act.activityType == 'Milestone' || act.activityType == 'Finish Milestone');
      
      dispatch(updateActivityCompletionStatus({ projectId:selectedProjectId, activityChanges: allActivityChanges, accessToken: accessToken}))
      .then(response => { 
          if (response.payload.activitiesCount === (allActivityChanges.length * 2))
          {
                dispatch(fetchActivityScheduleMilestone({projectId : selectedProjectId, accessToken : accessToken}))
                .then((response)=>{
                dispatch(setIsChecked(0));
                dispatch(setCompletionStatusId(1));
                dispatch(setMilestoneCompletionStatusId(2));
                  setTimeout(() => {
                    dispatch(removeActivityChanges());
                  }, 5000);
                setAnchorEl(null);
                setAddOpen(false);
                toastNotificationHandle(1, allActivityChanges.length, allTaskChanges.length, allMilestoneChanges.length);
              });
          }
          else {
            setAnchorEl(null);
            setAddOpen(false);
            toastNotificationHandle(2);
          }
      })
    }
  }

  return (
    <>
     { !isReady && 
        <CircularProgressBar />
      }
       { error && !isLoading &&
        <ErrorMessage message="Unable to display Activity table" />
      }
      { !isLoading && !error && isReady &&
        <DesignProductionViewerTable confirmSubmitChanges={confirmSubmitChanges}  disciplineOptions={disciplineOptions} packageOptions={packageOptions} phaseOptions={phaseOptions} taskData={activityScheduleMilestone} activityCompletionStatus={activityCompletionStatus} packagePctCompleteCalcTypes={packagePctCompleteCalcTypes}/>
      }
      {addOpen &&
        <Popover
        id='state-change-load'
        open={addOpen}
        anchorEl={anchorEl}   
        anchorReference='none'
        BackdropProps={{ invisible: false }}
        >
            <div className="state-change-load-popover">
                <CircularProgressBar />
            </div>
        </Popover>
      }
    </>
  );
}

export default DesignProductionViewerTableContainer;