import React, {useState, useEffect} from 'react';
import ScenarioManagerSelectorList from '../ScenarioManagerSelectorList';
import './UserGenScenarioSelector.scss';

const UserGenScenarioSelector = (props) => {
  const { selectedProject, scenarios, selectedScenarioId } = props;
  const [userGenScenario, setUserGenScenario] = useState([]);

  /* 
  * filters the scenario details on type selected i.e "user-generated" scenarios
  */
  const getScenarios = () => {
    if (scenarios.length > 0 ) {
      setUserGenScenario(scenarios.filter(scenario => scenario.scenarioType.toLowerCase() === "user-generated"));
    } else {
      setUserGenScenario([]);
    }
  }

  // calls the getScenarios function whenever the scenrio selection is changed
  useEffect(() => {
    getScenarios();
  }, [scenarios]);
  
  return (
 <div className='scenariolist-body'>
      <ScenarioManagerSelectorList selectedScenarioId={selectedScenarioId} type={'Scenario List'} scenarios={userGenScenario} selectedProject={selectedProject} />
 </div>
  )
}

export default UserGenScenarioSelector;