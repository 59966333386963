import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCheck } from 'react-icons/fa';
import './IFCDelayIcon.scss';
var classNames = require('classnames');

const IFCDelayIcon = (props) => {
  const { scale } = props;
  const iconSizes = [1, 2, 3, 4, 5];

  return (<div className="ifc-delay-icon-container">
    {scale == 0 &&
      <span className="ifc-no-delay" style={{ background: '#55AC7D' }}><FaCheck /></span>
    }
    {scale > 0 &&
      <div className="ifc-delay-indicator">
        {iconSizes.map(iconSize => {
          return <FontAwesomeIcon key={iconSize}
            icon="exclamation-triangle"
            className={classNames("idi-icon", "idi-" + iconSize, { "idi-icon-filled": scale + 1 > iconSize })}
          />
        })}
      </div>
    }
  </div>);
}

export default IFCDelayIcon;