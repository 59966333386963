import React from 'react';
import buildingAnimation from './building-animation.gif';

const BuildingAnimation = (props) => {
  const { width = '100%', height = '100%' } = props;

  return (
    <img width={width} height={height} src={buildingAnimation} alt="Building Animation" />
  )
};

export default BuildingAnimation;