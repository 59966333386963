import React, { useEffect, useState } from 'react';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { fetchScenarioOptions, selectAllScenarioOptions } from '../../../../../redux/slices/scenarioOptionsSlice';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import ScenarioOptions from '../ScenarioOptions';

const   ScenarioOptionsContainer = (props) => {
  const { scenarioId } = props;
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(state => state.scenarioOptions);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(undefined);
  const scenarioOptions = useSelector(state => selectAllScenarioOptions(state, scenarioId));
  
  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

   /**
   * Fetches the scenario options for a scenario
   */
  useEffect(() => {
    if (accessToken){
      dispatch(fetchScenarioOptions({ projectId: selectedProjectId, scenarioId: scenarioId, accessToken: accessToken}));
    }
  }, [dispatch, selectedProjectId, scenarioId, accessToken]);

  return (
    <>
     { isLoading && 
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to display design scenario options" />
      }
      { !isLoading && !error && scenarioOptions.length > 0 &&
       <ScenarioOptions options={scenarioOptions} />
      }
    </>
  );
}

export default ScenarioOptionsContainer;