import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ScenarioOptionsChart from '../ScenarioOptionsChart/ScenarioOptionsChart';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { fetchDisciplineImpact } from '../../../../../redux/slices/activityOptimisationChangeSlice';
import './ScenarioOptionsChartContainer.scss';

const ScenarioOptionsChartContainer = (props) => {
  const {isLoading , disciplineImpact}  = useSelector(state => state.activityOptimisationChange);
  const dispatch = useDispatch();
  const { selectedScenarioOptionId } = useSelector(state => state.scenarioOptionsComparison);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const { selectedProjectId, sidebarOpen } = useSelector(state => state.projects);
  const [procesedData, setProcesedData] = useState();

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

   /**
   * Fetches the discipline impact for the selected scenario
   */
    useEffect(() => {
      if (accessToken && selectedScenarioOptionId){
        dispatch(fetchDisciplineImpact({ projectId: selectedProjectId, scenarioOptionId: selectedScenarioOptionId, accessToken: accessToken}));
      }
    }, [dispatch, selectedProjectId, selectedScenarioOptionId, accessToken]);

    useEffect(() => {
      if(disciplineImpact?.length > 0) {
        setProcesedData(proccesData(disciplineImpact));
      }
    }, [disciplineImpact]);


  const proccesData = (rawData) => {
    const uniqueWeeklyDates = [...new Set(rawData.map(item => item.weeklyDate))];
    const uniqueDisciplinesName = [...new Set(rawData.map(item => item.disciplineName))];

    const returnData = {
      weeklyDates: uniqueWeeklyDates,
      disiplines: uniqueDisciplinesName,
      data: rawData
    }

    return returnData;
  }

  return (
    <>
    {isLoading && <CircularProgressBar/>}
    {!isLoading && disciplineImpact && procesedData &&
    <div className='scenario-option-chart-container' >
      <div className='legend-title' >
        <span className='low-impact-title-alignment' style={sidebarOpen?{left: '293%'}:{left: '345%'}}>Low Impact</span>
        <span className='high-impact-title-alignment' style={sidebarOpen?{left: '263%'}:{left: '315%'}}>High Impact</span>
    </div>
      <ScenarioOptionsChart data={procesedData} />
    </div>
    } 
    </>
  );
}

export default ScenarioOptionsChartContainer;