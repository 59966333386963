import React from 'react';
import { ArrowLeftIcon ,CloseIcon } from '../../../../Icons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import './LighthouseDialog.scss';
var classNames = require('classnames');

const LighthouseDialog = (props) => {
  const { isVisible = false, 
          width = '70%',
          height = '500px',
          title = 'Dialog',
          noPadding = false,
          backButton = false,
          closeButton = false,
       } = props;

  const closeButtonAction=()=>{
    props.closeButtonAction();
  }

  const backButtonAction=()=>{
    props.backButtonAction();
  }

  const header = () => {
    return <>
      <div className="dialog-header-wrapper">
        {backButton && <div className="dialog-back-button"><ArrowLeftIcon onClick={backButtonAction}/></div> }
        <div className="dialog-title">
          {title}
        </div>
        {closeButton && <div className="dialog-close-button"><CloseIcon onClick={closeButtonAction}/></div> }
      </div>
    </>
  }

  const content = () => {
    return <>
      <div className={classNames('dialog-content', { 'no-padding': noPadding })}>
        {props.children}
      </div>
    </>
  }

  return (
    <>
      <DialogComponent
        width={width}
        height={height}
        className="dialog-container"
        zIndex={9999}
        isModal={true}
        visible={isVisible}
        header={header}
        content={content}
      >

      </DialogComponent>
    </>
  )
};

export default LighthouseDialog;