import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import { selectAlertById, fetchAlerts, setSelectedAlertId, dismissOrUndismissAlert } from '../../../../../redux/slices/alertsSlice';
import { fetchPhases } from '../../../../../redux/slices/phasesSlice';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { setSelectedAlertIdInManager } from '../../../../../redux/slices/alertSelectionSlice';
import './ConfirmUnDismissAlertWizard.scss';


const ConfirmUnDismissAlertWizard = React.memo(props => {
  const { selectedAlertId, selectedAlertStatus, selectedPhaseId, selectedPackageId, selectedAlertType, selectedAlertDriver } = props;
  const { alertReason } = useSelector(state => state.alertReason);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  /**
   * Closes the undismiss alerts window
   */
  const cancelUnDismissAlert = () => {
    props.cancelUnDismissAlert();
  }
  
    /**
   * Passes the trigger to confirm the UnDismiss Alert, Calls the API and hides the wizard
   */
     const UnDismissAlertAPI = () => {
      if (accessToken) {
        let alertData = {
          alertReason : alertReason,
          phaseId : selectedPhaseId,
          packageId : selectedPackageId,
          alertType : selectedAlertType,
          driver : selectedAlertDriver
          }
        dispatch(dismissOrUndismissAlert({ projectId: selectedProjectId, alertId: selectedAlertId, alertStatus: selectedAlertStatus, alertData : alertData,  accessToken: accessToken }))
        .then((response) => dispatch(setSelectedAlertIdInManager(selectedAlertId)))
        .then((responsess) => dispatch(setSelectedAlertId(undefined)))
        .then((response) => dispatch(fetchAlerts({ projectId: selectedProjectId, accessToken: accessToken })))
        .then((response) => dispatch(fetchPhases({ projectId: selectedProjectId, accessToken: accessToken })));
      }
      props.unDismissAlertClick();
    }

  return (
    <>
      <div className="confirm-undismiss-alert-wizard">
        <div className="confirm-undismiss-alert-content">Re-Activating this alert will change the inputs to the forecast impact model. Changes will be reflected in the outputs of the next scheduled analysis run.</div>
        <div className="confirm-undismiss-alert-footer">
          <SecondaryLighthouseButton onClick={cancelUnDismissAlert}>No Thanks</SecondaryLighthouseButton>
          <PrimaryLighthouseButton className='confirm-btn' onClick={UnDismissAlertAPI}>Yes</PrimaryLighthouseButton>
        </div>
      </div>
    </>
  );
})

const areEqual = (prevProps, nextProps) => {
  if (nextProps.selectedAlertId == prevProps.selectedAlertId && nextProps.alertReason == prevProps.alertReason) {
    return true;
  }
  else {
    return false;
  }
}

export default React.memo(ConfirmUnDismissAlertWizard, areEqual);