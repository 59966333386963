import React, {useState, useEffect} from 'react';
import ScenarioManagerSelectorList from '../ScenarioManagerSelectorList';
import './SystemGenScenarioSelector.scss';

const SystemGenScenarioSelector = (props) => {
  const { selectedProject, scenarios, selectedScenarioId } = props;
  const [systemGenScenario, setSystemGenScenario] = useState([]);

  /* 
  * filters the scenario details on type selected i.e 'system-gen' scenarios
  */
  const getScenarios = () => {
    if (scenarios.length > 0 ) {
      setSystemGenScenario(scenarios.filter(scenario => scenario.scenarioType.toLowerCase() === "system"));
    } else {
      setSystemGenScenario([]);
    }
  }

  // calls the getScenarios function whenever the scenrio selection is changed
  useEffect(() => {
    getScenarios();
  }, [scenarios]);
  
  return (
 <div className='scenariolist-body'>
      <ScenarioManagerSelectorList selectedScenarioId={selectedScenarioId} type={'Scenario of the day'} scenarios={systemGenScenario} selectedProject={selectedProject} />
      <hr color='#D9E9F8'></hr>
 </div>
  )
}

export default SystemGenScenarioSelector;