import React, { useEffect, useState } from 'react';
import DesignProductionViewerChart from '../DesignProductionViewerChart';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { fetchPackagePctCompleteCalcTypes, selectAllPackagePctCompleteCalcTypes } from '../../../../../redux/slices/packagePctCompleteCalcTypesSlice';
import { fetchPhases, selectAllPhases } from '../../../../../redux/slices/phasesSlice';
import { fetchPackages, selectAllPackages } from '../../../../../redux/slices/packagesSlice';
import { fetchDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

//let dpvData = require('./designProductionViewer.mock.json');

const DesignProductionViewerChartContainer = (props) => {
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(state => state.phases);
  const phases = useSelector(selectAllPhases);
  const packages = useSelector(selectAllPackages);
  const packagePctCompleteCalcTypes = useSelector(selectAllPackagePctCompleteCalcTypes);
  const { selectedProjectId } = useSelector(state => state.projects);

  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  const [processedSchedule, setProcessedSchedule] = useState(null);
  const [isScheduleReady, setIsScheduleReady] = useState(false);
  

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

   /**
   * Fetches the packages and phases
   */
  useEffect(() => {
    if (accessToken){
      dispatch(fetchPackagePctCompleteCalcTypes({projectId : selectedProjectId, accessToken : accessToken}))
      dispatch(fetchPackages({ projectId: selectedProjectId, accessToken: accessToken}));
      dispatch(fetchPhases({ projectId: selectedProjectId, accessToken: accessToken}));
      dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken}));
    }
  }, [dispatch, selectedProjectId, accessToken]);

/**
 * Process the schedule from tree to gantt
 * only when the data is ready
 */
  useEffect(() => {
    if(!isLoading && phases?.length > 0 && packages?.length > 0){
      if(!isScheduleReady){
        // Translate schedule to gantt
        setProcessedSchedule(processSchedule(packages, phases));
      }
    }
  }, [isLoading]);

  useEffect(() => {
    // Set display state
    if(processedSchedule){
      setIsScheduleReady(true);
    }
  }, [processedSchedule])


  /**
   * Processes the schedule into elements for the 
   * consumption by design production chart viewer
   * @param {*} wbss
   * @returns 
   */
  const processSchedule = (packages, phases) => {
    let scheduleData = [];
    let alerts = []; 
  

    //console.log(phases)

    let integerId = 1;
    // sort packages by discipline
    //let packagesWithIntIds = packages.sort((a,b) => (a.disciplineId > b.disciplineId) ? 1 : ((b.disciplineId > a.disciplineId) ? -1 : 0)).map(pkg => ({ ...pkg, packageIntId: integerId++ }));

    // sort packages by start date
    let packagesWithIntIds = packages.sort((a,b) => (a.completionStatus.plannedStartDate > b.completionStatus.plannedStartDate) ? 1
       : ((b.completionStatus.plannedStartDate > a.completionStatus.plannedStartDate) ? -1 : 0)).map(pkg => ({ ...pkg, packageIntId: integerId++ }));

    // sort phases by planned start date
    let phasesWithIntIds = phases.sort((a,b) => (a.completionStatus.plannedStartDate > b.completionStatus.plannedStartDate) ? 1 : ((b.completionStatus.plannedStartDate > a.completionStatus.plannedStartDate) ? -1 : 0)).map(phase => ({ ...phase, phaseIntId: integerId++ }));


    // create a common key external milestone package
    let kemPackage = { 
                        id: 0,
                        packageId: '032c925b-f8e6-4934-9bb7-d99a94b5beee',
                        name: 'Key External Milestones',
                        filterDiciplineId: '00000000-0000-0000-0000-000000000001',
                        pkgAlertCount: 0,
                        highestPkgAlertType: 'Secondary',
                        phases: []
                      };

    //console.log("kems", filteredKems, "phases", filteredPhases)

    // get an array of unique alerts from the phases
    for (let phase of phases){
      if (phase.hasOwnProperty('alerts') && phase.alerts.length > 0) {
        for (let alert of phase.alerts){
          if (!alerts.find(a => a.alertId === alert.alertId)){
            alerts.push(alert);
          }
        }
      }
    }

   // console.log(packagesWithIntIds, phasesWithIntIds, alerts);

    for (let pkg of packagesWithIntIds){
      //console.log("pkg", pkg);
      let e = {};
      let pkgAlertCount = 0;
      let highestPkgAlertType = 'Secondary';
      let isKemPackage = false;

      e.id = pkg.packageIntId;
      e.packageId = pkg.packageId;
      e.disciplineId = pkg.disciplineId;
      e.name = pkg.description;
     // e.startDate = pkg.completionStatus.plannedStartDate;
     // e.endDate = pkg.completionStatus.plannedEndDate;
      e.phases = [];
      e.plannedPercentageComplete = pkg.completionStatus.plannedPercentageComplete;
      e.activityBasedActualPercComplete = pkg.completionStatus.activityBasedActualPercComplete;
      e.deliverableBasedActualPercComplete = pkg.completionStatus.deliverableBasedActualPercComplete;
      let packagePhases = phasesWithIntIds.filter(function (el) {
        return el.packageId == pkg.packageId
      });

      let ePhases = [];

        var selectedPhasePackagePctCalcType = packagePctCompleteCalcTypes.find(x=>x.packageId == pkg.packageId)?.packagePctCalcType;
        let pctType = 0; 
        if(selectedPhasePackagePctCalcType === 0 || selectedPhasePackagePctCalcType === 1){
          pctType = 0;
          e.PkgActualPercentageComplete = pkg.completionStatus.deliverableBasedActualPercComplete;      
        }
        if(selectedPhasePackagePctCalcType === 2){
          pctType = 1;
          e.PkgActualPercentageComplete = pkg.completionStatus.activityBasedActualPercComplete;      
        }

        e.pctType = pctType;
     
      for (let phase of packagePhases){
        //console.log("original phase " + phase.phaseName, phase);
        let p = {}
        p.id = phase.phaseIntId;
        p.phaseId = phase.phaseId;
        p.name = phase.phaseName;
        p.disciplineId = phase.disciplineId;
        p.packageId = pkg.packageId;
        p.packageName = pkg.packageName;
        p.startDate = new Date(phase.completionStatus.plannedStartDate);
        p.endDate = new Date(phase.completionStatus.plannedEndDate);
        p.actualPercentageComplete = pctType === 1? phase.completionStatus.activityBasedActualPercComplete : phase.completionStatus.deliverableBasedActualPercComplete;
        p.plannedPercentageComplete = phase.completionStatus.plannedPercentageComplete;
        p.activityBasedActualPercComplete = phase.completionStatus.activityBasedActualPercComplete;
        p.deliverableBasedActualPercComplete = phase.completionStatus.deliverableBasedActualPercComplete;
        //console.log("processed phase " + phase.phaseName, p, phase.completionStatus.plannedStartDate, phase.completionStatus.plannedEndDate);
        p.pctType = pctType;
        // if this is a KEM phase then add to the common KEM package instead
        if (phase.wbsType.toUpperCase() === "KEM"){
          isKemPackage = true;
          kemPackage.phases.push(p);
        }
        else{
          let predecessors = [];
        if (phase.hasOwnProperty('predecessors') && phase.predecessors.length > 0) {
          for (let pred of phase.predecessors){
            let predPhase = phasesWithIntIds.find(p => p.phaseId === pred.phaseId);
            if(predPhase)
              predecessors.push(predPhase.phaseIntId + pred.relationship);
          }
        }

        // get the alerts for this phase
        let phaseAlerts = alerts.filter(a => a.phaseId === p.phaseId);
        
        if (phaseAlerts.length > 0){
          // add to the package alert count
          pkgAlertCount = pkgAlertCount + phaseAlerts.length;

          //console.log("phase alerts", phaseAlerts);
          p.alerts = phaseAlerts;

          // determine the highest alert type
          if (p.alerts.some(e => e.alertType === 'Primary')) {
            p.highestAlertType = "Primary";
            highestPkgAlertType = "Primary";
          }
          else{
            p.highestAlertType = "Secondary";
          }
        }

        let preds = predecessors.join();
        p.predecessors = preds;

        ePhases.push(p);
        }
      }

      // add package to data if not a kem package
      if (!isKemPackage){
        e.phases = ePhases;
        e.alertCount = pkgAlertCount;
        e.highestAlertType = highestPkgAlertType;
        e.filterDiciplineId = e.disciplineId;

        scheduleData.push(e);
      }
    }
    
   //console.log(scheduleData, kemPackage);
   // add the KEM package
   kemPackage.phases.sort((a,b) => (a.endDate > b.endDate) ? 1 : ((b.endDate > a.endDate) ? -1 : 0));
   //scheduleData.unshift(kemPackage);

   return scheduleData;
  }


  return (
    <>
     { isScheduleReady !== true && 
        <CircularProgressBar />
      }
      { error && isScheduleReady !== true &&
        <ErrorMessage message="Unable to display design production viewer" />
      }
      { isScheduleReady === true && !error &&
        <DesignProductionViewerChart taskData={processedSchedule} />
      }
    </>
  );
}

export default DesignProductionViewerChartContainer;