import React, { useEffect, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { TickCirecleIcon } from '../../../../Icons';
import SupportModal from '../../molecules/SupportModal/SupportModal';
import './Support.scss';

const Support = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSupport, setOpenSupport] = useState(false);

  const handleSupportModal = (event) => {
    setAnchorEl(event);
    setOpenSupport(true);
  }

  const closeSupportModal = () => {
    setAnchorEl(null);
    setOpenSupport(false);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const SupportList = [
    {
      "Id": "1",
      "Name": "Provide Feedback",
      "Desc": "Have you got a problem and would like to ask a question or would you like to provide some feedback to the team? ",
      "Click": "to provide feedback.",
      "Icon": "edit",
      "Href": "https://forms.office.com/Pages/ResponsePage.aspx?id=DwvXh_xekUmgZeIFvD2zCOJwFOQtxu1Oghzc3_hFhJdUM1M1Qlc3RFY3QURKUlhFRjRMSjdMMVZOTy4u"
    },
    {
      "Id": "2",
      "Name": "Training and Guidance",
      "Desc": "Don't know where to start? Need some support navigating the tool? ",
      "Click": "to explore and learn more.",
      "Icon": "info-circle",
      "Href": "https://atkins.sharepoint.com/:p:/r/sites/RailLighthouseTeam/_layouts/15/Doc.aspx?sourcedoc=%7B2B6AD9A9-9C55-42B9-BAF7-90AFE1E9BC13%7D&file=LH%20User%20Manual_v2_gauhar-%2026-02-2022.pptx&action=edit&mobileredirect=true"
    }
  ]

  return (
    <>
      <div className="support-container">
        <div onClick={handleSupportModal} className={props.sidebarOpen ? "support-link open" : "support-link collapsed"}>
          <TickCirecleIcon height="24px" width="24px" />
          {props.sidebarOpen && <span>
            Support
          </span>}
          </div>
      </div>
      <Popover
        id='need-support'
        open={openSupport}
        onClose={closeSupportModal}
        anchorEl={anchorEl}
        anchorReference='none'
        BackdropProps={{ invisible: false }}
      >
        <div className="need-support-popover">
          <SupportModal SupportList={SupportList} closeWidget={closeSupportModal} />
        </div>
      </Popover>
    </>
  );
};

export default Support;