import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { RightArrowIcon } from '../../../../../Icons';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import LighthouseDialog from '../../../../../components/Shared/organisms/LighthouseDialog';
import { setSelectedInterfacePointId } from '../../../../../redux/slices/interfacePointsSlice';
import { fetchFullActivitySchedule } from '../../../../../redux/slices/fullActivityScheduleSlice';
import InterfacePointEditorContainer from '../../organisms/InterfacePointEditorContainer/InterfacePointEditorContainer'; 
import InterfaceFullProgrammeContainer from '../../organisms/InterfaceFullProgrammeContainer/InterfaceFullProgrammeContainer';
import '../../../../../theme/Forms/formstyles.scss';
import './CreateInterfacePoint.scss';

const CreateInterfacePoint = (props) => {
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const [ selectedActivityFromFullProgramme, setSelectedActivityFromFullProgramme] = useState([]);
  const [ addInterfacePointVisibility, setAddInterfacePointVisibility] = useState(false);
  const [ fullProgrammeVisibility, setFullProgrammeVisibility] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  /**
 * Fetches the full activity schedule
 */
 useEffect(() => {
  if (accessToken){
    dispatch(fetchFullActivitySchedule({projectId: selectedProjectId, accessToken: accessToken}));
  }
}, [dispatch, selectedProjectId, accessToken]);

  /**
  * Handles the add new interface point click
  */
  const closeCreateInterface =()=>{
    props.closeCreateInterface();
  }

  /**
  * Handles the add new interface point click
  */
  const addNewInterfacePointClick = () => {
    setAddInterfacePointVisibility(true);
    dispatch(setSelectedInterfacePointId(undefined));
  }

  /**
   * Cancel adding interface point
   */
   const closeAddInterfacePoint = () => {
    props.closeCreateInterface();
    setAddInterfacePointVisibility(false);
  }

  /**
   * back button from add interface
   */
   const backFromAddInterfacePoint = () => {
    setSelectedActivityFromFullProgramme([]);
    setAddInterfacePointVisibility(false);
  }

    /**
  * Handles the add new interface point click
  */
  const fullProgrammeClick = () => {
    setFullProgrammeVisibility(true);
  }

  /**
   * Cancel adding interface point
   */
   const closeFullProgramme = () => {
    props.closeCreateInterface();
    setFullProgrammeVisibility(false);
  }

  /**
   * back button from full program
   */
   const backFromFullProgram = () => {
    setFullProgrammeVisibility(false);
  }

  /**
   * sets the activity link from the full programme and redirects to the AddInterface screen
   */
  const selectActivityLink =(selectedActivity) =>{
    setFullProgrammeVisibility(false);
    setAddInterfacePointVisibility(true);
    setSelectedActivityFromFullProgramme(selectedActivity);
    dispatch(setSelectedInterfacePointId(undefined));
  }

  return (
  <>
    <div>
      <form className="ci-form">
        <label className="ci-label"> Please select one method to create the new interface point below. </label> 
        <div className="ci-prop-section">
          <table className="ci-prop-table">
            <tbody>
              <tr onClick={fullProgrammeClick}>
                <td>
                  <div className='row-heading'>Create interface from programme</div>
                  <div className='row-icons'>
                    Existing programme milestones
                    <InfoTooltip message={'Create interface from the existing programme milestones'}  iconSize={15}/>
                    <RightArrowIcon className='right-arrow-icon'/>
                  </div>
                </td>
              </tr>
              <tr onClick={addNewInterfacePointClick}>
                <td>
                  <div className='row-heading'>Create interface manually</div>
                  <div className='row-icons'>
                    Lighthouse programme milestones
                    <InfoTooltip message={'Create interface manually and link activities from Lighthouse programme milestones'}  iconSize={15}/>
                    <RightArrowIcon className='right-arrow-icon'/>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {fullProgrammeVisibility &&
            <LighthouseDialog isVisible={fullProgrammeVisibility} backButton={true} backButtonAction={backFromFullProgram} closeButton={true} closeButtonAction={closeFullProgramme} title="Create a New Interface" width="63%" height="800px">
              <InterfaceFullProgrammeContainer selectActivityLink={selectActivityLink} closeFullProgramme={closeFullProgramme} />
            </LighthouseDialog>
          }
          {addInterfacePointVisibility &&
            <LighthouseDialog isVisible={addInterfacePointVisibility} backButton={true} backButtonAction={backFromAddInterfacePoint} closeButton={true} closeButtonAction={closeAddInterfacePoint} title="Create a New Interface" width="63%" height="800px">
              <InterfacePointEditorContainer selectedActivityFromFullProgramme={selectedActivityFromFullProgramme} closeAddInterfacePoint={closeAddInterfacePoint} />
            </LighthouseDialog>
          }
        </div>
      </form>
    </div>
  </>
  );
}

export default CreateInterfacePoint;