import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Badge from '@material-ui/core/Badge';
import DisciplineSelectorContainer from '../DisciplineSelectorContainer';
import { useSelector } from "react-redux";
import { FilterIcon ,AdjustmentIcon} from '../../../../../Icons';
import './DisciplineFilter.scss';

const DisciplineFilter = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { filteredDisciplineIds } = useSelector(state => state.disciplines);
  const open = Boolean(anchorEl);
  const id = open ? 'discipline-filter-popover' : undefined;

  /**
  * Opens the discipline filter
  * @param {object} event 
  */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the discipline filter
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="filter-anchor">
        <Badge  anchorOrigin={{vertical: 'bottom', horizontal: 'left' }} badgeContent={filteredDisciplineIds?.length} color="primary" overlap="circular">
          <div onClick={handleClick} className="filter-discipline" >
            <AdjustmentIcon />
            <div>Discipline Filters</div>
          </div>
        </Badge>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        BackdropProps={{ invisible: false }}
      >
        <div className="filter-content">
          <DisciplineSelectorContainer closeFilter={handleClose} />
        </div>
      </Popover>
    </>
  );
};

export default DisciplineFilter;