import React, { useEffect, useRef, useState } from 'react';
import { selectAllScenarioOptions } from '../../../../../redux/slices/scenarioOptionsSlice';
import { setSelectedScenarioOptionId } from '../../../../../redux/slices/scenarioOptionsComparisonSlice';
import { useDispatch, useSelector } from "react-redux";
import './ScenarioOptionSelector.scss';

const ScenarioOptionSelector = (props) => {
  const { scenarioId, pagename } = props;
  const { selectedScenarioOptionId } = useSelector(state => state.scenarioOptionsComparison);
  const scenarioOptions = useSelector((state) => selectAllScenarioOptions(state, scenarioId));
  const dispatch = useDispatch();

  /**
 * Handles scenario option selection change event
 * @param {*} scenarioOptionId 
 */
  const handleScenarioOptionSelectorChange = (optionId) => {
    // gets and sets the scenario option Id
    dispatch(setSelectedScenarioOptionId(optionId));
  };

  useEffect(() => {
    dispatch(setSelectedScenarioOptionId(undefined));
  }, []);

  useEffect(() => {
    if (!selectedScenarioOptionId) {
      if (scenarioOptions.length > 0) {
        let baseOptionId = scenarioOptions.find(scenarioOption => scenarioOption.optionName === 'Base Option')?.optionId;
        dispatch(setSelectedScenarioOptionId(baseOptionId));
      }
    }
  }, [selectedScenarioOptionId, scenarioOptions]);

  return (
    <div className="sos-container">
      {
        pagename === "create scenario" &&
        <span className='list-instruction'>Changes History</span>
      }
      {
        pagename != "create scenario" &&
        <span className='list-instruction'>Select an option to view a breakdown.</span>
      }
      {
        scenarioOptions.map((scenarioOption, i) =>
        (
          <div key={scenarioOption.optionId}
            onClick={() => handleScenarioOptionSelectorChange(`${scenarioOption.optionId}`)}
            className={`${scenarioOption.optionId === selectedScenarioOptionId ? "active list-container" : "list-container"}`}
          >
            <span className='option-name'>{scenarioOption.optionName}</span>
          </div>
        ))
      }
    </div>
  );
}

export default ScenarioOptionSelector;