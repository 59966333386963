import React from 'react';
import './InlineInfoMessage.scss';
import { InlineInfoMessageIcon } from '../../../../Icons';

const InlineInfoMessage = (props) => {

  return (
    <div className="inline-info-message-container">
      <div className="inline-info-message">
        <InlineInfoMessageIcon className="inline-info-icon" />
        {props.message}
      </div>
    </div>
  );
}

export default InlineInfoMessage;