import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import ResourceCapacityChart from '../../molecules/ResourceCapacityChart';
import { selectDisciplineById, fetchDisciplineResourceCapacity } from '../../../../../redux/slices/disciplinesSlice';
import { CloseIcon } from '../../../../../Icons';
import './ResourceCapacityChartContainer.scss';

const ResourceCapacityChartContainer = (props) => {
    const { disciplineId } = props;
    const dispatch = useDispatch();
    const { isCapacityLoading, capacityError, resourceCapacity } = useSelector(state => state.disciplines);
    const disciplineItem = useSelector((state) => selectDisciplineById(state, disciplineId));
    const { selectedProjectId } = useSelector(state => state.projects);
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const [resourceCapacityFilter, setResourceCapacityFilter] = useState([]);

    const handleClose = () => {
        props.closePopover();
    }

    const handleResourceCapacityFiltering = (event) => {
      if(resourceCapacityFilter.includes(event.target.innerText)){
        setResourceCapacityFilter(arrayRemove(resourceCapacityFilter,event.target.innerText));
        
      }
      else{
        const resourceCapacityArray = [...resourceCapacityFilter, event.target.innerText];
        setResourceCapacityFilter(resourceCapacityArray);
      }
    }

    const arrayRemove = (arr, value) => {
      return arr.filter((data)=>{
          return data != value;
      });
    }
    
    const processData = (rawData) => {
      let filteredCapacity = rawData?.item1;
      let filteredTimedelay = rawData?.item2;
      let requirement= [];
      let balanced= [];
      let original = [];
      let modified = [];
      let delayDate = [];
      let delayTime;
      let balancedEnd;
      let requirementEnd;
      let segStart = 0;
      let segEnd = 0;
      let requiredMarker;
      let balancedMarker;
      let sortedCapacity = filteredCapacity?.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      let sortedTimedelay = filteredTimedelay?.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      if(sortedCapacity && sortedTimedelay){
        for (let data of sortedCapacity){
          requirement.push({hours: data.requirement, date: data.date});
          balanced.push({hours: data.balanced, date: data.date});
          original.push({hours: data.original, date: data.date});
          modified.push({hours: data.modified, date: data.date});
        }
        for (let data of sortedTimedelay){
          delayDate.push({hours: data.hours, date: data.date});
        }
        delayTime =`Time Delay: ${sortedTimedelay[0]?.timeDelay} Days`;
        requirementEnd = sortedTimedelay[0]?.date;
        balancedEnd = sortedTimedelay?.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).reverse()[0]?.date;
        segStart = delayDate[0]?.hours + (delayDate[0]?.hours/100);
        segEnd = delayDate[0]?.hours + (delayDate[0]?.hours/10);
        requiredMarker = { hours : 0, date : requirementEnd};
        balancedMarker = { hours : 0, date : balancedEnd};

      if(resourceCapacityFilter.includes('Requirement (Original Planned hours + Excess hours)')){
       requirement= [];
       requiredMarker = {};
      }
      if(resourceCapacityFilter.includes('Original Capacity')){
       original = [];
      }
      if(resourceCapacityFilter.includes('Balanced Requirement')){
       balanced= [];
       balancedMarker = {};
      }
      if(resourceCapacityFilter.includes('Modified Capacity')){
       modified = [];
      }
      if(resourceCapacityFilter.includes('Time Delay')){
        delayDate = [];
        segStart = '';
        segEnd = '';
        delayTime = '';
        requirementEnd = null;
        balancedEnd = null;
      }
      }
      const processed = {
         requirement : requirement,
         balanced : balanced,
         original : original,
         modified : modified,
         delayDate : delayDate,
         delayTime : delayTime,
         balancedEnd : balancedEnd,
         requirementEnd : requirementEnd,
         segStart : segStart,
         segEnd : segEnd,
         requiredMarker : requiredMarker ,
         balancedMarker : balancedMarker 
      };
      return processed;
    }

    useEffect(() => {
      /**
       * Acquire access token
       */
      if (accounts.length > 0) {
        const request = {
          scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
          account: accounts[0]
        };
        instance.acquireTokenSilent(request).then(response => {
          setAccessToken(response.accessToken);
        }).catch(error => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(request).then(response => {
              setAccessToken(response.accessToken);
            });
          }
        });
      }
    }, [accounts]);
  
    /**
     * fetching the resource capacity for the discipline
     */
    
    useEffect(() => {
      if (accessToken) {
        dispatch(fetchDisciplineResourceCapacity({ projectId: selectedProjectId, disciplineId: disciplineId, accessToken: accessToken }))
      }
    }, [dispatch, selectedProjectId, disciplineId, accessToken]);


    return (
      <>
      <div className="chart-container">
        <div className='popover-header'>
          <div>
          <h4>{disciplineItem?.disciplineName} - Resource Capacity</h4>
          </div>
              <div className='popover-close' onClick={handleClose}>
                    <CloseIcon />
              </div>
        </div>
        <div className='popover-content'>
          {isCapacityLoading &&
            <CircularProgressBar />
          }
          { !isCapacityLoading && capacityError &&
            <ErrorMessage message="Unable to display resource capacity graph" />
          }
          { !isCapacityLoading && !capacityError && !resourceCapacity &&
            <ErrorMessage message="No data available for this discipline" />
          }
          { !isCapacityLoading && !capacityError && resourceCapacity &&
          <div className='content-wrapper'>
            <div className='legend-container' >
              <div className='legends' onClick={handleResourceCapacityFiltering}>
                      <div className={resourceCapacityFilter.includes('Requirement (Original Planned hours + Excess hours)') ? "legend inactive" : "legend req"}></div>
                      <h6>Requirement (Original Planned hours + Excess hours)</h6>
              </div>
              <div className='legends' onClick={handleResourceCapacityFiltering}>
                    <div className={resourceCapacityFilter.includes('Balanced Requirement') ? "legend inactive" : "legend bal"}></div>
                    <h6>Balanced Requirement</h6>
              </div>
              <div className='legends' onClick={handleResourceCapacityFiltering}>
                    <div className={resourceCapacityFilter.includes('Time Delay') ? "legend inactive" : "legend delay"} ></div>
                    <h6>Time Delay</h6>
              </div>
              <div className='legends' onClick={handleResourceCapacityFiltering}>
                    <div className={resourceCapacityFilter.includes('Original Capacity') ? "legend inactive-dashed" : "legend original"}></div>
                    <h6>Original Capacity</h6>
              </div>
              <div className='legends' onClick={handleResourceCapacityFiltering}>
                    <div className={resourceCapacityFilter.includes('Modified Capacity') ? "legend inactive-dashed" : "legend modified"} ></div>
                    <h6>Modified Capacity</h6>
              </div>     
                    
            </div>
           <ResourceCapacityChart resourceCapacityData={processData(resourceCapacity)} /> 
          </div>
          }
        </div>
    </div>

    </> 
    );
};

export default ResourceCapacityChartContainer;