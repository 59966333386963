import React from 'react';
import './ProjectAvatar.scss';

const ProjectAvatar = (props) => {
  const { projectName } = props;
  return (
    <div className="pav-container">
      {projectName && projectName.charAt(0).toUpperCase()}
    </div>
  )
};

export default ProjectAvatar;