import React, { useEffect } from "react";

const HotJar = () => {
  useEffect(() => {
    //console.log(process.env.REACT_APP_ENABLE_STATS)
    if (process.env.REACT_APP_ENABLE_STATS === 'true') {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 2698141, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }
  }, []);

  return <></>;
};
export default HotJar;