import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { fetchRelatedAlerts, selectAllRelatedAlerts } from '../../../../../redux/slices/relatedAlertsSlice';
import RelatedAlert from '../RelatedAlert';
import './RelatedAlertContainer.scss'

export const RelatedAlertContainer = (props) => {
  const { currentAlertId, alert } = props;
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(state => state.relatedAlerts);
  const relatedAlerts = useSelector((state) => selectAllRelatedAlerts(state, currentAlertId));
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [relatedAlertsFilter, setRelatedAlertsFilter] = useState([]);
  const [accessToken, setAccessToken] = useState(null);

  const handleRelatedAlertsFiltering = (event) => {
    if(relatedAlertsFilter.includes(event.target.innerText)){
      setRelatedAlertsFilter(arrayRemove(relatedAlertsFilter,event.target.innerText)); 
    }
    else{
      const relatedAlertsArray = [...relatedAlertsFilter, event.target.innerText];
      setRelatedAlertsFilter(relatedAlertsArray);
    }
  }

  const arrayRemove = (arr, value) => {
    return arr.filter((data)=>{
        return data != value;
    });
  }

  const processRelatedAlerts = (alerts) => {
    let relatedAlerts = alerts;
    if(relatedAlertsFilter.includes('Same Phase & Package')){
      relatedAlerts = relatedAlerts.slice().filter(x=>x.index !== 1)
     }
     if(relatedAlertsFilter.includes('Different Phase & Same Package')){
      relatedAlerts = relatedAlerts.slice().filter(x=>x.index !== 2)
     }
     if(relatedAlertsFilter.includes('Same Discipline, Different Phase & Package')){
       relatedAlerts = relatedAlerts.slice().filter(x=>x.index !== 3)
     }
     if(relatedAlertsFilter.includes('1° seperation from package (different disc)')){
       relatedAlerts = relatedAlerts.slice().filter(x=>x.index !== 4)
     }
     return relatedAlerts;
  }

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  useEffect(() => {
    if (accessToken && currentAlertId){
      dispatch(fetchRelatedAlerts({ projectId: selectedProjectId, alertId: currentAlertId, accessToken: accessToken}));
    }
  }, [dispatch, selectedProjectId, accessToken, currentAlertId]);

return (
    <>
     {isLoading && 
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to display Related Alerts" />
      }
      {!isLoading && !error && relatedAlerts.length > 0 &&
      <>
         <div className='legend-container' >
              <div className='legends' onClick={handleRelatedAlertsFiltering}>
                      <div className={relatedAlertsFilter.includes('Same Phase & Package') ? "legend inactive" : "legend one"}></div>
                      <h6 className={relatedAlertsFilter.includes('Same Phase & Package') ? "text-inactive" : "text-active"}>Same Phase & Package</h6>
              </div>
              <div className='legends' onClick={handleRelatedAlertsFiltering}>
                    <div className={relatedAlertsFilter.includes('Different Phase & Same Package') ? "legend inactive" : "legend two"}></div>
                    <h6 className={relatedAlertsFilter.includes('Different Phase & Same Package') ? "text-inactive" : "text-active"}>Different Phase & Same Package</h6>
              </div>
              <div className='legends' onClick={handleRelatedAlertsFiltering}>
                    <div className={relatedAlertsFilter.includes('Same Discipline, Different Phase & Package') ? "legend inactive" : "legend three"} ></div>
                    <h6 className={relatedAlertsFilter.includes('Same Discipline, Different Phase & Package') ? "text-inactive" : "text-active"} >Same Discipline, Different Phase & Package</h6>
              </div>
              <div className='legends' onClick={handleRelatedAlertsFiltering}>
                    <div className={relatedAlertsFilter.includes('1° seperation from package (different disc)') ? "legend inactive" : "legend four"}></div>
                    <h6 className={relatedAlertsFilter.includes('1° seperation from package (different disc)') ? "text-inactive" : "text-active"} >1° seperation from package (different disc)</h6>
              </div>   
            </div>
            <RelatedAlert alert={alert} alertData={processRelatedAlerts(relatedAlerts)} />
      </>
      } 
     </>
  )
}
export default RelatedAlertContainer;