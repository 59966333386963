import React, { useEffect, useState } from 'react';
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { selectProjectById, setSelectedProjectId } from '../../redux/slices/projectsSlice';
import ScenarioManagerContainer from './components/organisms/ScenarioManagerContainer';
import { appInsights } from '../../AppInsights';
import PageHeader from '../../components/Shared/organisms/PageHeader';
import { selectAllScenarios } from '../../redux/slices/scenariosSlice';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import ScenarioProperties from './components/molecules/ScenarioProperties';
import ScenarioOptionsContainer from './components/molecules/ScenarioOptionsContainer/ScenarioOptionsContainer';
import ScenarioProcessingInfo from './components/molecules/ScenarioProcessingInfo/ScenarioProcessingInfo';
import ScenarioErrorInfo from './components/molecules/ScenarioErrorInfo';
import ScenarioActions from './components/molecules/ScenarioActions';
import ScenarioDraftInfo from './components/atoms/ScenarioDraftInfo';
import { setSelectedScenarioId, selectScenarioById } from '../../redux/slices/scenariosSlice';
import CircularProgressBar from '../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../components/Shared/molecules/ErrorMessage';
import './ProjectScenariosPage.scss';

let trackData = require('../../appTrackerInfo.json');
var classNames = require('classnames');

function ScenarioTabPanel(props) {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

ScenarioTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ScenarioTabs = withStyles({
  root: {

  },
  indicator: {
      backgroundColor: 'var(--color-additional)',
      marginBottom: '7px',
      height: '3px'
    }
})(Tabs);

const ScenarioTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 'fit-content',
    maxWidth: null,
    fontWeight: '450',
    padding: '20px',
    color: 'var(--color-tab-text)',
    '&:hover': {
      color: 'var(--color-tab-text)'
    },
    '&$selected': {
      color: 'var(--color-tab-text)'
    },
    '&:focus': {
      color: 'var(--color-tab-text)',
    },
  },
  selected: {},
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))((props) => <Tab disableRipple {...props} />);

export const ProjectScenariosPage = (props) => {

  const { match: { params } } = props;
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { selectedProjectId } = useSelector(state => state.projects);
  const { LHRoles, projects } = useSelector(state => state.authorization);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const dispatch = useDispatch();
  const scenarios = useSelector(selectAllScenarios);
  const { isLoading, error, selectedScenarioId } = useSelector(state => state.scenarios);
  const scenario = useSelector((state) => selectScenarioById(state, selectedScenarioId));
  const [value, setValue] = React.useState("Properties");

  useEffect(() => {
    if (params.projectId) {
      dispatch(setSelectedProjectId(params.projectId));
    }
  }, []);

  /*
  * when the page loads or project is changed,
  * system-gen scenario loads by default
  */
  useEffect(()=>{
    if(!selectedScenarioId){
      dispatch(setSelectedScenarioId(scenarios.filter(x=>x.scenarioType === 'System' && x.projectId === selectedProjectId)[0]?.scenarioId));
    }
  },[selectedProjectId, scenarios])

  /*
  * sets the scenario id,
  * even when switching between pages
  */
  useEffect(() => {
    if(scenarios.length > 0 && selectedScenarioId){
      dispatch(setSelectedScenarioId(scenarios.filter(x=>x.scenarioId === selectedScenarioId && x.projectId === selectedProjectId)[0]?.scenarioId));
    }
  }, [selectedProjectId, selectedScenarioId, scenarios]);

  useEffect(() => {
    if (account && !selectedProject) {
      appInsights.trackPageView({
        name:`${trackData.pages[2].name}`,   
        properties: { 
                   Project: 'Selection',
                   ProjectName: 'Selection',
                   User:`${account.username}`,
                   Username: `${account.name}`,
                   Role: 'Selection'  
        }});
    }
    if (account && selectedProject) {
      appInsights.trackPageView({
        name:`${trackData.pages[2].name}`,   
        properties: { 
                   Project: `${selectedProjectId}`,
                   ProjectName: `${selectedProject?.projectName}`,
                   User:`${account.username}`,
                   Username: `${account.name}`,
                   Role: `${LHRoles.find( x => x === 'GlobalAdmin')?'GlobalAdmin' : LHRoles[projects?.indexOf(selectedProjectId.toUpperCase())]?.toString()}` 
        }});
    }
  }, [account, selectedProject]);

    /**
* Tab change event
* @param {*} event 
* @param {*} newValue 
*/
const handleTabChange = (event, newValue) => {
  setValue(newValue);
};

  if (process.env.REACT_APP_ENABLE_INTERFACE_POINTS === 'true'){
    return (
      <>
        {selectedProject &&
          <div className="scenario-page">
            <div className="scenario-page-header">
              <PageHeader
                pageTitle="Optimisation"
                margin="43"
              >
              </PageHeader>
            </div>
            <div className="scenario-manager-panel">
              <ScenarioManagerContainer projectId={selectedProjectId} />
            </div>
            <div className="scenario-main">
              <div className={classNames('scenario-container')}>
                {isLoading &&
                  <CircularProgressBar />
                }
                {error && !isLoading &&
                  <ErrorMessage message="Unable to display Related Alerts" />
                }
                {selectedScenarioId && !error && !isLoading &&
                  <>
                    <div className="scenario-header">
                        <ScenarioTabs
                          value={value}
                          indicatorColor="primary"
                          textColor="primary"
                          onChange={handleTabChange}>
                          <ScenarioTab label={<span className="tab-label">Properties</span>} value="Properties" />
                          <ScenarioTab label={<span className="tab-label">Options</span>} value="Options" />
                        </ScenarioTabs>
                    </div>
                    <ScenarioTabPanel value={value} index="Properties" className="scenario-content">
                    {scenario &&
                      <>
                        <div className="scenario-option-manager-container">
                          <div className='scenario-option-content-section'>
                            <ScenarioProperties currentScenarioId={selectedScenarioId} scenario={scenario} />
                          </div>
                              <div className='scenario-comparision-action-section'>
                                <ScenarioActions scenario={scenario} />
                              </div>
                        </div>
                      </>
                    }
                  </ScenarioTabPanel>
                  <ScenarioTabPanel value={value} index="Options" className="scenario-content">
                    {scenario &&
                      <>
                        {scenario.scenarioStatus.toLowerCase() === "pending" &&
                          <>
                            <div className="scenario-option-manager-container">
                              <div className='scenario-option-content-section'>
                                <ScenarioProcessingInfo />
                              </div>
                              <div className='scenario-comparision-action-section'>
                                <ScenarioActions scenario={scenario} />
                              </div>
                            </div>
                          </>
                        }
                        {scenario.scenarioStatus.toLowerCase() === "failed" &&
                          <>
                            <div className="scenario-option-manager-container">
                              <div className='scenario-option-content-section'>
                                <ScenarioErrorInfo />
                              </div>
                              <div className='scenario-comparision-action-section'>
                                <ScenarioActions scenario={scenario} />
                              </div>
                            </div>
                          </>
                        }
                      {(scenario.scenarioStatus.toLowerCase() === "draft" && scenario.optionCount === 1) &&
                        <>
                          <div className="scenario-option-draft-manager-container">
                            <div className='scenario-option-draft-content-section'>
                              <ScenarioDraftInfo  currentScenarioId={selectedScenarioId} scenario={scenario}/>
                            </div>
                            <div className='scenario-comparision-draft-action-section'>
                              <ScenarioActions scenario={scenario} />
                            </div>
                          </div>
                        </>
                      }
                        {((scenario.scenarioStatus.toLowerCase() !== "pending" && scenario.scenarioStatus !== "failed") 
                        || (scenario.scenarioStatus.toLowerCase() === "draft" && scenario.optionCount > 1)) &&
                          <>
                            <div className="scenario-option-manager-container">
                              <div className='scenario-option-content-section'>
                                <ScenarioOptionsContainer scenarioId={scenario.scenarioId} />
                              </div>
                              <div className='scenario-comparision-action-section'>
                                <ScenarioActions scenario={scenario} />
                              </div>
                            </div>
                          </>
                        }
                      </>
                    }
                  </ScenarioTabPanel>
                  </>
                }
              </div>
            </div>
  
          </div>
        }
      </>
    );
    }
};

export default ProjectScenariosPage;