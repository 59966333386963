import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { 
  phaseProgressEndpoint,
} from '../../api/apiRoutes';

  /**
 * 
 * @returns Fetches alert phase Progress
 */
export const fetchPhaseProgress = createAsyncThunk(
  'project/fetchphaseprogress', async (params, thunkAPI) => {
     try {
        const response = await api.get(phaseProgressEndpoint(params.projectId, params.alertId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});


/**
 * Create alert adapter
 */
const phaseProgressAdapter = createEntityAdapter({
  selectId: (alert) => alert.alertId,
})

/**
 * Create alert slice / reducers
 */
const phaseProgressSlice = createSlice({
  name: 'phaseProgress',
  initialState: phaseProgressAdapter.getInitialState({
    isLoading: false,
    error: undefined,
    phaseProgress: [],
  }),
  reducers:
  {
    setPhaseProgress: (state, action) => {
      state.phaseProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPhaseProgress.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
        state.phaseProgress = undefined;
      })
      .addCase(fetchPhaseProgress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.phaseProgress = action.payload;
      })
      .addCase(fetchPhaseProgress.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Alert selectors
 */
export const {} = phaseProgressAdapter.getSelectors((state) => state.alerts);

/**
 * Alert actions
 */
export const {
  setPhaseProgress
} = phaseProgressSlice.actions;

/**
 * Export reducer
 */
export default phaseProgressSlice.reducer;