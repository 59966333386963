import React from 'react';
import { Link } from 'react-router-dom';
import './ProjectProgressCategoryHeader.scss';
import { ReactComponent as RightArrowIcon } from '../../../../../Icons/right-arrow-icon.svg';

const ProjectProgressCategoryHeader = (props) => {
  const { title, link, Icon } = props;

  return (
    <Link to={link} className={`no-text-decoration component-shadow ${!link ? "disabled-link" : ""}`}>
      <div className="pp-cat-header-container">
        <div className="header-title-container">
          {Icon && <div className="header-icon"><Icon /></div>}
          <div className="header-title">{title}</div>
        </div>
        <RightArrowIcon className="link-icon" />
      </div>
    </Link>);
}

export default ProjectProgressCategoryHeader;