import { ChartComponent, SeriesCollectionDirective, StripLine, SeriesDirective, Inject, Tooltip, DataLabel, LineSeries, DateTimeCategory, Zoom, Crosshair, ScrollBar  } from '@syncfusion/ej2-react-charts';
import React from 'react';
import {  useSelector } from "react-redux";
import CircularProgressBar from '../CircularProgressBar';
import { getGlobalFormat } from '../../../../../utils';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { chartTooltipThemeSettings } from '../../../../../theme/Charts';

const ResourceCapacityChart = (props) => {
    const data = props.resourceCapacityData;
    const { isLoading, error } = useSelector(state => state.disciplines);
    const majorGridLines= { color: '#F6F6F6',width: 1};
    const yAxisStripLines = [{ start: data?.requirementEnd, end: data?.balancedEnd, isSegmented: true, segmentStart: data.segStart, segmentEnd: data.segEnd, text : data.delayTime.toString(),  rotation: 360, color: "#ffffff", sizeType: 'Auto',verticalAlignment: 'End'},
    { start: data?.balancedEnd, size: 2, sizeType: 'Pixel', dashArray: "5,5", color: "#afafb6" },
    { start: data?.requirementEnd, size: 2, sizeType: 'Pixel', dashArray: "5,5", color: "#afafb6" }]
    const primaryXAxis = { title: 'Time',  titleStyle: {size: '12px', color: '#005A84',  fontWeight: 'bold'}, 
    stripLines: yAxisStripLines,
    valueType: 'DateTimeCategory', skeleton: 'Ed', intervalType: 'Days', interval : 1, labelFormat: getGlobalFormat(),  rangePadding: 'None' , labelRotation: -45, majorGridLines: majorGridLines };
    const primaryYAxis = { title: 'Hours per Day', titleStyle: {size: '12px', color: '#005A84',  fontWeight: 'bold'}, majorGridLines: majorGridLines };
    const tooltipSettings = { enable: true, shared: true, format: '${series.name} :  ${point.y} hours', ...chartTooltipThemeSettings };
    const zoomsettings = { enableMouseWheelZooming: true, enablePinchZooming: true,
                           enableSelectionZooming: true, mode: 'X', enableScrollbar: true };
    const crosshair = { enable: true, lineType: 'Vertical', dashArray: '10,5', line: { color: '#ff66ff' } };

    const requiredMarker =  { visible: true, height: 10, width: 10, shape: 'Circle', border: {width: 2, color: '#DA1B74'} };
    const balancedMarker =  { visible: true, height: 10, width: 10, shape: 'Circle', border: {width: 2, color: '#005A84'} };

    return (
        <>
            <div className='chart-div'>
                { isLoading && 
                    <CircularProgressBar />
                }
                 { !isLoading &&  error &&
                    <ErrorMessage  message="Unable to display resource capacity graph" />
                }
                { !isLoading && !error &&
                <>
                    <ChartComponent primaryXAxis={primaryXAxis} primaryYAxis={primaryYAxis} crosshair={crosshair} zoomSettings={zoomsettings} tooltip={tooltipSettings} width='1390px' height='680px'>
                        <Inject services={[LineSeries, Tooltip, DataLabel, DateTimeCategory, Zoom, Crosshair, StripLine, ScrollBar]}/>
                        <SeriesCollectionDirective>                          
                            <SeriesDirective dataSource={data.requirement} xName='date' yName='hours' width={2} name='Requirement (Original Planned hours + Excess hours)'  type='Line' fill='#DA1B74' >
                            </SeriesDirective>                           
                            <SeriesDirective dataSource={data.balanced} xName='date' yName='hours' width={2} name='Balanced Requirement'  type='Line' fill='#005A84'>
                            </SeriesDirective>                             
                            <SeriesDirective dataSource={data.original} xName='date' yName='hours' width={2} dashArray='5,5'  name='Original Capacity' type='Line' fill='#8ED8F8'>
                            </SeriesDirective>                           
                            <SeriesDirective dataSource={data.modified} xName='date' yName='hours' width={2} dashArray='5,5' name='Modified Capacity' type='Line' fill='#00ACA1'>
                            </SeriesDirective>
                            <SeriesDirective dataSource={data.delayDate} xName='date' yName='hours' type='Line' width={2}  name='Time Delay' fill='#FFAD61' tooltipFormat={data.delayTime.toString()} >
                            </SeriesDirective>
                            <SeriesDirective dataSource={[data.requiredMarker]} xName='date' yName='hours'  marker={requiredMarker} enableTooltip={false}></SeriesDirective>
                            <SeriesDirective dataSource={[data.balancedMarker]} xName='date' yName='hours'  marker={balancedMarker} enableTooltip={false}></SeriesDirective>
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </>
                }
            </div>           
    
        </>
      );
};

export default ResourceCapacityChart;