import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { selectProjectById, fetchProjects } from '../../../../../redux/slices/projectsSlice';
import { SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import  Radio  from '@material-ui/core/Radio';
import  RadioGroup  from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { fetchDateFormats, updateProjectPrefernces } from '../../../../../redux/slices/projectSettingsSlice';
import AuthorizedComponent from '../../../../../components/Shared/molecules/AuthorizedComponents/AuthorizedComponent';
import './RegionalPreferences.scss';

const RegionalPreferences = () => {
    const dispatch = useDispatch();
    const { selectedProjectId } = useSelector(state => state.projects);
    const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
    const [ dateFormat, setDateFormat ]=  useState(selectedProject?.dateFormat);
    const { isLoading, error, dateFormats } = useSelector(state => state.projectSettings);
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    const BlueRadio = withStyles({
        root: {
          color: 'var(--color-unchecked)',
          "&$checked": {
            color: 'var(--color-accent)'
          }
        },
        checked: {}
      })((props) => <Radio color="default" {...props} />);
    
      const WhiteTextTypography = withStyles({
        root: {
          fontWeight:"400",
          fontSize: "16px",
          lineHeight: "27px"
        }
      })(Typography);
  
  
    useEffect(() => {
      /**
       * Acquire access token
       */
        if (accounts.length > 0) {
            const request = {
                scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then(response => {
                setAccessToken(response.accessToken);
            }).catch(error => {
                // acquireTokenSilent can fail for a number of reasons, fallback to interaction
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenPopup(request).then(response => {
                        setAccessToken(response.accessToken);
                    });
                }
            });
        }
    }, [accounts]);

    const PreferencesChanges = () =>{
        if (accessToken) {
            let preferences = {
                dateFormat : dateFormat
              }
              dispatch(updateProjectPrefernces({ projectId: selectedProjectId, preferences : preferences,  accessToken: accessToken }))
              .then((res)=>dispatch(fetchProjects(accessToken)))
        }
    }

    const radioButtonValue = (e) => {
        setDateFormat(e.target.value);
        e.stopPropagation();
      } 
    useEffect(() =>{
        if (accessToken){
          dispatch(fetchDateFormats({ accessToken: accessToken}));
        }
     }, [dispatch, selectedProjectId, accessToken]);

  return (
    <>
        <div className='regional-preferences-container'>
            <div className='regional-preferences-content'>
            {isLoading &&
              <CircularProgressBar />
              }
              {error && !isLoading &&
              <ErrorMessage message="No Data found for Regional Preferences" />
              }
               {!isLoading && !error && dateFormats &&
                <>
                  <div className='preference-section'>
                     <div className='preference-section-title'>
                         Select your preferred date format:
                      </div>
                        <RadioGroup  row aria-label="date-preference"  name="radio-buttons-group" size="small" value = {dateFormat} onChange={radioButtonValue}>
                        {dateFormats?.map((dateFormatObj,i)=>{
                            return(
                              <AuthorizedComponent key={i} value="preference-change" projectId = {selectedProjectId} disciplineId = 'All'>
                                 <FormControlLabel  value={dateFormatObj.dateFormat} control={<BlueRadio size="small"/>} label={<WhiteTextTypography>{dateFormatObj.dateFormatType.toUpperCase()}</WhiteTextTypography>}/>
                              </AuthorizedComponent>   
                            ) 
                        })}    
                        </RadioGroup>
                  </div>
                <hr />
                </>}
            </div>
        </div>
        <div className='regional-preferences-footer'>
            <AuthorizedComponent value="preference-change" projectId = {selectedProjectId} disciplineId = 'All'>
                <SecondaryLighthouseButton onClick={PreferencesChanges}>Save Changes</SecondaryLighthouseButton>
             </AuthorizedComponent>    
        </div>
    </>
  )
}

export default RegionalPreferences;