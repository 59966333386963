import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { selectProjectById } from '../../redux/slices/projectsSlice';
import { setSelectedProjectId } from '../../redux/slices/projectsSlice';
import { setSelectedScenarioId } from '../../redux/slices/scenariosSlice';
import PageHeader from '../../components/Shared/organisms/PageHeader';
import { Link } from 'react-router-dom';
import '../ProjectScenariosPage/ProjectScenariosPage.scss';
import ScenarioOptionsComparisonContainer from './components/organisms/ScenarioOptionsComparisonContainer';
import { appInsights } from '../../AppInsights';
import { useMsal, useAccount } from "@azure/msal-react";
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import ScenarioOptionSelector from './components/molecules/ScenarioOptionSelector';
import ActivityOptimisationChangeContainer from './components/molecules/ActivityOptimisationChangeContainer';
import { ArrowLeftIcon } from '../../Icons'
import ResourceCapacityRequirementGraph from './components/organisms/ResourceCapacityRequirementGraph';
import './ProjectScenarioOptionsComparisonPage.scss';

let trackData = require('../../appTrackerInfo.json');
var classNames = require('classnames');
function SocTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dpv-tabpanel-${index}`}
      aria-labelledby={`dpv-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

SocTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SocTabs = withStyles({
  root: {

  },
})(Tabs);

const SocTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    width: '130px',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '14px',
    lineHeight: '24px',
    color: 'var(--color-brand-primary)',
    '&$selected': {
      background : '#BFDCF4',
      color: 'var(--color-brand-primary)',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {
    
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
}))((props) => <Tab disableRipple {...props} />);

export const ProjectScenarioOptionsComparisonPage = (props) => {
  const {
    match: { params },
  } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { selectedScenarioId } = useSelector(state => state.scenarios);
  const [showScenarioConstraintsWizard, setShowScenarioConstraintsWizard] = useState(false);
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [value, setValue] = React.useState("Properties");
  const [refreshScenarios, setRefreshScenarios] = useState(false);
  const { LHRoles, projects } = useSelector(state => state.authorization);

  useEffect(() => {
    if (params.projectId) {
      dispatch(setSelectedProjectId(params.projectId));
    }

    if (params.scenarioId) {
      dispatch(setSelectedScenarioId(params.scenarioId));
    }
  }, []);

  useEffect(() => {
    if (account && !selectedProject) {
      dispatch(setSelectedProjectId(params.projectId));
      appInsights.trackPageView({
        name:`${trackData.pages[3].name}`,   
        properties: { 
                   Project: 'Selection',
                   ProjectName: 'Selection',
                   User:`${account.username}`,
                   Username: `${account.name}`,
                   Role: 'Selection'  
        }});
    }
    if (account && selectedProject) {
      appInsights.trackPageView({
        name:`${trackData.pages[3].name}`,   
        properties: { 
                   Project: `${selectedProjectId}`,
                   ProjectName: `${selectedProject?.projectName}`,
                   User:`${account.username}`,
                   Username: `${account.name}`,
                   Role: `${LHRoles.find( x => x === 'GlobalAdmin')?'GlobalAdmin' : LHRoles[projects?.indexOf(selectedProjectId.toUpperCase())]?.toString()}` 
        }});
    }
  }, [account, selectedProject]);

    /**
   * Handle refresh scenarios
   */
     const handleRefreshScenarios = () => {
      setRefreshScenarios(true);
    }
    const cleanRefreshScenarios = () => {
      setRefreshScenarios(false);
    }

  /**
* Tab change event
* @param {*} event 
* @param {*} newValue 
*/
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {selectedProject && selectedScenarioId &&
        <div className="soc-page">
          <div className="soc-page-header">
          <Link className='arrow-left-icon' to={`/${selectedProjectId}/scenarios`} >
            <ArrowLeftIcon width={20} />
           </Link >
            <PageHeader
              pageTitle="Comparison View"
              margin="30"
            >
            </PageHeader>
          </div>
          <div className="soc-options-selector-panel panel-shadow">
            <ScenarioOptionSelector scenarioId={selectedScenarioId} />
          </div>
          <div className="soc-main">
            <div className={classNames('soc-container panel-shadow')}>
              <div className="soc-header">
                <div className="soc-tabs">
                  <SocTabs
                    className="tabs-container"
                    value={value}
                    textColor="primary"
                    onChange={handleTabChange}
                    TabIndicatorProps={{ 
                      style: {
                      display: "none",
                      }
                      }}
                  >
                    <SocTab label={<span className="tab-label">Properties</span>} value="Properties" />
                    {process.env.REACT_APP_SHOW_TABS === 'true' &&
                      <SocTab label={<span className="tab-label">Activity Schedule</span>} value="ActivityOptimisationChanges" />
                    }
                    {process.env.REACT_APP_SHOW_TABS === 'true' &&
                      <SocTab label={<span className="tab-label">Resourcing</span>} value="Graph" />
                    }
                  </SocTabs>
                </div>
              </div>
              <SocTabPanel value={value} index="Properties" className="soc-content">
                <ScenarioOptionsComparisonContainer showScenarioConstraintsWizard={showScenarioConstraintsWizard} />
              </SocTabPanel>
              {process.env.REACT_APP_SHOW_TABS === 'true' &&
                <SocTabPanel value={value} index="ActivityOptimisationChanges" className="soc-content">
                  <ActivityOptimisationChangeContainer showScenarioConstraintsWizard={showScenarioConstraintsWizard} />
                </SocTabPanel>
              }
              {process.env.REACT_APP_SHOW_TABS === 'true' &&
                <SocTabPanel value={value} index="Graph" className="soc-content">
                  <ResourceCapacityRequirementGraph showScenarioConstraintsWizard={showScenarioConstraintsWizard} cleanRefreshScenarios={cleanRefreshScenarios} refreshScenarios={refreshScenarios} />
                </SocTabPanel>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default ProjectScenarioOptionsComparisonPage;