import React from 'react';
import './ImpactRating.scss';

var classNames = require('classnames');

const ImpactRating = (props) => {
  const { value, total, scale, outOf, width } = props;
  let impactRating = (value/total);
  let impactRatingValue = +((value/total) * outOf).toFixed(scale);
  let impactClass = '';

  if(impactRating >= 0.71) {
    impactClass = '#FADAE2';
  }
  else if(impactRating >= 0.36 && impactRating <= 0.7) {
    impactClass = '#FFE1B4';
  }
  else if(impactRating <= 0.35) {
    impactClass = '#C6E3D3';
  }
  else if(isNaN(impactRatingValue)) {
    impactClass = '#C6E3D3';
  }
  else {
    impactClass = '#FADAE2';
  }

  return (
    <>
    <span className = 'impact-class' style={{backgroundColor: impactClass, width: width}}>
      {isNaN(impactRatingValue) && <>No Impact</>}
      {!isNaN(impactRatingValue) && <>
      {impactRatingValue}/{outOf}
      </>}
    </span>
    </>
  )
};

export default ImpactRating;