import React, { useEffect, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FilterActivityToolbar.scss';

const FilterActivityToolbar = (props) => {
  const {searchValue, defaultDiscipline, defaultPackage, defaultPhase, DisciplineOptions, PackageOptions, PhaseOptions, SelectionByFields } = props;
  const [allPhases, setAllPhases] = useState(PhaseOptions);
  const [allPackages, setAllPackages] = useState(PackageOptions);
  
   /**
   * Handles discipline change
   * @param {object} e 
   */
  const handleDiscipline = (e) => {
    props.handleDisciplineSelection(e?.itemData?.value);
  }

     /**
   * Handles Package change
   * @param {object} e 
   */
      const handlePackage = (e) => {
        props.handlePackageSelection(e?.itemData?.value);
      }

         /**
   * Handles Phase change
   * @param {object} e 
   */
  const handlePhase = (e) => {
    props.handlePhaseSelection(e?.itemData?.value);
  }

  /**
   * Handles search change
   * @param {object} e 
   */
  const handleSearch = (e) => {
    props.processSearch(e.target.value);
  }

  useEffect(()=>{
     let data = PhaseOptions?.filter((phase) => phase?.packageId === defaultPackage);
     setAllPhases(data)
   },[defaultPackage])
 
   useEffect(()=>{
      let data = PackageOptions?.filter((pkg) => pkg?.disciplineId === defaultDiscipline);
      setAllPackages(data);
      setAllPhases(null);
   },[defaultDiscipline])

   const packageNoDataTemp = () => {
    return (
    <>
    {defaultDiscipline &&  <div >No data found</div>}
    {!defaultDiscipline &&  <div >Select a discipline</div>}
    </>
    );
  };

  const phaseNoDataTemp = () => {
    return (
    <>
    {defaultPackage && defaultDiscipline &&  <div >No data found</div>}
    {!defaultPackage && !defaultDiscipline &&  <div >Select discipline and package</div>}
    {!defaultPackage &&  defaultDiscipline &&  <div >Select a package</div>}
    </>
    );
  };


  return (
    <div className="activity-filter-container">
      <div className='activity-dropdown-container'>
        <DropDownListComponent 
          placeholder='All Disciplines'
          change={handleDiscipline}
          dataSource={DisciplineOptions}
          fields={SelectionByFields}
          value={defaultDiscipline}
          popupWidth='230px'
          delayUpdate={true}/>
      </div>
      <div className='activity-dropdown-container'>
        <DropDownListComponent 
          placeholder='All Packages'
          change={handlePackage}
          dataSource={allPackages}
          fields={SelectionByFields}
          value={defaultPackage}
          noRecordsTemplate={packageNoDataTemp}
          popupWidth='230px'
          delayUpdate={true}/>
      </div>
      <div className='activity-dropdown-container'>
        <DropDownListComponent 
          placeholder='All Phases'
          change={handlePhase}
          dataSource={allPhases}
          fields={SelectionByFields}
          value={defaultPhase}
          noRecordsTemplate={phaseNoDataTemp}
          popupWidth='230px'
          delayUpdate={true} />
      </div>
      <div className='spacing-container'></div>
      <div className='search-container'>
        <TextField className="search-user-grid-text"
          placeholder='Enter Search...'
          value={searchValue}
          onChange={handleSearch}
          InputLabelProps={{ shrink: true }}
          InputProps={{ disableUnderline: true, style: { fontSize: 14 }, endAdornment: <FontAwesomeIcon icon="search" className='icon' /> }} />
      </div>
    </div>
  );
}

export default FilterActivityToolbar;