import { 
  createSlice,
} from '@reduxjs/toolkit';

/**
 * Create initial state
 */
 const initialState = { alertReason: "" }

/**
 * Create alertReason slice / reducers
 */
const alertReasonSlice = createSlice({
  name: 'alertReason',
  initialState,
  reducers: {
    setAlertReason: (state, action) => {
      state.alertReason = action.payload;
    },
  },
});

/**
 * alertReason actions
 */
 export const {  
  setAlertReason,
} = alertReasonSlice.actions;

/**
 * Export reducer
 */
export default alertReasonSlice.reducer;