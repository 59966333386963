import React, { useEffect, useState } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import Popover from '@material-ui/core/Popover';
import { PrimaryLighthouseButton } from '../../../../theme/Buttons';
import Avatar from 'react-avatar';

import './UserDetails.scss';
import { RightArrowOpenIcon } from '../../../../Icons';

const UserDetails = (props) => {
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [username, setUsername] = useState(null);
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (account) {
      setFirstname(account["idTokenClaims"]["given_name"]);
      setLastname(account["idTokenClaims"]["family_name"]);
      setUsername(account.username);
    }
  }, [account]);

  function signOutClickHandler(instance) {
    const logoutRequest = {
      account: instance.getAccountByHomeId(account.localAccountId),
      postLogoutRedirectUri: "/"
    }
    instance.logoutRedirect(logoutRequest);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {account !== {} &&
        <>
          <div className="user-details-container">
            <div onClick={handleClick} className={props.sidebarOpen ? "profile-link open" : "profile-link collapsed"}>
              <Avatar name={`${firstname} ${lastname}`} round={true} size="24px" color="#1F589A" className="user-avatar" />
              {props.sidebarOpen &&
                <>
                  <div className="user-names">
                    <span className="username">{firstname} {lastname}</span>
                  </div>
                  <span className="profile-icon">
                    <RightArrowOpenIcon />
                  </span>
                </>
              }
            </div>
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            BackdropProps={{ invisible: false }}
          >
            <div className="profile-card">
              <Avatar name={`${firstname} ${lastname}`} round={true} size="80px" color="#1F589A" className="user-avatar" />
              <div className="mt-2">
                <h5>{firstname} {lastname}</h5>
                <p>{username}</p>
                <PrimaryLighthouseButton onClick={() => signOutClickHandler(instance)}>Sign out</PrimaryLighthouseButton>
              </div>
            </div>
          </Popover>
        </>
      }
    </>
  );
};

export default UserDetails;