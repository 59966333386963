import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './CircularProgressBar.scss';

const CircularProgressBar = (props) => {

  return (
    <div className="circular-progress-bar-container">
      <CircularProgress />
    </div>
  );
}

export default CircularProgressBar;