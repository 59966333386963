import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import  Radio  from '@material-ui/core/Radio';
import  RadioGroup  from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import './AddUserWidget.scss';

const useStyles = makeStyles({
  textFieldStyle: {
    fontWeight: 500,
    borderRadius: '6px',
    width: '95%',
    fontSize: '14px',
    lineHeight: '24px',   
    padding: '11px 12px',
    border: 0,
    margin: 0,
    outline: 0,
    backgroundColor: '#F4F9FD !important'
  }
});


const BlueRadio = withStyles({
  root: {
    color: 'var(--color-unchecked) !important',
    "&$checked": {
      color: 'var(--color-vibrant) !important'
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);

const InputTag = ({ label, onDelete, ...props}) =>{
return (
  <div {...props}>
      <span >{label}</span>
      <CloseIcon onClick={onDelete} />
  </div>
)
}


const WhiteTextTypography = withStyles({
  root: {
    fontWeight:"500 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: 'var(--color-vibrant) !important'
  }
})(Typography);


const AddUserWidget = (props) => {
  const { disciplines, selectedProjectId, appRoles, LHRoles } = props;
  const { UsersAd, searchLoading } = useSelector(state => state.usersAd);
  const [ disSelect, setdisSelect ] = useState(true);
  const [ disciplineList, setDisciplineList] = useState([]);
  const [ selectedRole, setSelectedRole ] = useState('');
  const [ selectedRoleIds, setSelectedRoleIds] = useState([]);
  const [ currentText, setCurrentText ] = useState('');
  const [ allRoles, setAllRoles ] = useState([]);
  const [ value, setValue ] = useState([]);
  const [ errorHandler, setErrorHandler ] = useState(true)
  const classes = useStyles();

  useEffect(() => {
    if(currentText?.length > 0) {
       const timer = setTimeout(() => {
          props.searchUsers(currentText);
       }, 1000)
       return () => clearTimeout(timer)
    }    
    if(currentText?.length === 0){
         props.resetSearchUserList();
    }
  }, [currentText])

  const getUsers = (event, newValue) =>{
    if(newValue.length === 0){
      setErrorHandler(true)
    }
    else{
      setErrorHandler(false)
    }
    setCurrentText(newValue)
  }
    
  useEffect(() => {
      if(LHRoles){
        if(LHRoles.includes('GlobalAdmin')){
          setAllRoles([{ label: 'Project Owner', value: 'owner'},{ label: 'Contributor', value: 'contributor'}, { label: 'Member', value: 'member'}])
        }
        else if(LHRoles.includes('Owner')){
          setAllRoles([{ label: 'Contributor', value: 'contributor'}, { label: 'Member', value: 'member'}])
        }
        else if(LHRoles.includes('Contributor')){
          setAllRoles([{ label: 'Member', value: 'member'}])
        }
      }
  },[LHRoles])

  /**
   * Added the user and closes the dialog
   */
  const addUserRole = () => {
    props.addUserRole(value, selectedRoleIds);
  }

  /**
   * Cancels the user addition and closes dialog
   */
  const cancelUserAddition = () => {
    props.closeWidget();
  }

  const roleChange = (event, roleVal) => {
    if(roleVal === 'member' || roleVal === 'owner'){
      setSelectedRole(roleVal)
      setdisSelect(true)
    }else{
      setSelectedRole(roleVal)
      setdisSelect(false)
    }
  }

  const disciplineChange = (event) => {
    const propValue = event.target.properties;
    if(propValue.checked){
      setDisciplineList([...disciplineList, propValue.label]);
    }else{
      const updatedSelectedDis = disciplineList.filter((dis) => {
        return !(dis === propValue.label );
    });
    setDisciplineList(updatedSelectedDis);
    }
  }


useEffect(() => {
    if(selectedRole){
      let projectId = selectedProjectId?.toUpperCase();
      let RoleIds = [];
      if(selectedRole === 'contributor' && disciplineList){
        disciplineList?.map((val, i)=>{
          let roleValue = `contributor_${projectId}_${val.replaceAll(' ','')}`;
          RoleIds.push(appRoles?.filter((role) => role?.value?.toLowerCase() === roleValue?.toLowerCase())[0]?.id);
        })
      }else if(selectedRole === 'owner'){
        let roleValue = `owner_${projectId}`;
        RoleIds.push(appRoles?.filter((role) => role?.value?.toLowerCase() === roleValue?.toLowerCase())[0]?.id);
      }
      else if(selectedRole === 'member'){
        let roleValue = `member_${projectId}`;
        RoleIds.push(appRoles?.filter((role) => role?.value?.toLowerCase() === roleValue?.toLowerCase())[0]?.id);
      }
      setSelectedRoleIds(RoleIds)
    }
   
  }, [ selectedRole, disciplineList ]);

   const removeDuplicateValue = (UsersAd) =>{
    if(UsersAd && value){
      const currentAdded = value.map(data => data.mail);
      return UsersAd.filter((newVal) =>{ return !currentAdded.includes(newVal.mail) });
    }
      return UsersAd;
   }
  
  return (
  <>
    <div className="user-addition-container">
        <div className='popover-header'>
          <h6>Add Team Member</h6>
        </div>
        <div className='popover-content'>
          <div className="popover-section">
            <h4>1. Search for a User</h4>
            <p>Type the name/’s of members you would like to add:</p>
            <Autocomplete
               getOptionDisabled={ (option) => option.isProjectMember}
               getOptionLabel={ option => option.displayName}
               multiple
               id="user-search-auto"
               options={removeDuplicateValue(UsersAd)}
               freeSolo={errorHandler}
               loading={searchLoading}
               loadingText={<div style={{justifyContent: 'center', flexDirection: 'row', display: 'flex', width: '100%', height: '80px' }}>
                                <CircularProgressBar />
                            </div>}
               onInputChange={getUsers}
               noOptionsText={<div style={{justifyContent: 'space-between', flexDirection: 'row', display: 'flex', width: '100%' }}>
                                <span >There were no users found for this search.</span>
                             </div>}
               onChange={(event, val)=>{
                setValue(val)
              }}
               renderTags={(value, getTagProps) =>
               value.map((option, index) => (
                <InputTag key={index} label={option.displayName} {...getTagProps({ index })} />
                   ))
                }
               renderInput={(params) => (
                <TextField className={classes.textFieldStyle}  {...params} />
                )}
                renderOption={option =>  
                <div style={{justifyContent: 'space-between', flexDirection: 'row', display: 'flex', width: '100%' }}>
                          <span >{option.displayName}</span>
                          {option.isProjectMember === true && option.mail !== '' &&
                               <span>Already a team member </span>
                           }
                </div>
                }
            />
          </div>
          <hr color='#E4F1F8'></hr>
          <div className="popover-section">
            <h4>2. Choose Member Role</h4>
            <p>Select a role for the new member/’s:</p>
            <div className='input-role-box' >
               <RadioGroup  row aria-label="role-preference"  name="radio-buttons-group" size="small" onChange={roleChange}>
                 {allRoles?.map((currRole,i)=>{
                  return(
                     <FormControlLabel key={i} value={currRole?.value} control={<BlueRadio size="small"/>} label={<WhiteTextTypography>{currRole?.label}</WhiteTextTypography>}/>
                   ) 
                 })}    
               </RadioGroup>
            </div>
          </div>
          <hr color='#E4F1F8'></hr>
          <div className="popover-section">
            <h4>3. Select Disciplines</h4>
            <p>Select all Disciplines this user/’s can access:</p>
            <div className='input-dis-box' >
              {disciplines?.map((dis, i)=> {
               return (
                    <CheckBoxComponent onChange={disciplineChange} disabled={disSelect} key={i} label={dis?.disciplineName} name="disciplines" labelPosition="After"/>
               )
            }
            )}
            </div>
          </div>
        </div>
        <div className="popover-footer">
          <SecondaryLighthouseButton onClick={cancelUserAddition}>Cancel Changes</SecondaryLighthouseButton>
           <PrimaryLighthouseButton onClick={addUserRole}>Save Changes</PrimaryLighthouseButton> 
        </div>
      </div>
      </>
  );
}

export default AddUserWidget;