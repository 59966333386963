import React from 'react'
import { InformationIcon, FeedbackIcon } from '../../../../Icons';
import './SupportModalItem.scss';

const SupportModalItem = (props) => {
    const {SupportList}=props;
    return (
        <>
         <div className="popover-section-one">
            <p><FeedbackIcon className='icon' /><b>{SupportList[0].Name}</b></p>
            <p>{SupportList[0].Desc}<a className="help-center-btn" href={SupportList[0].Href} target='_blank' >Click Here</a> {SupportList[0].Click}</p>
          </div>
          <hr color='#F5F5F5'></hr> 
          <div className="popover-section-two">
            <p><InformationIcon className='icon' /><b>{SupportList[1].Name}</b></p>
            <p>{SupportList[1].Desc}<a className="help-center-btn" href={SupportList[1].Href} target='_blank' >Click Here</a> {SupportList[1].Click}</p>
          </div>
        </>
    )
}

export default SupportModalItem;
