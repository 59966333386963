import React, { useEffect, useState } from 'react';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import AuthorizationService from "../../../../../components/Shared/molecules/Authorization/AuthorizationService";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { editUserRoles } from '../../../../../redux/slices/userAdSlice';
import { selectAllDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';
import { fetchAppRoles } from '../../../../../redux/slices/userAdSlice';
import { fetchDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import EditUserWidget from '../../molecules/EditUserWidget';

const EditUserWidgetContainer = (props) => {
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { appRoles, selectedUser } = useSelector(state => state.usersAd);
  const { isLoading, error } = useSelector(state => state.disciplines);
  const { LHRoles, userProjectRole, userProjectDisciplines } = useSelector(state => state.authorization);
  const disciplines = useSelector(selectAllDisciplines).filter((d) => d.disciplineName !== "External Milestones");
  const { instance, accounts } = useMsal();
  const [ accessToken, setAccessToken ] = useState(null);
  const [ isReady, setIsReady ] = useState(false);

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  useEffect(() => {
    if (accessToken){
      dispatch(fetchAppRoles({projectId: selectedProjectId, accessToken: accessToken}))
       .then(() => dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken })).then(()=>{
            AuthorizationService({dispatch : dispatch, instance: instance, accounts: accounts, accessTokenAvailable: accessToken});
            setIsReady(true);
        }) 
      )
    }

  },[dispatch, selectedProjectId, accessToken]);

  /**
   * Cancels the user addition and closes dialog
   */
  const cancelUserEdit = () => {
    props.closeWidget();
  }

   const editUserRole = (removeRoleIds, addRoleIds) =>{
     if(removeRoleIds.length >0 || addRoleIds.length >0){
      setIsReady(false)
      let userRoles = {
        UserEmailAddress: selectedUser?.userEmail,
        removeRoleIds: removeRoleIds,
        addRoleIds: addRoleIds
      }
       dispatch(editUserRoles({ projectId: selectedProjectId, userRoles: userRoles, accessToken: accessToken}))
       .then(()=>{props.handleFetchUsersClose()})
     }else{
        props.toastNotificationHandle(2,'Please select/change required fields for user modification.');
        setIsReady(true)
     }
   }
  
  return (
    <>
        {!isReady &&
            <CircularProgressBar />
        }
        {error && !isLoading && isReady &&
            <ErrorMessage message="Unable to load roles and disciplines." />
         }
        {!isLoading && !error && isReady &&
            <EditUserWidget disciplines={disciplines} loggedUserRoles={LHRoles} appRoles={appRoles} selectedProject={selectedProject} selectedUser={selectedUser} cancelUserEdit={cancelUserEdit} editUserRole={editUserRole} userProjectRole={userProjectRole} userProjectDisciplines={userProjectDisciplines}/>
        }
    </>

  );
}

export default EditUserWidgetContainer;