import React from "react";
import PropTypes from "prop-types";

import "./DefaultLayout.scss";
import Sidebar from "../../components/Shared/organisms/Sidebar/Sidebar";
import HotJar from "../../components/Shared/organisms/HotJar/HotJar";

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../AppInsights';

const DefaultLayout = (props) => {
  return (
    <>
      <HotJar />
      <div className="dl-container" id="dl-wrapper" data-testid="root-elem">
        <div id="dl-sidebar" className="dl-sidebar panel-shadow">
          <Sidebar />
        </div>
        <div id="dl-main" className="dl-main">
          {props.children}
        </div>
      </div>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.any,
};

export default withAITracking(reactPlugin, DefaultLayout)
