import React, { useEffect, useState } from 'react';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { fetchActivityOptimisationChanges, selectAllActivityOptimisationChanges } from '../../../../../redux/slices/activityOptimisationChangeSlice';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import ActivityOptimisationChange from '../ActivityOptimisationChange';
import ActivityOptimisationOption from '../ActivityOptimisationOption';
import InfoMessage from '../../../../../components/Shared/molecules/InfoMessage';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import { selectScenarioOptionById } from '../../../../../redux/slices/scenarioOptionsSlice';
import { fetchDisciplines } from '../../../../../redux/slices/disciplinesSlice';

import './ActivityOptimisationChangeContainer.scss';


const ActivityOptimisationChangeContainer = (props) => {
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(state => state.activityOptimisationChange);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const { selectedScenarioOptionId } = useSelector(state => state.scenarioOptionsComparison);
  const selectedScenarioOption = useSelector((state) => selectScenarioOptionById(state, selectedScenarioOptionId));
  const activityOptimisationChanges = useSelector((state) => selectAllActivityOptimisationChanges(state, selectedScenarioOptionId));
  const filteredDisciplineIds = useSelector(state => state.disciplines.filteredDisciplineIds);

  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

   /**
   * Fetches the scenario and options for the selected scenario
   */
    useEffect(() => {
      if (accessToken && selectedScenarioOptionId){
        dispatch(fetchActivityOptimisationChanges({ projectId: selectedProjectId, scenarioOptionId: selectedScenarioOptionId, accessToken: accessToken}));
      }
    }, [dispatch, selectedProjectId, selectedScenarioOptionId, accessToken]);

  /**
  * Fetches the disciplines
  */
  useEffect(() => {
    if (accessToken) {
      dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken }));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  return (
    <>
     { isLoading && 
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to display activity optimisation change" />
      }
      { !isLoading && !error && activityOptimisationChanges.length === 0 &&
        <>
          <InfoMessage message="There are no activity optimisation changes for the selected option." />
        </>
      }
      { !isLoading && !error && activityOptimisationChanges.length > 0 &&
        <>
    <div className="scenario-options-comparison-activity-container">
      <div className="comparison-option-container">
        <div className="option-detail">
       <ActivityOptimisationOption selectedScenarioOption={selectedScenarioOption} />
        </div>
      </div>
      <div className="comparison-chart-container">
        <div className="chart-header">
          <div className="chart-title">
             Change Impact Per Package
             <span className="tooltip">
            <InfoTooltip message={'A detailed breakdown of the optimisation to schedule suggested by this option.'}  iconSize={15} width="none" />
          </span>
          </div>
      </div>
      <div className='sc-container'>
        <ActivityOptimisationChange  activityOptimisationChanges={activityOptimisationChanges} filteredDisciplineIds={filteredDisciplineIds} />
        </div>
      </div>
    </div>
        </>
      }
    </>
  );
}

export default ActivityOptimisationChangeContainer;