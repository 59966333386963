import React, { useState, useEffect } from 'react';
import { NODE_STATE_COLOUR as nodeStateColour } from '../../../../../constants';
import classNames from "classnames";
import { getMilestoneCompletionStatusColour, getSelectedMilestoneCompletionStatusColour, LighthouseTooltip } from '../../../../../utils';
import { useSelector } from "react-redux";
import { ReactComponent as KemIcon } from '../../../../../Icons/kem-icon.svg';
import { getFormattedDate } from '../../../../../utils';
import './ChartMilestone.scss';

const ChartMilestone = (props) => {
  const { phaseId, label, disciplineId, actualPercentageComplete, plannedPercentageComplete, isFiltered, plannedStartDate, actualStartDate } = props;
  const [bgColour, setBgColour] = useState("#FFFFFF");
  const [completedBgColour, setCompletedBgColour] = useState(getMilestoneCompletionStatusColour(actualPercentageComplete));
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const {
    highlightAlertSourceId,
    highlightAlertTargetId,
    selectedAlertSourceId,
    selectedAlertTargetId } = useSelector(state => state.alerts);

  const { selectedPhaseId } = useSelector(state => state.info);

  // construct tooltip text with title and dates
  let tooltipText = label + "\n" + "Planned Completion: " + getFormattedDate(plannedStartDate);
  if (actualStartDate){
    tooltipText += "\n" + "Actual Completion: " + getFormattedDate(actualStartDate);
  }
 
  useEffect(() => {
    if (selectedPhaseId === phaseId) {
      setIsSelected(true);
    }
    else {
      setIsSelected(false);
    }
  }, [selectedPhaseId]);

  // set node colour and background colour based on percentage complete
  // and filter status
  let nodeBorderColour;

  if (isFiltered) {
    nodeBorderColour = nodeStateColour['filtered'];
  }
  else {
    nodeBorderColour = getMilestoneCompletionStatusColour(actualPercentageComplete);
  }

  let nodeStyle;

  if (isSelected) {
    if (actualPercentageComplete === 100) {
      nodeStyle = { borderColor: nodeBorderColour, background: `${getSelectedMilestoneCompletionStatusColour(actualPercentageComplete)}` };
    }
    else {
      nodeStyle = { borderColor: nodeBorderColour, borderStyle: 'solid', background: `${getSelectedMilestoneCompletionStatusColour(actualPercentageComplete)}` };
    }
  }
  else {
    if (actualPercentageComplete === 100) {
      nodeStyle = {
        ...nodeStyle,
        background: `${completedBgColour}`
      };
    }
    else {
      nodeStyle = { borderColor: nodeBorderColour, borderStyle: 'solid', background: `${bgColour}` };
    }
  }

  // add the box shadow colour
  nodeStyle = {
    ...nodeStyle,
    boxShadow: `0 0 0 ${nodeBorderColour}`
  }

  const onMouseEnter = () => {
    setBgColour(getSelectedMilestoneCompletionStatusColour(actualPercentageComplete));
    setCompletedBgColour(getSelectedMilestoneCompletionStatusColour(actualPercentageComplete));
  };
  const onMouseLeave = () => {
    setBgColour("#FFFFFF");
    setCompletedBgColour(getMilestoneCompletionStatusColour(actualPercentageComplete));
  };

  return (
      <div className={classNames({ milestone: true, filtered: isFiltered, highlighted: isHighlighted })}
        style={nodeStyle} 
        //onMouseEnter={onMouseEnter}
        //onMouseLeave={onMouseLeave}
      >
        <KemIcon className="icon" />
      </div>

  );
};

export default ChartMilestone;