import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchPhaseCostCodes,  selectPhaseById } from '../../../../../redux/slices/phasesSlice';
import { selectDisciplineById } from '../../../../../redux/slices/disciplinesSlice';
import DisciplineIcon from '../../../../../components/Shared/atoms/DisciplineIcon';
import { getFormattedDate } from '../../../../../utils';
import ActivityListContainer from '../ActivityListContainer';
import DeliverableListContainer from '../DeliverableListContainer';
import DisciplineProgressDetail from '../../molecules/DisciplineProgressDetail/DisciplineProgressDetail';
import { selectPackageById } from '../../../../../redux/slices/packagesSlice';
import { Popover } from '@material-ui/core';
import ResourceAllocationChartContainer from '../../molecules/ResourceAllocationChartContainer';
import ResourceCapacityChartContainer from '../../molecules/ResourceCapacityChartContainer';
import { fetchPackagePctCompleteCalcTypes, selectAllPackagePctCompleteCalcTypes } from '../../../../../redux/slices/packagePctCompleteCalcTypesSlice';
import AuthorizationService from "../../../../../components/Shared/molecules/Authorization/AuthorizationService";
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import { useMsal } from "@azure/msal-react";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { WarningCircleIcon, InfoCircleIcon } from '../../../../../Icons';
import { renderToString } from 'react-dom/server';
import './PropertyViewer.scss';

const PropertyViewer = () => {
  const dispatch = useDispatch();
  const { selectedPhaseId } = useSelector(state => state.info);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { costCodesLoading, error, costCodes } = useSelector(state => state.phases);
  const phaseItem = useSelector((state) => selectPhaseById(state, selectedPhaseId));
  const [currentPackageId, setCurrentPackageId] = useState(null);
  const packageItem = useSelector((state) => selectPackageById(state, currentPackageId));
  const { filteredDisciplineIds } = useSelector(state => state.disciplines);
  const [currentDisciplineId, setCurrentDisciplineId] = useState(null);
  const disciplineItem = useSelector((state) => selectDisciplineById(state, currentDisciplineId));
  const [anchorEl, setAnchorEl] = useState(null);
  const [chartOpen, setChartOpen] = useState(false);
  const [capacityVisibility, setCapacityVisibility] = useState(false);
  const [ChartDisciplineId, setChartDisciplineId] = useState(null);
  const { accessToken, authorized } = useSelector(state => state.authorization);
  const { instance, accounts } = useMsal();
  const packagePctCompleteCalcTypes = useSelector(selectAllPackagePctCompleteCalcTypes);

  const infoDetails = [
    {
      header: 'Planned Schedule Activities',
      first: 'The planned % completion at the phase level is the % of the planned hours that should be completed.'
    },
    { 
      header: 'Estimated Schedule Activities',
      first: 'The % completion at the phase level is calculated as the aggregation of the individual activity % completions weighted by the planned hours for each activity.',
      second: 'An Activity can be defined as a process, done for a particular purpose. Each Work package is divided into activities. Activities have to lead to the development of one or more project outputs.'
    },
    {
      header: 'Estimated Deliverable',
      first: 'The % completion at the phase level is calculated as the aggregation of the individual activity % completions weighted by planned hours for each activity.',
      second: 'A deliverable can be defined as the physical evidence of what has been produced through an activity or as the physical evidence/support of the output that was produced through an activity.'
    }
  ]

   /**
 * Tooltip template
 * @param {object} props 
 * @returns 
 */
 const TooltipTemplate = (props) => {
  return (
      <>
      <div className='progress-info-container'>
        <div className='progress-info-header'>
          <p>{props?.header}</p>
        </div>
        <div className='progress-info-desc'>
          {props?.first !== null && <p>{props?.first}</p>}
          {props?.second !== null && <p>{props?.second}</p>}
        </div>
      </div>
      </>
  );
}

  const openResourceAllocation = (event, disciplineId) => {
    setAnchorEl(event.currentTarget);
    setChartOpen(true);
    setChartDisciplineId(disciplineId);
  }

  const openResourceCapacity = (event, disciplineId) => {
    setAnchorEl(event.currentTarget);
    setCapacityVisibility(true);
    setChartDisciplineId(disciplineId);
  }

  const handleAllocationClose = () => {
    setAnchorEl(null);
    setChartOpen(false);
  }

  const handleCapacityClose = () => {
    setAnchorEl(null);
    setCapacityVisibility(false);
  }

  useEffect(() =>{
    if (phaseItem){
      setCurrentPackageId(phaseItem.packageId);
      setCurrentDisciplineId(phaseItem.disciplineId);
    }
  }, [phaseItem]);

  useEffect(() => {
    //Acquire access token
    AuthorizationService({dispatch : dispatch, instance: instance, accounts: accounts, accessTokenAvailable:accessToken});
 }, [accounts]);

  useEffect(() => {
    //Get PackagePctCompleteCalcType
    if (accessToken && authorized == "Yes" && phaseItem && packageItem && (packagePctCompleteCalcTypes == undefined || packagePctCompleteCalcTypes.length ==0)){
      dispatch(fetchPackagePctCompleteCalcTypes({projectId : selectedProjectId, accessToken : accessToken}))
    }
  }, [dispatch, accessToken, authorized]);

  useEffect(() => {
    //Get Package cost code for selected phase
    if (accessToken && phaseItem){
      dispatch(fetchPhaseCostCodes({projectId : selectedProjectId, phaseId: phaseItem.phaseId, accessToken : accessToken}))
    }
  }, [dispatch, phaseItem]);

  return (
    <>
    <div className="pv-container">
      <div className="pv-header">
        <h3>Property Viewer</h3>
        <InfoTooltip message={'Property Viewer'} alignment="LeftCenter" iconSize={14} width={250}/>
      </div>
      {filteredDisciplineIds?.map((disciplineId, i) =>
          <div className="pv-discipline-progress">
            <DisciplineProgressDetail key={i} disciplineId={disciplineId} openResourceCapacity={openResourceCapacity} isPropertyViewer={true} openResourceAllocation={openResourceAllocation} />
          </div>
      )}
      {costCodesLoading &&
          <CircularProgressBar />
      }
      {error && !costCodesLoading &&
        <ErrorMessage message="Unable to get phase information" />
      }
      {phaseItem && packageItem && !error && !costCodesLoading &&
        <div className="pv-content">
          <div className="pv-title">{phaseItem.wbsType} Information:</div>
          <div className="pv-properties-container component-shadow">
            <div className="pv-properties-header">
              <div className='pv-ph-icon-container'>
                  <InfoCircleIcon className="pv-ph-icon" />
              </div>
              <div className="pv-ph-title">General Information</div>
            </div>
            <div className="pv-p-inner-container">
              <div className="pv-p-title">{phaseItem.wbsType} Top Info:</div>
              <table className="pv-properties-table">
                <tbody>
                  <tr>
                    <th>Package Name:</th>
                    <td>{packageItem.description}</td>
                  </tr>
                  <tr>
                    <th>{phaseItem.wbsType} Stage:</th>
                    <td>{phaseItem.phaseStage}</td>
                  </tr>
                  {disciplineItem && 
                  <tr>
                    <th>Discipline:</th>
                    <td><DisciplineIcon disciplineId={phaseItem.disciplineId} />&nbsp;{disciplineItem.disciplineName}</td>
                  </tr>
                  }
                  <tr>
                    <th>Planned Schedule Activities:</th>
                    <td>
                      {Math.round(phaseItem.completionStatus.plannedPercentageComplete??0)}%
                      <InfoTooltip message={renderToString(TooltipTemplate(infoDetails[0]))} width={510} alignment="LeftCenter" iconSize={12} />
                    </td>
                  </tr>
                  <tr>
                    <th>Estimated Schedule Activities:</th>
                    <td>
                      {Math.round(phaseItem.completionStatus.activityBasedActualPercComplete??0)}%
                      <InfoTooltip message={renderToString(TooltipTemplate(infoDetails[1]))} width={510} alignment="LeftCenter" iconSize={12} />
                    </td>
                  </tr>
                  <tr>
                    <th>Estimated Deliverable:</th>
                    <td>
                      {Math.round(phaseItem.completionStatus.deliverableBasedActualPercComplete??0)}%
                      <InfoTooltip message={renderToString(TooltipTemplate(infoDetails[2]))} width={510} alignment="LeftCenter" iconSize={12} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="pv-p-title">{phaseItem.wbsType} Start Date:</div>
              <table className="pv-properties-table">
                <tbody>
                  <tr>
                    <th>Planned:</th>
                    <td>{phaseItem.completionStatus.plannedStartDate && getFormattedDate(phaseItem.completionStatus.plannedStartDate)}</td>
                  </tr>
                  <tr>
                    <th>Actual:</th>
                    <td>{phaseItem.completionStatus.actualStartDate && getFormattedDate(phaseItem.completionStatus.actualStartDate)}</td>
                  </tr>
                </tbody>
              </table>
              <div className="pv-p-title">{phaseItem.wbsType} Completion Date:</div>
              <table className="pv-properties-table">
                <tbody>
                  <tr>
                    <th>Planned:</th>
                    <td>{phaseItem.completionStatus.plannedEndDate && getFormattedDate(phaseItem.completionStatus.plannedEndDate)}</td>
                  </tr>
                  <tr>
                    <th>Actual:</th>
                    <td>{phaseItem.completionStatus.actualEndDate && getFormattedDate(phaseItem.completionStatus.actualEndDate) }</td>
                  </tr>
                </tbody>
              </table>
              {costCodes.length > 0 && 
              <>
              <div className="pv-p-title">Cost Sub-Codes (Tasks):</div>
               <div className="pv-properties-table">
                  {costCodes?.map((costCode, i) => {
                     return (
                         <React.Fragment key={i}>
                           <div className='pv-prop-table-cost'>
                              <div className='t-head'>Cost Sub-code:</div>
                              <div className='t-desc'>{costCode.code}</div>
                            </div>
                            <div className='pv-prop-table-cost'>
                            <div className='t-head'>Description:</div>
                            <div className='t-desc'>{costCode.description}</div>
                            </div>
                            {(costCodes.length -1) !== i && 
                            <hr />
                            }
                           </React.Fragment>
                          )
                        })}
                 </div>
              </>
              }
            </div>
          </div>
        </div>
      }
      {phaseItem && packageItem && disciplineItem &&
        <div className="pv-content">
          <ActivityListContainer />
        </div>
      }
      {phaseItem && packageItem && disciplineItem &&
        <div className="pv-content">
          <DeliverableListContainer />
        </div>
      }
      {!phaseItem && filteredDisciplineIds?.length === 0 &&        
       <div className="toast-container">
        <div className="toast-header">
          <div className="toast-icon-label-container">
             <span className="span-warning"></span>
               <span className="toast-warning-icon">
                 <WarningCircleIcon/>
               </span>
                <p >You have not made a selection</p>
             </div>
            </div>
          </div>
      }
    </div>
   
    <Popover
        id='allocation-chart-popover'
        open={chartOpen}
        anchorEl={anchorEl}
        anchorReference='none'
        BackdropProps={{ invisible: false }}
        onClose={handleAllocationClose}
        >
          <div className='resource-allocation-chart-popover'>
            <ResourceAllocationChartContainer closePopover={handleAllocationClose} disciplineId={ChartDisciplineId} />
          </div>
      </Popover>
      <Popover
        id='capacity-chart-popover'
        open={capacityVisibility}
        anchorEl={anchorEl}
        anchorReference='none'
        onClose={handleCapacityClose}
        BackdropProps={{ invisible: false }}
        >
          <div className='resource-capacity-chart-popover'>
            <ResourceCapacityChartContainer closePopover={handleCapacityClose} disciplineId={ChartDisciplineId} />
          </div>
      </Popover>
    </>
  );
}

export default PropertyViewer;