import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';
import ProjectAlertsWidget from '../ProjectAlertsWidget';
import ProjectDesignDeliveryWidget from '../ProjectDesignDeliveryWidget';
import ProjectDesignProgressWidget from '../ProjectDesignProgressWidget';
import ProjectConstructionRisksWidget from '../ProjectConstructionRisksWidget';
import ProjectMitigationZeroWidget from '../ProjecMitigationZeroWidget';
import ProjectOptionMitigationFastestWidget from '../ProjectOptionMitigationFastestWidget';
import ProjectProgressCategoryHeader from '../../molecules/ProjectProgressCategoryHeader';
import { WarningCircleIcon, AlertsIcon, ScenariosIcon, ProductionIcon, CloseIcon } from '../../../../../Icons';
import ProjectActivityFocusAreasWidget from '../ProjecActivityFocusAreasWidget';
import { LinkLighthouseButton2 } from '../../../../../theme/Buttons';
import './ProjectProgress.scss';

const ProjectProgress = (props) => {
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const [ divTimer, setDivTimer ] = useState(true);

  useEffect(()=>{
    setDivTimer(true)
  },[selectedProject])

  const closeNotification = () => {
      setDivTimer(false);
  }

  const dataConnectionCheck = () => {
      props.handleModalOpen();
  }

  return (
    <div className="project-progress">
      <div className="pp-widgets-container">
       {divTimer && selectedProject?.projectDataStatus &&
            <div className="pp-widgets-data-status">
                <div className="data-source-toast-text">
                    <div className="data-source-container-scheduled-analysis">
                      <div className="data-source-header-scheduled-analysis">
                        <div className="data-source-icon-label-container-scheduled-analysis">
                          <span className="data-source-span-warning-scheduled-analysis"></span>
                          <span className="data-source-warning-icon-scheduled-analysis">
                            <WarningCircleIcon />
                            </span>
                               <p>
                                 Data connections are out of date. Please navigate to the     
                                 <LinkLighthouseButton2 onClick={dataConnectionCheck}>settings</LinkLighthouseButton2> 
                                 section to view more.
                               </p>
                        </div>
                        <div className='data-source-notification-close' onClick={closeNotification}>
                              <CloseIcon />
                       </div>
                      </div>
                    </div>
                </div>
       </div> }
       <div className="pp-widgets-group-container">
       <div className="pp-widgets-group">
          <ProjectProgressCategoryHeader title="Production" link={`/${selectedProjectId}/designstatus`} Icon={ProductionIcon} />
          <div className="pp-widget h-1 component-shadow">
            <ProjectDesignProgressWidget project={selectedProject} />
          </div>
          <div className="pp-widget h-1 component-shadow">
            <ProjectDesignDeliveryWidget project={selectedProject} />
          </div>
        </div>
        <div className="pp-widgets-group">
          <ProjectProgressCategoryHeader title="Alerts" link={`/${selectedProjectId}/alerts`} Icon={AlertsIcon} />
          <div className='pp-widget h-2 component-shadow'>
            <ProjectMitigationZeroWidget project={selectProjectById} />
          </div>
          <div className="pp-widget h-2 component-shadow">
            <ProjectAlertsWidget project={selectedProject} />
          </div>
          <div className="pp-widget h-2 component-shadow">
            <ProjectConstructionRisksWidget project={selectedProject} />
          </div>
        </div>
        <div className="pp-widgets-group">
          <ProjectProgressCategoryHeader title="Optimisation" link={`/${selectedProjectId}/scenarios`} Icon={ScenariosIcon} />
          <div className='pp-widget h-1 component-shadow'>
            <ProjectOptionMitigationFastestWidget project={selectProjectById} />
          </div>
          <div className='pp-widget h-1 component-shadow'>
            <ProjectActivityFocusAreasWidget project={selectProjectById} />
          </div>
        </div>
       </div>
        {/*
            <div className="pp-widget h-1 component-shadow">
              <ProjectDesignHoursWidget project={selectedProject} />
            </div>
              <div className="pp-widget h-1 component-shadow">
              <ProjectCriticalPackagesWidget project={selectedProject} />
            </div> */}
      </div>
    </div>
  );
};

export default ProjectProgress;