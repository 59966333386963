import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const PrimaryLighthouseButton = withStyles((theme) => ({
  root: {
    width: 180,
    height:'44px',
    color: '#FFFFFF',
    backgroundColor: '#005A84',
    textTransform: 'none',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
    borderRadius: '30px',
    fontSize: '12px',
    fontWeight: '500',
    FontFace: 'Flama',
    border: '1px solid',
    borderColor: '#005A84',
    '&:hover': {
      backgroundColor: '#227AA3',
      borderColor: '#227AA3',
    },
    '&:disabled':{
      backgroundColor: '#CCCCCC',
      borderColor: '#CCCCCC'
    }
  },
}))(Button);

export const SecondaryLighthouseButton = withStyles((theme) => ({
  root: {
    width: 180,
    height:'44px',
    color: '#005A84',
    textTransform: 'none',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
    borderRadius: '30px',
    fontSize: '12px',
    fontWeight: '400',
    FontFace: 'Flama',
    border: '1px solid',
    borderColor: '#005A84',
    '&:hover': {
      backgroundColor: '#005A84',
      color: '#FFFFFF',
    },
    '&:disabled':{
      backgroundColor: '#CCCCCC',
      borderColor: '#CCCCCC'
    }
  },
}))(Button);

export const LinkLighthouseButton = withStyles((theme) => ({
  root: {
    width: 180,
    height:'44px',
    backgroundColor: '#FFFFFF',
    color: '#005A84',
    textTransform: 'none',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
    borderRadius: '30px',
    fontSize: '12px',
    fontWeight: '500',
    FontFace: 'Flama',
    border: '1px solid',
    borderColor: 'Transparent',
    '&:hover': {
      backgroundColor: '#005A84',
      color: '#FFFFFF',
    },
    '&:disabled':{
      backgroundColor: '#CCCCCC',
      borderColor: '#CCCCCC'
    }
  },
  text: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))(Button);

export const LinkLighthouseButton2 = withStyles((theme) => ({
  root: {
    color: '#2853EB',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: '500',
    FontFace: 'Flama',
    border: '1px solid',
    borderColor: 'Transparent',
    '&:hover': {
      color: '#4658F9',
    },
    '&:disabled':{
      color: '#CCCCCC',
    }
  },
  text: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))(Button);

export const ChoiceLighthouseButton = withStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    color: '#333333',
    textTransform: 'none',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
    borderRadius: '5px',
    fontSize: '12px',
    fontWeight: '500',
    FontFace: 'Flama',
    border: '1px solid',
    borderColor: '#F8FAFA',
    boxShadow: '0 2px 3px rgb(0 0 0 / 0.1)',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#898989',
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    },
    '&:disabled':{
      backgroundColor: '#CCCCCC',
      borderColor: '#CCCCCC'
    }
  },
}))(Button);

export const InterfaceLinkButton = withStyles((theme) => ({
  root: {
    paddingLeft :'24px',
    paddingRight : '5px',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: '500',
    //FontFace: 'Flama',
    border: '1px solid',
    borderColor: 'Transparent',
    '&:hover': {
      color: '#4658F9',
    },
    '&:disabled':{
      color: '#CCCCCC',
    }
  },
 
}))(Button);
