import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Popover from '@material-ui/core/Popover';
import { OptionsIcon ,EditIcon } from '../../../../../Icons';
 import AuthorizedComponent from '../../../../../components/Shared/molecules/AuthorizedComponents/AuthorizedComponent';
import { setSelectedScenarioId } from '../../../../../redux/slices/scenariosSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';


import './ScenarioManagerSelectorItem.scss';

const ScenarioManagerSelectorItem = (props) => {
  const { scenario, selectedProject} = props;
  const { selectedScenarioId } = useSelector(state => state.scenarios);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'scenario-point-popover' : undefined;
  const dispatch = useDispatch();
  const scenarioRef = useRef(null);
  const scrollToScenario = () => scenarioRef.current.scrollIntoView();
  const history = useHistory();
  const { selectedProjectId } = useSelector(state => state.projects);

  useEffect(()=>{
    if(scenario.scenarioId === selectedScenarioId){
      scrollToScenario()
    }
  },[])

    /**
  * Handles scenario selection change event
  * @param {*} scenarioId 
  */
     const handleScenarioIdSelectorChange = (scenarioId) => {
      // gets and sets the selected scenario Id
      dispatch(setSelectedScenarioId(scenarioId));
    };

  /**
   * Options icon clicked
   * shows the Option pop-up
   */
  const handleOptionsClick = (event) => {
    if(scenario.scenarioStatus.toLowerCase()!=="pending")
    {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };
  
  /**
   * To close the pop-up
   */ 
  const handleOptionsClose= (event) =>{
    event.stopPropagation();
    setAnchorEl(null);
  }

  /**
   * edit scenario clicked
   * shows the edit scenario wizard
   */
  const editScenarioClicked = () => {
    history.push(`/${selectedProjectId}/scenarios/${scenario.scenarioId}/editscenario`);
  }

  return (
    <div>
    {scenario && selectedProject &&
    <div ref={scenarioRef}>
      <div
        onClick={() => handleScenarioIdSelectorChange(`${scenario.scenarioId}`)}
        className={`${scenario.scenarioId === selectedScenarioId ? "active scenario-item-container" : "scenario-item-container"}`} >   
      <div className="scenario-item-header" > 
         { scenario.scenarioStatus.toLowerCase() === "active" &&
         <span className="scenario-icon-success" >
            <FontAwesomeIcon icon="check-circle" className="user-gen-success" />
        </span>}
        {scenario.scenarioStatus.toLowerCase() === "pending" &&
         <span className="scenario-icon-pending">
            <FontAwesomeIcon icon="sync" className="user-gen-pending" />
         </span> }
         {scenario.scenarioStatus.toLowerCase() === "failed" &&
         <span className="scenario-icon-failed" >
            <FontAwesomeIcon icon="times-circle" className="user-gen-failed" />
        </span>}
        {scenario.scenarioStatus.toLowerCase() === "inactive" &&
         <span className="scenario-icon-inactive" >
            <FontAwesomeIcon icon="times-circle" className="user-gen-inactive" />
        </span>}
        {scenario.scenarioStatus.toLowerCase() === "draft" &&
         <span className="scenario-icon-draft" >
            <EditIcon icon="times-circle" className="user-gen-draft" />
        </span>}
        <span className='scenario-name'>{scenario.scenarioName?.length > 37 && scenario.scenarioName?.substring(0, 36) + "..."}
        {scenario.scenarioName?.length <= 37 && scenario.scenarioName}</span>
      </div>
      { scenario.scenarioType.toLowerCase() === 'user-generated'  && scenario.scenarioStatus != "Pending" && 
        <OptionsIcon onClick={handleOptionsClick} className="options-icon" />
      }
      </div>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleOptionsClose} anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
      transformOrigin={{vertical: 'top',horizontal: 'right'}} BackdropProps={{ invisible: false }} style={{borderRadius:12}}>
            <div className='scenario-full-content'>
              <div className="scenario-option-content">
                <AuthorizedComponent value='create-new-scenario' projectId={selectedProjectId} disciplineId='All'>
                  <div className='edit-active-container'>
                    <span className="edited-scenario" onClick={editScenarioClicked}><EditIcon className='edited-scenario-icon' /> Edit Scenario</span>
                  </div>
                </AuthorizedComponent>
              </div>
            </div>
    </Popover>
      </div>
      }
    </div>
  );
}

export default ScenarioManagerSelectorItem;