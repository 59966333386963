import { TextField } from '@material-ui/core';
import {
  withStyles
} from '@material-ui/core/styles';
import React from 'react';

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'var(--color-form-element-text)',
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input':{
        padding: '10px 20px',
        height: '44px',
        boxSizing: 'border-box',
        color: 'var(--color-form-element-text)',
        background: 'var(--color-form-element-background)',
        fontSize: '14px',
      },
      '& input.Mui-disabled': {
        color: 'var(--color-form-element-disabled)',
      },
    },
  },
})(TextField);

const FormTextField = React.forwardRef((props, ref) => {
  const { multiline = false, maxLength } = props;
  return (
      <CustomTextField className={props.className}  
                       {...props} 
                        multiline={multiline}
                        variant="outlined"
                        label=""
                        fullWidth={true}
                        inputProps={{ maxLength: maxLength }} />
  )
});

export default FormTextField;