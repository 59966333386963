import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Zoom, Tooltip, DataLabel, LineSeries, StripLine } from '@syncfusion/ej2-react-charts';
import { useSelector } from "react-redux";
import './PhaseProgessChart.scss';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { chartTooltipThemeSettings } from '../../../../../theme/Charts';

const PhaseProgessChart = (props) => {
    const data = props.data;
    const { isLoading, error } = useSelector(state => state.alerts);

    const yAxisStripLines = [
        {
            start: 99.9, 
            end: 100.1,
            size: .2, 
            sizeType: 'Auto', 
            isSegmented: true, 
            segmentStart: data.plannedCompletion?.manHours, 
            segmentEnd: data.lastForecasted?.manHours,
            color: "#FFCD83",
            //text: data.overEstimate.toString(),
            verticalAlignment: 'End',
        }
    ];

    const yAxisStripLinesMarkerVisibilty = data.plannedCompletion?.manHours <= data.lastForecasted?.manHours ? true : false;

    const primaryXAxis = { valueType: 'Double', title: 'Hours Completed',edgeLabelPlacement: 'Shift' ,minimum: 0, rangePadding: 'Additional', titleStyle: {size: '12px', color: '#1F589A',  fontWeight: 'bold'}};
    const primaryYAxis = { valueType: 'Double', title: 'Completion %', minimum: 0, maximum: 106, rangePadding: 'None',titleStyle: {size: '12px', color: '#1F589A',  fontWeight: 'bold'}, stripLines: yAxisStripLines };

    const plannedCompletionStripSeries = [
        { manHours: 0, percentage: data.plannedCompletion?.percentage },
        { manHours: data.plannedCompletion?.manHours, percentage: data.plannedCompletion?.percentage },
        { manHours: data.plannedCompletion?.manHours, percentage: 0 },
    ];
    const lastActualStripSeries = [
        { manHours: 0, percentage: data.lastActual?.percentage },
        { manHours: data.lastActual?.manHours, percentage: data.lastActual?.percentage },
        { manHours: data.lastActual?.manHours, percentage: 0 },
    ];

    const plannedCompletionMarker =  { visible: true, height: 10, width: 10, shape: 'Circle', border: {width: 2, color: '#4658F9'} };
    const lastActualMarker =  { visible: true, height: 10, width: 10, shape: 'Circle', border: {width: 2, color: '#408435'} };
    const excessHourMarker =  { visible: true, height: 10, width: 10, shape: 'Triangle', border: {width: 2, color: '#FFCD83'} };
    const forecastMarker =  { visible: true, height: 10, width: 10, shape: 'Circle', border: {width: 2, color: '#E6486E'} };
    const legendSettings = { visible: true, position: 'Top', alignment:"Far", height:'65',padding: 20,shapePadding: 15};
    const tooltipSettings = { enable: true, header: '',  format: '${point.x} Man hours<br><li>${point.y}% Complete', ...chartTooltipThemeSettings };
    const zoomsettings = { enableMouseWheelZooming: true, enablePinchZooming: true,
                             enableSelectionZooming: true, mode: 'X', enableScrollbar: true };

    return (
        <>
            <div className='chart-div'>
                { isLoading && 
                    <CircularProgressBar />
                }
                { !isLoading &&  error &&
                    <ErrorMessage  message="Unable to display phase progress graph" />
                }
                { !isLoading && !error &&
                <>
                <ChartComponent primaryXAxis={primaryXAxis} primaryYAxis={primaryYAxis} legendSettings={legendSettings} zoomSettings={zoomsettings}  tooltip={tooltipSettings} width='1390px' height='680px' > 
                    <Inject services={[LineSeries, Tooltip, DataLabel, Legend, StripLine, Zoom]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={data.planned.sort((a, b) => (a.percentage - b.percentage))} xName='manHours' yName='percentage' fill="#1F589A" legendShape="HorizontalLine" width={2} name='Planned' type='Line'>
                            </SeriesDirective>
                        <SeriesDirective dataSource={data.actual.sort((a, b) => (a.percentage - b.percentage))} xName='manHours' yName='percentage' fill="#408435" width={2} name='Actual' type='Line' legendShape="HorizontalLine">
                            </SeriesDirective>
                        <SeriesDirective dataSource={lastActualStripSeries.sort((a, b) => (a.manHours - b.manHours))} xName='manHours' yName='percentage' width={2} dashArray='10,5' type='Line' fill="#408435" >
                            </SeriesDirective>
                        <SeriesDirective dataSource={data.forecasted.sort((a, b) => (a.percentage - b.percentage))} xName='manHours' yName='percentage' width={2} name='Forecasted' fill="#E6486E" type='Line' legendShape="HorizontalLine" >
                            </SeriesDirective>
                            <SeriesDirective dataSource={[data.lastForecasted].sort((a, b) => (a.manHours - b.manHours))} xName='manHours' yName='percentage' width={2}  type='Line' fill='#E6486E'  marker={forecastMarker} >
                            </SeriesDirective>
                        <SeriesDirective dataSource={plannedCompletionStripSeries.sort((a, b) => (a.manHours - b.manHours))} xName='manHours' yName='percentage' width={2} fill="#4658F9" dashArray='10,5' name='Planned Total' type='Line' legendShape="HorizontalLine">
                            </SeriesDirective>
                        <SeriesDirective dataSource={[data.plannedCompletion].sort((a, b) => (a.percentage - b.percentage))} xName='manHours' yName='percentage' width={2}  type='Line' fill='#4658F9'  marker={plannedCompletionMarker} >
                            </SeriesDirective>
                        <SeriesDirective dataSource={[data.lastActual]} xName='manHours' yName='percentage' width={2} type='Line' fill='#408435' marker={lastActualMarker} >
                            </SeriesDirective>
                            {
                                (data.plannedCompletion?.manHours < data.lastForecasted?.manHours) &&
                        <SeriesDirective dataSource={[{manHours: data.plannedCompletion?.manHours + (data.overEstimate/2), percentage: 100}]} visible={yAxisStripLinesMarkerVisibilty} marker={excessHourMarker} xName='manHours' yName='percentage' name="Excess Hours" legendShape="HorizontalLine" width={2} fill='#FFAD61' type='Line' tooltipFormat={ "Excess Hours: " + data.overEstimate.toString()+" hours" } >
                            </SeriesDirective>
                            }
                    </SeriesCollectionDirective>
                </ChartComponent>
                </>
                }
            </div>           
        </>
      );
};

export default PhaseProgessChart;