import { 
    createEntityAdapter, 
    createSlice,
    createAsyncThunk 
  } from '@reduxjs/toolkit';
  import { api } from '../../api';
  import { relatedAlertsEndPoint } from '../../api/apiRoutes';
  
  /**
 * Fetches related alerts
 * @returns related alerts
 */
   export const fetchRelatedAlerts = createAsyncThunk(
    'alerts/fetchRelatedAlerts', async (params, thunkAPI) => {
      try {
        const response = await api.get(relatedAlertsEndPoint(params.projectId, params.alertId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    });
  
  /**
   * Create Related Alert adapter
   */
   const relatedAlertsAdapter = createEntityAdapter({
    selectId: (relatedAlert) => relatedAlert.alertId,
  })
  
  /**
   * Create Related Alert slice / reducers
   */
  const relatedAlertsSlice = createSlice({
    name: 'relatedAlerts',
    initialState: relatedAlertsAdapter.getInitialState( { id: undefined,
                                                        isLoading: false,
                                                        Error: undefined,
                                                     } ),
    extraReducers: (builder) => {
      builder
        .addCase(fetchRelatedAlerts.pending, (state, action) => {
          state.isLoading = true;
          state.Error = undefined;
        })
        .addCase(fetchRelatedAlerts.fulfilled, (state, action) => {
          state.isLoading = false;
          state.Error = undefined;
          relatedAlertsAdapter.setMany(state, action.payload);
        })
        .addCase(fetchRelatedAlerts.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.Error = action.payload.error;
          }
          else{
            state.Error = action.error.message;
          }
        })
    }
  });
  
  /**
   * Alert Related Alert selectors
  */
  
  export const selectAllRelatedAlerts = (state, centerAlertId) => {
    const relatedAlerts = state.relatedAlerts;
    const filteredRelatedAlerts = relatedAlertsAdapter.getSelectors((state) => relatedAlerts).selectAll().filter( relatedAlert => relatedAlert.centerAlertId === centerAlertId);
    return filteredRelatedAlerts;
  }
  
  /**
   * Export Related Alert reducer
   */
  export default relatedAlertsSlice.reducer;