import React from 'react';
import { getFormattedDate } from '../../../../../utils';
import './Task.scss';

const Task = (props) => {
  const { task } = props;
  
  return (
    <>
    <div className="task-content-container">
      <div className="tsk-prop-container">
      <div className="task-description">{task.activityName}</div>
        <div className="task-title">Properties:</div>
        <table className="tsk-prop-table">
          <tbody>
            <tr>
              <th>Activity ID:</th>
              <td>{task.activityCode}</td>
            </tr>
            <tr>
              <th>Activity Type:</th>
              <td>{task.activityType}</td>
            </tr>
          </tbody>
        </table>
        <div className="task-title">Planned Dates:</div>
        <table className="tsk-prop-table">
          <tbody>
          <tr>
              <th>Start Date:</th>
              <td>{task.plannedStartDate && getFormattedDate(task.plannedStartDate)}</td>
            </tr>
            
            <tr>
              <th>End Date:</th>
              <td>{task.plannedEndDate && getFormattedDate(task.plannedEndDate)}</td>
            </tr>
          </tbody>
        </table>
        <div className="task-title">Actual Dates:</div>
        <table className="tsk-prop-table">
          <tbody>
            <tr>
              <th>Start Date:</th>
              <td>{task.actualStartDate && getFormattedDate(task.actualStartDate)}</td>
            </tr>
            <tr>
              <th>End Date:</th>
              <td>{task.actualEndDate && getFormattedDate(task.actualEndDate)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </>
  )
};

export default Task;