import React from 'react';
import { AlertIcon } from '../../../../../Icons/index';
import { LighthouseTooltip, getFormattedDate } from '../../../../../utils';
import { renderToString } from 'react-dom/server';
import './RelatedAlert.scss';

export const RelatedAlert = (props) => {
  const { alert, alertData } = props;
  const relatedAlertPosition = { firstCirclePos: '130px',  secondCirclePos: '197px' , thirdCirclePos: '262px' , fourthCirclePos: '327px' }
  const relatedAlertStyle    = { firstCircleStyle: 'tick-1', secondCircleStyle: 'tick-2', thirdCircleStyle: 'tick-3', fourthCircleStyle: 'tick-4' }

  /**
 * Tooltip template
 * @param {object} props 
 * @returns 
 */
 const TooltipTemplate = (props) => {
  return (
    <table>
      <tbody>
          <tr>
            <td className='td-first'>Alert Name:</td>
            <td className='td-second'>{props.alertName}</td> 
          </tr>
          <tr>
            <td className='td-first'>Type:</td>
            <td className='td-second'>{props.alertType}</td> 
          </tr>
        <tr>
          <td className='td-first'>ID:</td>
          <td className='td-second'>{props.alertNumber}</td> 
        </tr>
        <tr>
          <td className='td-first'>Created:</td>
         <td className='td-second'>{getFormattedDate(props.raisedOn)}</td> 
        </tr>
      </tbody>
    </table>
  );
}

    /** The Related alert points template */
    const RelatedAlertPoint = ({data ,size, index, arrayLength, tagClass }) => {
      var trans = 'rotate('+((360/arrayLength)*index)+'deg) translate('+size+')';
      var css = { transform: trans }
      return (
        <>
          <div className={tagClass} style={css}>
               <LighthouseTooltip content={renderToString(TooltipTemplate(data))} >
                   <div className='related-tooltip' ></div>
               </LighthouseTooltip>
          </div>
        </>
      );
    }

  return (
    <div className='circle-container'> 
      <div className='circle-4 shape-border-circle'>
            {alertData?.slice().filter(x=>x.index === 4).map((data, i, array)=>{
              return (
                     <RelatedAlertPoint data={data} size={relatedAlertPosition.fourthCirclePos} index={i+1} arrayLength={array.length} tagClass={relatedAlertStyle.fourthCircleStyle} />
                     )
               })}
               <div className='circle-3 shape-border-circle'>
               {alertData?.slice().filter(x=>x.index === 3).map((data, i, array)=>{
                return (
                        <RelatedAlertPoint data={data} size={relatedAlertPosition.thirdCirclePos} index={i+1} arrayLength={array.length} tagClass={relatedAlertStyle.thirdCircleStyle} />
                       )
                })}
               <div className='circle-2 shape-border-circle'>
               {alertData?.slice().filter(x=>x.index === 2).map((data, i, array)=>{
                 return (
                        <RelatedAlertPoint data={data} size={relatedAlertPosition.secondCirclePos} index={i+1} arrayLength={array.length} tagClass={relatedAlertStyle.secondCircleStyle} />
                      )
                 })}
                 <div className='circle-1 shape-border-circle'>
                 {alertData?.slice().filter(x=>x.index === 1).map((data, i, array)=>{
                  return (
                         <RelatedAlertPoint data={data} size={relatedAlertPosition.firstCirclePos} index={i+1} arrayLength={array.length} tagClass={relatedAlertStyle.firstCircleStyle} />
                         )
                      })}
                      <LighthouseTooltip content={renderToString(TooltipTemplate(alert))} >
                      {alert?.alertType === 'Primary'?
                        <span className='alert-icon-primary'>
                            <AlertIcon className='alert-icon-svg' />
                        </span>:
                        <span className='alert-icon-secondary'>
                           <AlertIcon className='alert-icon-svg' />
                        </span>
                      }
                      </LighthouseTooltip>
                  </div>
               </div>
           </div>
      </div>
    </div>
  )
}
export default RelatedAlert;