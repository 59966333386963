import { TextField, withWidth } from '@material-ui/core';
import {
  withStyles
} from '@material-ui/core/styles';
import React from 'react';

const CustomTextAreaField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'var(--color-form-element-text)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'var(--color-form-element)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--color-form-element)',
        borderRadius: '6px',
      },
     
      '& .MuiOutlinedInput-input':{
        padding: '10px 20px',
        boxSizing: 'border-box',
        color: 'var(--color-form-element-text)',
        background: 'var(--color-form-element-background)',
        fontSize: '14px',
      },
      '&:hover fieldset': {
        borderColor: 'var(--color-form-element-focused)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--color-form-element-focused)',
      },
      '& .Mui-disabled': {
        color: 'var(--color-form-element-disabled)',
      },
    },
    '& .MuiOutlinedInput-multiline':{
      padding: '0px',
    },
    
  },
 
})(TextField);

const FormTextAreaField = React.forwardRef((props, ref) => {
  const { multiline = true, maxLength } = props;
  return (
      <CustomTextAreaField  {...props} 
                        multiline={multiline}
                        variant="outlined"
                        fullWidth={true}
                        minRows={10}
                        maxRows={10}
                        inputProps={{ maxLength: maxLength }} />
  )
});

export default FormTextAreaField;