import React from 'react';
var classNames = require('classnames');

const TimeImpact = (props) => {
  const { timeImpactDelayDays } = props;

  var timeImpactClass = classNames({
    'red': timeImpactDelayDays > 5,
    'amber': timeImpactDelayDays > 0 && timeImpactDelayDays <= 5,
    'green': timeImpactDelayDays < 0 || isNaN(timeImpactDelayDays),
    'grey': timeImpactDelayDays == 0,
  });
  
  return (
    <>
    <span className={timeImpactClass}>
    {isNaN(timeImpactDelayDays) && <>No Impact</>}
      {!isNaN(timeImpactDelayDays) && <>
        {timeImpactDelayDays < 0 && <>-</>}{timeImpactDelayDays > 0 && <>+</>}{Math.abs(timeImpactDelayDays)}&nbsp;days
      </>}
    </span>
    </>
  )
};

export default TimeImpact;