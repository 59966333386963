import React, { useState, useEffect } from 'react';
import './KemNode.scss';
import { Handle } from 'react-flow-renderer';
import { NODE_STATE_COLOUR as nodeStateColour } from '../../../../../constants';
import classNames from "classnames";
import { getMilestoneCompletionStatusColour, getSelectedMilestoneCompletionStatusColour, getPhaseNodeColour, LighthouseTooltip } from '../../../../../utils';
import { useSelector } from "react-redux";
import { getFormattedDate } from '../../../../../utils';
import { ReactComponent as KemIcon } from '../../../../../Icons/kem-icon.svg';
import { renderToString } from 'react-dom/server';

/**
 * Tooltip template
 * @param {object} props 
 * @returns 
 */
 const TooltipTemplate = (props) => {
   
  return (
    <>
    <div className='templete-tooltip-container'>
    <div className='templete-tooltip-field'>
        <span className='text-desc'>{props.label}</span>
      </div>
     {props.actualEndDate != undefined &&
      <div className='templete-tooltip-field'>
        <span className='text-title'>Milestone achieved on:</span>
        <span className='text-desc'>{getFormattedDate(props.actualEndDate)}</span>
      </div>
     } 
     {props.actualEndDate === undefined &&
      <div className='templete-tooltip-field'>
        <span className='text-title'>Ends On:</span>
        <span className='text-desc'>{getFormattedDate(props.plannedEndDate)}</span>
      </div>
      }
      <div className='templete-tooltip-field'>
        <span className='text-title'>Planned Schedule Activities:</span>
        <span className='text-desc'>{Math.round(props.plannedPercentageComplete??0)}%</span>
      </div>
      {props.pctType === 1 && 
       <div className='templete-tooltip-field'>
         <span className='text-title'>Estimated Schedule Activities:</span>
         <span className='text-desc'>{Math.round(props.activityBasedActualPercComplete??0)}%</span>
       </div>
      }
      {props.pctType === 0 && 
       <div className='templete-tooltip-field'>
          <span className='text-title'>Estimated Deliverable:</span>
          <span className='text-desc'>{Math.round(props.deliverableBasedActualPercComplete??0)}%</span>
       </div>
      }
     
    </div>
 </>
  );
}

const KemNode = (props) => {
  const { id, label, disciplineId, actualPercentageComplete, plannedPercentageComplete, isFiltered, isHidden, plannedStartDate, actualStartDate } = props.data;
  const [bgColour, setBgColour] = useState(getPhaseNodeColour(actualPercentageComplete, plannedPercentageComplete));
  const [completedBgColour, setCompletedBgColour] = useState(getMilestoneCompletionStatusColour(actualPercentageComplete));
  const [nodeBorderColour, setNodeBorderColour] = useState(getPhaseNodeColour(actualPercentageComplete, plannedPercentageComplete));
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const {
    highlightAlertSourceId,
    highlightAlertTargetId,
    selectedAlertSourceId,
    selectedAlertTargetId } = useSelector(state => state.alerts);

  const { selectedPhaseId } = useSelector(state => state.info);
  const [borderStyle, setBorderStyle] = useState('solid');

  // construct tooltip text with title and dates
  let tooltipText = label + "\n" + "Planned Completion: " +  getFormattedDate(plannedStartDate);
  if (actualStartDate){
    tooltipText += "\n" + "Actual Completion: " + getFormattedDate(actualStartDate);
  }

  useEffect(() => {
    // highlight alert source changed
    if (highlightAlertSourceId === props.id || highlightAlertTargetId.includes(props.id)
      || selectedAlertSourceId === props.id || selectedAlertTargetId.includes(props.id)) {
      setIsHighlighted(true);
      //console.log("found alert source to highlight:", props.id)
    }
    else {
      setIsHighlighted(false);
    }
  }, [highlightAlertSourceId, highlightAlertTargetId, selectedAlertSourceId, selectedAlertTargetId]);

  useEffect(() => {
    if (selectedPhaseId === props.id) {
      setIsSelected(true);
    }
    else {
      setIsSelected(false);
    }
  }, [selectedPhaseId]);

  let nodeStyle;

  if (isHidden) {
    nodeStyle = {
      ...nodeStyle, 
      borderColor: `${nodeStateColour['hidden']}`
    }
  }
  else if (isFiltered) {
    nodeStyle = {
      ...nodeStyle, 
      borderColor: `${nodeStateColour['filtered']}`
    }
  }

  if (isSelected) {
    if (actualPercentageComplete === 100) {
      nodeStyle = { borderColor: `${getMilestoneCompletionStatusColour(actualPercentageComplete)}`, borderStyle: 'dashed', background: `${getSelectedMilestoneCompletionStatusColour(actualPercentageComplete)}` };
    }
    else {
      nodeStyle = { borderColor: nodeBorderColour, borderStyle: 'solid', background: `${getSelectedMilestoneCompletionStatusColour(actualPercentageComplete)}` };
    }
  }
  else {
    if (actualPercentageComplete === 100) {
      nodeStyle = {
        ...nodeStyle,
        background: `${completedBgColour}`,
        borderStyle: `${borderStyle}`,
        borderColor: `${nodeBorderColour}`
      };
    }
    else {
      nodeStyle = {  ...nodeStyle, borderColor: `${nodeBorderColour}`, borderStyle: `${borderStyle}`, background: `${bgColour}` };
    }
  }

  // add the box shadow colour
  nodeStyle = {
    ...nodeStyle,
    boxShadow: `0 0 0 ${nodeBorderColour}`
  }

  const onMouseEnter = () => {
    setBgColour(getSelectedMilestoneCompletionStatusColour(actualPercentageComplete));
    setCompletedBgColour(getSelectedMilestoneCompletionStatusColour(actualPercentageComplete));
    setNodeBorderColour(getMilestoneCompletionStatusColour(actualPercentageComplete));
    setBorderStyle('dashed');
    
  };
  const onMouseLeave = () => {
    setBgColour(getPhaseNodeColour(actualPercentageComplete));
    setCompletedBgColour(getMilestoneCompletionStatusColour(actualPercentageComplete));
    setNodeBorderColour(getPhaseNodeColour(actualPercentageComplete));
    setBorderStyle('solid')
  };

  return (
    <LighthouseTooltip content={renderToString(TooltipTemplate(props.data))}>
    {/* <LighthouseTooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}> */}
      <div className={classNames({ kem: true, filtered: isFiltered, kemHighlighted: isHighlighted, hidden: isHidden })}
        style={nodeStyle} onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave} hidden={isHidden}
      >
        <Handle
          type="target"
          position="left"
          style={{ background: 'none', border: 'none', left: -5, top: 42.5 }}
        />
        <Handle
          type="source"
          position="right"
          style={{ background: 'none', border: 'none', right: -5, top: 0 }}
        />
        <KemIcon className="icon" />
      </div>
    </LighthouseTooltip>

  );
};

export default React.memo(KemNode);