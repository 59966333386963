import { 
  createSlice,
} from '@reduxjs/toolkit';

/**
 * Create initial state
 */
 const initialState = { selectedTab: "AlertList", 
                        selectedPhaseId: undefined, 
                        selectedActivityId: undefined }

/**
 * Create info slice / reducers
 */
const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      //console.log("setting selected tab:", action);
      state.selectedTab = action.payload;
    },
    setInfoSelectedPhaseId: (state, action) => {
      state.selectedPhaseId = action.payload;
    },
    setSelectedActivityId: (state, action) => {
      state.selectedActivityId = action.payload;
    },
  },
});

/**
 * Info actions
 */
 export const {  
  setSelectedTab,
  setInfoSelectedPhaseId,
  setSelectedActivityId
} = infoSlice.actions;

/**
 * Export reducer
 */
export default infoSlice.reducer;