import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';

/**
 * Create activity changes adapter
 */
 const activityChangesAdapter = createEntityAdapter({
  selectId: (activity) => activity.activityId,
})

/**
 * Create activity changes slice / reducers
 */
const activityChangesSlice = createSlice({
  name: 'activityChanges',
  initialState: activityChangesAdapter.getInitialState({ 
                                                          completionStatusId: 1,
                                                          milestoneCompletionStatusId: 2,
                                                          isCheckedValue: 0
                                                      }),
  reducers: {
    setActivityChanges: (state, action) => {
      activityChangesAdapter.upsertOne(state, action.payload);
    },
    setCompletionStatusId: (state, action) => {
      state.completionStatusId = action.payload;
    },
    setMilestoneCompletionStatusId: (state, action) => {
      state.milestoneCompletionStatusId = action.payload;
    },
    setIsChecked: (state, action) =>{
      state.isCheckedValue = action.payload;
    },
    removeActivityChange: (state, action) => {
      activityChangesAdapter.removeOne(state, action.payload);
    },
    removeActivityChanges: (state, action) => {
      activityChangesAdapter.removeAll(state);
    },
    updateAllActivity: (state, action) => {
      activityChangesAdapter.setAll(state, action.payload);
    }
  }
});

export const selectAllActivityChanges = (state) => {
  const activityChanges = state.activityChanges;
  const filteredActivityChanges = activityChangesAdapter.getSelectors((state) => activityChanges).selectAll();
  return filteredActivityChanges;
}

/**
 * Activity changes actions
 */
export const {  setActivityChanges,
                removeActivityChange,
                removeActivityChanges,
                updateAllActivity,
                setCompletionStatusId,
                setMilestoneCompletionStatusId,
                setIsChecked
              } = activityChangesSlice.actions;

/**
 * Export reducer
 */
export default activityChangesSlice.reducer;