import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import FormTextField from '../../../../../components/Shared/atoms/FormTextField';
import FormTextAreaField from '../../../../../components/Shared/atoms/FormTextAreaField';
import { setName, setDescription } from '../../../../../redux/slices/createScenarioSlice';
import './ScenarioDetails.scss';

const ScenarioDetails = (props) => {
  const { name, description } = useSelector(state => state.createScenario);
  const dispatch = useDispatch();

  /**
   * Sets the scenario name in state
   * @param {object} event 
   */
  const handleNameChange = (event) =>{
    dispatch(setName(event.target.value));
  }

  /**
   * Sets the scenario description in state
   * @param {object} event 
   */
  const handleDescriptionChange = (event) =>{
    dispatch(setDescription(event.target.value));
  }

  return (
    <div className="csd-container">
      <form className="csd-form">
        <div className="required-field form-group w-50">
          <label>Name:</label>
          <FormTextField maxLength={50} value={name ? name : ''} onChange={handleNameChange} autoFocus />
        </div>
        <div className="full-width required-field form-group w-75">
          <label>Description:</label>
          <FormTextAreaField maxLength={200} value={description ? description : ''} onChange={handleDescriptionChange} />
        </div>
        <label className="label-desc">(Description can be max 200 characters)</label>
      </form>
    </div>
  );
}

export default ScenarioDetails;