import React from 'react';
import { SuccessIcon, WarningCircleIcon, CloseCircleIcon } from '../../../../../Icons';
import { LighthouseTooltip } from '../../../../../utils';
import './DataSourceStatus.scss';

const DataSourceStatus = (props) => {
 const { Status, updateDate } = props;

const infoWeekDifference = (updateDate) => {
     var currentDate  = new Date(Date.now());
     var lastUpdatedDate = new Date(updateDate);
     var weeks = updateDate !== (null || undefined)? Math.floor((currentDate - lastUpdatedDate)/ 604800000): 0;
     return `This data-source has not been updated in over ${weeks} week/s`;
}

  return (
      <>
      {Status?.toLowerCase() === 'success' && 
              <span className='status-icon-text'>Data Refresh : <SuccessIcon className='icon-success' /> <b>Successful</b></span> 
      }
      {Status?.toLowerCase() === 'notupdated' && 
              <span className='status-icon-text'>Data Refresh : 
                <LighthouseTooltip width={400} content={infoWeekDifference(updateDate)} >
                         <WarningCircleIcon className='icon-warning' />
               </LighthouseTooltip>
               <b>Out of date</b>
              </span> 
      }
      {Status?.toLowerCase() === 'failed' && 
              <span className='status-icon-text'>Data Refresh : <CloseCircleIcon className='icon-error' /> <b>Failed</b></span> 
      }
      </>

  )
}

export default DataSourceStatus;