import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { 
  alertsEndpoint, 
  constructionRisksEndpoint,
  dismissOrUndismissAlertEndpoint,
  unDismissAlertEndpoint,
  alertStatusesEndpoint,
  alertMitigationZeroEndpoint,
  alertsFilteredEndpoint,
  primaryAlertsByAlertEndpoint
} from '../../api/apiRoutes';

/**
 * Fetches alerts
 * @returns alerts
 */
export const fetchAlerts = createAsyncThunk(
  'alert/fetchalerts', async (params, thunkAPI) => {
    try {
      const response = await api.get(alertsEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  });

  export const fetchAlertsFiltered = createAsyncThunk(
    'alert/fetchalerts', async (params, thunkAPI) => {
      try {
        const response = await api.get(alertsFilteredEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    });

    export const fetchPrimaryAlertsByAlert = createAsyncThunk(
      'alert/fetchprimaryalerts', async (params, thunkAPI) => {
        try {
          const response = await api.get(primaryAlertsByAlertEndpoint(params.projectId, params.alertId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
          return response.data;
        } catch (error) {
          return thunkAPI.rejectWithValue({ error: error.message });
        }
      });

  /**
   * 
   * @returns Fetches construction risks
   */
   export const fetchConstructionRisks = createAsyncThunk(
    'alert/fetchconstructionrisks', async (params, thunkAPI) => {
       try {
          const response = await api.get(constructionRisksEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
          return response.data;
        } catch (error) {
           return thunkAPI.rejectWithValue({ error: error.message });
        }
  });

  /**
 * 
 * @returns Fetches alert statuses
 */
export const fetchAlertStatuses = createAsyncThunk(
  'project/fetchalertstatuses', async (params, thunkAPI) => {
     try {
        const response = await api.get(alertStatusesEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});
  /**
 * Dismiss alerts
 */
 export const dismissOrUndismissAlert = createAsyncThunk(
  'alert/dismissOrUndismissAlert', async (params, thunkAPI) => {
     try {
        const response = await api.put(dismissOrUndismissAlertEndpoint(params.projectId, params.alertId, params.alertStatus), params.alertData, { headers: { Authorization: `Bearer ${params.accessToken}` } });
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

  /**
 * UnDismiss alerts
 */
   export const unDismissAlert = createAsyncThunk(
    'alert/UndismissAlert', async (params, thunkAPI) => {
       try {
          const response = await api.put(unDismissAlertEndpoint(params.projectId, params.alertId, params.alertStatus), params.alertData, { headers: { Authorization: `Bearer ${params.accessToken}` } });
          return response.data;
        } catch (error) {
           return thunkAPI.rejectWithValue({ error: error.message });
        }
  });

/**
 * Fetches alert mitigation
 * @returns alerts
 */
 export const fetchAlertMitigationZero = createAsyncThunk(
  'alerts/fetchAlertMitigationZero', async (params, thunkAPI) => {
    try {
      const response = await api.get(alertMitigationZeroEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  });

/**
 * Create alert adapter
 */
const alertAdapter = createEntityAdapter({
  selectId: (alert) => alert.alertId,
})

/**
 * Create alert slice / reducers
 */
const alertsSlice = createSlice({
  name: 'alerts',
  initialState: alertAdapter.getInitialState({
    selectedAlertId: undefined,
    isLoading: false,
    error: undefined,
    selectedAlertTargetId: [],
    highlightAlertTargetId: [],
    visibleAlertIds: [],
    alertsDdvToggle: false,
    constructionRisksLoading: false,
    phaseProgress: undefined,
    alertMitigationZero: {},
    statuses: undefined ,
    alertReason: ""
    }),
  reducers:
  {
    setSelectedAlertId: (state, action) => {
      state.selectedAlertId = action.payload;
    },
    setHighlightAlertSourceId: (state, action) => {
      state.highlightAlertSourceId = action.payload;
    },
    setHighlightAlertTargetId: (state, action) => {
      state.highlightAlertTargetId = action.payload;
    },
    setSelectedAlertSourceId: (state, action) => {
      state.selectedAlertSourceId = action.payload;
    },
    setSelectedAlertTargetId: (state, action) => {
      state.selectedAlertTargetId = action.payload;
    },
    setVisibleAlertIds: (state, action) => {
      state.visibleAlertIds = action.payload;
    },
    setAlertsDdvToggle: (state, action) => {
      state.alertsDdvToggle = action.payload;
    },
    setAlertReason: (state, action) => {
      state.alertReason = action.payload;
    },
    removeAlertStatus: (state) => {
      state.statuses = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlerts.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        alertAdapter.setAll(state, action.payload);
      })
      .addCase(fetchAlerts.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(fetchPrimaryAlertsByAlert.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchPrimaryAlertsByAlert.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        alertAdapter.setAll(state, action.payload);
      })
      .addCase(fetchPrimaryAlertsByAlert.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(fetchConstructionRisks.pending, (state, action) => {
        state.constructionRisksLoading = true;
        state.error = undefined;
      })
      .addCase(fetchConstructionRisks.fulfilled, (state, action) => {
        state.constructionRisksLoading = false;
        state.error = undefined;
        state.constructionRisks = action.payload;
      })
      .addCase(fetchConstructionRisks.rejected, (state, action) => {
        state.constructionRisksLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(dismissOrUndismissAlert.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(dismissOrUndismissAlert.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        alertAdapter.setAll(state, action.payload);
      })
      .addCase(dismissOrUndismissAlert.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(unDismissAlert.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(unDismissAlert.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        alertAdapter.setAll(state, action.payload);
        state.dismissAlertResult = action.payload;
      })
      .addCase(unDismissAlert.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(fetchAlertStatuses.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
        state.statuses = undefined;
      })
      .addCase(fetchAlertStatuses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.statuses = action.payload;
      })
      .addCase(fetchAlertStatuses.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(fetchAlertMitigationZero.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchAlertMitigationZero.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.alertMitigationZero = action.payload;
      })
      .addCase(fetchAlertMitigationZero.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Alert selectors
 */
export const {
  selectAll: selectAllAlerts,
  selectById: selectAlertById
} = alertAdapter.getSelectors((state) => state.alerts);

/**
 * Alert actions
 */
export const {
  setSelectedAlertId,
  setHighlightAlertSourceId,
  setHighlightAlertTargetId,
  setSelectedAlertSourceId,
  setSelectedAlertTargetId,
  setVisibleAlertIds,
  setAlertsDdvToggle,
  setAllAlerts,
  setPhaseProgress,
  setAlertReason,
  removeAlertStatus
} = alertsSlice.actions;

/**
 * Export reducer
 */
export default alertsSlice.reducer;