import React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns'
import { getFormattedDate } from '../../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SuccessIcon, WarningCircleIcon } from '../../../../../Icons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import FormTextField from '../../../../../components/Shared/atoms/FormTextField';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import FormTextAreaField from '../../../../../components/Shared/atoms/FormTextAreaField';
import LighthouseDialog from '../../../../../components/Shared/organisms/LighthouseDialog';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import { useMsal } from "@azure/msal-react";
import AuthorizationService from '../../../../../components/Shared/molecules/Authorization/AuthorizationService';
import { selectProjectById } from '../../../../../redux/slices/projectsSlice';
import { fetchDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import InterfaceActivitySelector from '../InterfaceActivitySelector/InterfaceActivitySelector';
import SelectedActivityComponent from '../../molecules/SelectedActivityComponent/SelectedActivityComponent';
import { selectAllInterfacePoints, selectInterfacePointById } from '../../../../../redux/slices/interfacePointsSlice';
import { fetchActivityScheduleMilestone,selectAllActivityScheduleMilestone } from '../../../../../redux/slices/activityScheduleMilestoneSlice';
import './InterfacePointEditor.scss';
import { fetchLastDesignActivity } from '../../../../../redux/slices/activitiesSlice';

const InterfacePointEditor = (props) => {
  const dispatch = useDispatch();
  const { isEditable, selectedActivityFromFullProgramme, daysPendingForCompletion, addForecastDate } = props;
  const { selectedProjectId } = useSelector(state => state.projects);
  const selectedProject = useSelector((state) => selectProjectById(state, selectedProjectId));
  const { selectedInterfacePointId } = useSelector(state => state.interfacePoints);
  const selectedInterfacePoint = useSelector((state) => selectInterfacePointById(state, selectedInterfacePointId));
  const interfacePoints = useSelector((state) => selectAllInterfacePoints(state, selectedProjectId));
  const { interfacePointTypes } = useSelector(state => state.interfacePoints);
  const { lastDesignActivity } = useSelector(state => state.activities);
  const { interfaceActivityLinks, isInterfaceActivityLinksLoading } = useSelector(state => state.interfacePoints);
  const { isLoading, error } = useSelector(state => state.activityScheduleMilestone);
  const activityScheduleMilestone = useSelector(selectAllActivityScheduleMilestone);
  
  const { accessToken, authorized } = useSelector(state => state.authorization);
  const { instance, accounts } = useMsal();

  const [ mandatoryInterfacePointTypes, setMandatoryInterfacePointTypes] = useState([]);
  const [ uniqueInterfacePointTypes, setUniqueInterfacePointTypes] = useState([]);
  const [ disabledInterfacePointTypes, setDisabledInterfacePointTypes] = useState([]);
  const [ disableInterfacePointTypeField, setDisableInterfacePointTypeField] = useState(false);
  const [ processInterfaceTypes, setProcessInterfaceTypes] = useState(false);
  const [ isReady, setIsReady] = useState(false);
  const [ alertModelNotification, setAlertModelNotification] = useState(false);
  const [data, setData] = useState(null);
  const [addNewActivityLinkVisibility, setAddNewActivityLinkVisibility] = useState(false);
  const [selectedActivities, setSelectedActivities]= useState([]);
  const [editActivity, setEditActivity]= useState(null);
  const [isInterfaceDateDisabled, setInterfaceDateDisabled] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [isFullScheduleLinkActivity, setIsFullScheduleLinkActivity] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState(false);
  const [dateValidationClassName, setDateValidationClassName] = useState('e-custom-style');
  const [typeValidationClassName, setTypeValidationClassName] = useState('e-custom-ddl-style');
  const [nameValidationClassName, setNameValidationClassName] = useState('e-custom-text-style');
  const [forecastDateValidationClassName, setForecastDateValidationClassName] = useState('e-custom-style');
  const [ioValidationClassName, setIoValidationClassName] = useState('e-custom-style');
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  
  const { control, handleSubmit, watch, reset, setValue, setError, clearErrors, formState: { errors } } = useForm({ 
    defaultValues: {
      name: "",
      responsibleParty: "",
      projectInterfacePointTypeId: null,
      interfacePointDate: null,
      forecastDate: null,
      description: "",
      interfaceDirection: "",
    }
  });

  const refDddlInterfacePointType = React.createRef();
  const refDtInterfacePoint = React.createRef();
  const refForecastDtInterfacePoint = React.createRef();
  const refInputInterfacePoint = React.createRef();
  const refOutputInterfacePoint = React.createRef();

  useEffect(() => {
    //Acquire access token
    if(accessToken == null || authorized == 'No'){
      AuthorizationService({dispatch: dispatch, instance: instance, accounts: accounts});
    }
  }, [accounts]);

  /**
   * Sets the already linked activities in the editor
   */
  useEffect(() => {
    if(interfaceActivityLinks)
      setSelectedActivities(interfaceActivityLinks);
  }, [interfaceActivityLinks]);

  /**
   * Sets the activity selected from the full programme in to the editor
   */
   useEffect(() => {
    if(selectedActivityFromFullProgramme){
      setSelectedActivities(selectedActivityFromFullProgramme);
      if(selectedActivityFromFullProgramme.length > 0){
        if(selectedActivityFromFullProgramme[0].activityName){
          selectedActivityFromFullProgramme[0].activityName.length > 47 && setValue("name", selectedActivityFromFullProgramme[0].activityName.substring(0, 46) + "...");
          selectedActivityFromFullProgramme[0].activityName.length <= 47 && setValue("name", selectedActivityFromFullProgramme[0].activityName);
        }
        selectedActivityFromFullProgramme[0].plannedStartDate && setValue("interfacePointDate", new Date(selectedActivityFromFullProgramme[0].plannedStartDate));

        setIsFullScheduleLinkActivity(selectedActivityFromFullProgramme[0].activityId)
      }
    }
  }, [selectedActivityFromFullProgramme]);

  /**
   * Fetches the activity schedule
   */
   useEffect(() => {
    if (accessToken){
      dispatch(fetchDisciplines({projectId: selectedProjectId, accessToken: accessToken}))
      .then((response) => dispatch(fetchLastDesignActivity({projectId: selectedProjectId, accessToken: accessToken}))
      .then((response) => dispatch(fetchActivityScheduleMilestone({projectId: selectedProjectId, accessToken: accessToken}))
      .then((response) => setLoadingCompleted(true))
      ));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  /**
   * Filling the form with values if its for editing
   */
  useEffect(() => {
    if (isEditable && selectedInterfacePoint){
      setValue("name", selectedInterfacePoint?.name);
      setValue("responsibleParty", selectedInterfacePoint?.responsibleParty);
      setValue("projectInterfacePointTypeId", selectedInterfacePoint?.projectInterfacePointTypeId);
      setValue("interfacePointDate", new Date(selectedInterfacePoint?.interfacePointDate));
      setValue("forecastDate", selectedInterfacePoint?.forecastDate ? new Date(selectedInterfacePoint?.forecastDate) : null);
      setValue("description", selectedInterfacePoint?.description);
      setValue("interfaceDirection", selectedInterfacePoint?.interfaceDirection == 'I' ? 'I' : 'O');
      if(mandatoryInterfacePointTypes.some(m=>m.id ==selectedInterfacePoint?.projectInterfacePointTypeId)){
        setAlertModelNotification(true);
      }
      else{
        setAlertModelNotification(false);
      }
    }
  }, [isEditable, mandatoryInterfacePointTypes, selectedInterfacePoint]);

  /**
   * Showing the notification when global interface date is no longer valid
   */
     useEffect(() => {
      if(loadingCompleted && selectedInterfacePoint?.projectInterfacePointTypeId && mandatoryInterfacePointTypes.some(m=>m.id == selectedInterfacePoint?.projectInterfacePointTypeId)){
        if(selectedInterfacePoint?.forecastDate){
          if(new Date(selectedInterfacePoint?.forecastDate) <= new Date(lastDesignActivity?.plannedEndDate).setHours(0,0,0,0)){
            setAlertModelNotification(false);
            setValidationError(true);
            setDisabledSubmit(true);
            setValidationErrorMessage("The selected Forecast Date for this Global Interface is now less than the End Date of the Final Design Activity, indicating that the selected date is no longer valid. Please select a Forecast Date that is later than the End Date (" + getFormattedDate(lastDesignActivity?.plannedEndDate) + ") of the Final Design Activity");
            setForecastDateValidationClassName('e-custom-style-error');
          }
        }
        else if (daysPendingForCompletion < 5 && selectedInterfacePoint?.status.toLowerCase() === 'active'){
          if(new Date(selectedInterfacePoint?.interfacePointDate) <= new Date(lastDesignActivity?.plannedEndDate).setHours(0,0,0,0)){
            setAlertModelNotification(false);
            setValidationError(true);
            setDisabledSubmit(true);
            setValidationErrorMessage("The selected Planned Date for this Global Interface is now less than the End Date of the Final Design Activity, indicating that the selected date is no longer valid. Please select a Forecast Date that is later than the End Date (" + getFormattedDate(lastDesignActivity?.plannedEndDate) + ") of the Final Design Activity");
            setForecastDateValidationClassName('e-custom-style-error');
          }
        }
        else{
          if(new Date(selectedInterfacePoint?.interfacePointDate) <= new Date(lastDesignActivity?.plannedEndDate).setHours(0,0,0,0)){
            setAlertModelNotification(false);
            setValidationError(true);
            setDisabledSubmit(true);
            setValidationErrorMessage("The selected Planned Date for this Global Interface is now less than the End Date of the Final Design Activity, indicating that the selected date is no longer valid. Please select a Planned Date that is later than the End Date (" + getFormattedDate(lastDesignActivity?.plannedEndDate) + ") of the Final Design Activity");
            setDateValidationClassName('e-custom-style-error');
          }
        }
      }
    }, [loadingCompleted]);


  /**
   * Setting the Mandator and unique interface types
   */
  useEffect(() => {
    if (interfacePointTypes && interfacePointTypes.length > 0){
      setMandatoryInterfacePointTypes(interfacePointTypes.filter(t=>t.isMandatory == true));
      setUniqueInterfacePointTypes(interfacePointTypes.filter(t=>t.isUnique == true && t.isMandatory == false));
    }
  }, [interfacePointTypes]);

  /**
   * Processing the interface types
   */
  useEffect(() => {
    if (interfacePointTypes && interfacePointTypes.length > 0){
      var disabledTypes = []
      if(mandatoryInterfacePointTypes.length > 0){
        interfacePointTypes.every(function(t){
          if(mandatoryInterfacePointTypes.some(m=>m.id == t.id)){
            if(interfacePoints.some(i=>i.projectInterfacePointTypeId == t.id)){
              //Disabling the item if its isMandatory and is already created
              disabledTypes.push(t.id);
            }
            else{
              disabledTypes.push(...interfacePointTypes.filter(it=>it.id!=t.id && it.isMandatory == false).map(function(p){ return p.id; }));
              return false;
            }
          }
          return true;
        })
      } 

      if(uniqueInterfacePointTypes.length > 0){
        interfacePointTypes.forEach((t)=>{
          if(uniqueInterfacePointTypes.some(m=>m.id == t.id)){
            if(interfacePoints.some(i=>i.projectInterfacePointTypeId == t.id)){
              //Disabling the item if its isUnique and is already created
              disabledTypes.push(t.id);
            }
          }
        })
      }
      setDisabledInterfacePointTypes(disabledTypes);
      setProcessInterfaceTypes(true);
    }
  }, [interfacePoints, mandatoryInterfacePointTypes, uniqueInterfacePointTypes]);

  /*
   * Processing before setting the isReady
   */
   useEffect(() => {
    if (processInterfaceTypes){
      setIsReady(false);

      //Setting the Mandatory as default if clicked
      if(mandatoryInterfacePointTypes.length == 1 && disabledInterfacePointTypes.length >= 1 && !disabledInterfacePointTypes.includes(mandatoryInterfacePointTypes[0]?.id)){
        setValue("projectInterfacePointTypeId", mandatoryInterfacePointTypes[0]?.id);
      }
      
      //Disabling the type field on editing mandatory interface
      if(isEditable && selectedInterfacePoint && mandatoryInterfacePointTypes && mandatoryInterfacePointTypes.length > 0){
          if(mandatoryInterfacePointTypes.some(m=>m.id == selectedInterfacePoint?.projectInterfacePointTypeId)){
            setDisableInterfacePointTypeField(true);
          }
          else{
            setDisableInterfacePointTypeField(false);
          }
      }

      //Disabling all the fields and making only forecast date editable
      if(isEditable && addForecastDate){
        setDisabled(true);
      }

      //Disabling the interface date if forecast date is available
      if(selectedInterfacePoint != undefined && selectedInterfacePoint?.forecastDate)
        setInterfaceDateDisabled(true);
      else if(selectedInterfacePoint != undefined && isEditable && daysPendingForCompletion < 5 && selectedInterfacePoint?.status.toLowerCase() === 'active')
        setInterfaceDateDisabled(true);
      
      if(!isInterfaceActivityLinksLoading && loadingCompleted)
        setIsReady(true);
    }
  }, [processInterfaceTypes, loadingCompleted, disabledInterfacePointTypes, selectedInterfacePoint, isInterfaceActivityLinksLoading]);

  /*
   * Sending the create/edit interface to the respective functions in the parent
   */
  useEffect(() => {
    if (data){
      // if we have form data then validation has passed
      // and we should send this data to api
      data.interfacePointDate = format(refDtInterfacePoint.current?.value, 'dd-MMM-yyyy');
      if(selectedInterfacePoint != undefined && data.forecastDate)
      {
        data.forecastDate = format(refForecastDtInterfacePoint.current?.value, 'dd-MMM-yyyy');
      }

      let selectedActivityIds = [];
      selectedActivities.map((activity) =>{
        selectedActivityIds.push(activity.activityId);
      })
      
      data.ActivityIds = selectedActivityIds;
      data.fullScheduleActivityId = isFullScheduleLinkActivity;
      
      if(isEditable && selectedInterfacePoint != undefined){
        props.editNewInterfacePoint(data, selectedInterfacePoint?.id, selectedInterfacePoint?.status);
      }
      else{
        props.createNewInterfacePoint(data);
      }
      clearForm();
    }
  }, [data]);

  /**
   * Setting alert notification for mandatory interface
   */
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if(name == "projectInterfacePointTypeId" && mandatoryInterfacePointTypes.some(m=>m.id == value.projectInterfacePointTypeId)){
        setAlertModelNotification(true);
      }

      if(name == "interfacePointDate" && mandatoryInterfacePointTypes.some(m=>m.id == value.projectInterfacePointTypeId) && new Date(value.interfacePointDate).setHours(0,0,0,0) > new Date(lastDesignActivity?.plannedEndDate).setHours(0,0,0,0)){
        setAlertModelNotification(true);
        setValidationError(false);
        setDisabledSubmit(false);
        setDateValidationClassName('e-custom-style-success');
        setValidationErrorMessage("");
      }

      if(name == "forecastDate" && mandatoryInterfacePointTypes.some(m=>m.id == value.projectInterfacePointTypeId) && new Date(value.forecastDate).setHours(0,0,0,0) > new Date(lastDesignActivity?.plannedEndDate).setHours(0,0,0,0)){
        setAlertModelNotification(true);
        setValidationError(false);
        setDisabledSubmit(false);
        setForecastDateValidationClassName('e-custom-style-success');
        setValidationErrorMessage("");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, mandatoryInterfacePointTypes, loadingCompleted]);

  /**
   * Handle the cancel button click
   */
   const handleCancelClick = () => {
    if(isEditable){
      props.cancelEditInterfacePoint();
    }
    else{
      props.cancelAddInterfacePoint();
      clearForm();
    }
  }

  /**
   * Clears the form
   */
  const clearForm = () =>{
    refDddlInterfacePointType.current?.clear();
    reset();
  }

  /**
   * Interface point type fields
   */
  const interfacePointTypesFields = {
    text: 'description',
    value: 'id'
  }

  /**
   * Disabling the nt required interface types when opening the dropdown
   */
  const dropDownOpened = (event) =>{
    var itemCount = event.popup.element.querySelectorAll('.e-list-item').length;
    for (let i = 0; i < itemCount; i++) {
      let itemId = event.popup.element.querySelectorAll('.e-list-item')[i].getAttribute("data-value");
      if(disabledInterfacePointTypes.includes(itemId)){
        //disabling interface types
        event.popup.element.querySelectorAll('.e-list-item')[i].classList.add('e-disabled');
        event.popup.element.querySelectorAll('.e-list-item')[i].classList.add('e-overlay');
      }
      else{
        //enabling them back
        event.popup.element.querySelectorAll('.e-list-item')[i].classList.remove('e-disabled');
        event.popup.element.querySelectorAll('.e-list-item')[i].classList.remove('e-overlay');
      }
    }
  }

  /**
  * Handles the add new activity link click
  */
   const addNewActivityLinkClick = () => {
     if(!addForecastDate){
        setEditActivity(null);
        setAddNewActivityLinkVisibility(true);
      }
  }

  /**
  * Handles the close new activity link click
  */
   const closeNewActivityLinkClick = () => {
    setAddNewActivityLinkVisibility(false);
  }

  /**
  * Back from the new activity link click
  */
   const backFromNewActivityLinkClick = () => {
    setAddNewActivityLinkVisibility(false);
  }

  /**
   * Handles the adding of new activity links
   */
  const addNewActivity=(selectedActivities)=>{
    let activities = selectedActivities.slice();
    selectedActivities.forEach((activity) => {
      if(!activities.some(a=>a.activityId == activity.activityId)){
        activities.push(activity);
      }
    });
    setSelectedActivities(activities);
    setAddNewActivityLinkVisibility(false);
  }

  /**
   * Handles the delete of activity links
   */
  const deleteActivityClick = (activityId) =>{
    let selectedActivitiesFiltered = selectedActivities.filter(a=>a.activityId!=activityId);
    setSelectedActivities(selectedActivitiesFiltered);
  }

  /**
   * Handles the edit of activity links
   */
  const editActivityClick = (activityId) =>{
    setEditActivity(activityId);
    setAddNewActivityLinkVisibility(true);
  }

  const forecastDateCreated = () =>{
    if(isDisabled && isReady && processInterfaceTypes && isLoading === false)
    {
      refForecastDtInterfacePoint.current?.isRendered && refForecastDtInterfacePoint.current?.show();
    }
  }

  /**
  * To check the correct Radio button When Input Output is rendered
  */
  const inputOutputCreated = () =>{
    if(selectedInterfacePoint?.interfaceDirection == 'I'){
      refInputInterfacePoint.current.properties.checked = true;
      refInputInterfacePoint.current.refresh();
    }
      
    if(selectedInterfacePoint?.interfaceDirection == 'O'){
      refOutputInterfacePoint.current.properties.checked = true;
      refOutputInterfacePoint.current.refresh();
    }
  }

    /**
    * To show the validation error message when date validation fails
    */
     useEffect (() =>{
      if(errors.interfacePointDate && errors.interfacePointDate.type =='required'){
        setDisabledSubmit(true);
        setDateValidationClassName('e-custom-style-error');
      }

    },[errors.interfacePointDate]);
    /**
    * To show the validation error message when date validation fails
    */
    useEffect (() =>{
      if(errors.name){
        setDisabledSubmit(true);
        setNameValidationClassName("e-custom-text-style-error");
      }

      if(errors.projectInterfacePointTypeId){
        setDisabledSubmit(true);
        setTypeValidationClassName("e-custom-ddl-style-error")
      }

      if(errors.interfaceDirection){
        setDisabledSubmit(true);
        setIoValidationClassName("e-custom-style-error")
      }

      if(errors.forecastDate){
        setDisabledSubmit(true);
        setForecastDateValidationClassName("e-custom-style-error")
      }

    },[errors.name,  errors.projectInterfacePointTypeId, errors.interfaceDirection, errors.forecastDate]);

   /**
  * Validation of InterfaceDate based on the rules defined
  */
    const validateInterfaceDate = (interfacePointDate) =>{
    interfacePointDate = (interfacePointDate == refDtInterfacePoint.current?.value ? interfacePointDate : refDtInterfacePoint.current?.value);

    if(interfacePointDate != null){
      let interfaceType = null;

      if (selectedInterfacePoint?.projectInterfacePointTypeId){
        interfaceType = selectedInterfacePoint?.projectInterfacePointTypeId;
      }

      if(refDddlInterfacePointType.current?.value){
        interfaceType = (interfaceType == refDddlInterfacePointType.current?.value) ? interfaceType : refDddlInterfacePointType.current?.value;
      }

      if(interfaceType == mandatoryInterfacePointTypes[0].id){
        var maxPlannedEndDate = new Date(lastDesignActivity?.plannedEndDate).setHours(0,0,0,0);

        if(new Date(interfacePointDate).setHours(0,0,0,0) < maxPlannedEndDate){
          if(selectedInterfacePoint?.forecastDate  || (daysPendingForCompletion < 5 && selectedInterfacePoint?.status.toLowerCase() === 'active')){
            return null;
          }
          else{
            setAlertModelNotification(false);
            setValidationError(true);
            setDisabledSubmit(true);
            setDateValidationClassName('e-custom-style-error');
            setValidationErrorMessage("The selected Planned Date for this Global Interface is now less than the End Date of the Final Design Activity, indicating that the project is already planned as being delayed. Please select a Planned Date that is later than the End Date (" + getFormattedDate(lastDesignActivity?.plannedEndDate) + ") of the Final Design Activity");
            return false;
          }
          
        }
        else{
          if(selectedInterfacePoint?.forecastDate  || (daysPendingForCompletion < 5 && selectedInterfacePoint?.status.toLowerCase() === 'active')){
            return null;
          }
          else{
            setAlertModelNotification(true);
            setValidationError(false);
            setDisabledSubmit(false);
            setValidationErrorMessage(null);
            setDateValidationClassName('e-custom-style-success');
            return true;
          }
        }
      }

      if(selectedActivities.length > 0){
        var plannedStartDates = selectedActivities.map(a => new Date(a.plannedStartDate)).slice();
        var plannedEndDates = selectedActivities.map(a => new Date(a.plannedEndDate)).slice();

        if(refForecastDtInterfacePoint.current == null || refForecastDtInterfacePoint.current?.value === null){
          if(refInputInterfacePoint.current?.value == 'I' && refInputInterfacePoint.current?.properties.checked == true){
            if(new Date(interfacePointDate).setHours(0,0,0,0) > new Date(Math.min(...plannedEndDates)).setHours(0,0,0,0)){
              setValidationError(true);
              setDisabledSubmit(true);
              setValidationErrorMessage("The Input Interface needs to be completed before the Linked Activities finish. Please select a new Date that is before the Planned End Date for the Linked Activities.");
              setDateValidationClassName('e-custom-style-error');
              return false;
            }
            else{
              setValidationError(false);
              setDisabledSubmit(false);
              setValidationErrorMessage(null);
              setDateValidationClassName('e-custom-style-success');
              return true;
            }
          }
    
          else if(refOutputInterfacePoint.current?.value == 'O' && refOutputInterfacePoint.current?.properties.checked == true){
            if(new Date(interfacePointDate).setHours(0,0,0,0) < new Date(Math.max(...plannedStartDates)).setHours(0,0,0,0)){
              setValidationError(true);
              setDisabledSubmit(true);
              setValidationErrorMessage("The linked Activities below needs to be started before this Output Interface. Please select a Date that is after the Planned Start Date for the Linked Activities.");
              setDateValidationClassName('e-custom-style-error');
              return false;
            }
            else{
              setValidationError(false);
              setDisabledSubmit(false);
              setValidationErrorMessage(null);
              setDateValidationClassName('e-custom-style-success');
              return true;
            }
          }

          else{
            return true;
          }
        }
        else {
          return true;
        }
        
      }
      else{
        setValidationError(false);
        setValidationErrorMessage(null);
        setDateValidationClassName('e-custom-style');
        return true;
      }

    }

    else{
      setDateValidationClassName('e-custom-style');
      return true;
    }
  }

   /**
  * Validation of Name Field
  */

  const validateName = (name) =>{
    if(name.length > 0){
      setDisabledSubmit(false);
      setNameValidationClassName("e-custom-text-style-success");
    }else{
      setDisabledSubmit(true);
      setNameValidationClassName("e-custom-text-style-error");
    }
  }

  /**
  * Validation of Type Dropdown
  */
  const validateType = (type) =>{
    if(type){
      setDisabledSubmit(false);
      setTypeValidationClassName("e-custom-ddl-style-success");
    }else{
      setDisabledSubmit(true) ;
      setTypeValidationClassName("e-custom-ddl-style-error")
    }
  }
        
  /**
  * Validation of Input output radio button
  */
  const validateIo = (ioPoint) =>{
    if(ioPoint){
      setDisabledSubmit(false);
      setIoValidationClassName("e-custom-style-success");
    }else{
      setDisabledSubmit(true);
      setIoValidationClassName("e-custom-style-error")
    }

    if(validationError == true){
      setDisabledSubmit(true);
    }
  }
  
  /**
  * Validation of Forecast date - only for mandatory condition and Global Interface date
  */
   const validateForecastDate = (fDate) =>{
    if(fDate){
      fDate = (fDate == refForecastDtInterfacePoint.current?.value ? fDate : refForecastDtInterfacePoint.current?.value);
      let interfaceType = null;

      if (selectedInterfacePoint?.projectInterfacePointTypeId){
        interfaceType = selectedInterfacePoint?.projectInterfacePointTypeId;
      }

      if(refDddlInterfacePointType.current?.value){
        interfaceType = (interfaceType == refDddlInterfacePointType.current?.value) ? interfaceType : refDddlInterfacePointType.current?.value;
      }

      if(interfaceType == mandatoryInterfacePointTypes[0].id){
        var maxPlannedEndDate = new Date(lastDesignActivity?.plannedEndDate).setHours(0,0,0,0);

        if(new Date(fDate).setHours(0,0,0,0) < maxPlannedEndDate){
          setAlertModelNotification(false);
          setDisabledSubmit(true);
          setValidationError(true);
          setForecastDateValidationClassName('e-custom-style-error');
          setValidationErrorMessage("The selected Forecast Date for this Global Interface is now less than the End Date of the Final Design Activity, indicating that the project is already planned as being delayed. Please select a Forecast Date that is later than the End Date (" + getFormattedDate(lastDesignActivity?.plannedEndDate) + ") of the Final Design Activity");
          return false;
        }
        else{
          setAlertModelNotification(true);
          setDisabledSubmit(false);
          setForecastDateValidationClassName('e-custom-style-success');
          setValidationError(false);
          setValidationErrorMessage(null);
          return true;
        }
      }
      else {
        setDisabledSubmit(false);
        setForecastDateValidationClassName("e-custom-style-success");
        return true;
      }
    }else{
      setDisabledSubmit(true);
      setForecastDateValidationClassName("e-custom-style-error");
      return false;
    }
  }

  const InputOutputTooltip = () => {
    return(
      <div className='i-o-div-container'>
        <h2>Input Interface</h2>
        <p>This type of Interface is an input to the Linked Activity/Activities and it must be Completed before the linked Activity/Activities can start.</p>
        <h2>Output Interface</h2>
        <p>This type of Interface is an output and cannot start before the Linked Activities are Completed.</p>
      </div>
    )
  }

  const interfaceDateChanged = (event) =>{
    if(event?.value){
      setValue("interfacePointDate",event.value);
    }
    var validate = validateInterfaceDate(event);
    if(validate == true)
      clearErrors("interfacePointDate");
    else{
      setError("interfacePointDate", { type: 'validate'});
    }
  }

  const inputOutputChanged = (event) =>{
    var validate = validateInterfaceDate(event);
    if(validate == true)
      clearErrors("interfacePointDate");
    else{
      setError("interfacePointDate", { type: 'validate'});
    }
  }

  const forecastDateChanged = (event) =>{
    if(event?.value){
      setValue("forecastDate",event.value);
    }
    var forecastDateValidity = validateForecastDate(event);
    var interfaceDateValidity = validateInterfaceDate(event);
    if(forecastDateValidity == true && (interfaceDateValidity == null || interfaceDateValidity == true))
      clearErrors("forecastDate");
    else{
      setError("forecastDate", { type: 'validate'});
    }
  }

  return (
    <>
    <div className="interface-notification">
      {
        validationError &&
          <div className="toast-container-interface-primary">
            <div className="toast-icon-label-container-interface">
              <span className="span-warning-interface-primary"></span>
              <span className="toast-warning-icon-interface-primary">
                <WarningCircleIcon />
              </span>
              <p>{validationErrorMessage}</p>
            </div>
          </div>
      }
      {
        alertModelNotification &&
          <div className="toast-container-interface-secondary">
            <div className="toast-icon-label-container-interface">
              <span className="span-warning-interface-secondary"></span>
              <span className="toast-warning-icon-interface-secondary">
                <WarningCircleIcon />
              </span>
              <p>This interface is automatically being linked to the end of every work package and is used by the alert model to estimate time and cost impacts.</p>
            </div>
          </div>
      }
    </div>
    {
      (isLoading || isInterfaceActivityLinksLoading || !loadingCompleted) &&
      <CircularProgressBar />
    }
    {
      error &&
      <ErrorMessage message="Unable to display interface editor" />
    }
    {
      isReady && processInterfaceTypes && isLoading === false && !error && !isInterfaceActivityLinksLoading && loadingCompleted &&
      <>
      <div className="ipe-container">
        <div className="ipe-content">
          <form className="ipe-form" width='100%'>
            <div className="required-field">
                {!errors.name && <label >Name:</label>}
                {errors.name && <label className="form-label-error">Name:</label> }
            <div className="label-container">
                {nameValidationClassName === "e-custom-text-style-success" &&  <div className='icon-input'><SuccessIcon  color='#327627' width={'16px'} height={'16px'} /></div> }
                {nameValidationClassName === "e-custom-text-style-error" &&  <div className='icon-input'><WarningCircleIcon  color='#AF2043' width={'16px'} height={'16px'} /></div>}
             
              <div className='ip-type-selector'>
                <Controller
                  render={({ field }) =>
                  <FormTextField {...field} className={nameValidationClassName} maxLength={50} disabled={isDisabled}/>}
                  name="name" 
                  rules={{ required: true, maxLength: 50,       
                    validate: (name) =>{
                    return validateName(name);
                   } }} 
                  control={control} 
                />
              </div>
              </div>
              {errors.name && <span className="form-error">Please enter name</span>}
            </div>
            
            <div>
              <label>Responsible Party:</label>
              <div className='ip-type-selector'>
                <Controller
                  name="responsibleParty"
                  rules={{ required: false, maxLength: 50 }}
                  control={control} 
                  render={({ field }) =>
                  <FormTextField {...field} className='e-custom-text-style' maxLength={50} disabled={isDisabled} />}
                  />
              </div>
            </div>
            
            <div className="full-width">
              <div className="required-field">
                {!errors.projectInterfacePointTypeId && <label >Type:</label>}
                {errors.projectInterfacePointTypeId && <label className="form-label-error">Type:</label> }
                <div className="label-container">
                {typeValidationClassName === "e-custom-ddl-style-success" &&  <div className='icon-input'><SuccessIcon  color='#327627' width={'16px'} height={'16px'} /></div> }
                {typeValidationClassName === "e-custom-ddl-style-error" &&  <div className='icon-input'><WarningCircleIcon  color='#AF2043' width={'16px'} height={'16px'} /></div>}
                <div className='ip-type-selector'>
                  <Controller
                    render={({ field }) =>
                      <DropDownListComponent {...field} enabled={!isDisabled}
                      cssClass={typeValidationClassName}
                      id="projectInterfacePointType"
                        readonly={disableInterfacePointTypeField}
                        ref={refDddlInterfacePointType}
                        dataSource={interfacePointTypes}
                        fields={interfacePointTypesFields}
                        open={dropDownOpened} />} 
                    name="projectInterfacePointTypeId" 
                    rules={{ required: true, 
                      validate: (type) =>{
                        return validateType(type);
                      } 
                    }}
                    control={control} 
                  />
                </div>
                </div>
                {errors.projectInterfacePointTypeId && <span className="form-error">Please enter type</span>}
              </div>
            </div>
            
            <div className="required-field">
              {!errors.interfacePointDate && <label >Planned Date:</label>}
              {errors.interfacePointDate && <label className="form-label-error">Planned Date:</label> }
              <div className="label-container">
                {dateValidationClassName === "e-custom-style-success" &&  <div className='icon-input'><SuccessIcon  color='#327627' width={'16px'} height={'16px'} /></div> }
                {dateValidationClassName === "e-custom-style-error" &&  <div className='icon-input'><WarningCircleIcon  color='#AF2043' width={'16px'} height={'16px'} /></div>}
             
              <div className='ip-type-selector'>
                <Controller
                  render={({ field }) => (
                    <DatePickerComponent {...field} ref={refDtInterfacePoint}
                      cssClass={dateValidationClassName}
                      placeholder="Select a date"
                      openOnFocus={true}  
                      format={selectedProject.dateFormat} 
                      change = {interfaceDateChanged}
                      disabled={isInterfaceDateDisabled}
                    />
                  )}
                  name="interfacePointDate"
                  rules={{ required: true, 
                    validate: (interfacePointDate) =>{
                      return validateInterfaceDate(interfacePointDate);
                    } 
                  }} 
                  control={control}
                />
              </div>
              </div>
              {errors.interfacePointDate && errors.interfacePointDate.type == 'required' && <span className="form-error">Please enter date</span>}
              {errors.interfacePointDate && errors.interfacePointDate.type == 'validate' && <span className="form-error">Invalid Date Selected</span>}
            </div>

            {selectedInterfacePoint != undefined && isEditable && (selectedInterfacePoint?.forecastDate  || (daysPendingForCompletion < 5 && selectedInterfacePoint?.status.toLowerCase() === 'active')) &&
            <div className="required-field">
               {!errors.forecastDate && <label >Forecast Date:</label>}
              {errors.forecastDate && <label className="form-label-error">Forecast Date:</label> }

              <div className="label-container">
                {forecastDateValidationClassName === "e-custom-style-success" &&  <div className='icon-input'><SuccessIcon  color='#327627' width={'16px'} height={'16px'} /></div> }
                {forecastDateValidationClassName === "e-custom-style-error" &&  <div className='icon-input'><WarningCircleIcon  color='#AF2043' width={'16px'} height={'16px'} /></div>}

              <div className='ip-type-selector'>
                <Controller
                  render={({ field }) => (
                    <DatePickerComponent {...field} 
                      ref={refForecastDtInterfacePoint} 
                      openOnFocus={true} 
                      strictMode={true}
                      allowEdit={true}
                      cssClass={forecastDateValidationClassName}
                      placeholder="Select a date"
                      format={selectedProject.dateFormat}
                      min={new Date()} 
                      created={forecastDateCreated}
                      change={forecastDateChanged}
                    />
                  )}
                  name="forecastDate"
                  rules={{ required: true, 
                    validate: (fDate) =>{
                      return validateForecastDate(fDate);
                    } 
                  }} 
                  control={control}
                />
              </div>
              </div>
              {errors.forecastDate && errors.forecastDate.type == 'required' && <span className="form-error">Please enter forecast date</span>}
              {errors.forecastDate && errors.forecastDate.type == 'validate' && <span className="form-error">Invalid Date Selected</span>}
            </div>
            }

            <div className="required-field">
              <div className="i-o-label-container">
              {!errors.interfaceDirection && <label >Input/Output:</label>}
              {errors.interfaceDirection && <label className="form-label-error">Input/Output:</label> }
              <InfoTooltip message={InputOutputTooltip}  iconSize={12} width={390}/>
                {ioValidationClassName === "e-custom-style-success" &&  <div className='icon-input-output'><SuccessIcon  color='#327627' width={'16px'} height={'16px'} /></div> }
                {ioValidationClassName === "e-custom-style-error" &&  <div className='icon-input-output'><WarningCircleIcon  color='#AF2043' width={'16px'} height={'16px'} /></div>}
              </div>
              <div className='ip-radio-selector'>
                <Controller
                  render={({ field }) => (
                    <div>
                      <span>
                        <RadioButtonComponent {...field} 
                          ref={refInputInterfacePoint} 
                          label="Input" 
                          name="interfaceDirection"
                          value='I'
                          labelPosition="After" 
                          created={inputOutputCreated}
                          change={inputOutputChanged}
                          disabled={isInterfaceDateDisabled}
                        />
                      </span>
                      <span>
                        <RadioButtonComponent {...field} 
                          ref={refOutputInterfacePoint} 
                          label="Output" 
                          name="interfaceDirection"
                          value='O'
                          labelPosition="After" 
                          created={inputOutputCreated}
                          change={inputOutputChanged}
                          disabled={isInterfaceDateDisabled}
                        />
                      </span>
                    </div>
                    
                  )}
                  name="interfaceDirection"
                  rules={{ required: true, 
                    validate: (ioPoint) =>{
                      return validateIo(ioPoint);
                    } 
                  }}
                  control={control}
                />
              </div>
              {errors.interfaceDirection && errors.interfaceDirection.type == 'required' && <span className="form-error">Please provide an option</span>}
            </div>
          </form>
          
          <label className="label-desc">Description:</label>
          <div >
            <Controller
              name="description"
              rules={{ required: false, maxLength: 200 }}
              control={control} 
              render={({ field }) => (
                <FormTextAreaField className='ip-description-container'{...field} maxLength={200} disabled={isDisabled} />
              )}
            />
          </div>
          <label className="label-desc2">(Description can be max 200 characters)</label>

          <label className="label-link">Linked activity (optional):</label>
          <div className={`${isDisabled ? "ip-activity-link-container-disabled" : "ip-activity-link-container"}`} onClick={addNewActivityLinkClick}>
            <span className={`${isDisabled ? "activity-link-button-disabled" : "activity-link-button"}`}><FontAwesomeIcon icon="plus-circle"/> Add activity link</span>
          </div>
          <div className={`${isDisabled ? "selected-activity-container-disabled" : "selected-activity-container"}`}>
            {selectedActivities && selectedActivities.map((activity) =>
              (
                <SelectedActivityComponent key={activity.activityId} activity={activity} deleteActivity={deleteActivityClick} editActivity={editActivityClick} addForecastDate={addForecastDate} />
              ))
            }
          </div>
         </div>
      </div>
      {addNewActivityLinkVisibility &&
        <LighthouseDialog isVisible={addNewActivityLinkVisibility} backButton={true} backButtonAction={backFromNewActivityLinkClick} closeButton={true} closeButtonAction={closeNewActivityLinkClick} title="Add activity link" width="63%" height="800px">
          <InterfaceActivitySelector activitySchedule={activityScheduleMilestone} selectedActivities={selectedActivities} closeNewActivityLinkClick={closeNewActivityLinkClick} addNewActivity={addNewActivity} editActivity={editActivity} />
        </LighthouseDialog>
      }  
      <div className="ipe-actions">
        { !addForecastDate &&  <SecondaryLighthouseButton onClick={handleCancelClick}>Cancel</SecondaryLighthouseButton>}
        { isEditable && !addForecastDate &&  <PrimaryLighthouseButton className="ml-15 mr-40" disabled={disabledSubmit} onClick={handleSubmit((data) => setData(data))}>Update Interface</PrimaryLighthouseButton> }
        { (!isEditable || isEditable == undefined) &&  <PrimaryLighthouseButton className="ml-15 mr-40" disabled={disabledSubmit} onClick={handleSubmit((data) => setData(data))}>Create Interface</PrimaryLighthouseButton> }
        { addForecastDate &&  <SecondaryLighthouseButton className="ml-15 mr-40" disabled={disabledSubmit} onClick={handleSubmit((data) => setData(data))}>Add forecast date</SecondaryLighthouseButton>}
      </div>
      </>
    }
    </>
  );
}

export default InterfacePointEditor;