import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { setAccessToken, setRole } from '../../../../redux/slices/authorizationSlice';
import { fetchProtectedResources } from '../../../../redux/slices/protectedResourcesSlice';
import { fetchRoleResourceMappings } from '../../../../redux/slices/roleResourceMappingsSlice';
import { dateNow } from '@microsoft/applicationinsights-core-js';

const AuthorizationService = (props)=> {
  const { dispatch, instance, accounts, accessTokenAvailable } = props;
  var accessToken = null;
  var authorized = "No";
  var roles, LHRoles, projects, disciplines = [];
  var allowedRoles = ["GlobalAdmin","Owner","Contributor","Member"];

  //Acquire access token
  if (accounts != undefined && accounts.length > 0) {
    
    const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
    };
    
    instance.acquireTokenSilent(request).then(response => {
        accessToken = response.accessToken;
        roles = response.idTokenClaims.roles;
        getRoles(roles);
        
        //Updating the access token and role states
        if(accessTokenAvailable != accessToken){
          dispatch(setAccessToken({accessToken : undefined}));
          dispatch(setAccessToken({accessToken : accessToken}));
          dispatch(setRole({authorized : authorized, roles : roles, LHRoles : LHRoles, projects : projects, disciplines : disciplines}));
        }
    }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(request).then(response => {
                accessToken = response.accessToken;
                roles = response.idTokenClaims.roles;
                getRoles(roles);
                if(accessTokenAvailable != accessToken){
                  dispatch(setAccessToken(accessToken));
                  dispatch(setRole({authorized : authorized, roles : roles, LHRoles : LHRoles, projects : projects, disciplines : disciplines}));
                }
            });
        }
    });
      
    }

  const getRoles = (roles) =>{
    LHRoles = roles.map(x=>allowedRoles.includes(x.split("_")[0]) && x.split("_")[0]);
    LHRoles = LHRoles.filter(x=>x != false);

    if(LHRoles.length > 0){
      projects = roles.map(x=>(x.split("_")[1] != undefined) ? x.split("_")[1] : x.split("_")[0] == "GlobalAdmin" && "All");
      projects = projects.filter(x=>x != false);

      if(projects.length > 0){
        disciplines = roles.map(x=>(x.split("_")[2] != undefined) ? x.split("_")[2] : (projects.includes(x.split("_")[1]) || x.split("_")[0] == "GlobalAdmin") && "All");
        disciplines = disciplines.filter(x=>x != false);
      }
    }

    if(LHRoles.length > 0 && projects.length > 0 && disciplines.length > 0){
      authorized = "Yes";
    }
    else{
      authorized = "No";
    }
  }
}

export default AuthorizationService;