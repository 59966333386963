import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../ErrorMessage';
import { fetchProjects, selectAllProjects } from '../../../../redux/slices/projectsSlice';
import CircularProgressBar from '../CircularProgressBar';
import ProjectSelector from '../ProjectSelector/ProjectSelector';
import { useMsal } from "@azure/msal-react";
import AuthorizationService from "../Authorization/AuthorizationService";
import { useSnackbar } from 'notistack';
import { fetchProtectedResources } from '../../../../redux/slices/protectedResourcesSlice';
import { fetchRoleResourceMappings } from '../../../../redux/slices/roleResourceMappingsSlice';

const ProjectSelectorContainer = (props) => {
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector(state => state.projects);
  const LHprojects = useSelector(selectAllProjects);
  const { instance, accounts } = useMsal();

  const { accessToken, authorized, LHRoles, projects, disciplines } = useSelector(state => state.authorization);

  useEffect(() => {
     //Acquire access token
     AuthorizationService({dispatch : dispatch, instance: instance, accounts: accounts});
  }, [accounts]);

  useEffect(() => {
    // fetch projects
    if (accessToken && authorized == "Yes"){
      dispatch(fetchProjects(accessToken));
    }
  }, [dispatch, accessToken, authorized]);

  useEffect(() => {
    if (accessToken && authorized == "Yes"){
      //Protected resources state
      dispatch(fetchProtectedResources(accessToken));
    }
  }, [dispatch, accessToken, authorized]);

  useEffect(() => {
    if (accessToken && authorized == "Yes" && LHRoles && projects && disciplines){
      //Role Resource Mapping state
      dispatch(fetchRoleResourceMappings({roles: LHRoles , projects: projects, disciplines: disciplines, accessToken: accessToken}));
    }
  }, [dispatch, accessToken, authorized]);

  return (
    <>
      { isLoading && 
        <CircularProgressBar />
      }
      { error && !isLoading &&
        <ErrorMessage message="Unable to get projects" />
      }
      { !isLoading && !error &&
        <ProjectSelector projects={LHprojects} collapsed={props.collapsed} />
      }
    </>
  );
}

export default ProjectSelectorContainer;