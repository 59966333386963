import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { fetchDisciplinesResourceCapacity } from '../../../../../redux/slices/disciplinesSlice';
import PlannedResourceCapacityRequirementChart from '../PlannedResourceCapacityRequirementChart';
import './PlannedResourceCapacityRequirementContainer.scss';

const PlannedResourceCapacityRequirementContainer = (props) => {
    const {selectedFilterTypes} = props;
    const dispatch = useDispatch();
    const { isLoading, error, resourceCapacityByProject } = useSelector(state => state.disciplines);
    const { selectedProjectId } = useSelector(state => state.projects);
    const { selectedScenarioId } = useSelector(state => state.scenarios);
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const { selectedScenarioOptionId } = useSelector(state => state.scenarioOptionsComparison);
    const [resourceCapacityFilter, setResourceCapacityFilter] = useState([]);

    /* legend filter handling */
    const handleResourceCapacityFiltering = (event) => {
        if(resourceCapacityFilter.includes(event.target.innerText)){
          setResourceCapacityFilter(arrayRemove(resourceCapacityFilter,event.target.innerText));
        }
        else{
          const resourceCapacityArray = [...resourceCapacityFilter, event.target.innerText];
          setResourceCapacityFilter(resourceCapacityArray);
        }
      }
      const arrayRemove = (arr, value) => {
        return arr.filter((data)=>{
            return data != value;
        });
      }

    const processData = (rawData) => {

        let balancedRequirement = [];
        let original = [];
        let minDate;
        let maxDate;
        let minSortedBalanceReqDate;
        let maxSortedBalanceReqDate;

        let sortedCapacity = rawData?.item1?.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        let sortedBalancedReq = rawData?.item2?.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        let minSortedCapactiyDate = new Date(sortedCapacity[0]?.date);
        let maxSortedCapactiyDate = new Date(sortedCapacity[sortedCapacity.length -1]?.date);

        /* if sortedBalancedReq, then setting the min & max sorted balanced req date */
        if(sortedBalancedReq.length > 0)
        {
            minSortedBalanceReqDate = new Date(sortedBalancedReq[0]?.date);
            maxSortedBalanceReqDate = new Date(sortedBalancedReq[sortedBalancedReq.length -1]?.date);
        }

        /* setting min and max date with compairing with capacity and balanced req date */
        if (minSortedBalanceReqDate !== undefined && minSortedCapactiyDate > minSortedBalanceReqDate) {
            minDate = minSortedBalanceReqDate;
        }
        else {
            minDate = minSortedCapactiyDate;
        }
        if (maxSortedBalanceReqDate !== undefined && maxSortedCapactiyDate < maxSortedBalanceReqDate) {
            maxDate = maxSortedBalanceReqDate;
        }
        else {
            maxDate = maxSortedCapactiyDate;
        }

        let allDiscipline = [];
        if (sortedCapacity) {
            if (selectedFilterTypes.length > 0) {
                for (let dis of selectedFilterTypes) {
                    allDiscipline.push(dis?.value)
                }

                sortedCapacity = sortedCapacity?.filter(sortData => allDiscipline.some(Id => Id === sortData?.disciplineId));
                sortedBalancedReq = sortedBalancedReq?.filter(sortData => allDiscipline.some(Id => Id === sortData?.disciplineId));
            }

            for (let data of sortedCapacity) {
                original.push({ date: data.date, hours: data.original });
            }
            for (let res of sortedBalancedReq) {
                balancedRequirement.push({ date: res.date, hours: res.hours });
            }
        }

        /* sum of capacities present at any particular date */
        var sumOfCapacity = {};
        original.forEach(function (d) {
            if (sumOfCapacity.hasOwnProperty(d.date)) {
                sumOfCapacity[d.date] = sumOfCapacity[d.date] + d.hours;
            } else {
                sumOfCapacity[d.date] = d.hours;
            }
        });
        var sumOfCapacityArray = [];
        for (var prop in sumOfCapacity) {
            sumOfCapacityArray.push({ date: prop, hours: sumOfCapacity[prop] });
        }

        /* sum of balanced requirement present at any particular date */
        var sumOfBalancedRequirement = {};
        balancedRequirement.forEach(function (d) {
            if (sumOfBalancedRequirement.hasOwnProperty(d.date)) {
                sumOfBalancedRequirement[d.date] = sumOfBalancedRequirement[d.date] + d.hours;
            } else {
                sumOfBalancedRequirement[d.date] = d.hours;
            }
        });
        var sumOfBalancedRequirementArray = [];
        for (var prop in sumOfBalancedRequirement) {
            sumOfBalancedRequirementArray.push({ date: prop, hours: sumOfBalancedRequirement[prop] });
        }
        
        if (resourceCapacityFilter.includes('Original Capacity')) {
            sumOfCapacityArray = [];
            minDate = minSortedBalanceReqDate;
            maxDate = maxSortedBalanceReqDate;

        }
        if (resourceCapacityFilter.includes('Balanced Requirement')) {
            sumOfBalancedRequirementArray = [];
            if(minSortedBalanceReqDate !== undefined && maxSortedBalanceReqDate !== undefined) {
                minDate = minSortedCapactiyDate;
                maxDate = maxSortedCapactiyDate;
            }
        }
        
        /* obj for chart */
        let processed = {
            balanced: sumOfBalancedRequirementArray,
            original: sumOfCapacityArray,
            minDate: minDate,
            maxDate: maxDate
        }
        return processed;
    }

    useEffect(() => {
        /**
         * Acquire access token
         */
        if (accounts.length > 0) {
            const request = {
                scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then(response => {
                setAccessToken(response.accessToken);
            }).catch(error => {
                // acquireTokenSilent can fail for a number of reasons, fallback to interaction
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenPopup(request).then(response => {
                        setAccessToken(response.accessToken);
                    });
                }
            });
        }
    }, [accounts]);
    
      /**
       * fetching the resource capacity for all the discipline and balanced requirement
       */
      
    useEffect(() => {
        if (accessToken) {
            dispatch(fetchDisciplinesResourceCapacity({ projectId: selectedProjectId, scenarioId: selectedScenarioId, scenarioOptionId: selectedScenarioOptionId, accessToken: accessToken }))
        }
    }, [dispatch, selectedProjectId, selectedScenarioId, selectedScenarioOptionId, accessToken]);

  
    return (
        <>
            <div className="chart-container">
                <div className='popover-header'>
                </div>
                <div className="alert-title">
                </div>
                <div className='popover-content'>
                    {isLoading &&
                        <CircularProgressBar />
                    }
                    {!isLoading && !error && resourceCapacityByProject &&
                        <div className='content-wrapper'>
                            <div className='legend-container' >
                                <div className='legends' onClick={handleResourceCapacityFiltering}>
                                    <div className={resourceCapacityFilter.includes('Balanced Requirement') ? "legend inactive" : "legend bal"}></div>
                                    <h6>Balanced Requirement</h6>
                                </div>
                                <div className='legends' onClick={handleResourceCapacityFiltering}>
                                    <div className={resourceCapacityFilter.includes('Original Capacity') ? "legend inactive-dashed" : "legend original"}></div>
                                    <h6>Original Capacity</h6>
                                </div>
                            </div>
                            <PlannedResourceCapacityRequirementChart resourceCapacityData={processData(resourceCapacityByProject)} />
                        </div>
                    }
                </div>
            </div>
        </>
    );


};

export default PlannedResourceCapacityRequirementContainer;