import React from 'react';
import './LinearProgressBar.scss';

const LinearProgressBar = (props) => {

    const { percentage = null, backgroundBarColour = null, actualBarColour = null, height = '4px'} = props;
    const progressPercentage = `${Math.min(percentage, 100)}%`;

    let barColour = "#C0E4D1";
    let backgroundColour = "#CCD2DA";

    if (actualBarColour){
        barColour = actualBarColour;
    }

    if(backgroundBarColour){
        backgroundColour = backgroundBarColour;
    }

  return (
    <div className="linear-progress-bar-container-shared" style={{ backgroundColor: backgroundColour, height : height}}>
      { percentage > 0 && <div className="linear-progress-bar-shared" style={{ width: progressPercentage, backgroundColor: barColour, height : height}} /> }
    </div>
  );
}

export default LinearProgressBar;