import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { projectsEndpoint } from '../../api/apiRoutes';

/**
 * Fetches projects
 * @returns projects
 */
 export const fetchProjects = createAsyncThunk(
  'project/fetchprojects', async (accessToken, thunkAPI) => {
     try {
        const response = await api.get(projectsEndpoint(), { headers: { Authorization: `Bearer ${accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Create project adapter
 */
 const projectAdapter = createEntityAdapter({
  selectId: (project) => project.projectId,
})

/**
 * Create project slice / reducers
 */
const projectsSlice = createSlice({
  name: 'projects',
  initialState: projectAdapter.getInitialState( { selectedProjectId: undefined,
                                                  sidebarOpen: true,
                                                  isLoading: false,
                                                  error: undefined } ),
  reducers: {
      setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload;
    },
     setSideBarOpen: (state, action) => {
      state.sidebarOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        projectAdapter.setAll(state, action.payload);
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Project selectors
 */
 export const {
  selectAll: selectAllProjects,
  selectById: selectProjectById,
} = projectAdapter.getSelectors((state) => state.projects);

/**
 * Project actions
 */
 export const {  
  setSelectedProjectId, 
  setAllProjects,
  setSideBarOpen
} = projectsSlice.actions;

/**
 * Export reducer
 */
export default projectsSlice.reducer;