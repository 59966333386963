import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { roleResourceMappingEndpoint } from '../../api/apiRoutes';

/**
 * Fetches roleResourceMappings
 * @returns roleResourceMappings
 */
 export const fetchRoleResourceMappings = createAsyncThunk(
  'admin/fetchRoleResourceMappings', async (params, thunkAPI) => {
     try {
        const response = await api.get(roleResourceMappingEndpoint(params.roles, params.projects, params.disciplines), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Create Role Resource Mappings adapter
 */
 const roleResourceMappingsSliceAdapter = createEntityAdapter({
  selectId: (admin) => admin.id,
})

/**
 * Create Role Resource Mappings Slice / reducers
 */
 const roleResourceMappingsSlice = createSlice({
  name: 'roleResourceMappings',
  initialState: roleResourceMappingsSliceAdapter.getInitialState( {  selectedMappingId: undefined,
                                                    isLoading: false,
                                                    error: undefined }),
  reducers: {
    setSelectedMappingId: (state, action) => {
      state.setSelectedMappingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleResourceMappings.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchRoleResourceMappings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        roleResourceMappingsSliceAdapter.setMany(state, action.payload);
      })
      .addCase(fetchRoleResourceMappings.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Role Resource Mappings actions
 */
 export const {  
  selectById: selectRoleResourceMappingById,
} = roleResourceMappingsSlice.actions;

export const selectAllRoleResourceMappings = (state) => {
  const roleResourceMappings = state.roleResourceMappings;
  const filteredRoleResourceMappings = roleResourceMappingsSliceAdapter.getSelectors((state) => roleResourceMappings).selectAll();
  return filteredRoleResourceMappings;
}

/**
 * Export reducer
 */
export default roleResourceMappingsSlice.reducer;