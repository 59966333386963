import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk 
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { packagesEndpoint, criticalPackagesEndpoint } from '../../api/apiRoutes';

/**
 * 
 * @returns Fetches packages
 */
export const fetchPackages = createAsyncThunk(
  'package/fetchpackages', async (params, thunkAPI) => {
     try {
        const response = await api.get(packagesEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * 
 * @returns Fetches critical packages
 */
 export const fetchCriticalPackages = createAsyncThunk(
  'package/fetchcriticalpackages', async (params, thunkAPI) => {
     try {
        const response = await api.get(criticalPackagesEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Create package adapter
 */
const packagesAdapter = createEntityAdapter({
  selectId: (pkg) => pkg.packageId,
})

/**
 * Create schedule slice / reducers
 */
const packagesSlice = createSlice({
  name: 'packages',
  initialState: packagesAdapter.getInitialState( {  selectedPackageId: undefined, 
                                                    isLoading: false,
                                                    error: undefined,
                                                    criticalPackages: undefined,
                                                    criticalPackagesLoading: false } ),
  reducers: {
    setSelectedPackageId: (state, action) => {
      state.selectedPacakgeId = action.payload;
    },
    removeAllPackages: (state) => {
      packagesAdapter.removeAll(state);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        packagesAdapter.setAll(state, action.payload);
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(fetchCriticalPackages.pending, (state, action) => {
        state.criticalPackagesLoading = true;
        state.error = undefined;
      })
      .addCase(fetchCriticalPackages.fulfilled, (state, action) => {
        state.criticalPackagesLoading = false;
        state.error = undefined;
        state.criticalPackages = action.payload;
      })
      .addCase(fetchCriticalPackages.rejected, (state, action) => {
        state.criticalPackagesLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Package selectors
 */
export const {
  selectAll: selectAllPackages,
  selectById: selectPackageById,
} = packagesAdapter.getSelectors((state) => state.packages);

/**
 * PAckage actions
 */
export const {  packagesSuccess, 
                startLoading, 
                hasError, 
                setSelectedPackageId, 
                setAllPAckages,
                removeAllPackages
              } = packagesSlice.actions;

export const selectAllNonKEMPackages = (state) => {
  const packages = state.packages;
  const nonKEMpackages = packagesAdapter.getSelectors((state) => packages).selectAll().filter(workPackage => !workPackage.packageName.includes('KEM'));
  return nonKEMpackages;
}

/**
 * Export reducer
 */
export default packagesSlice.reducer;