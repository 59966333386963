import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk ,
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { activityCompletionPipelineEndPoint } from '../../api/apiRoutes';

/**
 * 
 * @returns Fetches activity completion pipeline
 */
export const fetchActivityCompletionPipeline = createAsyncThunk(
  'scenario/fetchActivityCompletionPipeline', async (params, thunkAPI) => {
    try{
      const response = await api.get(activityCompletionPipelineEndPoint(params.projectId, params.phaseId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Create activities completion pipeline adapter
 */
 const activityCompletionPipelineAdapter = createEntityAdapter({
  selectId: (activityCompletionPipeline) => activityCompletionPipeline.phaseId,
})

/**
 * Create activities completion pipeline slice / reducers
 */
const activityCompletionPipelineSlice = createSlice({
  name: 'activityCompletionPipeline',
  initialState: activityCompletionPipelineAdapter.getInitialState( { phaseId: undefined,
                                                      isLoading: false,
                                                      error: undefined
                                                   } ),
  reducers: {
    setSelectedId: (state, action) => {
      state.phaseId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivityCompletionPipeline.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchActivityCompletionPipeline.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        activityCompletionPipelineAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchActivityCompletionPipeline.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Activity completion pipeline selectors
*/
 export const {
  selectAll: selectAllActivityCompletionPipeline,
  selectById: selectStateById,
} = activityCompletionPipelineAdapter.getSelectors((state) => state.activityCompletionPipeline);

/**
 * Export reducer
 */
export default activityCompletionPipelineSlice.reducer;