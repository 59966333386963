import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { fetchActivitySchedule, selectAllActivitySchedule } from '../../../../../redux/slices/activityScheduleSlice';
import InfoMessage from '../../../../../components/Shared/molecules/InfoMessage';
import CreateScenarioScheduleChart from '../../molecules/CreateScenarioScheduleChart';
import { fetchDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import { fetchPackagePctCompleteCalcTypes, selectAllPackagePctCompleteCalcTypes } from '../../../../../redux/slices/packagePctCompleteCalcTypesSlice';

import './CreateScenarioScheduleContainer.scss';

const CreateScenarioScheduleContainer = (props) => {
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const activitySchedule = useSelector(selectAllActivitySchedule);
  const packagePctCompleteCalcTypes = useSelector(selectAllPackagePctCompleteCalcTypes);
  const { error, isLoading } = useSelector(state => state.activitySchedule);
  const filteredDisciplineIds = useSelector(state => state.disciplines.filteredDisciplineIds);

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  /**
  * Fetches activity schedule, disciplines and package calculation types
  */
   useEffect(() => {
      if (accessToken) {
        dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken }))
        .then((respone) => dispatch(fetchActivitySchedule({ projectId: selectedProjectId, accessToken: accessToken })))
      }
    }, [dispatch, selectedProjectId, accessToken]);

  return (
    <>
      {isLoading &&
        <CircularProgressBar />
      }
      {error && !isLoading &&
        <ErrorMessage message="Unable to display activity optimisation change" />
      }
      {!isLoading && !error && activitySchedule.length === 0 &&
        <>
          <InfoMessage message="There are no activity optimisation changes for the selected option." />
        </>
      }
      {!isLoading && !error && activitySchedule.length > 0 &&
        <>
          <div className="cssc-activity-container">
            <CreateScenarioScheduleChart activityScheduleDateChange={activitySchedule} filteredDisciplineIds={filteredDisciplineIds} />
          </div>
        </>
      }
    </>
  );
}

export default CreateScenarioScheduleContainer;