import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectDisciplineById, selectAllDisciplines, fetchDisciplines } from '../../../../../redux/slices/disciplinesSlice';
import DesignProductionViewerProgress from '../../organisms/DesignProductionViewerProgress';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';

const DesignProductionViewerProgressContainer = () => {
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const { error, isLoading, filteredDisciplineIds } = useSelector(state => state.disciplines);
  const disciplines = useSelector(selectAllDisciplines).filter((d) => d.disciplineName !== "External Milestones");
  const [currentDisciplineIds, setCurrentDisciplineIds] = useState([]);
  const [isDisciplinesReady, setIsDisciplinesReady] = useState(false);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

 const processDisciplines = (disciplines, filteredDisciplineIds ) =>{

  if(filteredDisciplineIds.length> 0){
        return filteredDisciplineIds;
  }else{
        return disciplines.map(({disciplineId}) => { return disciplineId })
       }
  }
  
  useEffect(() => {
    /**
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

   /**
   * Fetches the disciplines
   */
      useEffect(() => {
        if (accessToken){
          dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken}));
        }
      }, [dispatch, selectedProjectId, accessToken]);

/**
 * Process the schedule from tree to gantt
 * only when the data is ready
 */
 useEffect(() => {
  if(!isLoading && disciplines?.length > 0){
    // Process Filter and Fetched Discipline data
    setCurrentDisciplineIds(processDisciplines(disciplines, filteredDisciplineIds ));
}
}, [isLoading, filteredDisciplineIds]);

useEffect(() => {
  // Set display state
  if(currentDisciplineIds){
    setIsDisciplinesReady(true);
  }
}, [currentDisciplineIds])

  return (
    <>
       { isDisciplinesReady !== true && 
        <CircularProgressBar />
      }
      { error && isDisciplinesReady !== true &&
        <ErrorMessage message="Unable to display design production viewer" />
      }
      { isDisciplinesReady === true && !error && 
         <DesignProductionViewerProgress filteredDisciplineIds={currentDisciplineIds} />
      }
      </>
  )
}

export default DesignProductionViewerProgressContainer;