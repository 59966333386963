import { combineReducers, configureStore } from '@reduxjs/toolkit';

import projects from './slices/projectsSlice';
import disciplines from './slices/disciplinesSlice';
import phases from './slices/phasesSlice';
import alerts from './slices/alertsSlice';
import phaseProgress from './slices/phaseProgressSlice';
import info from './slices/infoSlice';
import packages from './slices/packagesSlice';
import scenarios from './slices/scenariosSlice';
import scenarioOptions from './slices/scenarioOptionsSlice';
import scenarioOptionsComparison from './slices/scenarioOptionsComparisonSlice';
import scenarioStatus from './slices/scenarioStatusSlice';
import createScenario from './slices/createScenarioSlice';
import scenarioConstraints from './slices/scenarioConstraintsSlice';
import dpv from './slices/dpvSlice';
import deliverables from './slices/deliverablesSlice';
import activities from './slices/activitiesSlice';
import users from './slices/usersSlice';
import usersAd from './slices/userAdSlice';
import activityCompletionStatus from './slices/activityCompletionStatusSlice';
import updateActivityCompletionStatus from './slices/updateActivityCompletionStatusSlice';
import activityCompletionPipeline from './slices/activityCompletionPipelineSlice';
import ScenarioComment from './slices/ScenarioCommentSlice';
import alertActivityDrivers from './slices/alertActivityDriversSlice';
import alertDeliverableDrivers from './slices/alertDeliverableDriversSlice';
import relatedAlerts from './slices/relatedAlertsSlice';
import scenarioOptionFastest from './slices/scenarioOptionFastestSlice'
import authorization from './slices/authorizationSlice';
import protectedResources from './slices/protectedResourcesSlice';
import roleResourceMappings from './slices/roleResourceMappingsSlice';
import packagePctCompleteCalcTypes from './slices/packagePctCompleteCalcTypesSlice';
import activityOptimisationChange from './slices/activityOptimisationChangeSlice';
import alertReason from './slices/alertReasonSlice';
import alertSelection from './slices/alertSelectionSlice';
import projectSettings from './slices/projectSettingsSlice';
import activityFocusArea from './slices/activityFocusAreaSlice';
import interfacePoints from './slices/interfacePointsSlice';
import activitySchedule from './slices/activityScheduleSlice';
import activityScheduleMilestone from './slices/activityScheduleMilestoneSlice';
import activityChanges from './slices/activityChangesSlice';
import optimisationFocusAreas from './slices/optimisationFocusAreasSlice';
import fullActivitySchedule from './slices/fullActivityScheduleSlice';

const reducer = combineReducers({
  projects,
  disciplines,
  phases,
  alerts,
  phaseProgress,
  info,
  packages,
  scenarios,
  scenarioOptions,
  scenarioOptionsComparison,
  scenarioStatus,
  createScenario,
  scenarioConstraints,
  dpv,
  deliverables,
  activities,
  users,
  usersAd,
  activityCompletionStatus,
  updateActivityCompletionStatus,
  activityCompletionPipeline,
  ScenarioComment,
  alertActivityDrivers,
  alertDeliverableDrivers,
  relatedAlerts,
  scenarioOptionFastest,
  authorization,
  protectedResources,
  roleResourceMappings,
  packagePctCompleteCalcTypes,
  activityOptimisationChange,
  alertReason,
  alertSelection,
  projectSettings,
  activityFocusArea,
  interfacePoints,
  activitySchedule,
  activityScheduleMilestone,
  activityChanges,
  optimisationFocusAreas,
  fullActivitySchedule
});

const store = configureStore({
    reducer 
});

export default store;