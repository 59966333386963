import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { WarningCircleIcon, CloseIcon } from '../../../../../Icons';
import { getFormattedDate } from '../../../../../utils';

import './ScenarioProperties.scss';

const ScenarioProperties = (props) => {
  const { scenario, currentScenarioId } = props;
  const [divTimer, setDivTimer] = useState();
  const { selectedProjectId } = useSelector(state => state.projects);

  const closeNotification = () => {
    setDivTimer(false);
  }

  /**
   * Selected filter types changed
   */
  useEffect(() => {
    setDivTimer(true);
  },
    [currentScenarioId]);

  return (
    <>
      {currentScenarioId && scenario &&
        <>
          <div className="scenario-properties-container">
            {scenario.scenarioStatus.toLowerCase() === 'failed' && divTimer === true &&
              <div className="pp-widgets-scenario">
                <div className="scenario-toast-text">
                  <div className="scenario-container-scheduled-analysis">
                    <div className="scenario-header-scheduled-analysis">
                      <div className="scenario-icon-label-container-scheduled-analysis">
                        <span className="scenario-span-warning-scheduled-analysis"></span>
                        <span className="scenario-warning-icon-scheduled-analysis">
                          <WarningCircleIcon />
                        </span>
                        <p>
                          The scenario has failed
                        </p>
                      </div>
                      <div className='scenario-notification-close'>
                        <CloseIcon width="7.5px" height="7.5px" color="#005A84" onClick={closeNotification} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {scenario.optionCount > 1 && scenario.scenarioStatus.toLowerCase() === 'draft' && divTimer === true &&
              <div className="pp-widgets-scenario-draft">
                <div className="scenario-toast-text-draft">
                  <div className="scenario-container-scheduled-analysis-draft">
                    <div className="scenario-header-scheduled-analysis-draft">
                      <div className="scenario-icon-label-container-scheduled-analysis-draft">
                        <span className="scenario-span-warning-scheduled-analysis-draft"></span>
                        <span className="scenario-warning-icon-scheduled-analysis-draft">
                          <WarningCircleIcon />
                        </span>
                        <p>
                          The selected scenario is still a draft. <a className="help-center-btn" href={`/${selectedProjectId}/scenarios/${scenario.scenarioId}/editscenario`} >Edit Scenario</a>
                        </p>
                      </div>
                      <div className='scenario-notification-close-draft'>
                        <CloseIcon width="7.5px" height="7.5px" color="#005A84" onClick={closeNotification} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="properties-sub-container" >
              <div className="properties-title">Properties:</div>
              <div className="properties-sub-container-card">
                <table className="properties-table">
                  <tbody>
                    <tr>
                      <th>Name:</th>
                      <td>{scenario.scenarioName}</td>
                    </tr>
                    <tr>
                      <th>Description:</th>
                      <td>{scenario.scenarioType.toLowerCase() === "system" ? 'Scenario of the day generated by lighthouse' : scenario.scenarioDescription}</td>
                    </tr>
                    <tr>
                      <th>Created By: </th>
                      <td>{scenario.createdBy}</td>
                    </tr>
                    <tr>
                      <th>Creation Date: </th>
                      <td>{getFormattedDate(scenario.createdOn)}</td>
                    </tr>
                    <tr>
                      <th>Last Modified By:</th>
                      <td>{scenario.modifiedBy}</td>
                    </tr>
                    <tr>
                      <th>Last Modified Date:</th>
                      <td>{getFormattedDate(scenario.modifiedOn)}</td>
                    </tr>
                    <tr>
                      <th>Type:</th>
                      <td>{scenario.scenarioType}</td>
                    </tr>
                    <tr>
                      <th>Status:</th>
                      <td>
                        {scenario.scenarioStatus.toLowerCase() === 'active' && <span>Options Available</span>}
                        {scenario.scenarioStatus.toLowerCase() === 'pending' && <span>Processing</span>}
                        {scenario.scenarioStatus.toLowerCase() !== 'pending' && scenario.scenarioStatus.toLowerCase() !== 'active' && <span>{scenario.scenarioStatus}</span>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      }
    </>

  )
}

export default ScenarioProperties;