import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  ColumnsDirective,
  ColumnDirective,
  GanttComponent,
  Inject,
  Selection,
  Filter,
  DayMarkers,
  EventMarkersDirective,
  EventMarkerDirective
} from '@syncfusion/ej2-react-gantt';
import { setAlertsDdvToggle, setSelectedAlertId, setVisibleAlertIds, selectAlertById } from '../../../../../redux/slices/alertsSlice';
import { setInfoSelectedPhaseId } from '../../../../../redux/slices/infoSlice';
import { setSelectedTab } from '../../../../../redux/slices/infoSlice';
import ChartTask from '../../molecules/ChartTask';
import ChartMilestone from '../../molecules/ChartMilestone';
import { getFormattedDate, getGlobalFormat, LighthouseTooltip } from '../../../../../utils';
import { getPhaseNodeProgressColour } from '../../../../../utils';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { AlertIcon, ExtentIcon, MinusIcon, PlusIcon } from '../../../../../Icons';
import './DesignProductionViewerChart.scss';

var classNames = require('classnames');

/**
 * Task template
 * @param {object} props 
 * @returns Taskbar
 */
const TaskbarTemplate = (props) => {
  const dispatch = useDispatch();
  const { selectedAlertId } = useSelector(state => state.alerts);
  const [isSelected, setIsSelected] = useState(false);

  const taskData = props.taskData;

  useEffect(() => {
    if (taskData.alerts?.some(alert => alert.alertId === selectedAlertId)) {
      setIsSelected(true);
    }
    else {
      setIsSelected(false);
    }
  }, [selectedAlertId]);

  /**
   * Alert clicked
   * @param {string} alertId 
   */
  const alertOnClick = (alertId) => {
    dispatch(setSelectedTab("AlertList"));
    dispatch(setSelectedAlertId(alertId));
  }

  // assign alert css classes
  let alertClasses = classNames({
    'task-alert': true,
    'alert-primary': taskData.highestAlertType === 'Primary',
    'alert-secondary': taskData.highestAlertType === 'Secondary',
    'highlight': isSelected,
  });

  /**
   * Alert tooltip template
   * @returns html
   */
  const alertTooltipTemplate = () => {
    let alertTooltipContent = "";
    taskData.alerts.forEach(alert => alertTooltipContent += `<li>${alert.alertName}</li>`)
    return (
      <>
        <ul className="alert-tooltip-list" dangerouslySetInnerHTML={{ __html: alertTooltipContent }}></ul>
      </>
    );
  }

  return (
    <>
      <div className="e-gantt-child-taskbar task-wrapper">
        <ChartTask phaseId={taskData.phaseId}
          disciplineId={taskData.disciplineId}
          label={taskData.name}
          actualPercentageComplete={Math.round(taskData.actualPercentageComplete)}
          plannedPercentageComplete={Math.round(taskData.plannedPercentageComplete)}
        />
      </div>
      {taskData.alerts &&
        <LighthouseTooltip content={alertTooltipTemplate} offsetX={60} offsetY={-10} width={420}>
          <span className={alertClasses} onClick={() => alertOnClick(taskData.alerts.sort((a, b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))[0].alertId)}>
            {taskData.alerts.length > 1 && <span className={classNames('alert-counter', { grey: taskData.isFiltered })}>{taskData.alerts.length}</span>}
            <AlertIcon />
          </span>
        </LighthouseTooltip>
      }
    </>
  );
}

/**
 * Parent Task template
 * @param {object} props 
 * @returns ParentTaskbar
 */
 const ParentTaskbarTemplate = (props) => {
  const taskData = props.taskData;
  const percentageComplete = taskData.PkgActualPercentageComplete != undefined ? taskData.PkgActualPercentageComplete : taskData.actualPercentageComplete;
  return (
    <>
      <div className="e-gantt-parent-taskbar-inner-div e-row-collapse e-gantt-parent-taskbar" style={{ height: "100%"}}>
        <div className="e-gantt-parent-progressbar-inner-div e-row-collapse e-gantt-parent-progressbar" style={{ width: percentageComplete + "%", height: "100%", borderRadius: "0px"}}></div>
      </div>
    </>
  );
 }

/**
 * Milestone template
 * @param {object} props 
 * @returns Milestone
 */
const MilestoneTemplate = (props) => {
  const taskData = props.taskData;

  return (
    <div className="e-gantt-child-taskbar task-wrapper">
      <ChartMilestone phaseId={taskData.phaseId}
        label={taskData.name}
        actualPercentageComplete={Math.round(taskData.actualPercentageComplete)}
        plannedPercentageComplete={Math.round(taskData.plannedPercentageComplete)}
      />
    </div>
  );
}

/**
 * Alert column template
 * @param {object} props 
 * @returns 
 */
const AlertColumnTemplate = (props) => {
  const taskData = props.taskData;

  let alertClasses = classNames({
    'package-alert': true,
    'alert-primary': taskData.highestAlertType === 'Primary',
    'alert-secondary': taskData.highestAlertType === 'Secondary',
  });

  return (
    <span className="alert-column">
      {taskData.alertCount > 0 &&
        <span className={alertClasses}>
          {taskData.alertCount > 1 && <span className={classNames('alert-counter', { grey: taskData.isFiltered })}>{taskData.alertCount}</span>}
          <AlertIcon />
        </span>
      }
    </span>
  );
}

/**
 * Package column template
 * @param {object} props 
 * @returns Package column
 */
const PackageColumnTemplate = (props) => {
  const taskData = props.taskData;
  const backgroundColour = `${getPhaseNodeProgressColour(taskData.actualPercentageComplete, taskData.plannedPercentageComplete)}`;

  return (
    <span className="package-column">
      {taskData.phaseId &&
        <span className="circle-progress-indicator" style={{ backgroundColor: backgroundColour }}>
        </span>
      }
      {taskData.packageId && !taskData.phaseId &&
        <span className="vertical-progress-indicator" style={{ backgroundColor: backgroundColour }}>
        </span>
      }

      <span>
        {props.name}
      </span>
    </span>
  );
}

/**
 * Tooltip template
 * @param {object} props 
 * @returns 
 */
const TooltipTemplateTaskbar = (props) => {
  return (
    <>
       <div className='templete-tooltip-container'>
         <div className='templete-tooltip-field'>
           <span className='text-desc'>{props?.taskData?.name}</span>
         </div>
         <div className='templete-tooltip-field'>
           <span className='text-title'>Starts On:</span>
           <span className='text-desc'>{getFormattedDate(props?.taskData?.startDate)}</span>
         </div>
         <div className='templete-tooltip-field'>
           <span className='text-title'>Ends On:</span>
           <span className='text-desc'>{getFormattedDate(props?.taskData?.endDate)}</span>
         </div>
         <div className='templete-tooltip-field'>
           <span className='text-title'>Planned Schedule Activities:</span>
           <span className='text-desc'>{Math.round(props?.taskData?.plannedPercentageComplete??0)}%</span>
         </div>
         {props?.taskData?.pctType === 1 && 
          <div className='templete-tooltip-field'>
            <span className='text-title'>Estimated Schedule Activities:</span>
            <span className='text-desc'>{Math.round(props?.taskData?.activityBasedActualPercComplete??0)}%</span>
         </div>}
        {props?.taskData?.pctType === 0 && 
         <div className='templete-tooltip-field'>
           <span className='text-title'>Estimated Deliverable:</span>
           <span className='text-desc'>{Math.round(props?.taskData?.deliverableBasedActualPercComplete??0)}%</span>
         </div>
         }
       </div>
    </>
  );
}


/**
 * The design production viewer main component
 * @param {*} props 
 * @returns 
 */
const DesignProductionViewerChart = (props) => {
  const { taskData } = props;
  const { selectedAlertId } = useSelector(state => state.alerts);
  const { selectedPhaseId } = useSelector(state => state.info);
  const { filteredDisciplineIds } = useSelector(state => state.disciplines);
  const ganttComponentref = useRef();
  const [isFiltering, setIsFiltering] = useState(false);
  const [isInitialising, setIsInitialising] = useState(true);
  const dispatch = useDispatch();
  var classNames = require('classnames');

  const taskFields = {
    id: 'id',
    packageId: 'packageId',
    filterDiciplineId: 'filterDiciplineId',
    name: 'name',
    startDate: 'startDate',
    endDate: 'endDate',
    child: 'phases',
    dependency: 'predecessors',
    plannedPercentageComplete: 'plannedPercentageComplete',
    progress: 'actualPercentageComplete',
    PkgActualPercentageComplete: 'PkgActualPercentageComplete',
    activityBasedActualPercComplete: 'activityBasedActualPercComplete',
    deliverableBasedActualPercComplete: 'deliverableBasedActualPercComplete',
    pctType: 'pctType',
    alertCount: 'alertCount',
    disiplineId: 'disciplineId'
  }

  useEffect(() => {
    // clear any alert filtering set from treeview
    dispatch(setAlertsDdvToggle(false));
    dispatch(setVisibleAlertIds([]));
  }, []);

  /* filter chart based on discipline selection */
  useEffect(() => {
    filterByDisciplines();
  }, [filteredDisciplineIds]);

  /* selected alert changed */
  useEffect(() => {
    if (ganttComponentref.current?.isRendered) {
      expandSelectedAlertPackage();
    }
  }, [selectedAlertId]);

  /**
   * Filter by selected disciplines
   */
  const filterByDisciplines = () => {
    if (ganttComponentref.current?.isRendered) {
      ganttComponentref.current?.clearFiltering();
      if (filteredDisciplineIds.length > 0) {
        ganttComponentref.current?.filterByColumn("filterDiciplineId", "equal", filteredDisciplineIds);
      } else {
        ganttComponentref.current?.filterByColumn("filterDiciplineId", "equal", []);
      }
    } else {
      //console.log('not rendered');
    }
  }

  /**
   * Expands the package containing the selected alert
   */
  const expandSelectedAlertPackage = () => {
    // get the Gantt datasource and find the package
    // that the selected alert id exists
    // collapse all other packages and expand the 
    // selected alert one
    let packageData = ganttComponentref.current.dataSource;
    for (let pkg of packageData) {
      for (let phase of pkg.phases) {
        if (phase.alerts) {
          for (let alert of phase.alerts) {
            if (alert.alertId == selectedAlertId) {
              try {
                // expand the package
                ganttComponentref.current?.expandByID(pkg.id);

                // scroll into view
                let row = ganttComponentref.current?.getRowByID(phase.id);
                row.scrollIntoView(false);
                
                let rowPosition = row.getBoundingClientRect().y;
                let scrollTop = ganttComponentref.current?.ganttChartModule.scrollElement.scrollTop;
                if(rowPosition > 500){
                  ganttComponentref.current?.setScrollTop(scrollTop+300);
                }
                
              } catch {
                // package may be filtered
              }
            }
          }
        }
      }
    }
  }

  /* selected phase changed */
  useEffect(() => {
    if (ganttComponentref.current?.isRendered) {
      expandSelectedPhasePackage();
    }
  }, [selectedPhaseId]);

  /** Expands the selected phase package */
  const expandSelectedPhasePackage = () => {
    // get the Gantt datasource and find the package
    // that the selected phase id exists
    // collapse all other packages and expand the 
    // selected phase one
    let packageData = ganttComponentref.current.dataSource;
    for (let pkg of packageData) {
      for (let phase of pkg.phases) {
        if (phase.phaseId == selectedPhaseId) {
          // expand the package and collapse others
          //ganttComponentref.current?.collapseAll();
          try {
            ganttComponentref.current?.expandByID(pkg.id);

            // scroll into view
            let row = ganttComponentref.current?.getRowByID(phase.id);
            row.scrollIntoView(false);
            
            let rowPosition = row.getBoundingClientRect().y;
            let scrollTop = ganttComponentref.current?.ganttChartModule.scrollElement.scrollTop;
            if(rowPosition > 500){
              ganttComponentref.current?.setScrollTop(scrollTop+300);
            }

            // find the row index and select the cell
            let flatDataItem = ganttComponentref.current?.flatData.find(e => e.id === phase.id);
            if (flatDataItem) {
              ganttComponentref.current?.selectionModule.selectCell({ cellIndex: 1, rowIndex: flatDataItem.index });
            }
          }
          catch {
            // package may be filtered
          }
        }
      }
    }
  }

  useEffect(() => {
    // setup initial filter after delay
    setIsFiltering(true);
    const timeout = setTimeout(() => {
      filterByDisciplines();
    }, 750)
  }, [])

  /**
  * Load completed
  * @param {*} args 
  */
  const loadComplete = (args) => {
    if (selectedAlertId) {
      expandSelectedAlertPackage();
    }

    if (selectedPhaseId) {
      expandSelectedPhasePackage();
    }

    ganttComponentref.current?.fitToProject();
    ganttComponentref.current?.windowResize();
  }

  /**
  * Taskbar (phase) clicked in chart
  * @param {object} args
  */
  const taskbarClick = (args) => {
    let phaseData = args.data.taskData;

    if (phaseData.hasOwnProperty("phaseId")) {
      // set the selected phase Id and open the properties pane
      dispatch(setInfoSelectedPhaseId(phaseData.phaseId));
      dispatch(setSelectedTab("PropertyViewer"));

      // select the package cell
      ganttComponentref.current?.selectionModule.selectCell({ cellIndex: 1, rowIndex: args.rowIndex });
    }
  }

  /**
   * Zoom in
   */
  const handleZoomInClick = () => {
    ganttComponentref.current?.zoomIn();
  }

  /**
   * Zoom out
   */
  const handleZoomOutClick = () => {
    ganttComponentref.current?.zoomOut();
  }

  /**
   * Fit to project
   */
  const handleFitToProject = () => {
    ganttComponentref.current?.fitToProject();
  }

  /**
   * Action begin
   * @param {object} args 
   */
  const actionBegin = (args) => {
    if (args?.requestType === "filtering") {
      setIsFiltering(true);
    }
    if (args?.requestType === "refresh") {
      filterByDisciplines();
      setIsFiltering(false);
    }
  }

  /**
   * Action complete
   * @param {object} args 
   */
  const actionComplete = (args) => {
    if (args?.requestType === "filtering") {
      setIsFiltering(false);
      if (isInitialising) {
        setIsInitialising(false);
      }
      loadComplete();
    }
  }

  /** Gantt component settings */
  const toolbar = ['ZoomIn', 'ZoomOut', 'ZoomToFit'];
  const splitterSettings = { columnIndex: 2 };
  const selectionSettings = { mode: 'Cell' }
  const tooltipSettings = { showTooltip: true, taskbar: TooltipTemplateTaskbar }
  const dayWorkingTime = [{ from: 0, to: 24 }]
  const searchSettings = { fields: ['disciplineId'], operator: 'contains' }
  const timelineSettings = { timelineViewMode: 'Year' }

  return (
    <>
      {(isFiltering || isInitialising) && <CircularProgressBar />}
      <div className={classNames({ 'design-production-viewer-chart': true, 'hidden': isFiltering })} style={{ "height": "100%" }}>
        <GanttComponent
          id='ganttDPV'
          ref={ganttComponentref}
          dataSource={taskData}
          taskFields={taskFields}
          toolbar={toolbar}
          splitterSettings={splitterSettings}
          viewType='ProjectView'
          rowHeight={50}
          taskbarHeight={32}
          dataBound={loadComplete}
          collapseAllParentTasks={true}
          height='100%'
          taskType='FixedDuration'
          parentTaskbarTemplate={ParentTaskbarTemplate}
          taskbarTemplate={TaskbarTemplate}
          milestoneTemplate={MilestoneTemplate}
          onTaskbarClick={taskbarClick}
          allowSelection={true}
          selectionSettings={selectionSettings}
          dateFormat={getGlobalFormat()}
          tooltipSettings={tooltipSettings}
          autoFocusTasks={true}
          enableContextMenu={false}
          allowRowDragAndDrop={false}
          includeWeekend={true}
          enablePredecessorValidation={false}
          dayWorkingTime={dayWorkingTime}
          treeColumnIndex={1}
          allowFiltering={true}
          searchSettings={searchSettings}
          timelineSettings={timelineSettings}
          connectorLineWidth={2}
          connectorLineBackground="#8D9DB4"
          actionBegin={actionBegin}
          actionComplete={actionComplete}
          filterSettings={{
            // columns: [
            //     { field: 'filterDiciplineId', 
            //     matchCase: false, 
            //     operator: 'equal', 
            //     value: ['589a6da2-b640-4907-8f76-a7a468d6e826'] }
            // ],
            ignoreAccent: true,
            hierarchyMode: 'Both'
          }}
        >
          <ColumnsDirective>
            <ColumnDirective field='alertCount' headerText=" " width='50' template={AlertColumnTemplate} allowFiltering={false}></ColumnDirective>
            <ColumnDirective field='name' headerText="Package" width='350' template={PackageColumnTemplate}></ColumnDirective>
            <ColumnDirective field='filterDiciplineId' headerText="Filter Discipline Id" visible={false}></ColumnDirective>
          </ColumnsDirective>
          <EventMarkersDirective>
            <EventMarkerDirective day={new Date()} cssClass='e-today-event-marker' ></EventMarkerDirective>
          </EventMarkersDirective>
          <Inject services={[Selection, Filter, DayMarkers]} />
        </GanttComponent>
        <div className="custom-toolbar">
          <div className="toolbar-section">
            <button onClick={handleZoomInClick} className="toolbar-button">
              <PlusIcon />
            </button>
            <hr />
            <button onClick={handleZoomOutClick} className="toolbar-button">
              <MinusIcon />
            </button>
          </div>
          <div className="toolbar-section">
            <button onClick={handleFitToProject} className="toolbar-button">
              <ExtentIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignProductionViewerChart;