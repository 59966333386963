import { 
    createSlice,
    createAsyncThunk,
  } from '@reduxjs/toolkit';
  import { api } from '../../api';
  import { fastestOptionEndpoint } from '../../api/apiRoutes';
  
  export const fetchfastestOption = createAsyncThunk(
    'scenarios/options/fastestoption', async (params, thunkAPI) => {
      try{
        const response = await api.get(fastestOptionEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      }
      catch (error){
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  
  /**
   * Create fastest option slice / reducers
   */
  const scenarioOptionFastestSlice = createSlice({
    name: 'scenarioOptionFastest',
    initialState: { 
        error: undefined,
        isLoading: false,
        fastestOption: {}
    },
    //reducers: {
    //},
    extraReducers: (builder) => {
      builder
        .addCase(fetchfastestOption.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(fetchfastestOption.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          state.fastestOption = action.payload;
        })
        .addCase(fetchfastestOption.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
    }
  });
  
  /**
   * Fastest option selectors
  */
  export const selectFastestOption = (state) => {
      return state.fastestOption;
  }
  
  /**
   * Export reducer
   */
  export default scenarioOptionFastestSlice.reducer;