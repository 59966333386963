import React from 'react';
import { SettingsIcon } from '../../../../../Icons'
import './SettingsButton.scss';

const SettingsButton = (props) => {

 /*Will open the settings modal*/

  const handleModalOpen =()=>{
   props.modalOpen();
  }

  return (
    <div className="settings-container" onClick={handleModalOpen}>
    <SettingsIcon width={20} height={20} />
   </div> 
  )
}

export default SettingsButton;