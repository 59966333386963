import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InterfaceIncompleteStateIcon, InterfaceNotificationIcon } from '../../../../../Icons';
import { setSelectedInterfacePointNumber } from '../../../../../redux/slices/optimisationFocusAreasSlice';
import './OptimisationFocusAreasInterfacePointSelectorItem.scss';

const OptimisationFocusAreasInterfacePointSelectorItem = (props) => {
  const { interfacePoint } = props;
  const { selectedInterfacePointNumber } = useSelector(state => state.optimisationFocusAreas);
  const [daysPendingForCompletion, setDaysPendingForCompletion] = useState(0);
  const { interfacePointTypes } = useSelector(state => state.interfacePoints);
  const { interfaceWarnings } = useSelector(state => state.interfacePoints);
  const [interfaceWarningType, setInterfaceWarningType] = useState(null);
  const interfacePointTitle = interfacePoint.interfacePointNumber + ' - ' + interfacePoint.name;
  const dispatch = useDispatch();

  /**
   * Get the number of days remaining for an incomplete interface point(due in the next week)
   */
   const getPendingDaysForCompletion = (selectedInterfacePoint) => {
    const millisecondsInDay = 86400000;
    let currentDate = new Date();
    if(selectedInterfacePoint.forecastDate == null || selectedInterfacePoint.forecastDate == undefined){
      let differenceInDays = new Date(selectedInterfacePoint.interfacePointDate).getTime() - new Date(currentDate).getTime();
      const daysDiff = Math.round(differenceInDays / millisecondsInDay);
      setDaysPendingForCompletion(daysDiff);
    }
    else{
      let differenceInDays = new Date(selectedInterfacePoint.forecastDate).getTime() - new Date(currentDate).getTime();
      const daysDiff = Math.round(differenceInDays / millisecondsInDay);
      setDaysPendingForCompletion(daysDiff);
    }
  }

  /**
   * Fetched activities linked to the selected interface point
   */
   useEffect(() => {
    if(interfacePoint && interfacePointTypes.length){
      let selectedInterfaceWarnings = interfaceWarnings.filter(a=>a.interfaceId == interfacePoint.interfaceId).slice();
      let linkType = "";
      if(selectedInterfaceWarnings.length > 0)
      {
        linkType = [...new Set(selectedInterfaceWarnings.map(item => item.linkType))][0];
        setInterfaceWarningType(linkType);
      }
      else{
        setInterfaceWarningType(null);
      }

      getPendingDaysForCompletion(interfacePoint);
    }
  }, [interfacePoint, interfacePointTypes, interfaceWarnings]);

  /**
  * Handles interface point selection change event
  * @param {*} InterfacePointNumber 
  */
  const handleInterfacePointSelectorChange = (interfacePointNumber) => {
    // gets and sets the interface point number
    dispatch(setSelectedInterfacePointNumber(interfacePointNumber));
  };

  return (
    <>
      <div onClick={() => handleInterfacePointSelectorChange(`${interfacePoint.interfacePointNumber}`)}
        className={`${interfacePoint.interfacePointNumber === selectedInterfacePointNumber ? "active ofaip-item-container" : "ofaip-item-container"}`}>
          <span>
            {interfacePoint.interfaceStatus.toLowerCase() ==='active' && daysPendingForCompletion >= 5 && interfaceWarningType == null && <InterfaceIncompleteStateIcon className="ip-active" />}
            {interfacePoint.interfaceStatus.toLowerCase() ==='active' && daysPendingForCompletion < 5 && daysPendingForCompletion > 0 && interfaceWarningType == null && <InterfaceNotificationIcon className="ip-secondary-notification" />}
            {interfacePoint.interfaceStatus.toLowerCase() ==='active' && daysPendingForCompletion <= 0 && interfaceWarningType == null && <InterfaceNotificationIcon className="ip-primary-notification" />}
            {interfacePoint.interfaceStatus.toLowerCase() ==='active' && interfaceWarningType && <InterfaceNotificationIcon className="ip-primary-notification" />}
          </span>
          <span className='ofaip-name'>{interfacePointTitle}</span>
      </div>
    </>
  );
}

export default OptimisationFocusAreasInterfacePointSelectorItem;