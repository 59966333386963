import { 
  createSlice,
  createAsyncThunk ,
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { scenarioStatusesEndpoint } from '../../api/apiRoutes';

/**
 * 
 * @returns Fetches scenario statuses
 */
export const fetchScenarioStatuses = createAsyncThunk(
  'project/fetchscenariostatuses', async (params, thunkAPI) => {
     try {
        const response = await api.get(scenarioStatusesEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Create scenario status slice / reducers
 */
const scenarioStatusSlice = createSlice({
  name: 'scenarioStatus',
  initialState: { statuses: undefined,
                  isLoading: false,
                  error: undefined
                },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScenarioStatuses.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
        state.statuses = undefined;
      })
      .addCase(fetchScenarioStatuses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.statuses = action.payload;
      })
      .addCase(fetchScenarioStatuses.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
    }
  });

/**
 * Export reducer
 */
export default scenarioStatusSlice.reducer;