import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { focusAreasInterfacePointsEndpoint, focusAreasInterfacePointDrivingPathEndpoint, totalCountDrivingPathEndpoint } from '../../api/apiRoutes';

/**
* Fetches focus areas interface points
*/
export const fetchFocusAreasInterfacePoints = createAsyncThunk(
  'project/focusareasinterfacepoints', async (params, thunkAPI) => {
    try {
      const response = await api.get(focusAreasInterfacePointsEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Fetches driving path for an interface point
 */
export const fetchFocusAreasInterfacePointDrivingPath = createAsyncThunk(
  'project/interfacepointdrivingpath', async (params, thunkAPI) => {
    try {
      const response = await api.get(focusAreasInterfacePointDrivingPathEndpoint(params.projectId, params.interfacePointNumber), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  });

  /**
* Fetches total count for interfaces and activities for driving path
*/
export const fetchTotalCountDrvingPath = createAsyncThunk(
  'project/totalcountdrvingpath', async (params, thunkAPI) => {
    try {
      const response = await api.get(totalCountDrivingPathEndpoint(params.projectId,params.interfacePointNumber), { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


/**
 * Optimisation focus areas adapter
 */
const optimisationFocusAreasAdapter = createEntityAdapter({
  selectId: (focusAreaNode) => focusAreaNode.focusAreaNodeId,
})

/**
 * Create optimisation focus areas slice / reducers
 */
const optimisationFocusAreasSlice = createSlice({
  name: 'optimisationFocusAreas',
  initialState: optimisationFocusAreasAdapter.getInitialState({
    error: undefined,
    isInterfacePointDrivingPathLoading: false,
    interfacePointdrivingPathError: undefined,
    selectedInterfacePointNumber: undefined,
    selectedFocusAreaNodeId: undefined,
    focusAreaNodeFilterIds: undefined,
    targetOptimisationDays: 1,
    focusAreasInterfacePoints: [],
    isFocusAreasInterfacePointsLoading: false,
    focusAreasInterfacePointsError: undefined,
    totalCount : undefined,
    selectedHoverFocusAreaNodeId : undefined
  }),
  reducers: {
    setSelectedInterfacePointNumber: (state, action) => {
      state.selectedInterfacePointNumber = action.payload;
    },
    setSelectedFocusAreaNodeId: (state, action) => {
      state.selectedFocusAreaNodeId = action.payload;
    },
    setFocusAreaNodeFilterIds: (state, action) => {
      state.focusAreaNodeFilterIds = action.payload;
    },
    setTargetOptimisationDays: (state, action) => {
      state.targetOptimisationDays = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setHoverFocusAreaNodeId: (state, action) => {
      state.selectedHoverFocusAreaNodeId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFocusAreasInterfacePointDrivingPath.pending, (state, action) => {
        state.isInterfacePointDrivingPathLoading = true;
        state.interfacePointdrivingPathError = undefined;
      })
      .addCase(fetchFocusAreasInterfacePointDrivingPath.fulfilled, (state, action) => {
        state.isInterfacePointDrivingPathLoading = false;
        state.interfacePointdrivingPathError = undefined;
        optimisationFocusAreasAdapter.setAll(state, action.payload);
      })
      .addCase(fetchFocusAreasInterfacePointDrivingPath.rejected, (state, action) => {
        state.isInterfacePointDrivingPathLoading = false;
        if (action.payload) {
          state.interfacePointdrivingPathError = action.payload.error;
        }
        else {
          state.interfacePointdrivingPathError = action.error.message;
        }
      })
      .addCase(fetchFocusAreasInterfacePoints.pending, (state, action) => {
        state.focusAreasInterfacePoints = [];
        state.isFocusAreasInterfacePointsLoading = true;
        state.focusAreasInterfacePointsError = undefined;
      })
      .addCase(fetchFocusAreasInterfacePoints.fulfilled, (state, action) => {
        state.focusAreasInterfacePoints = action.payload;
        state.isFocusAreasInterfacePointsLoading = false;
        state.focusAreasInterfacePointsError = undefined;
      })
      .addCase(fetchFocusAreasInterfacePoints.rejected, (state, action) => {
        state.isInterfacePointTypesLoading = false;
        if (action.payload) {
          state.focusAreasInterfacePointsError = action.payload.error;
        }
        else {
          state.focusAreasInterfacePointsError = action.error.message;
        }
      })

      .addCase(fetchTotalCountDrvingPath.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
        state.totalCount = undefined;
      })
      .addCase(fetchTotalCountDrvingPath.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.totalCount = action.payload;
      })
      .addCase(fetchTotalCountDrvingPath.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.totalCount = action.payload;
        }
        else {
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Optimisation focus areas selectors
 */
 export const {
  selectAll: selectAllDrivingPathNodes,
} = optimisationFocusAreasAdapter.getSelectors((state) => state.optimisationFocusAreas);

/**
 * Optimisation focus areas actions
 */
export const {
  setError,
  setSelectedInterfacePointNumber,
  setSelectedFocusAreaNodeId,
  setTargetOptimisationDays,
  setFocusAreaNodeFilterIds,
  setHoverFocusAreaNodeId,
} = optimisationFocusAreasSlice.actions;

/**
 * Export reducer
 */
export default optimisationFocusAreasSlice.reducer;