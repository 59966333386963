import React from 'react';
import { ExclamationTriangleIcon } from '../../../../Icons';
import './ErrorMessage.scss';

const ErrorMessage = (props) => {

  return (
    <div className="error-message-container">
       <ExclamationTriangleIcon className="error-icon" /><div className="error-message">{props.message}</div>
    </div>
  );
}

export default ErrorMessage;