import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { setCompletionStatusId, setMilestoneCompletionStatusId } from '../../../../../redux/slices/activityChangesSlice';
import { selectAllActivityCompletionStatus, selectAllActivityCompletionStatusMilestone } from '../../../../../redux/slices/activityCompletionStatusSlice';
import './AllActivityStateDropDownListContainer.scss';

const AllActivityStateDropDownListContainer = (props) =>{
    const { type } = props;
    const dispatch = useDispatch();
    const activityStateSelector = React.useRef();
    const fields = { text: 'completionStatus', value: 'id' };
    const activityCompletionStatus = useSelector(selectAllActivityCompletionStatus);
    const activityCompletionStatusMilestone = useSelector(selectAllActivityCompletionStatusMilestone);

    const [dropDownDataSource, setDropDownDataSource] = useState([]);
    const [activityCompletionStatusId, setActivityCompletionStatusId] = useState(null);

    useEffect(() =>{
        if(type == 'Task'){
            setDropDownDataSource(activityCompletionStatus);
            setActivityCompletionStatusId(1);
        }
        else if(type == 'Milestone' || type == 'Finish Milestone'){
            setDropDownDataSource(activityCompletionStatusMilestone);
            setActivityCompletionStatusId(2);
        }
    },[]);

      const onStateChange = (event) =>{
        var selectedCompletionStatusId = null;
        selectedCompletionStatusId = event.value;
        var obj = activityStateSelector.current;
        if(obj){
            if(type == 'Task')
                dispatch(setCompletionStatusId(selectedCompletionStatusId));
            else if(type == 'Milestone' || type == 'Finish Milestone')
                dispatch(setMilestoneCompletionStatusId(selectedCompletionStatusId));
        }
      }

      const stateTemplate = (data) => {
        return (
        <div className='state-item-dropdown'>
            {data.completionStatus}
        </div>
        )
    };
    
    const selectedStateTemplate = (data) => {
        let className = 'e-input-group state-item-selected';
        let dropdownIconClassName = 'dropdown-icon';
    
        return (
        <div className={className}>
            {data.completionStatus}
            <div className={dropdownIconClassName}><FontAwesomeIcon icon="edit"/></div>
        </div>
        )
    };

    return(
        <DropDownListComponent id="all-activity-state-selector"
            ref={activityStateSelector}
            cssClass="all-activity-state-selector"
            onChange={onStateChange}
            fields={fields}
            dataSource={dropDownDataSource}
            value={activityCompletionStatusId}
            valueTemplate={selectedStateTemplate}
            itemTemplate={stateTemplate}
            popupWidth="95px"
            delayUpdate={false}
            width="110px"
        />
    );
}

export default AllActivityStateDropDownListContainer;