import './LinearProgressBarContainer.scss';
import { useSelector } from "react-redux";
import LinearProgressBar from '../../../../../components/Shared/molecules/LinearProgressBar';

const LinearProgressBarContainer = (props) => {
  const { actualPercentageComplete,progressBarBgColour, progressBarColour  } = props;
  const { progressBarHeight } = useSelector(state => state.dpv);

  return (
    <LinearProgressBar percentage = {actualPercentageComplete} backgroundBarColour = {progressBarBgColour} actualBarColour = {progressBarColour} height = {progressBarHeight}/>
  );
};

export default LinearProgressBarContainer;