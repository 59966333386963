import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import '../../../ProjectProgressWidgets.scss';
import { fetchActivityFocusAreas } from '../../../../../redux/slices/activityFocusAreaSlice';

const ProjectActivityFocusAreasWidget = (props) => {
  const { project } = props;
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { error, isLoading } = useSelector(state => state.activityFocusArea);
  const { activityFocusAreas } = useSelector(state => state.activityFocusArea);

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  useEffect(() => {
    // fetch focus areas for project
    if (accessToken) {
      dispatch(fetchActivityFocusAreas({ projectId: selectedProjectId, accessToken: accessToken }));
    }
  }, [dispatch, selectedProjectId, accessToken]);



  if (project) {
    return (
      <>
        {isLoading &&
          <CircularProgressBar />
        }
        {error && !isLoading &&
          <ErrorMessage message="Can not load project focus areas" />
        }
        {!isLoading && !error && activityFocusAreas &&
          <>
            <div className="widget-container">
              <div className="widget-title">
                <h3>Suggested Focus Areas &nbsp;</h3>
                <span className="tooltip">
                  <InfoTooltip message={'A prediction of the near-term design activities on the actualised critical path you should focus on progressing today.'}  iconSize={20} width="none" />
                </span>
              </div>
              <div className="widget-content">
                {activityFocusAreas.length === 0 &&
                  <p>There are currently no suggested focus areas.</p>
                }
                {activityFocusAreas.length > 0 &&
                  <table>
                    <thead>
                    </thead>
                    <tbody>
                      {activityFocusAreas.map((activity, i) => {
                        return (
                          <tr key={i}>
                            <td>{activity.criticalActivity}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </>
        }
      </>
    );
  }
  else {
    return null;
  }
}

export default ProjectActivityFocusAreasWidget;