import { 
    createEntityAdapter, 
    createSlice,
    createAsyncThunk
  } from '@reduxjs/toolkit';
  import { api } from '../../api';
  import { activityScheduleMilestoneEndpoint } from '../../api/apiRoutes';
  
  export const fetchActivityScheduleMilestone = createAsyncThunk(
    'scenarioOption/fetchactivityschedulemilestone', async (params, thunkAPI) => {
      try{
        const response = await api.get(activityScheduleMilestoneEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      }
      catch (error){
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  
  /**
   * Create activity schedule milestone adapter
   */
   const activityScheduleMilestoneAdapter = createEntityAdapter({
    selectId: (activityScheduleMilestone) => activityScheduleMilestone.disciplineId,
  })
  
  /**
   * Create activity schedule milestone slice / reducers
   */
  const activityScheduleMilestoneSlice = createSlice({
    name: 'activityScheduleMilestone',
    initialState: activityScheduleMilestoneAdapter.getInitialState( {  isLoading: false,
                                                              error: undefined
                                                          } ),
    reducers: {
  
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchActivityScheduleMilestone.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(fetchActivityScheduleMilestone.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          activityScheduleMilestoneAdapter.setAll(state, action.payload.disciplines);
        })
        .addCase(fetchActivityScheduleMilestone.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
    }
  });
  
  /**
   *  Activity schedule Milestone selectors
  */
   export const {
    selectById: selectActivityScheduleMilestoneById,
  } = activityScheduleMilestoneAdapter.getSelectors((state) => state.activityScheduleMilestone);
  
  export const selectAllActivityScheduleMilestone = (state) => {
    const activityScheduleMilestone = state.activityScheduleMilestone;
    const allActivityScheduleMilestone = activityScheduleMilestoneAdapter.getSelectors((state) => activityScheduleMilestone).selectAll();
    return allActivityScheduleMilestone;
  }
  
  /**
   * Activity schedule milestone actions
   */
  export const {  
                  setAllActivityScheduleMilestone
               } = activityScheduleMilestoneSlice.actions;
  
  /**
   * Export reducer
   */
  export default activityScheduleMilestoneSlice.reducer;