import React, { useEffect, useState } from 'react';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import './RemoveUserWidget.scss';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from '../../../../../theme/Buttons';
import { CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';

const RemoveUserWidget = (props) => {
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);


  useEffect(() => {
    /** 
     * Acquire access token
     */
      if (accounts.length > 0) {
          const request = {
              scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
              account: accounts[0]
          };
          instance.acquireTokenSilent(request).then(response => {
              setAccessToken(response.accessToken);
          }).catch(error => {
              // acquireTokenSilent can fail for a number of reasons, fallback to interaction
              if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenPopup(request).then(response => {
                      setAccessToken(response.accessToken);
                  });
              }
          });
      }
  }, [accounts]);

  /**
   * Cancels the user Removal and closes dialog
   */
  const cancelUserRemoval = () => {
    props.closeWidget();
  }

  /**
   * Close the user Removal dialog
   */
  const closeUserRemoval = () => {
    props.closeWidget();
  }

  /**
   * Save the user Removal dialog
   */
  const removeUser = () => {
   // console.log('Save user Removal');
  }

  
  return (
    <>
      <div className="user-removal-container">
        <div className='popover-header'>
          <h6>Remove User</h6>
        </div>
        <div className='popover-content'>
          <p>
            Are you sure that you would like to remove this member?
          </p>
          <p>
            (They will no longer be able to view this project)
          </p>
        </div>
        <div className="popover-footer">
          <SecondaryLighthouseButton onClick={cancelUserRemoval}>No thanks</SecondaryLighthouseButton>
          <PrimaryLighthouseButton onClick={removeUser}>Remove User</PrimaryLighthouseButton>
        </div>
      </div>
    </>
  );
}

export default RemoveUserWidget;