import React, { useEffect, useState } from 'react';
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { fetchDisciplines  } from '../../../../../redux/slices/disciplinesSlice';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import PlannedResourceCapacityRequirementContainer from '../../molecules/PlannedResourceCapacityRequirementContainer';

const ResourceCapacityRequirementGraph = (props) => {
  const { refreshScenarios } = props;
  const dispatch = useDispatch();

  const { error, isLoading } = useSelector(state => state.scenarioStatus);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { instance, accounts } = useMsal();

  const [accessToken, setAccessToken] = useState(null);
  const [filterSelectionOptions, setFilterSelectionOptions] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);

  const disciplineFields = {text: "text", value: "value"};

    /**

   * Handles adding filter type

   * @param {object} e

   */
    const handleFilterAdd = (e) => {
        setSelectedFilterTypes([...selectedFilterTypes, {
            filterProperty: e.itemData.filterProperty,
            value: e.itemData.value
        }]);
    }

    /**
  
     * Handles removing filter type
  
     * @param {object} e
  
     */
    const handleFilterRemove = (e) => {
        let filterPropertyToRemove = e.itemData.filterProperty;
        let filterValueToRemove = e.itemData.value;

        const updatedFilterTypes = selectedFilterTypes.filter((filterType) => {
            return !(filterType.filterProperty === filterPropertyToRemove && filterType.value === filterValueToRemove);
        });

        setSelectedFilterTypes(updatedFilterTypes);
    }


    useEffect(() => {
        /**
         * Acquire access token
         */
        if (accounts.length > 0) {
            const request = {
                scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then(response => {
                setAccessToken(response.accessToken);
            }).catch(error => {
                // acquireTokenSilent can fail for a number of reasons, fallback to interaction
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenPopup(request).then(response => {
                        setAccessToken(response.accessToken);
                    });
                }
            });
        }
    }, [accounts]);

   /**
   * Fetches the disciplines for the selected project
   */
    useEffect(() => {
        let filterOptions = [];

        if (accessToken) {

            dispatch(fetchDisciplines({ projectId: selectedProjectId, accessToken: accessToken })).then(
                (response) => {
                    response.payload.forEach((d) => {
                        let item = {
                            text: d.disciplineName,
                            value: d.disciplineId,
                            category: 'Discipline',
                            filterProperty: 'disciplineId'
                        }
                        filterOptions.push(item)

                        setFilterSelectionOptions(filterOptions);
                        setIsReady(true);
                    });
                    handleRefreshScenarios()
                }
            );
        }
    }, [dispatch, selectedProjectId, accessToken, refreshScenarios]);
  
  /**
  * Refreshes the scenarios if flag is set
  */
  const handleRefreshScenarios=()=>{
    props.cleanRefreshScenarios();
  }
 
  return (
    <>
    {!isReady &&
        <CircularProgressBar />
    }
    {error && !isLoading &&
        <ErrorMessage message="Unable to display design scenario manager" />
    }
    {!isLoading && !error && isReady &&
    <>
        <div style={{ width: '250px'}}>
            <MultiSelectComponent id="mtselement" tagging={handleFilterAdd} removed={handleFilterRemove} filterFields={disciplineFields} dataSource={filterSelectionOptions} placeholder="Select Discipline" />
        </div>
        <PlannedResourceCapacityRequirementContainer selectedFilterTypes={selectedFilterTypes}/>
    </>
      }
    </>
  );
}

export default ResourceCapacityRequirementGraph;