import React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, LineSeries, DateTime, ScrollBar, Zoom } from '@syncfusion/ej2-react-charts';
import { useSelector } from "react-redux";
import CircularProgressBar from '../CircularProgressBar';
import { getGlobalFormat } from '../../../../../utils';
import { chartTooltipThemeSettings } from '../../../../../theme/Charts';
import './ResourceAllocationChart.scss';


const ResourceAllocationChart = (props) => {

    const data = props.resourceData;
    // const resourceFilter = props.resourceFilter;
    const disciplineItem = props.disciplineItem;
    const { isLoading, error } = useSelector(state => state.disciplines);
    const majorGridLines= { color: '#F6F6F6',width: 1}
    const primaryXAxis = { valueType: 'DateTime', intervalType: 'Days', rangePadding: 'Additional', labelFormat: getGlobalFormat(), labelRotation: -45, rangePadding: 'None', majorGridLines: majorGridLines };
    const primaryYAxis = { title: 'Number of Hours Worked', titleStyle: {size: '12px', color: '#005A84',  fontWeight: 'bold'}, majorGridLines: majorGridLines };
    // const plannedMarker =  { visible: true, height: 10, width: 10, shape: 'Triangle' };
    // const actualMarker =  { visible: true, height: 10, width: 10, shape: 'Diamond' };
    // const legendSettings = { visible: true, position: 'Right' };
    const tooltipSettings = { enable: true, 
                              format: 'W/C ${point.x}<br><li>${point.y} Hours (${series.name})',
                              ...chartTooltipThemeSettings
                            };

    //console.log(tooltipSettings)
    // const palette = ["#1D6AB1", "#8ED8F8", "#DA1B74", "#00ACA1"];


    return (
        <>
            <div className='chart-div'>
                { isLoading && 
                    <CircularProgressBar />
                }
                { !isLoading &&
                <>
                    <ChartComponent primaryXAxis={primaryXAxis} 
                                    primaryYAxis={primaryYAxis}
                                    tooltip={tooltipSettings}
                                    width='1236px'
                                    height='720px'
                                    zoomSettings={{
                                      enableMouseWheelZooming: true, enablePinchZooming: true,
                                      enableSelectionZooming: true, mode: 'X', enableScrollbar: true
                                    }}>
                        <Inject services={[LineSeries, Tooltip, DataLabel, DateTime, Zoom, ScrollBar]}/>
                        <SeriesCollectionDirective>
                            <SeriesDirective dataSource={data.seniorActual} xName='weekCommencing' yName='hours' width={2} name='Senior (Actual)' type='Line' fill='#00ACA1'>
                            </SeriesDirective>                           
                            <SeriesDirective dataSource={data.juniorActual} xName='weekCommencing' yName='hours' width={2} name='Junior (Actual)' type='Line' fill='#DA1B74'>
                            </SeriesDirective>                           
                            <SeriesDirective dataSource={data.intermediateActual} xName='weekCommencing' yName='hours' width={2} name='Intermediate (Actual)' type='Line' fill='#005A84'>
                            </SeriesDirective>                           
                            <SeriesDirective dataSource={data.leadActual} xName='weekCommencing' yName='hours' width={2} name='Lead (Actual)' type='Line' fill='#8ED8F8'>
                            </SeriesDirective>                           
                            <SeriesDirective dataSource={data.seniorPlanned} xName='weekCommencing' yName='hours' width={2} dashArray='5,5' name='Senior (Planned)' type='Line' fill='#00ACA1'>
                            </SeriesDirective>                           
                            <SeriesDirective dataSource={data.juniorPlanned} xName='weekCommencing' yName='hours' width={2} dashArray='5,5' name='Junior (Planned)' type='Line' fill='#DA1B74'>
                            </SeriesDirective>                           
                            <SeriesDirective dataSource={data.intermediatePlanned} xName='weekCommencing' yName='hours' width={2} dashArray='5,5' name='Intermediate (Planned)' type='Line' fill='#005A84'>
                            </SeriesDirective>                           
                            <SeriesDirective dataSource={data.leadPlanned} xName='weekCommencing' yName='hours' width={2} dashArray='5,5' name='Lead (Planned)' type='Line' fill='#8ED8F8'>
                            </SeriesDirective>
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </>
                }
            </div>           
    
        </>
      );
};

export default ResourceAllocationChart;