import { 
  createEntityAdapter, 
  createSlice,
  createAsyncThunk ,
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { activityCompletionStatusEndPoint } from '../../api/apiRoutes';

/**
 * 
 * @returns Fetches activity completion status
 */
export const fetchActivityCompletionStatus = createAsyncThunk(
  'scenario/fetchActivityCompletionStatus', async (params, thunkAPI) => {
    try{
      const response = await api.get(activityCompletionStatusEndPoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
      return response.data;
    }
    catch (error){
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Create activities completion status adapter
 */
 const activityCompletionStatusAdapter = createEntityAdapter({
  selectId: (activityCompletionStatus) => activityCompletionStatus.id,
})

/**
 * Create activities completion status slice / reducers
 */
const activityCompletionStatusSlice = createSlice({
  name: 'activityCompletionStatus',
  initialState: activityCompletionStatusAdapter.getInitialState( { selectedId: undefined,
                                                      isLoading: false,
                                                      error: undefined
                                                   } ),
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivityCompletionStatus.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchActivityCompletionStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        activityCompletionStatusAdapter.setAll(state, action.payload);
      })
      .addCase(fetchActivityCompletionStatus.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Activities completion status selectors
*/
 export const {
  selectAll: selectAllActivityCompletionStatus,
  selectById: selectStateById,
} = activityCompletionStatusAdapter.getSelectors((state) => state.activityCompletionStatus);

export const selectAllActivityCompletionStatusMilestone = (state) =>{
  const allCompletionStatus = state.activityCompletionStatus;
  const allCompletionStatusMilestone = activityCompletionStatusAdapter.getSelectors((state) => allCompletionStatus).selectAll().filter(a=>a.completionStatus != 'Started');
  return allCompletionStatusMilestone;
}

/**
 * Export reducer
 */
export default activityCompletionStatusSlice.reducer;