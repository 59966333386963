import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { setScenarioConstraintsEndpoint, defaultConstraintsEndpoint, resourceTypesEndpoint } from '../../api/apiRoutes';

/**
 * Sets scenario constraints
 */
export const setScenarioConstraints = createAsyncThunk(
  'scenario/setscenarioconstraints', async (params, thunkAPI) => {
    try {
      const response = await api.put(setScenarioConstraintsEndpoint(params.projectId, params.scenarioId), params.constraints, { headers: { Authorization: `Bearer ${params.accessToken}` } });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Fetches default constraints
 * @returns default constraints
 */
 export const fetchDefaultConstraints = createAsyncThunk(
  'project/fetchdefaultconstraints', async (params, thunkAPI) => {
     try {
        const response = await api.get(defaultConstraintsEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Fetches resource types
 * @returns resource types
 */
export const fetchResourceTypes = createAsyncThunk(
  'project/fetchresourcetypes', async (params, thunkAPI) => {
     try {
        const response = await api.get(resourceTypesEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
        return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue({ error: error.message });
      }
});

/**
 * Scenario constraints slice / reducers
 */
const scenarioConstraintsSlice = createSlice({
  name: 'scenarioConstraints',
  initialState: { isLoading: false, 
                  error: undefined, 
                  scenarioId: undefined, 
                  editedConstraints: undefined, 
                  defaultConstraints: undefined,
                  resourceTypes: ["Junior", "Intermediate", "Senior", "Lead", "Blended"] 
                },
  reducers: {
    setScenarioId: (state, action) => {
      state.scenarioId = action.payload;
    },
    setEditedConstraints: (state, action) => {
      state.editedConstraints = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourceTypes.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
        state.resourceTypes = undefined;
      })
      .addCase(fetchResourceTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.resourceTypes = action.payload;
      })
      .addCase(fetchResourceTypes.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(fetchDefaultConstraints.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
        state.defaultConstraints = undefined;
      })
      .addCase(fetchDefaultConstraints.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.defaultConstraints = action.payload;
      })
      .addCase(fetchDefaultConstraints.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload){
          state.error = action.payload.error;
        }
        else{
          state.error = action.error.message;
        }
      })
      .addCase(setScenarioConstraints.pending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(setScenarioConstraints.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(setScenarioConstraints.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = action.payload.error;
        }
        else {
          state.error = action.error.message;
        }
      })
  }
});

/**
 * Scenario constraints actions
 */
export const { 
  startLoading,
  hasError,
  setScenarioId,
  setEditedConstraints,
} = scenarioConstraintsSlice.actions;

/**
 * Export reducer
 */
export default scenarioConstraintsSlice.reducer;