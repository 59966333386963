import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { PrimaryLighthouseButton, SecondaryLighthouseButton } from "../../../../theme/Buttons";
import './RouterPrompt.scss';

const RouterPrompt = (props) => {
  const { when, onOK, onCancel, title, okText, cancelText, messageText } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => { });
    }

    return () => {
      history.block(() => { });
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => { });
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => { });
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  const header = () => {
    return <>
      <div className="router-prompt-header-wrapper">
        <div className="router-prompt-title">
          {title}
        </div>
      </div>
    </>
  }

  const content = () => {
    return <>
      <div className={'router-prompt-content'}>
        {messageText}
      </div>
    </>
  }

  const footerTemplate = () => {
    return <>
      <div className={'router-prompt-actions'}>
        <PrimaryLighthouseButton onClick={handleOK}>{okText}</PrimaryLighthouseButton>
        <SecondaryLighthouseButton onClick={handleCancel}>{cancelText}</SecondaryLighthouseButton>
      </div>
    </>
  }

  return showPrompt ? (
    <DialogComponent
      className="router-prompt-container"
      width={450}
      height={200}
      zIndex={9999}
      isModal={true}
      visible={showPrompt}
      title={title}
      content={content}
      footerTemplate={footerTemplate}
      header={header}
    >
    </DialogComponent>
  ) : null;
}

export default RouterPrompt;