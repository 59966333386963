import React from 'react';
import './Deliverable.scss';

const Deliverable = (props) => {
  const { deliverable } = props;

  return (
    <>
    <div className="delv-content-container">
      <div className="delv-prop-container">
        <div className="delv-title">Properties:</div>
        <table className="delv-prop-table">
          <tbody>
            <tr>
              <th>Deliverable ID:</th>
              <td>{deliverable.aggregateDocCode}</td>
            </tr>
            <tr>
              <th>Description:</th>
              <td>{deliverable.description}</td>
            </tr>
            <tr>
              <th>Expected Code:</th>
              <td>{deliverable.expectedCode}</td>
            </tr>
          </tbody>
        </table>
        <div className="delv-title">Deliverable % Complete within Phase:</div>
        <table className="delv-prop-table">
          <tbody>
          <tr>
              <th style={{ width: "10px" }}>Planned:</th>
              <td>{Math.round(deliverable.plannedPercentageComplete * 100)}%</td>
              <th style={{ width: "10px" }}>Estimated:</th>
              <td style={{ color: "#00AC6E" }}>{Math.round(deliverable.actualPercentageComplete * 100)}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </>
  )
};

export default Deliverable;