import { 
    createSlice,
    createAsyncThunk 
  } from '@reduxjs/toolkit';
  import { api } from '../../api';
  import { projectSourceDetailsEndpoint, dateFormatsEndpoint, projectRegionalPreferenceUpdateEndpoint } from '../../api/apiRoutes';
  
  /**
   * Fetches project source details
   * @returns project sources
   */
   export const fetchProjectSourceDetails = createAsyncThunk(
    'projectSettings/fetchprojectsourcedetails', async (params, thunkAPI) => {
       try {
          const response = await api.get(projectSourceDetailsEndpoint(params.projectId), { headers: { Authorization: `Bearer ${params.accessToken}` }});
          return response.data;
        } catch (error) {
           return thunkAPI.rejectWithValue({ error: error.message });
        }
  });

    /**
   * Fetches date formats
   * @returns date formats
   */
     export const fetchDateFormats = createAsyncThunk(
      'projectSettings/fetchdateformats', async (params, thunkAPI) => {
         try {
            const response = await api.get(dateFormatsEndpoint(), { headers: { Authorization: `Bearer ${params.accessToken}` }});
            return response.data;
          } catch (error) {
             return thunkAPI.rejectWithValue({ error: error.message });
          }
    });

    /**
   * Updates Project Preferences
   */
      export const updateProjectPrefernces = createAsyncThunk(
           'projectSettings/updateprojectprefernces', async (params, thunkAPI) => {
             try {
                const response = await api.put(projectRegionalPreferenceUpdateEndpoint(params.projectId),params.preferences, { headers: { Authorization: `Bearer ${params.accessToken}` }});
                return response.data;
              } catch (error) {
                 return thunkAPI.rejectWithValue({ error: error.message });
              }
      });
  
  /**
   * Create Project Settings slice / reducers
   */
  const projectSettingsSlice = createSlice({
    name: 'projectSettings',
    initialState: { 
                    projectSources : undefined,
                    dateFormats : undefined,
                    error: undefined,
                    isLoading: false, }
    ,
    extraReducers: (builder) => {
      builder
        .addCase(fetchProjectSourceDetails.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(fetchProjectSourceDetails.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          state.projectSources = action.payload;
        })
        .addCase(fetchProjectSourceDetails.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
        .addCase(fetchDateFormats.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(fetchDateFormats.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
          state.dateFormats = action.payload;
        })
        .addCase(fetchDateFormats.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
        .addCase(updateProjectPrefernces.pending, (state, action) => {
          state.isLoading = true;
          state.error = undefined;
        })
        .addCase(updateProjectPrefernces.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = undefined;
        })
        .addCase(updateProjectPrefernces.rejected, (state, action) => {
          state.isLoading = false;
          if (action.payload){
            state.error = action.payload.error;
          }
          else{
            state.error = action.error.message;
          }
        })
    }
  });
  
  
  /**
   * Export reducer
   */
  export default projectSettingsSlice.reducer;