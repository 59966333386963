import React, { useEffect, useState } from 'react';
import { DeleteIcon, EditIcon } from '../../../../../Icons';
import { getFormattedDate } from '../../../../../utils';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import './SelectedActivityComponent.scss'

const SelectedActivityComponent = (props) =>{
    const {activity, addForecastDate} = props;
    const [iconClass, setIconClass] = useState('enabled-action');

    useEffect(() => {
      (activity.isLighthouseActivity == 0 || activity.isLighthouseActivity == 1 || activity.isFullScheduleLink == 1) &&
      setIconClass('disabled-action');
    }, [activity]);
  
    /**
   * Deletes the selected activity from the editor
   */
    const deleteActivity = (event) =>{
      if(!addForecastDate){
        let activityId = event.currentTarget.parentElement.parentElement.id;
        props.deleteActivity(activityId);
      }
    }
  
    /**
   * Edits the selected activity from the editor
   */
    const editActivity = (event) =>{
      if(!addForecastDate){
        let activityId = event.currentTarget.parentElement.parentElement.id;
        props.editActivity(activityId);
      }
    }
  
    return (
      <div className='selected-activity' key={activity.activityId} id={activity.activityId}>
        <div className='selected-activity-table'>
          <table>
            <thead>
              <tr>
                <th className='activity-code'>Activity Code:</th>
                <th className='activity-name'>Activity Name:</th>
                <th className='planned-start'>Planned Start:</th>
                <th className='planned-end'>Planned End:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{activity.activityCode}</td>
                <td>
                  <TooltipComponent title={activity.activityName} className="tooltip-container">
                    {activity.activityName?.length > 50 && activity.activityName?.substring(0, 49) + "..."}
                    {activity.activityName?.length <= 50 && activity.activityName}
                  </TooltipComponent>
                </td>
                <td>{getFormattedDate(activity.plannedStartDate)}</td>
                <td>{getFormattedDate(activity.plannedEndDate)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='delete-activity-container'><DeleteIcon className={iconClass} onClick={deleteActivity}/></div>
        <div className='edit-activity-container'><EditIcon className={iconClass} onClick={editActivity}/></div>
      </div>
    );
}

export default SelectedActivityComponent;