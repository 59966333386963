import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { LighthouseTooltip } from '../../../../utils';
import Zoom from '@material-ui/core/Zoom';
import { ReactComponent as TooltipIcon } from '../../../../Icons/tooltip-icon.svg';
import './InfoTooltip.scss';

const InfoTooltip = (props) => {
  const { message, alignment, iconSize, width, onClick } = props;

  return (
    <div className="info-tooltip">
      <LighthouseTooltip content={message} width={width} placement={alignment} >
          <TooltipIcon height={iconSize} width={iconSize} onClick={onClick}/>
      </LighthouseTooltip>
    </div>
  );
}

export default InfoTooltip;