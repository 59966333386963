import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import CostImpact from '../../../../../components/Shared/atoms/CostImpact';
import TimeImpact from '../../../../../components/Shared/atoms/TimeImpact';
import RiskCategory from '../../../../../components/Shared/atoms/RiskCategory/RiskCategory';
import { fetchfastestOption } from '../../../../../redux/slices/scenarioOptionFastestSlice';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { DENOMINATION as denomination } from '../../../../../constants'
import  { getRiskCategoryColour, getCostColourInverse, getTimeColourInverse, getCostScale } from '../../../../../utils';
import '../../../ProjectProgressWidgets.scss';


const ProjectOptionMitigationFastestWidget = (props) => {
    const { project } = props;
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const { selectedProjectId } = useSelector(state => state.projects);
    const { error, isLoading } = useSelector(state => state.scenarioOptionFastest);
    const { fastestOption } = useSelector(state => state.scenarioOptionFastest);

    useEffect(() => {
      /**
       * Acquire access token
       */
      if (accounts.length > 0) {
        const request = {
          scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
          account: accounts[0]
        };
        instance.acquireTokenSilent(request).then(response => {
          setAccessToken(response.accessToken);
        }).catch(error => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(request).then(response => {
              setAccessToken(response.accessToken);
            });
          }
        });
      }
    }, [accounts]);

    useEffect(() => {
      // fetch fastest option
      if (accessToken) {
        dispatch(fetchfastestOption({ projectId: selectedProjectId, accessToken: accessToken }));
      }
    }, [dispatch, selectedProjectId, accessToken]);

if (project) {
    return (
      <>
        {isLoading &&
          <CircularProgressBar />
        }
        {error && !isLoading &&
          <ErrorMessage message="Can not load project impact metrics on the best-performing option." />
        }
        {!isLoading && !error &&
          <>
            <div className="widget-container">
              <div className="widget-title">
                <h3>Forecast Impact Reduction Opportunities &nbsp;</h3>
                <span className="tooltip">
                  <InfoTooltip message={'A prediction of the time and cost impact that could be mitigated by you today by improving the design schedule and/or resourcing plan.'}  iconSize={20} width="none"/>
                </span>
              </div>
              <div className="widget-content">
                <table>
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Design Cost</td>
                      <td width={160}>
                        <article
                          style={{ backgroundColor: getCostColourInverse(fastestOption.costImpactDesignTotalSaving) }}>
                          <CostImpact costImpact={fastestOption.costImpactDesignTotalSaving} denomination={denomination.auto} />
                        </article>
                      </td>
                    </tr>
                    <tr>
                      <td>Construction Cost</td>
                      <td>
                        <article
                          style={{ backgroundColor: getCostColourInverse(fastestOption.costImpactConstructionTotalSaving) }}>
                          <CostImpact costImpact={fastestOption.costImpactConstructionTotalSaving} denomination={denomination.auto} />
                        </article>
                      </td>
                    </tr>
                    <tr>
                      <td>3rd Party Cost</td>
                      <td>
                        <article
                          style={{ backgroundColor: getCostColourInverse(fastestOption.costImpactClientTotalSaving) }}>
                          <CostImpact costImpact={fastestOption.costImpactClientTotalSaving} denomination={denomination.auto} />
                        </article>
                      </td>
                    </tr>
                    <tr>
                      <td>Time Delay</td>
                      <td>
                      <article
                          style={{ backgroundColor: getTimeColourInverse(fastestOption.optionDelayDaysSaving) }}>
                            <TimeImpact timeImpactDelayDays={fastestOption.optionDelayDaysSaving} />
                        </article>
                      </td>
                    </tr>
                    <tr>
                      <td>Risk Category</td>
                      <td>
                          <article
                            style={{ backgroundColor: getRiskCategoryColour(fastestOption.riskCategory) }}>
                                  <RiskCategory riskCategory={fastestOption.riskCategory} /> 
                          </article>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        }
      </>
    );
  }
  else {
    return null;
  }
}

export default ProjectOptionMitigationFastestWidget;