import React from 'react';
import { useSelector } from "react-redux";
import getSymbolFromCurrency from 'currency-symbol-map';
import { formatCost, getFormattedDate } from '../../../../../utils';
import CostImpactNew from '../../../../../components/Shared/atoms/CostImpactNew';
import ImpactRating from '../../../../../components/Shared/atoms/ImpactRating';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import { WarningTriangleIcon, WarningCircleIcon } from '../../../../../Icons';
import { DENOMINATION } from '../../../../../../src/constants';
import { selectPhaseById } from '../../../../../redux/slices/phasesSlice';
import './AlertProperties.scss';

const AlertProperties = (props) => {
   const { currencyCode, alert, currentAlertId } = props;
   const selectedPhase = useSelector((state) => selectPhaseById(state, alert?.phaseId));
   const orchestratorRunRequired = selectedPhase?.orchestratorRunRequired;

 function createDescriptionMarkup() {
        return { __html: alert.description };
 }

 const ConstructionTooltipMessage = () => {
    return(
      <div>
          Construction cost impact shown is an estimated proportion of the total forecast construction cost linked to this alert. Lighthouse allocates an estimated <b> 
          { getSymbolFromCurrency(currencyCode) +  formatCost(Math.round(Math.abs(alert.costImpactConstruction)/10)*10, 0, DENOMINATION.auto) } </b> impact against total 
          construction cost impact of <b>{ getSymbolFromCurrency(currencyCode) + formatCost(Math.round(Math.abs(alert.fullCostImpactConstruction)/10)*10, 0, DENOMINATION.auto) } 
          </b> for this alert.'
      </div>
    )
  }
  const OtherTooltipMessage = () => {
    return(
      <div>
          Other cost impacts include 3rd Party Approval and Operational Revenue loss. Lighthouse estimates a potential total 3rd Party Approval impact = <b> 
          { getSymbolFromCurrency(currencyCode) + formatCost(Math.round(Math.abs(alert.fullCostImpactClient)/10)*10, 0, DENOMINATION.auto) } </b> and 
          Operational Revenue Loss = <b> { getSymbolFromCurrency(currencyCode) + formatCost(Math.round(Math.abs(alert.fullRevenueLoss)/10)*10, 0, DENOMINATION.auto) }</b>. 
          The impact shown is a proportion of this total allocated to the alert as a magnitude of risk.
      </div>
    )
  }
  const TimeTooltipMessage = () => {
    return(
      <div>
          The time impact shown is the estimated proportion of the total time impact linked to this alert. Lighthouse allocates an estimated <b> 
          { Math.ceil(alert.ifcCompletionDelayDays) } day(s)</b> delay against the total time impact of <b> {Math.ceil(alert.fullIfcCompletionDelayDays) } day(s)</b> for this alert
      </div>
    )
  }

  return (
    <>
    {currentAlertId && alert &&
      <>
      
      {orchestratorRunRequired === true && alert.alertStatus === 'Active' &&
                <div className="scheduled-analysis-text">
                    <div className="toast-container-scheduled-analysis">
                      <div className="toast-header-scheduled-analysis">
                        <div className="toast-icon-label-container-scheduled-analysis">
                          <span className="span-warning-scheduled-analysis"></span>
                          <span className="toast-warning-icon-scheduled-analysis">
                            <WarningCircleIcon />
                          </span>
                          <p>
                          A new % complete has been calculated for this work package.
                            Updates to this alert are now being processed, please check back tomorrow to see the changes.
                          </p>
                        </div>
                      </div>
                    </div>
                </div>
              }

              {alert.isAlertDismissalFlag === true && alert.alertStatus.toLowerCase() === "dismissed" &&
                <div className="scheduled-analysis-text">
                    <div className="toast-container-scheduled-analysis">
                      <div className="toast-header-scheduled-analysis">
                        <div className="toast-icon-label-container-scheduled-analysis">
                          <span className="span-warning-scheduled-analysis"></span>
                          <span className="toast-warning-icon-scheduled-analysis">
                            <WarningCircleIcon />
                          </span>
                          <p >Dismissing this alert will change the inputs to the forecast impact model. Changes will be reflected in the outputs of the next scheduled analysis run.</p>
                        </div>
                      </div>
                    </div>
                </div>
              }
          {alert.alertStatus.toLowerCase() === "dismissed" &&
            <div>
              <div className="status-description">
              <div className="toast-container-status-description">
                      <div className="toast-header-status-description">
                        <div className="toast-icon-label-container-status-description">
                          <span className="span-warning"></span>
                          <span className="toast-warning-icon">
                             <WarningCircleIcon />
                          </span>
                          <p>This alert was dismissed {alert.resolvedOn && <span> on {getFormattedDate(alert.resolvedOn, true)}</span>} and will be re-activated if still valid in 7 days.</p>
                        </div>
                      </div>
                    </div>
              </div>
              {/* <div className="section-title">Comment:</div>
              <div className="reason-text">{alert.dismissalReason}</div> */}
            </div>
          }
      <div className="alert-properties">
       <div className="al-prop-section">
            <div className="al-prop-title-main">Event:</div>
              <div className="al-prop-container">
                <div className="alert-description-event">
                  <div dangerouslySetInnerHTML={createDescriptionMarkup()}></div>
                </div>
            </div>
       </div>
       </div>
       <div className="alert-properties">
        <div className="al-prop-section">
          <div className="al-prop-title-main">Alert Data:</div>
          <div className="al-prop-container">
            <table className="al-prop-table">
              <tbody>
                <tr>
                  <th>Alert Name:</th>
                  <td>{alert.alertName}</td>
                </tr>
                <tr>
                  <th>Alert ID:</th>
                  <td>{alert.alertNumber}</td>
                </tr>
                <tr>
                  <th>Alert Issue:</th>
                  <td>{alert.alertDriver}</td>
                </tr>
                <tr>
                  <th>Alert Type:</th>
                  <td>{alert.alertType}</td>
                </tr>
                <tr>
                  <th>Alert Created:</th>
                  <td>{alert.raisedOn !== '0001-01-01T00:00:00' && getFormattedDate(alert.raisedOn,true)}</td>
                </tr>
                <tr>
                  <th>Alert Owner:</th>
                  <td>{alert.alertOwner}</td>
                </tr>
                <tr>
                  <th>Alert Work Package:</th>
                  <td>{alert.packageName}</td>
                </tr>
                <tr>
                  <th>Alert Work Package Code:</th>
                  <td>{alert.packageCode}</td>
                </tr>
                <tr>
                  <th>Alert Phase:</th>
                  <td>{alert.phaseName}</td>
                </tr>
                <tr>
                  <th>Alert Status:</th>
                  <td>{alert.alertStatus}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {alert.alertType.toLowerCase() === 'primary' &&
           <>
              <div className="al-prop-section">
                <div className="al-prop-title">
                  <div className='al-prop-title-icon'>
                    {getSymbolFromCurrency(currencyCode)}
                    </div>
                  &nbsp;&nbsp;Estimated Design Cost</div>
                <div className="al-des-container">
                <table className="al-des-table">
                  <tbody>
                        <tr className='total-cost'>
                          <th>Total Design Cost</th>
                          <td>
                            <span className='total-cost-value'>
                              <CostImpactNew costImpact={Math.round(alert.costImpactDesign/100)*100} scale={1} denomination={DENOMINATION.auto} width='110px' />
                            </span>
                          </td>
                        </tr>
                        <tr className='design-sub-cost'>
                          <th>Direct work to complete</th>
                          <td>
                              <CostImpactNew costImpact={Math.round(alert.costImpactDesignDirect/100)*100} scale={1} denomination={DENOMINATION.auto} width='110px' />
                          </td>
                        </tr>
                        <tr  className='design-sub-cost'>
                          <th>Direct downstream re-work</th>
                          <td>
                              <CostImpactNew costImpact={Math.round(alert.costImpactDesignDirectInter/100)*100} scale={1} denomination={DENOMINATION.auto} width='110px' />
                          </td>
                        </tr>
                      
                  </tbody>
                </table>
              </div>
               <div className="al-prop-title">
                 <div className='al-prop-title-icon'>
                 <WarningTriangleIcon className='other-impact-icon'/>
                 </div>
                 &nbsp;&nbsp;Other Potential Impacts</div>
                <div className="al-other-impact-container">
                <table className="al-other-impact-table">
                  <tbody>
                    <tr className='other-impact-name'>
                      <th>Construction
                          <InfoTooltip message={ConstructionTooltipMessage}  iconSize={12} width={500}/>
                      </th>
                      <td>
                          <ImpactRating value={alert.costImpactConstruction} total={alert.fullCostImpactConstruction} scale={2} outOf={10} width='110px' />
                      </td>
                    </tr>
                    <tr className='other-impact-name'>
                      <th>Other
                          <InfoTooltip message={OtherTooltipMessage}  iconSize={12} width={500}/>
                      </th>
                      <td>
                          <ImpactRating value={alert.revenueLoss+alert.costImpactClient} total={alert.fullRevenueLoss+alert.fullCostImpactClient} scale={2} outOf={10} width='110px' />
                      </td>
                    </tr>
                    <tr className='other-impact-name'>
                      <th>Time Impact
                          <InfoTooltip message={TimeTooltipMessage}  iconSize={12} width={500}/>
                      </th>
                      <td>
                          <ImpactRating value={Math.ceil(alert.ifcCompletionDelayDays)} total={Math.ceil(alert.fullIfcCompletionDelayDays)} scale={2} outOf={10} width='110px'/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
           </>
          }
     </div>
    </>
    }
    </>
   
  )
}

export default AlertProperties;