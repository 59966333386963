import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressBar from '../../../../../components/Shared/molecules/CircularProgressBar';
import { fetchAlerts, selectAllAlerts } from '../../../../../redux/slices/alertsSlice';
import ErrorMessage from '../../../../../components/Shared/molecules/ErrorMessage';
import { ReactComponent as AlertIcon } from '../../../../../Icons/alert-icon.svg';
import InfoTooltip from '../../../../../components/Shared/molecules/InfoTooltip';
import '../../../ProjectProgressWidgets.scss';

const ProjectAlertsWidget = (props) => {
  const { project } = props;
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const { selectedProjectId } = useSelector(state => state.projects);
  const { error, isLoading } = useSelector(state => state.alerts);
  const alerts = useSelector(selectAllAlerts);
  const [alertCountByDiscipline, setAlertCountByDiscipline] = useState([]);

  const groupBy = (arr, criteria) => {
    return arr.reduce(function (obj, item) {

      // Check if the criteria is a function to run on the item or a property of it
      let key = typeof criteria === 'function' ? criteria(item) : item[criteria];

      // If the key doesn't exist yet, create it
      if (!Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = [];
      }

      // Push the value to the object
      obj[key].push(item);

      // Return the object to the next item in the loop
      return obj;

    }, {});
  }

  useEffect(() => {
    /**
     * Acquire access token
     */
    if (accounts.length > 0) {
      const request = {
        scopes: [`api://${process.env.REACT_APP_LIGHTHOUSE_CLIENT_ID}/Pharos.Read`],
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then(response => {
        setAccessToken(response.accessToken);
      }).catch(error => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then(response => {
            setAccessToken(response.accessToken);
          });
        }
      });
    }
  }, [accounts]);

  useEffect(() => {
    // fetch disciplines
    if (accessToken) {
      dispatch(fetchAlerts({ projectId: selectedProjectId, accessToken: accessToken }));
    }
  }, [dispatch, selectedProjectId, accessToken]);

  useEffect(() => {
    // Group the alerts by alert ownder (discipline)
    if (alerts.length > 0) {
      let groupedAlerts = groupBy(alerts, 'alertOwner');
      let alertsByDiscipline = Object.keys(groupedAlerts).map((discipline, i) => {
        return {
          discipline: discipline, alertCount: Object.values(groupedAlerts)[i].length,
          primary: Object.values(groupedAlerts)[i].filter(x => x.alertType === 'Primary' && x.alertStatus === 'Active').length,
          secondary: Object.values(groupedAlerts)[i].filter(x => x.alertType === 'Secondary' && x.alertStatus === 'Active').length
        };
      })

      let sortedAlertsByDiscipline = alertsByDiscipline.slice().sort((a, b) => a.alertCount < b.alertCount ? 1 : -1);

      setAlertCountByDiscipline(sortedAlertsByDiscipline);
    }
    else {
      setAlertCountByDiscipline([]);
    }

  }, [alerts]);

  if (project) {
    return (
      <>
        {isLoading &&
          <CircularProgressBar />
        }
        {error && !isLoading &&
          <ErrorMessage message="Unable to get alert list" />
        }
        {!isLoading && !error &&
          <>
            <div className="widget-container">
              <div className="widget-title">
                <h3>Discipline Alerts Breakdown</h3>
                <span className="tooltip ml-10">
                  <InfoTooltip message={'A prediction of your design disciplines that may be contributing to delays or cost increases.'}  iconSize={20} width="none" />
                </span>
              </div>
              <div className="widget-summary">
                <div className="widget-content">
                  {alertCountByDiscipline.length === 0 &&
                    <p>There are currently no project alerts.</p>
                  }
                  {alertCountByDiscipline.length > 0 &&
                    <table>
                      <thead>
                        <tr>
                          <th>Discipline Name</th>
                          <th>
                            <div className="heading-group">
                              <span className="alert-icon-critical">
                                <AlertIcon className="alert-icon" />
                              </span>
                              Primary
                            </div>
                          </th>
                          <th>
                            <div className="heading-group">
                              <span className="alert-icon-others">
                                <AlertIcon className="alert-icon" />
                              </span>
                              Secondary
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {alertCountByDiscipline.map((discipline, i) => {
                          return (
                            <tr key={i}>
                              <td>{discipline.discipline}</td>
                              <td style={{ textAlign: 'center' }}>{discipline.primary}</td>
                              <td style={{ textAlign: 'center' }}>{discipline.secondary}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  }

                </div>
              </div>
            </div>
          </>
        }
      </>
    );
  }
  else {
    return null;
  }
}

export default ProjectAlertsWidget;