import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import FilterToolbarInterface from '../../../../../components/Shared/molecules/FilterToolbarInterface';
import { selectAllInterfacePoints, setSelectedInterfacePointId } from '../../../../../redux/slices/interfacePointsSlice';
import InterfacePointSelectorItem from '../../molecules/InterfacePointSelectorItem';
import './InterfacePointsSelector.scss';

const InterfacePointsSelector = (props) => {
  const { isEditable } = props;
  const { selectedInterfacePointId } = useSelector(state => state.interfacePoints);
  const interfacePoints = useSelector((state) => selectAllInterfacePoints(state));
  const { interfacePointTypes } = useSelector(state => state.interfacePoints);
  const { isInterfacePointsLoading, isInterfacePointTypesLoading } = useSelector(state => state.interfacePoints);
  const [ mandatoryInterfacePointTypes, setMandatoryInterfacePointTypes] = useState([]);
  const [ disableAddInterfacePoint, setDisableAddInterfacePoint] = useState(false);

  const categoryFields = { text: "text", value: "value", groupBy: "category"};
  const [filterSelectionOptions, setFilterSelectionOptions] = useState([]);
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [processedData, setProcessedData] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [ processInterfaceTypes, setProcessInterfaceTypes] = useState(false);
  const dispatch = useDispatch();

  useEffect(() =>{
    // set the selected interface point as the selected one
    dispatch(setSelectedInterfacePointId(selectedInterfacePointId));
  }, []);

  useEffect(() =>{
    // set the selected interface point as the selected one
    if(interfacePoints.length > 0)
      setProcessedData(processInterfaces(interfacePoints));
  }, [selectedFilterTypes,searchText]);

  /**
   * Waits for data to be ready and sets flag
   */
   useEffect(() => {
    if (!isInterfacePointsLoading && !isInterfacePointTypesLoading) {
      setIsReady(true);
    }
  }, [interfacePoints, isInterfacePointsLoading, isInterfacePointTypesLoading]);

  /**
   * Sets Mandatory interfaces
   */
  useEffect(() => {
    if (isReady && interfacePointTypes && interfacePointTypes.length > 0){
      setMandatoryInterfacePointTypes(interfacePointTypes.filter(t=>t.isMandatory == true));

      let filterSelectionOptionsArray = [
        { text: 'Active', value: 'Active', category: 'Status'},
        { text: 'Completed', value: 'Completed', category: 'Status'}
      ];
      
      // let interfacePointTypeOptions = [];
      // interfacePointTypes?.forEach((t) => {
      //   let types = {
      //     text: t.description,
      //     value: t.id,
      //     category: 'Type',
      //   }
      //   interfacePointTypeOptions.push(types);
      // })

      // filterSelectionOptionsArray = filterSelectionOptionsArray.concat(interfacePointTypeOptions);

      setFilterSelectionOptions(filterSelectionOptionsArray);
    }
  }, [isReady, interfacePoints]);

  /**
   * Processing interface types & setting the notification if required
   */
  useEffect(() => {
    if (isReady && !isInterfacePointsLoading && interfacePointTypes && interfacePointTypes.length > 0 && interfacePointTypes && interfacePointTypes.length > 0){
      if(mandatoryInterfacePointTypes.length > 0){
        interfacePointTypes.forEach((t)=>{
          if(mandatoryInterfacePointTypes.some(m=>m.id == t.id)){
            if(!interfacePoints.some(i=>i.projectInterfacePointTypeId == t.id)){
              //Setting the add interface point button disabled
              setDisableAddInterfacePoint(true);
            }
            else{
              setDisableAddInterfacePoint(false);
            }
          }
        })
      } 
      setProcessInterfaceTypes(true);
    }
  }, [mandatoryInterfacePointTypes]);

  /**
   * Deletes an interface point by setting status to deleted
   * @param {string} id 
   */
  const deleteInterfacePoint = (interfacePointId) => {
    props.deleteInterfacePoint(interfacePointId);
  }

  const handleUpdateFilter = (e) => {
    setSelectedFilterTypes(e);
  }

  const handleSearch = (searchText) => {
    setSearchText(searchText);
  }

   /**
   * Processes and filters the interfaces
   * with filter and sort options
   * @param {object} rawData 
   * @returns 
   */
    const processInterfaces = (rawData) => {
      let data = [];
      let sortedData = [];

      let searchedData = [];
      if(searchText || selectedFilterTypes.length > 0){
        if (searchText && searchText.length > 0) {
          searchedData = rawData.slice().filter((d) => ((d.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) || (d.interfacePointNumber.toLowerCase().indexOf(searchText.toLowerCase()) > -1)))
        } 
        else {
          searchedData = rawData.slice();
        }
    
        /*
          Filtering the Data
        **/
        let filtered = searchedData;
    
        if(selectedFilterTypes.length > 0){
          filtered = filtered.filter(a=>selectedFilterTypes.some(b=>b.value.value == a.status))
        }
        // if(selectedFilterTypes.length > 0){
        //   filtered = filtered.filter(a=>selectedFilterTypes.some(b=>b.value.value == a.status || b.value.value == a.projectInterfacePointTypeId))
        // }
        else {
          // skip filter and set to the search data
          filtered = searchedData;
        }

        // return the filtered/searched data
        data = filtered;
      }
      else{
        data = rawData;
      }

      // return the sorted data
      sortedData = data.slice().sort((a,b)=>
                                      (a.status.localeCompare(b.status)) || 
                                      ((new Date() - new Date(b.forecastDate ? b.forecastDate : b.interfacePointDate)) - (new Date() - new Date(a.forecastDate ? a.forecastDate : a.interfacePointDate))) || 
                                      (a.interfacePointNumber - b.interfacePointNumber)
                                    )

      return sortedData;
    }

  return (
    <>
    {isReady && processInterfaceTypes && !disableAddInterfacePoint &&
      <div className='interface-selector-container-panel'>
        <div className="interface-selector-container-panel-top">
          <div className="header-section">Interface List</div>
          <FilterToolbarInterface 
          filterOptions={filterSelectionOptions}
          filterFields={categoryFields}
          processUpdateFilter={handleUpdateFilter}
          processSearch={handleSearch}
          labelName={'Search for Interface:'}
          />
        </div>
        <div className="ips-container">
          {
            processedData.map((interfacePoint, i) =>
            (
              <InterfacePointSelectorItem interfacePoint={interfacePoint} key={interfacePoint.id} deleteInterfacePoint={deleteInterfacePoint} isEditable={isEditable} />
            ))
          }
        </div>
      </div>
    }
    </>
  );
}

export default InterfacePointsSelector;